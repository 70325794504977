import CategoryLogo from 'assets/images/admin-nodata/category.svg'
import CreatorLogo from 'assets/images/admin-nodata/creator.svg'
import EnqueteLogo from 'assets/images/admin-nodata/enquete.svg'
import FilelistLogo from 'assets/images/admin-nodata/filelist.svg'
import GroupLogo from 'assets/images/admin-nodata/group.svg'
import InviteLogo from 'assets/images/admin-nodata/invite.svg'
import LiveLogo from 'assets/images/admin-nodata/live.svg'
import NewsLogo from 'assets/images/admin-nodata/news.svg'
import PlaylistLogo from 'assets/images/admin-nodata/playlist.svg'
import StripeCouponLogo from 'assets/images/admin-nodata/stripe_coupon.svg'
import StripePriceLogo from 'assets/images/admin-nodata/stripe_price.svg'
import StripeProductLogo from 'assets/images/admin-nodata/stripe_product.svg'
import StripeTaxRateLogo from 'assets/images/admin-nodata/stripe_tax_rate.svg'
import TagLogo from 'assets/images/admin-nodata/tag.svg'
import TestLogo from 'assets/images/admin-nodata/test.svg'
import UserLogo from 'assets/images/admin-nodata/user.svg'
import VideoLogo from 'assets/images/admin-nodata/video.svg'
import { Routes } from 'common/enums'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import './registeritembutton.scss'

type PageType =
  | 'category'
  | 'creator'
  | 'filelist'
  | 'group'
  | 'invite'
  | 'live'
  | 'event'
  | 'news'
  | 'send_email'
  | 'enquete'
  | 'test'
  | 'playlist'
  | 'tag'
  | 'user'
  | 'custom_user'
  | 'video'
  | 'stripe_price'
  | 'stripe_product'
  | 'stripe_coupon'
  | 'stripe_tax_rate'

interface IRegisterItemButton {
  type: PageType
  states?: any[]
  onClick?: () => void
  disabled?: boolean
}

const RegisterItemButton: React.FC<IRegisterItemButton> = (props) => {
  const { t } = useTranslation('adminCommon')
  const history = useHistory()
  const { image, main, sub, buttons } = getData(props.type, t)
  return (
    <div className="register-item-button-wrapper">
      <div className="register-item-button">
        <div>
          <img
            className="admin-nodata-logo"
            src={image}
            alt="admin_nodata_logo"
          />
        </div>
        <div className="main-massage">{main}</div>
        <div className="sub-massage">{sub}</div>
        <div className="buttons">
          {buttons.map((b, key) => (
            <Button
              key={key}
              className="admin-nodata-button"
              onClick={() => {
                if (b.isNotTransition && props.onClick) {
                  props.onClick()
                } else if (props.states && props.states[key]) {
                  history.push(b.path, props.states[key])
                } else {
                  history.push(b.path)
                }
              }}
              color="primary"
              size="m"
              type="button"
              disabled={props.disabled}
            >
              {b.text}
            </Button>
          ))}
        </div>
      </div>
    </div>
  )
}

interface IButton {
  path: string
  text: string
  isNotTransition?: boolean
}

const getData = (
  type: PageType,
  t: TFunction<'adminCommon', undefined>
): {
  image: string
  main: string
  sub: string
  buttons: IButton[]
} => {
  const i18n = t('registerItemButton', { returnObjects: true })
  switch (type) {
    case 'category':
      return {
        image: CategoryLogo,
        main: i18n.category.main,
        sub: i18n.category.sub,
        buttons: [
          { path: Routes.AdminCategoryCreate, text: i18n.category.button },
        ],
      }
    case 'creator':
      return {
        image: CreatorLogo,
        main: i18n.creator.main,
        sub: i18n.creator.sub,
        buttons: [
          { path: Routes.AdminCreatorCreate, text: i18n.creator.button },
        ],
      }
    case 'filelist':
      return {
        image: FilelistLogo,
        main: i18n.filelist.main,
        sub: i18n.filelist.sub,
        buttons: [
          { path: Routes.AdminFilelistCreate, text: i18n.filelist.button },
        ],
      }
    case 'group':
      return {
        image: GroupLogo,
        main: i18n.group.main,
        sub: i18n.group.sub,
        buttons: [{ path: Routes.AdminGroupCreate, text: i18n.group.button }],
      }
    case 'invite':
      return {
        image: InviteLogo,
        main: i18n.invite.main,
        sub: '',
        buttons: [
          { path: Routes.AdminUserInviteCreate, text: i18n.invite.button1 },
          {
            path: Routes.AdminAdministratorInviteCreate,
            text: i18n.invite.button2,
          },
        ],
      }
    case 'live':
      return {
        image: LiveLogo,
        main: i18n.live.main,
        sub: i18n.live.sub,
        buttons: [{ path: Routes.AdminLiveCreate, text: i18n.live.button }],
      }
    case 'event':
      return {
        image: LiveLogo,
        main: i18n.event.main,
        sub: i18n.event.sub,
        buttons: [{ path: Routes.AdminEventCreate, text: i18n.event.button }],
      }
    case 'news':
      return {
        image: NewsLogo,
        main: i18n.news.main,
        sub: i18n.news.sub,
        buttons: [{ path: Routes.AdminNewsCreate, text: i18n.news.button }],
      }
    case 'send_email':
      return {
        image: NewsLogo,
        main: i18n.sendMail.main,
        sub: i18n.sendMail.sub,
        buttons: [
          { path: Routes.AdminSendEmailCreate, text: i18n.sendMail.button },
        ],
      }
    case 'enquete':
      return {
        image: EnqueteLogo,
        main: i18n.enquete.main,
        sub: i18n.enquete.sub,
        buttons: [
          { path: Routes.AdminEnqueteCreate, text: i18n.enquete.button },
        ],
      }
    case 'test':
      return {
        image: TestLogo,
        main: i18n.test.main,
        sub: i18n.test.sub,
        buttons: [{ path: Routes.AdminTestCreate, text: i18n.test.button }],
      }
    case 'playlist':
      return {
        image: PlaylistLogo,
        main: i18n.playlist.main,
        sub: i18n.playlist.sub,
        buttons: [
          { path: Routes.AdminPlaylistCreate, text: i18n.playlist.button },
        ],
      }
    case 'tag':
      return {
        image: TagLogo,
        main: i18n.tag.main,
        sub: i18n.tag.sub,
        buttons: [{ path: Routes.AdminTagCreate, text: i18n.tag.button }],
      }
    case 'user':
      return {
        image: UserLogo,
        main: i18n.user.main,
        sub: i18n.user.sub,
        buttons: [
          { path: Routes.AdminUserInviteCreate, text: i18n.user.button },
        ],
      }
    case 'custom_user':
      return {
        image: UserLogo,
        main: i18n.customUser.main,
        sub: i18n.customUser.sub,
        buttons: [
          { path: Routes.AdminUserInviteCreate, text: i18n.customUser.button1 },
          { path: '', text: i18n.customUser.button2, isNotTransition: true },
          { path: Routes.AdminUserImport, text: i18n.customUser.button3 },
        ],
      }
    case 'video':
      return {
        image: VideoLogo,
        main: i18n.video.main,
        sub: i18n.video.sub,
        buttons: [{ path: Routes.AdminVideoCreate, text: i18n.video.button }],
      }
    case 'stripe_price':
      return {
        image: StripePriceLogo,
        main: i18n.stripePrice.main,
        sub: i18n.stripePrice.sub,
        buttons: [
          {
            path: Routes.AdminStripePriceCreate,
            text: i18n.stripePrice.button,
          },
        ],
      }
    case 'stripe_product':
      return {
        image: StripeProductLogo,
        main: i18n.stripeProduct.main,
        sub: i18n.stripeProduct.sub,
        buttons: [
          {
            path: Routes.AdminStripeProductCreate,
            text: i18n.stripeProduct.button,
          },
        ],
      }
    case 'stripe_coupon':
      return {
        image: StripeCouponLogo,
        main: i18n.stripeCoupon.main,
        sub: i18n.stripeCoupon.sub,
        buttons: [
          {
            path: Routes.AdminStripeCouponCreate,
            text: i18n.stripeCoupon.button,
          },
        ],
      }
    case 'stripe_tax_rate':
      return {
        image: StripeTaxRateLogo,
        main: i18n.stripeTaxRate.main,
        sub: i18n.stripeTaxRate.sub,
        buttons: [
          {
            path: Routes.AdminStripeTaxRateCreate,
            text: i18n.stripeTaxRate.button,
          },
        ],
      }
  }
}

export default RegisterItemButton
