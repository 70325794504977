import { ILive, LivePlatform } from 'common/interfaces/live'
import { i18nValidation } from 'i18n/i18n'

/**
 * ライブ登録・編集用バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param live `ILive` ライブドキュメント
 * @param isImageCropped boolean サムネイル設定時に切り取りボタンが押下されたかどうか
 */
export const validateForm = (live: ILive, isImageCropped: boolean): void => {
  if (!live.name) throw new Error(i18nValidation('input.title'))
  if (live.platform === LivePlatform.ZOOM_MEETING && !live.meeting_number) {
    throw new Error(i18nValidation('input.meetingId'))
  }
  if (live.platform === LivePlatform.ZOOM_WEBINAR && !live.meeting_number) {
    throw new Error(i18nValidation('input.webinarId'))
  }
  if (
    live.platform === LivePlatform.YOUTUBE &&
    live.youtube_video_id.length !== 11
  ) {
    throw new Error(i18nValidation('input.youtubeUrlOrId'))
  }
  if (
    [
      LivePlatform.VIMEO_VIDEO,
      LivePlatform.VIMEO_LIVE,
      LivePlatform.VIMEO_WEBINAR,
    ].includes(live.platform) &&
    !live.vimeo_id
  ) {
    throw new Error(i18nValidation('input.vimeoUrlOrId'))
  }

  if (!isImageCropped) {
    throw new Error(i18nValidation('thumbnail.incompleteCut'))
  }
}
