import React from 'react'
import { Button } from 'reactstrap'

interface INormalButtonProps {
  className?: string
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  content: string
  color?: string
  size?: string
  disabled?: boolean
}

const NormalButton: React.FC<INormalButtonProps> = (props) => (
  <Button
    className={props.className}
    onClick={props.onClick}
    color={props.color ?? 'default'}
    size={props.size ?? 'sm'}
    type="button"
    disabled={props.disabled}
  >
    {props.content}
  </Button>
)

export default NormalButton
