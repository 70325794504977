import { IMailTemplateEventName, Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IMailTemplate } from 'common/interfaces/team'
import { nowTimestamp } from 'common/times'
import { isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert, i18nMailTemplate, i18nValidation } from 'i18n/i18n'
import { reloadCachedTeam } from 'providers/AuthProvider'
import { updateMailTemplates } from 'repositories/store/firebase_team'
import { alertService } from 'services/alert'

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  STATUS,
  EMAIL_TITLE,
  EMAIL_CONTENT,
}

const generateMailContent = (event_name: IMailTemplateEventName) => {
  if (event_name === IMailTemplateEventName.CANCEL_SUBSCRIPTION) {
    return i18nMailTemplate('mailTemplate.eventType.cancelSubscription.content')
  }
  const sentence = i18nMailTemplate(
    `mailTemplate.eventType.${event_name}.content`
  )
  return i18nMailTemplate('mailTemplate.baseTemplate', { sentence })
}

/**
 * メールテンプレートの初期値を返却
 */
export const initMailTemplate = (mailTemplate: IMailTemplate) => {
  mailTemplate.email_title =
    mailTemplate.email_title ||
    i18nMailTemplate(`mailTemplate.eventType.${mailTemplate.event_name}.title`)
  mailTemplate.email_content =
    mailTemplate.email_content || generateMailContent(mailTemplate.event_name)
  return mailTemplate
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  mailTemplate: IMailTemplate,
  setMailTemplate: React.Dispatch<React.SetStateAction<IMailTemplate>>,
  type: InputType,
  e: React.ChangeEvent<HTMLInputElement>
) => {
  const { value, checked } = e.target
  setMailTemplate({
    ...mailTemplate,
    status: type === InputType.STATUS ? checked : mailTemplate.status,
    email_title:
      type === InputType.EMAIL_TITLE ? value : mailTemplate.email_title,
  })
}

/**
 * Quill入力欄の`onChange`イベントハンドラ
 */
export const onChangeInputQuill = (
  mailTemplate: IMailTemplate,
  setMailTemplate: React.Dispatch<React.SetStateAction<IMailTemplate>>,
  e: string
) =>
  setMailTemplate({
    ...mailTemplate,
    email_content: e,
  })

/**
 * 自動メール更新処理
 */
export const saveMailTemplate = async (
  history: History,
  mailTemplate: IMailTemplate,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    if (!mailTemplate.email_title) {
      throw new Error(i18nValidation('input.mail.title'))
    }
    if (!mailTemplate.email_content) {
      throw new Error(i18nValidation('input.mail.content'))
    }

    const team = storeCache.team!
    const mailTemplates = team.mail_templates.filter(
      (mailTemplateObj) =>
        mailTemplateObj.event_name !== mailTemplate.event_name
    )
    mailTemplate.updated_at = nowTimestamp()
    mailTemplates.push(mailTemplate)

    await updateMailTemplates(team, mailTemplates)
    await reloadCachedTeam(storeCache)

    history.push(Routes.AdminMailTemplate)
    alertService.show(true, i18nAlert('saved.mailTemplate.email'))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
