import { Routes } from 'common/enums'
import { IEvent } from 'common/interfaces/event'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/EventForm/FormBody'
import TableBody from 'components/organisms/Admin/EventForm/TableBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initEvent } from 'services/admin/event_form'

const EventForm: React.FC = () => {
  const { t } = useTranslation('adminEvent')
  const { pathname, state } = useLocation<{ event: IEvent }>()
  const isCreate = pathname === Routes.AdminEventCreate
  const event = isCreate ? initEvent() : state.event

  return (
    <>
      <H1 title={isCreate ? t('form.title.register') : t('form.title.edit')} />

      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminEvent}
          content={t('form.backTolist')}
        />
        <FormBody isCreate={isCreate} eventObj={event} />
        {!isCreate && <TableBody eventObj={event} />}
      </Container>
    </>
  )
}

export default EventForm
