import { IStoreCache } from 'common/interfaces/auth_provider'
import { IStripe } from 'common/interfaces/stripe'
import { getAllFilelistSize, getFilelistUploadLimit } from 'common/utils'
import { i18nValidation } from 'i18n/i18n'

/**
 * 添付ファイル作成フォーム用バリデーション
 * 前提としてtryの中で使用すること
 */
export const validateForm = (
  file: File | undefined,
  { subscriptionObj, filelists }: IStoreCache,
  stripe: IStripe
): void => {
  if (file === undefined) throw new Error(i18nValidation('filelist.uploadFile'))

  const uploadLimit = getFilelistUploadLimit(subscriptionObj, stripe)
  const totalfilelistsSize = getAllFilelistSize(filelists)
  const uploadFileSize = file.size
  if (uploadLimit < totalfilelistsSize + uploadFileSize) {
    throw new Error(i18nValidation('filelist.cantUploadOverCapacity'))
  }
}
