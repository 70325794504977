import { Elements } from '@stripe/react-stripe-js'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import { IInvoice } from 'common/interfaces/stripe/Invoice'
import { couponInterval2str, formatIntervalV2, formatPrice } from 'common/utils'
import CheckoutForm from 'components/molecules/PaymentFormModal/CheckoutForm'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Modal, Row } from 'reactstrap'

type TPaymentFormModal = {
  stripe: Stripe | null
  invoice: IInvoice | null
  clientSecret: string
  isOpen: boolean
  buttonLoading: boolean
  onToggleModal: () => void
  onClick: (stripe: Stripe | null, elements: StripeElements | null) => void
}

const PaymentFormModal: React.FC<TPaymentFormModal> = (props) => {
  const { t } = useTranslation('itemSelect')

  if (!props.stripe || !props.invoice || !props.clientSecret) {
    return <></>
  }

  const { currency, total, tax, total_excluding_tax, discount, lines } =
    props.invoice
  const { price, tax_rates } = lines.data[0]
  const interval = formatIntervalV2(price.recurring?.interval)
  const intervalWithCount = formatIntervalV2(
    price.recurring?.interval,
    price.recurring?.interval_count
  )
  const coupon = discount?.coupon
  const isPercentOff = !!coupon?.percent_off
  const totalAmount = formatPrice(total, currency)

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={props.isOpen}
      toggle={!props.buttonLoading ? props.onToggleModal : undefined}
    >
      <div className="p-4">
        <div className="modal-header">
          <h1 className="modal-title">{price.nickname}</h1>
        </div>
        <div className="modal-header mt--4">
          <h1 className="modal-title">
            {totalAmount}/{interval}
          </h1>
        </div>
        <div className="modal-body">
          {coupon && (
            <div className="mb-4">
              {priceInfo(t('paymentFormModal.coupon.name'), coupon.name)}
              {priceInfo(
                t(
                  isPercentOff
                    ? 'paymentFormModal.coupon.discountPercentage'
                    : 'paymentFormModal.coupon.discountAmount'
                ),
                isPercentOff
                  ? `${String(coupon.percent_off!)}%`
                  : formatPrice(coupon.amount_off!, currency)
              )}
              {priceInfo(
                t('paymentFormModal.coupon.duration'),
                couponInterval2str(
                  coupon.duration,
                  coupon.duration_in_months ?? null
                )
              )}
            </div>
          )}
          {priceInfo(
            t('paymentFormModal.intervalInvoice', { intervalWithCount }),
            formatPrice(total_excluding_tax, currency)
          )}
          {currency === 'jpy' &&
            priceInfo(
              t('paymentFormModal.taxRate', {
                taxRatePercentage: tax_rates[0].percentage,
              }),
              formatPrice(tax, currency)
            )}
          {priceInfo(t('paymentFormModal.totalAmount'), totalAmount)}

          <div className="mt-4">
            <Elements
              stripe={props.stripe}
              options={{ clientSecret: props.clientSecret }}
            >
              <CheckoutForm
                clientSecret={props.clientSecret}
                buttonLoading={props.buttonLoading}
                onClick={props.onClick}
              />
            </Elements>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const priceInfo = (left: string, right: string) => (
  <Row className="my-1 d-flex justify-content-between">
    <Col>{left}</Col>
    <Col className="text-right">{right}</Col>
  </Row>
)

export default PaymentFormModal
