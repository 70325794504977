import classNames from 'classnames'
import {
  IMailTemplateEventName,
  PaymentIntentStatus,
  PaymentMethodType,
} from 'common/enums'
import {
  findCachedFilelists,
  findCachedGroups,
  findCachedTags,
  findCachedVideosAndLives,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IFilelist } from 'common/interfaces/filelist'
import { IGroup } from 'common/interfaces/group'
import { ITag } from 'common/interfaces/tag'
import { VideoStatus } from 'common/interfaces/video'
import { History } from 'history'
import { i18nAdminCommon, i18nAdminTest, i18nMailTemplate } from 'i18n/i18n'
import React from 'react'
import { Badge, Button } from 'reactstrap'
import './elementsontable.scss'

// compoenets/organisms/Admin/. のテーブルで使用

/**
 * overviewカラムの内容を生成
 */
export const createOverviewContent = (overview: string | null): JSX.Element => {
  return (
    <div className="overview">
      {createOverviewDataString(overview ?? '')
        .split('\n')
        .map((line, i) => (
          <div key={i}>{line}</div>
        ))}
    </div>
  )
}

/**
 * overviewカラムのdataStringを返却
 */
export const createOverviewDataString = (str: string): string =>
  str
    .replace(/<p><br.*?\/?><\/p>|<p><\/p>|<div><\/div>/gi, '')
    .replace(/<\/(div|p)>|<br.*?\/?>/gi, '\n')
    .replace(/(<([^>]+)>)/gi, '')

/**
 * 設問カラムのdataStringを返却
 */
export const createTestQuestionNameDataString = (str: string): string => {
  const deleteImageTagStr = str.replace(
    /<img.*?>/gi,
    i18nAdminTest('answerDetail.table.image')
  )

  return createOverviewDataString(deleteImageTagStr)
}

/**
 * テーブルにボタン生成
 */
export const makeCustomOperationButton = (
  name: string,
  action: () => void
): JSX.Element => (
  <Button onClick={action} color="secondary" type="button" size="sm" outline>
    {name}
  </Button>
)

/**
 * Tableの操作カラムのボタンを生成
 */
export const makeCustomOperationButtons = (
  children: JSX.Element | JSX.Element[]
): JSX.Element => {
  const buttons = (Array.isArray(children) ? children : [children]).map(
    (b, i) => <div key={i}>{b}</div>
  )
  return <div className="operations">{buttons}</div>
}

/**
 * Tableの「編集」「削除」操作ボタンを生成
 */
export const makeEditDeleteOperationButtons = <T, O = {}>(
  history: History,
  state: string | O,
  pathname: string,
  remove: (e: T) => void,
  e: T,
  additionalButtons?: {
    buttons: JSX.Element | JSX.Element[]
    isFirst?: boolean
  }
): JSX.Element => {
  const st: O | object = typeof state === 'string' ? { [state]: e } : state
  const optionalButtons = additionalButtons?.buttons
    ? (Array.isArray(additionalButtons.buttons)
        ? additionalButtons.buttons
        : [additionalButtons.buttons]
      ).map((b, i) => <div key={i}>{b}</div>)
    : null

  return (
    <div className="operations">
      {additionalButtons?.isFirst && optionalButtons}
      <div>
        {makeCustomOperationButton(
          i18nAdminCommon('elementsOnTable.operationButtons.edit'),
          () => history.push({ pathname, state: st })
        )}
      </div>
      <div>
        {makeCustomOperationButton(
          i18nAdminCommon('elementsOnTable.operationButtons.delete'),
          () => remove(e)
        )}
      </div>
      {!additionalButtons?.isFirst && optionalButtons}
    </div>
  )
}

/**
 * imgエレメントを生成
 */
export const makeThumbnailElement = (
  image: string | null,
  alt: string | null,
  type: 'category' | 'creator' | 'live' | 'event' | 'playlist' | 'tag' | 'video'
): JSX.Element => {
  if (!image) return <></>

  const is16x9 =
    type === 'live' ||
    type === 'playlist' ||
    type === 'video' ||
    type === 'event'

  return (
    <img
      className={classNames({
        'thumbnail-16x9': is16x9,
        'thumbnail-1x1': !is16x9,
      })}
      src={String(image)}
      alt={String(alt)}
      width={100}
      height="auto"
    />
  )
}

const makeBadge = (
  obj: ITag | IGroup | IFilelist | null,
  color: string
): JSX.Element => (obj ? <Badge color={color}>{obj.name}</Badge> : <></>)

/**
 * テキストからバッジを生成
 */
export const makePrimaryBadges = (texts: string[]): JSX.Element => (
  <>
    {texts.map((t, i) => (
      <div className="mb-1" key={i}>
        {makeBadge({ name: t } as ITag, 'primary')}
      </div>
    ))}
  </>
)

/**
 * 公開、非公開、期限公開のバッジを生成
 */
export const makeVideoStatusBadge = (status: number): JSX.Element => {
  const { name, color } = (() => {
    switch (status) {
      case VideoStatus.PRIVATE:
        return {
          name: i18nAdminCommon('elementsOnTable.videoStatusBadge.private'),
          color: 'danger',
        }
      case VideoStatus.PUBLIC:
        return {
          name: i18nAdminCommon('elementsOnTable.videoStatusBadge.public'),
          color: 'success',
        }
      default:
      case VideoStatus.PUBLICATION_PERIOD:
        return {
          name: i18nAdminCommon(
            'elementsOnTable.videoStatusBadge.publicationPeriod'
          ),
          color: 'info',
        }
    }
  })()
  return makeBadge({ name } as ITag, color)
}

/**
 * タグのバッジを生成
 */
export const makeTagBadges = (
  storeCache: IStoreCache,
  tagIds: string[]
): JSX.Element[] =>
  findCachedTags(storeCache, tagIds).map((tag, i) => (
    <div className="mb-1" key={i}>
      {makeBadge(tag, 'primary')}
    </div>
  ))

/**
 * コンテンツのバッジを生成
 */
export const makeVideoAndLiveBadges = (
  storeCache: IStoreCache,
  contentIds: string[]
): JSX.Element[] =>
  findCachedVideosAndLives(storeCache, contentIds).map((c, i) => (
    <div className="mb-1" key={i}>
      {makeBadge(c, 'primary')}
    </div>
  ))

/**
 * グループのバッジを生成
 */
export const makeGroupBadges = (
  storeCache: IStoreCache,
  groupIds: string[]
): JSX.Element[] =>
  findCachedGroups(storeCache, groupIds).map((group, i) => (
    <div className="mb-1" key={i}>
      {makeBadge(group, 'primary')}
    </div>
  ))

/**
 * ファイルリストのバッジを生成
 */
export const makeFilelistBadges = (
  storeCache: IStoreCache,
  filelistIds: string[]
): JSX.Element[] =>
  findCachedFilelists(storeCache, filelistIds).map((filelist, i) => (
    <div className="mb-1" key={i}>
      {makeBadge(filelist, 'info')}
    </div>
  ))

/**
 * 有効、無効のバッジを生成
 */
export const makeActiveBadge = (isActive: boolean): JSX.Element => {
  const name = isActive ? i18nAdminCommon('enable') : i18nAdminCommon('disable')
  const color = isActive ? 'success' : 'danger'
  return makeBadge({ name } as ITag, color)
}

/**
 * 支払いステータスのバッジを生成
 */
export const makePaymentStatusBadge = (
  status: PaymentIntentStatus
): JSX.Element => {
  const { name, color } = (() => {
    switch (status) {
      case PaymentIntentStatus.CANCELED:
        return {
          name: i18nAdminCommon('elementsOnTable.paymentStatusBadge.failure'),
          color: 'danger',
        }
      default:
      case PaymentIntentStatus.SUCCEEDED:
        return {
          name: i18nAdminCommon('elementsOnTable.paymentStatusBadge.success'),
          color: 'success',
        }
    }
  })()
  return makeBadge({ name } as ITag, color)
}

/**
 * 支払い方法の複数バッジを生成
 */
export const makePaymentMethodTypesStatusBadges = (
  paymentMethodTypes: PaymentMethodType[]
): JSX.Element => {
  const formatPaymentMethodTypes = paymentMethodTypes.map((pmt) => {
    switch (pmt) {
      case PaymentMethodType.CUSTOMER_BALANCE:
        return i18nAdminCommon(
          'elementsOnTable.paymentMethodTypesStatusBadges.customerBalance'
        )
      default:
      case PaymentMethodType.CARD:
        return i18nAdminCommon(
          'elementsOnTable.paymentMethodTypesStatusBadges.card'
        )
    }
  })
  return makePrimaryBadges(formatPaymentMethodTypes)
}

/**
 * メールテンプレートのイベント名を返す
 */
export const getMailTemplateEventName = (
  eventName: IMailTemplateEventName
): string => {
  return i18nMailTemplate(`mailTemplateEventName.${eventName}`)
}
