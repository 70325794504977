import { ColumnDef } from '@tanstack/react-table'
import { AuthMethodType } from 'common/enums'
import { IInvitePendingUser } from 'common/interfaces/invite'
import {
  customFieldColumnDef,
  useTanstackTableWithCsvExport,
} from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeCustomOperationButtons,
} from 'components/atoms/Table/ElementsOnTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import {
  approveUser,
  removePendingUser,
} from 'services/admin/approval_pending_user'
import { getInvitePendingUser } from 'services/admin/user'
import InvitePendingUsersModal from './InvitePendingUsersModal'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminInvite')
  const { storeCache } = useContext(AuthContext)
  const [invitePendingUsers, setInvitePendingUsers] = useState<
    IInvitePendingUser[]
  >([])
  const [selectedInvitePendingUsers, setSelectedInvitePendingUsers] = useState<
    IInvitePendingUser[]
  >([])
  const [pendingInviteUsersModal, setInvitePendingUsersModal] =
    useState<boolean>(false)
  const togglePendingUsersModal = () =>
    setInvitePendingUsersModal(!pendingInviteUsersModal)

  useEffect(() => {
    getInvitePendingUser(setInvitePendingUsers, storeCache)
  }, [setInvitePendingUsers, storeCache])

  return (
    <>
      <InvitePendingUsersModal
        isOpen={pendingInviteUsersModal}
        toggleModal={togglePendingUsersModal}
        selectedInvitePendingUsers={selectedInvitePendingUsers}
      />
      {selectedInvitePendingUsers.length > 0 && (
        <Trans
          t={t}
          i18nKey="list.dropdown.action"
          components={[
            <UncontrolledDropdown>
              <DropdownToggle caret color="secondary">
                <></>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={togglePendingUsersModal}>
                  {t('list.dropdown.approve')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>,
          ]}
        />
      )}
      <Table
        invitePendingUsers={invitePendingUsers}
        onChangeSelectedInvitePendingUsers={setSelectedInvitePendingUsers}
      />
    </>
  )
}

const Table: React.FC<{
  invitePendingUsers: IInvitePendingUser[]
  onChangeSelectedInvitePendingUsers: (users: IInvitePendingUser[]) => void
}> = ({ invitePendingUsers, onChangeSelectedInvitePendingUsers }) => {
  const i18n = useTranslation('adminInvite').t('approvalPendingUser.table', {
    returnObjects: true,
  })
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const isCustomAuthMethod =
    storeCache.team!.auth_method === AuthMethodType.CUSTOM

  const columns = useMemo<ColumnDef<IInvitePendingUser>[]>(
    () => [
      {
        header: i18n.title,
        accessorKey: 'invite_name',
      },
      {
        header: i18n.email,
        accessorKey: 'email',
        meta: { hidden: isCustomAuthMethod },
      },
      {
        header: i18n.name,
        accessorKey: 'name',
      },
      {
        header: i18n.phone,
        accessorKey: 'phone',
      },
      ...customFieldColumnDef<IInvitePendingUser>(
        storeCache.team!,
        invitePendingUsers
      ),
      {
        header: i18n.operations,
        accessorFn: (u) =>
          makeCustomOperationButtons([
            makeCustomOperationButton(i18n.approve, () =>
              approveUser(storeCache, u, u.invited_id!)
            ),
            makeCustomOperationButton(i18n.delete, () => {
              const isPendingAllUsersPage = true
              removePendingUser(
                storeCache,
                { history, invitedId: u.invited_id! },
                u.id,
                isPendingAllUsersPage
              )
            }),
          ]),
        meta: { csvExport: false },
      },
    ],
    [storeCache, history, isCustomAuthMethod, invitePendingUsers, i18n]
  )
  const data = invitePendingUsers.sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  )

  return useTanstackTableWithCsvExport<IInvitePendingUser>(
    columns,
    data,
    'approval_pending_users.csv',
    {
      enableMultiRowSelection: true,
      enableAllRowSelection: true,
      onChangeSelectedRows: onChangeSelectedInvitePendingUsers,
    }
  )
}

export default TableBody
