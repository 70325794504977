import { IStoreCache } from 'common/interfaces/auth_provider'
import { isLoggedIn, stripeAPIError } from 'common/utils'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedUser } from 'providers/AuthProvider'
import { changePrice } from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'

/**
 * stripeで料金（プラン）を変更する
 *
 * @param history `History`
 * @param storeCache `IStoreCache`
 * @param subscriptionObj `ISubscriptionObject` | null
 * @param priceId string  変更するプランのID
 * @param redirectURL string  変更後にリダイレクトするURL
 * @param setLoading `React.Dispatch<React.SetStateAction<boolean>>`
 */
export const changePriceForUser = async (
  { replace }: History,
  storeCache: IStoreCache,
  priceId: string,
  redirectURL: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache) || !storeCache.selfSubscriptionObj) return

  setLoading(true)
  try {
    await changePrice(
      storeCache.team!.stripe.account_id,
      storeCache.selfSubscriptionObj.subscription.id!,
      priceId
    )
    await reloadCachedUser(storeCache)

    replace(redirectURL)
    alertService.show(true, i18nAlert('plans.changed'))
  } catch (error: any) {
    stripeAPIError(error)
  }
  setLoading(false)
}
