import { Routes } from 'common/enums'
import { ITaxRate } from 'common/interfaces/stripe/tax_rate'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/StripeTaxRateForm/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initTaxRateForm } from 'services/admin/tax_rate_form'

const StripeTaxRateForm: React.FC = () => {
  const { t } = useTranslation('adminStripeBilling')
  const history = useHistory()
  const location = useLocation<{ taxRate: ITaxRate }>()
  const isCreate = location.pathname === Routes.AdminStripeTaxRateCreate
  const taxRate: ITaxRate = isCreate
    ? initTaxRateForm()
    : location.state.taxRate
  return (
    <>
      <H1
        title={
          isCreate
            ? t('stripeTaxRate.form.title.create')
            : t('stripeTaxRate.form.title.edit')
        }
      />

      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminStripeTaxRate}
          content={t('stripeTaxRate.backToList')}
        />
        <FormBody isCreate={isCreate} taxRateObj={taxRate} history={history} />
      </Container>
    </>
  )
}

export default StripeTaxRateForm
