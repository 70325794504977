import { AxiosProgressEvent } from 'axios'
import { Routes } from 'common/enums'
import {
  findCachedCategory,
  findCachedCreator,
  findCachedFilelistsNonNull,
  findCachedGroupsNonNull,
  findCachedTagsNonNull,
  getDefaultPrices,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IStripe } from 'common/interfaces/stripe'
import { IPlayablePeriod, IVideo, VideoStatus } from 'common/interfaces/video'
import {
  date2Timestamp,
  datetimeLocalFormat,
  nowTimestamp,
  todayFirebaseTimestamp,
} from 'common/times'
import {
  checkFirebaseError,
  createFilelistOptions,
  createGroupOptions,
  createPriceOptions,
  createTagOptions,
  emptyStr2Null,
  getAllVideoSize,
  getDefaultPublicationPeriod,
  getFileSize,
  getVideoUploadLimit,
  isLoggedIn,
} from 'common/utils'
import { isImageBunnyThumbnail } from 'common/utils_bunny'
import {
  createCategoryJSXOptions,
  createCreatorJSXOptions,
} from 'components/atoms/Table/CreateTable'
import { History } from 'history'
import { i18nAdminVideo, i18nAlert } from 'i18n/i18n'
import { reloadCachedVideos } from 'providers/AuthProvider'
import {
  isUploadSuccess,
  register,
  upload as uploadVideo,
} from 'repositories/functions/functions_video'
import { removeImageFile, upload } from 'repositories/storage/firebase_video'
import {
  removeImage,
  store,
  update,
  updateUploaded,
} from 'repositories/store/firebase_video'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/video'
import { v4 as uuidv4 } from 'uuid'
import { initProgressEvent, uploadProgressService } from './upload_progress'
import { showUploadErrorModal } from './video'

/**
 * `IVideo`の初期値を返却する
 */
export const initVideo = (): IVideo => {
  return {
    id: uuidv4(),
    bunny: {
      videoId: '',
      status: 'Uploading',
      length: 0,
      storageSize: 0,
    },
    name: '',
    image: null,
    overview: null,
    category_id: '',
    tag_ids: [],
    filelist_ids: [],
    creator_id: '',
    status: VideoStatus.PRIVATE,
    group_ids: [],
    price_ids: [],
    view_count: 0,
    playable_period: null,
    publication_period: null,
    action_onend: null,
    is_seekable: true,
    is_public: false,
    is_list_hidden: false,
    is_premiere: false,
    is_registered: false,
    is_uploaded: false,
    created_at: nowTimestamp(),
    updated_at: nowTimestamp(),
    released_at: null,
  }
}

/**
 * 動画のデフォルト値を返却する
 */
export const getVideoDefaults = (storeCache: IStoreCache, video: IVideo) => {
  return {
    defaultCategory: findCachedCategory(storeCache, video.category_id),
    defaultCreator: findCachedCreator(storeCache, video.creator_id),
    defaultTags: findCachedTagsNonNull(storeCache, video.tag_ids).map(
      (tag) => ({ value: tag.id, label: tag.name })
    ),
    defaultFilelists: findCachedFilelistsNonNull(
      storeCache,
      video.filelist_ids
    ).map((filelist) => ({ value: filelist.id, label: filelist.name })),
    defaultGroups: findCachedGroupsNonNull(storeCache, video.group_ids).map(
      (group) => ({ value: group.id, label: group.name })
    ),
    defaultPrices: getDefaultPrices(storeCache, video),
    defaultQuestion: video.enquete?.activate_enquete
      ? 'active_enquete'
      : video.test?.activate_test
      ? 'active_test'
      : video.next_video?.activate_next_video
      ? 'active_next_video'
      : '',
  }
}

/**
 * Dropzoneのスタイル
 */
export const dropzoneStyle = {
  baseStyle: { display: 'flex', flexDirection: 'column' },
  borderNormalStyle: { border: '2px dotted #888' },
  borderDragStyle: {
    border: '2px solid #00f',
    transition: 'border .5s ease-in-out',
  },
}

/**
 * Input/Selectで使用するoptionsを返却する
 */
export const getFormOptions = (storeCache: IStoreCache) => {
  return {
    tagOptions: createTagOptions(storeCache.tags),
    filelistOptions: createFilelistOptions(storeCache.filelists),
    categoryOptions: createCategoryJSXOptions(storeCache.categories),
    creatorOptions: createCreatorJSXOptions(storeCache.creators),
    groupOptions: createGroupOptions(storeCache.groups),
    priceOptions: createPriceOptions(storeCache.prices),
  }
}

/**
 * 再生期間のデフォルト値を返却する
 */
export const getDefaultPlayablePeriod = ({
  playable_period,
}: IVideo): IPlayablePeriod => {
  return {
    from: playable_period?.from ?? todayFirebaseTimestamp()[0],
    to: playable_period?.to ?? todayFirebaseTimestamp()[1],
  }
}

/**
 * 公開グループ選択の現在値を返却する
 */
export const getGroupValues = (storeCache: IStoreCache, video: IVideo) =>
  getVideoDefaults(storeCache, video).defaultGroups

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
  CATEGORY_ID,
  TAG_IDS,
  FILELIST_IDS,
  OVERVIEW,
  CREATOR_ID,
  IS_DISABLE_SEEK,
  IS_LIST_HIDDEN,
  GROUP_IDS,
  PRICE_IDS,
  RELEASED_AT,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  type: InputType,
  e: any
) => {
  const val = e.target?.value
  setVideo({
    ...video,
    name: type === InputType.NAME ? val : video.name,
    category_id: type === InputType.CATEGORY_ID ? val : video.category_id,
    tag_ids:
      type === InputType.TAG_IDS ? e.map((x: any) => x.value) : video.tag_ids,
    filelist_ids:
      type === InputType.FILELIST_IDS
        ? e.map((x: any) => x.value)
        : video.filelist_ids,
    overview: type === InputType.OVERVIEW ? e : video.overview,
    creator_id: type === InputType.CREATOR_ID ? val : video.creator_id,
    group_ids:
      type === InputType.GROUP_IDS
        ? e.map((x: any) => x.value)
        : video.group_ids,
    is_seekable:
      type === InputType.IS_DISABLE_SEEK
        ? !e.target.checked
        : video.is_seekable,
    is_list_hidden:
      type === InputType.IS_LIST_HIDDEN
        ? e.target.checked
        : video.is_list_hidden,
    price_ids:
      type === InputType.PRICE_IDS
        ? e.map((x: any) => x.value)
        : video.price_ids,
    released_at: (() => {
      if (type !== InputType.RELEASED_AT) {
        return video.released_at
      }
      if (val === '') return null
      return date2Timestamp(new Date(val))
    })(),
    publication_period: (() => {
      if (type !== InputType.RELEASED_AT) {
        return video.publication_period
      }
      return {
        start: date2Timestamp(new Date(val)),
        end: video.publication_period?.end ?? null,
      }
    })(),
  })
}

/**
 * 公開/非公開Inputの`onChange`イベントハンドラ
 */
export const onChangeStatus = (
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  e: React.ChangeEvent<HTMLInputElement>
) => {
  const val = Number(e.target.value)
  const period: IVideo['publication_period'] = (() => {
    switch (val) {
      case VideoStatus.PUBLIC:
        return { start: nowTimestamp(), end: null }
      case VideoStatus.PUBLICATION_PERIOD:
        return getDefaultPublicationPeriod(video)
      default:
        return null
    }
  })()
  setVideo({
    ...video,
    status: val,
    publication_period: period,
    released_at: period?.start ?? null,
  })
}

/**
 * 公開日/公開期限Inputの`onChange`イベントハンドラ
 */
export const onChangeLimitedAccess = (
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  type: 'start' | 'end',
  { target }: React.ChangeEvent<HTMLInputElement>
) => {
  const date = date2Timestamp(new Date(target.value))
  setVideo({
    ...video,
    publication_period: {
      start: type === 'start' ? date : video.publication_period!.start,
      end: type === 'end' ? date : video.publication_period!.end,
    },
    released_at: type === 'start' ? date : video.released_at,
  })
}

/**
 * 公開可能期間可否のチェックボックス`onChange`イベントハンドラ
 */
export const onChangePlayablePeriodStatus = (
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  { target }: React.ChangeEvent<HTMLInputElement>,
  setPlayablePeriod: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { checked } = target
  const defaultPlayablePeriod = getDefaultPlayablePeriod(video)
  setVideo({
    ...video,
    playable_period: checked ? defaultPlayablePeriod : null,
    is_premiere: false,
    is_seekable: true,
  })
  setPlayablePeriod(checked)
}

/**
 * 公開可能期間のFrom/To Inputの`onChange`イベントハンドラ
 */
export const onChangePlayablePeriod = (
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  type: 'from' | 'to',
  { target }: React.ChangeEvent<HTMLInputElement>
) => {
  const date = date2Timestamp(new Date(target.value))
  if (video.is_premiere) {
    setVideo({
      ...video,
      playable_period: { from: date, to: date },
    })
    return
  }

  const defaultPlayablePeriod = getDefaultPlayablePeriod(video)
  setVideo({
    ...video,
    playable_period: {
      from:
        type === 'from'
          ? date
          : video.playable_period?.from ?? defaultPlayablePeriod.from,
      to:
        type === 'to'
          ? date
          : video.playable_period?.to ?? defaultPlayablePeriod.to,
    },
  })
}

/**
 * プレミア公開のチェックボックス`onChange`イベントハンドラ
 */
export const onChangePremiere = (
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  { target: { checked } }: React.ChangeEvent<HTMLInputElement>
) => {
  setVideo({
    ...video,
    is_premiere: checked,
    is_seekable: !checked,
  })
}

/**
 * 動画を未ログインでも表示するチェックボックスの`onChange`イベントハンドラ
 * チェックされた場合、`is_registered`(再生制限を会員登録必須にする)をfalseにする
 */
export const onChangeIsPublic = (
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  { target }: React.ChangeEvent<HTMLInputElement>
) =>
  setVideo({
    ...video,
    is_public: target.checked,
    is_registered: video.is_public ? false : video.is_registered,
    group_ids: [],
  })

/**
 * 再生制限を会員登録必須 or 必須でないにするラジオボタンの`onChange`イベントハンドラ
 */
export const onChangeIsRegistered = (
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  { target }: React.ChangeEvent<HTMLInputElement>
) => setVideo({ ...video, is_registered: target.value === 'true' })

/**
 * 動画終了時のアクション有無Inputの`onChange`イベントハンドラ
 */
export const onChangeActionOnEndCheck = (
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  e: React.ChangeEvent<HTMLInputElement>
) => {
  const checked = e.target.checked
  setVideo({
    ...video,
    action_onend: checked ? { text: '', link: '', as_new_tab: false } : null,
  })
}

/**
 * 動画終了時のアクションInputの`onChange`イベントハンドラ
 */
export const onChangeActionOnEnd = (
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  type: 'text' | 'link' | 'as_new_tab',
  e: React.ChangeEvent<HTMLInputElement>
) => {
  const checked = e.target?.checked
  const val = e.target?.value
  setVideo({
    ...video,
    action_onend: {
      text: type === 'text' ? val : video.action_onend?.text ?? '',
      link: type === 'link' ? val : video.action_onend?.link ?? '',
      as_new_tab:
        type === 'as_new_tab'
          ? checked
          : video.action_onend?.as_new_tab ?? false,
    },
  })
}

/**
 * アンケートやテストの可否イベントハンドラ
 */
export const onChangeActiveQuestions = (
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  type: 'none' | 'active_enquete' | 'active_test' | 'active_next_video'
) => {
  const activate_enquete = type === 'active_enquete'
  const activate_test = type === 'active_test'
  const activate_next_video = type === 'active_next_video'

  setVideo({
    ...video,
    enquete: { activate_enquete, enquete_id: '' },
    test: { activate_test, test_id: '' },
    next_video: { activate_next_video, next_video_id: '' },
  })
}

/**
 * アンケートやテストのIDイベントハンドラ
 */
export const onChangeActiveQuestionsId = (
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  type: 'enquete_id' | 'test_id' | 'next_video_id',
  e: React.ChangeEvent<HTMLInputElement>
) => {
  const val = e.target.value
  setVideo({
    ...video,
    enquete: {
      activate_enquete: video.enquete!.activate_enquete,
      enquete_id: type === 'enquete_id' ? val : video.enquete!.enquete_id,
    },
    test: {
      activate_test: video.test!.activate_test,
      test_id: type === 'test_id' ? val : video.test!.test_id,
    },
    next_video: {
      activate_next_video: video.next_video!.activate_next_video,
      next_video_id:
        type === 'next_video_id' ? val : video.next_video!.next_video_id,
    },
  })
}

/**
 * 動画ファイルをStateに格納しタイトルを設定する
 */
const setVideoFileAndOverwriteTitle = (
  file: File,
  setVideoFile: React.Dispatch<React.SetStateAction<File | undefined>>,
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  videoTitleRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>
) => {
  setVideoFile(file)
  if (
    emptyStr2Null(video.name) === null &&
    videoTitleRef &&
    videoTitleRef.current
  ) {
    const videoName =
      file.name.lastIndexOf('.') === -1
        ? file.name
        : file.name.slice(0, file.name.lastIndexOf('.'))
    videoTitleRef.current.value = videoName
    setVideo({ ...video, name: videoName })
  }
}

/**
 * 動画ファイル選択時のイベントハンドラ
 */
export const onChangeVideoFile = (
  e: React.ChangeEvent<HTMLInputElement>,
  setVideoFile: React.Dispatch<React.SetStateAction<File | undefined>>,
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  videoTitleRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>
) => {
  if (e.target.files && e.target.files[0]) {
    setVideoFileAndOverwriteTitle(
      e.target.files[0],
      setVideoFile,
      video,
      setVideo,
      videoTitleRef
    )
  }
}

/**
 * 動画ファイルドロップ時のイベントハンドラ
 */
export const onDropVideoFile = (
  acceptedFiles: File[],
  setVideoFile: React.Dispatch<React.SetStateAction<File | undefined>>,
  video: IVideo,
  setVideo: React.Dispatch<React.SetStateAction<IVideo>>,
  videoTitleRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>
) => {
  if (acceptedFiles[0]) {
    setVideoFileAndOverwriteTitle(
      acceptedFiles[0],
      setVideoFile,
      video,
      setVideo,
      videoTitleRef
    )
  }
}

/**
 * 容量オーバーのファイルサイズ文言を返却する
 */
export const videoSizeOverText = (
  { subscriptionObj, videos }: IStoreCache,
  stripe: IStripe
) => {
  const limit = getFileSize(getVideoUploadLimit(subscriptionObj, stripe))
  const use = getFileSize(getAllVideoSize(videos))
  return i18nAdminVideo('form.sizeOver.limit', { limit, use })
}

/**
 * 動画レコード作成/更新処理
 */
export const saveVideo = async (
  history: History,
  video: IVideo,
  videoFile: File | undefined,
  imageBlob: Blob | undefined,
  imageSuggestionUrl: string | undefined,
  isImageDelete: boolean,
  isImageCropped: boolean,
  storeCache: IStoreCache,
  isCreate: boolean,
  isLimitPlayActive: boolean,
  setEnableSaveButton: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!isLoggedIn(storeCache)) return

  try {
    if (isCreate && !videoFile) {
      throw new Error(i18nAlert('video.notSelected'))
    }

    validateForm(video, isImageCropped)

    if (!isLimitPlayActive) {
      video.is_registered = false
      video.price_ids = []
    }
    if (video.is_registered) video.price_ids = []

    if (isCreate) {
      await createVideo(
        history,
        video,
        videoFile!,
        imageBlob,
        storeCache,
        setEnableSaveButton,
        setIsLoading
      )
    } else {
      await updateVideo(
        video,
        imageBlob,
        imageSuggestionUrl,
        isImageDelete,
        storeCache
      )
    }
    await reloadCachedVideos(storeCache)

    history.push(Routes.AdminVideo)
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}

const createVideo = async (
  history: History,
  video: IVideo,
  videoFile: File,
  imageBlob: Blob | undefined,
  storeCache: IStoreCache,
  setEnableSaveButton: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true)
  setEnableSaveButton(false)
  video.image = await upload(storeCache.team!, video, imageBlob)

  const tusUploadData = await register(storeCache, video.name)
  if (!tusUploadData) {
    setIsLoading(false)
    setEnableSaveButton(true)
    throw new Error(i18nAlert('video.failedRegister'))
  }

  video.bunny.videoId = tusUploadData.headers.VideoId
  video.created_at = nowTimestamp()
  video.updated_at = nowTimestamp()
  await store(storeCache.team!, video)

  const uploadAbortController = new AbortController()
  const uploadProgress = (e: AxiosProgressEvent) =>
    uploadProgressService.update({
      videoId: video.id,
      videoName: video.name,
      progressEvent: e,
      abortController: uploadAbortController,
    })
  uploadProgress(initProgressEvent())

  uploadVideo(
    videoFile,
    video.name,
    tusUploadData,
    uploadProgress,
    uploadAbortController
  ).then(async (e) => {
    if (uploadProgressService.isUploadCancelled(video.id)) return

    if (isUploadSuccess(e, videoFile)) {
      try {
        await updateUploaded(storeCache.team!, video, true)
        await reloadCachedVideos(storeCache)
        uploadProgressService.remove(video.id)
      } catch (e) {
        alertService.show(false, i18nAlert('updated.fail.videoUploadInfo'))
      }
    } else {
      showUploadErrorModal(history, storeCache, video, videoFile.name)
    }
  })
}

const updateVideo = async (
  video: IVideo,
  imageBlob: Blob | undefined,
  imageSuggestionUrl: string | undefined,
  isImageDelete: boolean,
  storeCache: IStoreCache
) => {
  const isImageFromBunny = isImageBunnyThumbnail(storeCache.team!, video)
  if (
    isImageDelete ||
    (video.image && imageSuggestionUrl && !isImageFromBunny)
  ) {
    await deleteImage(video, storeCache)
  }

  if (imageBlob && !isImageDelete) {
    video.image = await upload(storeCache.team!, video, imageBlob)
  }
  if (imageSuggestionUrl) {
    video.image = imageSuggestionUrl
  }

  await update(storeCache.team!, video)
}

/**
 * 動画の画像を削除する(ドキュメント更新 & ファイル削除)
 */
const deleteImage = async (
  video: IVideo,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    const isImageFromBunny = isImageBunnyThumbnail(storeCache.team!, video)
    if (!isImageFromBunny) {
      await removeImageFile(storeCache.team!, video)
    }
    await removeImage(storeCache.team!, video)
  } catch (error) {
    console.log(error)
    alertService.show(false, i18nAlert('deleted.fail.thumbnail'))
  }
}

/**
 * 公開日を返却する
 */
export const getReleaseDate = (video: IVideo): string => {
  if (video.status === VideoStatus.PUBLICATION_PERIOD) {
    return datetimeLocalFormat(
      video.publication_period?.start ?? nowTimestamp()
    )
  }
  if (video.released_at === null) return ''
  return datetimeLocalFormat(video.released_at)
}
