import { EntryType, Routes } from 'common/enums'
import { auth } from 'common/firebase'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { makeEntryPath, makeLoginTeamPath } from 'common/link_path'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { findTeamUserById } from 'repositories/store/firebase_user'
import { alertService } from './alert'
import { LOCAL_STORAGE_TEAM_ID } from './local_storage'

const replacePageFromLocalStorage = (history: History) => {
  const teamId = LOCAL_STORAGE_TEAM_ID
  if (teamId) {
    history.replace(makeLoginTeamPath(teamId))
    return
  }
  history.replace(makeEntryPath(), { type: EntryType.NOT_TEAM })
}

const isAdminPage = (): boolean =>
  window.location.pathname === Routes.LoggedInAdmin ||
  window.location.pathname === Routes.EntryAdmin ||
  window.location.pathname.startsWith(Routes.Admin)

/**
 * UIDからチームとユーザーの情報を取得する
 *
 * - 管理者ページ: 管理者としての情報を取得
 * - 会員ページ: URLのチームIDから対象チームとしての情報を取得
 * - どちらも存在しない場合はログアウトしページ遷移する
 */
export const getTeamUserData = async (
  history: History,
  uid: string
): Promise<{
  team: ITeam
  user: IUser
} | null> => {
  const teamUsers = await findTeamUserById(uid)
  if (!teamUsers) {
    alertService.show(false, i18nAlert('account.noAccount'))
    await auth.signOut()
    replacePageFromLocalStorage(history)
    return null
  }

  if (isAdminPage()) {
    const teamUser = teamUsers.filter((tu) => tu.user.admin)
    if (teamUser.length <= 0) {
      alertService.show(false, i18nAlert('account.noAdminAccount'))
      await auth.signOut()
      if (teamUsers.length > 0) {
        history.replace(makeLoginTeamPath(teamUsers[0].team.id))
      } else {
        replacePageFromLocalStorage(history)
      }
      return null
    }
    return teamUser[0]
  }

  const teamId = window.location.pathname.split('/')[2]
  if (!teamId) {
    throw Error('Team ID does not exist')
  }
  const teamUser = teamUsers.filter((tu) => tu.team.id === teamId)
  if (teamUser.length <= 0) {
    alertService.show(false, i18nAlert('account.noUserAccountForThisTeam'))
    await auth.signOut()
    replacePageFromLocalStorage(history)
    return null
  }
  return teamUser[0]
}
