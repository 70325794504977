import { IFindTeam, ITeam } from './interfaces/team'
import { IVideo } from './interfaces/video'

export const createLpBunnyIntroVideoHlsUrl = (team: IFindTeam) =>
  `https://${team.bunny_pull_zone_sub_domain}.b-cdn.net/${team.landing_page.intro_video_bunny_id}/playlist.m3u8`

export const createBunnyHlsUrl = (team: ITeam, video: IVideo) =>
  `https://${team.bunny.pullZoneSubDomain}.b-cdn.net/${video.bunny.videoId}/playlist.m3u8`

export const createLpBunnyFallbackMP4Urls = (team: IFindTeam) => {
  const base = `https://${team.bunny_pull_zone_sub_domain}.b-cdn.net/${team.landing_page.intro_video_bunny_id}`
  return [
    `${base}/play_720p.mp4`,
    `${base}/play_480p.mp4`,
    `${base}/play_360p.mp4`,
  ]
}

export const createBunnyFallbackMP4Urls = (team: ITeam, video: IVideo) => {
  const base = `https://${team.bunny.pullZoneSubDomain}.b-cdn.net/${video.bunny.videoId}`
  return [
    `${base}/play_720p.mp4`,
    `${base}/play_480p.mp4`,
    `${base}/play_360p.mp4`,
  ]
}

export const createLpBunnyIntroThumbnailUrl = (team: IFindTeam) =>
  team.landing_page.intro_video_thumb_url ||
  `https://${team.bunny_pull_zone_sub_domain}.b-cdn.net/${team.landing_page.intro_video_bunny_id}/thumbnail.jpg`

export const createVideoThumbnailUrl = (team: ITeam, video: IVideo) =>
  video.image || getBunnyThumbnailUrls(team, video)[0]

export const createBunnyAnimationUrl = (team: ITeam, video: IVideo) =>
  `https://${team.bunny.pullZoneSubDomain}.b-cdn.net/${video.bunny.videoId}/preview.webp`

export const createBunnyEmbedUrl = (team: ITeam, video: IVideo) =>
  `https://iframe.mediadelivery.net/embed/${team.bunny.videoLibraryId}/${video.bunny.videoId}`

export const createBunnyEmbedExampleCode = (team: ITeam, video: IVideo) =>
  `<div style="position: relative; padding-top: 56.25%;">
  <iframe src="${createBunnyEmbedUrl(team, video)}"
      loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;"
      allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;" allowfullscreen="true">
  </iframe>
</div>`

export const getBunnyThumbnailUrls = (team: ITeam, video: IVideo) => {
  const base = `https://${team.bunny.pullZoneSubDomain}.b-cdn.net/${video.bunny.videoId}`
  return [
    `${base}/thumbnail.jpg`,
    `${base}/thumbnail_1.jpg`,
    `${base}/thumbnail_2.jpg`,
    `${base}/thumbnail_3.jpg`,
    `${base}/thumbnail_4.jpg`,
    `${base}/thumbnail_5.jpg`,
  ]
}

export const isImageBunnyThumbnail = (team: ITeam, video: IVideo) =>
  video.image && getBunnyThumbnailUrls(team, video).includes(video.image)
