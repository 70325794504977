import { auth } from 'common/firebase'
import { makeLoggedInPath } from 'common/link_path'
import FormBody from 'components/organisms/Login/FormBody'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

const Login: React.FC = () => {
  const history = useHistory()
  const params = useParams<{ teamId?: string }>()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        history.replace(makeLoggedInPath(params.teamId))
      }
    })
    return unsubscribe
  }, [history, params])

  return <FormBody />
}

export default Login
