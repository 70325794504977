import { ICreator } from 'common/interfaces/creator'
import { standardModules } from 'common/quill'
import { null2str } from 'common/utils'
import QuillForm from 'components/atoms/Input/QuillForm'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import ThumbnailCropper from 'components/molecules/Admin/ThumbnailCropper'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  InputType,
  saveCreator,
  onChangeInput as serviceOnChangeInput,
} from 'services/admin/creator_form'

interface IFormBodyProps {
  isCreate: boolean
  creatorObj: ICreator
  history: History
}

const FormBody: React.FC<IFormBodyProps> = ({
  isCreate,
  creatorObj,
  history,
}: IFormBodyProps) => {
  const { t } = useTranslation('adminCreator')
  const { storeCache } = useContext(AuthContext)
  const [creator, setCreator] = useState<ICreator>(creatorObj)
  const [imageBlob, setImageBlob] = useState<Blob>()
  const [isImageDelete, setIsImageDelete] = useState<boolean>(false)
  const [isImageCropped, setIsImageCropped] = useState<boolean>(true)

  const onChangeInput = (type: InputType, e: any) =>
    serviceOnChangeInput(creator, setCreator, type, e)
  const save = () =>
    saveCreator(
      history,
      creator,
      imageBlob,
      isImageDelete,
      isImageCropped,
      storeCache,
      isCreate
    )

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="creatorNameForm">
              <InfoTooltip
                content={t('form.name.title')}
                tooltipContent={t('form.name.tips')}
                required
              />
            </Label>
            <Input
              id="creatorNameForm"
              value={creator.name}
              onChange={(e) => onChangeInput(InputType.NAME, e)}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <ThumbnailCropper
        type="creator"
        croppedData={imageBlob}
        setCroppedData={setImageBlob}
        handleIsImageCropped={setIsImageCropped}
        edit={{
          defaultImageUrl: creator.image,
          isImageDelete,
          setIsImageDelete,
        }}
      />
      <Row>
        <Col md="3">
          <FormGroup>
            <Label for="tagSortNumForm">
              <InfoTooltip
                content={t('form.sortNumber.title')}
                tooltipContent={t('form.sortNumber.tips')}
              />
            </Label>
            <Input
              id="tagSortNumForm"
              value={null2str(creator.sort_num)}
              onChange={(e) => onChangeInput(InputType.SORT_NUM, e)}
              type="number"
              step="1"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label>
              <InfoTooltip
                content={t('form.profile.title')}
                tooltipContent={t('form.profile.tips')}
              />
            </Label>
            <pre>
              <QuillForm
                value={creator.profile}
                onChange={(e) => onChangeInput(InputType.PROFILE, e)}
                modules={standardModules()}
              />
            </pre>
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {t('form.save')}
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
