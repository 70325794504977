import { ITest } from 'common/interfaces/test'
import QuillHtml from 'components/atoms/Quill/QuillHtml'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalBody } from 'reactstrap'
import { getTestPassedModalButtonUrl } from 'services/user/video_detail_test'
import './testpassedmodal.scss'

interface ITestPassedModalProps {
  test: ITest
  modal: {
    isOpen: boolean
    toggle: () => void
  }
}

const TestPassedModal: React.FC<ITestPassedModalProps> = ({
  test,
  modal,
}: ITestPassedModalProps) => {
  const { t } = useTranslation('userVideo')
  const { storeCache } = useContext(AuthContext)

  return (
    <Modal
      className="test-passed-modal"
      isOpen={modal.isOpen}
      toggle={modal.toggle}
      centered
    >
      <ModalBody>
        <div className="passed-title">
          {t('test.testPassedModal.congratulations')}
        </div>
        {test.passed.button_text !== null && test.passed.button_url !== null && (
          <div className="passed-btn-wrapper">
            <Button
              color="primary"
              href={getTestPassedModalButtonUrl(storeCache, test)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {test.passed.button_text}
            </Button>
          </div>
        )}
        <QuillHtml html={test.passed.note} />
      </ModalBody>
    </Modal>
  )
}

export default TestPassedModal
