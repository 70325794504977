import { ITestQuestion, ITestQuestionType } from 'common/interfaces/test'
import { hideToolbarModules } from 'common/quill'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, FormGroup, Input, Row } from 'reactstrap'
import { InputType } from 'reactstrap/es/Input'
import QuillForm from '../Input/QuillForm'
import './questioncard.scss'

interface ITitleRowProps {
  title: string
  onChange: (e: string) => void
  onRemove: () => void
}

interface IOptionProps {
  inputType: InputType
  inputName: string
  isChecked: boolean
  optionName: string
  onChangeChecked: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface IQuestionCardProps {
  question: ITestQuestion
  index: number
  onChange: {
    title: (e: string) => void
    optionChecked: (
      e: React.ChangeEvent<HTMLInputElement>,
      index: number
    ) => void
    optionName: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void
    explain: (e: React.ChangeEvent<HTMLInputElement>) => void
  }
  onRemove: (position: number) => void
}

/**
 * 設問順で使用するxボタンを含むタイトルの生成
 */
const TitleRow: React.FC<ITitleRowProps> = (props) => {
  const { t } = useTranslation('adminTest')
  return (
    <Row className="title-row">
      <Col xs="10" className="title-row__wrapper">
        <div className="title-row__title">
          {t('form.questions.card.question')}
        </div>
        <pre className="w-100 mb-0">
          <QuillForm
            className="title-row__quill-form"
            value={props.title}
            onChange={props.onChange}
            modules={hideToolbarModules()}
          />
        </pre>
      </Col>
      <Col xs="2" className="title-row__remove-button-wrapper">
        <i
          className="fas fa-times title-row__remove-button"
          onClick={props.onRemove}
        />
      </Col>
    </Row>
  )
}

/**
 * 設問順で使用する選択肢の生成
 */
const Option: React.FC<IOptionProps> = (props) => (
  <FormGroup check className="option">
    <Input
      type={props.inputType}
      name={props.inputName}
      checked={props.isChecked}
      onChange={props.onChangeChecked}
    />
    <Input
      className="input-text"
      type="text"
      value={props.optionName}
      onChange={props.onChangeName}
    />
  </FormGroup>
)

/**
 * 設問順で使用する各カードの生成
 */
export const QuestionCard: React.FC<IQuestionCardProps> = (props) => {
  const { t } = useTranslation('adminTest')
  return (
    <div className="placed-card">
      <TitleRow
        title={props.question.name}
        onChange={props.onChange.title}
        onRemove={() => props.onRemove(props.index)}
      />
      <div>
        {props.question.options.map((option, optionIndex) => {
          const isRadio = props.question.type === ITestQuestionType.RADIO
          const inputType = isRadio ? 'radio' : 'checkbox'
          const inputName = String(props.index)
          const isChecked = isRadio
            ? props.question.correct_index === optionIndex
            : (props.question.correct_index as number[]).includes(optionIndex)

          return (
            <Option
              key={optionIndex}
              inputType={inputType}
              inputName={inputName}
              isChecked={isChecked}
              optionName={option}
              onChangeChecked={(e) =>
                props.onChange.optionChecked(e, optionIndex)
              }
              onChangeName={(e) => props.onChange.optionName(e, optionIndex)}
            />
          )
        })}
      </div>
      <div className="explain-row">
        <div className="explain-row__explain">
          {t('form.questions.card.explain')}
        </div>
        <Input
          type="text"
          value={props.question.explain}
          onChange={props.onChange.explain}
        />
      </div>
    </div>
  )
}
