import { ColumnDef } from '@tanstack/react-table'
import { IInvite } from 'common/interfaces/invite'
import { IUser } from 'common/interfaces/user'
import { dateFormat } from 'common/times'
import { isAuthMethodEmail, userUid } from 'common/utils'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getUsersByInvitedId } from 'services/admin/user'

interface ITableBody {
  invite: IInvite
}

const TableBody: React.FC<ITableBody> = ({ invite }) => {
  const i18n = useTranslation('adminInvite').t('invitedUser.table', {
    returnObjects: true,
  })
  const { storeCache } = useContext(AuthContext)
  const [invitedUsers, setInvitedUsers] = useState<IUser[]>([])

  useEffect(() => {
    getUsersByInvitedId(setInvitedUsers, invite.id, storeCache)
  }, [invite, storeCache])

  const isAuthEmail = isAuthMethodEmail(storeCache.team!)
  const columns = useMemo<ColumnDef<IUser>[]>(
    () => [
      {
        header: 'ID',
        accessorFn: (u) => (!isAuthEmail && u.admin ? u.id : userUid(u.id)),
        meta: { hidden: isAuthEmail },
      },
      {
        header: i18n.email,
        accessorKey: 'email',
        meta: { hidden: !isAuthEmail },
      },
      {
        header: i18n.nickName,
        accessorFn: (u) => u.name ?? '',
      },
      {
        header: i18n.createdAt,
        accessorFn: (u) => dateFormat(u.created_at),
      },
    ],
    [isAuthEmail, i18n]
  )

  return useTanstackTable<IUser>(columns, invitedUsers)
}

export default TableBody
