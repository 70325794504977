import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Row } from 'reactstrap'

interface IButtonRight {
  nextPage?: string
  state?: unknown
  content: string
  disabled?: boolean
}

const ButtonRight: React.FC<IButtonRight> = (props) => {
  const history = useHistory()
  return (
    <Row className="justify-content-end mb-3 mr-1 mr-md-0">
      <Button
        onClick={() =>
          props.nextPage
            ? history.push(props.nextPage, props.state)
            : history.goBack()
        }
        color="default"
        size="sm"
        type="button"
        disabled={props.disabled}
      >
        {props.content}
      </Button>
    </Row>
  )
}

export default ButtonRight
