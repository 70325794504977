import {
  formInvite2Invite,
  formInvite2InviteByUser,
} from 'common/converter_invite'
import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import {
  IFormInvite,
  IInvite,
  IInviteAndApproved,
} from 'common/interfaces/invite'
import { IUser } from 'common/interfaces/user'
import { isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import React from 'react'
import {
  get,
  getForUser,
  remove,
  store,
  update,
} from 'repositories/store/firebase_invite'
import { getApprovedUserAndUnapprovedUserObjects } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 招待一覧を取得する
 */
export const getInvites = async (
  setData: React.Dispatch<React.SetStateAction<IInviteAndApproved[]>>,
  storeCache: IStoreCache
) => {
  if (!isLoggedIn(storeCache)) return

  const invites = await get(storeCache.team!)
  const result = await getApprovedUserAndUnapprovedUserObjects(
    storeCache.team!,
    invites
  )
  setData(result)
}

/**
 * 会員の招待一覧を取得する
 */
export const getForUserInvites = async (
  storeCache: IStoreCache,
  setData: React.Dispatch<React.SetStateAction<IInvite[]>>
) => {
  if (!isLoggedIn(storeCache)) return

  try {
    setData(await getForUser(storeCache.team!))
  } catch (error: any) {
    console.error(error)
    alertService.show(false, error.message)
  }
}

const invite = async (
  isCreate: boolean,
  formInvite: IFormInvite,
  setIsInviteSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  storeCache: IStoreCache,
  isAdmin: boolean
): Promise<void> => {
  const i18n = i18nAlert('invite.created', { returnObjects: true })
  if (!isLoggedIn(storeCache)) return

  if (!formInvite.name) {
    alertService.show(false, i18nValidation('input.title'))
    return
  }

  let result = false
  try {
    const invite = isAdmin
      ? formInvite2Invite(formInvite)
      : formInvite2InviteByUser(formInvite)
    if (isCreate) {
      await store(storeCache.team!, invite)
    } else {
      await update(storeCache.team!, invite)
    }
    result = true
  } catch (error) {
    console.log(error)
  }
  setIsInviteSuccess(result)

  const message = (() => {
    if (result) {
      return isCreate ? i18n.url : i18nAlert('updated.inviteCode')
    }
    return isCreate ? i18n.fail.url : i18nAlert('updated.fail.inviteCode')
  })()
  alertService.show(result, message)
}

/**
 * 管理者招待レコード作成/更新処理
 */
export const saveInviteAdministrator = async (
  isCreate: boolean,
  formInvite: IFormInvite,
  setIsInviteSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  storeCache: IStoreCache
) => invite(isCreate, formInvite, setIsInviteSuccess, storeCache, true)

/**
 * 会員招待レコード作成/更新処理
 */
export const saveInviteUser = async (
  isCreate: boolean,
  formInvite: IFormInvite,
  setIsInviteSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  storeCache: IStoreCache
) => invite(isCreate, formInvite, setIsInviteSuccess, storeCache, false)

/**
 * 「招待コード一覧へ」ボタンのonClick処理
 */
export const onClickInviteList = ({ replace }: History) =>
  replace(Routes.AdminInvite)

/**
 * 招待レコード削除処理
 */
export const removeInvite = (
  { replace }: History,
  invite: IInvite,
  unapprovedUsers: IUser[],
  storeCache: IStoreCache
) => {
  if (!isLoggedIn(storeCache)) return
  if (unapprovedUsers.length > 0) {
    alertService.show(
      false,
      i18nValidation('cantDelete.inviteCode.inApprovedPendingUser')
    )
    return
  }

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      await remove(storeCache.team!, invite)

      replace(Routes.AdminInvite)
      alertService.show(true, i18nAlert('deleted.message'))
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('cantDeleted'))
    }
  })
}
