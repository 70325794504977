import { ISubscriptionObject } from 'common/interfaces/subscription'
import { IFindTeam } from 'common/interfaces/team'
import {
  createLpBunnyFallbackMP4Urls,
  createLpBunnyIntroThumbnailUrl,
  createLpBunnyIntroVideoHlsUrl,
} from 'common/utils_bunny'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import QuillHtml from 'components/atoms/Quill/QuillHtml'
import HlsVideo from 'components/atoms/Video/HlsVideo'
import Footer from 'components/molecules/Footer'
import Header from 'components/molecules/TeamLanding/Header'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Container } from 'reactstrap'
import { findTeam } from 'services/team_landing'
import { calcUserColorCssProperties } from 'services/user/colors'
import './pagebody.scss'

const PageBody: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const [team, setTeam] = useState<IFindTeam | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [subscriptionObj, setSubscriptionObj] =
    useState<ISubscriptionObject | null>(null)

  useEffect(() => {
    findTeam(teamId, setTeam, setIsLoading, setSubscriptionObj)
  }, [teamId])

  if (isLoading) return <FullLoadingSpinner />
  if (!team) return null

  return (
    <div className="landing-page-body" style={calcUserColorCssProperties(team)}>
      <Header team={team} subscriptionObj={subscriptionObj} />

      <Container className="mb-4">
        {team.landing_page.banner_image && (
          <img
            className="banner-image"
            src={team.landing_page.banner_image.path}
            alt={team.name}
          />
        )}

        <div className="mt-3">
          <QuillHtml html={team.landing_page.site_overview} />
        </div>

        {team.landing_page.intro_video_bunny_id && (
          <HlsVideo
            className="intro-video"
            hlsUrl={createLpBunnyIntroVideoHlsUrl(team)}
            fallbackMP4Urls={createLpBunnyFallbackMP4Urls(team)}
            posterUrl={createLpBunnyIntroThumbnailUrl(team)}
          />
        )}
      </Container>

      <Footer team={team} />
    </div>
  )
}

export default PageBody
