import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ITaxRate } from 'common/interfaces/stripe/tax_rate'
import { isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { saveTaxRate } from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/tax_rate_form'

/**
 * `ITaxRate`の初期値設定
 */
export const initTaxRateForm = (): ITaxRate => {
  return {
    id: '',
    percentage: 0,
    display_name: '',
    active: true,
    created: 0,
  }
}

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  DISPLAY_NAME,
  PERCENTAGE,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  taxRate: ITaxRate,
  setProduct: React.Dispatch<React.SetStateAction<ITaxRate>>,
  type: InputType,
  e: React.ChangeEvent<HTMLInputElement>
) => {
  const { value } = e.target
  const num = Number(value)
  setProduct({
    ...taxRate,
    display_name:
      type === InputType.DISPLAY_NAME ? value : taxRate.display_name,
    percentage:
      type === InputType.PERCENTAGE
        ? num > 100
          ? 100
          : num
        : taxRate.percentage,
  })
}

/**
 * 税率レコード作成処理
 */
export const saveStripeTaxRate = async (
  history: History,
  taxRate: ITaxRate,
  storeCache: IStoreCache,
  isCreate: boolean
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    validateForm(taxRate)

    const { id, display_name, percentage } = taxRate
    await saveTaxRate(
      storeCache.team!.stripe.account_id,
      id,
      display_name,
      percentage,
      isCreate
    )

    history.push(Routes.AdminStripeTaxRate)
    alertService.show(true, i18nAlert('saved.stripe.taxRate'))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
