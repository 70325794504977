import { IAuthForm } from 'common/interfaces/setting/auth_form'
import { IUser } from 'common/interfaces/user'
import { null2str } from 'common/utils'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import {
  InputType,
  UpdateType,
  initAuthForm,
  onChangeInput as serviceOnChangeInput,
  updateUser,
} from 'services/admin/admin_setting'
import {
  getNewerAdministrators,
  removeAdministrator,
  moveZoomSetting as serviceMoveZoomSetting,
} from 'services/admin/administrator'

const FormBody: React.FC = () => {
  const { t } = useTranslation('adminSetting')
  const { storeCache, currentUser } = useContext(AuthContext)
  const history = useHistory()

  const [authForm, setAuthForm] = useState<IAuthForm>(
    initAuthForm(storeCache, currentUser)
  )
  const [users, setUsers] = useState<IUser[]>([])

  useEffect(() => {
    getNewerAdministrators(setUsers, storeCache)
  }, [storeCache])

  if (!storeCache.team) return null

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(authForm, setAuthForm, type, e)

  const update = (type: UpdateType) =>
    updateUser(type, authForm, storeCache, currentUser)

  const moveZoomSetting = () => serviceMoveZoomSetting(history)
  const removeSelf = () =>
    removeAdministrator(storeCache, storeCache.user!, history, true)

  return (
    <Form>
      <Row>
        <Col lg={6} className="mt-2 mb-4">
          <h2>{t('form.name')}</h2>
          <Container className="px-4 pt-2">
            <FormGroup>
              <Input
                value={authForm.name}
                onChange={(e) => onChangeInput(InputType.NAME, e)}
                type="text"
              />
            </FormGroup>
            <Col md="12" className="text-center">
              <Button
                onClick={() => update(UpdateType.NAME)}
                color="primary"
                type="button"
              >
                {t('form.save')}
              </Button>
            </Col>
          </Container>
        </Col>

        <Col lg={6} className="mt-2 mb-4">
          <h2>{t('form.notificationSetting.title')}</h2>
          <Container className="px-4 pt-2">
            <Row>
              <ToggleButton
                defaultChecked={
                  storeCache.user!.admin_notification_setting.encode_completed
                }
                label={t('form.notificationSetting.encodeFinished')}
                onChange={(e) =>
                  onChangeInput(InputType.NOTIFICATION_ENCODE_COMPLETE, e)
                }
              />
              <ToggleButton
                defaultChecked={
                  storeCache.user!.admin_notification_setting
                    .user_first_subscription_payment
                }
                label={t(
                  'form.notificationSetting.userFirstSubscriptionPayment'
                )}
                onChange={(e) =>
                  onChangeInput(
                    InputType.NOTIFICATION_USER_FIRST_SUBSCRIPTION_PAYMENT,
                    e
                  )
                }
              />
              <ToggleButton
                defaultChecked={
                  storeCache.user!.admin_notification_setting
                    .user_payment_completed
                }
                label={t('form.notificationSetting.userPayment')}
                onChange={(e) =>
                  onChangeInput(
                    InputType.NOTIFICATION_USER_PAYMENT_COMPLETED,
                    e
                  )
                }
              />
              <ToggleButton
                defaultChecked={
                  storeCache.user!.admin_notification_setting
                    .user_video_enquete_answered
                }
                label={t('form.notificationSetting.userVideoEnqueteAnswered')}
                onChange={(e) =>
                  onChangeInput(InputType.USER_VIDEO_ENQUETE_ANSWERED, e)
                }
              />
              <ToggleButton
                defaultChecked={
                  storeCache.user!.admin_notification_setting
                    .user_registration_completed
                }
                label={t('form.notificationSetting.userRegistration')}
                onChange={(e) =>
                  onChangeInput(
                    InputType.NOTIFICATION_USER_REGISTRATION_COMPLETED,
                    e
                  )
                }
              />
              <ToggleButton
                defaultChecked={
                  storeCache.user!.admin_notification_setting
                    .user_video_commented
                }
                label={t('form.notificationSetting.userVideoComment')}
                onChange={(e) =>
                  onChangeInput(InputType.NOTIFICATION_USER_VIDEO_COMMENTED, e)
                }
              />
              <ToggleButton
                defaultChecked={
                  storeCache.user!.admin_notification_setting
                    .user_subscription_removed
                }
                label={t('form.notificationSetting.userSubscriptionRemove')}
                onChange={(e) =>
                  onChangeInput(
                    InputType.NOTIFICATION_USER_SUBSCRIPTION_REMOVED,
                    e
                  )
                }
              />
              <ToggleButton
                defaultChecked={
                  storeCache.user!.admin_notification_setting
                    .user_account_deleted
                }
                label={t('form.notificationSetting.userAccountDeleted')}
                onChange={(e) =>
                  onChangeInput(InputType.NOTIFICATION_USER_ACCOUNT_DELETED, e)
                }
              />
              <Col md="12" className="text-center">
                <Button
                  onClick={() => update(UpdateType.ADMIN_NOTIFICATION_SETTING)}
                  color="primary"
                  type="button"
                >
                  {t('form.change')}
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row>
        <Col lg={6} className="mt-2 mb-4">
          <h2>{t('form.emailSetting.title')}</h2>
          <Container className="px-4 pt-2">
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label>{t('form.emailSetting.email')}</Label>
                  <Input
                    value={null2str(authForm.email)}
                    type="email"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="11">
                <FormGroup>
                  <Label>{t('form.emailSetting.newEmail')}</Label>
                  <Input
                    value={authForm.new_email}
                    onChange={(e) => onChangeInput(InputType.NEW_EMAIL, e)}
                    type="email"
                  />
                </FormGroup>
              </Col>
              <Col md="11">
                <FormGroup>
                  <Label>{t('form.emailSetting.currentPassword')}</Label>
                  <Input
                    onChange={(e) => onChangeInput(InputType.PASSWORD, e)}
                    type="password"
                  />
                </FormGroup>
              </Col>
              <Col md="12" className="text-center">
                <Button
                  onClick={() => update(UpdateType.EMAIL)}
                  color="primary"
                  type="button"
                >
                  {t('form.change')}
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>

        <Col lg={6} className="mt-2 mb-4">
          <h2>{t('form.passwordChange.title')}</h2>
          <Container className="px-4 pt-2">
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label>{t('form.passwordChange.currentPassword')}</Label>
                  <Input
                    onChange={(e) => onChangeInput(InputType.PASSWORD, e)}
                    type="password"
                  />
                </FormGroup>
              </Col>
              <Col md="11">
                <FormGroup>
                  <Label>{t('form.passwordChange.newPassword')}</Label>
                  <Input
                    onChange={(e) => onChangeInput(InputType.NEW_PASSWORD, e)}
                    type="password"
                  />
                </FormGroup>
              </Col>
              <Col md="11">
                <FormGroup>
                  <Label>{t('form.passwordChange.newPasswordConfirm')}</Label>
                  <Input
                    onChange={(e) =>
                      onChangeInput(InputType.NEW_PASSWORD_CONFIRM, e)
                    }
                    type="password"
                  />
                </FormGroup>
              </Col>
              <Col md="12" className="text-center">
                <Button
                  onClick={() => update(UpdateType.PASSWORD)}
                  color="primary"
                  type="button"
                >
                  {t('form.change')}
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <div className="mt-2 mb-4 d-flex">
        <Button onClick={moveZoomSetting} color="info" type="button">
          {t('zoomSetting.title')}
        </Button>
        {users.length > 1 && (
          <Button onClick={removeSelf} color="danger" type="button">
            {t('form.accountDelete')}
          </Button>
        )}
      </div>
    </Form>
  )
}

const ToggleButton: React.FC<{
  defaultChecked: boolean
  label: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
}> = ({ defaultChecked, label, onChange }) => {
  return (
    <Col md="11">
      <FormGroup row>
        <Col xs="12" md="9" lg="8">
          <Label>{label}</Label>
        </Col>
        <Col>
          <label className="custom-toggle">
            <input
              type="checkbox"
              onChange={onChange}
              defaultChecked={defaultChecked}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </Col>
      </FormGroup>
    </Col>
  )
}

export default FormBody
