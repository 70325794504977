import { IEnqueteType } from 'common/interfaces/enquete_customize'
import {
  FieldCustomizePublicStatus,
  IFieldCustomizeType,
} from 'common/interfaces/field_customize'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap'
import {
  ChangeAddCardType,
  onAddOption as serviceOnAddOption,
  onChangeOption as serviceOnChangeOption,
  onDeleteOption as serviceOnDeleteOption,
} from 'services/admin/field_customize'
import './addcard.scss'

type TAddCard = {
  title: string
  placeholder: string
  type: IFieldCustomizeType | IEnqueteType
  changeablePublicStatus?: boolean
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: IFieldCustomizeType | IEnqueteType,
    changeType: ChangeAddCardType
  ) => void
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  options?: string[]
  setOptions?: React.Dispatch<React.SetStateAction<string[]>>
}

const AddCard: React.FC<TAddCard> = (props) => {
  const { t } = useTranslation('adminCommon')

  const [publicStatus, setPublicStatus] = useState<FieldCustomizePublicStatus>(
    FieldCustomizePublicStatus.PUBLIC
  )

  return (
    <Col xs="12">
      <div className="add-card-options">
        <div>
          <div>{props.title}</div>
          <Input
            placeholder={props.placeholder}
            type="text"
            onChange={(e) =>
              props.onChange(e, props.type, ChangeAddCardType.TITLE)
            }
          />
          {props.options && props.setOptions && (
            <GenerateOptions
              type={props.type}
              options={props.options}
              setOptions={props.setOptions}
            />
          )}
        </div>
        <div className="options">
          {props.changeablePublicStatus && (
            <div className="mt-2">
              {[
                [FieldCustomizePublicStatus.PUBLIC, t('addCard.public')],
                [FieldCustomizePublicStatus.ONLY_ADMIN, t('addCard.onlyAdmin')],
              ].map(([status, text]) => (
                <FormGroup key={status} check className="mt-2">
                  <Label check>
                    <Input
                      onChange={(e) => {
                        setPublicStatus(
                          e.target.value as FieldCustomizePublicStatus
                        )
                        props.onChange(
                          e,
                          props.type,
                          ChangeAddCardType.PUBLIC_STATUS
                        )
                      }}
                      type="radio"
                      value={status}
                      checked={publicStatus === status}
                    />
                    {text}
                  </Label>
                </FormGroup>
              ))}
            </div>
          )}
          <div className="mt-2">
            <h6>{t('addCard.required')}</h6>
            <label className="custom-toggle">
              <input
                type="checkbox"
                onChange={(e) =>
                  props.onChange(e, props.type, ChangeAddCardType.REQUIRED)
                }
                disabled={
                  publicStatus === FieldCustomizePublicStatus.ONLY_ADMIN
                }
              />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
          </div>
          <div className="option-add-button">
            <Button
              className="add-button"
              onClick={props.onClick}
              color="primary"
              size="sm"
              type="button"
            >
              {t('addCard.add')}
            </Button>
          </div>
        </div>
      </div>
    </Col>
  )
}

interface IGenerateOptions {
  type: IFieldCustomizeType | IEnqueteType
  options: string[]
  setOptions: React.Dispatch<React.SetStateAction<string[]>>
}

const GenerateOptions: React.FC<IGenerateOptions> = (props) => {
  const { t } = useTranslation('adminCommon')

  const onAddOption = () => serviceOnAddOption(props.options, props.setOptions)

  const onDeleteOption = (key: number) =>
    serviceOnDeleteOption(props.options, props.setOptions, key)

  const onChangeOption = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => serviceOnChangeOption(props.options, props.setOptions, e, key)

  const button = (content: string, onClick: () => void) => (
    <Button onClick={() => onClick()} color="primary" outline>
      {content}
    </Button>
  )

  switch (props.type) {
    case IFieldCustomizeType.RADIO:
    case IFieldCustomizeType.PULLDOWN:
    case IEnqueteType.RADIO:
    case IEnqueteType.CHECKBOX:
      return (
        <>
          <div className="mt-2">{t('addCard.optionsList.title')}</div>
          {props.options.map((option, key) => (
            <InputGroup className="mb-3" key={key}>
              <Input
                placeholder={t('addCard.optionsList.placeholder')}
                type="text"
                value={option}
                onChange={(e) => onChangeOption(e, key)}
              />
              <InputGroupAddon addonType="append">
                {button('-', () => onDeleteOption(key))}
              </InputGroupAddon>
            </InputGroup>
          ))}
          <div className="text-right">{button('+', () => onAddOption())}</div>
        </>
      )
    default:
      return <></>
  }
}

export default AddCard
