import { InvoiceActionType } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { i18nAlert, i18nItemSelect } from 'i18n/i18n'

export enum InputType {
  NAME,
  EMAIL,
}
export interface IInvoicePayment {
  name: string
  email: string
}

/**
 * `IInvoicePayment`初期値
 */
export const initInvoicePayment = (
  storeCache: IStoreCache
): IInvoicePayment => ({
  name: storeCache.user?.name || '',
  email: storeCache.user?.email || '',
})

/**
 * `onChange`イベントハンドラ
 */
export const onChangeInput = (
  state: IInvoicePayment,
  setState: React.Dispatch<React.SetStateAction<IInvoicePayment>>,
  type: InputType,
  e: React.ChangeEvent<HTMLInputElement>
) =>
  setState({
    ...state,
    name: type === InputType.NAME ? e.target.value : state.name,
    email: type === InputType.EMAIL ? e.target.value : state.email,
  })

/**
 * モーダル内の内容/アクションボタン文言生成処理
 */
export const generateContents = (
  storeCache: IStoreCache,
  actionType: InvoiceActionType | undefined
): string[] => {
  if (!actionType) actionType = InvoiceActionType.DEFAULT
  switch (actionType) {
    case InvoiceActionType.DEFAULT:
      return storeCache.user?.admin
        ? [
            i18nAlert('modal.canJoinPlansAfterPaidToInvoiceAccountAdmin'),
            i18nItemSelect('invoicePaymentModal.buttons.submitInvoice'),
          ]
        : [
            i18nAlert('modal.canJoinPlansAfterPaidToInvoiceAccountUser'),
            i18nItemSelect('invoicePaymentModal.buttons.submitInvoice'),
          ]
    case InvoiceActionType.RESEND:
      return [
        i18nAlert('modal.confirm.reissueInvoice'),
        i18nItemSelect('invoicePaymentModal.buttons.resendInvoice'),
      ]
    case InvoiceActionType.CANCEL:
      return [
        i18nAlert('modal.confirm.issueNewInvoiceAfterCancelIssuedInvoice'),
        i18nItemSelect(
          'invoicePaymentModal.buttons.issueInvoiceAfterCancelIssuedInvoice'
        ),
      ]
  }
}

/**
 * モーダル内のアクション処理の派生処理
 */
export const invoiceAction = async (
  actionType: InvoiceActionType,
  defaultAction: () => void,
  resendInvoice: () => void,
  cancelPayment: (() => void) | undefined
): Promise<void> => {
  switch (actionType) {
    case InvoiceActionType.DEFAULT:
      defaultAction()
      break
    case InvoiceActionType.RESEND:
      resendInvoice()
      break
    case InvoiceActionType.CANCEL:
      cancelPayment === undefined ? undefined : cancelPayment()
  }
}
