import ChooseGenre from 'components/molecules/User/ChooseGenre/ChooseGenre'
import SearchVideoInput from 'components/molecules/User/SearchVideoInput/SearchVideoInput'
import TypeContentsList from 'components/organisms/User/TypeContentsList/TypeContentsList'
import React from 'react'
import { Container } from 'reactstrap'
import './typecontents.scss'

const TypeContents: React.FC = () => {
  return (
    <div className="type-contents">
      <Container>
        <ChooseGenre type="content_type" />
        <ChooseGenre type="category" />
        <ChooseGenre type="tag" />
        <ChooseGenre type="creator" />
        <ChooseGenre type="playStatus" />
        <SearchVideoInput />
      </Container>
      <hr />
      <Container className="type-content-list-container">
        <TypeContentsList />
      </Container>
    </div>
  )
}

export default TypeContents
