import {
  ILogAllUserVideoCount,
  ILogUserVideoCount,
} from 'common/interfaces/analytics'
import { ILog } from 'common/interfaces/log'
import { IUser } from 'common/interfaces/user'
import { isAuthMethodEmail, userUid } from 'common/utils'
import { isLogEmpty } from 'common/utils_log'
import { useTanstackTableWithCsvExport } from 'components/atoms/Table/CreateTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'reactstrap'
import {
  allUserLogs2LogAllUserVideoCounts,
  getAllUserLogs,
  getAllUserVideoCountColumns,
  getUsers,
  logUserVideoCountColumns,
  userLog2LogUserVideoCounts,
} from 'services/admin/analytics_log_user_videos'

const AnalyticsLogUserVideos: React.FC = () => {
  const { t } = useTranslation('adminAnalytics')
  const { storeCache } = useContext(AuthContext)
  const [users, setUsers] = useState<IUser[]>([])
  const [currentUserId, setCurrentUserId] = useState<string>('')
  const [allUserLogs, setAllUserLogs] = useState<ILog[]>([])

  useEffect(() => {
    getUsers(storeCache, setUsers)
    getAllUserLogs(storeCache, setAllUserLogs)
  }, [storeCache])

  if (!storeCache.team) return null

  const userOptions = users.map((user) => (
    <option key={user.id} value={user.id}>
      {user.email || userUid(user.id)}
    </option>
  ))
  const targetUserLog = allUserLogs.find((l) => l.user_id === currentUserId)

  return (
    <>
      <AllDataTable allUsers={users} allUserLogs={allUserLogs} />
      <Input onChange={(e) => setCurrentUserId(e.target.value)} type="select">
        <option>{t('pleaseSelect')}</option>
        {userOptions}
      </Input>
      <div className="mt-5">
        {currentUserId && targetUserLog && isLogEmpty(targetUserLog) && (
          <>{t('noHistory')}</>
        )}
        {currentUserId && targetUserLog && !isLogEmpty(targetUserLog) && (
          <Table targetUserLog={targetUserLog} />
        )}
      </div>
    </>
  )
}

const AllDataTable: React.FC<{ allUsers: IUser[]; allUserLogs: ILog[] }> = ({
  allUsers,
  allUserLogs,
}) => {
  const { storeCache } = useContext(AuthContext)
  const isAuthEmail = isAuthMethodEmail(storeCache.team!)

  const columns = getAllUserVideoCountColumns(allUsers, storeCache.team!)
  const data = allUserLogs2LogAllUserVideoCounts(
    storeCache.videos,
    allUsers,
    allUserLogs,
    isAuthEmail
  )

  return useTanstackTableWithCsvExport<ILogAllUserVideoCount>(
    columns,
    data,
    'analytics_log_user_videos.csv',
    { hideTable: true }
  )
}

const Table: React.FC<{ targetUserLog: ILog }> = ({ targetUserLog }) => {
  const { videos } = useContext(AuthContext).storeCache
  const data = useMemo<ILogUserVideoCount[]>(
    () => userLog2LogUserVideoCounts(videos, targetUserLog),
    [videos, targetUserLog]
  )

  return useTanstackTableWithCsvExport<ILogUserVideoCount>(
    logUserVideoCountColumns(),
    data,
    'analytics_log_user_videos.csv'
  )
}

export default AnalyticsLogUserVideos
