import { IStoreCache } from 'common/interfaces/auth_provider'
import { IZoomSignature } from 'common/interfaces/broadcast'
import { ILive, LivePlatform } from 'common/interfaces/live'
import { isLoggedIn } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { getZoomSignature as apiGetZoomSignature } from 'repositories/functions/functions_broadcast'
import { alertService } from 'services/alert'
import { isLiveStarted } from './live'

export enum ZOOM_TYPE {
  PROMPT,
  EMBED,
  WINDOW,
}

/**
 * メールアドレスが必須か否かを返却
 */
export const isEmailRequired = (
  storeCache: IStoreCache,
  live: ILive
): boolean =>
  !storeCache.user?.email && live.platform === LivePlatform.ZOOM_WEBINAR

/**
 * Zoomミーティング用のSignatureを取得
 */
export const getZoomSignature = async (
  storeCache: IStoreCache,
  live: ILive | undefined,
  setZoomSignature: React.Dispatch<React.SetStateAction<IZoomSignature | null>>
): Promise<void> => {
  if (!isLoggedIn(storeCache) || !live || !isLiveStarted(live)) return

  const zoomSignature = await apiGetZoomSignature(
    storeCache.user?.id || 'guest',
    storeCache.team?.id,
    live.meeting_number
  )
  if (!zoomSignature) {
    alertService.show(false, i18nAlert('zoom.failed.initialize'))
    return
  }
  setZoomSignature(zoomSignature)
}

/**
 * Zoomミーティング用htmlのPathを取得
 */
export const getZoomMeetingPath = (
  storeCache: IStoreCache,
  live: ILive,
  zoomSignature: IZoomSignature,
  userEmail: string,
  setMeetingPath: React.Dispatch<React.SetStateAction<string | null>>
): void => {
  const params = new URLSearchParams({
    signature: zoomSignature.signature,
    meetingNumber: live.meeting_number,
    userName: storeCache.user
      ? storeCache.user.name || storeCache.user.email.split('@')[0]
      : 'guest',
    userEmail,
    clientId: zoomSignature.clientId,
    passWord: live.meeting_password,
  })

  const url = `/broadcasts/live/zoom.html?${params.toString()}`
  setMeetingPath(url)
}
