import { Routes, ViewType } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import Footer from 'components/molecules/Footer'
import Header from 'components/molecules/TeamLanding/Header'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { calcUserColorCssProperties } from 'services/user/colors'
import ItemSelectContent from 'template/admin/ItemSelectContent'
import CreatorDetail from 'template/user/CreatorDetail'
import Genres from 'template/user/Genres'
import Index from 'template/user/Index'
import LiveDetail from 'template/user/LiveDetail'
import PlaylistMenu from 'template/user/PlaylistMenu'
import TypeContents from 'template/user/TypeContents'
import VideoDetail from 'template/user/VideoDetail'

const Catalog: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const [viewType, setViewType] = useState<ViewType>(ViewType.LOADING)

  useEffect(() => {
    if (storeCache.isLoading) {
      setViewType(ViewType.LOADING)
    } else if (storeCache.team === null) {
      setViewType(ViewType.GONE)
    } else {
      setViewType(ViewType.VISIBLE)
    }
  }, [setViewType, storeCache])

  const { replace } = useHistory()
  switch (viewType) {
    case ViewType.GONE:
      replace(Routes.Root)
      return null
    case ViewType.VISIBLE:
      return catalogRouteSwitch(storeCache)
    default:
      return <FullLoadingSpinner />
  }
}

const catalogRouteSwitch = (storeCache: IStoreCache) => {
  return (
    <div style={calcUserColorCssProperties(storeCache.team)}>
      <Header
        team={storeCache.team!}
        subscriptionObj={storeCache.subscriptionObj}
        isCatalogPage
      />
      <Switch>
        <Route
          path={Routes.Catalog}
          component={() => <Index isCatalogPage />}
          exact
        />
        <Route
          path={[
            Routes.CatalogContentCategories,
            Routes.CatalogContentTags,
            Routes.CatalogContentCreators,
          ]}
          component={() => <Genres />}
          exact
        />
        <Route
          path={Routes.CatalogItemSelectContent}
          component={() => <ItemSelectContent />}
          exact
        />
        <Route
          path={[
            Routes.CatalogVideosPopular,
            Routes.CatalogSearchVideos,
            Routes.CatalogContentsNewer,
            Routes.CatalogCombineContents,
          ]}
          component={() => <TypeContents />}
          exact
        />
        <Route
          path={[Routes.CatalogPlaylistVideos, Routes.CatalogEventContents]}
          component={() => <PlaylistMenu />}
          exact
        />
        <Route
          path={[Routes.CatalogVideoDetail, Routes.CatalogContentVideoDetail]}
          component={() => <VideoDetail isCatalogPage />}
          exact
        />
        <Route
          path={[Routes.CatalogLiveDetail, Routes.CatalogContentLiveDetail]}
          component={() => <LiveDetail />}
          exact
        />
        <Route
          path={Routes.CatalogCreatorDetail}
          component={() => <CreatorDetail />}
          exact
        />
      </Switch>
      <Footer />
    </div>
  )
}

export default Catalog
