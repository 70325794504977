import React from 'react'
import { Button } from 'reactstrap'

interface IFooterButtons {
  leftContent: string
  rightContent: string
  leftOnClick: () => void
  rightOnClick: () => void
  leftColor?: string
  rightColor?: string
}

const FooterButtons: React.FC<IFooterButtons> = ({
  leftContent,
  rightContent,
  leftOnClick,
  rightOnClick,
  leftColor,
  rightColor,
}: IFooterButtons) => (
  <div className="mt-5 d-flex justify-content-between">
    <Button color={leftColor ?? 'primary'} type="button" onClick={leftOnClick}>
      {leftContent}
    </Button>
    <Button
      color={rightColor ?? 'secondary'}
      type="button"
      onClick={rightOnClick}
    >
      {rightContent}
    </Button>
  </div>
)

export default FooterButtons
