import { Routes } from 'common/enums'
import { auth } from 'common/firebase'
import FormBody from 'components/organisms/SamlLogin/FormBody'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const SamlLogin: React.FC = () => {
  const history = useHistory()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        history.replace(Routes.LoginAdmin)
      }
    })
    return unsubscribe
  }, [history])

  return <FormBody />
}

export default SamlLogin
