import { Routes } from 'common/enums'
import { formatUNIXToDate, getDateDiff } from 'common/times'
import UploadProgressItem from 'components/atoms/Header/UploadProgressItem'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { repaymentInvoice } from 'services/admin/stripe/invoice'
import {
  IUploadProgress,
  uploadProgressService,
} from 'services/admin/upload_progress'
import { cancelVideoUpload } from 'services/admin/video'
import './header.scss'

const Header: React.FC = () => {
  const { t } = useTranslation('adminCommon')
  const { storeCache } = useContext(AuthContext)
  const [uploadTasks, setUploadTasks] = useState<IUploadProgress[]>([])
  const { pathname } = useLocation()

  useEffect(() => {
    const subscription = uploadProgressService
      .onChange()
      .subscribe(setUploadTasks)

    return () => subscription.unsubscribe()
  }, [])

  if (!storeCache.team) return null

  const messageElement = (() => {
    if (pathname === Routes.AdminItemSelect) return <BankPaymentNavbar />
    if (!storeCache.subscriptionObj) return <TrialPeriodNavbar />

    const { subscription } = storeCache.subscriptionObj
    if (subscription.metadata.is_paid === 'false') {
      return <SentInvoiceNavbar />
    }
    if (!['active', 'trialing'].includes(subscription.status)) {
      const repayment = () => repaymentInvoice(subscription)
      return <RepaymentNavbar onClick={repayment} />
    }
    if (subscription.cancel_at_period_end) {
      return (
        <RestartNavbar
          cancelAt={formatUNIXToDate(
            subscription.cancel_at,
            t('header.subscriptionCancelDateFormat')
          )}
        />
      )
    }
  })()

  return (
    <div className="admin-header">
      {messageElement}
      {uploadTasks.length > 0 && (
        <Container fluid className="upload-progress-container">
          <h6 className="text-muted">
            {t('header.uploadProgress.count', { count: uploadTasks.length })}
          </h6>
          <Row className="upload-progress-row">
            {uploadTasks.map((up) => (
              <Col key={up.videoId} xs="6" sm="4" xl="3">
                <UploadProgressItem
                  uploadProgress={up}
                  onRemove={() => cancelVideoUpload(up, storeCache)}
                />
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </div>
  )
}

const headerContainer = (
  message: string,
  buttonText?: string,
  buttonOnClick?: () => void
): JSX.Element => (
  <Container fluid className="header-message-container">
    <div className="message-wrapper">
      <span>
        <i className="fas fa-exclamation-triangle icon-exclamation" />
      </span>
      {message}
    </div>
    {buttonText && (
      <div className="clickable-element" onClick={buttonOnClick}>
        {buttonText}
      </div>
    )}
  </Container>
)

const TrialPeriodNavbar: React.FC = () => {
  const { t } = useTranslation('adminCommon')
  const history = useHistory()
  const { team } = useContext(AuthContext).storeCache

  return headerContainer(
    t('header.navbar.trialPeriod.expireDate.message', {
      expireDate: getDateDiff(team!.expire_date),
    }),
    t('header.navbar.confirmPlan'),
    () => history.push(Routes.AdminItemSelect)
  )
}

const SentInvoiceNavbar: React.FC = () => {
  const { t } = useTranslation('adminCommon')
  const history = useHistory()
  const { subscription } = useContext(AuthContext).storeCache.subscriptionObj!

  return headerContainer(
    t('header.navbar.trialPeriod.expireDate.sentInvoice', {
      expireDate: subscription.created,
    }),
    t('header.navbar.resendInvoice'),
    () => history.push(Routes.AdminItemSelect)
  )
}

const RepaymentNavbar: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation('adminCommon')
  return headerContainer(
    t('header.navbar.subscription.payment.update'),
    t('header.navbar.subscription.payment.retry'),
    onClick
  )
}

const RestartNavbar: React.FC<{ cancelAt: string }> = ({ cancelAt }) => {
  const { t } = useTranslation('adminCommon')
  return headerContainer(
    t('header.navbar.subscription.canceledAccount', { cancelAt }),
    t('header.navbar.contactToStaff'),
    () => {
      window.location.href = 'https://onestream.channel.io/'
    }
  )
}

const BankPaymentNavbar: React.FC = () => {
  const { t } = useTranslation('adminCommon')

  return headerContainer(
    t('header.navbar.bankPayment'),
    t('header.navbar.confirmDetail'),
    () => {
      window.open(
        'https://one-stream.oopy.io/61172249-780c-45cc-8052-98ca57b300d0',
        '_blank'
      )
    }
  )
}

export default Header
