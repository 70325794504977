import { ITestAndAnswer } from 'common/interfaces/test_answer'
import H1 from 'components/atoms/Head/H1'
import TableBody from 'components/organisms/Admin/TestAnswerDetail/TableBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'

const TestAnswerDetail: React.FC = () => {
  const { t } = useTranslation('adminTest')
  const { test, answer } = useLocation<ITestAndAnswer>().state

  return (
    <>
      <H1 title={t('answerDetail.title')} />

      <Container fluid>
        <TableBody test={test} answer={answer} />
      </Container>
    </>
  )
}

export default TestAnswerDetail
