import classNames from 'classnames'
import { IFromTo } from 'common/interfaces/time'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Input, Label, Row } from 'reactstrap'
import { DatePeriod } from 'services/admin/analytics'

type UseState<T> = [T, React.Dispatch<React.SetStateAction<T>>]

interface IAnalyticsRangeSelector {
  className?: string
  children?: React.ReactNode
  monthOptions: JSX.Element[]
  selectedDatePeriod: UseState<DatePeriod>
  selectedMonth: UseState<string>
  fromTo: UseState<IFromTo>
}

const AnalyticsRangeSelector: React.FC<IAnalyticsRangeSelector> = (props) => {
  const { t } = useTranslation('adminAnalytics')

  const setFrom = (e: React.ChangeEvent<HTMLInputElement>) =>
    props.fromTo[1]({ ...props.fromTo[0], from: e.target.value })
  const setTo = (e: React.ChangeEvent<HTMLInputElement>) =>
    props.fromTo[1]({ ...props.fromTo[0], to: e.target.value })

  return (
    <div className={props.className}>
      <Row className="justify-content-end">
        {props.children && <Col md="9">{props.children}</Col>}
        <Col
          md="3"
          className={classNames({
            'mt-2 mt-md-0': props.children,
          })}
        >
          <Input
            value={props.selectedDatePeriod[0]}
            onChange={(e) =>
              props.selectedDatePeriod[1](e.target.value as DatePeriod)
            }
            type="select"
          >
            <option value={DatePeriod.DAYS_7}>{t('period.sevenDays')}</option>
            <option value={DatePeriod.DAYS_30}>{t('period.thirtyDays')}</option>
            <option value={DatePeriod.DAYS_90}>{t('period.ninetyDays')}</option>
            <option value={DatePeriod.EVERY_MONTH}>{t('period.month')}</option>
            <option value={DatePeriod.SPECIFIED_PERIOD}>
              {t('period.specifiedPeriod')}
            </option>
          </Input>
          {props.selectedDatePeriod[0] === DatePeriod.EVERY_MONTH && (
            <Input
              value={props.selectedMonth[0]}
              onChange={(e) => props.selectedMonth[1](e.target.value)}
              type="select"
              className="mt-2"
            >
              {props.monthOptions}
            </Input>
          )}
        </Col>
      </Row>
      {props.selectedDatePeriod[0] === DatePeriod.SPECIFIED_PERIOD && (
        <Row className="justify-content-end mt-2">
          <Col md="3">
            <Label>FROM</Label>
            <Input
              onChange={setFrom}
              type="date"
              value={props.fromTo[0].from}
            />
          </Col>
          <Col md="3">
            <Label>TO</Label>
            <Input onChange={setTo} type="date" value={props.fromTo[0].to} />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default AnalyticsRangeSelector
