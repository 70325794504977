import { IStripe } from 'common/interfaces/stripe'
import { get } from 'repositories/store/firebase_stripe'
import { alertService } from 'services/alert'

/**
 * Stripe初期値設定
 */
export const initStripe = (): IStripe => ({
  tax_rates: '',
  publishableKey: '',
  product_id: '',
  basic_price_id: [],
  pro_price_id: [],
  business_price_id: [],
  enterprise_price_id: [],
  enterprise_400_price_id: [],
  enterprise_500_price_id: [],
  enterprise_600_price_id: [],
  enterprise_1000_price_id: [],
  enterprise_1500_price_id: [],
  enterprise_2000_price_id: [],
  enterprise_2500_price_id: [],
  enterprise_3000_price_id: [],
  vimeo_price_id: [],
})

/**
 * Stripeドキュメントを取得しStateに格納
 */
export const getStripe = async (
  setStripe: React.Dispatch<React.SetStateAction<IStripe>>
): Promise<void> => {
  try {
    setStripe(await get())
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
