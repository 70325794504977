import { IStoreCache } from './interfaces/auth_provider'
import { ICategory } from './interfaces/category'
import { ICreator } from './interfaces/creator'
import { IFilelist } from './interfaces/filelist'
import { IGroup } from './interfaces/group'
import { ILive } from './interfaces/live'
import { IPrice } from './interfaces/stripe/price'
import { IProduct } from './interfaces/stripe/product'
import { ITag } from './interfaces/tag'
import { IVideo } from './interfaces/video'
import { createContents, generatePriceLabel } from './utils'

/**
 * find cached category
 */
export const findCachedCategory = (
  { categories }: IStoreCache,
  findId: string
): ICategory | null => categories.find((c) => c.id === findId) ?? null

/*
 * find cached tag
 */
export const findCachedTag = (
  { tags }: IStoreCache,
  findId: string
): ITag | null => tags.find((t) => t.id === findId) ?? null

/**
 * find cached tags
 */
export const findCachedTags = (
  { tags }: IStoreCache,
  findIds: string[]
): (ITag | null)[] => {
  const result: (ITag | null)[] = []
  findIds.forEach((id) => result.push(tags.find((t) => t.id === id) ?? null))
  return result
}

/**
 * find cached tags not null
 */
export const findCachedTagsNonNull = (
  storeCache: IStoreCache,
  findIds: string[]
): ITag[] =>
  findCachedTags(storeCache, findIds).filter((t): t is ITag => t !== null)

/**
 * find cached filelists
 */
export const findCachedFilelists = (
  { filelists }: IStoreCache,
  findIds: string[]
): (IFilelist | null)[] => {
  const result: (IFilelist | null)[] = []
  findIds.forEach((id) =>
    result.push(filelists.find((f) => f.id === id) ?? null)
  )
  return result
}

/**
 * find cached filelists not null
 */
export const findCachedFilelistsNonNull = (
  storeCache: IStoreCache,
  findIds: string[]
): IFilelist[] =>
  findCachedFilelists(storeCache, findIds).filter(
    (t): t is IFilelist => t !== null
  )

/**
 * find cached creator
 */
export const findCachedCreator = (
  { creators }: IStoreCache,
  findId: string
): ICreator | null => creators.find((c) => c.id === findId) ?? null

/**
 * find cached groups
 */
export const findCachedGroups = (
  { groups }: IStoreCache,
  findIds: string[]
): (IGroup | null)[] => {
  const result: (IGroup | null)[] = []
  findIds.forEach((id) => result.push(groups.find((g) => g.id === id) ?? null))
  return result
}

/**
 * find cached groups non null
 */
export const findCachedGroupsNonNull = (
  storeCache: IStoreCache,
  findIds: string[]
): IGroup[] =>
  findCachedGroups(storeCache, findIds).filter((t): t is IGroup => t !== null)

/*
 * find cached videos and lives
 */
export const findCachedVideosAndLives = (
  { videos, lives }: IStoreCache,
  findIds: string[]
): (IVideo | null)[] => {
  const result: (IVideo | null)[] = []
  const contents = createContents(videos, lives) as IVideo[]
  findIds.forEach((id) =>
    result.push(contents.find((c) => c.id === id) ?? null)
  )
  return result
}

/**
 * find cached videos and lives not null
 */
export const findCachedVideoAndLivesNonNull = (
  storeCache: IStoreCache,
  findIds: string[]
): IVideo[] =>
  findCachedVideosAndLives(storeCache, findIds).filter(
    (t): t is IVideo => t !== null
  )

/**
 * get default products
 */
export const getDefaultProducts = (
  product_ids: string[],
  products: IProduct[]
) =>
  product_ids
    .map((id) => products.find((p) => p.id === id) ?? null)
    .filter((p): p is IProduct => p !== null)
    .map((p) => ({ value: p.id, label: p.name }))

/**
 * get default prices
 */
export const getDefaultPrices = (
  storeCache: IStoreCache,
  { price_ids }: IVideo | ILive
) =>
  price_ids
    .map((id) => storeCache.prices.find((p) => p.id === id) ?? null)
    .filter((p): p is IPrice => p !== null)
    .map((p) => ({ value: p.id, label: generatePriceLabel(p, p.currency) }))

/*
 * find product
 */
const findProducts = (
  products: IProduct[],
  findIds: string[]
): (IProduct | null)[] =>
  findIds.map((id) => products.find((p) => p.id === id) ?? null)

/**
 * find productss non null
 */
export const findProductsNonNull = (
  products: IProduct[],
  findIds: string[]
): IProduct[] =>
  findProducts(products, findIds).filter((t): t is IProduct => t !== null)

/*
 * find prices
 */
const findPrices = (prices: IPrice[], findIds: string[]): (IPrice | null)[] =>
  findIds.map((id) => prices.find((p) => p.id === id) ?? null)

/**
 * find prices non null
 */
export const findPricesNonNull = (
  { prices }: IStoreCache,
  findIds: string[]
): IPrice[] =>
  findPrices(prices, findIds).filter((t): t is IPrice => t !== null)
