export enum Routes {
  Root = '/',
  TeamLanding = '/lp/:teamId',
  Register = '/register',
  LoginAdmin = '/login',
  LoginUser = '/login/:teamId',
  SamlLogin = '/login_saml',
  LoggedInAdmin = '/logged_in',
  LoggedInUser = '/logged_in/:teamId',
  PasswordResetRequest = '/password_reset_request',
  JoinAdmin = '/join/admin/:inviteId',
  JoinUser = '/join/user/:inviteId',
  InvalidInviteCode = '/invalid_invite_code',
  EntryAdmin = '/entry',
  EntryUser = '/entry/:teamId',
  ItemSelect = '/item_select/:teamId',
  AdminItemSelect = '/admin_item_select',
  AdminItemChange = '/admin_item_select/:purchasedPriceId',
  ItemSelectContent = '/item_select_content',
  CreditCardUpdate = '/credit_card_update',
  Unapproved = '/unapproved',
  AnswerToEnqueteUser = '/answer_to_enquete/:teamId',
  ChangePassword = '/change_password',
  Admin = '/admin',
  AdminVideo = '/admin/video',
  AdminVideoCreate = '/admin/video_create',
  AdminVideoReCreate = '/admin/video_recreate',
  AdminVideoEdit = '/admin/video_edit',
  AdminPlaylist = '/admin/playlist',
  AdminPlaylistCreate = '/admin/playlist_create',
  AdminPlaylistEdit = '/admin/playlist_edit',
  AdminLive = '/admin/live',
  AdminLiveCreate = '/admin/live_create',
  AdminLiveEdit = '/admin/live_edit',
  AdminEvent = '/admin/event',
  AdminEventCreate = '/admin/event_create',
  AdminEventEdit = '/admin/event_edit',
  AdminFilelist = '/admin/filelist',
  AdminFilelistCreate = '/admin/filelist_create',
  AdminNews = '/admin/news',
  AdminNewsCreate = '/admin/news_create',
  AdminNewsEdit = '/admin/news_edit',
  AdminSendEmail = '/admin/send_email',
  AdminSendEmailCreate = '/admin/send_email_create',
  AdminSendEmailEdit = '/admin/send_email_edit',
  AdminSendEmailLog = '/admin/send_email_log',
  AdminMailTemplate = '/admin/mail_template',
  AdminMailTemplateEdit = '/admin/mail_template_edit',
  AdminEnquete = '/admin/enquete',
  AdminEnqueteCreate = '/admin/enquete_create',
  AdminEnqueteEdit = '/admin/enquete_edit',
  AdminEnqueteAnswer = '/admin/enquete_answer',
  AdminTest = '/admin/test',
  AdminComment = '/admin/comment',
  AdminCommentReply = '/admin/comment_reply',
  AdminTestCreate = '/admin/test_create',
  AdminTestEdit = '/admin/test_edit',
  AdminTestAnswer = '/admin/test_answer',
  AdminTestAnswerDetail = '/admin/test_answer_detail',
  AdminAnalytics = '/admin/analytics',
  AdminAnalyticsTotal = '/admin/analytics/total',
  AdminAnalyticsVideos = '/admin/analytics/videos',
  AdminAnalyticsUsers = '/admin/analytics/users',
  AdminAnalyticsVideoUsers = '/admin/analytics/video/users',
  AdminAnalyticsLiveUsers = '/admin/analytict/live/users',
  AdminAnalyticsLogUser = '/admin/analytics/log/user',
  AdminAnalyticsLogUserVideos = '/admin/analytics/log/user/videos',
  AdminAnalyticsWatchLogUser = '/admin/analytics/watch_log/user',
  AdminAnalyticsWatchLogUserVideos = '/admin/analytics/watch_log/user/videos',
  AdminSiteCustomize = '/admin/site_customize',
  AdminSiteCustomizeLp = '/admin/site_customize/lp',
  AdminSiteCustomizeImage = '/admin/site_customize/image',
  AdminSiteCustomizeHeaderItemOrder = '/admin/site_customize/header_item_order',
  AdminSiteCustomizeTopItemOrder = '/admin/site_customize/top_item_order',
  AdminSiteCustomizeColor = '/admin/site_customize/color',
  AdminUser = '/admin/user',
  AdminUserViewLog = '/admin/user/view_log',
  AdminUserPaidHistory = '/admin/user/paid_history',
  AdminUserSubscriptionPaidHistory = '/admin/user/subscription/paid_history',
  AdminUserOneTimePaidHistory = '/admin/user/one_time/paid_history',
  AdminUserImport = '/admin/user_import',
  AdminUserInviteCreate = '/admin/user_invite_create',
  AdminUserInviteEdit = '/admin/user_invite_edit',
  AdminUserEdit = '/admin/user_edit',
  AdminGroup = '/admin/group',
  AdminGroupCreate = '/admin/group_create',
  AdminGroupEdit = '/admin/group_edit',
  AdminAdministrator = '/admin/administrator',
  AdminAdministratorInviteCreate = '/admin/administrator_invite_create',
  AdminAdministratorInviteEdit = '/admin/administrator_invite_edit',
  AdminInvite = '/admin/invite',
  AdminInviteMail = '/admin/invite_mail',
  AdminInvitedUser = '/admin/invited_user',
  AdminFieldCustomize = '/admin/field_customize',
  AdminApprovalPendingUser = '/admin/approval_pending_user',
  AdminApprovalPendingAllUsers = '/admin/approval_pending_all_users',
  AdminCategory = '/admin/category',
  AdminCategoryCreate = '/admin/category_create',
  AdminCategoryEdit = '/admin/category_edit',
  AdminTag = '/admin/tag',
  AdminTagCreate = '/admin/tag_create',
  AdminTagEdit = '/admin/tag_edit',
  AdminCreator = '/admin/creator',
  AdminCreatorCreate = '/admin/creator_create',
  AdminCreatorEdit = '/admin/creator_edit',
  AdminPayment = '/admin/payment',
  AdminBilling = '/admin/billing',
  AdminStripe = '/admin/stripe',
  AdminStripeProduct = '/admin/stripe_product',
  AdminStripeProductCreate = '/admin/stripe_product_create',
  AdminStripeProductEdit = '/admin/stripe_product_edit',
  AdminStripePrice = '/admin/stripe_price',
  AdminStripePriceCreate = '/admin/stripe_price_create',
  AdminStripePriceEdit = '/admin/stripe_price_edit',
  AdminStripeCoupon = '/admin/stripe_coupon',
  AdminStripeCouponCreate = '/admin/stripe_coupon_create',
  AdminStripeTaxRate = '/admin/stripe_tax_rate',
  AdminStripeTaxRateCreate = '/admin/stripe_tax_rate_create',
  AdminStripeTaxRateEdit = '/admin/stripe_tax_rate_edit',
  AdminBasicInfo = '/admin/basic_info',
  AdminContractingPlan = '/admin/contracting_plan',
  AdminPaidHistory = '/admin/paid_history',
  AdminSetting = '/admin/setting',
  AdminSettingZoomSetting = '/admin/setting/zoom_setting',
  AdminSettingZoomSettingAuthorized = '/admin/setting/zoom_setting/authorized',
  AdminInviteEmailLog = '/admin/invite_email_log',
  AdminEmailLogShow = '/admin/email_log_show',
  User = '/user/:teamId',
  UserNews = '/user/:teamId/news',
  UserSetting = '/user/:teamId/setting',
  UserPaidHistory = '/user/:teamId/paid_history',
  UserChangePrice = '/user/:teamId/change_price',
  UserViewLog = '/user/:teamId/view_log',
  UserBookmark = '/user/:teamId/bookmark',
  UserWatchLater = '/user/:teamId/watchLater',
  UserPurchased = '/user/:teamId/purchased',
  UserContentCategories = '/user/:teamId/:type(videos|lives|playlists)/categories',
  UserContentTags = '/user/:teamId/:type(videos|lives|playlists)/tags',
  UserContentCreators = '/user/:teamId/:type(videos|lives)/creators',
  UserVideosPopular = '/user/:teamId/videos/popular',
  UserSearchVideos = '/user/:teamId/videos/search/:query',
  UserVideoDetail = '/user/:teamId/video/:videoId',
  UserEventVideos = '/user/:teamId/event/:playlistId',
  UserEventContents = '/user/:teamId/event/:eventId',
  UserPlaylistVideos = '/user/:teamId/playlist/:playlistId',
  UserContentVideoDetail = '/user/:teamId/(playlist|event)/:parentId/:videoId',
  UserContentsNewer = '/user/:teamId/:type(videos|lives|playlists|events)/newer',
  UserCombineContents = '/user/:teamId/:type(videos|lives|playlists|events)(/category/.+?|/tag/.+?|/creator/.+?|/playStatus/.+?)*',
  UserLiveDetail = '/user/:teamId/live/:liveId',
  UserContentLiveDetail = '/user/:teamId/event/live/:parentId/:liveId',
  UserCreatorDetail = '/user/:teamId/creator/:creatorId',
  Catalog = '/catalog/:teamId',
  CatalogItemSelectContent = '/catalog/:teamId/item_select_content',
  CatalogContentCategories = '/catalog/:teamId/:type(videos|lives|playlists)/categories',
  CatalogContentTags = '/catalog/:teamId/:type(videos|lives|playlists)/tags',
  CatalogContentCreators = '/catalog/:teamId/:type(videos|lives)/creators',
  CatalogVideosPopular = '/catalog/:teamId/videos/popular',
  CatalogSearchVideos = '/catalog/:teamId/videos/search/:query',
  CatalogVideoDetail = '/catalog/:teamId/video/:videoId',
  CatalogContentsNewer = '/catalog/:teamId/:type(videos|lives|playlists|events)/newer',
  CatalogCombineContents = '/catalog/:teamId/:type(videos|lives|playlists|events)(/category/.+?|/tag/.+?|/creator/.+?|/playStatus/.+?)*',
  CatalogLiveDetail = '/catalog/:teamId/live/:liveId',
  CatalogContentLiveDetail = '/catalog/:teamId/event/live/:parentId/:liveId',
  CatalogCreatorDetail = '/catalog/:teamId/creator/:creatorId',
  CatalogEventContents = '/catalog/:teamId/event/:eventId',
  CatalogEventContentDetail = '/catalog/:teamId/event/:eventId/:contentId',
  CatalogPlaylistVideos = '/catalog/:teamId/playlist/:playlistId',
  CatalogContentVideoDetail = '/catalog/:teamId/(playlist|event)/:parentId/:videoId',
}

export enum LangType {
  JA = 'ja',
  EN = 'en',
}

export enum ViewType {
  LOGIN,
  DISABLED_USER,
  RESTRICTED_SINGLE_LOGIN,
  RESTRICTED_IP,
  NOTSUBSCRIBE,
  NOTPAID,
  UPDATE_FAILURE,
  UNAPPROVED,
  CANCELLED,
  ANSWER_TO_ENQUETE,
  CHANGE_PASSWORD,
  ENTRY,
  LOADING,
  GONE,
  VISIBLE,
}

export enum ReplyCommentInputType {
  SHOW,
  CONTENT,
}

export enum AuthMethodType {
  EMAIL = 'email',
  CUSTOM = 'custom',
}

export enum CurrencyType {
  JPY = 'jpy',
  USD = 'usd',
}

export enum PriceType {
  ONE_TIME = 'one_time',
  RECURRING = 'recurring',
}

export enum PriceIntervalType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum InvoiceStatusType {
  DRAFT = 'draft',
  OPEN = 'open',
  VOID = 'void',
  PAID = 'paid',
  UNCOLLECTIBLE = 'uncollectible',
}

export enum CouponDiscountType {
  AMOUNT = 'amount',
  PERCENT = 'percent',
}

export enum CouponDurationType {
  FOREVER = 'forever',
  ONCE = 'once',
  REPEATING = 'repeating',
}

export enum EmailAction {
  SENT = 'sent',
  INVITATION = 'invitation',
  BULK_INVITATION = 'bulkInvitation',
}

export enum IMailTemplateEventName {
  WELCOME = 'welcome',
  APPROVAL = 'approval',
  COMPLETED_PURCHASE = 'completedPurchase',
  CANCEL_SUBSCRIPTION = 'cancelSubscription',
}

export enum IAdministratorsNotifyType {
  Registered = 'registered',
  PaymentUser = 'paymentUser',
  VideoEncodeFinished = 'videoEncodeFinished',
  VideoCommented = 'videoCommented',
  VideoReplyCommented = 'videoReplyCommented',
  VideoEnqueteAnswered = 'videoEnqueteAnswered',
  UserSubscriptionRemoved = 'userSubscriptionRemoved',
  UserAccountDeleted = 'userAccountDeleted',
}

export enum IMailTemplateType {
  ADMIN_REGISTER,
}

export enum EmailLogEvent {
  BOUNCE = 'bounce',
  DEFERRED = 'deferred',
  DELIVERED = 'delivered',
  DROPPED = 'dropped',
  PROCESSED = 'processed',
}

export enum SendEmailStatus {
  SEND_NOW,
  SPECIFIED_TIME,
  SENT,
}

export enum SendEmailFormLimits {
  CONTENT_LENGTH = 1000,
}

export enum EventFormat {
  APPLICATION = 'application',
}

export enum LimitPlay {
  LOADING,
  REGISTERED,
  UNPURCHASED,
  BEFORE,
  AFTER,
  UNAPPLIED,
  OUTSIDE_APPLICATION_PERIOD,
  BEFORE_EVENT,
  AFTER_EVENT,
  VISIBLE,
}

export enum PaymentIntentStatus {
  SUCCEEDED = 'succeeded',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_ACTION = 'requires_action',
  PROCESSING = 'processing',
  REQUIRES_CAPTURE = 'requires_capture',
  CANCELED = 'canceled',
}

export enum PaymentMethodType {
  CARD = 'card',
  CUSTOMER_BALANCE = 'customer_balance',
}

export enum DateRange {
  NONE,
  CURRENT_MONTH,
  LAST_MONTH,
  FROM_TO,
}

export enum InvoiceActionType {
  DEFAULT,
  CANCEL,
  RESEND,
}

export enum EntryType {
  NOT_SUBSCRIBE,
  UNPAID_ADMIN,
  UNPAID_UESR,
  NOT_TEAM,
}
