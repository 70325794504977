import { ITeam } from 'common/interfaces/team'
import { i18nAlert } from 'i18n/i18n'

/**
 * Stripe接続ボタン押下用バリデーション
 * 前提としてtryの中で使用すること
 */
export const validateForm = (team: ITeam): void => {
  const { stripe } = team
  if (stripe.account_id) {
    throw new Error(i18nAlert('stripe.connectedOneStream'))
  }
}
