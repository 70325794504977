import { IEnquete } from 'common/interfaces/enquete'
import {
  IEnqueteCustomize,
  IEnqueteType,
} from 'common/interfaces/enquete_customize'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Input, Row } from 'reactstrap'
import {
  getFiledPlaceHolder,
  onChangeEnqueteField as serviceOnChangeEnqueteField,
} from 'services/admin/enquete_customize'

/**
 * 表示順で使用する各カードの生成
 */
export const createItemCard = (
  initEnquete: IEnquete,
  index: number,
  enqueteCustomizeStates: {
    enqueteCustomize: IEnqueteCustomize[]
    setEnqueteCustomize: React.Dispatch<
      React.SetStateAction<IEnqueteCustomize[]>
    >
  },
  onRemove: (index: number) => void
): JSX.Element => {
  const item = enqueteCustomizeStates.enqueteCustomize[index]
  const isEditable =
    initEnquete.questions?.find(
      (question) => item.questionName === question.questionName
    ) === undefined
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    isToggle: boolean
  ) => {
    serviceOnChangeEnqueteField(index, enqueteCustomizeStates, e, isToggle)
  }
  return (
    <SelectedCard
      isEditable={isEditable}
      index={index}
      enqueteCustomize={item}
      onChange={(e) => onChange(e, false)}
      onChangeToggle={(e) => onChange(e, true)}
      onRemove={() => onRemove(index)}
    />
  )
}

/**
 * 表示順で使用するxボタンを含むタイトルの生成
 */
const titleRow = (title: string, onRemove: () => void): JSX.Element => (
  <Row>
    <Col xs="10">{title}</Col>
    <Col xs="2" className="text-right">
      <i
        className="fas fa-times"
        style={{ cursor: 'default' }}
        onClick={onRemove}
      />
    </Col>
  </Row>
)

/**
 * 表示順で使用する各カードの生成
 */
const SelectedCard: React.FC<{
  isEditable: boolean
  index: number
  enqueteCustomize: IEnqueteCustomize
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeToggle: (e: React.ChangeEvent<HTMLInputElement>) => void
  onRemove: () => void
}> = ({
  isEditable,
  index,
  enqueteCustomize,
  onChange,
  onChangeToggle,
  onRemove,
}) => {
  const { t } = useTranslation('adminEnquete')

  const typeTitle = (type: IEnqueteType) => {
    switch (type) {
      case IEnqueteType.TEXT:
        return t('form.card.title.text')
      case IEnqueteType.TEXTAREA:
        return t('form.card.title.textarea')
      case IEnqueteType.RADIO:
        return t('form.card.title.radio')
      case IEnqueteType.CHECKBOX:
        return t('form.card.title.checkbox')
    }
  }

  return (
    <div className="placed-card">
      {titleRow(`${index + 1}. ${typeTitle(enqueteCustomize.type)}`, onRemove)}
      <Input
        value={enqueteCustomize.questionName}
        placeholder={getFiledPlaceHolder()}
        type="text"
        onChange={onChange}
        disabled={!isEditable}
      />
      {enqueteCustomize.options && enqueteCustomize.options.length > 0 && (
        <div className="mt-3 ml-3">
          {t('form.card.optionsList')}
          {enqueteCustomize.options.map((option, key) => (
            <div key={key} className="mt-2">{`${key + 1}. ${option}`}</div>
          ))}
        </div>
      )}
      <div className="mt-3">
        <h6>{t('form.card.required')}</h6>
        <label className="custom-toggle">
          <input
            type="checkbox"
            onChange={onChangeToggle}
            checked={enqueteCustomize.isRequired}
            disabled={!isEditable}
          />
          <span className="custom-toggle-slider rounded-circle" />
        </label>
      </div>
    </div>
  )
}
