import { ColumnDef } from '@tanstack/react-table'
import { IUser } from 'common/interfaces/user'
import { IAnalyticsVideoWatchLog } from 'common/interfaces/video_watch_log'
import { userUid } from 'common/utils'
import { useTanstackTableWithCsvExport } from 'components/atoms/Table/CreateTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'reactstrap'
import {
  getAllUserWatchLogColumns,
  getAllUserWatchLogs,
  getUsers,
} from 'services/admin/analytics_watch_log_user_videos'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminAnalytics')
  const { storeCache } = useContext(AuthContext)
  const [users, setUsers] = useState<IUser[]>([])
  const [currentUserId, setCurrentUserId] = useState<string>('')
  const [allUserWatchLogs, setAllUserWatchLogs] = useState<
    IAnalyticsVideoWatchLog[]
  >([])

  useEffect(() => {
    getUsers(storeCache, setUsers)
  }, [storeCache])

  useEffect(() => {
    getAllUserWatchLogs(storeCache, setAllUserWatchLogs, users)
  }, [storeCache, users])

  if (!storeCache.team) return null

  const userOptions = users.map((user) => (
    <option key={user.id} value={user.id}>
      {user.email || userUid(user.id)}
    </option>
  ))
  const targetUserLogs = allUserWatchLogs.filter(
    (l) => l.user_id === currentUserId
  )

  return (
    <>
      <AllDataTable allUsers={users} allUserWatchLogs={allUserWatchLogs} />
      <Input onChange={(e) => setCurrentUserId(e.target.value)} type="select">
        <option>{t('pleaseSelect')}</option>
        {userOptions}
      </Input>
      <div className="mt-5">
        {targetUserLogs && <Table targetUserLogs={targetUserLogs} />}
      </div>
    </>
  )
}

const AllDataTable: React.FC<{
  allUsers: IUser[]
  allUserWatchLogs: IAnalyticsVideoWatchLog[]
}> = ({ allUsers, allUserWatchLogs }) => {
  const { storeCache } = useContext(AuthContext)

  const columns = useMemo<ColumnDef<IAnalyticsVideoWatchLog>[]>(
    () => getAllUserWatchLogColumns(storeCache, allUsers),
    [storeCache, allUsers]
  )

  return useTanstackTableWithCsvExport<IAnalyticsVideoWatchLog>(
    columns,
    allUserWatchLogs,
    'analytics_watch_log_user_videos.csv',
    { hideTable: true }
  )
}

const Table: React.FC<{
  targetUserLogs: IAnalyticsVideoWatchLog[]
}> = ({ targetUserLogs }) => {
  const { storeCache } = useContext(AuthContext)

  const columns = useMemo<ColumnDef<IAnalyticsVideoWatchLog>[]>(
    () => getAllUserWatchLogColumns(storeCache, []).splice(-6),
    [storeCache]
  )

  return useTanstackTableWithCsvExport<IAnalyticsVideoWatchLog>(
    columns,
    targetUserLogs,
    'analytics_watch_log_user_videos.csv'
  )
}

export default TableBody
