import { ITag } from 'common/interfaces/tag'
import { ITeam } from 'common/interfaces/team'
import { nowTimestamp } from 'common/times'
import { Teams } from './firebase_team'

const Tags = (team: ITeam) => Teams.doc(team.id).collection('tags')

/**
 * get all tags
 */
export const get = async (team: ITeam): Promise<ITag[]> => {
  try {
    const tagDatas = await Tags(team).get()
    return tagDatas.docs.map((tag) => tag.data() as ITag)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * add tag
 * @throws Firebase error
 */
export const store = async (team: ITeam, tag: ITag): Promise<void> => {
  await Tags(team).doc(tag.id).set(tag)
}

/**
 * update tag
 * @throws Firebase error
 */
export const update = async (team: ITeam, tag: ITag): Promise<void> => {
  tag.updated_at = nowTimestamp()
  await Tags(team).doc(tag.id).update(tag)
}

/**
 * delete tag
 * @throws Firebase error
 */
export const remove = async (team: ITeam, tag: ITag): Promise<void> => {
  await Tags(team).doc(tag.id).delete()
}

/**
 * remove tag image
 * @throws Firebase error
 */
export const removeImage = async (team: ITeam, tag: ITag): Promise<void> => {
  tag.image = null
  tag.updated_at = nowTimestamp()
  await Tags(team).doc(tag.id).update(tag)
}
