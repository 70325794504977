import { Routes } from 'common/enums'
import { IUser } from 'common/interfaces/user'
import ButtonRight from 'components/atoms/Button/Right'
import AuthInfo from 'components/atoms/Head/AuthInfo'
import H1 from 'components/atoms/Head/H1'
import H2 from 'components/atoms/Head/H2'
import TableBody from 'components/organisms/Admin/UserViewLogs/TableBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Container } from 'reactstrap'

const UserViewLogs: React.FC = () => {
  const { t } = useTranslation('adminUser')
  const { storeCache } = useContext(AuthContext)
  const { state } = useLocation<IUser>()

  return (
    <>
      <H1 title={t('viewHistory.title')} />
      <H2 title={state.name ?? ''} />
      <AuthInfo team={storeCache.team!} user={state} />

      <Container fluid>
        <ButtonRight nextPage={Routes.AdminUser} content={t('backToList')} />
        <TableBody user={state} />
      </Container>
    </>
  )
}

export default UserViewLogs
