import { IStoreCache } from 'common/interfaces/auth_provider'
import GenreBadge from 'components/atoms/Badge/GenreBadge'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useRef } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import {
  createBadgeData,
  createTitle,
  IChooseGenreType,
} from 'services/user/choose_genre'
import {
  IContentTypeListParams,
  useContentTypeListParams,
} from 'services/user/type_contents_list/page_params'
import './choosegenre.scss'

interface IChooseGenreProps {
  type: IChooseGenreType
}

const ChooseGenre: React.FC<IChooseGenreProps> = ({
  type,
}: IChooseGenreProps) => {
  const { storeCache } = useContext(AuthContext)
  const params = useContentTypeListParams()

  const title = createTitle(type)
  const scrollRef = useBadgeContainerWheelEvent()
  const badges = createBadges(storeCache, type, params)

  if (badges.length <= 0) return null

  return (
    <div className="choose-genre">
      <div className="title">{title}</div>
      <ScrollContainer className="badges" innerRef={scrollRef}>
        {badges}
      </ScrollContainer>
    </div>
  )
}

const useBadgeContainerWheelEvent = () => {
  const scrollRef = useRef<HTMLElement | null>(null)
  const mouseWheelEvent = (e: WheelEvent) => {
    e.preventDefault()
    if (scrollRef.current) {
      const isYbig = Math.abs(e.deltaY) > Math.abs(e.deltaX)
      scrollRef.current.scrollLeft += isYbig ? e.deltaY : e.deltaX
    }
  }
  useEffect(() => {
    scrollRef.current?.removeEventListener('wheel', mouseWheelEvent)
    scrollRef.current?.addEventListener('wheel', mouseWheelEvent)
  }, [scrollRef])

  return scrollRef
}

const createBadges = (
  storeCache: IStoreCache,
  type: IChooseGenreType,
  params: IContentTypeListParams
) => {
  return createBadgeData(storeCache, type, params).map(
    ({ id, name, linkPath, isTag, isEnable }) => (
      <GenreBadge
        key={id}
        className="choose-genre-badge"
        name={name}
        linkPath={linkPath}
        isTag={isTag}
        isEnable={isEnable}
      />
    )
  )
}

export default ChooseGenre
