import { ICreator } from 'common/interfaces/creator'
import { IVideo } from 'common/interfaces/video'
import { makeVideoPath } from 'common/link_path'
import CreatorIcon from 'components/atoms/Icon/CreatorIcon'
import QuillHtml from 'components/atoms/Quill/QuillHtml'
import VideoCard from 'components/molecules/User/VideoCard/VideoCard'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { findVideosOfCreator } from 'services/user/creator_detail'
import './creatordetailbody.scss'

interface ICreatorDetailBody {
  creator: ICreator
}

const CreatorDetailBody: React.FC<ICreatorDetailBody> = ({ creator }) => {
  const { storeCache } = useContext(AuthContext)
  const videos = findVideosOfCreator(storeCache, creator.id)

  return (
    <div className="user-creator-detail">
      <Container>
        <CreatorIcon creator={creator} centered />

        <div className="creator-name">{creator.name}</div>
        <div className="creator-profile">
          <QuillHtml html={creator.profile} />
        </div>

        <Row className="creator-video-row">{creatorVideoCols(videos)}</Row>
      </Container>
    </div>
  )
}

const creatorVideoCols = (videos: IVideo[]): JSX.Element[] => {
  return videos.map((v) => (
    <Col key={v.id} className="video-card-container" xs="6" md="4">
      <Link to={makeVideoPath(v.id)}>
        <VideoCard className="video-card" video={v} />
      </Link>
    </Col>
  ))
}

export default CreatorDetailBody
