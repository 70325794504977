import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { ITest } from 'common/interfaces/test'
import {
  ITestAndAnswer,
  ITestAnswerDetail,
} from 'common/interfaces/test_answer'
import { datetimeFormat } from 'common/times'
import NormalButton from 'components/atoms/Button/Normal'
import { useTanstackTableWithCsvExport } from 'components/atoms/Table/CreateTable'
import { createTestQuestionNameDataString } from 'components/atoms/Table/ElementsOnTable'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

const TableBody: React.FC<ITestAndAnswer> = ({ test, answer }) => {
  const { t } = useTranslation('adminTest')
  const columns = useMemo<ColumnDef<ITestAnswerDetail>[]>(
    () => [
      {
        header: t('answerDetail.table.passOrFail'),
        accessorFn: (a) =>
          a.is_passed
            ? t('answerDetail.table.pass')
            : t('answerDetail.table.failed'),
      },
      {
        header: t('answerDetail.table.answeredAt'),
        accessorFn: (a) => datetimeFormat(a.answered_at),
      },
      ...test.questions.map((q, i) => ({
        header: t('answerDetail.table.questions', {
          index: i + 1,
          questionName: createTestQuestionNameDataString(q.name),
        }),
        accessorFn: (a: ITestAnswerDetail) => {
          if (typeof a.answer[i] === 'number') {
            return q.options[a.answer[i] as number]
          }

          const answers = JSON.parse(a.answer[i] as string) as number[]
          return (
            <>
              {answers.map((answer, j) => (
                <div key={j}>{q.options[answer]}</div>
              ))}
            </>
          )
        },
      })),
    ],
    [test.questions, t]
  )

  const data = answer.answers.sort(
    (a, b) => b.answered_at.seconds - a.answered_at.seconds
  )

  return useTanstackTableWithCsvExport<ITestAnswerDetail>(
    columns,
    data,
    'test_answer_detail.csv',
    {},
    <BackButton test={test} />
  )
}

const BackButton: React.FC<{ test: ITest }> = ({ test }) => {
  const { t } = useTranslation('adminTest')
  const history = useHistory()
  return (
    <NormalButton
      className="ml-3"
      onClick={() =>
        history.push({ pathname: Routes.AdminTestAnswer, state: { test } })
      }
      content={t('answerDetail.back')}
    />
  )
}

export default TableBody
