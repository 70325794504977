import { CurrencyType, Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IProduct } from 'common/interfaces/stripe/product'
import { getCurrency, isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { reloadCachedTeam } from 'providers/AuthProvider'
import { saveProduct } from 'repositories/functions/functions_stripe'
import { updateStripeSubscriptionProductId } from 'repositories/store/firebase_team'
import { alertService } from 'services/alert'

/**
 * `IProduct`の初期値設定
 */
export const initProductForm = (): IProduct => ({
  id: '',
  name: '',
  description: '',
  created: 0,
  active: true,
  metadata: { tax_rate: '', currency: getCurrency() },
})

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
  DESCRIPTION,
  TAXRATE,
  CURRENCY,
  ACTIVE,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  product: IProduct,
  setProduct: React.Dispatch<React.SetStateAction<IProduct>>,
  type: InputType,
  e: React.ChangeEvent<HTMLInputElement>
) =>
  setProduct({
    ...product,
    name: type === InputType.NAME ? e.target.value : product.name,
    description:
      type === InputType.DESCRIPTION ? e.target.value : product.description,
    metadata: {
      ...product.metadata,
      tax_rate:
        type === InputType.TAXRATE ? e.target.value : product.metadata.tax_rate,
      currency:
        type === InputType.CURRENCY
          ? (e.target.value as CurrencyType)
          : product.metadata.currency ?? CurrencyType.JPY,
    },
    active: type === InputType.ACTIVE ? e.target.checked : product.active,
  })

/**
 * お知らせレコード作成処理
 */
export const saveStripeProduct = async (
  { push }: History,
  { id, name, description, metadata: { tax_rate, currency }, active }: IProduct,
  isSubscriptionProductIdON: boolean,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    if (!name || !description || !tax_rate) {
      throw new Error(i18nValidation('input.allFields'))
    }

    const team = storeCache.team!
    const product = await saveProduct(
      team.stripe.account_id,
      id,
      name,
      description,
      tax_rate,
      currency ?? CurrencyType.JPY,
      active
    )
    // サブスク商品に設定した場合、teamを更新する
    if (isSubscriptionProductIdON) {
      await updateStripeSubscriptionProductId(team, product.id)
      await reloadCachedTeam(storeCache)
    }

    push(Routes.AdminStripeProduct)
    alertService.show(true, i18nAlert('saved.stripe.product'))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
