import { ITeamColors } from 'common/interfaces/team'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  hideThemeColors,
  InputType,
  onChangeInput as serviceOnChangeInput,
  saveTeamColors,
  setDarkTheme as serviceSetDarkTheme,
  setHideTheme as serviceSetHideTheme,
  setLightTheme as serviceSetLightTheme,
} from 'services/admin/site_customize_color'
import { calcUserColors } from 'services/user/colors'

const FormBody: React.FC = () => {
  const { t } = useTranslation('adminSiteCustomize')
  const { storeCache } = useContext(AuthContext)
  const [teamColors, setTeamColors] = useState<ITeamColors>(
    storeCache.team?.colors || ({} as ITeamColors)
  )
  const [lightClickCount, setLightClickCount] = useState<number>(0)
  if (!storeCache.team) return null

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(teamColors, setTeamColors, type, e)
  const setLightTheme = () => {
    serviceSetLightTheme(setTeamColors)
    setLightClickCount(lightClickCount + 1)
  }
  const setDarkTheme = () => {
    serviceSetDarkTheme(setTeamColors)
    setLightClickCount(0)
  }
  const setHideTheme = (type: any) => serviceSetHideTheme(setTeamColors, type)
  const update = () => saveTeamColors(teamColors, storeCache)

  return (
    <Form>
      <Row>
        <Col md="4">
          <FormGroup>
            <Label for="accentColor">
              {t('colorCustomize.form.accentColor')}
            </Label>
            <Input
              id="accentColor"
              type="color"
              value={teamColors.accent}
              onChange={(e) => onChangeInput(InputType.ACCENT, e)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="textColor">{t('colorCustomize.form.textColor')}</Label>
            <Input
              id="textColor"
              type="color"
              value={teamColors.text_primary}
              onChange={(e) => onChangeInput(InputType.TEXT_PRIMARY, e)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="bgColor">{t('colorCustomize.form.bgColor')}</Label>
            <Input
              id="bgColor"
              type="color"
              value={teamColors.background}
              onChange={(e) => onChangeInput(InputType.BACKGROUND, e)}
            />
          </FormGroup>

          <div>
            <Button onClick={setLightTheme} color="secondary">
              {t('colorCustomize.form.lightTheme')}
            </Button>
          </div>

          <div className="mt-3">
            <Button onClick={setDarkTheme} color="dark">
              {t('colorCustomize.form.darkTheme')}
            </Button>
          </div>

          {lightClickCount > 4 && hideButtons(setHideTheme)}
        </Col>
        <Col lg="6" md="8" className="mt-3">
          {previewSvg(teamColors)}
        </Col>
      </Row>

      <div className="text-center">
        <Button onClick={update} className="my-4" color="primary" type="button">
          {t('save')}
        </Button>
      </div>
    </Form>
  )
}

const hideButtons = (setHideTheme: (type: any) => void) => {
  const settings = [
    {
      name: 'sakura',
      text: hideThemeColors.sakura.text_primary,
      bg: hideThemeColors.sakura.background,
    },
    {
      name: 'chocolate',
      text: hideThemeColors.chocolate.text_primary,
      bg: hideThemeColors.chocolate.background,
    },
    {
      name: 'nature',
      text: hideThemeColors.nature.text_primary,
      bg: hideThemeColors.nature.background,
    },
    {
      name: 'novel',
      text: hideThemeColors.novel.text_primary,
      bg: hideThemeColors.novel.background,
    },
  ]

  return settings.map(({ name, text, bg }) => (
    <div key={name} className="mt-3">
      <Button
        onClick={() => setHideTheme(name)}
        style={{
          color: text,
          background: bg,
          borderColor: bg,
        }}
      >
        {name.charAt(0).toUpperCase() + name.slice(1)} Theme
      </Button>
    </div>
  ))
}

const previewSvg = (teamColors: ITeamColors) => {
  const { text_primary, background, accent } = calcUserColors(teamColors)

  const svgOptions = {
    headerRight: {
      width: '12',
      height: '12',
      rx: '1',
      fill: accent,
    },
    chooseGenre: {
      text: {
        width: '32',
        height: '6',
        rx: '3',
        fill: text_primary,
      },
      badge: {
        width: '24',
        height: '6',
        rx: '3',
        fill: text_primary,
        fillOpacity: 0.1,
      },
    },
    videoCard: {
      base: {
        width: '46',
        height: '34',
        rx: '1',
        fill: '#fff',
      },
      body: {
        width: '47',
        height: '35',
        rx: '1',
        fill: background,
        fillOpacity: '0.95',
        style: { filter: 'url(#shadow)' },
      },
    },
  }

  return (
    <svg
      style={{ border: '1px solid black' }}
      fill="none"
      viewBox="0 0 228 120"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter id="shadow">
          <feDropShadow
            dx="0.2"
            dy="0.2"
            stdDeviation="0"
            floodColor="black"
            floodOpacity="0.3"
          />
        </filter>
      </defs>
      <g>
        <path d="M0 0H228V120H0V0Z" fill={background} />
        <rect x="34" y="6" width="12" height="12" rx="1" fill={text_primary} />
        <rect x="165" y="6" {...svgOptions.headerRight} />
        <rect x="182" y="6" {...svgOptions.headerRight} />
        <rect x="37" y="29" {...svgOptions.chooseGenre.text} />
        <rect x="80" y="29" {...svgOptions.chooseGenre.badge} />
        <rect x="106" y="29" {...svgOptions.chooseGenre.badge} />
        <rect x="132" y="29" {...svgOptions.chooseGenre.badge} />
        <rect x="37" y="38" {...svgOptions.chooseGenre.text} />
        <rect x="80" y="38" {...svgOptions.chooseGenre.badge} />
        <rect x="106" y="38" {...svgOptions.chooseGenre.badge} />
        <rect x="132" y="38" {...svgOptions.chooseGenre.badge} />
        <rect x="158" y="38" {...svgOptions.chooseGenre.badge} />
        <rect x="37" y="47" {...svgOptions.chooseGenre.text} />
        <rect x="80" y="47" {...svgOptions.chooseGenre.badge} />
        <rect x="106" y="47" {...svgOptions.chooseGenre.badge} />
        <rect x="34" y="64" width="41" height="6" rx="3" fill={text_primary} />
        <rect x="173" y="64" width="21" height="6" rx="3" fill={text_primary} />
        <rect x="34.5" y="78.5" {...svgOptions.videoCard.base} />
        <rect x="34" y="78" {...svgOptions.videoCard.body} />
        <rect x="85.5" y="78.5" {...svgOptions.videoCard.base} />
        <rect x="85" y="78" {...svgOptions.videoCard.body} />
        <rect x="136.5" y="78.5" {...svgOptions.videoCard.base} />
        <rect x="136" y="78" {...svgOptions.videoCard.body} />
      </g>
    </svg>
  )
}

export default FormBody
