import { LimitPlay } from 'common/enums'
import { ICreator } from 'common/interfaces/creator'
import { IEnquete } from 'common/interfaces/enquete'
import { IEnqueteAnswer } from 'common/interfaces/enquete_answer'
import { ILive } from 'common/interfaces/live'
import { IVideo } from 'common/interfaces/video'
import { makeCreatorDetailPath, makeVideoPath } from 'common/link_path'
import CreatorIcon from 'components/atoms/Icon/CreatorIcon'
import NextStep from 'components/atoms/NextStep/NextStep'
import QuillHtml from 'components/atoms/Quill/QuillHtml'
import {
  Filelists,
  makeTagElements,
} from 'components/atoms/VideoDetail/ElementsOnVideoDetail'
import { TFunction } from 'i18next'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'
import { overviewTimeClickable } from 'services/user/video_chapter'
import VideoCard from '../VideoCard/VideoCard'
import './overview.scss'

interface IOverviewProps {
  isCatalogPage: boolean
  parentId: string | undefined
  video: IVideo
  creator: ICreator | null
  nextContent: IVideo | ILive | null
  sameCategory3Videos: IVideo[]
  limitPlay: LimitPlay
  isNotLimitPlayCatalogPage: boolean
  enquete: IEnquete | null
  enqueteAnswer: IEnqueteAnswer | null
  onToggleEnqueteModal: () => void
}

const Overview: React.FC<IOverviewProps> = ({
  isCatalogPage,
  parentId,
  video,
  creator,
  nextContent,
  sameCategory3Videos,
  limitPlay,
  isNotLimitPlayCatalogPage,
  enquete,
  enqueteAnswer,
  onToggleEnqueteModal,
}: IOverviewProps) => {
  const { t } = useTranslation('userVideo')
  const { storeCache } = useContext(AuthContext)

  return (
    <div className="overview">
      <Row>
        <Col md="8">
          <QuillHtml
            html={overviewTimeClickable(video.overview, video.bunny.length)}
          />
          {makeTagElements(storeCache, video.tag_ids)}
          {isNotLimitPlayCatalogPage &&
            enqueteButton(
              t,
              isCatalogPage,
              video,
              enquete,
              enqueteAnswer,
              onToggleEnqueteModal
            )}
        </Col>
        <Col md="4" className="mt-4 mt-md-3">
          {creator && (
            <div className="video-creator">
              <CreatorIcon creator={creator} width="8rem" centered enableLink />
              <h2 className="creator-name">
                <Link to={makeCreatorDetailPath(creator.id)}>
                  {creator.name}
                </Link>
              </h2>
            </div>
          )}
          {limitPlay === LimitPlay.VISIBLE && (
            <Filelists obj={video} isCatalogPage={isCatalogPage} />
          )}
        </Col>
      </Row>

      <NextStep parentId={parentId} nextContent={nextContent} />

      {sameCategory3Videos.length !== 0 && (
        <div className="same-category-videos">
          <h2 className="title">{t('overview.sameCategoryVideo')}</h2>
          <Row>
            {sameCategory3Videos.map((video) => (
              <Col key={video.id} xs={12} sm={6} md={4}>
                <Link to={makeVideoPath(video.id)}>
                  <VideoCard className="video-card" video={video} />
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  )
}

const enqueteButton = (
  t: TFunction<'userVideo', undefined>,
  isCatalogPage: boolean,
  video: IVideo,
  enquete: IEnquete | null,
  enqueteAnswer: IEnqueteAnswer | null,
  onClick: () => void
) => {
  if (
    isCatalogPage ||
    !enquete ||
    video.enquete === undefined ||
    !video.enquete.activate_enquete
  ) {
    return null
  }
  return (
    <Button
      className="enquete-btn"
      onClick={onClick}
      disabled={enqueteAnswer !== null}
    >
      {enqueteAnswer !== null
        ? t('overview.enqueteButton.answeredEnquete')
        : t('overview.enqueteButton.showEnquete')}
    </Button>
  )
}

export default Overview
