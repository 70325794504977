import { ITeam } from 'common/interfaces/team'
import { IVideoWatchLog } from 'common/interfaces/video_watch_log'
import { addedTimestamp } from 'common/times'
import { Teams } from './firebase_team'

const VideoWatchLogs = (team: ITeam, userId: string) =>
  Teams.doc(team.id)
    .collection('users')
    .doc(userId)
    .collection('video_watch_logs')

/**
 * Get video watch logs within the last 7 days
 * @param team `ITeam`
 * @param userId User ID
 * @returns If successfully got logs, return `IVideoWatchLog[]`. If error, return `undefined`.
 */
export const get = async (
  team: ITeam,
  userId: string
): Promise<IVideoWatchLog[] | undefined> => {
  const days7ago = addedTimestamp(-7, 'day')

  try {
    const data = await VideoWatchLogs(team, userId)
      .where('started_at', '>=', days7ago)
      .get()
    return data.docs.map((doc) => doc.data() as IVideoWatchLog)
  } catch (error) {
    console.error(error)
    return undefined
  }
}

/**
 * Set video watch time log
 * @throws Firebase error
 */
export const mergeLog = async (
  team: ITeam,
  userId: string,
  log: Partial<IVideoWatchLog> & Pick<IVideoWatchLog, 'id' | 'video_id'>
): Promise<void> => {
  await VideoWatchLogs(team, userId).doc(log.id).set(log, { merge: true })
}
