import { Routes } from 'common/enums'
import { IUser } from 'common/interfaces/user'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardText, CardTitle, Col, Row } from 'reactstrap'

type TLinkBody = {
  user: IUser
}

const LinkBody: React.FC<TLinkBody> = ({ user }: TLinkBody) => {
  const { t } = useTranslation('adminUser')
  const cardStyle: CSSProperties = { color: 'unset' }
  return (
    <Row>
      <Col sm="12" className="mb-4">
        <Link
          to={{
            pathname: Routes.AdminUserSubscriptionPaidHistory,
            state: user,
          }}
          style={cardStyle}
        >
          <Card body>
            <CardTitle tag="h4">
              {t('paidHistory.subscriptionPaidHistory.title')}
            </CardTitle>
            <CardText>{t('paidHistory.subscriptionPaidHistory.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-4">
        <Link
          to={{ pathname: Routes.AdminUserOneTimePaidHistory, state: user }}
          style={cardStyle}
        >
          <Card body>
            <CardTitle tag="h4">
              {t('paidHistory.oneTimePaidHistory.title')}
            </CardTitle>
            <CardText>{t('paidHistory.oneTimePaidHistory.note')}</CardText>
          </Card>
        </Link>
      </Col>
    </Row>
  )
}

export default LinkBody
