import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICategory } from 'common/interfaces/category'
import { ICreator } from 'common/interfaces/creator'
import { IPlaylist } from 'common/interfaces/playlist'
import { ITag } from 'common/interfaces/tag'
import {
  allLiveCategoryPath,
  allLiveCreatorPath,
  allLiveTagPath,
  allPlaylistCategoryPath,
  allPlaylistTagPath,
  allVideoCategoryPath,
  allVideoCreatorPath,
  allVideoTagPath,
  makeCategoryLivePath,
  makeCategoryPlaylistPath,
  makeCategoryVideoPath,
  makeCreatorLivePath,
  makeCreatorVideoPath,
  makeTagLivePath,
  makeTagPlaylistPath,
  makeTagVideoPath,
} from 'common/link_path'
import { sortBySortNumber } from 'common/sort_contents'
import { i18nUserVideoList } from 'i18n/i18n'

enum PageType {
  CATEGORY_VIDEO,
  TAG_VIDEO,
  CREATOR_VIDEO,
  CATEGORY_LIVE,
  TAG_LIVE,
  CREATOR_LIVE,
  CATEGORY_PLAYLIST,
  TAG_PLAYLIST,
}

const pageType = (): PageType => {
  switch (window.location.pathname) {
    case allVideoCategoryPath():
      return PageType.CATEGORY_VIDEO
    case allVideoTagPath():
      return PageType.TAG_VIDEO
    case allVideoCreatorPath():
      return PageType.CREATOR_VIDEO
    case allLiveCategoryPath():
      return PageType.CATEGORY_LIVE
    case allLiveTagPath():
      return PageType.TAG_LIVE
    case allLiveCreatorPath():
      return PageType.CREATOR_LIVE
    case allPlaylistCategoryPath():
      return PageType.CATEGORY_PLAYLIST
    case allPlaylistTagPath():
      return PageType.TAG_PLAYLIST
  }
  return PageType.CATEGORY_VIDEO
}

/**
 * ジャンルタイプに応じたタイトルを返す
 */
export const createTitle = (): string => {
  const i18n = i18nUserVideoList('genreList.titles', {
    returnObjects: true,
  })
  switch (pageType()) {
    case PageType.CATEGORY_VIDEO:
      return i18n.videoCategoryList
    case PageType.TAG_VIDEO:
      return i18n.videoTagList
    case PageType.CREATOR_VIDEO:
      return i18n.videoCreatorList
    case PageType.CATEGORY_LIVE:
      return i18n.liveCategoryList
    case PageType.TAG_LIVE:
      return i18n.liveTagList
    case PageType.CREATOR_LIVE:
      return i18n.liveCreatorList
    case PageType.CATEGORY_PLAYLIST:
      return i18n.playlistCategoryList
    case PageType.TAG_PLAYLIST:
      return i18n.playlistTagList
  }
}

const getGenreName = (name: string): string =>
  pageType() === PageType.TAG_VIDEO ? `#${name}` : name

const getGenreLink = (id: string): string => {
  switch (pageType()) {
    case PageType.CATEGORY_VIDEO:
      return makeCategoryVideoPath(id)
    case PageType.TAG_VIDEO:
      return makeTagVideoPath(id)
    case PageType.CREATOR_VIDEO:
      return makeCreatorVideoPath(id)
    case PageType.CATEGORY_LIVE:
      return makeCategoryLivePath(id)
    case PageType.TAG_LIVE:
      return makeTagLivePath(id)
    case PageType.CREATOR_LIVE:
      return makeCreatorLivePath(id)
    case PageType.CATEGORY_PLAYLIST:
      return makeCategoryPlaylistPath(id)
    case PageType.TAG_PLAYLIST:
      return makeTagPlaylistPath(id)
  }
}

/**
 * ジャンルタイプに応じたデータを返す
 */
export const getGenreData = (
  storeCache: IStoreCache
): {
  id: string
  name: string
  link: string
  image: string | null
}[] => {
  const data = (() => {
    switch (pageType()) {
      case PageType.CATEGORY_VIDEO:
      case PageType.CATEGORY_LIVE:
      case PageType.CATEGORY_PLAYLIST:
        return sortBySortNumber(storeCache.categories)
      case PageType.TAG_VIDEO:
      case PageType.TAG_LIVE:
      case PageType.TAG_PLAYLIST:
        return sortBySortNumber(storeCache.tags)
      case PageType.CREATOR_VIDEO:
      case PageType.CREATOR_LIVE:
        return sortBySortNumber(storeCache.creators)
    }
  })()

  return (data as Array<ICategory | ITag | IPlaylist | ICreator>).map((d) => ({
    id: d.id,
    name: getGenreName(d.name),
    link: getGenreLink(d.id),
    image: d.image,
  }))
}
