import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Button, Input, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { deleteAccount, openAuthModal } from 'services/user/setting'

interface IDeleteAccountButton {
  className?: string
}

const DeleteAccountButton: React.FC<IDeleteAccountButton> = (props) => {
  const { t } = useTranslation('userSetting')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isSNSAuth, setIsSNSAuth] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')

  const modalToggle = () => setIsModalOpen(!isModalOpen)
  const onButtonClick = () =>
    openAuthModal(storeCache, modalToggle, setIsSNSAuth)

  return (
    <div className={props.className}>
      <Button color="danger" onClick={onButtonClick} type="button">
        {t('operationRow.deleteAccountButton.deleteAccount')}
      </Button>

      <Modal isOpen={isModalOpen} toggle={modalToggle} centered>
        <ModalBody>
          <div>
            <Trans
              t={t}
              i18nKey="operationRow.deleteAccountButton.deleteAccountConfirm"
            />
          </div>
          {!isSNSAuth && (
            <Input
              className="mt-3"
              placeholder={t('operationRow.deleteAccountButton.inputPassword')}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          )}
        </ModalBody>
        <ModalFooter style={{ borderTop: 'unset' }}>
          <Button color="secondary" onClick={modalToggle}>
            {t('operationRow.deleteAccountButton.cancel')}
          </Button>
          <Button
            color="danger"
            onClick={() =>
              deleteAccount(storeCache, history, password, isSNSAuth)
            }
          >
            {t('operationRow.deleteAccountButton.delete')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default DeleteAccountButton
