import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { ITaxRate } from 'common/interfaces/stripe/tax_rate'
import { formatUNIXToDate } from 'common/times'
import { goBackWithErrorAlert } from 'common/utils'
import NormalButton from 'components/atoms/Button/Normal'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeCustomOperationButtons,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Row } from 'reactstrap'
import { getStripeTaxRates } from 'services/admin/stripe/tax_rate'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminStripeBilling')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const [taxRates, setTaxRates] = useState<ITaxRate[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await getStripeTaxRates(storeCache, setTaxRates)
      setIsLoading(false)
    })()
  }, [storeCache])

  if (isLoading) return <LoadingSpinner />

  if (!storeCache.team!.stripe.account_id) {
    goBackWithErrorAlert(
      history,
      i18nAlert('stripe.setting.unfinish.notFunction')
    )
    return null
  }

  if (taxRates.length <= 0) {
    return <RegisterItemButton type="stripe_tax_rate" />
  }
  return (
    <>
      <Row className="justify-content-end mb-3 mr-1 mr-md-0">
        <NormalButton
          onClick={() => history.push(Routes.AdminBilling)}
          content={t('stripeTaxRate.backToList')}
        />
        <NormalButton
          onClick={() => history.push(Routes.AdminStripeTaxRateCreate)}
          content={t('stripeTaxRate.list.register')}
        />
      </Row>
      <Table taxRates={taxRates} />
    </>
  )
}

const Table: React.FC<{ taxRates: ITaxRate[] }> = ({ taxRates }) => {
  const { t } = useTranslation('adminStripeBilling')
  const history = useHistory()

  const columns = useMemo<ColumnDef<ITaxRate>[]>(
    () => [
      {
        header: t('stripeTaxRate.list.table.id'),
        accessorKey: 'id',
      },
      {
        header: t('stripeTaxRate.list.table.name'),
        accessorKey: 'display_name',
      },
      {
        header: t('stripeTaxRate.list.table.percentage'),
        accessorKey: 'percentage',
      },
      {
        header: t('stripeTaxRate.list.table.createdAt'),
        accessorFn: (tr) => formatUNIXToDate(tr.created),
      },
      {
        header: t('stripeTaxRate.list.table.operations'),
        accessorFn: (tr) =>
          makeCustomOperationButtons(
            makeCustomOperationButton(t('stripeTaxRate.list.table.edit'), () =>
              history.push(Routes.AdminStripeTaxRateEdit, { taxRate: tr })
            )
          ),
      },
    ],
    [history, t]
  )

  return useTanstackTable<ITaxRate>(columns, taxRates, {
    fixedLastColumn: true,
  })
}

export default TableBody
