import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IVideo } from 'common/interfaces/video'
import { isLoggedIn } from 'common/utils'
import { isImageBunnyThumbnail } from 'common/utils_bunny'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedVideos } from 'providers/AuthProvider'
import React from 'react'
import { removeFromFirestoreVideo } from 'repositories/functions/functions_video'
import { checkVideoLibraryReady } from 'repositories/functions/functions_video_library'
import { removeImageFile } from 'repositories/storage/firebase_video'
import { onSnapshot, remove } from 'repositories/store/firebase_video'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'
import { validateDeleteVideo } from 'services/validation/video'
import { IUploadProgress, uploadProgressService } from './upload_progress'

/**
 * 動画の一覧をストリーミングする
 * @returns unsubscribe function
 */
export const streamVideos = (
  storeCache: IStoreCache,
  setVideos: React.Dispatch<React.SetStateAction<IVideo[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
): (() => void) => {
  if (!isLoggedIn(storeCache)) return () => {}

  return onSnapshot(storeCache.team!, (videos) => {
    setVideos(videos)
    setIsLoading(false)
  })
}

/**
 * 動画ライブラリの準備中か否かをストリーミングする
 */
export const streamPreparingVideoLibrary = async (
  storeCache: IStoreCache,
  setIsPreparing: React.Dispatch<React.SetStateAction<boolean>>
): Promise<(() => void) | null> => {
  if (!isLoggedIn(storeCache)) return null

  const isPreparing = async (): Promise<boolean> => {
    const now = Math.floor(Date.now() / 1000)
    const diff = now - storeCache.team!.apply_date.seconds
    if (diff > 60 * 5) {
      return false
    }
    const isReady = await checkVideoLibraryReady(
      storeCache.team!.id,
      storeCache.user!.id
    )
    return !isReady
  }

  const firstPreparing = await isPreparing()
  setIsPreparing(firstPreparing)
  if (!firstPreparing) return null

  const timer = setInterval(async () => {
    const preparing = await isPreparing()
    setIsPreparing(preparing)
    if (!preparing) clearInterval(timer)
  }, 10 * 1000)
  return () => clearInterval(timer)
}

/**
 * 動画がアップロード中かを返却する
 */
export const isUploading = (
  uploadingVideoIds: string[],
  targetVideoId: string
): boolean => uploadingVideoIds.includes(targetVideoId)

/**
 * 動画のサムネイルと動画を削除する共通処理
 */
const doRemoveVideo = async (video: IVideo, storeCache: IStoreCache) => {
  try {
    const isImageFromBunny = isImageBunnyThumbnail(storeCache.team!, video)
    if (video.image && !isImageFromBunny) {
      await removeImageFile(storeCache.team!, video)
    }
  } catch (error: any) {
    if (error.code !== 'storage/object-not-found') {
      console.log(error)
      alertService.show(false, i18nAlert('deleted.fail.message'))
      return
    }
  }

  try {
    if (
      video.bunny.videoId !== '-1' &&
      !(await removeFromFirestoreVideo(storeCache, video))
    ) {
      throw new Error(i18nAlert('deleted.fail.message'))
    }

    await remove(storeCache.team!, video)
    await reloadCachedVideos(storeCache)

    alertService.show(true, i18nAlert('deleted.message'))
  } catch (error) {
    console.log(error)
    alertService.show(false, i18nAlert('deleted.fail.message'))
  }
}

/**
 * videosテーブルのレコードを削除する
 */
export const removeVideo = (video: IVideo, storeCache: IStoreCache) => {
  if (!isLoggedIn(storeCache)) return
  try {
    validateDeleteVideo(storeCache.team!, video)
  } catch (e: any) {
    alertService.show(false, e.message)
    return
  }

  modalService.showDeleteVideo(() => doRemoveVideo(video, storeCache))
}

/**
 * アップロード中の動画を削除する
 */
export const cancelVideoUpload = (
  { videoId }: IUploadProgress,
  storeCache: IStoreCache
) => {
  if (!isLoggedIn(storeCache)) return

  modalService.show(i18nAlert('modal.confirm.cancelToDelete'), () => {
    const video = storeCache.videos.find((v) => v.id === videoId)
    if (!video) {
      alertService.show(false, i18nAlert('video.cantFind'))
      return
    }

    uploadProgressService.cancel(videoId)
    doRemoveVideo(video, storeCache)
  })
}

export const showUploadErrorModal = (
  { push }: History,
  storeCache: IStoreCache,
  video: IVideo,
  videoFileName?: string
) => {
  uploadProgressService.remove(video.id)

  const message = (() => {
    let msg = i18nAlert('video.failedUpload.recreateAfterDeleteVideo', {
      videoName: video.name,
    })
    if (videoFileName) {
      msg += i18nAlert('video.fileName', { videoFileName })
    }

    return i18nAlert('video.backToRegisterPage.afterDelete', { message: msg })
  })()

  modalService.show(message, () => {
    removeVideo(video, storeCache)
    push({ pathname: Routes.AdminVideoReCreate, state: { video } })
  })
}
