import {
  HeaderItemOrderType,
  IHeaderItemOrder,
} from 'common/interfaces/header_item_order'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Button, Col, Row } from 'reactstrap'
import {
  onAddItem as serviceOnAddItem,
  onDragEnd as serviceOnDragEnd,
  onRemoveItem as serviceOnRemoveItem,
  updateTeamHeaderItemOrder,
} from 'services/admin/site_customize_header_order'
import '../order-customize-style.scss'
import { AddCard, ItemCard } from './ElementsOnHeaderItemOrder'

const FormBody: React.FC = () => {
  const { t } = useTranslation('adminSiteCustomize')
  const { storeCache } = useContext(AuthContext)
  const [selectedItems, setSelectedItems] = useState<IHeaderItemOrder[]>(
    storeCache.team?.header_item_order ?? []
  )

  if (!storeCache.team) return null

  const onAddItem = (type: HeaderItemOrderType) =>
    serviceOnAddItem(type, { selectedItems, setSelectedItems })
  const onRemoveItem = (position: number) =>
    serviceOnRemoveItem(position, { selectedItems, setSelectedItems })
  const onDragEnd = (result: DropResult) =>
    serviceOnDragEnd(result, { selectedItems, setSelectedItems })
  const update = () => updateTeamHeaderItemOrder(selectedItems, storeCache)

  return (
    <div className="order-customize-style">
      <Row>
        <Col md="4" className="all-items">
          <div className="scroll-box">
            <AddCard onAddItem={onAddItem} />
          </div>
        </Col>
        <Col md="8" className="selected-items">
          <div className="scroll-box">
            <h2>{t('headerCustomize.showOrder')}</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {selectedItems.map((item, index) => {
                      const id = item.type + index
                      return (
                        <Draggable key={id} draggableId={id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ItemCard
                                item={item}
                                index={index}
                                onRemove={onRemoveItem}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Col>
      </Row>

      <div className="text-center mt-3">
        <Button onClick={update} className="my-4" color="primary" type="button">
          {t('save')}
        </Button>
      </div>
    </div>
  )
}

export default FormBody
