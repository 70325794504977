import NightLogo from 'assets/images/night.svg'
import OneStreamIcon from 'assets/images/onestream_icon.png'
import { EntryType, Routes } from 'common/enums'
import { ISubscription } from 'common/interfaces/subscription'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { Button, Container } from 'reactstrap'
import { signOut } from 'services/admin/account'
import { changeCard } from 'services/admin/basic_info'
import { setSubscription } from 'services/entry'
import './body.scss'

const Body: React.FC = () => {
  const { t } = useTranslation('common')
  const { storeCache } = useContext(AuthContext)
  const { state } = useLocation<{ type: EntryType }>()
  const history = useHistory()

  const [unpaidSubscription, setUnpaidSubscription] =
    useState<ISubscription | null>(null)
  const [entryType, setEntryType] = useState<EntryType>(
    state && 'type' in state ? state.type : EntryType.NOT_SUBSCRIBE
  )

  useEffect(() => {
    setSubscription(storeCache, setUnpaidSubscription, setEntryType)
  }, [storeCache])

  const onClick = () => {
    switch (entryType) {
      case EntryType.NOT_SUBSCRIBE:
        history.push(Routes.AdminItemSelect)
        break
      case EntryType.UNPAID_ADMIN:
        changeCard(storeCache, unpaidSubscription!)
        break
      case EntryType.NOT_TEAM:
        window.location.href = 'https://onestream.channel.io/lounge'
        break
      default:
        null
    }
  }
  const logOut = () => signOut(storeCache.team!, storeCache.user!)

  return (
    <div className="main-content">
      <Container className="expire-container">
        <div>
          <img className="night-logo" src={NightLogo} alt="night logo" />
        </div>
        <div className="expire-title">
          <img src={OneStreamIcon} alt="onestream icon" />
          <Title type={entryType} />
        </div>
        <div className="expire-text">
          <Description type={entryType} />
        </div>
        {entryType !== EntryType.UNPAID_UESR && (
          <div className="avtive-button">
            <Button onClick={onClick} color="primary" type="button">
              <Action type={entryType} />
            </Button>
          </div>
        )}
        <div className="avtive-button">
          <Button onClick={logOut} type="button">
            {t('logout')}
          </Button>
        </div>
        <div className="customer-support">
          <Trans
            t={t}
            i18nKey="entry.contactCustomerSupport"
            components={{
              customerSupportLink: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a href="https://onestream.channel.io/lounge" />
              ),
            }}
          />
        </div>
      </Container>
    </div>
  )
}

interface Props {
  type: EntryType
}

const Title: React.FC<Props> = ({ type }: Props) => {
  const { t } = useTranslation('common')

  switch (type) {
    case EntryType.NOT_SUBSCRIBE:
      return <>{t('entry.notSubscribe.title')}</>
    case EntryType.UNPAID_ADMIN:
      return <>{t('entry.unpaidAdmin.title')}</>
    case EntryType.UNPAID_UESR:
      return <>{t('entry.unpaidUser.title')}</>
    case EntryType.NOT_TEAM:
      return <>{t('entry.notTeam.title')}</>
    default:
      return <></>
  }
}

const Description: React.FC<Props> = ({ type }: Props) => {
  const { t } = useTranslation('common')

  switch (type) {
    case EntryType.NOT_SUBSCRIBE:
      return <>{t('entry.notSubscribe.description')}</>
    case EntryType.UNPAID_ADMIN:
      return <>{t('entry.unpaidAdmin.description')}</>
    case EntryType.UNPAID_UESR:
      return <>{t('entry.unpaidUser.description')}</>
    case EntryType.NOT_TEAM:
      return <>{t('entry.notTeam.description')}</>
    default:
      return <></>
  }
}

const Action: React.FC<Props> = ({ type }: Props) => {
  const { t } = useTranslation('common')

  switch (type) {
    case EntryType.NOT_SUBSCRIBE:
      return <>{t('entry.notSubscribe.action')}</>
    case EntryType.UNPAID_ADMIN:
      return <>{t('entry.unpaidAdmin.action')}</>
    case EntryType.NOT_TEAM:
      return <>{t('entry.notTeam.action')}</>
    default:
      return <></>
  }
}

export default Body
