import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { IFilelist } from 'common/interfaces/filelist'
import { dateFormat } from 'common/times'
import { downloadFile, getFileSize } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeCustomOperationButtons,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getFilelistUseCount, removeFilelist } from 'services/admin/filelist'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminFilelist')
  const { filelists } = useContext(AuthContext).storeCache

  if (filelists.length <= 0) {
    return <RegisterItemButton type="filelist" />
  }
  return (
    <>
      <ButtonRight
        nextPage={Routes.AdminFilelistCreate}
        content={t('list.newRegister')}
      />
      <Table />
    </>
  )
}

const Table: React.FC = () => {
  const { t } = useTranslation('adminFilelist')
  const { storeCache } = useContext(AuthContext)
  const { filelists } = storeCache

  const columns = useMemo<ColumnDef<IFilelist>[]>(
    () => [
      {
        header: t('list.table.name'),
        accessorKey: 'name',
      },
      {
        header: t('list.table.fileSize'),
        accessorFn: (f) => getFileSize(f.size),
      },
      {
        header: t('list.table.uploadedAt'),
        accessorFn: (f) => dateFormat(f.created_at),
      },
      {
        header: t('list.table.usingVideoCount.title'),
        accessorFn: (f) =>
          t('list.table.usingVideoCount.count', {
            usingFilelistCount: getFilelistUseCount(f, storeCache),
          }),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (f) =>
          makeCustomOperationButtons([
            makeCustomOperationButton(t('list.table.download'), () =>
              downloadFile(f)
            ),
            makeCustomOperationButton(t('list.table.delete'), () =>
              removeFilelist(f, storeCache)
            ),
          ]),
      },
    ],
    [storeCache, t]
  )

  const data = filelists.sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  )
  return useTanstackTable<IFilelist>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
