import { ITest } from 'common/interfaces/test'
import H1 from 'components/atoms/Head/H1'
import TableBody from 'components/organisms/Admin/TestAnswer/TableBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'

const TestAnswer: React.FC = () => {
  const { t } = useTranslation('adminTest')
  const { test } = useLocation<{ test: ITest }>().state

  return (
    <>
      <H1 title={t('answer.title', { title: test.name })} />

      <Container fluid>
        <TableBody test={test} />
      </Container>
    </>
  )
}

export default TestAnswer
