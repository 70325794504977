import axios from 'axios'
import { IUserFromCSV } from 'common/interfaces/user'

const CF_API_BASE_URL = String(process.env.REACT_APP_FIREBASE_FUNCTIONS_URI)

/**
 * get custom token
 * @param uid team_id + user_id
 * @param password user password
 * @returns `string`
 */
export const getCustomToken = async (
  uid: string,
  password: string,
  isLogin: boolean
): Promise<string> => {
  const dir = isLogin
    ? '/auth/get_custom_token/login'
    : '/auth/get_custom_token'
  const url = CF_API_BASE_URL + dir
  const headers = { 'Content-Type': 'application/json' }
  const data = { uid, password }
  return (
    await axios.post<{ token: string; message: string }>(url, data, { headers })
  ).data.token
}

/**
 * create users by csv file
 * @param teamId `string`
 * @param id `string`
 * @param password `string`
 * @returns `_void`
 */
export const createCustomUser = async (
  teamId: string,
  id: string,
  password: string,
  is_password_changeable: boolean
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/auth/create_custom_user`
  const headers = { 'Content-Type': 'application/json' }
  const data = { teamId, id, password, is_password_changeable }
  await axios.post<void>(url, data, { headers })
}

/**
 * create users by csv file
 * @param teamId `string`
 * @param userFromCSV `IUserFromCSV[]`
 * @param customHeader `string[]`
 * @returns `_void`
 */
export const createUsersByCSV = async (
  teamId: string,
  userFromCSV: IUserFromCSV[],
  customHeader: string[]
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/auth/create_by_csv`
  const headers = { 'Content-Type': 'application/json' }
  const data = { teamId, userFromCSV, customHeader }
  await axios.post<void>(url, data, { headers })
}

/**
 * change password for custom auth method
 * @param userId `string`
 * @param password `string`
 * @returns `_void`
 */
export const changePasswordForCustomAuth = async (
  userId: string,
  password: string
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/auth/change_password`
  const headers = { 'Content-Type': 'application/json' }
  const data = { userId, password }
  await axios.post(url, data, { headers })
}

/**
 * save IP address by user
 * @param operatedTeamId `string`
 * @param operatedUserId `string`
 * @returns `boolean`
 */
export const saveUserIp = async (
  operatedTeamId: string,
  operatedUserId: string
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/auth/save_user_ip`
  const headers = { 'Content-Type': 'application/json' }
  const data = { operatedTeamId, operatedUserId }
  await axios.post(url, data, { headers })
}

/**
 * check single login
 * 他端末で認証している場合は`false`を返す
 *
 * @param operatedTeamId `string`
 * @param operatedUserId `string`
 * @returns `boolean`
 */
export const checkSingleLogin = async (
  operatedTeamId: string,
  operatedUserId: string
): Promise<boolean> => {
  const url = `${CF_API_BASE_URL}/auth/check_single_login`
  const headers = { 'Content-Type': 'application/json' }
  const data = { operatedTeamId, operatedUserId }
  return (
    await axios.post<{ isSingleLogin: boolean }>(url, data, {
      headers,
    })
  ).data.isSingleLogin
}

/**
 * check allowed IP address
 * @param operatedTeamId `string`
 * @param operatedUserId `string`
 * @returns `boolean`
 */
export const checkAllowedIp = async (
  operatedTeamId: string,
  operatedUserId: string
): Promise<boolean> => {
  const url = `${CF_API_BASE_URL}/auth/check_allowed_ip`
  const headers = { 'Content-Type': 'application/json' }
  const data = { operatedTeamId, operatedUserId }
  return (
    await axios.post<{ isAllowed: boolean }>(url, data, {
      headers,
    })
  ).data.isAllowed
}

/**
 * remove user in my team
 * @param operatedTeamId `string`
 * @param operatedUserId `string`
 * @param removeUserId `string`
 * @param stripeAccount `string` | `undefined` stripe account id
 * @returns `boolean`
 */
export const removeUser = async (
  operatedTeamId: string,
  operatedUserId: string,
  removeUserId: string,
  stripeAccount?: string
): Promise<boolean> => {
  const url = `${CF_API_BASE_URL}/auth/remove_user`
  const headers = { 'Content-Type': 'application/json' }
  const data = { operatedTeamId, operatedUserId, removeUserId, stripeAccount }

  try {
    return (
      await axios.request<{ success: boolean }>({
        method: 'DELETE',
        url,
        headers,
        data,
      })
    ).data.success
  } catch (error) {
    return false
  }
}
