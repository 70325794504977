import { Routes } from 'common/enums'
import { IPrice } from 'common/interfaces/stripe/price'
import { IProduct } from 'common/interfaces/stripe/product'
import { goBackWithErrorAlert } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/StripePriceForm/FormBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initPriceForm } from 'services/admin/price_form'

const StripePriceForm: React.FC = () => {
  const { t } = useTranslation('adminStripeProduct')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const {
    pathname,
    state: { price, product },
  } = useLocation<{ price: IPrice; product: IProduct }>()

  const { team } = storeCache
  if (!team) {
    goBackWithErrorAlert(
      history,
      i18nAlert('stripe.setting.unfinish.notFunction')
    )
    return null
  }

  const isCreate = pathname === Routes.AdminStripePriceCreate

  return (
    <>
      <H1 title={isCreate ? t('form.new') : t('form.edit')} />

      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminStripePrice}
          state={{ product }}
          content={t('form.back')}
        />
        <FormBody
          isCreate={isCreate}
          storeCache={storeCache}
          team={team}
          product={product}
          priceObj={isCreate ? initPriceForm(product, team) : price}
          history={history}
        />
      </Container>
    </>
  )
}

export default StripePriceForm
