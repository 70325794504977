import { goBackWithErrorAlert } from 'common/utils'
import TabBody from 'components/organisms/User/PaidHistory/TabBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const PaidHistory: React.FC = () => {
  const { t } = useTranslation('userSubscription')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  if (!storeCache.user || !storeCache.user.customer_id) {
    goBackWithErrorAlert(history, t('paidHistory.noPaidHistory'))
  }

  return <TabBody storeCache={storeCache} />
}

export default PaidHistory
