import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ISendEmail } from 'common/interfaces/send_email'
import { isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { get, remove } from 'repositories/store/firebase_send_email'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 送信メールを取得
 */
export const getSendEmails = async (
  storeCache: IStoreCache,
  setData: React.Dispatch<React.SetStateAction<ISendEmail[]>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  setData(await get(storeCache.team!))
}

/**
 * 送信メール削除処理
 */
export const removeSendEmail = (
  { replace }: History,
  sendEmail: ISendEmail,
  storeCache: IStoreCache
) => {
  if (!isLoggedIn(storeCache)) return

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      await remove(storeCache.team!, sendEmail)

      alertService.show(true, i18nAlert('deleted.message'))
      replace(Routes.AdminSendEmail)
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('cantDeleted'))
    }
  })
}

/**
 * グループIDからグループ名を取得
 */
export const getGroupNamesByIds = (
  storeCache: IStoreCache,
  groupIds: string[]
): string[] => {
  const groupNames = groupIds.map((groupId) => {
    const group = storeCache.groups.find((group) => group.id === groupId)
    return group?.name || ''
  })

  return groupNames
}
