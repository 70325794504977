import { findCachedCategory, findCachedTag } from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICategory } from 'common/interfaces/category'
import { ICreator } from 'common/interfaces/creator'
import { IEvent } from 'common/interfaces/event'
import { IPlaylist } from 'common/interfaces/playlist'
import { ITag } from 'common/interfaces/tag'
import {
  allLiveCategoryPath,
  allLiveCreatorPath,
  allLiveTagPath,
  allPlaylistCategoryPath,
  allPlaylistPath,
  allPlaylistTagPath,
  allVideoCategoryPath,
  allVideoCreatorPath,
  allVideoTagPath,
  makeCategoryLivePath,
  makeCategoryPlaylistPath,
  makeCategoryVideoPath,
  makeCreatorLivePath,
  makeCreatorVideoPath,
  makeEventPath,
  makePlaylistPath,
  makeTagLivePath,
  makeTagPlaylistPath,
  makeTagVideoPath,
  newerEventsPath,
  newerPlaylistsPath,
} from 'common/link_path'
import { sortByContentsOrderType, sortBySortNumber } from 'common/sort_contents'
import { showingEvents, showingPlaylists } from 'common/utils'
import { i18nUserIndex } from 'i18n/i18n'

/**
 * SideScrollGenreListのジャンルタイプenum
 */
export enum GenreType {
  CATEGORY_VIDEO,
  TAG_VIDEO,
  CREATOR_VIDEO,
  CATEGORY_LIVE,
  TAG_LIVE,
  CREATOR_LIVE,
  NEW_EVENTS,
  NEW_PLAYLISTS,
  CATEGORY_PLAYLIST,
  TAG_PLAYLIST,
  PLAYLIST_IN_CATEGORY,
  PLAYLIST_IN_TAG,
  PLAYLIST,
}

/**
 * ジャンルタイプに応じたタイトルを返す
 */
export const createTitle = (
  storeCache: IStoreCache,
  type: GenreType,
  targetId?: string
): string => {
  const i18n = i18nUserIndex('sideScrollGenreList.titles', {
    returnObjects: true,
  })
  switch (type) {
    case GenreType.CATEGORY_VIDEO:
      return i18n.videoCategory
    case GenreType.TAG_VIDEO:
      return i18n.videoTag
    case GenreType.CREATOR_VIDEO:
      return i18n.videoCreator
    case GenreType.CATEGORY_LIVE:
      return i18n.liveCategory
    case GenreType.TAG_LIVE:
      return i18n.liveTag
    case GenreType.CREATOR_LIVE:
      return i18n.liveCreator
    case GenreType.NEW_EVENTS:
      return i18n.newEvent
    case GenreType.NEW_PLAYLISTS:
      return i18n.newPlaylist
    case GenreType.CATEGORY_PLAYLIST:
      return i18n.playlistCategory
    case GenreType.TAG_PLAYLIST:
      return i18n.playlistTag
    case GenreType.PLAYLIST_IN_CATEGORY:
      return findCachedCategory(storeCache, targetId!)?.name ?? ''
    case GenreType.PLAYLIST_IN_TAG:
      return `#${findCachedTag(storeCache, targetId!)?.name ?? ''}`
    case GenreType.PLAYLIST:
      return i18n.playlist
  }
}

/**
 * ジャンルタイプに応じた「すべて表示」リンクを返す
 */
export const createShowAllLink = (
  type: GenreType,
  targetId?: string
): string => {
  switch (type) {
    case GenreType.CATEGORY_VIDEO:
      return allVideoCategoryPath()
    case GenreType.TAG_VIDEO:
      return allVideoTagPath()
    case GenreType.CREATOR_VIDEO:
      return allVideoCreatorPath()
    case GenreType.CATEGORY_LIVE:
      return allLiveCategoryPath()
    case GenreType.TAG_LIVE:
      return allLiveTagPath()
    case GenreType.CREATOR_LIVE:
      return allLiveCreatorPath()
    case GenreType.NEW_EVENTS:
      return newerEventsPath()
    case GenreType.NEW_PLAYLISTS:
      return newerPlaylistsPath()
    case GenreType.CATEGORY_PLAYLIST:
      return allPlaylistCategoryPath()
    case GenreType.TAG_PLAYLIST:
      return allPlaylistTagPath()
    case GenreType.PLAYLIST_IN_CATEGORY:
      return makeCategoryPlaylistPath(targetId!)
    case GenreType.PLAYLIST_IN_TAG:
      return makeTagPlaylistPath(targetId!)
    case GenreType.PLAYLIST:
      return allPlaylistPath()
  }
}

const getGenreName = (
  type: GenreType,
  genre: ICategory | ITag | ICreator | IEvent | IPlaylist
): string =>
  type === GenreType.TAG_VIDEO ||
  type === GenreType.TAG_LIVE ||
  type === GenreType.TAG_PLAYLIST
    ? `#${genre.name}`
    : genre.name

const getGenreLink = (
  type: GenreType,
  genre: ICategory | ITag | ICreator | IEvent | IPlaylist
): string => {
  switch (type) {
    case GenreType.CATEGORY_VIDEO:
      return makeCategoryVideoPath(genre.id)
    case GenreType.TAG_VIDEO:
      return makeTagVideoPath(genre.id)
    case GenreType.CREATOR_VIDEO:
      return makeCreatorVideoPath(genre.id)
    case GenreType.CATEGORY_LIVE:
      return makeCategoryLivePath(genre.id)
    case GenreType.TAG_LIVE:
      return makeTagLivePath(genre.id)
    case GenreType.CREATOR_LIVE:
      return makeCreatorLivePath(genre.id)
    case GenreType.CATEGORY_PLAYLIST:
      return makeCategoryPlaylistPath(genre.id)
    case GenreType.TAG_PLAYLIST:
      return makeTagPlaylistPath(genre.id)
    case GenreType.NEW_EVENTS:
      return makeEventPath(genre.id)
    case GenreType.NEW_PLAYLISTS:
    case GenreType.PLAYLIST_IN_CATEGORY:
    case GenreType.PLAYLIST_IN_TAG:
    case GenreType.PLAYLIST:
      return makePlaylistPath(genre.id)
  }
}

/**
 * ジャンルタイプに応じたデータを返す（7個まで）
 */
export const get7GenreData = (
  storeCache: IStoreCache,
  type: GenreType,
  targetId?: string
): {
  id: string
  name: string
  link: string
  image: string | null
}[] => {
  const { categories, tags, creators } = storeCache
  const playlistOrder = storeCache.team!.default_contents_orders.playlist
  const data = (
    (() => {
      switch (type) {
        case GenreType.CATEGORY_VIDEO:
        case GenreType.CATEGORY_LIVE:
        case GenreType.CATEGORY_PLAYLIST:
          return sortBySortNumber(categories)
        case GenreType.TAG_VIDEO:
        case GenreType.TAG_LIVE:
        case GenreType.TAG_PLAYLIST:
          return sortBySortNumber(tags)
        case GenreType.CREATOR_VIDEO:
        case GenreType.CREATOR_LIVE:
          return sortBySortNumber(creators)
        case GenreType.NEW_EVENTS:
          return showingEvents(storeCache).sort(
            (a, b) => b.created_at.seconds - a.created_at.seconds
          )
        case GenreType.NEW_PLAYLISTS:
          return showingPlaylists(storeCache).sort(
            (a, b) => b.created_at.seconds - a.created_at.seconds
          )
        case GenreType.PLAYLIST_IN_CATEGORY:
          const catPls = showingPlaylists(storeCache).filter(
            (p) => p.category_id === targetId
          )
          return sortByContentsOrderType(catPls, playlistOrder)
        case GenreType.PLAYLIST_IN_TAG:
          const tagPls = showingPlaylists(storeCache).filter((p) =>
            p.tag_ids.includes(targetId ?? '')
          )
          return sortByContentsOrderType(tagPls, playlistOrder)
        case GenreType.PLAYLIST:
          const pls = showingPlaylists(storeCache)
          return sortByContentsOrderType(pls, playlistOrder)
      }
    })() as Array<ICategory | ITag | IEvent | IPlaylist | ICreator>
  ).slice(0, 7)

  return data.map((genre) => ({
    id: genre.id,
    name: getGenreName(type, genre),
    link: getGenreLink(type, genre),
    image: genre.image,
  }))
}
