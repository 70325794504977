import { AuthMethodType } from 'common/enums'
import firebase, { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IHubSpotFormField } from 'common/interfaces/hubspot'
import { IAnyTeamItem } from 'common/interfaces/register'
import { ITeam } from 'common/interfaces/team'
import { checkFirebaseError, isLoggedIn } from 'common/utils'
import { i18nValidation } from 'i18n/i18n'
import { reloadCachedTeam, reloadCachedUser } from 'providers/AuthProvider'
import { submitForm } from 'repositories/hubspot/hubspot_form'
import { updateIAnyTeamItem } from 'repositories/store/firebase_team'
import { update as updateUser } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'

export enum StepNo {
  STEP1,
  STEP2,
}

/**
 * `IRegister`初期値
 */
export const initState = (team: ITeam): IAnyTeamItem => ({
  name: team.name,
  email:
    team.auth_method === AuthMethodType.CUSTOM
      ? ''
      : auth.currentUser?.email ?? '',
  company: team.company,
  phone: team.tel ?? '',
})

export enum InputType {
  TEAM_NAME,
  COMPANY,
  PHONE,
  USER_NAME,
}

/**
 * `onChange`イベントハンドラ
 */
export const onChangeInput = (
  state: IAnyTeamItem,
  setState: React.Dispatch<React.SetStateAction<IAnyTeamItem>>,
  type: InputType,
  { target: { value } }: React.ChangeEvent<HTMLInputElement>
) =>
  setState({
    ...state,
    name: type === InputType.TEAM_NAME ? value : state.name,
    company: type === InputType.COMPANY ? value : state.company,
    phone: type === InputType.PHONE ? value : state.phone,
  })

/**
 * 管理側専用任意入力モーダルのStep1「次へ」処理
 */
export const stepOne = async (
  { name }: IAnyTeamItem,
  setStepNo: (value: React.SetStateAction<StepNo>) => void
): Promise<void> => {
  try {
    if (!name) {
      throw new Error(i18nValidation('input.siteName'))
    }

    setStepNo(StepNo.STEP2)
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}

/**
 * 管理側専用任意入力モーダルでteamとuser情報を更新
 */
export const updateTeam = async (
  storeCache: IStoreCache,
  state: IAnyTeamItem,
  close: () => void
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    if (!state.name || !state.company || !state.phone) {
      throw new Error(i18nValidation('input.allFields'))
    }

    const team = storeCache.team!
    team.name = state.name
    team.company = state.company
    team.tel = state.phone
    await updateIAnyTeamItem(team, state)
    await reloadCachedTeam(storeCache)

    const user = storeCache.user!
    user.email = state.email
    user.phone = state.phone
    await updateUser(team, user)
    await reloadCachedUser(storeCache)

    sendHubSpotForm(storeCache, state, auth.currentUser!)
    close()
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}

const sendHubSpotForm = (
  { user }: IStoreCache,
  { company, phone }: IAnyTeamItem,
  { email, uid }: firebase.User
) => {
  const portalId = Number(process.env.REACT_APP_HUBSPOT_REGISTER_FORM_PORTAL_ID)
  const formId = String(process.env.REACT_APP_HUBSPOT_REGISTER_FORM_ID)

  const fields: IHubSpotFormField[] = [
    { objectTypeId: '0-1', name: 'company', value: company },
    { objectTypeId: '0-1', name: 'firstname', value: user!.name ?? '' },
    { objectTypeId: '0-1', name: 'phone', value: phone },
    { objectTypeId: '0-1', name: 'email', value: email ?? 'Not set email' },
    { objectTypeId: '0-1', name: 'message', value: `TeamId: ${uid}` },
  ]
  submitForm(portalId, formId, fields)
}
