import { str2hash } from 'common/utils'
import React, { useMemo } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { v4 as uuidv4 } from 'uuid'
import './infotooltip.scss'

interface IInfoItem {
  iconName?: string
  content: string
  tooltipContent: string
  onClick?: () => void
  required?: boolean
}

const InfoTooltip: React.FC<IInfoItem> = (props: IInfoItem) => {
  const uuid = useMemo(() => uuidv4(), [])
  const baseStr = props.content + props.tooltipContent + uuid
  const hashStr = `id-${str2hash(baseStr)}`
  const iconName = props.iconName || 'fas fa-info-circle'
  return (
    <div className="info-tooltip">
      {props.content}
      {props.required && <span className="info-tooltip__required">*</span>}
      <span className="info-tooltip__icon" id={hashStr}>
        <i className={iconName} onClick={props.onClick} />
      </span>
      <UncontrolledTooltip placement="right" target={hashStr}>
        {props.tooltipContent}
      </UncontrolledTooltip>
    </div>
  )
}

export default InfoTooltip
