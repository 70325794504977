import { SendEmailFormLimits, SendEmailStatus } from 'common/enums'
import { ISendEmail } from 'common/interfaces/send_email'
import { isAfterTimestamp } from 'common/times'
import { i18nValidation } from 'i18n/i18n'

/**
 * メール登録・編集用バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param sendEmail send_emailsドキュメント
 */
export const validateForm = ({
  title,
  subject,
  body,
  status,
  send_at,
}: ISendEmail): void => {
  if (!title) {
    throw new Error(i18nValidation('input.title'))
  }
  if (!subject) {
    throw new Error(i18nValidation('input.mail.subject'))
  }

  const stripTagRegex = /<\/?[^>]+(>|$)/g
  const strippedBody = body.replace(stripTagRegex, '')
  if (!strippedBody) {
    throw new Error(i18nValidation('input.mail.content'))
  }
  if (strippedBody.length > SendEmailFormLimits.CONTENT_LENGTH) {
    throw new Error(i18nValidation('input.mail.contentOverLimit'))
  }

  if (status === SendEmailStatus.SPECIFIED_TIME && isAfterTimestamp(send_at)) {
    throw new Error(i18nValidation('set.sendTime.after.operationDate'))
  }
}
