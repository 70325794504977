import { LimitPlay } from 'common/enums'
import { ILive } from 'common/interfaces/live'
import { IVideo } from 'common/interfaces/video'
import { convertCatalogIntoNormal } from 'common/link'
import { makeItemSelectContentPath } from 'common/link_path'
import { datetimeFormat } from 'common/times'
import { createCalendarLink, isLiveVideo } from 'common/utils'
import { History } from 'history'
import { i18nUserCommon } from 'i18n/i18n'
import { IListLocation } from 'services/item_select_video'
import { getPremiereEndDate } from './video'

/**
 * 公開前/後のテキストを取得
 * @param content `IVideo` | `ILive`
 * @param limitPlay `LimitPlay`
 * @returns `note`: 文言, `date`: 日付, `calendarLink`: カレンダーリンク生成関数
 */
export const getBeforeAfterTexts = (
  content: IVideo | ILive,
  limitPlay: LimitPlay
) => {
  const i18n = i18nUserCommon('limitPlayContainer', { returnObjects: true })

  const note = (() => {
    const i18nObj = isLiveVideo(content) ? i18n.live : i18n.video
    return limitPlay === LimitPlay.BEFORE ? i18nObj.before : i18nObj.after
  })()

  const date = (() => {
    if ('hold_from' in content) {
      return limitPlay === LimitPlay.BEFORE
        ? datetimeFormat(content.hold_from)
        : datetimeFormat(content.hold_to)
    }
    if (limitPlay === LimitPlay.BEFORE) {
      return datetimeFormat(content.playable_period!.from)
    }
    const premiereEndDate = getPremiereEndDate(content)
    return premiereEndDate
      ? datetimeFormat(premiereEndDate)
      : datetimeFormat(content.playable_period!.to)
  })()

  const calendarLink = (type: Parameters<typeof createCalendarLink>[3]) => {
    const isLive = 'hold_from' in content
    const start = isLive ? content.hold_from : content.playable_period!.from
    const end = isLive ? content.hold_to : content.playable_period!.to
    return createCalendarLink(content.name, start, end, type)
  }

  return { note, date, calendarLink }
}

/**
 * 商品選択画面に遷移する
 * @param isCatalogPage `boolean` カタログページかどうか
 * @param history `History`
 * @param content `IVideo` | `ILive` 閲覧中の動画又は、配信
 */
export const transitionItemSelect = (
  isCatalogPage: boolean,
  history: History,
  content: IVideo | ILive
): void =>
  history.push(makeItemSelectContentPath(), <IListLocation>{
    isCatalogPage,
    content,
    redirectPath: convertCatalogIntoNormal(),
  })
