import { ValidationModal } from 'common/interfaces/video_multi_update'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Modal, ModalBody } from 'reactstrap'

interface IValidateModalProps extends ValidationModal {
  toggleModal: () => void
}

export const ValidateModal: React.FC<IValidateModalProps> = (props) => {
  const { t } = useTranslation('validation')

  return (
    <Modal isOpen={props.open} toggle={props.toggleModal} centered>
      <ModalBody>
        {props.error === 'publicVideoHasNoGroups' && (
          <>
            <Trans t={t} i18nKey="selectedVideos.cantSet.publiclySetVideo" />
            <ul className="mt-3">
              {props.invalidVideos.map((video) => (
                <li key={video.id}>{video.name}</li>
              ))}
            </ul>
          </>
        )}

        <div className="mt-4 text-right">
          <Button color="primary" type="button" onClick={props.toggleModal}>
            OK
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
