import { AuthType } from 'common/auth_type'
import {
  AuthMethodType,
  IMailTemplateEventName,
  IMailTemplateType,
  Routes,
} from 'common/enums'
import firebase, { auth } from 'common/firebase'
import { ContentsOrderType } from 'common/interfaces/contents_orders'
import { HeaderItemOrderType } from 'common/interfaces/header_item_order'
import { IRegister } from 'common/interfaces/register'
import { IMailTemplate, ITeam, defaultColors } from 'common/interfaces/team'
import { TopItemOrderType } from 'common/interfaces/top_item_order'
import { IUser } from 'common/interfaces/user'
import { nowTimestamp, thirtyOneDaysLaterDateFormat } from 'common/times'
import { checkFirebaseError, getLangType } from 'common/utils'
import { History } from 'history'
import {
  i18nAlert,
  i18nMailTemplate,
  i18nRegister,
  i18nValidation,
} from 'i18n/i18n'
import { sendMailTemplate } from 'repositories/functions/functions_mail'
import { createVideoLibrary } from 'repositories/functions/functions_video_library'
import { store as storeTeam } from 'repositories/store/firebase_team'
import {
  findTeamUserById,
  store as storeUser,
} from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'

/**
 * `IRegister`初期値
 */
export const initState = (): IRegister => ({
  user_name: '',
  email: '',
  password: '',
})

export enum InputType {
  USER_NAME,
  EMAIL,
  PASS,
}

/**
 * `onChange`イベントハンドラ
 */
export const onChangeInput = (
  { user_name, email, password }: IRegister,
  setState: React.Dispatch<React.SetStateAction<IRegister>>,
  type: InputType,
  { target: { value } }: React.ChangeEvent<HTMLInputElement>
) =>
  setState({
    user_name: type === InputType.USER_NAME ? value : user_name,
    email: type === InputType.EMAIL ? value : email,
    password: type === InputType.PASS ? value : password,
  })

/**
 * Authentificationを作成する
 */
export const register = async (
  history: History,
  state: IRegister,
  authType: AuthType,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  setIsLoading(true)

  try {
    if (authType === AuthType.EMAIL && (!state.email || !state.password)) {
      throw new Error(i18nValidation('input.emailAndPassword'))
    }

    let userCredential: firebase.auth.UserCredential | undefined
    switch (authType) {
      case AuthType.EMAIL:
      case AuthType.CUSTOM:
        userCredential = await auth.createUserWithEmailAndPassword(
          state.email,
          state.password
        )
        break
      case AuthType.GOOGLE:
        userCredential = await auth.signInWithPopup(
          new firebase.auth.GoogleAuthProvider()
        )
        break
      default:
        break
    }
    if (!userCredential || userCredential.user === null) {
      throw new Error(i18nAlert('created.fail.authUser'))
    }
    if (
      userCredential.additionalUserInfo &&
      !userCredential.additionalUserInfo.isNewUser
    ) {
      alertService.show(true, i18nAlert('auth.loggedIn.alreadyRegistered'))
      history.replace(Routes.Admin)
      return
    }

    await registerTeamAndFirstUser(
      history,
      state,
      authType,
      userCredential.user
    )
  } catch (error) {
    console.log(error)
    const message = checkFirebaseError(error)
    alertService.show(false, message)
  }

  setIsLoading(false)
}

/**
 * チームを作成し、初期ユーザーを追加する
 */
const registerTeamAndFirstUser = async (
  history: History,
  { user_name, email }: IRegister,
  authType: AuthType,
  user: firebase.User
): Promise<void> => {
  try {
    const alreadyUseEmail = (await findTeamUserById(user.uid)) !== null
    if (alreadyUseEmail) {
      throw new Error(i18nAlert('auth.alreadyUsedEmail'))
    }

    const videoLibrary = await createVideoLibrary(user.uid, user.uid)
    if (!videoLibrary) {
      throw new Error(i18nAlert('created.fail.videoLibrary'))
    }

    const userName =
      authType === AuthType.GOOGLE ? user.displayName ?? '' : user_name
    const now = nowTimestamp()
    const lang = getLangType()
    const team: ITeam = {
      id: user.uid,
      bunny: videoLibrary,
      name: i18nRegister('yourVideoSite', { userName, lng: lang }),
      email: null,
      urls: {
        about: null,
        terms: null,
        contact: null,
        faq: null,
        law: null,
        account_register: null,
      },
      colors: defaultColors,
      logo: null,
      top_images: [],
      address: null,
      tel: '',
      company: '',
      apply_date: now,
      cancelled_date: null,
      expire_date: thirtyOneDaysLaterDateFormat(),
      auth_method: AuthMethodType.EMAIL,
      hide_footer_logo: false,
      social_logins: { google: true },
      is_password_complex: false,
      is_comment_active: true,
      is_invoice_payment: false,
      is_single_login: false,
      is_site_public: false,
      enquete_id: '',
      item_order: [
        { type: TopItemOrderType.TOP_IMAGES },
        { type: TopItemOrderType.CHOOSE_GENRE_CATEGORY },
        { type: TopItemOrderType.CHOOSE_GENRE_TAG },
        { type: TopItemOrderType.CHOOSE_GENRE_CREATOR },
        { type: TopItemOrderType.CHOOSE_PLAY_STATUS },
        { type: TopItemOrderType.SEARCH_VIDEO_INPUT },
        { type: TopItemOrderType.DIVIDER },
        { type: TopItemOrderType.VIDEOS_NEWER },
        { type: TopItemOrderType.VIDEOS_POPULAR },
      ],
      header_item_order: [
        { type: HeaderItemOrderType.MY_ACCOUNT },
        { type: HeaderItemOrderType.USER_VIEW_LOGS },
        { type: HeaderItemOrderType.USER_BOOKMARKS },
        { type: HeaderItemOrderType.USER_WATCH_LATER },
        { type: HeaderItemOrderType.DIVIDER },
        { type: HeaderItemOrderType.LOGOUT },
      ],
      customize_field: [],
      landing_page: {
        banner_image: null,
        site_overview: '',
        intro_video_bunny_id: null,
      },
      allow_ips: { admin: [], user: [] },
      stripe: {
        account_id: '',
        is_products_activate: false,
        allow_invoice_payment: false,
        subscription_product_id: '',
      },
      saml_provider_id: '',
      stripeId: '',
      mail_templates: [
        defaultMailTemplate(IMailTemplateEventName.WELCOME, now),
        defaultMailTemplate(IMailTemplateEventName.APPROVAL, now),
        defaultMailTemplate(IMailTemplateEventName.COMPLETED_PURCHASE, now),
        defaultMailTemplate(IMailTemplateEventName.CANCEL_SUBSCRIPTION, now),
      ],
      default_contents_orders: {
        video: ContentsOrderType.NEWER,
        live: ContentsOrderType.NEWER,
        event: ContentsOrderType.NEWER,
        playlist: ContentsOrderType.NEWER,
      },
      lang,
    }

    const firstUser: IUser = {
      id: user.uid,
      origin_id: user.uid,
      disabled: false,
      name: userName,
      birthdate: '',
      address: '',
      phone: '',
      any_id: '',
      admin: true,
      email,
      bookmarks: [],
      invited_id: null,
      is_approved: 'undefined',
      is_password_changeable: false,
      customer_id: null,
      group_ids: [],
      recent_ip: '',
      admin_notification_setting: {
        encode_completed: true,
        user_first_subscription_payment: false,
        user_payment_completed: false,
        user_registration_completed: false,
        user_video_commented: false,
        user_video_enquete_answered: false,
        user_subscription_removed: false,
        user_account_deleted: false,
      },
      notification_setting: { news: true },
      zoom_user_id: null,
      expire: firebase.firestore.Timestamp.fromMillis(0),
      created_at: now,
      updated_at: now,
    }
    await storeTeam(team)
    await storeUser(team, firstUser)

    if (firstUser.email) {
      await sendMailTemplate(
        team.id,
        IMailTemplateType.ADMIN_REGISTER,
        firstUser.email,
        ['info@rootteam.co.jp']
      )
    }

    history.push(Routes.Admin)
  } catch (error) {
    console.log(error)
    const message = checkFirebaseError(error)
    alertService.show(false, message)
  }
}

const defaultMailTemplate = (
  event_name: IMailTemplateEventName,
  now: firebase.firestore.Timestamp
): IMailTemplate => ({
  event_name,
  event_detail: getEventDetail(event_name),
  status: false,
  email_title: '',
  email_content: '',
  updated_at: now,
})

const getEventDetail = (eventName: IMailTemplateEventName) => {
  switch (eventName) {
    case IMailTemplateEventName.APPROVAL:
      return i18nMailTemplate('defaultMailTemplate.approval')
    case IMailTemplateEventName.WELCOME:
      return i18nMailTemplate('defaultMailTemplate.welcome')
    case IMailTemplateEventName.COMPLETED_PURCHASE:
      return i18nMailTemplate('defaultMailTemplate.completePurchase')
    case IMailTemplateEventName.CANCEL_SUBSCRIPTION:
      return i18nMailTemplate('defaultMailTemplate.cancelSubscription')
  }
}
