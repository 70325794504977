import { AuthType } from 'common/auth_type'
import { Routes } from 'common/enums'
import { IInvite } from 'common/interfaces/invite'
import { IAdminLogin } from 'common/interfaces/login'
import { ISubscriptionObject } from 'common/interfaces/subscription'
import { ITeam } from 'common/interfaces/team'
import { isTrialPlan } from 'common/utils'
import AccentButton from 'components/atoms/Button/Block/AccentButton'
import SignInWithGoogle from 'components/atoms/Button/SignInWithGoogle'
import ButtonLoading from 'components/atoms/Loading/ButtonLoading'
import Footer from 'components/molecules/Footer'
import { UsingTrialNavbar } from 'components/molecules/User/UsingTrialNavbar/UsingTrialNavbar'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from 'reactstrap'
import { findTeamAdministrator, joinAdministrator } from 'services/join'

const FormBody: React.FC = () => {
  const { t } = useTranslation('join')
  const inviteId = useParams<{ inviteId: string }>().inviteId
  const history = useHistory()
  const [joinState, setJoinState] = useState<IAdminLogin>({
    email: '',
    password: '',
    name: '',
  })
  const [team, setTeam] = useState<ITeam | null>(null)
  const [invite, setIInvite] = useState<IInvite | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [errorReason, setErrorReason] = useState<string>('')
  const [isRegisterLoading, setIsRegisterLoading] = useState<boolean>(false)
  const [subscriptionObj, setSubscriptionObj] =
    useState<ISubscriptionObject | null>(null)

  useEffect(() => {
    findTeamAdministrator(
      inviteId,
      setTeam,
      setIInvite,
      setIsLoading,
      setErrorReason,
      setSubscriptionObj
    )
  }, [inviteId])

  if (isLoading) return null
  if (!team || !invite || errorReason) {
    history.replace(Routes.InvalidInviteCode, { message: errorReason })
    return null
  }

  enum InputType {
    EMAIL,
    PASS,
    NAME,
  }

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = e.target.value
    setJoinState({
      email: type === InputType.EMAIL ? val : joinState.email,
      password: type === InputType.PASS ? val : joinState.password,
      name: type === InputType.NAME ? val : joinState.name,
    })
  }

  const doJoin = (authType: AuthType) =>
    joinAdministrator(
      history,
      setIsRegisterLoading,
      team,
      invite,
      inviteId,
      joinState,
      authType
    )

  const formElement = !team.saml_provider_id ? (
    <>
      <FormGroup className="mb-3">
        <InputGroup className="input-group-alternative">
          <Input
            onChange={(e) => onChangeInput(InputType.EMAIL, e)}
            placeholder={t('form.email')}
            type="email"
            autoComplete="new-email"
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup className="input-group-alternative">
          <Input
            onChange={(e) => onChangeInput(InputType.PASS, e)}
            placeholder={t('form.password')}
            type="password"
            autoComplete="new-password"
          />
        </InputGroup>
      </FormGroup>

      <div className="text-center">
        {isRegisterLoading ? (
          <ButtonLoading className="my-2" />
        ) : (
          <AccentButton
            onClick={() => doJoin(AuthType.EMAIL)}
            className="my-4"
            content={t('form.createAccount')}
            colors={team.colors}
          />
        )}
      </div>

      {team.social_logins.google && (
        <SignInWithGoogle onClick={() => doJoin(AuthType.GOOGLE)} centered />
      )}
    </>
  ) : (
    <div className="text-center mt-3">
      <Button color="primary" onClick={() => doJoin(AuthType.SAML)}>
        {t('form.saml')}
      </Button>
    </div>
  )

  return (
    <div>
      {!isLoading && isTrialPlan(subscriptionObj) && <UsingTrialNavbar />}
      <div className="main-content">
        <Container className="mt-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  {team.logo && (
                    <div className="text-center mb-4">
                      <img
                        src={team.logo.path}
                        alt={team.name}
                        width="200"
                        height="auto"
                        style={{ borderRadius: '4px' }}
                      />
                    </div>
                  )}
                  <div className="text-center text-muted mb-3">
                    <div className="mb-5">
                      <Trans
                        t={t}
                        i18nKey="title"
                        components={[<strong />]}
                        values={{ team: team.name }}
                      />
                    </div>
                    <div>{t('adminOnly')}</div>
                  </div>
                  <Form role="form">
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <Input
                          onChange={(e) => onChangeInput(InputType.NAME, e)}
                          placeholder={t('form.name')}
                          type="text"
                          autoComplete="new-name"
                        />
                      </InputGroup>
                    </FormGroup>

                    {formElement}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Footer team={team} />
      </div>
    </div>
  )
}

export default FormBody
