import { IMailTemplate } from 'common/interfaces/team'
import { standardModules } from 'common/quill'
import QuillForm from 'components/atoms/Input/QuillForm'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { getMailTemplateEventName } from 'components/atoms/Table/ElementsOnTable'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { InputType } from 'services/admin/mail_template_form'

type TMailTemplateForm = {
  isAdministrator: boolean
  mailTemplate: IMailTemplate
  onChange: (
    inputType: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  onChangeQuill: (e: string) => void
  save: () => void
}

const MailTemplateForm: React.FC<TMailTemplateForm> = ({
  isAdministrator,
  mailTemplate,
  onChange,
  onChangeQuill,
  save,
}: TMailTemplateForm) => {
  const adminMailTemplate = useTranslation('adminMailTemplate')
  const adminCommon = useTranslation('adminCommon')
  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="mailTemplateEventName">
              {adminMailTemplate.t('form.eventName')}
            </Label>
            <Input
              id="mailTemplateEventName"
              type="text"
              defaultValue={getMailTemplateEventName(mailTemplate.event_name)}
              disabled
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="mailTemplateEventDetail">
              {adminMailTemplate.t('form.eventDetail')}
            </Label>
            <Input
              id="mailTemplateEventDetail"
              type="text"
              defaultValue={mailTemplate.event_detail}
              disabled
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="mailTemplateStatus">
          <InfoTooltip
            content={adminMailTemplate.t('form.status.title')}
            tooltipContent={adminMailTemplate.t('form.status.tips')}
          />
        </Label>
        <Row>
          <Col md="9">
            <label className="custom-toggle" id="mailTemplateStatus">
              <input
                type="checkbox"
                onChange={(e) => onChange(InputType.STATUS, e)}
                checked={mailTemplate.status}
              />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
          </Col>
        </Row>
      </FormGroup>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="mailTemplateTitle">
              <InfoTooltip
                content={adminMailTemplate.t('form.mailTitle.title')}
                tooltipContent={adminMailTemplate.t('form.mailTitle.tips')}
              />
            </Label>
            <Input
              id="mailTemplateTitle"
              type="text"
              onChange={(e) => onChange(InputType.EMAIL_TITLE, e)}
              defaultValue={mailTemplate.email_title}
              disabled={isAdministrator}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="mailTemplateContent">
              <InfoTooltip
                content={adminMailTemplate.t('form.mailContents.title')}
                tooltipContent={adminMailTemplate.t('form.mailContents.tips')}
              />
            </Label>
            <div>{adminMailTemplate.t('form.mailContents.tips2')}</div>
            <div>
              {`{{user_name}}：${adminMailTemplate.t(
                'form.mailContents.replacer.userName'
              )}`}
            </div>
            <div>
              {`{{site_name}}：${adminMailTemplate.t(
                'form.mailContents.replacer.siteName'
              )}`}
            </div>
            <div>
              {`{{login_url}}：${adminMailTemplate.t(
                'form.mailContents.replacer.loginUrl'
              )}`}
            </div>
            <pre>
              <QuillForm
                id="mailTemplateContent"
                onChange={onChangeQuill}
                modules={standardModules()}
                defaultValue={mailTemplate.email_content}
                readOnly={isAdministrator}
              />
            </pre>
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {adminCommon.t('save')}
        </Button>
      </div>
    </Form>
  )
}

export default MailTemplateForm
