import { IStripe } from 'common/interfaces/stripe'
import { goBackWithErrorAlert, isBasicPrice } from 'common/utils'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Col, Input, Label, Row } from 'reactstrap'
import {
  disconnectZoomAccount,
  zoomOAuthStartUrl,
} from 'services/admin/admin_setting_zoom_setting'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'

const ZoomSettingBody: React.FC = () => {
  const { t } = useTranslation('adminSetting')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const [stripe, setStripe] = useState<IStripe>(initStripe())

  useEffect(() => {
    getStripe(setStripe)
  }, [])

  if (isBasicPrice(storeCache.subscriptionObj, stripe)) {
    goBackWithErrorAlert(history, i18nAlert('noOperationAuthority'))
    return null
  }

  const zoomUserId = storeCache.user?.zoom_user_id ?? ''
  const isZoomConnected = Boolean(zoomUserId)

  return (
    <>
      <Label>{t('zoomSetting.userId')}</Label>
      <Row>
        <Col xs="8" md="6">
          <Input value={zoomUserId} readOnly />
        </Col>
        <Col xs="4">
          {isZoomConnected ? <DisconnectButton /> : <OAuthButton />}
        </Col>
      </Row>
    </>
  )
}

const OAuthButton: React.FC = () => {
  const { t } = useTranslation('adminSetting')
  return (
    <Button color="primary" href={zoomOAuthStartUrl}>
      {t('zoomSetting.connect')}
    </Button>
  )
}

const DisconnectButton: React.FC = () => {
  const { t } = useTranslation('adminSetting')
  const { storeCache } = useContext(AuthContext)
  const disconnect = () => disconnectZoomAccount(storeCache)
  return (
    <Button color="danger" onClick={disconnect}>
      {t('zoomSetting.disconnect')}
    </Button>
  )
}

export default ZoomSettingBody
