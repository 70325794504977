import { ColumnDef } from '@tanstack/react-table'
import 'chart.js/auto'
import { IVideoViewCount } from 'common/interfaces/analytics'
import { IFlattedLog } from 'common/interfaces/log'
import { IFromTo } from 'common/interfaces/time'
import { useTanstackTableWithCsvExport } from 'components/atoms/Table/CreateTable'
import AnalyticsRangeSelector from 'components/molecules/Admin/AnalyticsRangeSelector'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import {
  DatePeriod,
  getSortedUserLogs,
  initFromTo,
  setMonthSelectOptions,
} from 'services/admin/analytics'
import {
  createVideoViewCountChartData,
  setVideoViewCountData,
} from 'services/admin/analytics_videos'

const ChartBody: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const [allUserLogs, setAllUserLogs] = useState<IFlattedLog[]>([])
  const [monthOptions, setMonthOptions] = useState<JSX.Element[]>([])
  const [selectedMonth, setSelectedMonth] = useState<string>('')
  const [videoViewCounts, setVideoViewCounts] = useState<IVideoViewCount[]>([])
  const [selectedDatePeriod, setSelectedDatePeriod] = useState<DatePeriod>(
    DatePeriod.DAYS_7
  )
  const [fromTo, setFromTo] = useState<IFromTo>(initFromTo())

  useEffect(() => {
    getSortedUserLogs(storeCache, setAllUserLogs)
  }, [storeCache])

  useEffect(() => {
    setMonthSelectOptions(allUserLogs, setMonthOptions, setSelectedMonth)
  }, [allUserLogs])

  useEffect(() => {
    setVideoViewCountData(
      storeCache,
      allUserLogs,
      selectedDatePeriod,
      setVideoViewCounts,
      { date: selectedMonth, fromTo }
    )
  }, [storeCache, allUserLogs, selectedDatePeriod, selectedMonth, fromTo])

  if (!storeCache.team) return null

  const { playChart, endedChart } = createVideoViewCountChartData(
    videoViewCounts,
    selectedDatePeriod,
    { date: selectedMonth, fromTo }
  )

  return (
    <>
      <AnalyticsRangeSelector
        className="mb-3"
        monthOptions={monthOptions}
        selectedDatePeriod={[selectedDatePeriod, setSelectedDatePeriod]}
        selectedMonth={[selectedMonth, setSelectedMonth]}
        fromTo={[fromTo, setFromTo]}
      />
      <Row>
        <Col md="6" className="mt-3 mt-md-0">
          <Chart type="pie" data={playChart[0]} options={playChart[1]} />
        </Col>
        <Col md="6" className="mt-3 mt-md-0">
          <Chart type="pie" data={endedChart[0]} options={endedChart[1]} />
        </Col>
      </Row>
      <div className="mt-5">
        <Table viewCounts={videoViewCounts} />
      </div>
    </>
  )
}

const Table: React.FC<{ viewCounts: IVideoViewCount[] }> = ({ viewCounts }) => {
  const { t } = useTranslation('adminAnalytics')
  const columns = useMemo<ColumnDef<IVideoViewCount>[]>(
    () => [
      {
        header: t('videoTable.videoTitle'),
        accessorKey: 'video_name',
      },
      {
        header: t('logType.videoPageOpen'),
        accessorKey: 'page_open_count',
      },
      {
        header: t('logType.videoPlay'),
        accessorKey: 'video_play_count',
      },
      {
        header: t('logType.videoNotPlayRate'),
        accessorKey: 'not_play_percent',
      },
      {
        header: t('logType.videoEnded'),
        accessorKey: 'video_ended_count',
      },
      {
        header: t('logType.videoEndedRate'),
        accessorKey: 'video_ended_percent',
      },
    ],
    [t]
  )

  return useTanstackTableWithCsvExport<IVideoViewCount>(
    columns,
    viewCounts,
    'analytics_videoly.csv'
  )
}

export default ChartBody
