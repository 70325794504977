import {
  findCachedCategory,
  findCachedCreator,
  findCachedTag,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ContentsOrderType } from 'common/interfaces/contents_orders'
import { IEvent } from 'common/interfaces/event'
import { sortByContentsOrderType } from 'common/sort_contents'
import { showingEvents } from 'common/utils'
import { i18nUserIndex, i18nUserVideoList } from 'i18n/i18n'
import React from 'react'
import { ContentTypeListPageType, IContentTypeListParams } from './page_params'

export const getEventListPageType = (
  params: IContentTypeListParams
): ContentTypeListPageType => {
  if (
    [
      params.categoryId,
      params.tagId,
      params.creatorId,
      params.playStatus,
    ].filter(Boolean).length > 0
  ) {
    return ContentTypeListPageType.COMBINE_EVENTS
  }
  return ContentTypeListPageType.NEWER_EVENTS
}

export const createEventListTitle = (
  pageType: ContentTypeListPageType,
  storeCache: IStoreCache,
  params: IContentTypeListParams
): JSX.Element[] => {
  const titles: string[] = []

  switch (pageType) {
    case ContentTypeListPageType.NEWER_EVENTS:
      titles.push(i18nUserVideoList('typeContentsList.titles.newEvent'))
      break
    case ContentTypeListPageType.COMBINE_EVENTS:
      if (params.categoryId) {
        const category = findCachedCategory(storeCache, params.categoryId)
        titles.push(category?.name ?? '')
      }
      if (params.tagId) {
        const tag = findCachedTag(storeCache, params.tagId)
        titles.push(tag?.name ? `#${tag.name}` : '')
      }
      if (params.creatorId) {
        const creator = findCachedCreator(storeCache, params.creatorId)
        titles.push(creator?.name ?? '')
      }
      if (params.playStatus) {
        const i18n = i18nUserIndex('chooseGenre.badges.playStatus', {
          returnObjects: true,
        })
        const name = i18n[params.playStatus]
        titles.push(name)
      }
      break
  }

  return titles.map((title, index) =>
    React.createElement('span', { key: index }, title)
  )
}

export const getFilteredEvents = (
  pageType: ContentTypeListPageType,
  storeCache: IStoreCache,
  params: IContentTypeListParams
): IEvent[] => {
  const events = showingEvents(storeCache)
  const sortType = storeCache.team!.default_contents_orders.event
  switch (pageType) {
    case ContentTypeListPageType.NEWER_EVENTS:
      return sortByContentsOrderType(events, ContentsOrderType.NEWER)
    case ContentTypeListPageType.COMBINE_EVENTS:
      let filtered = events
      if (params.categoryId) {
        filtered = filtered.filter((p) => p.category_id === params.categoryId)
      }
      if (params.tagId) {
        filtered = filtered.filter((p) => p.tag_ids.includes(params.tagId!))
      }
      if (params.creatorId || params.playStatus) {
        filtered = []
      }
      return sortByContentsOrderType(filtered, sortType)
  }
  return []
}
