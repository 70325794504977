import { ICustomizeFields, IUser } from 'common/interfaces/user'
import CustomizeFieldForm from 'components/atoms/CustomizeFields/CustomizeFieldForm'
import TextInput from 'components/atoms/Form/TextInput'
import CardComponent from 'components/molecules/User/Setting/CardComponent'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Col, Form, FormGroup, Label, Row } from 'reactstrap'
import {
  InputType,
  onChangeInput as serviceOnChangeInput,
  updateUser,
} from 'services/admin/user_edit'
import { onChangeInputForCustomizeFields } from 'services/join'
import { updateUserNoti } from 'services/user/setting'

const ProfileFormRow: React.FC = () => {
  const { t } = useTranslation('userSetting')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [userEdit, setUserEdit] = useState<IUser>(storeCache.user!)
  const [customizeField, setCustomizeField] = useState<
    ICustomizeFields[] | null | undefined
  >(userEdit.customize_fields)

  if (!storeCache.team || !storeCache.user) return null

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(userEdit, setUserEdit, type, e)

  const save = () =>
    updateUser(history, userEdit, storeCache, customizeField, false, true)

  const updateNoti = () => updateUserNoti(storeCache, userEdit)

  return (
    <Row className="user-setting-auth-row">
      <CardComponent title={t('profileFormRow.settingProfile')} onClick={save}>
        <Form>
          <TextInput
            id="nameForm"
            label={t('profileFormRow.name')}
            value={userEdit.name}
            col={12}
            onChange={(e) => onChangeInput(InputType.NAME, e)}
          />
          <TextInput
            id="phoneForm"
            label={t('profileFormRow.phone')}
            value={userEdit.phone}
            col={12}
            onChange={(e) => onChangeInput(InputType.PHONE, e)}
          />
          {storeCache.team.customize_field?.map((customizeFieldObj, key) => (
            <CustomizeFieldForm
              key={key}
              colNum={12}
              customizeField={customizeField}
              customizeFieldObj={customizeFieldObj}
              userCustomizeField={storeCache.user!.customize_fields}
              onChange={(e) =>
                onChangeInputForCustomizeFields(
                  key,
                  storeCache.team!.customize_field ?? [],
                  customizeFieldObj,
                  e,
                  { customizeField, setCustomizeField }
                )
              }
              hideOnlyAdmin
            />
          ))}
        </Form>
      </CardComponent>
      <CardComponent
        title={t('profileFormRow.notification')}
        onClick={updateNoti}
      >
        <Form>
          <FormGroup row>
            <Col xs="6" md="4" lg="3">
              <Label>{t('profileFormRow.news')}</Label>
            </Col>
            <Col>
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    onChangeInput(InputType.NOTIFICATION_SETTING_NEWS, e)
                  }
                  defaultChecked={storeCache.user.notification_setting.news}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
          </FormGroup>
        </Form>
      </CardComponent>
    </Row>
  )
}

export default ProfileFormRow
