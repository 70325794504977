import { ICreator } from 'common/interfaces/creator'
import { ITeam } from 'common/interfaces/team'
import { nowTimestamp } from 'common/times'
import { Teams } from './firebase_team'

const Creators = (team: ITeam) => Teams.doc(team.id).collection('creators')

/**
 * get all creators
 */
export const get = async (team: ITeam): Promise<ICreator[]> => {
  try {
    const creatorDatas = await Creators(team).get()
    return creatorDatas.docs.map((creator) => creator.data() as ICreator)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * add creator
 * @throws Firebase error
 */
export const store = async (team: ITeam, creator: ICreator): Promise<void> => {
  await Creators(team).doc(creator.id).set(creator)
}

/**
 * update creator
 * @throws Firebase error
 */
export const update = async (team: ITeam, creator: ICreator): Promise<void> => {
  creator.updated_at = nowTimestamp()
  await Creators(team).doc(creator.id).update(creator)
}

/**
 * remove creator
 * @throws Firebase error
 */
export const remove = async (team: ITeam, creator: ICreator): Promise<void> => {
  await Creators(team).doc(creator.id).delete()
}

/**
 * remove creator image
 * @throws Firebase error
 */
export const removeImage = async (
  team: ITeam,
  creator: ICreator
): Promise<void> => {
  creator.image = null
  creator.updated_at = nowTimestamp()
  await Creators(team).doc(creator.id).update(creator)
}
