import React from 'react'
import { Col, FormGroup, Input, Label } from 'reactstrap'

type TTextInput = {
  id: string
  label: string
  value: string | number | readonly string[] | undefined
  col: number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  readOnly?: boolean
}

const TextInput: React.FC<TTextInput> = ({
  id,
  label,
  value,
  col,
  onChange,
  readOnly,
}: TTextInput) => (
  <FormGroup row>
    <Col md={col}>
      <Label for={id}>{label}</Label>
      <Input
        id={id}
        value={value}
        onChange={onChange}
        placeholder={label}
        type="text"
        readOnly={readOnly}
      />
    </Col>
  </FormGroup>
)

export default TextInput
