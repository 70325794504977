import { useParams } from 'react-router-dom'

/**
 * URLパラメーターインターフェース
 */
export interface IContentTypeListParams {
  type: string | undefined
  tagId: string | undefined
  categoryId: string | undefined
  creatorId: string | undefined
  playStatus: 'notYet' | 'playing' | 'played' | undefined
  query: string | undefined
}

/**
 * 適切なURLパラメーターを返すhook
 */
export const useContentTypeListParams = (): IContentTypeListParams => {
  const reg =
    /\/(videos|lives|events|playlists)(\/category\/(.+?))?(\/tag\/(.+?))?(\/creator\/(.+?))?(\/playStatus\/(.+?))?$/
  const matcher = window.location.pathname.match(reg)

  const p = useParams<IContentTypeListParams>()
  return {
    type: p.type,
    categoryId: matcher?.[3],
    tagId: matcher?.[5],
    creatorId: matcher?.[7],
    playStatus: matcher?.[9] as IContentTypeListParams['playStatus'],
    query: p.query ? decodeURIComponent(p.query) : undefined,
  }
}

/**
 * 表示する内容のタイプ
 */
export enum ContentTypeListPageType {
  NEWER_VIDEOS,
  POPULAR_VIDEOS,
  SEARCH_VIDEOS,
  COMBINE_VIDEOS,

  NEWER_LIVES,
  COMBINE_LIVES,

  NEWER_EVENTS,
  COMBINE_EVENTS,

  NEWER_PLAYLISTS,
  COMBINE_PLAYLISTS,
}
