import { IPrice } from 'common/interfaces/stripe/price'
import { getPrices } from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'

/**
 * Stripeの料金をすべて取得
 */
export const getAllStripePrices = async (
  stripeAccount: string | undefined
): Promise<IPrice[]> => {
  if (!stripeAccount) return []

  try {
    return await getPrices(stripeAccount)
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }

  return []
}
