import { IPlaylist } from 'common/interfaces/playlist'
import { DropResult } from 'react-beautiful-dnd'

/**
 * ドラッグ＆ドロップによりplaylistの並び順を調整時にPlaylistのvideo_idsの状態更新をする
 */
export const onDragEnd = (
  result: DropResult,
  playlist: IPlaylist,
  setPlaylist: React.Dispatch<React.SetStateAction<IPlaylist>>
) => {
  if (!result.destination) return

  const items = (() => {
    const data = Array.from(playlist.video_ids)
    const [removed] = data.splice(result.source.index, 1)
    data.splice(result.destination.index, 0, removed)
    return data
  })()
  setPlaylist({
    ...playlist,
    video_ids: items,
  })
}
