import i18next from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import enAdminCommon from './en/adminCommon.json'
import enAdminVideo from './en/adminVideo.json'
import enAdminPlaylist from './en/adminPlaylist.json'
import enAdminLive from './en/adminLive.json'
import enAdminEvent from './en/adminEvent.json'
import enAdminNews from './en/adminNews.json'
import enAdminSendEmail from './en/adminSendEmail.json'
import enAdminMailTemplate from './en/adminMailTemplate.json'
import enAdminEnquete from './en/adminEnquete.json'
import enAdminTest from './en/adminTest.json'
import enAdminComment from './en/adminComment.json'
import enAdminAnalytics from './en/adminAnalytics.json'
import enAdminUser from './en/adminUser.json'
import enAdminUserGroup from './en/adminUserGroup.json'
import enAdminAdministrator from './en/adminAdministrator.json'
import enAdminInvite from './en/adminInvite.json'
import enAdminCustomizeFields from './en/adminCustomizeFields.json'
import enAdminSiteCustomize from './en/adminSiteCustomize.json'
import enAdminCategory from './en/adminCategory.json'
import enAdminTag from './en/adminTag.json'
import enAdminCreator from './en/adminCreator.json'
import enAdminFilelist from './en/adminFilelist.json'
import enAdminStripeProduct from './en/adminStripeProduct.json'
import enAdminStripePrice from './en/adminStripePrice.json'
import enAdminStripeCoupon from './en/adminStripeCoupon.json'
import enAdminStripePayment from './en/adminStripePayment.json'
import enAdminStripeBilling from './en/adminStripeBilling.json'
import enAdminBasicInfo from './en/adminBasicInfo.json'
import enAdminSetting from './en/adminSetting.json'
import enAdminContractingPlan from './en/adminContractingPlan.json'
import enAdminPaidHistory from './en/adminPaidHistory.json'
import enCommon from './en/common.json'
import enAlert from './en/alert.json'
import enUserCommon from './en/userCommon.json'
import enUserIndex from './en/userIndex.json'
import enUserVideo from './en/userVideo.json'
import enUserVideoList from './en/userVideoList.json'
import enUserLive from './en/userLive.json'
import enUserSetting from './en/userSetting.json'
import enUserSubscription from './en/userSubscription.json'
import enRegister from './en/register.json'
import enJoin from './en/join.json'
import enItemSelect from './en/itemSelect.json'
import enMailTemplate from './en/mailTemplate.json'
import enValidation from './en/validation.json'
import jaAdminCommon from './ja/adminCommon.json'
import jaAdminVideo from './ja/adminVideo.json'
import jaAdminPlaylist from './ja/adminPlaylist.json'
import jaAdminLive from './ja/adminLive.json'
import jaAdminEvent from './ja/adminEvent.json'
import jaAdminNews from './ja/adminNews.json'
import jaAdminSendEmail from './ja/adminSendEmail.json'
import jaAdminMailTemplate from './ja/adminMailTemplate.json'
import jaAdminEnquete from './ja/adminEnquete.json'
import jaAdminTest from './ja/adminTest.json'
import jaAdminComment from './ja/adminComment.json'
import jaAdminAnalytics from './ja/adminAnalytics.json'
import jaAdminUser from './ja/adminUser.json'
import jaAdminUserGroup from './ja/adminUserGroup.json'
import jaAdminAdministrator from './ja/adminAdministrator.json'
import jaAdminInvite from './ja/adminInvite.json'
import jaAdminCustomizeFields from './ja/adminCustomizeFields.json'
import jaAdminSiteCustomize from './ja/adminSiteCustomize.json'
import jaAdminCategory from './ja/adminCategory.json'
import jaAdminTag from './ja/adminTag.json'
import jaAdminCreator from './ja/adminCreator.json'
import jaAdminFilelist from './ja/adminFilelist.json'
import jaAdminStripeProduct from './ja/adminStripeProduct.json'
import jaAdminStripePrice from './ja/adminStripePrice.json'
import jaAdminStripeCoupon from './ja/adminStripeCoupon.json'
import jaAdminStripePayment from './ja/adminStripePayment.json'
import jaAdminStripeBilling from './ja/adminStripeBilling.json'
import jaAdminBasicInfo from './ja/adminBasicInfo.json'
import jaAdminSetting from './ja/adminSetting.json'
import jaAdminContractingPlan from './ja/adminContractingPlan.json'
import jaAdminPaidHistory from './ja/adminPaidHistory.json'
import jaCommon from './ja/common.json'
import jaAlert from './ja/alert.json'
import jaUserCommon from './ja/userCommon.json'
import jaUserIndex from './ja/userIndex.json'
import jaUserVideo from './ja/userVideo.json'
import jaUserVideoList from './ja/userVideoList.json'
import jaUserLive from './ja/userLive.json'
import jaUserSetting from './ja/userSetting.json'
import jaUserSubscription from './ja/userSubscription.json'
import jaRegister from './ja/register.json'
import jaJoin from './ja/join.json'
import jaItemSelect from './ja/itemSelect.json'
import jaMailTemplate from './ja/mailTemplate.json'
import jaValidation from './ja/validation.json'

const resources = {
  en: {
    common: enCommon,
    alert: enAlert,
    adminCommon: enAdminCommon,
    adminVideo: enAdminVideo,
    adminPlaylist: enAdminPlaylist,
    adminLive: enAdminLive,
    adminEvent: enAdminEvent,
    adminNews: enAdminNews,
    adminSendEmail: enAdminSendEmail,
    adminMailTemplate: enAdminMailTemplate,
    adminEnquete: enAdminEnquete,
    adminTest: enAdminTest,
    adminComment: enAdminComment,
    adminAnalytics: enAdminAnalytics,
    adminUser: enAdminUser,
    adminUserGroup: enAdminUserGroup,
    adminAdministrator: enAdminAdministrator,
    adminInvite: enAdminInvite,
    adminCustomizeFields: enAdminCustomizeFields,
    adminSiteCustomize: enAdminSiteCustomize,
    adminCategory: enAdminCategory,
    adminTag: enAdminTag,
    adminCreator: enAdminCreator,
    adminFilelist: enAdminFilelist,
    adminStripeProduct: enAdminStripeProduct,
    adminStripePrice: enAdminStripePrice,
    adminStripeCoupon: enAdminStripeCoupon,
    adminStripePayment: enAdminStripePayment,
    adminStripeBilling: enAdminStripeBilling,
    adminBasicInfo: enAdminBasicInfo,
    adminSetting: enAdminSetting,
    adminContractingPlan: enAdminContractingPlan,
    adminPaidHistory: enAdminPaidHistory,
    userCommon: enUserCommon,
    userIndex: enUserIndex,
    userVideo: enUserVideo,
    userVideoList: enUserVideoList,
    userLive: enUserLive,
    userSetting: enUserSetting,
    userSubscription: enUserSubscription,
    register: enRegister,
    join: enJoin,
    itemSelect: enItemSelect,
    mailTemplate: enMailTemplate,
    validation: enValidation,
  },
  ja: {
    common: jaCommon,
    alert: jaAlert,
    adminCommon: jaAdminCommon,
    adminVideo: jaAdminVideo,
    adminPlaylist: jaAdminPlaylist,
    adminLive: jaAdminLive,
    adminEvent: jaAdminEvent,
    adminNews: jaAdminNews,
    adminSendEmail: jaAdminSendEmail,
    adminMailTemplate: jaAdminMailTemplate,
    adminEnquete: jaAdminEnquete,
    adminTest: jaAdminTest,
    adminComment: jaAdminComment,
    adminAnalytics: jaAdminAnalytics,
    adminUser: jaAdminUser,
    adminUserGroup: jaAdminUserGroup,
    adminAdministrator: jaAdminAdministrator,
    adminInvite: jaAdminInvite,
    adminCustomizeFields: jaAdminCustomizeFields,
    adminSiteCustomize: jaAdminSiteCustomize,
    adminCategory: jaAdminCategory,
    adminTag: jaAdminTag,
    adminCreator: jaAdminCreator,
    adminFilelist: jaAdminFilelist,
    adminStripeProduct: jaAdminStripeProduct,
    adminStripePrice: jaAdminStripePrice,
    adminStripeCoupon: jaAdminStripeCoupon,
    adminStripePayment: jaAdminStripePayment,
    adminStripeBilling: jaAdminStripeBilling,
    adminBasicInfo: jaAdminBasicInfo,
    adminSetting: jaAdminSetting,
    adminContractingPlan: jaAdminContractingPlan,
    adminPaidHistory: jaAdminPaidHistory,
    userCommon: jaUserCommon,
    userIndex: jaUserIndex,
    userVideo: jaUserVideo,
    userVideoList: jaUserVideoList,
    userLive: jaUserLive,
    userSetting: jaUserSetting,
    userSubscription: jaUserSubscription,
    register: jaRegister,
    join: jaJoin,
    itemSelect: jaItemSelect,
    mailTemplate: jaMailTemplate,
    validation: jaValidation,
  },
}
const lngs = ['en', 'ja']

const detector = new I18nextBrowserLanguageDetector(null, {
  order: [
    'querystring',
    'cookie',
    'sessionStorage',
    'navigator',
    'localStorage',
    'htmlTag',
  ],
  htmlTag: document.documentElement,
})

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: lngs,
    supportedLngs: lngs,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18next
