import axios from 'axios'
import {
  EmailAction,
  IAdministratorsNotifyType,
  IMailTemplateType,
} from 'common/enums'
import { IUserAndEmail } from 'common/interfaces/admin/invite_mail'
import { createOverviewDataString } from 'components/atoms/Table/ElementsOnTable'

/** メール送信元のドメイン */
export const MAIL_SEND_DOMAIN = String(process.env.REACT_APP_MAIL_SEND_DOMAIN)

const CF_API_BASE_URL = String(process.env.REACT_APP_FIREBASE_FUNCTIONS_URI)

type EmailData = string | { name?: string; email: string }

/**
 * send mail
 * @param teamId string
 * @param action EmailAction | undefined
 * @param foreign_id string
 * @param name string | undefined
 * @param from EmailData | null
 * @param to string | string[]
 * @param replyTo EmailData | undefined
 * @param subject string
 * @param text string
 * @param html string
 * @param bcc bcc email addresses
 * @returns is send successful
 */
export const send = async (
  teamId: string,
  action: EmailAction | undefined,
  foreign_id: string | undefined,
  name: string | undefined,
  from: EmailData | null,
  to: string | string[],
  replyTo: EmailData | undefined,
  subject: string,
  text: string,
  html: string,
  bcc: string[] = [],
  is_change_status = false
): Promise<boolean> => {
  text = createOverviewDataString(text)

  const url = `${CF_API_BASE_URL}/mail`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    teamId,
    action,
    foreign_id,
    name,
    from,
    to,
    replyTo,
    subject,
    text,
    html,
    bcc,
    is_change_status,
  }
  return (await axios.post<{ success: boolean }>(url, data, { headers })).data
    .success
}

/**
 * send mail to multiple
 * @param teamId string
 * @param action EmailAction
 * @param foreign_id string
 * @param from EmailData | null
 * @param replyTo EmailData | undefined
 * @param userAndEmails IUserAndEmail[]
 * @param subject string
 * @param html string
 * @param bcc bcc email addresses
 * @returns is send successful
 */
export const sendToMultiple = async (
  teamId: string,
  action: EmailAction,
  foreign_id: string,
  from: EmailData | null,
  replyTo: EmailData | undefined,
  importCsvData: IUserAndEmail[],
  subject: string,
  html: string,
  bcc: string[] = []
): Promise<boolean> => {
  const url = `${CF_API_BASE_URL}/mail/to_multiple`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    teamId,
    action,
    foreign_id,
    from,
    replyTo,
    importCsvData,
    subject,
    html,
    bcc,
  }
  return (await axios.post<{ success: boolean }>(url, data, { headers })).data
    .success
}

/**
 * send mail to administrators
 * @param teamId string
 * @param notifyType IAdministratorsNotifyType
 * @param userEmail string
 * @param registeredUserName string
 * @param registeredUserTel string
 * @param registeredUserEmail string
 * @param videoTitle string
 * @param videoUrl string
 * @param stripeProductName string
 * @param stripeProductPrice string
 * @param deletedUserEmail string
 * @returns is send successful
 */
export const sendToAdministrators = async (
  teamId: string,
  notifyType: IAdministratorsNotifyType,
  userEmail = '',
  registeredUserName = '',
  registeredUserTel = '',
  registeredUserEmail = '',
  videoTitle = '',
  videoUrl = '',
  stripeProductName = '',
  stripeProductPrice = '',
  deletedUserEmail = ''
): Promise<boolean> => {
  const url = `${CF_API_BASE_URL}/mail/to_administrators`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    teamId,
    notifyType,
    userEmail,
    registeredUserName,
    registeredUserTel,
    registeredUserEmail,
    videoTitle,
    videoUrl,
    stripeProductName,
    stripeProductPrice,
    deletedUserEmail,
  }
  return (await axios.post<{ success: boolean }>(url, data, { headers })).data
    .success
}

/**
 * send mail by template
 * @param teamId string
 * @param mailTemplate `IMailTemplate`
 * @param to string
 * @param bcc bcc email addresses
 * @returns is send successful
 */
export const sendMailTemplate = async (
  teamId: string,
  mailTemplate: IMailTemplateType,
  to: string,
  bcc: string[] = []
): Promise<boolean> => {
  const url = `${CF_API_BASE_URL}/mail/template`
  const headers = { 'Content-Type': 'application/json' }
  const data = { teamId, mailTemplate, to, bcc }
  return (await axios.post<{ success: boolean }>(url, data, { headers })).data
    .success
}
