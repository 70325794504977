import { IEnquete } from 'common/interfaces/enquete'
import {
  IEnqueteCustomize,
  IEnqueteType,
} from 'common/interfaces/enquete_customize'
import React from 'react'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import RequiredText from '../Required/RequiredText'

export const questionElement = (
  enquete: IEnquete | null,
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    enquete: IEnqueteCustomize,
    index: number
  ) => void
): JSX.Element[] | null => {
  if (!enquete || !enquete.questions) return null

  return enquete.questions.map((question, key) => {
    switch (question.type) {
      case IEnqueteType.TEXT:
      case IEnqueteType.TEXTAREA:
        return (
          <FormGroup row key={key}>
            <Col md="10">
              <RequiredText label required={question.isRequired}>
                {question.questionName}
              </RequiredText>
              <Input
                type={question.type}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(e, question, key)
                }
              />
            </Col>
          </FormGroup>
        )
      case IEnqueteType.RADIO:
      case IEnqueteType.CHECKBOX:
        return (
          <FormGroup row check key={key}>
            <Row>
              <Col md="10">
                <RequiredText label required={question.isRequired}>
                  {question.questionName}
                </RequiredText>
              </Col>
            </Row>
            {question.options?.map((option, optionkey) => {
              const name = `enqueteRadio${key}`
              const idName = `enqueteRadio${key}-${optionkey}`
              const type =
                question.type === IEnqueteType.RADIO ? 'radio' : 'checkbox'
              return (
                <div
                  className={`custom-control custom-control-alternative custom-${type} mb-3`}
                  key={optionkey}
                >
                  <input
                    className="custom-control-input"
                    id={idName}
                    name={name}
                    type={type}
                    value={option}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChange(e, question, key)
                    }
                  />
                  <Label className="custom-control-label" for={idName}>
                    {option}
                  </Label>
                </div>
              )
            })}
          </FormGroup>
        )
      default:
        return <></>
    }
  })
}
