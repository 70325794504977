import { ICategory } from 'common/interfaces/category'
import { ICreator } from 'common/interfaces/creator'
import { ITag } from 'common/interfaces/tag'
import { ContentsOrderType } from './interfaces/contents_orders'
import { IEvent } from './interfaces/event'
import { IGroup } from './interfaces/group'
import { ILive } from './interfaces/live'
import { IPlaylist } from './interfaces/playlist'
import { IVideo } from './interfaces/video'

/**
 * `sort_num`でソートした配列を返却
 */
export const sortBySortNumber = <T extends ICategory | ITag | ICreator>(
  array: T[]
): T[] =>
  array.sort((a, b) => {
    if (a.sort_num === null) return 1
    if (b.sort_num === null) return -1
    if (a.sort_num! < b.sort_num!) return -1
    if (a.sort_num! > b.sort_num!) return 1
    return 0
  })

/**
 * `ContentsOrderType`でソートしたコンテンツを返却
 */
export const sortByContentsOrderType = <
  T extends IVideo | ILive | IEvent | IPlaylist | ICategory | ITag | IGroup
>(
  array: T[],
  sortType: ContentsOrderType
): T[] => {
  switch (sortType) {
    case ContentsOrderType.NEWER:
      return array.sort((a, b) => b.created_at.seconds - a.created_at.seconds)
    case ContentsOrderType.PUBLISH:
      return array.sort((a, b) => {
        if ('released_at' in a && 'released_at' in b) {
          if (a.released_at === null) return 1
          if (b.released_at === null) return -1
          if (a.released_at < b.released_at) return 1
          if (a.released_at > b.released_at) return -1
        }
        return 0
      })
    case ContentsOrderType.UPDATE:
      return array.sort((a, b) => b.updated_at.seconds - a.updated_at.seconds)
    case ContentsOrderType.NAME_ASC:
      return array.sort((a, b) => a.name.localeCompare(b.name))
    case ContentsOrderType.NAME_DESC:
      return array.sort((a, b) => b.name.localeCompare(a.name))
  }
}

/**
 * `view_count`でソートした動画を返却
 */
export const sortByViewCount = (videos: IVideo[]): IVideo[] =>
  videos.sort((a, b) => b.view_count - a.view_count)
