import { IStoreCache } from 'common/interfaces/auth_provider'
import { IComment } from 'common/interfaces/comment'
import { IUser } from 'common/interfaces/user'
import { checkFirebaseError, isLoggedIn } from 'common/utils'
import { get as getComment } from 'repositories/store/firebase_comment'
import { getNewer } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'

/**
 * すべてのユーザーを取得する
 */
export const getUsers = async (
  storeCache: IStoreCache,
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>
) => {
  if (!isLoggedIn(storeCache)) return
  const users = await getNewer(storeCache.team!)
  setUsers(users)
}

/**
 * 対象の動画コメントを取得する
 */
export const getVideoComments = async (
  storeCache: IStoreCache,
  videoId: string,
  setVideoComments: React.Dispatch<React.SetStateAction<IComment[]>>
): Promise<void> => {
  const video = storeCache.videos.find((video) => video.id === videoId)
  if (!video) {
    setVideoComments([])
    return
  }

  try {
    const comments = await getComment(storeCache.team!, video)
    setVideoComments(comments)
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}
