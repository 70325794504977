import { InvoiceActionType, PriceType } from 'common/enums'
import { IProductInfo } from 'common/interfaces/stripe/product_price'
import { nl2br } from 'common/utils'
import FooterButtons from 'components/atoms/Modal/FooterButtons'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, FormGroup, Input, Label, Modal } from 'reactstrap'
import {
  IInvoicePayment,
  InputType,
  generateContents,
  initInvoicePayment,
  invoiceAction as serviceInvoiceAction,
  onChangeInput as serviceOnChangeInput,
} from 'services/invoice_payment_modal'

type TInvoicePaymentModal = {
  selectProductInfo: IProductInfo | null
  couponId?: string
  isOpen: boolean
  actionType: InvoiceActionType
  onToggleModal: () => void
  onClick: (
    selectedProductInfo: IProductInfo,
    invoicePayment: IInvoicePayment,
    couponId: string | undefined
  ) => void
  resendInvoice: (invoicePayment: IInvoicePayment) => void
  cancelPayment?: (
    selectedProductInfo: IProductInfo,
    invoicePayment: IInvoicePayment
  ) => void
}

const InvoicePaymentModal: React.FC<TInvoicePaymentModal> = ({
  selectProductInfo,
  couponId,
  isOpen,
  actionType,
  onToggleModal,
  onClick,
  resendInvoice,
  cancelPayment,
}: TInvoicePaymentModal) => {
  const { storeCache } = useContext(AuthContext)
  const { t } = useTranslation('itemSelect')

  const [invoicePayment, setInvoicePayment] = useState<IInvoicePayment>(
    initInvoicePayment(storeCache)
  )

  if (!selectProductInfo) return <></>

  const contents = () => generateContents(storeCache, actionType)

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(invoicePayment, setInvoicePayment, type, e)

  const invoiceAction = () =>
    serviceInvoiceAction(
      actionType,
      () => onClick(selectProductInfo, invoicePayment, couponId),
      () => resendInvoice(invoicePayment),
      cancelPayment === undefined
        ? undefined
        : () => cancelPayment(selectProductInfo, invoicePayment)
    )

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={onToggleModal}
      size={actionType === InvoiceActionType.CANCEL ? 'lg' : 'md'}
    >
      <div className="modal-header">
        <h5 className="modal-title">
          {t('invoicePaymentModal.invoicePayment')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onToggleModal}
        >
          <span aria-hidden>×</span>
        </button>
      </div>
      <div className="modal-body">
        {nl2br(contents()[0])}
        <InvoicePaymentForm
          invoicePayment={invoicePayment}
          type={selectProductInfo.type}
          onChangeInput={(type, e) => onChangeInput(type, e)}
        />
        <FooterButtons
          leftContent={contents()[1]}
          rightContent={t('close')}
          leftOnClick={() => invoiceAction()}
          rightOnClick={onToggleModal}
          leftColor={
            actionType === InvoiceActionType.CANCEL ? 'danger' : undefined
          }
        />
      </div>
    </Modal>
  )
}

interface IInvoicePaymentForm {
  invoicePayment: IInvoicePayment
  type: PriceType
  onChangeInput: (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
}

const InvoicePaymentForm: React.FC<IInvoicePaymentForm> = (props) => {
  if (props.type !== PriceType.RECURRING) {
    return null
  }

  return (
    <>
      <hr />
      <FormGroup row>
        <Col sm={12}>
          <Label>宛名</Label>
          <Input
            type="text"
            onChange={(e) => props.onChangeInput(InputType.NAME, e)}
            defaultValue={props.invoicePayment.name}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={12}>
          <Label>メールアドレス</Label>
          <Input
            type="email"
            onChange={(e) => props.onChangeInput(InputType.EMAIL, e)}
            defaultValue={props.invoicePayment.email}
          />
        </Col>
      </FormGroup>
    </>
  )
}

export default InvoicePaymentModal
