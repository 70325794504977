import { IEnqueteAnswerField } from 'common/interfaces/enquete_answer'
import { IEnqueteCustomize } from 'common/interfaces/enquete_customize'
import { IVideoEnquete } from 'common/interfaces/video'
import { i18nValidation } from 'i18n/i18n'

/**
 * 動画詳細画面のアンケート回答バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param enquete 動画ドキュメント内のアンケートMap
 * @param enqueteAnswers アンケート回答項目群
 */
export const validateForm = (
  enquete: IVideoEnquete | undefined,
  enqueteAnswers: IEnqueteAnswerField[],
  enqueteQuestions: IEnqueteCustomize[]
): void => {
  if (enquete === undefined || !enquete.enquete_id) {
    throw new Error(i18nValidation('none.enquete'))
  }
  checkEnqueteAnswers(enqueteAnswers, enqueteQuestions)
}

/**
 * 会員限定のアカウント登録後アンケート回答バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param enqueteId teamドキュメント内のアンケートID（サイト設定画面で選択可能）
 * @param enqueteAnswers アンケート回答項目群
 */
export const validateFormForUser = (
  enqueteId: string,
  enqueteAnswers: IEnqueteAnswerField[],
  enqueteQuestions: IEnqueteCustomize[]
): void => {
  if (!enqueteId) {
    throw new Error(i18nValidation('none.enquete'))
  }
  checkEnqueteAnswers(enqueteAnswers, enqueteQuestions)
}

const checkEnqueteAnswers = (
  enqueteAnswers: IEnqueteAnswerField[],
  enqueteQuestions: IEnqueteCustomize[]
): void => {
  for (const answerObj of enqueteAnswers) {
    for (const answerKey of Object.keys(answerObj)) {
      const questionObj = enqueteQuestions?.find(
        ({ questionName }) => questionName === answerKey
      )
      if (
        questionObj &&
        answerObj[answerKey].length < 1 &&
        questionObj.isRequired
      ) {
        throw new Error(
          i18nValidation('required.answer.enquete', {
            questionName: answerKey,
          })
        )
      }
    }
  }
}
