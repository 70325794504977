import { INews } from 'common/interfaces/news'
import { dateFormat } from 'common/times'
import QuillHtml from 'components/atoms/Quill/QuillHtml'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import './list.scss'

const List: React.FC = () => {
  const newslist = useContext(AuthContext).storeCache.newslists

  return (
    <ListGroup className="news-list" flush>
      {newslist.map((news: INews, key: number) => (
        <ListGroupItem
          className="list-group-item-action flex-column align-items-start py-4 px-4"
          href="#"
          key={key}
        >
          <div className="d-flex w-100 justify-content-between">
            <div>
              <div className="d-flex w-100 align-items-center">
                <h4 className="mt-3 mb-1">{news.title}</h4>
              </div>
            </div>
            <small>{dateFormat(news.created_at)}</small>
          </div>
          <QuillHtml html={news.content} />
        </ListGroupItem>
      ))}
    </ListGroup>
  )
}

export default List
