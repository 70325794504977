import React from 'react'
import GoogleButton from 'react-google-button'
import { useTranslation } from 'react-i18next'

interface ISignInWithGoogleProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>
  centered?: boolean
}

const SignInWithGoogle: React.FC<ISignInWithGoogleProps> = ({
  onClick,
  centered,
}: ISignInWithGoogleProps) => {
  const { t } = useTranslation('register')
  const googleButton = (
    <GoogleButton label={t('googleAccount')} type="light" onClick={onClick} />
  )

  return centered ? (
    <div className="d-flex justify-content-center">{googleButton}</div>
  ) : (
    googleButton
  )
}

export default SignInWithGoogle
