import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICreator } from 'common/interfaces/creator'
import { IVideo } from 'common/interfaces/video'
import { sortByContentsOrderType } from 'common/sort_contents'

/**
 * find creator
 */
export const findCreator = (
  { creators }: IStoreCache,
  creatorId: string
): ICreator | null => {
  return creators.find((c) => c.id === creatorId) ?? null
}

/**
 * find videos of creator
 */
export const findVideosOfCreator = (
  { videos, team }: IStoreCache,
  creatorId: string
): IVideo[] => {
  const creatorVideos = videos.filter(
    (v) => v.creator_id === creatorId && !v.is_list_hidden
  )
  return sortByContentsOrderType(
    creatorVideos,
    team!.default_contents_orders.video
  )
}
