import { IAnyTeamItem } from 'common/interfaces/register'
import { TFunction } from 'i18next'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  ModalBody,
} from 'reactstrap'
import {
  initState,
  InputType,
  onChangeInput as serviceOnChangeInput,
  StepNo,
  stepOne,
  updateTeam,
} from 'services/any_team_item_form'
import { AnyTeamItemFormModalService } from 'services/any_team_item_form_modal'

const AnyTeamItemFormModal: React.FC = () => {
  const { t } = useTranslation('register')
  const { storeCache } = useContext(AuthContext)

  const [modal, setModal] = useState<{ isShow: boolean }>({ isShow: false })
  const [state, setState] = useState<IAnyTeamItem>(initState(storeCache.team!))
  const [stepNo, setStepNo] = useState<StepNo>(StepNo.STEP1)

  useEffect(() => {
    const subscription = AnyTeamItemFormModalService.onModal().subscribe(
      (modal) => setModal(modal)
    )
    return () => subscription.unsubscribe()
  }, [])

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(state, setState, type, e)

  const close = () => AnyTeamItemFormModalService.close(modal)

  const stepOneOk = () => stepOne(state, setStepNo)
  const stepTwoOk = () => updateTeam(storeCache, state, close)

  if (!storeCache.team || !storeCache.user) close()

  return (
    <Modal isOpen={modal.isShow} centered>
      {stepNo === StepNo.STEP1 && (
        <Step1
          t={t}
          item={state}
          onChangeInput={onChangeInput}
          ok={stepOneOk}
        />
      )}
      {stepNo === StepNo.STEP2 && (
        <Step2
          t={t}
          item={state}
          onChangeInput={onChangeInput}
          ok={stepTwoOk}
        />
      )}
    </Modal>
  )
}

const Step1: React.FC<{
  t: TFunction<'register'>
  item: IAnyTeamItem
  onChangeInput: (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  ok: () => void
}> = ({ t, item, onChangeInput, ok }): JSX.Element => (
  <CommonModal
    t={t}
    subTitle={t('anyTeamItemForm.step1.subtitle')}
    buttonTitle={t('anyTeamItemForm.step1.buttonTitle')}
    ok={ok}
  >
    <FormGroup className="my-4">
      <InputGroup className="input-group-alternative">
        <Input
          value={item.name}
          onChange={(e) => onChangeInput(InputType.TEAM_NAME, e)}
          placeholder={t('anyTeamItemForm.step1.form.siteName')}
          type="text"
          autoComplete="team-name"
        />
      </InputGroup>
    </FormGroup>
  </CommonModal>
)

const Step2: React.FC<{
  t: TFunction<'register'>
  item: IAnyTeamItem
  onChangeInput: (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  ok: () => void
}> = ({ t, item, onChangeInput, ok }): JSX.Element => (
  <CommonModal
    t={t}
    subTitle={t('anyTeamItemForm.step2.subtitle')}
    buttonTitle={t('anyTeamItemForm.step2.buttonTitle')}
    ok={ok}
  >
    <FormGroup className="mb-3">
      <InputGroup className="input-group-alternative">
        <Input
          value={item.company}
          onChange={(e) => onChangeInput(InputType.COMPANY, e)}
          placeholder={t('anyTeamItemForm.step2.form.company')}
          type="text"
          autoComplete="company"
        />
      </InputGroup>
    </FormGroup>
    <FormGroup className="mb-3">
      <InputGroup className="input-group-alternative">
        <Input
          value={item.phone}
          onChange={(e) => onChangeInput(InputType.PHONE, e)}
          placeholder={t('anyTeamItemForm.step2.form.phone')}
          type="tel"
          autoComplete="phone"
        />
      </InputGroup>
    </FormGroup>
  </CommonModal>
)

const CommonModal = ({
  children,
  t,
  subTitle,
  buttonTitle,
  ok,
}: {
  children: React.ReactNode
  t: TFunction<'register'>
  subTitle: string
  buttonTitle: string
  ok: () => void
}) => (
  <ModalBody>
    <Form role="form">
      <h1>{t('anyTeamItemForm.title')}</h1>
      <h2>{subTitle}</h2>
      <p>{t('anyTeamItemForm.subtitle')}</p>
      {children}
      <div className="text-center">
        <Button color="primary" onClick={ok}>
          {buttonTitle}
        </Button>
      </div>
    </Form>
  </ModalBody>
)

export default AnyTeamItemFormModal
