import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import {
  findCachedCategory,
  findCachedCreator,
  findPricesNonNull,
} from 'common/find_store_cache'
import { ILive, LivePlatform } from 'common/interfaces/live'
import { periodDatetimeFormat } from 'common/times'
import { generatePriceLabel, nl2br } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  createOverviewContent,
  makeEditDeleteOperationButtons,
  makeFilelistBadges,
  makeGroupBadges,
  makePrimaryBadges,
  makeTagBadges,
  makeThumbnailElement,
  makeVideoStatusBadge,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { removeLive } from 'services/admin/live'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminLive')
  const { storeCache } = useContext(AuthContext)
  const { lives } = storeCache

  if (lives.length <= 0) {
    return <RegisterItemButton type="live" />
  }
  return (
    <>
      <ButtonRight nextPage={Routes.AdminLiveCreate} content={t('list.new')} />
      <Table />
    </>
  )
}

const Table: React.FC = () => {
  const { t } = useTranslation('adminLive')
  const { storeCache } = useContext(AuthContext)
  const { lives } = storeCache
  const history = useHistory()

  const columns = useMemo<ColumnDef<ILive>[]>(
    () => [
      { header: 'No', accessorFn: (_, i) => lives.length - i },
      {
        header: t('list.table.status'),
        accessorFn: (l) => makeVideoStatusBadge(l.status),
      },
      { header: t('list.table.title'), accessorKey: 'name' },
      {
        header: 'ID',
        accessorFn: (l): (string | JSX.Element)[] => {
          switch (l.platform) {
            case LivePlatform.ZOOM_MEETING:
              return nl2br(`Zoom Meeting ID\n${l.meeting_number}`)
            case LivePlatform.ZOOM_WEBINAR:
              return nl2br(`Zoom Webinar ID\n${l.meeting_number}`)
            case LivePlatform.YOUTUBE:
              return nl2br(`YouTube Video ID\n${l.youtube_video_id}`)
            case LivePlatform.VIMEO_VIDEO:
            case LivePlatform.VIMEO_LIVE:
            case LivePlatform.VIMEO_WEBINAR:
              return nl2br(`Vimeo ID\n${l.vimeo_id}`)
          }
        },
      },
      {
        header: t('list.table.holdDate'),
        accessorFn: (l) => periodDatetimeFormat(l.hold_from, l.hold_to),
      },
      {
        header: t('list.table.thumbnail'),
        accessorFn: (l) => makeThumbnailElement(l.image, l.name, 'live'),
      },
      {
        header: t('list.table.category'),
        accessorFn: (l) =>
          findCachedCategory(storeCache, l.category_id)?.name ?? '',
      },
      {
        header: t('list.table.tag'),
        accessorFn: (l) => makeTagBadges(storeCache, l.tag_ids),
      },
      {
        header: t('list.table.publishGroup'),
        accessorFn: (l) => makeGroupBadges(storeCache, l.group_ids),
      },
      {
        header: t('list.table.file'),
        accessorFn: (l) => makeFilelistBadges(storeCache, l.filelist_ids),
      },
      {
        header: t('list.table.overview'),
        accessorFn: (l) => createOverviewContent(l.overview),
      },
      {
        header: t('list.table.creator'),
        accessorFn: (l) =>
          findCachedCreator(storeCache, l.creator_id)?.name ?? '',
      },
      {
        header: t('list.table.price'),
        accessorFn: (l) =>
          makePrimaryBadges(
            findPricesNonNull(storeCache, l.price_ids).map((p) =>
              generatePriceLabel(p, p.currency)
            )
          ),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (l) =>
          makeEditDeleteOperationButtons<ILive>(
            history,
            'live',
            Routes.AdminLiveEdit,
            (e) => removeLive(e, storeCache),
            l
          ),
      },
    ],
    [storeCache, history, lives.length, t]
  )

  const data = lives.sort((a, b) => b.created_at.seconds - a.created_at.seconds)
  return useTanstackTable<ILive>(columns, data, { fixedLastColumn: true })
}

export default TableBody
