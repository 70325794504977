import { Stripe as StripeJs } from '@stripe/stripe-js'
import {
  EmailAction,
  IAdministratorsNotifyType,
  IMailTemplateEventName,
} from 'common/enums'
import firebase, { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import {
  IAuthEmailForm,
  IAuthPasswordForm,
} from 'common/interfaces/setting/auth_form'
import { IStripe } from 'common/interfaces/stripe'
import { ICancelSubscription } from 'common/interfaces/stripe/cancel_subscription'
import { IProduct } from 'common/interfaces/stripe/product'
import { ISubscriptionObject } from 'common/interfaces/subscription'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { makeLoginTeamPath } from 'common/link_path'
import { makeLoginTeamUrl, makeSettingUrl } from 'common/link_url'
import { formatUNIXToDate } from 'common/times'
import {
  checkFirebaseError,
  checkPasswordComplex,
  getExpirationAtInfo,
  isAuthMethodEmail,
  isLoggedIn,
} from 'common/utils'
import { History } from 'history'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import {
  reloadCachedSelfSubscriptionObj,
  reloadCachedUser,
} from 'providers/AuthProvider'
import { changePasswordForCustomAuth } from 'repositories/functions/functions_auth'
import {
  send,
  sendToAdministrators,
} from 'repositories/functions/functions_mail'
import {
  deleteSubscription,
  getCheckoutSessionForSetup,
} from 'repositories/functions/functions_stripe'
import { addLoggedOutLog } from 'repositories/store/firebase_log'
import { get } from 'repositories/store/firebase_stripe'
import {
  findTeamUserById,
  remove,
  update,
} from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * initialize IAuthEmailForm
 */
export const initAuthEmailForm = (): IAuthEmailForm => {
  return { email: '', password: '' }
}

/**
 * initialize IAuthPasswordForm
 */
export const initAuthPasswordForm = (): IAuthPasswordForm => {
  return { password: '', new_password: '', new_password_confirm: '' }
}

/**
 * logout firebase
 */
export const signOut = (
  history: History,
  { team, user }: IStoreCache
): void => {
  modalService.show(i18nAlert('modal.confirm.logout'), async () => {
    try {
      if (team && user) {
        await addLoggedOutLog(team, user)
      }
      await auth.signOut()

      history.push(makeLoginTeamPath(team?.id ?? ''))
      alertService.show(true, i18nAlert('auth.loggedout'))
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('auth.cantLoggedout'))
    }
  })
}

/**
 * 通知設定を更新する
 */
export const updateUserNoti = async (
  storeCache: IStoreCache,
  user: IUser
): Promise<void> => {
  if (!isLoggedIn(storeCache, true)) return

  try {
    if (storeCache.user!.admin) {
      throw new Error(i18nValidation('setting.cantChange.admin'))
    }

    const newUser = storeCache.user!
    newUser.notification_setting.news = user.notification_setting.news

    await update(storeCache.team!, newUser)
    await reloadCachedUser(storeCache)

    alertService.show(true, i18nAlert('updated.user'))
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}

/**
 * FireAuthのメールアドレスを更新する
 */
export const updateEmail = (
  storeCache: IStoreCache,
  { email, password }: IAuthEmailForm
): void => {
  if (!isLoggedIn(storeCache, true)) return

  try {
    if (storeCache.user!.admin) {
      throw new Error(i18nValidation('setting.cantChange.admin'))
    }
    if (!email || !password) {
      throw new Error(i18nValidation('setting.input.newEmailAndPassword'))
    }

    modalService.show(i18nAlert('modal.confirm.change.email'), async () => {
      try {
        const teamUsers = await findTeamUserById(auth.currentUser!.uid)
        if (!teamUsers) {
          throw new Error(i18nAlert('failedGetUserData'))
        }

        await auth.signInWithEmailAndPassword(storeCache.user!.email, password)
        await auth.currentUser!.updateEmail(email)

        const updateEmailTasks = teamUsers.map(async (tu) => {
          tu.user.email = email
          await update(tu.team, tu.user)
        })
        await Promise.all(updateEmailTasks)

        await reloadCachedUser(storeCache)
        alertService.show(true, i18nAlert('updated.email'))
      } catch (error) {
        console.log(error)
        alertService.show(false, checkFirebaseError(error))
      }
    })
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * FireAuthのパスワードを更新する
 */
export const updatePassword = (
  team: ITeam,
  { id, email, admin }: IUser,
  { password, new_password, new_password_confirm }: IAuthPasswordForm,
  setAuthPassForm: React.Dispatch<React.SetStateAction<IAuthPasswordForm>>
): void => {
  try {
    if (admin) {
      throw new Error(i18nValidation('setting.cantChange.admin'))
    }

    const isAuthEmail = isAuthMethodEmail(team)
    if ((isAuthEmail && !password) || !new_password || !new_password_confirm) {
      throw new Error(i18nValidation('setting.input.password'))
    }
    if (new_password !== new_password_confirm) {
      throw new Error(
        i18nValidation('setting.password.different.confirmPassword')
      )
    }
    if (!checkPasswordComplex(admin, team.is_password_complex, new_password)) {
      throw new Error(i18nValidation('input.complexPassword'))
    }

    modalService.show(i18nAlert('modal.confirm.change.password'), async () => {
      try {
        if (!isAuthEmail) {
          await changePasswordForCustomAuth(id, new_password)
        } else {
          await auth.signInWithEmailAndPassword(email, password)
          await auth.currentUser!.updatePassword(new_password)
        }
        alertService.show(true, i18nAlert('updated.password'))
        setAuthPassForm(initAuthPasswordForm())
      } catch (error) {
        console.log(error)
        alertService.show(false, checkFirebaseError(error))
      }
    })
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * アカウントを削除できるかどうか
 */
const canDeleteAccount = (
  storeCache: IStoreCache,
  subscriptionObj: ISubscriptionObject | null
): boolean => {
  if (!isLoggedIn(storeCache, true)) return false

  if (subscriptionObj) {
    alertService.show(false, i18nValidation('setting.cancel.subscription'))
    return false
  }
  if (storeCache.user!.admin) {
    alertService.show(
      false,
      i18nValidation('setting.cantDelete.account.onThisPage')
    )
    return false
  }
  return true
}

/**
 * 操作ユーザの認証方式によって出現させるモーダルを切り分ける
 */
export const openAuthModal = async (
  storeCache: IStoreCache,
  modalToggle: () => void,
  setIsSNSAuth: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!canDeleteAccount(storeCache, storeCache.selfSubscriptionObj)) return

  try {
    const providerData = auth.currentUser!.providerData
    if ('0' in providerData && providerData[0]?.providerId === 'google.com') {
      const user = await auth.signInWithPopup(
        new firebase.auth.GoogleAuthProvider()
      )
      if (!user) return

      setIsSNSAuth(true)
    }

    modalToggle()
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}

/**
 * アカウントを物理削除する
 */
export const deleteAccount = async (
  storeCache: IStoreCache,
  history: History,
  password: string,
  isSNSAuth: boolean
): Promise<void> => {
  if (!canDeleteAccount(storeCache, storeCache.selfSubscriptionObj)) return

  try {
    if (!isSNSAuth) {
      if (!password) throw new Error(i18nValidation('setting.input.password'))

      await auth.signInWithEmailAndPassword(storeCache.user!.email, password)
    }

    const teamUsers = await findTeamUserById(auth.currentUser!.uid)
    if (!teamUsers) {
      throw new Error(i18nAlert('failedDeleteAccount'))
    }

    await remove(storeCache.team!, storeCache.user!)

    if (teamUsers.length === 1) {
      await auth.currentUser!.delete()
    }

    await sendToAdministrators(
      storeCache.team!.id,
      IAdministratorsNotifyType.UserAccountDeleted,
      storeCache.team!.email!,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      storeCache.user!.email
    )

    alertService.show(true, i18nAlert('unsubscribed'))
    history.push(makeLoginTeamPath(storeCache.team!.id))
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}

/**
 * クレジットカード情報変更画面に遷移（CONNECTアカウント専用）
 */
export const changeCard = async (storeCache: IStoreCache): Promise<void> => {
  const { customer_id } = storeCache.user!
  if (!isLoggedIn(storeCache) || !customer_id) return

  try {
    const { account_id } = storeCache.team!.stripe
    const redirectURL = makeSettingUrl()
    const sessionId = (
      await getCheckoutSessionForSetup(
        account_id,
        undefined,
        customer_id,
        redirectURL,
        redirectURL
      )
    ).id
    const { publishableKey }: IStripe = await get()
    const { Stripe } = window
    const stripe: StripeJs | undefined = Stripe
      ? Stripe(publishableKey, { stripeAccount: account_id })
      : undefined
    if (stripe) stripe.redirectToCheckout({ sessionId })
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * Stripeで定期購入をキャンセルする
 */
export const cancelSubscription = async (
  storeCache: IStoreCache,
  subscriptionObj: ISubscriptionObject | null,
  subscriptionProductName: string
) => {
  if (!isLoggedIn(storeCache, true) || !subscriptionObj) return

  modalService.show(
    i18nAlert('subscription.cancel.disableFromNextUpdate', {
      date: formatUNIXToDate(subscriptionObj.subscription.current_period_end),
      name: subscriptionObj.subscription_item.price.nickname,
    }),
    async () => {
      try {
        const { team, user } = storeCache
        const { current_period_end }: ICancelSubscription =
          await deleteSubscription(
            team!.stripe.account_id,
            subscriptionObj.subscription.id
          )
        await sendToAdministrators(
          team!.id,
          IAdministratorsNotifyType.UserSubscriptionRemoved,
          user!.email,
          '',
          '',
          '',
          '',
          '',
          subscriptionProductName,
          subscriptionObj.subscription_item.price.nickname
        )
        const mailTemplate = team!.mail_templates.find(
          (mt) => mt.event_name === IMailTemplateEventName.CANCEL_SUBSCRIPTION
        )
        if (mailTemplate && mailTemplate.status) {
          const loginUrl = makeLoginTeamUrl(team!.id)
          const mailContent = mailTemplate.email_content
            .replace('{{user_name}}', user!.name || user!.email)
            .replace('{{site_name}}', team!.name || '')
            .replace('{{login_raw_url}}', loginUrl)
            .replace('{{login_url}}', `<a href="${loginUrl}">${loginUrl}</a>`)
            .replace(
              /{{price_name}}/g,
              subscriptionObj.subscription_item.price.nickname
            )
          await send(
            team!.id,
            EmailAction.SENT,
            IMailTemplateEventName.CANCEL_SUBSCRIPTION,
            undefined,
            null,
            user!.email,
            { name: team!.name, email: team!.email! },
            mailTemplate.email_title,
            mailContent,
            mailContent
          )
        }
        modalService.show(
          getExpirationAtInfo(
            team!,
            subscriptionObj.subscription_item.price,
            current_period_end
          ),
          () => null
        )
        await reloadCachedSelfSubscriptionObj(storeCache)
      } catch (error) {
        console.log(error)
        alertService.show(false, checkFirebaseError(error))
      }
    }
  )
}

export const getProductNameInProducts = (
  products: IProduct[],
  subscriptionObj: ISubscriptionObject | null
): string => {
  if (!subscriptionObj) return ''

  const product = products.find(
    (p) => p.id === subscriptionObj.subscription_item.price.product
  )

  if (!product) return ''

  return product.name
}
