import { Routes } from 'common/enums'
import { IProduct } from 'common/interfaces/stripe/product'
import { goBackWithErrorAlert } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/StripeProductForm/FormBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initProductForm } from 'services/admin/product_form'

const StripeProductForm: React.FC = () => {
  const { t } = useTranslation('adminStripeProduct')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const { pathname, state } = useLocation<{ product: IProduct }>()

  if (!storeCache.team!.stripe.account_id) {
    goBackWithErrorAlert(
      history,
      i18nAlert('stripe.setting.unfinish.notFunction')
    )
    return null
  }

  const isCreate = pathname === Routes.AdminStripeProductCreate
  const product: IProduct = isCreate ? initProductForm() : state.product

  return (
    <>
      <H1 title={isCreate ? t('form.new') : t('form.edit')} />

      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminStripeProduct}
          content={t('form.back')}
        />
        <FormBody isCreate={isCreate} productObj={product} history={history} />
      </Container>
    </>
  )
}

export default StripeProductForm
