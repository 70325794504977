import AdminLogo from 'assets/images/admin_logo.png'
import { AuthType } from 'common/auth_type'
import { AuthMethodType, Routes } from 'common/enums'
import { ILogin } from 'common/interfaces/login'
import { ISubscriptionObject } from 'common/interfaces/subscription'
import { IFindTeam } from 'common/interfaces/team'
import { IVideoPurchaseLead } from 'common/interfaces/video'
import { makeJoinUserPath } from 'common/link_path'
import { isAuthMethodEmail, isTrialPlan } from 'common/utils'
import AccentButton from 'components/atoms/Button/Block/AccentButton'
import SignInWithGoogle from 'components/atoms/Button/SignInWithGoogle'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import BlackLink from 'components/atoms/Link/BlackLink'
import Footer from 'components/molecules/Footer'
import { UsingTrialNavbar } from 'components/molecules/User/UsingTrialNavbar/UsingTrialNavbar'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from 'reactstrap'
import {
  InputType,
  findTeam,
  initLogin,
  login,
  replaceManifest,
  onChangeInput as serviceOnChangeInput,
} from 'services/team/login'

const FormBody: React.FC = () => {
  const { t } = useTranslation('common')
  const { teamId } = useParams<{ teamId: string }>()
  const videoPurchaseLead =
    useLocation<IVideoPurchaseLead | null>().state ?? null
  const history = useHistory()

  const isTeamLogin = (teamId ?? '') !== ''

  const [team, setTeam] = useState<IFindTeam | null>(null)
  const [loginState, setLoginState] = useState<ILogin>(initLogin())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [subscriptionObj, setSubscriptionObj] =
    useState<ISubscriptionObject | null>(null)

  useEffect(() => {
    document.body.style.backgroundColor = ''
    findTeam(teamId, setTeam, setIsLoading, setSubscriptionObj, history)
  }, [teamId, setTeam, history])

  useEffect(() => {
    replaceManifest(team)
  }, [team])

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(type, e, loginState, setLoginState)

  const loginTeam = (authType: AuthType) =>
    login(history, loginState, authType, team, videoPurchaseLead)

  if (isLoading) return <FullLoadingSpinner />

  const isSamlAuth = isTeamLogin && team?.saml_provider_id

  const samlFormElement = (
    <div className="text-center mt-3">
      <Button color="primary" onClick={() => loginTeam(AuthType.SAML)}>
        {t('login.form.saml')}
      </Button>
    </div>
  )

  const registerLink = ((isTeamLogin && team && team.urls.account_register) ||
    (!isTeamLogin && !team)) && (
    <div className="text-center text-muted mt-4">
      <Link
        to={{
          pathname:
            team && team.urls.account_register
              ? makeJoinUserPath(team.urls.account_register)
              : Routes.Register,
          state: videoPurchaseLead,
        }}
      >
        {t('login.form.noRegistered')}
      </Link>
    </div>
  )

  const standardFormElement = (
    <Form role="form">
      {!isSamlAuth ? (
        <>
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <Input
                onChange={(e) => onChangeInput(InputType.EMAIL, e)}
                placeholder={t('login.form.email')}
                type="email"
                autoComplete="email"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <Input
                onChange={(e) => onChangeInput(InputType.PASS, e)}
                placeholder={t('login.form.password')}
                type="password"
                autoComplete="password"
              />
            </InputGroup>
          </FormGroup>

          <div className="text-center">
            <AccentButton
              onClick={() => loginTeam(AuthType.EMAIL)}
              className="my-4"
              content={t('login.form.login')}
              colors={team?.colors}
            />
          </div>

          {((isTeamLogin && team && team.social_logins.google) ||
            (!isTeamLogin && !team)) && (
            <SignInWithGoogle
              onClick={() => loginTeam(AuthType.GOOGLE)}
              centered
            />
          )}

          {team?.auth_method !== AuthMethodType.CUSTOM && (
            <div className="text-center text-muted mt-4">
              <BlackLink
                to={Routes.PasswordResetRequest}
                content={t('login.form.forgotPassword')}
              />
            </div>
          )}
        </>
      ) : (
        samlFormElement
      )}

      {registerLink}
    </Form>
  )

  const customFormElement = !isSamlAuth ? (
    <Form role="form">
      <FormGroup className="mb-3">
        <InputGroup className="input-group-alternative">
          <Input
            onChange={(e) => onChangeInput(InputType.CUSTOM, e)}
            placeholder="ID"
            type="text"
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup className="input-group-alternative">
          <Input
            onChange={(e) => onChangeInput(InputType.PASS, e)}
            placeholder={t('login.form.password')}
            type="password"
          />
        </InputGroup>
      </FormGroup>
      <div className="text-center">
        <AccentButton
          onClick={() => loginTeam(AuthType.CUSTOM)}
          className="my-4"
          content={t('login.form.login')}
          colors={team?.colors}
        />
      </div>

      {registerLink}
    </Form>
  ) : (
    <>
      {samlFormElement}
      {registerLink}
    </>
  )

  const authTypeElement = (() => {
    if (!isTeamLogin && !team) {
      return (
        <>
          <div className="text-center mb-4">
            <img src={AdminLogo} alt="admin logo" width="200" height="auto" />
          </div>
          <div className="text-center text-muted mb-5">{t('login.title')}</div>
          {standardFormElement}
        </>
      )
    }

    const isAuthEmail = team && isAuthMethodEmail(team)
    if (isTeamLogin && team && team.logo_path) {
      return (
        <>
          <div className="text-center text-muted mb-4">
            <Trans
              t={t}
              i18nKey="login.customTitle"
              values={{ team: team.name }}
              components={[<strong />]}
            />
          </div>
          <div className="text-center mb-5">
            <img
              src={team.logo_path}
              alt={team.name}
              width="200"
              height="auto"
              style={{ borderRadius: '4px' }}
            />
          </div>
          {isAuthEmail ? standardFormElement : customFormElement}
        </>
      )
    }
    if (isTeamLogin && team) {
      return (
        <>
          <div className="text-center text-muted mb-5">
            <Trans
              t={t}
              i18nKey="login.customTitle"
              values={{ team: team.name }}
              components={[<strong />]}
            />
          </div>
          {isAuthEmail ? standardFormElement : customFormElement}
        </>
      )
    }
  })()

  return (
    <div>
      {isTeamLogin && isTrialPlan(subscriptionObj) && <UsingTrialNavbar />}
      <div className="main-content">
        <Container className="mt-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  {authTypeElement}
                </CardBody>
              </Card>
              {!isTeamLogin && (
                <div className="text-center mt-3">
                  <BlackLink to={Routes.SamlLogin} content="SAML認証はこちら" />
                </div>
              )}
            </Col>
          </Row>
        </Container>

        <Footer team={team ?? undefined} />
      </div>
    </div>
  )
}

export default FormBody
