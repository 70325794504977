import { IStoreCache } from 'common/interfaces/auth_provider'
import {
  FieldCustomizePublicStatus,
  IFieldCustomize,
  IFieldCustomizeType,
  IFieldName,
} from 'common/interfaces/field_customize'
import { checkFirebaseError, isDuplicate, isLoggedIn } from 'common/utils'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { reloadCachedTeam } from 'providers/AuthProvider'
import { DropResult } from 'react-beautiful-dnd'
import { updateCustomizeField } from 'repositories/store/firebase_team'
import { alertService } from 'services/alert'

/**
 * IFieldName初期値設定
 */
export const initFieldName = (): IFieldName => {
  return {
    text: '',
    textarea: '',
    number: '',
    date: '',
    bool: '',
    radio: '',
    pulldown: '',
    text_public_status: FieldCustomizePublicStatus.PUBLIC,
    textarea_public_status: FieldCustomizePublicStatus.PUBLIC,
    number_public_status: FieldCustomizePublicStatus.PUBLIC,
    date_public_status: FieldCustomizePublicStatus.PUBLIC,
    bool_public_status: FieldCustomizePublicStatus.PUBLIC,
    radio_public_status: FieldCustomizePublicStatus.PUBLIC,
    pulldown_public_status: FieldCustomizePublicStatus.PUBLIC,
    text_required: false,
    textarea_required: false,
    number_required: false,
    date_required: false,
    bool_required: false,
    radio_required: false,
    pulldown_required: false,
  }
}

/**
 * 追加済カード内onChangeイベントハンドラ
 */
export const onChangeCustomizeField = (
  index: number,
  customizeFieldStates: {
    customizeFields: IFieldCustomize[]
    setCustomizeFields: React.Dispatch<React.SetStateAction<IFieldCustomize[]>>
  },
  e: React.ChangeEvent<HTMLInputElement>,
  changeType: ChangeAddCardType
): void => {
  const item = customizeFieldStates.customizeFields[index]
  const newItem: IFieldCustomize = (() => {
    switch (changeType) {
      case ChangeAddCardType.TITLE:
        return { ...item, fieldName: e.target.value }
      case ChangeAddCardType.PUBLIC_STATUS:
        const publicStatus = e.target.value as FieldCustomizePublicStatus
        const isRequired =
          publicStatus === FieldCustomizePublicStatus.PUBLIC
            ? item.isRequired
            : false
        return { ...item, publicStatus, isRequired }
      case ChangeAddCardType.REQUIRED:
        return { ...item, isRequired: e.target.checked }
    }
  })()

  customizeFieldStates.customizeFields.splice(index, 1, newItem)
  customizeFieldStates.setCustomizeFields([
    ...customizeFieldStates.customizeFields,
  ])
}

export enum ChangeAddCardType {
  TITLE,
  PUBLIC_STATUS,
  REQUIRED,
}

/**
 * 追加カード内onChangeイベントハンドラ
 */
export const onChangeAddCard = (
  customizeFieldStates: {
    fieldName: IFieldName
    setFieldName: React.Dispatch<React.SetStateAction<IFieldName>>
  },
  e: React.ChangeEvent<HTMLInputElement>,
  type: IFieldCustomizeType,
  changeType: ChangeAddCardType
): void => {
  const newFieldName = { ...customizeFieldStates.fieldName }

  const { value, checked } = e.target
  if (changeType === ChangeAddCardType.TITLE) {
    newFieldName[type] = value
  } else if (changeType === ChangeAddCardType.PUBLIC_STATUS) {
    newFieldName[`${type}_public_status`] = value as FieldCustomizePublicStatus
  } else if (changeType === ChangeAddCardType.REQUIRED) {
    newFieldName[`${type}_required`] = checked
  }

  customizeFieldStates.setFieldName(newFieldName)
}

/**
 * 追加カード内追加ボタン押下時
 */
export const onAddItem = (
  type: IFieldCustomizeType,
  fieldNamObj: IFieldName,
  customizeFieldStates: {
    customizeFields: IFieldCustomize[]
    setCustomizeFields: React.Dispatch<React.SetStateAction<IFieldCustomize[]>>
  },
  options: string[],
  setOptions: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const { customizeFields, setCustomizeFields } = customizeFieldStates

  try {
    const fieldName = checkFieldName(fieldNamObj[type], customizeFields)
    const publicStatus = fieldNamObj[`${type}_public_status`]
    const isRequired =
      publicStatus === FieldCustomizePublicStatus.PUBLIC
        ? fieldNamObj[`${type}_required`]
        : false

    let newOptions: string[] = []
    if (
      [IFieldCustomizeType.RADIO, IFieldCustomizeType.PULLDOWN].includes(type)
    ) {
      if (options.length <= 1) {
        throw new Error(i18nValidation('options.moreThanOne'))
      }
      if (options.includes('')) {
        throw new Error(i18nValidation('options.includeEmpty'))
      }
      if (new Set(options).size !== options.length) {
        throw new Error(i18nValidation('options.duplication'))
      }
      newOptions = options
      setOptions([''])
    }

    const customizeField: IFieldCustomize = {
      type,
      fieldName,
      publicStatus,
      isRequired,
      options: newOptions,
    }
    setCustomizeFields([...customizeFields, customizeField])
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * 項目名存在確認
 */
const checkFieldName = (
  fieldName: string,
  customizeFields: IFieldCustomize[]
): string => {
  if (!fieldName) throw new Error(i18nValidation('required.fieldName'))
  const existsFieldName = customizeFields.find(
    (cf) => cf.fieldName === fieldName
  )
  if (existsFieldName) throw new Error(i18nValidation('exist.fieldName'))
  return fieldName
}

/**
 * 表示順のxボタンを押下した際
 */
export const onRemoveItem = (
  position: number,
  customizeFieldStates: {
    customizeFields: IFieldCustomize[]
    setCustomizeFields: React.Dispatch<React.SetStateAction<IFieldCustomize[]>>
  }
) => {
  customizeFieldStates.customizeFields.splice(position, 1)
  customizeFieldStates.setCustomizeFields([
    ...customizeFieldStates.customizeFields,
  ])
}

/**
 * 表示順のドラッグが終了した際
 */
export const onDragEnd = (
  result: DropResult,
  customizeFieldStates: {
    customizeFields: IFieldCustomize[]
    setCustomizeFields: React.Dispatch<React.SetStateAction<IFieldCustomize[]>>
  }
) => {
  if (!result.destination) return

  const items = (() => {
    const data = Array.from(customizeFieldStates.customizeFields)
    const [removed] = data.splice(result.source.index, 1)
    data.splice(result.destination.index, 0, removed)
    return data
  })()
  customizeFieldStates.setCustomizeFields(items)
}

/**
 * チームのカスタマイズ項目の表示順更新処理
 */
export const updateTeamFieldCustomize = async (
  fieldCustomize: IFieldCustomize[],
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    if (isDuplicate(fieldCustomize, 'fieldName')) {
      throw new Error(i18nValidation('duplication.field'))
    }

    await updateCustomizeField(storeCache.team!, fieldCustomize)
    await reloadCachedTeam(storeCache)

    alertService.show(true, i18nAlert('updated.order'))
  } catch (error) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, message)
  }
}

/**
 * 追加箇所のオプション入力欄変更処理
 */
export const onChangeOption = (
  options: string[],
  setOptions: React.Dispatch<React.SetStateAction<string[]>>,
  e: React.ChangeEvent<HTMLInputElement>,
  index: number
): void => {
  options.splice(index, 1, e.target.value)
  setOptions([...options])
}

/**
 * 当該するオプション入力欄の削除処理
 */
export const onDeleteOption = (
  options: string[],
  setOptions: React.Dispatch<React.SetStateAction<string[]>>,
  index: number
): void => {
  options.splice(index, 1)
  setOptions([...options])
}

/**
 * ラベル入力欄を追加
 */
export const onAddOption = (
  options: string[],
  setOptions: React.Dispatch<React.SetStateAction<string[]>>
) => setOptions([...options, ''])
