import { Routes } from 'common/enums'
import { IInvite } from 'common/interfaces/invite'
import { goBackWithErrorAlert } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/InviteMail/FormBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { Container } from 'reactstrap'

const InviteMail: React.FC = () => {
  const { t } = useTranslation('adminInvite')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const { state } = useLocation<IInvite>()
  if (!state.id) {
    goBackWithErrorAlert(history, i18nAlert('cantOpenThisPage'))
    return null
  }

  return (
    <>
      <H1 title={t('inviteMail.title')} />
      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminInvite}
          content={t('inviteMail.backToList')}
        />
        <FormBody storeCache={storeCache} invite={state} history={history} />
      </Container>
    </>
  )
}

export default InviteMail
