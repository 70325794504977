import firebase from 'common/firebase'

export type BunnyStatus =
  | 'Uploading'
  | 'Queued'
  | 'Processing'
  | 'Encoding'
  | 'Finished'
  | 'ResolutionFinished'
  | 'Failed'
  | 'PresignedUploadStarted'
  | 'PresignedUploadFinished'
  | 'PresignedUploadFailed'

interface IVideoBunny {
  videoId: string
  status: BunnyStatus
  length: number
  storageSize: number
}

export enum VideoStatus {
  PRIVATE = 1,
  PUBLIC = 2,
  PUBLICATION_PERIOD = 3,
}

export interface IPlayablePeriod {
  from: firebase.firestore.Timestamp
  to: firebase.firestore.Timestamp
}

export interface IPublicationPeriod {
  start: firebase.firestore.Timestamp
  end: firebase.firestore.Timestamp | null
}

export interface IActionOnEnd {
  text: string
  link: string
  as_new_tab: boolean
}

export interface IVideoEnquete {
  activate_enquete: boolean
  enquete_id: string
}

export interface IVideoTest {
  activate_test: boolean
  test_id: string
}

export interface IVideoNextVideo {
  activate_next_video: boolean
  next_video_id: string
}

export interface IVideo {
  id: string
  bunny: IVideoBunny
  name: string
  image: string | null
  overview: string | null
  category_id: string
  tag_ids: string[]
  filelist_ids: string[]
  creator_id: string
  status: VideoStatus
  group_ids: string[]
  price_ids: string[]
  view_count: number
  playable_period: IPlayablePeriod | null
  publication_period: IPublicationPeriod | null
  action_onend: IActionOnEnd | null
  enquete?: IVideoEnquete
  test?: IVideoTest
  next_video?: IVideoNextVideo
  is_seekable: boolean
  is_public: boolean
  is_list_hidden: boolean
  is_premiere: boolean
  is_registered: boolean
  is_uploaded: boolean
  created_at: firebase.firestore.Timestamp
  updated_at: firebase.firestore.Timestamp
  released_at: firebase.firestore.Timestamp | null
}

export interface IVideoPurchaseLead {
  redirectPath: string
  price?: string
  couponId?: string
  isInvoicePayment?: boolean
}
