import classNames from 'classnames'
import { findCachedTagsNonNull } from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ILive } from 'common/interfaces/live'
import { IVideo } from 'common/interfaces/video'
import { duration2str, isLiveVideo } from 'common/utils'
import { createBunnyAnimationUrl, createBunnyHlsUrl } from 'common/utils_bunny'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Col, Row } from 'reactstrap'
import { getVideoResume } from 'services/local_storage'
import './widecontentcard.scss'

interface IWideContentCard {
  className?: string
  content?: IVideo | ILive
  videoId?: string
  customTitle?: string
  purchaseds?: string[]
}

const WideContentCard: React.FC<IWideContentCard> = ({
  className,
  content,
  videoId,
  purchaseds,
}: IWideContentCard) => {
  const { storeCache } = useContext(AuthContext)

  if (!content && !videoId) return null
  if (!content) {
    const cachedContent =
      storeCache.videos.find((v) => v.id === videoId) ||
      storeCache.lives.find((l) => l.id === videoId)
    if (!cachedContent) return null

    content = cachedContent
  }

  // issue for #1220
  // const title = customTitle
  //   ? customTitle.replace('{{title}}', content.name)
  //   : content.name
  const title = content.name

  return (
    <div className={classNames('wide-video-card', className)}>
      <Row>
        <Col className="thumbnail-col" xs={12} md={3}>
          <div className="thumbnail">
            <ContentThumbnail content={content} alt={title} />
          </div>
        </Col>
        <Col className="texts" xs={12} md={9}>
          <div className="title">{title}</div>
          {purchaseds && purchaseds.length > 0 && (
            <div className="description purchaseds">
              {creatPurchaseds(purchaseds)}
            </div>
          )}
          <div className="description tags">
            {createTags(storeCache, content)}
          </div>
        </Col>
      </Row>
    </div>
  )
}

const creatPurchaseds = (purchaseds: string[]): JSX.Element[] =>
  purchaseds.map((p, i) => (
    <div key={i} className="tag">
      {p}
    </div>
  ))

const createTags = (
  storeCache: IStoreCache,
  { tag_ids }: IVideo | ILive
): JSX.Element[] =>
  findCachedTagsNonNull(storeCache, tag_ids).map((tag) => (
    <div key={tag.id} className="tag">
      {tag.name}
    </div>
  ))

const ContentThumbnail: React.FC<{ content: IVideo | ILive; alt: string }> = ({
  content,
  alt,
}) => {
  const { storeCache } = useContext(AuthContext)

  if (isLiveVideo(content)) {
    if (!content.image) return null
    return <img className="live-thumbnail" src={content.image} alt={alt} />
  }

  const video = content as IVideo
  const animationUrl = createBunnyAnimationUrl(storeCache.team!, video)
  const playedPercent = (() => {
    const pos = getVideoResume(createBunnyHlsUrl(storeCache.team!, video))
    if (video.bunny.length <= 0 || pos === null || pos <= 0) return 0
    return Math.floor((pos / video.bunny.length) * 100)
  })()
  return (
    <>
      {video.image && (
        <img className="video-thumbnail" src={video.image} alt={alt} />
      )}
      <img className="video-preview" src={animationUrl} alt="" />
      <div className="video-duration">{duration2str(video.bunny.length)}</div>
      <div className="played-position" style={{ width: `${playedPercent}%` }} />
    </>
  )
}

export default WideContentCard
