import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import '../order-customize-style.scss'

/**
 * 並び順で使用する各カードの生成
 */
export const SelectedCard: React.FC<{
  index: number
  videoId: string
}> = ({ index, videoId }): JSX.Element => {
  const { videos } = useContext(AuthContext).storeCache

  const name = useTranslation('adminPlaylist').t('list.videoOrder.cardName', {
    index: index + 1,
    videoName: videos.find((v) => v.id === videoId)?.name ?? '',
  })

  return <div className="placed-card">{name}</div>
}
