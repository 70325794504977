import { CouponDiscountType, CouponDurationType } from 'common/enums'
import { ICoupon } from 'common/interfaces/stripe/coupon'
import { formatUNIXToDate, isTodayThanAfter } from 'common/times'
import { i18nValidation } from 'i18n/i18n'

/**
 * Stripeクーポンマスタ登録・編集用バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param coupon ICoupon
 */
export const validateForm = (
  coupon: ICoupon,
  type: CouponDiscountType
): void => {
  if (!coupon.name) {
    throw new Error(i18nValidation('coupon.input.name'))
  }
  if (type === CouponDiscountType.PERCENT) {
    if (!coupon.percent_off) {
      throw new Error(i18nValidation('coupon.input.discountRate.moreThan0'))
    }
    if (coupon.percent_off > 100) {
      throw new Error(i18nValidation('coupon.input.discountRate.hundredOrLess'))
    }
  } else if (type === CouponDiscountType.AMOUNT) {
    if (!coupon.amount_off || !coupon.currency) {
      throw new Error(i18nValidation('coupon.input.amount_off'))
    }
  }
  if (
    coupon.duration === CouponDurationType.REPEATING &&
    !coupon.duration_in_months
  ) {
    throw new Error(i18nValidation('coupon.set.monthsCount'))
  }
  if (
    coupon.redeem_by &&
    !isTodayThanAfter(formatUNIXToDate(coupon.redeem_by))
  ) {
    throw new Error(i18nValidation('coupon.input.date.afterToday'))
  }
}
