import Chart from 'chart.js'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ITest } from 'common/interfaces/test'
import { ITestAnswer } from 'common/interfaces/test_answer'
import { isLoggedIn } from 'common/utils'
import { i18nAdminTest } from 'i18n/i18n'
import { get } from 'repositories/store/firebase_test_answer'

/**
 * テストの回答取得処理
 */
export const getTestAnswers = async (
  storeCache: IStoreCache,
  test: ITest,
  setTestAnswer: React.Dispatch<React.SetStateAction<ITestAnswer[]>>
) => {
  if (!isLoggedIn(storeCache)) return

  setTestAnswer(await get(storeCache.team!, test))
}

/**
 * 正答率Chartjsデータを作成
 */
export const createPassedPieData = (
  answers: ITestAnswer[]
): { chartData: Chart.ChartData; chartOptions: Chart.ChartOptions } => {
  const passedUserCount = answers.filter((answer) =>
    answer.answers.some((a) => a.is_passed)
  ).length
  const failedUserCount = answers.length - passedUserCount
  const passedPercent = Math.round((passedUserCount / answers.length) * 100)

  const data = [passedUserCount, failedUserCount]
  const chartData: Chart.ChartData = {
    labels: [i18nAdminTest('answer.pass'), i18nAdminTest('answer.failed')],
    datasets: [{ type: 'pie', data, backgroundColor: ['#00e676', '#ff1744'] }],
  }
  const chartOptions: Chart.ChartOptions = {
    responsive: true,
    aspectRatio: 2 / 1,
    plugins: {
      title: {
        display: true,
        font: { size: 18 },
        text: `${i18nAdminTest('answer.passedPercent', { passedPercent })}`,
      },
      tooltip: {
        callbacks: {
          label: (item) =>
            i18nAdminTest('answer.passedPeoples', {
              peoples: data[item.dataIndex],
            }),
        },
      },
    },
  }

  return { chartData, chartOptions }
}
