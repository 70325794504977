import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEmailLog } from 'common/interfaces/email_logs'
import { isLoggedIn } from 'common/utils'
import { getByForeignId } from 'repositories/store/firebase_email_logs'

/**
 * 招待ごとに一括でメール送信をした履歴をStateに保存する
 */
export const getEmailLogs = async (
  storeCache: IStoreCache,
  foreignId: string,
  setData: React.Dispatch<React.SetStateAction<IEmailLog[]>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  setData(await getByForeignId(storeCache.team!, foreignId))
}
