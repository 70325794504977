import { IStoreCache } from 'common/interfaces/auth_provider'
import { ContentsOrderType } from 'common/interfaces/contents_orders'
import { ILive } from 'common/interfaces/live'
import { ILog } from 'common/interfaces/log'
import { IVideo } from 'common/interfaces/video'
import {
  makeBookmarkPath,
  makeCategoryLivePath,
  makeCategoryVideoPath,
  makeCreatorDetailPath,
  makeLivePath,
  makePlaylistPath,
  makePlaylistVideoPath,
  makeTagLivePath,
  makeTagVideoPath,
  makeVideoPath,
  makeViewLogPath,
  newerLivePath,
  newerVideosPath,
  popularVideosPath,
} from 'common/link_path'
import { sortByContentsOrderType, sortByViewCount } from 'common/sort_contents'
import { isLoggedIn } from 'common/utils'
import { i18nUserIndex } from 'i18n/i18n'
import { get as getLog } from 'repositories/store/firebase_log'

/**
 * SideScrollVideoListのリストタイプenum
 */
export enum ListType {
  NEW_VIDEOS,
  POPULAR_VIDEOS,
  POPULAR_VIDEOS_IN_GENRE,
  VIDEOS_IN_CATEGORY,
  VIDEOS_IN_TAG,
  VIDEOS_IN_CREATOR,
  NEW_LIVES,
  LIVES_IN_CATEGORY,
  LIVES_IN_TAG,
  LIVES_IN_CREATOR,
  VIDEOS_IN_PLAYLIST,
  USER_BOOKMARKS,
  USER_VIEW_LOGS,
}

const findVideo = (targetId: string | undefined, { videos }: IStoreCache) =>
  videos.find((v) => v.id === targetId)
const findCategory = (
  targetId: string | undefined,
  { categories }: IStoreCache
) => categories.find((c) => c.id === targetId)
const findTag = (targetId: string | undefined, { tags }: IStoreCache) =>
  tags.find((t) => t.id === targetId)
const findCreator = (targetId: string | undefined, { creators }: IStoreCache) =>
  creators.find((c) => c.id === targetId)
const findCategoryTagCreator = (
  targetId: string | undefined,
  storeCache: IStoreCache
) => {
  const category = findCategory(targetId, storeCache)
  if (category) {
    return { data: category, type: 'category' }
  }
  const tag = findTag(targetId, storeCache)
  if (tag) {
    return { data: tag, type: 'tag' }
  }
  const creator = findCreator(targetId, storeCache)
  if (creator) {
    return { data: creator, type: 'creator' }
  }
  return null
}
const findPlaylist = (
  targetId: string | undefined,
  { playlists }: IStoreCache
) => playlists.find((p) => p.id === targetId)

/**
 * ユーザーの視聴ログを取得する
 */
export const getUserViewLog = async (
  storeCache: IStoreCache,
  listType: ListType,
  setUserLog: React.Dispatch<React.SetStateAction<ILog | null | undefined>>
) => {
  if (listType !== ListType.USER_VIEW_LOGS || !isLoggedIn(storeCache, true)) {
    return
  }

  const userLog = await getLog(storeCache.team!, storeCache.user!)
  setUserLog(userLog)
}

/**
 * リストタイプに応じたタイトルを返す
 */
export const createTitle = (
  type: ListType,
  targetId: string | undefined,
  storeCache: IStoreCache
): string => {
  if (type === ListType.POPULAR_VIDEOS_IN_GENRE) {
    const genre = findCategoryTagCreator(targetId, storeCache)
    if (!genre) return ''
    const prefix = genre.type === 'tag' ? '#' : ''
    return i18nUserIndex('sideScrollVideoList.titles.popularVideoInGenre', {
      name: prefix + genre.data.name,
    })
  }

  const i18n = i18nUserIndex('sideScrollVideoList.titles', {
    returnObjects: true,
  })
  switch (type) {
    case ListType.NEW_VIDEOS:
      return i18n.newVideo
    case ListType.POPULAR_VIDEOS:
      return i18n.popularVideo
    case ListType.NEW_LIVES:
      return i18n.newLive
    case ListType.VIDEOS_IN_CATEGORY:
    case ListType.LIVES_IN_CATEGORY:
      return findCategory(targetId, storeCache)?.name ?? ''
    case ListType.VIDEOS_IN_TAG:
    case ListType.LIVES_IN_TAG:
      return `#${findTag(targetId, storeCache)?.name ?? ''}`
    case ListType.VIDEOS_IN_CREATOR:
    case ListType.LIVES_IN_CREATOR:
      return findCreator(targetId, storeCache)?.name ?? ''
    case ListType.VIDEOS_IN_PLAYLIST:
      return findPlaylist(targetId, storeCache)?.name ?? ''
    case ListType.USER_BOOKMARKS:
      return i18n.bookmarksVideo
    case ListType.USER_VIEW_LOGS:
      return i18n.viewHistory
  }
}

/**
 * リストタイプに応じた「すべて表示」リンクを返す
 */
export const createShowAllLink = (
  type: ListType,
  targetId: string | undefined,
  storeCache: IStoreCache
): string => {
  switch (type) {
    case ListType.NEW_VIDEOS:
      return newerVideosPath()
    case ListType.POPULAR_VIDEOS:
      return popularVideosPath()
    case ListType.POPULAR_VIDEOS_IN_GENRE: {
      const genre = findCategoryTagCreator(targetId, storeCache)
      switch (genre?.type) {
        case 'category':
          return makeCategoryVideoPath(genre.data.id)
        case 'tag':
          return makeTagVideoPath(genre.data.id)
        case 'creator':
          return makeCreatorDetailPath(genre.data.id)
      }
      return ''
    }
    case ListType.VIDEOS_IN_CATEGORY:
      return makeCategoryVideoPath(findCategory(targetId, storeCache)?.id ?? '')
    case ListType.VIDEOS_IN_TAG:
      return makeTagVideoPath(findTag(targetId, storeCache)?.id ?? '')
    case ListType.VIDEOS_IN_CREATOR:
      return makeCreatorDetailPath(findCreator(targetId, storeCache)?.id ?? '')
    case ListType.NEW_LIVES:
      return newerLivePath()
    case ListType.LIVES_IN_CATEGORY:
      return makeCategoryLivePath(findCategory(targetId, storeCache)?.id ?? '')
    case ListType.LIVES_IN_TAG:
      return makeTagLivePath(findTag(targetId, storeCache)?.id ?? '')
    case ListType.LIVES_IN_CREATOR:
      return makeCreatorDetailPath(findCreator(targetId, storeCache)?.id ?? '')
    case ListType.VIDEOS_IN_PLAYLIST:
      return makePlaylistPath(findPlaylist(targetId, storeCache)?.id ?? '')
    case ListType.USER_BOOKMARKS:
      return makeBookmarkPath()
    case ListType.USER_VIEW_LOGS:
      return makeViewLogPath()
  }
}

const getVideoData = (
  type: ListType,
  targetId: string | undefined,
  userLog: ILog | null | undefined,
  storeCache: IStoreCache
) => {
  const videos = storeCache.videos.filter((v) => !v.is_list_hidden)
  const lives = storeCache.lives.filter((l) => !l.is_list_hidden)
  const contentsOrders = storeCache.team!.default_contents_orders

  switch (type) {
    case ListType.NEW_VIDEOS:
      return sortByContentsOrderType(videos, ContentsOrderType.NEWER)
    case ListType.POPULAR_VIDEOS:
      return sortByViewCount(videos)
    case ListType.POPULAR_VIDEOS_IN_GENRE:
      const genreVs = videos.filter(
        (v) =>
          v.category_id === targetId ||
          v.tag_ids.includes(targetId ?? '') ||
          v.creator_id === targetId
      )
      return sortByViewCount(genreVs)
    case ListType.VIDEOS_IN_CATEGORY:
      const catVs = videos.filter((v) => v.category_id === targetId)
      return sortByContentsOrderType(catVs, contentsOrders.video)
    case ListType.VIDEOS_IN_TAG:
      const tagVs = videos.filter((v) => v.tag_ids.includes(targetId ?? ''))
      return sortByContentsOrderType(tagVs, contentsOrders.video)
    case ListType.VIDEOS_IN_CREATOR:
      const creatorVs = videos.filter((v) => v.creator_id === targetId)
      return sortByContentsOrderType(creatorVs, contentsOrders.video)
    case ListType.NEW_LIVES:
      return lives.sort((a, b) => b.created_at.seconds - a.created_at.seconds)
    case ListType.LIVES_IN_CATEGORY:
      const catLs = lives.filter((l) => l.category_id === targetId)
      return sortByContentsOrderType(catLs, contentsOrders.live)
    case ListType.LIVES_IN_TAG:
      const tagLs = lives.filter((l) => l.tag_ids.includes(targetId ?? ''))
      return sortByContentsOrderType(tagLs, contentsOrders.live)
    case ListType.LIVES_IN_CREATOR:
      const creatorLs = lives.filter((l) => l.creator_id === targetId)
      return sortByContentsOrderType(creatorLs, contentsOrders.live)
    case ListType.VIDEOS_IN_PLAYLIST:
      return (
        findPlaylist(targetId, storeCache)?.video_ids.map((vId) =>
          findVideo(vId, storeCache)
        ) ?? []
      )
    case ListType.USER_BOOKMARKS:
      return storeCache.user!.bookmarks.map((b) =>
        findVideo(b.video_id, storeCache)
      )
    case ListType.USER_VIEW_LOGS:
      return (
        userLog?.video_page_opens.map((vpo) =>
          findVideo(vpo.video_id, storeCache)
        ) ?? []
      )
  }
}

const getVideoLink = (
  type: ListType,
  targetId: string | undefined,
  video: IVideo | ILive
): string => {
  switch (type) {
    case ListType.NEW_LIVES:
    case ListType.LIVES_IN_CATEGORY:
    case ListType.LIVES_IN_TAG:
    case ListType.LIVES_IN_CREATOR:
      return makeLivePath(video.id)
    case ListType.VIDEOS_IN_PLAYLIST:
      return makePlaylistVideoPath(targetId ?? '', video.id)
  }
  return makeVideoPath(video.id)
}

const getOverwriteTitle = (
  type: ListType,
  index: number,
  video: IVideo | ILive
): string | undefined => {
  return type === ListType.VIDEOS_IN_PLAYLIST
    ? `${index + 1}. ${video.name}`
    : undefined
}

/**
 * リストタイプに応じたデータを返す（7個まで）
 */
export const get7VideoData = (
  type: ListType,
  targetId: string | undefined,
  userLog: ILog | null | undefined,
  storeCache: IStoreCache
): {
  video: IVideo | ILive
  link: string
  overwriteTitle: string | undefined
}[] => {
  const sevenVideos = (
    (
      getVideoData(type, targetId, userLog, storeCache) as Array<
        IVideo | ILive | undefined
      >
    ).filter((v) => v !== undefined) as Array<IVideo | ILive>
  ).slice(0, 7)

  return sevenVideos.map((v, i) => ({
    video: v,
    link: getVideoLink(type, targetId, v),
    overwriteTitle: getOverwriteTitle(type, i, v),
  }))
}
