import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEnquete } from 'common/interfaces/enquete'
import { IEnqueteAnswer } from 'common/interfaces/enquete_answer'
import { IVideo } from 'common/interfaces/video'
import { isLoggedIn } from 'common/utils'
import React, { useEffect, useState } from 'react'
import { getEnqueteById } from './enquete'
import { getEnqueteAnswerByUserIdAndVideoId } from './enquete_answer'

/**
 * 動画のアンケート詳細情報を取得する
 */
export const useVideoEnqueteData = (
  isCatalogPage: boolean,
  storeCache: IStoreCache,
  video: IVideo
): {
  enquete: IEnquete | null
  enqueteAnswer: IEnqueteAnswer | null
  setEnqueteAnswer: React.Dispatch<React.SetStateAction<IEnqueteAnswer | null>>
} => {
  const [enquete, setEnquete] = useState<IEnquete | null>(null)
  const [enqueteAnswer, setEnqueteAnswer] = useState<IEnqueteAnswer | null>(
    null
  )

  useEffect(() => {
    if (isCatalogPage || !isLoggedIn(storeCache, true) || !video) return

    if (video.enquete?.activate_enquete) {
      const { enquete_id } = video.enquete
      getEnqueteById(storeCache, enquete_id, setEnquete)
      getEnqueteAnswerByUserIdAndVideoId(
        storeCache,
        enquete_id,
        storeCache.user!.id,
        video.id,
        setEnqueteAnswer
      )
    }
  }, [isCatalogPage, storeCache, video, setEnquete])

  if (!isCatalogPage && (!isLoggedIn(storeCache, true) || !video)) {
    return {
      enquete: null,
      enqueteAnswer: null,
      setEnqueteAnswer,
    }
  }

  return {
    enquete,
    enqueteAnswer,
    setEnqueteAnswer,
  }
}
