import { ILive } from 'common/interfaces/live'
import { ITeam } from 'common/interfaces/team'
import { VideoStatus } from 'common/interfaces/video'
import { nowTimestamp } from 'common/times'
import { Teams } from './firebase_team'
import { isShowVideo } from './firebase_video'

const Lives = (team: ITeam) => Teams.doc(team.id).collection('lives')

const getLives = async (
  team: ITeam,
  userGroupIds: string[],
  isOnlyForUser: boolean
): Promise<ILive[]> => {
  try {
    const liveDatas = await Lives(team).orderBy('created_at', 'desc').get()
    const nowTime = nowTimestamp().seconds
    return liveDatas.docs
      .map((live) => live.data() as ILive)
      .filter((live) => isShowVideo(live, userGroupIds, isOnlyForUser, nowTime))
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get lives order by created_at
 *
 * includes secret videos
 */
export const get = async (team: ITeam, userGroupIds: string[]) =>
  getLives(team, userGroupIds, false)

/**
 * get for user lives order by created_at
 *
 * excludes secret videos
 */
export const getForUser = async (team: ITeam, userGroupIds: string[]) =>
  getLives(team, userGroupIds, true)

/**
 * get for non auth user lives order by created_at
 *
 * `team.is_site_public` must be true
 */
export const getForNonAuthUser = async (team: ITeam): Promise<ILive[]> => {
  try {
    const nowTime = nowTimestamp().seconds
    const liveDatas = await Lives(team)
      .where('is_public', '==', true)
      .orderBy('created_at', 'desc')
      .get()
    return liveDatas.docs
      .map((live) => live.data() as ILive)
      .filter(
        (l) =>
          l.status === VideoStatus.PUBLIC ||
          (l.status === VideoStatus.PUBLICATION_PERIOD &&
            l.publication_period!.start.seconds <= nowTime &&
            l.publication_period!.end!.seconds >= nowTime)
      )
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * add live
 * @throws Firebase error
 */
export const store = async (team: ITeam, live: ILive): Promise<void> => {
  await Lives(team).doc(live.id).set(live)
}

/**
 * update live
 * @throws Firebase error
 */
export const update = async (team: ITeam, live: ILive): Promise<void> => {
  live.updated_at = nowTimestamp()
  await Lives(team).doc(live.id).update(live)
}

/**
 * delete live
 * @throws Firebase error
 */
export const remove = async (team: ITeam, live: ILive): Promise<void> => {
  await Lives(team).doc(live.id).delete()
}

/**
 * remove live image
 * @throws Firebase error
 */
export const removeImage = async (team: ITeam, live: ILive): Promise<void> => {
  live.image = null
  live.updated_at = nowTimestamp()
  await Lives(team).doc(live.id).update(live)
}
