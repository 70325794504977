import firebase, { auth } from 'common/firebase'
import { IAuthProvider, IStoreCache } from 'common/interfaces/auth_provider'
import { IEvent } from 'common/interfaces/event'
import { ILive } from 'common/interfaces/live'
import { ISubscriptionObject } from 'common/interfaces/subscription'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { IVideo } from 'common/interfaces/video'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { fetchSubscriptionObj } from 'repositories/functions/functions_stripe'
import { get as getCategories } from 'repositories/store/firebase_category'
import { get as getCreators } from 'repositories/store/firebase_creator'
import {
  get as getEvents,
  getForUser as getForUserEvents,
} from 'repositories/store/firebase_event'
import { get as getFilelists } from 'repositories/store/firebase_filelist'
import { get as getGroups } from 'repositories/store/firebase_group'
import {
  getForUser as getForUserLives,
  get as getLives,
} from 'repositories/store/firebase_live'
import {
  get as getNewslists,
  getForUser as getNewslistsForUser,
} from 'repositories/store/firebase_news'
import { get as getPlaylists } from 'repositories/store/firebase_playlist'
import { get as getTags } from 'repositories/store/firebase_tag'
import { findById as getTeam } from 'repositories/store/firebase_team'
import { findById as getUser } from 'repositories/store/firebase_user'
import {
  getForUser as getForUserVideos,
  get as getVideos,
} from 'repositories/store/firebase_video'
import { getIsTeamAnswered } from 'services/admin/enquete_answer'
import { getAllStripePrices } from 'services/admin/stripe/price'
import {
  checkRestrictedIpAndAllowedIp,
  checkRestrictedSingleLogin,
} from 'services/auth'
import { getTeamUserData } from 'services/auth_provider'

export const AuthContext = React.createContext<IAuthProvider>({
  currentUser: null,
  storeCache: {
    isLoading: true,
    isSingleLogin: false,
    isIpAllowed: false,
    isAnswered: false,
    setStoreCache: null,
    team: null,
    user: null,
    subscriptionObj: null,
    selfSubscriptionObj: null,
    videos: [],
    lives: [],
    events: [],
    creators: [],
    categories: [],
    tags: [],
    playlists: [],
    filelists: [],
    newslists: [],
    groups: [],
    prices: [],
  },
})

export const AuthProvider = ({ children }: { children: any }): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null)
  const [storeCache, setStoreCache] = useState<IStoreCache>({
    isLoading: true,
    isSingleLogin: false,
    isIpAllowed: false,
    isAnswered: false,
    setStoreCache: null,
    team: null,
    user: null,
    subscriptionObj: null,
    selfSubscriptionObj: null,
    videos: [],
    lives: [],
    events: [],
    creators: [],
    categories: [],
    tags: [],
    playlists: [],
    filelists: [],
    newslists: [],
    groups: [],
    prices: [],
  })
  const history = useHistory()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setCurrentUser)
    if (currentUser === null) {
      return unsubscribe
    }

    ;(async () => {
      const fsUserData = await getTeamUserData(history, currentUser.uid)
      if (!fsUserData) return

      const fsCache = await getFirestoreCache(setStoreCache, fsUserData)
      setStoreCache(fsCache)
    })()

    return unsubscribe
  }, [history, currentUser, setStoreCache])

  return (
    <AuthContext.Provider value={{ currentUser, storeCache }}>
      {children}
    </AuthContext.Provider>
  )
}

const getFirestoreCache = async (
  setStoreCache: React.Dispatch<React.SetStateAction<IStoreCache>>,
  fsUserData: {
    team: ITeam
    user: IUser
  }
): Promise<IStoreCache> => {
  const { team, user } = fsUserData

  const promises = [
    checkRestrictedSingleLogin(team, user),
    checkRestrictedIpAndAllowedIp(team, user),
    getIsTeamAnswered(team, user),
    team.stripeId ? fetchSubscriptionObj(undefined, team.stripeId) : null,
    !user.admin && user.customer_id
      ? fetchSubscriptionObj(team.stripe.account_id, user.customer_id)
      : null,
    user.admin
      ? getVideos(team, user.group_ids)
      : getForUserVideos(team, user.group_ids),
    user.admin
      ? getLives(team, user.group_ids)
      : getForUserLives(team, user.group_ids),
    user.admin ? getEvents(team) : getForUserEvents(team),
    getCreators(team),
    getCategories(team),
    getTags(team),
    getPlaylists(team),
    getFilelists(team),
    user.admin ? getNewslists(team) : getNewslistsForUser(team, user.group_ids),
    getGroups(team),
    user.admin ? getAllStripePrices(team.stripe.account_id) : [],
  ] as const

  const [
    isSingleLogin,
    isIpAllowed,
    isAnswered,
    subscriptionObj,
    selfSubscriptionObj,
    videos,
    lives,
    events,
    creators,
    categories,
    tags,
    playlists,
    filelists,
    newslists,
    groups,
    prices,
  ] = await Promise.all(promises)

  return {
    isLoading: false,
    isSingleLogin,
    isIpAllowed,
    isAnswered,
    setStoreCache,
    team,
    user,
    subscriptionObj,
    selfSubscriptionObj,
    videos,
    lives,
    events,
    creators,
    categories,
    tags,
    playlists,
    filelists,
    newslists,
    groups,
    prices,
  }
}

export const reloadCachedTeam = async (storeCache: IStoreCache) =>
  storeCache.setStoreCache?.({ ...storeCache, team: await newTeam(storeCache) })

export const reloadCachedUser = async (storeCache: IStoreCache) => {
  const newUser = storeCache.user
    ? await getUser(storeCache.team!, storeCache.user.id)
    : null
  storeCache.setStoreCache?.({ ...storeCache, user: newUser })
}

export const reloadCachedSubscriptionObj = async (storeCache: IStoreCache) =>
  storeCache.setStoreCache?.({
    ...storeCache,
    subscriptionObj: await newSubscriptionObj(storeCache),
  })

export const reloadCachedSelfSubscriptionObj = async (
  storeCache: IStoreCache
) =>
  storeCache.setStoreCache?.({
    ...storeCache,
    selfSubscriptionObj: await fetchSubscriptionObj(
      storeCache.team!.stripe.account_id,
      storeCache.user!.customer_id ?? ''
    ),
  })

export const reloadCachedVideos = async (storeCache: IStoreCache) => {
  let newVideos: IVideo[] = []
  if (storeCache.team && storeCache.user) {
    newVideos = storeCache.user.admin
      ? await getVideos(storeCache.team, storeCache.user.group_ids)
      : await getForUserVideos(storeCache.team, storeCache.user.group_ids)
  }
  storeCache.setStoreCache?.({ ...storeCache, videos: newVideos })
}

export const reloadCachedLives = async (storeCache: IStoreCache) => {
  let newLives: ILive[] = []
  if (storeCache.team && storeCache.user) {
    newLives = storeCache.user.admin
      ? await getLives(storeCache.team, storeCache.user.group_ids)
      : await getForUserLives(storeCache.team, storeCache.user.group_ids)
  }
  storeCache.setStoreCache?.({ ...storeCache, lives: newLives })
}

export const reloadCachedEvents = async (storeCache: IStoreCache) => {
  let events: IEvent[] = []
  if (storeCache.team && storeCache.user) {
    events = storeCache.user.admin
      ? await getEvents(storeCache.team)
      : await getForUserEvents(storeCache.team)
  }
  storeCache.setStoreCache?.({ ...storeCache, events })
}

export const reloadCachedCreators = async (storeCache: IStoreCache) => {
  const newCreators = storeCache.team ? await getCreators(storeCache.team) : []
  storeCache.setStoreCache?.({ ...storeCache, creators: newCreators })
}

export const reloadCachedCategories = async (storeCache: IStoreCache) => {
  const newCategories = storeCache.team
    ? await getCategories(storeCache.team)
    : []
  storeCache.setStoreCache?.({ ...storeCache, categories: newCategories })
}

export const reloadCachedTags = async (storeCache: IStoreCache) => {
  const newTags = storeCache.team ? await getTags(storeCache.team) : []
  storeCache.setStoreCache?.({ ...storeCache, tags: newTags })
}

export const reloadCachedPlaylists = async (storeCache: IStoreCache) => {
  const newPlaylists = storeCache.team
    ? await getPlaylists(storeCache.team)
    : []
  storeCache.setStoreCache?.({ ...storeCache, playlists: newPlaylists })
}

export const reloadCachedFilelists = async (storeCache: IStoreCache) => {
  const newFilelists = storeCache.team
    ? await getFilelists(storeCache.team)
    : []
  storeCache.setStoreCache?.({ ...storeCache, filelists: newFilelists })
}

export const reloadCachedNews = async (storeCache: IStoreCache) => {
  const newNewslists = storeCache.team
    ? await getNewslists(storeCache.team)
    : []
  storeCache.setStoreCache?.({ ...storeCache, newslists: newNewslists })
}

export const reloadCachedGroups = async (storeCache: IStoreCache) => {
  const newGroups = storeCache.team ? await getGroups(storeCache.team) : []
  storeCache.setStoreCache?.({ ...storeCache, groups: newGroups })
}

export const reloadCachedPrices = async (storeCache: IStoreCache) => {
  const newPrices = storeCache.team
    ? await getAllStripePrices(storeCache.team.stripe.account_id)
    : []
  storeCache.setStoreCache?.({ ...storeCache, prices: newPrices })
}

export const reloadCachedStoreCache = async (newStoreCache: IStoreCache) =>
  newStoreCache.setStoreCache?.(newStoreCache)

export const newTeam = async (storeCache: IStoreCache): Promise<ITeam | null> =>
  storeCache.team ? getTeam(storeCache.team) : null

export const newSubscriptionObj = async (
  storeCache: IStoreCache,
  stripeId?: string
): Promise<ISubscriptionObject | null> =>
  storeCache.team
    ? fetchSubscriptionObj(undefined, stripeId || storeCache.team.stripeId)
    : null
