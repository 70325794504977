import { Routes } from 'common/enums'
import { findCachedTagsNonNull } from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IPlaylist } from 'common/interfaces/playlist'
import { nowTimestamp } from 'common/times'
import {
  checkFirebaseError,
  createTagOptions,
  createVideoOptions,
  isLoggedIn,
} from 'common/utils'
import { createCategoryJSXOptions } from 'components/atoms/Table/CreateTable'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedPlaylists } from 'providers/AuthProvider'
import { removeImageFile, upload } from 'repositories/storage/firebase_playlist'
import {
  removeImage,
  store,
  update,
} from 'repositories/store/firebase_playlist'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/playlist_form'
import { v4 as uuidv4 } from 'uuid'

/**
 * `IPlaylist`の初期値を返却する
 */
export const initPlaylist = (): IPlaylist => {
  return {
    id: uuidv4(),
    name: '',
    image: null,
    overview: null,
    category_id: '',
    tag_ids: [],
    video_ids: [],
    is_public: false,
    created_at: nowTimestamp(),
    updated_at: nowTimestamp(),
  }
}

/**
 * プレイリストのデフォルト値を返却する
 */
export const getPlaylistDefaults = (
  storeCache: IStoreCache,
  playlist: IPlaylist
) => {
  return {
    defaultVideos: playlist.video_ids.map((vId) => {
      const video = storeCache.videos.find((v) => v.id === vId)
      return { value: video?.id ?? '', label: video?.name ?? '' }
    }),
    defaultTags: findCachedTagsNonNull(storeCache, playlist.tag_ids).map(
      (tag) => ({ value: tag.id, label: tag.name })
    ),
  }
}

/**
 * Input/Selectで使用するoptionsを返却する
 */
export const getFormOptions = (storeCache: IStoreCache) => {
  return {
    categoryOptions: createCategoryJSXOptions(storeCache.categories),
    tagOptions: createTagOptions(storeCache.tags),
    videoOptions: createVideoOptions(storeCache.videos),
  }
}

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
  OVERVIEW,
  CATEGORY_ID,
  TAG_IDS,
  VIDEO_IDS,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  playlist: IPlaylist,
  setPlaylist: React.Dispatch<React.SetStateAction<IPlaylist>>,
  type: InputType,
  e: any
) => {
  const val = e.target?.value
  setPlaylist({
    ...playlist,
    name: type === InputType.NAME ? val : playlist.name,
    overview: type === InputType.OVERVIEW ? e : playlist.overview,
    category_id: type === InputType.CATEGORY_ID ? val : playlist.category_id,
    tag_ids:
      type === InputType.TAG_IDS
        ? e.map((x: any) => x.value)
        : playlist.tag_ids,
    video_ids:
      type === InputType.VIDEO_IDS
        ? e.map((x: any) => x.value)
        : playlist.video_ids,
  })
}

/**
 * プレイリストを未ログインでも表示するチェックボックスの`onChange`イベントハンドラ
 */
export const onChangeIsPublic = (
  playlist: IPlaylist,
  setPlaylist: React.Dispatch<React.SetStateAction<IPlaylist>>,
  { target }: React.ChangeEvent<HTMLInputElement>
) => setPlaylist({ ...playlist, is_public: target.checked })

/**
 * プレイリストレコード作成/更新処理
 */
export const savePlaylist = async (
  { push }: History,
  playlist: IPlaylist,
  imageBlob: Blob | undefined,
  isImageDelete: boolean,
  isImageCropped: boolean,
  storeCache: IStoreCache,
  isCreate: boolean
) => {
  if (!isLoggedIn(storeCache)) return

  try {
    validateForm(playlist, isImageCropped)

    if (imageBlob && !isImageDelete) {
      playlist.image = await upload(storeCache.team!, playlist, imageBlob)
    }
    if (!isCreate && isImageDelete) {
      await deleteImage(playlist, storeCache)
    }

    if (isCreate) {
      playlist.created_at = nowTimestamp()
      playlist.updated_at = nowTimestamp()
      await store(storeCache.team!, playlist)
    } else {
      await update(storeCache.team!, playlist)
    }
    await reloadCachedPlaylists(storeCache)

    push(Routes.AdminPlaylist)
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}

/**
 * プレイリストの画像を削除する(ドキュメント更新 & ファイル削除)
 */
const deleteImage = async (
  playlist: IPlaylist,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    await removeImageFile(storeCache.team!, playlist)
    await removeImage(storeCache.team!, playlist)

    alertService.show(true, i18nAlert('deleted.thumbnail'))
  } catch (error) {
    console.log(error)
    alertService.show(false, i18nAlert('deleted.fail.thumbnail'))
  }
}
