import classNames from 'classnames'
import React from 'react'
import { Label } from 'reactstrap'
import './requiredtext.scss'

interface IRequiredText {
  className?: string
  label?: boolean
  labelFor?: string
  /** default `true` */
  required?: boolean
  children?: React.ReactNode
}

const RequiredText: React.FC<IRequiredText> = (props) => {
  const className = classNames('required-text', props.className)
  const required = props.required === undefined || props.required
  const children = (
    <>
      {props.children}
      {required && <span className="required-text__require">*</span>}
    </>
  )

  if (props.label) {
    return (
      <Label className={className} for={props.labelFor}>
        {children}
      </Label>
    )
  }
  return <div className={className}>{children}</div>
}

export default RequiredText
