import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/FieldCustomize/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'

const FieldCustomize: React.FC = () => {
  const { t } = useTranslation('adminCustomizeFields')
  return (
    <>
      <H1 title={t('title')} />

      <Container fluid>
        <FormBody />
      </Container>
    </>
  )
}

export default FieldCustomize
