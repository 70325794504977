import { ColumnDef } from '@tanstack/react-table'
import 'chart.js/auto'
import { IDailyViewCount } from 'common/interfaces/analytics'
import { IFlattedLog } from 'common/interfaces/log'
import { IFromTo } from 'common/interfaces/time'
import { useTanstackTableWithCsvExport } from 'components/atoms/Table/CreateTable'
import AnalyticsRangeSelector from 'components/molecules/Admin/AnalyticsRangeSelector'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import {
  DatePeriod,
  getSortedUserLogs,
  initFromTo,
  setMonthSelectOptions,
} from 'services/admin/analytics'
import {
  createDailyViewCountChartData,
  setDailyViewCountData,
} from 'services/admin/analytics_total'

const ChartBody: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const [allUserLogs, setAllUserLogs] = useState<IFlattedLog[]>([])
  const [monthOptions, setMonthOptions] = useState<JSX.Element[]>([])
  const [selectedMonth, setSelectedMonth] = useState<string>('')
  const [dailyViewCounts, setDailyViewCounts] = useState<IDailyViewCount[]>([])
  const [selectedDatePeriod, setSelectedDatePeriod] = useState<DatePeriod>(
    DatePeriod.DAYS_7
  )
  const [fromTo, setFromTo] = useState<IFromTo>(initFromTo())

  useEffect(() => {
    getSortedUserLogs(storeCache, setAllUserLogs)
  }, [storeCache])

  useEffect(() => {
    setMonthSelectOptions(allUserLogs, setMonthOptions, setSelectedMonth)
  }, [allUserLogs])

  useEffect(() => {
    setDailyViewCountData(allUserLogs, selectedDatePeriod, setDailyViewCounts, {
      date: selectedMonth,
      fromTo,
    })
  }, [allUserLogs, selectedDatePeriod, selectedMonth, fromTo])

  if (!storeCache.team) return null

  const { chartData, chartOption } = createDailyViewCountChartData(
    dailyViewCounts,
    selectedDatePeriod
  )

  return (
    <>
      <AnalyticsRangeSelector
        className="mb-3"
        monthOptions={monthOptions}
        selectedDatePeriod={[selectedDatePeriod, setSelectedDatePeriod]}
        selectedMonth={[selectedMonth, setSelectedMonth]}
        fromTo={[fromTo, setFromTo]}
      />
      <Chart type="line" data={chartData} options={chartOption} />
      <div className="mt-5">
        <Table viewCounts={dailyViewCounts} />
      </div>
    </>
  )
}

const Table: React.FC<{ viewCounts: IDailyViewCount[] }> = ({ viewCounts }) => {
  const { t } = useTranslation('adminAnalytics')
  const columns = useMemo<ColumnDef<IDailyViewCount>[]>(
    () => [
      {
        header: t('total.table.date'),
        accessorKey: 'date',
      },
      {
        header: t('logType.videoPageOpen'),
        accessorKey: 'page_open_count',
      },
      {
        header: t('logType.videoPlay'),
        accessorKey: 'video_play_count',
      },
      {
        header: t('logType.videoNotPlayRate'),
        accessorKey: 'not_play_percent',
      },
      {
        header: t('logType.videoEnded'),
        accessorKey: 'video_ended_count',
      },
      {
        header: t('logType.videoEndedRate'),
        accessorKey: 'video_ended_percent',
      },
    ],
    [t]
  )
  const data = viewCounts.reverse()

  return useTanstackTableWithCsvExport<IDailyViewCount>(
    columns,
    data,
    'analytics_daily.csv'
  )
}

export default ChartBody
