import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import List from 'components/organisms/User/ChangePrice/List'
import React, { useState } from 'react'
import { Container } from 'reactstrap'

const ChangePrice: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)

  if (loading) return <FullLoadingSpinner />
  return (
    <Container>
      <List setLoading={setLoading} />
    </Container>
  )
}

export default ChangePrice
