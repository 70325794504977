import { IStoreCache } from 'common/interfaces/auth_provider'
import { IStripe as ITeamStripe } from 'common/interfaces/team'
import { i18nValidation } from 'i18n/i18n'

/**
 * team.stripeの保存処理用バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param teamStripe IStripe
 * @param storeCache IStoreCache
 */
export const validateForm = (
  teamStripe: ITeamStripe,
  { team, videos }: IStoreCache
): void => {
  if (!teamStripe.account_id) {
    throw new Error(i18nValidation('input.accountId'))
  }
  if (
    !team!.stripe.is_products_activate &&
    teamStripe.is_products_activate &&
    videos.some(({ price_ids }) => price_ids.length > 0)
  ) {
    throw new Error(i18nValidation('stripe.subscriptionProducts.cantActive'))
  }
}
