import { ILive } from 'common/interfaces/live'
import { nowTimestamp } from 'common/times'

/**
 * 配信予定時刻の開始後か否か
 */
export const isLiveStarted = (live: ILive): boolean =>
  live.hold_from.seconds <= nowTimestamp().seconds

/**
 * すでに配信が終了しているか否か
 */
export const isLiveEnded = (live: ILive): boolean =>
  live.hold_to.seconds <= nowTimestamp().seconds
