import { ITeamColors } from 'common/interfaces/team'
import React from 'react'
import { Button } from 'reactstrap'

interface IAccentButton {
  onClick: () => void
  className?: string
  content: string
  colors?: ITeamColors
}

const AccentButton: React.FC<IAccentButton> = (props: IAccentButton) => {
  return (
    <Button
      onClick={props.onClick}
      className={props.className}
      block
      style={{
        color: props.colors?.background ?? 'white',
        background: props.colors?.accent ?? '#6856FF',
        border: props.colors?.accent ?? '#6856FF',
      }}
    >
      {props.content}
    </Button>
  )
}

export default AccentButton
