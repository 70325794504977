import ZoomAuthorizedBody from 'components/organisms/Admin/AdminSettingZoomSettingAuthorized/ZoomAuthorizedBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { alertService } from 'services/alert'

const AdminSettingZoomSettingAuthorized: React.FC = () => {
  const { t } = useTranslation('alert')
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const userId = query.get('userId')

  if (!userId) {
    alertService.show(false, t('zoom.failed.getUserId'))
    return null
  }

  return <ZoomAuthorizedBody userId={userId} />
}

export default AdminSettingZoomSettingAuthorized
