import Chart from 'chart.js'
import { IVideoViewCount } from 'common/interfaces/analytics'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IFlattedLog, LogType } from 'common/interfaces/log'
import { IFromTo } from 'common/interfaces/time'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { i18nAdminAnalytics } from 'i18n/i18n'
import { DatePeriod, getDatePeriodDuration } from 'services/admin/analytics'
import { alertService } from 'services/alert'
import { validateAnalyticsLimitPeriod } from 'services/validation/analytics'

/**
 * 動画毎の分析を算出する共通関数
 * @param storeCache `IStoreCache`
 * @param allUserLogs `IFlattedLog[]`
 * @param setViewCounts state setter
 * @param startDate `dayjs.Dayjs`
 * @param endDate `dayjs.Dayjs`
 * @returns `void`
 */
const setVideoViewCounts = (
  storeCache: IStoreCache,
  allUserLogs: IFlattedLog[],
  setViewCounts: React.Dispatch<React.SetStateAction<IVideoViewCount[]>>,
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs
): void => {
  dayjs.extend(isBetween)
  const result: IVideoViewCount[] = storeCache.videos.map((v) => ({
    video_id: v.id,
    video_name: v.name,
    page_open_count: 0,
    video_play_count: 0,
    not_play_percent: '-',
    video_ended_count: 0,
    video_ended_percent: '-',
  }))

  allUserLogs
    .filter((l) =>
      dayjs.unix(l.timestamp.seconds).isBetween(startDate, endDate, null, '[]')
    )
    .forEach((l) => {
      const resultTarget = result.find((r) => r.video_id === l.video_id)
      if (!resultTarget) return

      switch (l.type) {
        case LogType.VIDEO_PAGE_OPEN:
          resultTarget.page_open_count += 1
          break
        case LogType.VIDEO_PLAY:
          resultTarget.video_play_count += 1
          break
        case LogType.VIDEO_ENDED:
          resultTarget.video_ended_count += 1
          break
      }
    })

  const filtered = result.filter(
    (r) =>
      r.page_open_count > 0 || r.video_play_count > 0 || r.video_ended_count > 0
  )
  filtered.forEach((vvc) => {
    if (vvc.page_open_count > 0 || vvc.video_play_count > 0) {
      const notPlayPercent =
        (1 - vvc.video_play_count / vvc.page_open_count) * 100
      vvc.not_play_percent = `${Math.round(notPlayPercent)}%`
    }
    if (vvc.video_play_count > 0 || vvc.video_ended_count > 0) {
      const videoEndedPercent =
        (vvc.video_ended_count / vvc.video_play_count) * 100
      vvc.video_ended_percent = `${Math.round(videoEndedPercent)}%`
    }
  })
  const sorted = filtered.sort((a, b) => (a.video_name > b.video_name ? 1 : -1))
  setViewCounts(sorted)
}

/**
 * 動画毎の分析を日付タイプに応じて算出
 * @param storeCache `IStoreCache`
 * @param allUserLogs `IFlattedLog[]`
 * @param datePeriodType `DatePeriod`
 * @param setViewCounts state setter
 * @param target `{ date: string, fromTo: IFromTo }`
 * @returns `void`
 */
export const setVideoViewCountData = (
  storeCache: IStoreCache,
  allUserLogs: IFlattedLog[],
  datePeriodType: DatePeriod,
  setViewCounts: React.Dispatch<React.SetStateAction<IVideoViewCount[]>>,
  target: {
    date: string
    fromTo: IFromTo
  }
): void => {
  const duration = getDatePeriodDuration(datePeriodType, setViewCounts, target)
  if (!duration) return

  try {
    validateAnalyticsLimitPeriod(duration.start, duration.end)
  } catch (error: any) {
    alertService.show(false, error.message)
    setViewCounts([])
    return
  }

  setVideoViewCounts(
    storeCache,
    allUserLogs,
    setViewCounts,
    duration.start,
    duration.end
  )
}

/**
 * `IVideoViewCount[]`からChartjsのデータを作成する
 */
export const createVideoViewCountChartData = (
  viewCounts: IVideoViewCount[],
  datePeriodType: DatePeriod,
  target: {
    date: string
    fromTo: IFromTo
  }
): {
  playChart: [Chart.ChartData, Chart.ChartOptions]
  endedChart: [Chart.ChartData, Chart.ChartOptions]
} => {
  const bgColors = (ids: string[]): string[] => {
    const str2hex = (str: string): number => {
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      return hash
    }

    return ids.map((id) => {
      let color = '#'
      for (let i = 0; i < 3; i++) {
        const value = (str2hex(id) >> (i * 8)) & 0xff
        color += value.toString(16).padStart(2, '0')
      }
      return color
    })
  }

  const backgroundColor = bgColors(viewCounts.map((v) => v.video_id))

  const [playChartTitle, endedChartTitle] = (() => {
    if (datePeriodType === DatePeriod.EVERY_MONTH) {
      if (target.date === '') return ['', '']
      const [year, month] = target.date.split('-')
      const i18nObject = { year, month }
      return [
        i18nAdminAnalytics('videos.chart.videoPlayTitle.month', i18nObject),
        i18nAdminAnalytics('videos.chart.videoEndedTitle.month', i18nObject),
      ]
    }
    if (
      datePeriodType === DatePeriod.SPECIFIED_PERIOD &&
      (target.fromTo.from === '' || target.fromTo.to === '')
    ) {
      return ['', '']
    }

    const [start, end] = (() => {
      if (datePeriodType === DatePeriod.SPECIFIED_PERIOD) {
        return [target.fromTo.from.split('-'), target.fromTo.to.split('-')]
      }

      const nowDateTime = dayjs()
      const dayCount = Number(datePeriodType)
      const startDateTime = nowDateTime.subtract(dayCount, 'day')
      return [
        startDateTime.format('YYYY-MM-DD').split('-'),
        nowDateTime.format('YYYY-MM-DD').split('-'),
      ]
    })()

    const i18nObject = {
      startYear: start[0],
      startMonth: start[1],
      startDay: start[2],
      endYear: end[0],
      endMonth: end[1],
      endDay: end[2],
    }

    return [
      i18nAdminAnalytics(
        'videos.chart.videoPlayTitle.specifiedPeriod',
        i18nObject
      ),
      i18nAdminAnalytics(
        'videos.chart.videoEndedTitle.specifiedPeriod',
        i18nObject
      ),
    ]
  })()

  const playChartData: Chart.ChartData = {
    labels: viewCounts
      .filter((v) => v.video_play_count > 0)
      .map((v) => v.video_name),
    datasets: [
      {
        type: 'pie',
        data: viewCounts
          .filter((v) => v.video_play_count > 0)
          .map((v) => v.video_play_count),
        backgroundColor,
      },
    ],
  }

  const playChartOption: Chart.ChartOptions = {
    responsive: true,
    aspectRatio: 2 / 1,
    plugins: {
      title: {
        display: true,
        font: { size: 18 },
        text: playChartTitle,
      },
    },
  }

  const endedChartData: Chart.ChartData = {
    labels: viewCounts
      .filter((v) => v.video_ended_count > 0)
      .map((v) => v.video_name),
    datasets: [
      {
        type: 'pie',
        data: viewCounts
          .filter((v) => v.video_ended_count > 0)
          .map((v) => v.video_ended_count),
        backgroundColor,
      },
    ],
  }

  const endedChartOption: Chart.ChartOptions = {
    responsive: true,
    aspectRatio: 2 / 1,
    plugins: {
      title: {
        display: true,
        font: { size: 18 },
        text: endedChartTitle,
      },
    },
  }

  return {
    playChart: [playChartData, playChartOption],
    endedChart: [endedChartData, endedChartOption],
  }
}
