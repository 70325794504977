import { IStoreCache } from 'common/interfaces/auth_provider'
import { makeCombinePath } from 'common/link_path'
import { sortBySortNumber } from 'common/sort_contents'
import { i18nUserIndex } from 'i18n/i18n'
import { IContentTypeListParams } from './type_contents_list/page_params'

export type IChooseGenreType =
  | 'content_type'
  | 'category'
  | 'tag'
  | 'creator'
  | 'playStatus'

interface BadgeBaseData {
  id: string
  name: string
}

/**
 * ChooseGenreのタイトルを返却
 */
export const createTitle = (type: IChooseGenreType): string => {
  const i18n = i18nUserIndex('chooseGenre.titles', { returnObjects: true })
  switch (type) {
    case 'content_type':
      return i18n.contentType
    case 'category':
      return i18n.category
    case 'tag':
      return i18n.tag
    case 'creator':
      return i18n.creator
    case 'playStatus':
      return i18n.status
  }
}

/**
 * ChooseGenreで使うバッジのデータを返却
 */
export const createBadgeData = (
  storeCache: IStoreCache,
  type: IChooseGenreType,
  params: IContentTypeListParams
): {
  id: string
  name: string
  linkPath: string
  isTag: boolean
  isEnable: boolean
}[] => {
  const i18n = i18nUserIndex('chooseGenre.badges', { returnObjects: true })
  const target: BadgeBaseData[] = (() => {
    switch (type) {
      case 'content_type': {
        const data = [
          { id: 'videos', name: i18n.video },
          { id: 'lives', name: i18n.live },
          { id: 'events', name: i18n.event },
          { id: 'playlists', name: i18n.playlist },
        ] as const

        const result = data.flatMap((d) =>
          storeCache[d.id].length > 0 ? d : []
        )
        return result.length > 1 ? result : []
      }
      case 'category':
        return sortBySortNumber(storeCache.categories)
      case 'tag':
        return sortBySortNumber(storeCache.tags)
      case 'creator':
        return sortBySortNumber(storeCache.creators)
      case 'playStatus':
        return [
          { id: 'notYet', name: i18n.playStatus.notYet },
          { id: 'playing', name: i18n.playStatus.playing },
          { id: 'played', name: i18n.playStatus.played },
        ]
    }
  })()

  const makeLink = (id: string) => makeCombinePath(params, { type, id })

  const isEnable = (target: BadgeBaseData) =>
    (type === 'content_type' && params.type === target.id) ||
    (type === 'category' &&
      params.categoryId !== undefined &&
      target.id === params.categoryId) ||
    (type === 'tag' &&
      params.tagId !== undefined &&
      target.id === params.tagId) ||
    (type === 'creator' &&
      params.creatorId !== undefined &&
      target.id === params.creatorId) ||
    (type === 'playStatus' &&
      params.playStatus !== undefined &&
      target.id === params.playStatus)

  return target.map((t) => ({
    id: t.id,
    name: t.name,
    linkPath: makeLink(t.id),
    isTag: type === 'tag',
    isEnable: isEnable(t),
  }))
}
