import {
  makeJoinAdministratorPath,
  makeJoinUserPath,
  makeLoginTeamPath,
  makeSettingPath,
  makeUserRootPath,
} from './link_path'

/**
 * パスにOriginを付与
 */
export const makePath2Url = (path: string): string =>
  window.location.origin + path

/**
 * チームのログインUrl
 * @returns `${ORIGIN}/login/:teamId`
 */
export const makeLoginTeamUrl = (teamId: string): string =>
  makePath2Url(makeLoginTeamPath(teamId))

/**
 * 管理者の招待URL
 * @returns `${ORIGIN}/join/admin/:inviteId`
 */
export const makeJoinAdministratorUrl = (inviteId: string): string =>
  makePath2Url(makeJoinAdministratorPath(inviteId))

/**
 * 会員の招待URL
 * @returns `${ORIGIN}/join/user/:inviteId`
 */
export const makeJoinUserUrl = (inviteId: string): string =>
  makePath2Url(makeJoinUserPath(inviteId))

/**
 * 会員側のRootUrl
 * @returns `${ORIGIN}/user/:thisTeamId`
 */
export const makeUserRootUrl = (teamId?: string): string =>
  makePath2Url(makeUserRootPath(teamId))

/**
 * 会員側のマイアカウントUrl
 * @returns `${ORIGIN}/user/:thisTeamId/setting`
 */
export const makeSettingUrl = (): string => makePath2Url(makeSettingPath())

/**
 * 動画詳細URL
 * @returns `${ORIGIN}/user/:teamId/video/:videoId`
 */
export const makeVideoUrl = (videoId: string, teamId: string): string =>
  makePath2Url(`${makeUserRootPath(teamId)}/video/${videoId}`)

/**
 * 指定のチームのカタログページの動画詳細ページのPath
 * @returns `${ORIGIN}/catalog/:teamId/video/:videoId`
 */
export const makeCatalogVideoUrl = (teamId: string, videoId: string): string =>
  makePath2Url(`/catalog/${teamId}/video/${videoId}`)

/**
 * プレイリスト詳細URL
 * @returns `${ORIGIN}/user/:teamId/playlist/:playlistId`
 */
export const makePlaylistUrl = (playlistId: string, teamId: string): string =>
  makePath2Url(`${makeUserRootPath(teamId)}/playlist/${playlistId}`)

/**
 * プレイリスト詳細URL
 * @returns `${ORIGIN}/user/:teamId/playlist/:playlistId`
 */
export const makecatalogPlaylistUrl = (
  playlistId: string,
  teamId: string
): string => makePath2Url(`/catalog/${teamId}/playlist/${playlistId}`)

/**
 * 配信詳細URL
 * @returns `${ORIGIN}/user/:teamId/live/:liveId`
 */
export const makeLiveUrl = (liveId: string, teamId: string): string =>
  makePath2Url(`${makeUserRootPath(teamId)}/live/${liveId}`)

/**
 * 指定のチームのカタログページの配信詳細ページのPath
 * @returns `${ORIGIN}/catalog/:teamId/live/:liveId`
 */
export const makeCatalogLiveUrl = (liveId: string, teamId: string): string =>
  makePath2Url(`/catalog/${teamId}/live/${liveId}`)

/**
 * イベント詳細URL
 * @returns `${ORIGIN}/user/:teamId/event/:eventId`
 */
export const makeEventUrl = (eventId: string, teamId: string): string =>
  makePath2Url(`${makeUserRootPath(teamId)}/event/${eventId}`)

/**
 * 指定のチームのカタログページのイベント詳細ページのPath
 * @returns `${ORIGIN}/catalog/:teamId/event/:eventId`
 */
export const makeCatalogEventUrl = (teamId: string, eventId: string): string =>
  makePath2Url(`/catalog/${teamId}/event/${eventId}`)
