import { ITest, ITestQuestion } from 'common/interfaces/test'
import { notOnKeyDownHyphen } from 'common/utils'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import AddCard from 'components/atoms/TestForm/AddCard'
import { QuestionCard } from 'components/atoms/TestForm/QuestionCard'
import React, { useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  AddQuestionInputType,
  InputType,
  initTestQuestions,
  onAddAddQuestionOption,
  onChangeAddQuestionOption,
  onDeleteAddQuestionOption,
  onAddItem as serviceOnAddItem,
  onChangeAddQuestionInput as serviceOnChangeAddQuestionInput,
  onChangeQuestionCorrectIndex as serviceOnChangeQuestionCorrectIndex,
  onChangeQuestionExplain as serviceOnChangeQuestionExplain,
  onChangeQuestionOption as serviceOnChangeQuestionOption,
  onChangeQuestionTitle as serviceOnChangeQuestionTitle,
  onDragEnd as serviceOnDragEnd,
  onRemoveItem as serviceOnRemoveItem,
} from 'services/admin/test_form'
import '../order-customize-style.scss'
import './formpartquestions.scss'

interface IFormPartQuestionsProps {
  test: ITest
  setTest: React.Dispatch<React.SetStateAction<ITest>>
  onChangeInput: (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement> | string
  ) => void
  showAddCardColumn: boolean
  isChangePassCorrectCount: boolean
}

const FormPartQuestions: React.FC<IFormPartQuestionsProps> = ({
  test,
  setTest,
  onChangeInput,
  showAddCardColumn,
  isChangePassCorrectCount,
}: IFormPartQuestionsProps) => {
  const { t } = useTranslation('adminTest')

  const [questions, setQuestions] = useState<ITestQuestion[]>(
    initTestQuestions()
  )

  const onChangeAddQuestionInput = (
    type: AddQuestionInputType,
    index: number,
    e: any
  ) => serviceOnChangeAddQuestionInput(questions, setQuestions, type, index, e)

  const onChangeAddOption = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    optionIndex: number
  ) =>
    onChangeAddQuestionOption(
      { questions, setQuestions },
      e,
      index,
      optionIndex
    )

  const onChangeQuestionTitle = (index: number, e: string) =>
    serviceOnChangeQuestionTitle({ test, setTest }, index, e)

  const onChangeQuestionCorrectIndex = (
    index: number,
    optionIndex: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) =>
    serviceOnChangeQuestionCorrectIndex(
      { test, setTest },
      index,
      optionIndex,
      e
    )

  const onChangeQuestionOption = (
    index: number,
    optionIndex: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeQuestionOption({ test, setTest }, index, optionIndex, e)

  const onChangeQuestionExplain = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeQuestionExplain({ test, setTest }, index, e)

  const onDeleteAddOption = (index: number, optionIndex: number) =>
    onDeleteAddQuestionOption({ questions, setQuestions }, index, optionIndex)

  const onAddAddOption = (index: number) =>
    onAddAddQuestionOption({ questions, setQuestions }, index)

  const onAddItem = (index: number) =>
    serviceOnAddItem(index, { test, setTest }, { questions, setQuestions })

  const onDragEnd = (result: DropResult) =>
    serviceOnDragEnd(result, { test, setTest })

  const onRemoveItem = (position: number) =>
    serviceOnRemoveItem(position, { test, setTest })

  return (
    <div className="order-customize-style">
      <FormGroup row className="form-part-questions">
        <Col md="10">
          <Label for="pass-correct-count">
            <InfoTooltip
              content={t('form.questions.passStandard.title')}
              tooltipContent={t('form.questions.passStandard.tips')}
              required
            />
          </Label>
          <div id="pass-correct-count" className="pass-correct-count">
            <span>
              {t('form.questions.questionLength', {
                questions: test.questions.length,
              })}
            </span>
            <Input
              className="pass-correct-count__input"
              type="number"
              defaultValue={test.pass_correct_count}
              onChange={(e) => onChangeInput(InputType.PASS_CORRECT_COUNT, e)}
              disabled={!isChangePassCorrectCount}
              min="0"
              onKeyDown={notOnKeyDownHyphen}
            />
            <span>{t('form.questions.correctCount')}</span>
          </div>
        </Col>
      </FormGroup>

      <Row>
        {showAddCardColumn && (
          <Col md="4" className="all-items">
            <div className="scroll-box">
              <Row>
                <AddCard
                  title={t('form.questions.card.title.radioQuestion')}
                  question={questions[0]}
                  onChangeQuestionInput={(type, e) =>
                    onChangeAddQuestionInput(type, 0, e)
                  }
                  onChangeOption={(e, index) => onChangeAddOption(e, 0, index)}
                  onAddOption={() => onAddAddOption(0)}
                  onDeleteOption={(index) => onDeleteAddOption(0, index)}
                  onAddItem={() => onAddItem(0)}
                />
                <AddCard
                  title={t('form.questions.card.title.checkboxQuestion')}
                  question={questions[1]}
                  onChangeQuestionInput={(type, e) =>
                    onChangeAddQuestionInput(type, 1, e)
                  }
                  onChangeOption={(e, index) => onChangeAddOption(e, 1, index)}
                  onAddOption={() => onAddAddOption(1)}
                  onDeleteOption={(index) => onDeleteAddOption(1, index)}
                  onAddItem={() => onAddItem(1)}
                />
              </Row>
            </div>
          </Col>
        )}
        <Col md={showAddCardColumn ? 8 : 12} className="selected-items">
          <div className="scroll-box">
            <h2>{t('form.questions.questionOrder')}</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {test.questions.map((question, index) => {
                      const id = question.type + index
                      return (
                        <Draggable key={id} draggableId={id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <QuestionCard
                                question={question}
                                index={index}
                                onRemove={onRemoveItem}
                                onChange={{
                                  title: (e) => onChangeQuestionTitle(index, e),
                                  optionChecked: (e, optionIndex) =>
                                    onChangeQuestionCorrectIndex(
                                      index,
                                      optionIndex,
                                      e
                                    ),
                                  optionName: (e, optionIndex) =>
                                    onChangeQuestionOption(
                                      index,
                                      optionIndex,
                                      e
                                    ),
                                  explain: (e) =>
                                    onChangeQuestionExplain(index, e),
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default FormPartQuestions
