import { LimitPlay } from 'common/enums'
import { IEventApplication } from 'common/interfaces/event_application'
import { LivePlatform } from 'common/interfaces/live'
import { isEventPage } from 'common/link'
import { makeUserRootPath } from 'common/link_path'
import LimitPlayContainer from 'components/atoms/LimitPlay/LimitPlayContainer'
import NextStep from 'components/atoms/NextStep/NextStep'
import QuillHtml from 'components/atoms/Quill/QuillHtml'
import {
  Filelists,
  makeGenreBadge,
  makeTagElements,
} from 'components/atoms/VideoDetail/ElementsOnVideoDetail'
import EventApplicationModal from 'components/molecules/User/VideoDetail/EventApplicationModal'
import VimeoLiveDetailBody from 'components/organisms/User/LiveDetail/VimeoLiveDetailBody'
import YTLiveDetailBody from 'components/organisms/User/LiveDetail/YTLiveDetailBody'
import ZoomLiveDetailBody from 'components/organisms/User/LiveDetail/ZoomLiveDetailBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import { Container } from 'reactstrap'
import {
  EventApplicationInputType,
  createEventApplication,
  initEventApplication,
  onChangeInputForEventApplication as serviceOnChangeInputForEventApplication,
} from 'services/user/event_application'
import { useLiveData } from 'services/user/live_detail'
import './livedetail.scss'

const LiveDetail: React.FC = () => {
  const { t } = useTranslation('userLive')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const { parentId, liveId } =
    useParams<{ parentId?: string; liveId: string }>()

  const [eventApplicationModal, setEventApplicationModal] =
    useState<boolean>(false)
  const [eventApplication, setEventApplication] = useState<IEventApplication>(
    initEventApplication(storeCache.user)
  )

  const isEvent = isEventPage()
  const { isCatalog, event, live, nextContent, limitPlay } = useLiveData(
    storeCache,
    isEvent,
    parentId,
    liveId
  )

  if (!live) {
    return (
      <div className="mt-5 text-center">
        <Trans
          t={t}
          i18nKey="liveDetail.notFoundLive"
          components={{
            topLink: <Link to={makeUserRootPath(storeCache.team?.id)} />,
          }}
        />
      </div>
    )
  }

  const onToggleEventApplicationModal = () =>
    setEventApplicationModal(!eventApplicationModal)

  const onChangeInputForEventApplication = (
    type: EventApplicationInputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) =>
    serviceOnChangeInputForEventApplication(type, e, {
      eventApplication,
      setEventApplication,
    })

  const onApplyToEvent = () =>
    createEventApplication(
      history,
      storeCache,
      isEvent,
      event,
      eventApplication,
      onToggleEventApplicationModal
    )

  const liveElement = (() => {
    if (
      ([
        LivePlatform.ZOOM_MEETING,
        LivePlatform.ZOOM_WEBINAR,
        LivePlatform.VIMEO_VIDEO,
        LivePlatform.VIMEO_LIVE,
        LivePlatform.VIMEO_WEBINAR,
      ].includes(live.platform) &&
        limitPlay !== LimitPlay.VISIBLE) ||
      (live.platform === LivePlatform.YOUTUBE &&
        [
          LimitPlay.UNAPPLIED,
          LimitPlay.OUTSIDE_APPLICATION_PERIOD,
          LimitPlay.BEFORE_EVENT,
          LimitPlay.AFTER_EVENT,
          LimitPlay.BEFORE,
          LimitPlay.AFTER,
        ].includes(limitPlay))
    ) {
      return (
        <LimitPlayContainer
          storeCache={storeCache}
          history={history}
          isCatalogPage={isCatalog}
          event={event}
          content={live}
          limitPlay={limitPlay}
          onToggle={onToggleEventApplicationModal}
        />
      )
    }
    switch (live.platform) {
      case LivePlatform.ZOOM_MEETING:
      case LivePlatform.ZOOM_WEBINAR:
        return <ZoomLiveDetailBody live={live} />
      case LivePlatform.YOUTUBE:
        return <YTLiveDetailBody live={live} />
      case LivePlatform.VIMEO_VIDEO:
      case LivePlatform.VIMEO_LIVE:
      case LivePlatform.VIMEO_WEBINAR:
        return <VimeoLiveDetailBody live={live} />
    }
  })()

  const isShowFilelist = [
    LimitPlay.BEFORE,
    LimitPlay.AFTER,
    LimitPlay.BEFORE_EVENT,
    LimitPlay.AFTER_EVENT,
    LimitPlay.VISIBLE,
  ].includes(limitPlay)

  return (
    <div className="user-live-detail">
      <div className="live-video-container">{liveElement}</div>

      <Container>
        {makeGenreBadge(storeCache, live.category_id)}
        <h1 className="live-title">{live.name}</h1>
        <div className="overview">
          <QuillHtml html={live.overview} />
          {makeTagElements(storeCache, live.tag_ids)}
          {isShowFilelist && <Filelists obj={live} />}
          <NextStep parentId={parentId} nextContent={nextContent} />
        </div>
      </Container>
      <EventApplicationModal
        isEvent={isEvent}
        event={event}
        showModal={eventApplicationModal}
        onToggleModal={onToggleEventApplicationModal}
        onChange={onChangeInputForEventApplication}
        onApplyToEvent={onApplyToEvent}
      />
    </div>
  )
}

export default LiveDetail
