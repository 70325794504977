import {
  CouponDiscountType,
  CouponDurationType,
  CurrencyType,
} from 'common/enums'
import { DiscountType, ICoupon } from 'common/interfaces/stripe/coupon'
import { IProduct } from 'common/interfaces/stripe/product'
import { formatCouponInterval, notOnKeyDownHyphen } from 'common/utils'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import {
  InputType,
  getCouponDefaults,
  getFormOptions,
  saveStripeCoupon,
  onChangeInput as serviceOnChangeInput,
} from 'services/admin/coupon_form'
import { getStripeProducts } from 'services/admin/stripe/product'

type TFormBody = {
  couponObj: ICoupon
  isCreate: boolean
}

const FormBody: React.FC<TFormBody> = (props) => {
  const { t } = useTranslation('adminStripeCoupon')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [coupon, setCoupon] = useState<ICoupon>(props.couponObj)
  const [products, setProducts] = useState<IProduct[]>([])
  const [type, setType] = useState<DiscountType>(CouponDiscountType.PERCENT)

  useEffect(() => {
    getStripeProducts(storeCache, setProducts)
  }, [storeCache])

  const { defaultProducts } = getCouponDefaults(coupon, products)
  const { productOptions } = getFormOptions(products)

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => serviceOnChangeInput(coupon, setCoupon, type, e)

  const save = () => saveStripeCoupon(history, coupon, storeCache, type)

  return (
    <Form>
      <FormGroup row>
        <Col md="10">
          <Label>{t('form.id.title')}</Label>
          <Input
            type="text"
            defaultValue={coupon.id}
            placeholder={t('form.id.tips')}
            disabled
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.name.title')}
              tooltipContent={t('form.name.tips')}
              required
            />
          </Label>
          <Input
            onChange={(e) => onChangeInput(InputType.NAME, e)}
            type="text"
            defaultValue={coupon.name}
          />
        </Col>
      </FormGroup>
      <FormGroup row check>
        <Row>
          <Col md="10">
            <Label>
              <InfoTooltip
                content={t('form.type.title')}
                tooltipContent={t('form.type.tips')}
              />
            </Label>
          </Col>
        </Row>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              onChange={() => setType(CouponDiscountType.PERCENT)}
              checked={type === CouponDiscountType.PERCENT}
            />
            {t('form.type.percent')}
          </Label>
        </FormGroup>
        <FormGroup check className="mt-2">
          <Label check>
            <Input
              type="radio"
              onChange={() => setType(CouponDiscountType.AMOUNT)}
              checked={type === CouponDiscountType.AMOUNT}
            />
            {t('form.type.amount')}
          </Label>
        </FormGroup>
      </FormGroup>

      <div className="my-4">
        <DiscountForm
          type={type}
          coupon={coupon}
          onChangeInput={onChangeInput}
        />
      </div>

      <FormGroup row>
        <Col md="4">
          <Label>
            <InfoTooltip
              content={t('form.duration.title')}
              tooltipContent={t('form.duration.tips')}
            />
          </Label>
          <Input
            onChange={(e) => onChangeInput(InputType.DURATION, e)}
            type="select"
            defaultValue={coupon.duration}
          >
            <option value={CouponDurationType.FOREVER}>
              {formatCouponInterval(CouponDurationType.FOREVER)}
            </option>
            <option value={CouponDurationType.ONCE}>
              {formatCouponInterval(CouponDurationType.ONCE)}
            </option>
            <option value={CouponDurationType.REPEATING}>
              {formatCouponInterval(CouponDurationType.REPEATING)}
            </option>
          </Input>
        </Col>
        {coupon.duration === CouponDurationType.REPEATING && (
          <Col md="4">
            <Label>
              <InfoTooltip
                content={t('form.numberOfMonths.title')}
                tooltipContent={t('form.numberOfMonths.tips')}
              />
            </Label>
            <Input
              onChange={(e) => onChangeInput(InputType.DURATION_IN_MONTHS, e)}
              type="number"
              defaultValue={coupon.duration_in_months}
              min="0"
              onKeyDown={notOnKeyDownHyphen}
            />
          </Col>
        )}
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.redeemBy.title')}
              tooltipContent={t('form.redeemBy.tips')}
            />
          </Label>
          <Input
            onChange={(e) => onChangeInput(InputType.REDEEM_BY, e)}
            type="date"
            defaultValue={coupon.redeem_by ?? undefined}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.products.title')}
              tooltipContent={t('form.products.tips')}
            />
          </Label>
          <Select
            value={defaultProducts}
            defaultValue={defaultProducts}
            onChange={(e) => onChangeInput(InputType.PRODUCT_IDS, e)}
            options={productOptions}
            closeMenuOnSelect={false}
            isMulti
            isDisabled={!props.isCreate}
            placeholder={t('form.products.placeholder')}
          />
        </Col>
      </FormGroup>
      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {t('form.save')}
        </Button>
      </div>
    </Form>
  )
}

interface IDiscountFormProps {
  type: DiscountType
  coupon: ICoupon
  onChangeInput: (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
}

const DiscountForm: React.FC<IDiscountFormProps> = (props) => {
  const { t } = useTranslation('adminStripeCoupon')

  if (props.type === CouponDiscountType.PERCENT) {
    return (
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.percentOff.title')}
              tooltipContent={t('form.percentOff.tips')}
            />
          </Label>
          <InputGroup>
            <Input
              onChange={(e) => props.onChangeInput(InputType.PERCENT_OFF, e)}
              type="number"
              value={props.coupon.percent_off ?? 0}
              min="0"
              onKeyDown={notOnKeyDownHyphen}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>%</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </FormGroup>
    )
  }

  return (
    <>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.amountOff.title')}
              tooltipContent={t('form.amountOff.tips')}
            />
          </Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                {props.coupon.currency === CurrencyType.JPY ? '￥' : '$'}
              </InputGroupText>
            </InputGroupAddon>
            <Input
              onChange={(e) => props.onChangeInput(InputType.AMOUNT_OFF, e)}
              type="number"
              value={props.coupon.amount_off ?? 0}
              min="0"
              onKeyDown={notOnKeyDownHyphen}
            />
          </InputGroup>
        </Col>
      </FormGroup>
      <FormGroup row check>
        <Row>
          <Col md="10">
            <Label>
              <InfoTooltip
                content={t('form.currency.title')}
                tooltipContent={t('form.currency.tips')}
              />
            </Label>
          </Col>
        </Row>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              onChange={(e) => props.onChangeInput(InputType.CURRENCY, e)}
              checked={props.coupon.currency === CurrencyType.JPY}
              value={CurrencyType.JPY}
            />
            {t('form.currency.jpy')}
          </Label>
        </FormGroup>
        <FormGroup check className="mt-2">
          <Label check>
            <Input
              type="radio"
              onChange={(e) => props.onChangeInput(InputType.CURRENCY, e)}
              checked={props.coupon.currency === CurrencyType.USD}
              value={CurrencyType.USD}
            />
            {t('form.currency.usd')}
          </Label>
        </FormGroup>
      </FormGroup>
    </>
  )
}

export default FormBody
