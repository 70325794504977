export interface IHeaderItemOrder {
  type: HeaderItemOrderType
}

export enum HeaderItemOrderType {
  DIVIDER = 'divider',
  MY_ACCOUNT = 'my_account',
  USER_VIEW_LOGS = 'user_view_histories',
  USER_BOOKMARKS = 'user_bookmarks',
  USER_WATCH_LATER = 'user_watch_later',
  USER_PURCHASEDS = 'user_purchaseds',
  LOGOUT = 'logout',
  CATEGORIES = 'categories',
  TAGS = 'tags',
  PLAYLISTS = 'playlists',
  CREATORS = 'creators',
}
