import { ColumnDef, Row } from '@tanstack/react-table'
import Encoding from 'encoding-japanese'

/**
 * Create CSV blob only data and none double quotation
 */
export const createCsvBlobOnlyDataAndNoneDoubleQuote = (
  data: any[]
): string => {
  const body = data
    .map((row: any) =>
      row.map((field: any) => (field === undefined ? '' : field)).join(',')
    )
    .join('\n')
  return createObjectURL(body)
}

/**
 * Create CSV blob data
 */
export const createCsvBlobFromColumnDef = <T>(
  columns: ColumnDef<T>[],
  rows: Row<any>[]
): string => {
  const escape = (str: unknown) => `"${String(str).replace(/"/g, '""')}"`
  const csvColumns = columns.filter((c) => c.meta?.csvExport !== false)

  const header = csvColumns.map((c) => escape(c.header as string)).join(',')
  const body = rows
    .map((row) =>
      csvColumns
        .map((column) => {
          const cell = row.getAllCells().find((c) => {
            if (c.column.columnDef.id !== undefined) {
              return c.column.columnDef.id === column.id
            }
            return c.column.columnDef.header === column.header
          })
          const value = cell?.getValue<string | string[]>() ?? ''
          return Array.isArray(value) ? escape(value.join(', ')) : escape(value)
        })
        .join(',')
    )
    .join('\n')

  return createObjectURL(`${header}\n${body}`)
}

/**
 * create blob url csv
 */
const createObjectURL = (data: string): string => {
  const strArray = Encoding.stringToCode(data)
  const sjisArray = Encoding.convert(strArray, 'SJIS', 'UNICODE')
  const uint8Array = new Uint8Array(sjisArray)
  return URL.createObjectURL(new Blob([uint8Array], { type: 'text/csv' }))
}
