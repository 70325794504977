import { INews } from 'common/interfaces/news'
import { standardModules } from 'common/quill'
import QuillForm from 'components/atoms/Input/QuillForm'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  InputType,
  getFormOptions,
  saveNews,
  onChangeInput as serviceOnChangeInput,
} from 'services/admin/news_form'

interface IFormBodyProps {
  isCreate: boolean
  newsObj: INews
}

const FormBody: React.FC<IFormBodyProps> = (props) => {
  const adminNews = useTranslation('adminNews')
  const adminCommon = useTranslation('adminCommon')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [news, setNews] = useState<INews>(props.newsObj)
  const [sendMail, setSendMail] = useState<boolean>(false)

  const { groupOptions } = getFormOptions(storeCache)

  const onChangeInput = (type: InputType, e: any) =>
    serviceOnChangeInput(news, setNews, type, e)

  const save = () =>
    saveNews(history, news, sendMail, storeCache, props.isCreate)

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="newsTitleForm">
              <InfoTooltip
                content={adminNews.t('form.newsTitle.title')}
                tooltipContent={adminNews.t('form.newsTitle.tips')}
                required
              />
            </Label>
            <Input
              id="newsTitleForm"
              onChange={(e) => onChangeInput(InputType.TITLE, e)}
              type="text"
              defaultValue={news.title}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label>
              <InfoTooltip
                content={adminNews.t('form.newsContent.title')}
                tooltipContent={adminNews.t('form.newsContent.tips')}
              />
            </Label>
            <pre>
              <QuillForm
                onChange={(e) => onChangeInput(InputType.CONTENT, e)}
                modules={standardModules()}
                defaultValue={news.content}
              />
            </pre>
          </FormGroup>
        </Col>
      </Row>
      {props.isCreate && (
        <>
          <Row>
            <Col md="8">
              <FormGroup>
                <Label for="newsGroupForm">
                  <InfoTooltip
                    content={adminNews.t('form.newsGroup.title')}
                    tooltipContent={adminNews.t('form.newsGroup.tips')}
                  />
                  <div style={{ fontSize: '0.8rem' }}>
                    {adminNews.t('form.newsGroup.tips2')}
                  </div>
                </Label>
                <Select
                  id="newsGroupForm"
                  placeholder={adminNews.t('form.newsGroup.allGroup')}
                  onChange={(e) => onChangeInput(InputType.GROUP_IDS, e)}
                  options={groupOptions}
                  closeMenuOnSelect={false}
                  isMulti
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check inline>
                <Label check>
                  <Input
                    onChange={() => setSendMail(!sendMail)}
                    type="checkbox"
                  />
                  {adminNews.t('form.newsSubmitMail')}
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {adminCommon.t('save')}
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
