import { makeUserRootPath } from 'common/link_path'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { NavbarBrand } from 'reactstrap'

const Logo: React.FC = () => {
  const { team } = useContext(AuthContext).storeCache
  if (!team) return null

  const userUrl = makeUserRootPath(team.id)

  return team.logo ? (
    <NavbarBrand tag={Link} to={userUrl}>
      <img src={team.logo.path} alt={team.name} />
    </NavbarBrand>
  ) : (
    <Link className="team-name" to={userUrl}>
      {team.name}
    </Link>
  )
}

export default Logo
