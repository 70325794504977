import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IFilelist } from 'common/interfaces/filelist'
import { IStripe } from 'common/interfaces/stripe'
import { nowTimestamp } from 'common/times'
import {
  getAllFilelistSize,
  getFileSize,
  getFilelistUploadLimit,
} from 'common/utils'
import { History } from 'history'
import { i18nAdminFilelist, i18nAlert } from 'i18n/i18n'
import { reloadCachedFilelists } from 'providers/AuthProvider'
import { upload } from 'repositories/storage/firebase_filelist'
import { store } from 'repositories/store/firebase_filelist'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/filelist_create'
import { v4 as uuidv4 } from 'uuid'

/**
 * Dropzoneのスタイルを返却する
 */
export const dropzoneStyle = {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  borderNormalStyle: {
    border: '2px dotted #888',
  },
  borderDragStyle: {
    border: '2px solid #00f',
    transition: 'border .5s ease-in-out',
  },
}

/**
 * PDFファイル作成処理
 */
export const createPDFFile = async (
  history: History,
  file: File | undefined,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  storeCache: IStoreCache,
  stripe: IStripe
): Promise<void> => {
  try {
    validateForm(file, storeCache, stripe)
    setIsLoading(true)

    const filelist: IFilelist = {
      id: uuidv4(),
      name: file!.name,
      size: file!.size,
      path: '',
      created_at: nowTimestamp(),
    }
    filelist.path = await upload(storeCache.team!, filelist, file)

    await store(storeCache.team!, filelist)
    await reloadCachedFilelists(storeCache)

    alertService.show(true, i18nAlert('created.file'))
    history.push(Routes.AdminFilelist)
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
    setIsLoading(false)
  }
}

/**
 * 容量オーバーのファイルサイズ文言を返却する
 */
export const filelistSizeOverText = (
  { subscriptionObj, filelists }: IStoreCache,
  stripe: IStripe
) => {
  const limit = getFileSize(getFilelistUploadLimit(subscriptionObj, stripe))
  const use = getFileSize(getAllFilelistSize(filelists))
  return i18nAdminFilelist('form.sizeOver.limit', { limit, use })
}
