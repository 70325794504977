import Footer from 'components/molecules/Footer'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from 'reactstrap'
import { loginSaml } from 'services/team/login'

const FormBody: React.FC = () => {
  const { t } = useTranslation('common')
  const history = useHistory()

  const [samlProviderId, setSamlProviderId] = useState<string>('')

  const login = () => loginSaml(history, samlProviderId)

  return (
    <div className="main-content">
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        onChange={(e) => setSamlProviderId(e.target.value)}
                        placeholder={t('login.form.samlProviderId')}
                        type="text"
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center mt-3">
                    <Button color="primary" onClick={login}>
                      {t('login.form.saml')}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  )
}

export default FormBody
