import { ColumnDef } from '@tanstack/react-table'
import {
  ILogAllUserVideoCount,
  ILogUserVideoCount,
} from 'common/interfaces/analytics'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ILog, LogType } from 'common/interfaces/log'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { IVideo } from 'common/interfaces/video'
import { isAuthMethodEmail, isLoggedIn, userUid } from 'common/utils'
import { flattedLog } from 'common/utils_log'
import { customFieldColumnDef } from 'components/atoms/Table/CreateTable'
import { i18nAdminAnalytics } from 'i18n/i18n'
import { getAll as getAllLogs } from 'repositories/store/firebase_log'
import { getNewer } from 'repositories/store/firebase_user'

/**
 * すべてのユーザーを取得する
 */
export const getUsers = async (
  storeCache: IStoreCache,
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>
) => {
  if (!isLoggedIn(storeCache)) return

  const users = await getNewer(storeCache.team!)
  setUsers(users)
}

/**
 * すべてのユーザーのログを取得する
 */
export const getAllUserLogs = async (
  storeCache: IStoreCache,
  setAllUserLogs: React.Dispatch<React.SetStateAction<ILog[]>>
) => {
  if (!isLoggedIn(storeCache)) return

  const logs = await getAllLogs(storeCache.team!)
  setAllUserLogs(logs)
}

const baseTableColumn = () => {
  const i18n = i18nAdminAnalytics('logType', { returnObjects: true })
  return [
    {
      header: i18nAdminAnalytics('videoTable.videoTitle'),
      accessorKey: 'video_name',
    },
    {
      header: i18n.videoPageOpen,
      accessorKey: 'page_open_count',
    },
    {
      header: i18n.videoPlay,
      accessorKey: 'video_play_count',
    },
    {
      header: i18n.videoNotPlayRate,
      accessorKey: 'not_play_percent',
    },
    {
      header: i18n.videoEnded,
      accessorKey: 'video_ended_count',
    },
    {
      header: i18n.videoEndedRate,
      accessorKey: 'video_ended_percent',
    },
  ]
}

/**
 * `ILogUserVideoCount`のカラム
 */
export const logUserVideoCountColumns = (): ColumnDef<ILogUserVideoCount>[] =>
  baseTableColumn()

/**
 * 特定ユーザーの`ILog`から`ILogUserVideoCount[]`を算出する
 */
export const userLog2LogUserVideoCounts = (
  videos: IVideo[],
  log: ILog
): ILogUserVideoCount[] => {
  const flatted = flattedLog(log)
  const videosInLog = flatted
    .filter((f) => videos.find((v) => v.id === f.video_id))
    .map((f) => videos.find((v) => v.id === f.video_id)!)
    .filter((elem, index, self) => self.indexOf(elem) === index)

  const result: ILogUserVideoCount[] = videosInLog.map((video) => ({
    video_id: video.id,
    video_name: video.name,
    page_open_count: 0,
    video_play_count: 0,
    not_play_percent: '-',
    video_ended_count: 0,
    video_ended_percent: '-',
  }))

  flatted.forEach((f) => {
    const obj = result.find((r) => r.video_id === f.video_id)
    if (!obj) return

    switch (f.type) {
      case LogType.VIDEO_PAGE_OPEN:
        obj.page_open_count += 1
        break
      case LogType.VIDEO_PLAY:
        obj.video_play_count += 1
        break
      case LogType.VIDEO_ENDED:
        obj.video_ended_count += 1
        break
    }
  })

  result.forEach((luvc) => {
    if (luvc.page_open_count > 0 || luvc.video_play_count > 0) {
      const percent = (1 - luvc.video_play_count / luvc.page_open_count) * 100
      luvc.not_play_percent = `${Math.round(percent)}%`
    }
    if (luvc.video_play_count > 0 || luvc.video_ended_count > 0) {
      const videoEndedPercent =
        (luvc.video_ended_count / luvc.video_play_count) * 100
      luvc.video_ended_percent = `${Math.round(videoEndedPercent)}%`
    }
  })
  return result.sort((a, b) => (a.video_name > b.video_name ? 1 : -1))
}

/**
 * 全ユーザーの視聴集計を出力するCSVのカラム
 */
export const getAllUserVideoCountColumns = (
  allUsers: IUser[],
  team: ITeam
): ColumnDef<ILogAllUserVideoCount>[] => {
  const i18n = i18nAdminAnalytics('userTable', { returnObjects: true })
  const isAuthEmail = isAuthMethodEmail(team)
  return [
    {
      header: 'ID',
      accessorKey: 'user_id',
    },
    {
      header: i18n.email,
      accessorKey: 'user_email',
      meta: { csvExport: isAuthEmail },
    },
    {
      header: i18n.name,
      accessorKey: 'user_name',
    },
    ...customFieldColumnDef<ILogAllUserVideoCount>(team, allUsers),
    ...baseTableColumn(),
  ]
}

/**
 * 全ユーザーの`ILog[]`から`ILogUserVideoCount[]`を算出する
 */
export const allUserLogs2LogAllUserVideoCounts = (
  videos: IVideo[],
  allUsers: IUser[],
  allUserLogs: ILog[],
  isAuthEmail: boolean
): ILogAllUserVideoCount[] => {
  return allUsers
    .filter((u) => !u.admin)
    .flatMap((u) => {
      const userLogs = allUserLogs.find((l) => l.user_id === u.id)
      if (!userLogs) return []

      const userVideoCountLog = userLog2LogUserVideoCounts(videos, userLogs)
      return userVideoCountLog.map((l) => ({
        user_id: isAuthEmail ? u.id : userUid(u.id),
        user_email: u.email,
        user_name: u.name ?? '',
        ...l,
      }))
    })
}
