import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ITest, ITestQuestion, ITestQuestionType } from 'common/interfaces/test'
import { nowTimestamp } from 'common/times'
import { checkFirebaseError, emptyStr2Null, isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import React from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { store, update } from 'repositories/store/firebase_test'
import { get as getTestAnswers } from 'repositories/store/firebase_test_answer'
import { alertService } from 'services/alert'
import { v4 as uuidv4 } from 'uuid'

/**
 * `ITest`の初期値を返却する
 */
export const initTest = (): ITest => {
  return {
    id: uuidv4(),
    name: '',
    questions: [],
    pass_correct_count: 0,
    retest: {
      is_enable: false,
      is_stop_when_passed: false,
      limit_count: 0,
    },
    passed: {
      button_text: null,
      button_url: null,
      note: '',
    },
    failed: {
      is_show_answer: false,
      is_show_explain: false,
    },
    created_at: nowTimestamp(),
    updated_at: nowTimestamp(),
  }
}

const initTestQuestion = (type: ITestQuestionType) => ({
  type,
  name: '',
  options: [],
  correct_index: type === ITestQuestionType.RADIO ? -1 : [],
  explain: '',
})

/**
 * `ITestQuestion`の初期値を返却する
 */
export const initTestQuestions = (): ITestQuestion[] => [
  initTestQuestion(ITestQuestionType.RADIO),
  initTestQuestion(ITestQuestionType.CHECKBOX),
]

/**
 * テストの設問などすべてを編集できるか
 */
export const isTestEditAll = async (
  storeCache: IStoreCache,
  test: ITest,
  setTestEditAll: React.Dispatch<React.SetStateAction<boolean>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  setLoading(true)
  const answers = await getTestAnswers(storeCache.team!, test)
  const isEditAll = answers.length <= 0
  setTestEditAll(isEditAll)
  setLoading(false)
}

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  TITLE,
  RETEST_ENABLE,
  RETEST_STOP_WHEN_PASSED,
  RETEST_LIMIT_COUNT,
  PASSED_BUTTON_TEXT,
  PASSED_BUTTON_URL,
  PASSED_NOTE,
  FAILED_SHOW_ANSWER,
  FAILED_SHOW_EXPLAIN,
  PASS_CORRECT_COUNT,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  test: ITest,
  setTest: React.Dispatch<React.SetStateAction<ITest>>,
  type: InputType,
  e: any
) => {
  const value = e.target?.value
  const checked = e.target?.checked

  setTest({
    ...test,
    name: type === InputType.TITLE ? value : test.name,
    retest: {
      ...test.retest,
      is_enable:
        type === InputType.RETEST_ENABLE ? checked : test.retest.is_enable,
      is_stop_when_passed:
        type === InputType.RETEST_ENABLE
          ? false
          : type === InputType.RETEST_STOP_WHEN_PASSED
          ? checked
          : test.retest.is_stop_when_passed,
      limit_count:
        type === InputType.RETEST_ENABLE
          ? 0
          : type === InputType.RETEST_LIMIT_COUNT
          ? Number(value)
          : test.retest.limit_count,
    },
    passed: {
      ...test.passed,
      button_text:
        type === InputType.PASSED_BUTTON_TEXT
          ? emptyStr2Null(value)
          : test.passed.button_text,
      button_url:
        type === InputType.PASSED_BUTTON_URL
          ? emptyStr2Null(value)
          : test.passed.button_url,
      note: type === InputType.PASSED_NOTE ? e : test.passed.note,
    },
    failed: {
      ...test.failed,
      is_show_answer:
        type === InputType.FAILED_SHOW_ANSWER
          ? checked
          : test.failed.is_show_answer,
      is_show_explain:
        type === InputType.FAILED_SHOW_EXPLAIN
          ? checked
          : test.failed.is_show_explain,
    },
    pass_correct_count:
      type === InputType.PASS_CORRECT_COUNT
        ? Number(value)
        : test.pass_correct_count,
  })
}

/**
 * `onChangeAddQuestionInput`で使用する入力タイプ
 */
export enum AddQuestionInputType {
  TITLE,
  CORRECT_INDEX,
  EXPLAIN,
}

/**
 * 設問追加の各入力欄の`onChange`イベントハンドラ
 */
export const onChangeAddQuestionInput = (
  questions: ITestQuestion[],
  setQuestions: React.Dispatch<React.SetStateAction<ITestQuestion[]>>,
  type: AddQuestionInputType,
  index: number,
  e: any
) => {
  const value = e.target?.value
  questions.splice(index, 1, {
    name: type === AddQuestionInputType.TITLE ? e : questions[index].name,
    correct_index:
      type === AddQuestionInputType.CORRECT_INDEX
        ? questions[index].type === ITestQuestionType.RADIO
          ? Number(value)
          : e.map((e: any) => e.value)
        : questions[index].correct_index,
    explain:
      type === AddQuestionInputType.EXPLAIN ? value : questions[index].explain,
    options: questions[index].options,
    type: questions[index].type,
  })
  setQuestions([...questions])
}

/**
 * 設問追加の選択肢のオプション入力欄の`onChange`イベントハンドラ
 */
export const onChangeAddQuestionOption = (
  questionsState: {
    questions: ITestQuestion[]
    setQuestions: React.Dispatch<React.SetStateAction<ITestQuestion[]>>
  },
  { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  index: number,
  optionIndex: number
) => {
  questionsState.questions[index].options.splice(optionIndex, 1, value)
  questionsState.setQuestions([...questionsState.questions])
}

/**
 * 設問追加の選択肢のオプション削除処理
 */
export const onDeleteAddQuestionOption = (
  questionsState: {
    questions: ITestQuestion[]
    setQuestions: React.Dispatch<React.SetStateAction<ITestQuestion[]>>
  },
  index: number,
  optionIndex: number
) => {
  questionsState.questions[index].options.splice(optionIndex, 1)
  questionsState.setQuestions([...questionsState.questions])
}

/**
 * 設問追加の選択肢のオプション追加処理
 */
export const onAddAddQuestionOption = (
  questionsState: {
    questions: ITestQuestion[]
    setQuestions: React.Dispatch<React.SetStateAction<ITestQuestion[]>>
  },
  index: number
) => {
  questionsState.questions[index].options.push('')
  questionsState.setQuestions([...questionsState.questions])
}

/**
 * 設問順のタイトルの`onChange`イベントハンドラ
 */
export const onChangeQuestionTitle = (
  testStates: {
    test: ITest
    setTest: React.Dispatch<React.SetStateAction<ITest>>
  },
  position: number,
  e: string
): void => {
  testStates.test.questions[position].name = e
  testStates.setTest({ ...testStates.test })
}

/**
 * 設問順の正答の`onChange`イベントハンドラ
 */
export const onChangeQuestionCorrectIndex = (
  testStates: {
    test: ITest
    setTest: React.Dispatch<React.SetStateAction<ITest>>
  },
  index: number,
  optionIndex: number,
  e: React.ChangeEvent<HTMLInputElement>
): void => {
  const thisQuestion = testStates.test.questions[index]
  const isRadio = thisQuestion.type === ITestQuestionType.RADIO

  const isChecked = e.target.checked
  if (isRadio) {
    thisQuestion.correct_index = optionIndex
  } else {
    const correctIndex = thisQuestion.correct_index as number[]
    if (isChecked && !correctIndex.includes(optionIndex)) {
      correctIndex.push(optionIndex)
    } else if (!isChecked && correctIndex.includes(optionIndex)) {
      correctIndex.splice(correctIndex.indexOf(optionIndex), 1)
    }
  }

  testStates.setTest({ ...testStates.test })
}

/**
 * 設問順の選択肢の`onChange`イベントハンドラ
 */
export const onChangeQuestionOption = (
  testStates: {
    test: ITest
    setTest: React.Dispatch<React.SetStateAction<ITest>>
  },
  index: number,
  optionIndex: number,
  e: React.ChangeEvent<HTMLInputElement>
): void => {
  testStates.test.questions[index].options[optionIndex] = e.target.value
  testStates.setTest({ ...testStates.test })
}

/**
 * 設問順の解説の`onChange`イベントハンドラ
 */
export const onChangeQuestionExplain = (
  testStates: {
    test: ITest
    setTest: React.Dispatch<React.SetStateAction<ITest>>
  },
  index: number,
  e: React.ChangeEvent<HTMLInputElement>
): void => {
  testStates.test.questions[index].explain = e.target.value
  testStates.setTest({ ...testStates.test })
}

const questionValidation = (question: ITestQuestion) => {
  if (!question.name) {
    throw new Error(i18nValidation('empty.questionName'))
  }
  if (question.options.filter((item) => item.trim() === '').length > 0) {
    throw new Error(i18nValidation('empty.options'))
  }
  if (
    question.options.filter((item, i) => question.options.indexOf(item) !== i)
      .length > 0
  ) {
    throw new Error(i18nValidation('duplication.options'))
  }
  if (
    (question.type === ITestQuestionType.RADIO &&
      (question.correct_index as number) < 0) ||
    (question.type === ITestQuestionType.CHECKBOX &&
      (question.correct_index as number[]).length === 0)
  ) {
    throw new Error(i18nValidation('notSelected.correct'))
  }
}

/**
 * 設問の追加ボタンを押下した際
 */
export const onAddItem = (
  index: number,
  testStates: {
    test: ITest
    setTest: React.Dispatch<React.SetStateAction<ITest>>
  },
  {
    questions,
    setQuestions,
  }: {
    questions: ITestQuestion[]
    setQuestions: React.Dispatch<React.SetStateAction<ITestQuestion[]>>
  }
) => {
  const question = questions[index]

  try {
    questionValidation(question)
  } catch (error: any) {
    alertService.show(false, error.message)
    return
  }

  testStates.test.questions.push(question)
  testStates.setTest({ ...testStates.test })

  questions.splice(index, 1, initTestQuestions()[index])
  setQuestions([...questions])
}

/**
 * 設問順のxボタンを押下した際
 */
export const onRemoveItem = (
  position: number,
  testStates: {
    test: ITest
    setTest: React.Dispatch<React.SetStateAction<ITest>>
  }
) => {
  testStates.test.questions.splice(position, 1)
  testStates.setTest({ ...testStates.test })
}

/**
 * 設問順のドラッグが終了した際
 */
export const onDragEnd = (
  { destination, source }: DropResult,
  testStates: {
    test: ITest
    setTest: React.Dispatch<React.SetStateAction<ITest>>
  }
) => {
  if (!destination) return

  const questions = (() => {
    const data = Array.from(testStates.test.questions)
    const [removed] = data.splice(source.index, 1)
    data.splice(destination.index, 0, removed)
    return data
  })()
  testStates.setTest({ ...testStates.test, questions })
}

/**
 * テストレコード作成/更新処理
 */
export const saveTest = async (
  isCreate: boolean,
  { push }: History,
  test: ITest,
  storeCache: IStoreCache
) => {
  if (!isLoggedIn(storeCache)) return

  try {
    if (!test.name) {
      throw new Error(i18nValidation('empty.testName'))
    }
    if (test.questions.length <= 0) {
      throw new Error(i18nValidation('empty.question'))
    }
    if (test.pass_correct_count <= 0) {
      throw new Error(i18nValidation('empty.correctCount'))
    }
    if (test.questions.length < test.pass_correct_count) {
      throw new Error(i18nValidation('invalid.correctCount'))
    }
    test.questions.forEach((question) => {
      questionValidation(question)
    })

    test.updated_at = nowTimestamp()
    if (isCreate) {
      test.created_at = nowTimestamp()
      await store(storeCache.team!, test)
      alertService.show(true, i18nAlert('created.test'))
    } else {
      await update(storeCache.team!, test)
      alertService.show(true, i18nAlert('updated.test'))
    }

    push(Routes.AdminTest)
  } catch (error) {
    const message = checkFirebaseError(error)
    alertService.show(false, message)
    console.log(error)
  }
}
