import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { IStripe } from 'common/interfaces/stripe'
import { goBackWithErrorAlert, isBasicPrice, isProPrice } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeCustomOperationButtons,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'
import {
  ITestData,
  getTestUseCount,
  getTestsWithAnswerCount,
  removeTest,
} from 'services/admin/test'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminTest')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const [testDatas, setTestDatas] = useState<ITestData[]>([])
  const [stripe, setStripe] = useState<IStripe>(initStripe())
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await Promise.all([
        getStripe(setStripe),
        getTestsWithAnswerCount(storeCache, setTestDatas),
      ])
      setIsLoading(false)
    })()
  }, [storeCache])

  if (isLoading) return <LoadingSpinner />
  if (
    isBasicPrice(storeCache.subscriptionObj, stripe) ||
    isProPrice(storeCache.subscriptionObj, stripe)
  ) {
    goBackWithErrorAlert(history, i18nAlert('plans.pro.under.notUseFeature'))
    return null
  }
  if (testDatas.length <= 0) {
    return <RegisterItemButton type="test" />
  }
  return (
    <>
      <ButtonRight nextPage={Routes.AdminTestCreate} content={t('list.new')} />
      <Table testDatas={testDatas} setTestDatas={setTestDatas} />
    </>
  )
}

const Table: React.FC<{
  testDatas: ITestData[]
  setTestDatas: React.Dispatch<React.SetStateAction<ITestData[]>>
}> = ({ testDatas, setTestDatas }) => {
  const { t } = useTranslation('adminTest')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const columns = useMemo<ColumnDef<ITestData>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => testDatas.length - i,
      },
      {
        header: t('list.table.title'),
        accessorKey: 'test.name',
      },
      {
        header: t('list.table.usingCount'),
        accessorFn: (e) =>
          t('list.usingCount', {
            count: getTestUseCount(e.test, storeCache.videos),
          }),
      },
      {
        header: t('list.table.answerCount'),
        accessorFn: (e) => t('list.answerCount', { count: e.answerCount }),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (e) =>
          makeCustomOperationButtons([
            makeCustomOperationButton(t('list.answerList'), () =>
              history.push({
                pathname: Routes.AdminTestAnswer,
                state: { test: e.test },
              })
            ),
            makeCustomOperationButton(t('list.edit'), () => {
              history.push({
                pathname: Routes.AdminTestEdit,
                state: { test: e.test },
              })
            }),
            makeCustomOperationButton(t('list.delete'), () =>
              removeTest(storeCache, e.test, setTestDatas)
            ),
          ]),
      },
    ],
    [storeCache, history, testDatas.length, setTestDatas, t]
  )

  const data = testDatas.sort(
    (a, b) => b.test.created_at.seconds - a.test.created_at.seconds
  )
  return useTanstackTable<ITestData>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
