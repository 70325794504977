import {
  findCachedCategory,
  findCachedFilelistsNonNull,
  findCachedTagsNonNull,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IFilelist } from 'common/interfaces/filelist'
import { ILive } from 'common/interfaces/live'
import { IVideo } from 'common/interfaces/video'
import { makeCategoryVideoPath, makeTagVideoPath } from 'common/link_path'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Badge, Button } from 'reactstrap'
import { downloadFile } from 'services/user/video'
import GenreBadge from '../Badge/GenreBadge'
import './elementsonvideodetail.scss'

/**
 * 動画詳細画面のカテゴリのバッジを生成
 */
export const makeGenreBadge = (
  storeCache: IStoreCache,
  categoryId: string
): JSX.Element => {
  const category = findCachedCategory(storeCache, categoryId)
  return category ? (
    <GenreBadge
      className="category-badge"
      name={category.name}
      linkPath={makeCategoryVideoPath(category.id)}
    />
  ) : (
    <></>
  )
}

/**
 * 動画詳細画面のタグのelementsを生成
 */
export const makeTagElements = (
  storeCache: IStoreCache,
  tagIds: string[]
): JSX.Element | null => {
  if (tagIds.length <= 0) return null

  return (
    <div className="tags">
      {findCachedTagsNonNull(storeCache, tagIds).map((tag) => (
        <div key={tag.id}>
          <Link className="tag-link" to={makeTagVideoPath(tag.id)}>
            #{tag.name}
          </Link>
        </div>
      ))}
    </div>
  )
}

/**
 * 動画詳細画面の添付ファイルを生成
 */
export const Filelists: React.FC<{
  obj: IVideo | ILive
  isCatalogPage?: boolean
}> = ({ obj, isCatalogPage }): JSX.Element | null => {
  const { t } = useTranslation('userVideo')
  const { storeCache } = useContext(AuthContext)

  if (obj.filelist_ids.length <= 0 || isCatalogPage) return null

  const dlFile = (filelist: IFilelist) =>
    downloadFile(filelist, storeCache, obj)
  return (
    <div className="filelist-contents">
      <h3 className="filelist-title">{t('overview.filelist')}</h3>
      {findCachedFilelistsNonNull(storeCache, obj.filelist_ids).map((f) => (
        <div key={f.id} className="my-1">
          <Button
            className="filelist-btn"
            type="button"
            onClick={() => dlFile(f)}
          >
            {f.name}
            <Badge className="download-badge">
              <i className="ni ni-cloud-download-95" />
            </Badge>
          </Button>
        </div>
      ))}
    </div>
  )
}
