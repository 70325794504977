import { goBackWithErrorAlert } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import TableBody from 'components/organisms/Admin/EmailLog/TableBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { Container } from 'reactstrap'

const EmailLog: React.FC = () => {
  const { t } = useTranslation('adminInvite')
  const { t: i18nAlert } = useTranslation('alert')
  const history = useHistory()
  const { state } = useLocation<{ foreignId: string }>()

  if (state && !state.foreignId) {
    goBackWithErrorAlert(history, i18nAlert('cantOpenThisPage'))
    return null
  }

  return (
    <>
      <H1 title={t('emailLog.title')} />

      <Container fluid>
        <ButtonRight content={t('emailLog.backToList')} />
        <TableBody foreignId={state.foreignId} />
      </Container>
    </>
  )
}

export default EmailLog
