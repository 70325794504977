export interface IEnqueteName {
  text: string
  textarea: string
  radio: string
  checkbox: string
  text_required: boolean
  textarea_required: boolean
  radio_required: boolean
  checkbox_required: boolean
}

export interface IEnqueteCustomize {
  type: IEnqueteType
  questionName: string
  isRequired?: boolean
  options?: string[]
}

export enum IEnqueteType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}
