import { IStoreCache } from 'common/interfaces/auth_provider'
import { INews } from 'common/interfaces/news'
import { isLoggedIn } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedNews } from 'providers/AuthProvider'
import { remove } from 'repositories/store/firebase_news'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * お知らせレコード削除処理
 */
export const removeNews = (news: INews, storeCache: IStoreCache) => {
  if (!isLoggedIn(storeCache)) return

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      await remove(storeCache.team!, news)
      await reloadCachedNews(storeCache)
      alertService.show(true, i18nAlert('deleted.message'))
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('cantDeleted'))
    }
  })
}
