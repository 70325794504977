import axios from 'axios'
import { CouponDurationType, CurrencyType, PriceType } from 'common/enums'
import { IStripeAccountLink } from 'common/interfaces/stripe/account_link'
import { ICancelSubscription } from 'common/interfaces/stripe/cancel_subscription'
import { IStripeCheckoutSession } from 'common/interfaces/stripe/checkout_session'
import { ICoupon } from 'common/interfaces/stripe/coupon'
import { IInvoice } from 'common/interfaces/stripe/Invoice'
import { IPaymentIntent } from 'common/interfaces/stripe/payment_intent'
import { IPrice } from 'common/interfaces/stripe/price'
import { IProduct } from 'common/interfaces/stripe/product'
import { IProductInfo } from 'common/interfaces/stripe/product_price'
import { IPurchasedInfo } from 'common/interfaces/stripe/purchased_info'
import { ITaxRate } from 'common/interfaces/stripe/tax_rate'
import {
  ISubscription,
  ISubscriptionObject,
} from 'common/interfaces/subscription'
import { IUser } from 'common/interfaces/user'
import { IInvoicePayment } from 'services/invoice_payment_modal'

const CF_API_BASE_URL = String(process.env.REACT_APP_FIREBASE_FUNCTIONS_URI)

/**
 * Stripe上の商品一覧を取得する
 * @param stripeAccount string
 * @returns `IProduct[]`
 */
export const getProducts = async (
  stripeAccount: string
): Promise<IProduct[]> => {
  const url = `${CF_API_BASE_URL}/stripe/get_product`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount }
  return (await axios.post<{ products: IProduct[] }>(url, data, { headers }))
    .data.products
}

/**
 * Stripe上で商品を保存する
 * @param stripeAccount string
 * @param id string
 * @param name string
 * @param description string
 * @param tax_rate string
 * @param currency `CurrencyType`
 * @returns `IProduct`
 */
export const saveProduct = async (
  stripeAccount: string,
  id: string,
  name: string,
  description: string,
  tax_rate: string,
  currency: CurrencyType,
  active: boolean
): Promise<IProduct> => {
  const url = `${CF_API_BASE_URL}/stripe/save_product`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    stripeAccount,
    id,
    name,
    description,
    tax_rate,
    currency,
    active,
  }
  return (await axios.post<{ product: IProduct }>(url, data, { headers })).data
    .product
}

/**
 * Stripで全ての料金を取得する
 * @param stripeAccount string
 * @returns `IPrice[]`
 */
export const getPrices = async (stripeAccount: string): Promise<IPrice[]> => {
  const url = `${CF_API_BASE_URL}/stripe/get_price`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount }
  return (await axios.post<{ prices: IPrice[] }>(url, data, { headers })).data
    .prices
}

/**
 * Stripe上で料金を保存する
 * @param stripeAccount string
 * @param product string
 * @param price `IPrice`
 * @param interval string | null
 * @param isCreate boolean
 * @returns void
 */
export const savePrice = async (
  stripeAccount: string,
  product: string,
  { id, unit_amount, nickname, active, currency, metadata }: IPrice,
  interval: string | null
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/stripe/save_price`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    stripeAccount,
    product,
    id,
    unit_amount,
    nickname,
    active,
    currency,
    metadata,
    interval,
  }
  await axios.post(url, data, { headers })
}

/**
 * Stripeのクーポンを取得する
 * @param stripeAccount string | undefined
 * @returns `ICoupon[]`
 */
export const getCoupons = async (
  stripeAccount?: string
): Promise<ICoupon[]> => {
  const url = `${CF_API_BASE_URL}/stripe/get_coupon`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount }
  return (await axios.post<{ coupons: ICoupon[] }>(url, data, { headers })).data
    .coupons
}

/**
 * Stripe上でクーポンを保存する
 * @param stripeAccount string
 * @param name string
 * @param currency CurrencyType | undefined
 * @param amount_off number | undefined
 * @param percent_off number | undefined
 * @param duration `CouponDurationType`
 * @param duration_in_months number | undefined
 * @param redeem_by number | null | undefined
 * @param product_ids string[] | null
 * @returns void
 */
export const saveCoupon = async (
  stripeAccount: string,
  name: string,
  currency: CurrencyType | undefined,
  amount_off: number | undefined,
  percent_off: number | undefined,
  duration: CouponDurationType,
  duration_in_months: number | undefined,
  redeem_by: number | null | undefined,
  product_ids: string[] | null
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/stripe/create_coupon`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    stripeAccount,
    name,
    currency,
    amount_off,
    percent_off,
    duration,
    duration_in_months,
    redeem_by,
    product_ids,
  }
  await axios.post(url, data, { headers })
}

/**
 * Stripeの特定のクーポンを削除する
 * @param stripeAccount string | undefined
 * @param couponId string
 * @returns `ICoupon[]`
 */
export const deleteCoupon = async (
  stripeAccount: string | undefined,
  couponId: string
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/stripe/delete_coupon`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, couponId }
  await axios.post<void>(url, data, { headers })
}

/**
 * Stripe上の税率一覧を取得する
 * @param stripeAccount string
 * @returns `ITaxRate[]`
 */
export const getTaxRates = async (
  stripeAccount: string
): Promise<ITaxRate[]> => {
  const url = `${CF_API_BASE_URL}/stripe/get_tax_rate`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount }
  return (await axios.post<{ tax_rates: ITaxRate[] }>(url, data, { headers }))
    .data.tax_rates
}

/**
 * Stripe上で税率を保存する
 * @param stripeAccount string
 * @param id string
 * @param display_name string
 * @param percentage number
 * @param isCreate boolean
 * @returns void
 */
export const saveTaxRate = async (
  stripeAccount: string,
  id: string,
  display_name: string,
  percentage: number,
  isCreate: boolean
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/stripe/${
    isCreate ? 'create_tax_rate' : 'update_tax_rate'
  }`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    stripeAccount,
    id,
    display_name,
    percentage,
  }
  await axios.post(url, data, { headers })
}

/**
 * Stripeで特定の商品の料金と税率を取得する
 * @param stripeAccount string | undefined
 * @param product string
 * @param taxRateId string | undefined
 * @param currency string | undefined
 * @returns `IProductInfo[]`
 */
export const fetchProductInfo = async (
  stripeAccount: string | undefined,
  product: string,
  taxRateId: string | undefined,
  currency?: string
): Promise<IProductInfo[]> => {
  const url = `${CF_API_BASE_URL}/stripe/get_product_info`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, product, taxRateId, currency }
  return (
    await axios.post<{ product_info: IProductInfo[] }>(url, data, { headers })
  ).data.product_info
}

/**
 * Stripeで特定の商品の料金と税率を複数の料金ID取得する
 * @param stripeAccount string | undefined
 * @param priceIds string[]
 * @returns `IProductInfo[]`
 */
export const fetchProductInfoByPriceIds = async (
  stripeAccount: string | undefined,
  priceIds: string[],
  onlyOneTime?: boolean
): Promise<IProductInfo[]> => {
  const url = `${CF_API_BASE_URL}/stripe/price_ids/get_product_info`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, priceIds, onlyOneTime }
  return (
    await axios.post<{ product_info: IProductInfo[] }>(url, data, { headers })
  ).data.product_info
}

/**
 * Stripeでセッションを作成する
 * @param stripeAccount string
 * @param customer string | undefined
 * @param customer_email string
 * @param price string
 * @param coupon string | undefined
 * @param uid string
 * @param success_url string
 * @param cancel_url string
 * @param tax_rate string
 * @param mode 'payment' | 'setup' | 'subscription'
 * @param valid_days number | undefined
 * @param trial_end number | undefined
 * @returns `IStripeCheckoutSession`
 */
export const createCheckoutSession = async (
  stripeAccount: string | undefined,
  customer: string | undefined,
  customer_email: string | undefined,
  price: string,
  coupon: string | undefined,
  uid: string,
  success_url: string,
  cancel_url: string,
  tax_rate: string,
  mode: 'payment' | 'setup' | 'subscription',
  valid_days: number | undefined,
  trial_end: number | undefined
): Promise<IStripeCheckoutSession> => {
  const url = `${CF_API_BASE_URL}/stripe/create_session`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    stripeAccount,
    customer,
    customer_email,
    price,
    coupon,
    uid,
    success_url,
    cancel_url,
    tax_rate,
    mode,
    valid_days,
    trial_end,
  }
  return (
    await axios.post<{ checkout_session: IStripeCheckoutSession }>(url, data, {
      headers,
    })
  ).data.checkout_session
}

/**
 * Stripeで発行済みの請求書をキャンセルする
 * @param stripeAccount string | undefined
 * @param paymentIntentId string
 * @param invoiceId string | null
 * @param customerId string | null
 * @param price string
 * @param currency string
 * @param unit_amount_with_tax number
 * @param coupon string | undefined
 * @param tax_rate string
 * @param mode `PriceType`
 * @param valid_days number | undefined
 * @param trial_end number | undefined
 * @returns `void`
 */
export const cancelPaymentIntent = async (
  stripeAccount: string | undefined,
  paymentIntentId: string,
  invoiceId: string | null,
  customerId: string | null,
  invoicePayment: IInvoicePayment,
  price: string,
  currency: string,
  unit_amount_with_tax: number,
  coupon: string | undefined,
  tax_rate: string,
  mode: PriceType,
  valid_days?: number,
  trial_end?: number
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/stripe/cancel_payment_intent`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    stripeAccount,
    paymentIntentId,
    invoiceId,
    customerId,
    name: invoicePayment.name,
    email: invoicePayment.email,
    price,
    currency,
    unit_amount_with_tax,
    coupon,
    tax_rate,
    mode,
    valid_days,
    trial_end,
  }
  await axios.post<void>(url, data, { headers })
}

/**
 * Stripeで請求書払いを作成する
 * @param stripeAccount string | undefined
 * @param firebaseUID string
 * @param customerId string | null
 * @param price string
 * @param currency string
 * @param unit_amount_with_tax number
 * @param coupon string | undefined
 * @param tax_rate string
 * @param mode `PriceType`
 * @param valid_days number | undefined
 * @param trial_end number | undefined
 * @returns `Promise<void>`
 */
export const createInvoicePayment = async (
  stripeAccount: string | undefined,
  isTeam: boolean,
  firebaseUID: string,
  customerId: string | null,
  user: IUser,
  invoicePayment: IInvoicePayment,
  price: string,
  currency: string,
  unit_amount_with_tax: number,
  coupon: string | undefined,
  tax_rate: string,
  mode: PriceType,
  valid_days: number | undefined,
  trial_end: number | undefined
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/stripe/create_invoice_payment`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    stripeAccount,
    isTeam,
    firebaseUID,
    customerId,
    userId: user.id,
    name: invoicePayment.name,
    email: invoicePayment.email,
    price,
    currency,
    unit_amount_with_tax,
    coupon,
    tax_rate,
    mode,
    valid_days,
    trial_end,
  }
  await axios.post<void>(url, data, { headers })
}

/**
 * Stripeで請求書を再送する（一括または、継続）
 * @param stripeAccount string | undefined
 * @param userId string
 * @param paymentIntentId string
 * @param invoiceId string
 * @returns `void`
 */
export const sendInvoice = async (
  stripeAccount: string | undefined,
  userId: string | undefined,
  customerId: string,
  invoicePayment: IInvoicePayment,
  paymentIntentId: string | undefined,
  invoiceId: string | null
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/stripe/send_invoice`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    stripeAccount,
    userId,
    customerId,
    name: invoicePayment.name,
    email: invoicePayment.email,
    paymentIntentId,
    invoiceId,
  }
  await axios.post<void>(url, data, { headers })
}

/**
 * Stripeでセッションを作成する（カード変更用）
 * @param stripeAccount string | undefined
 * @param subscription string | undefined
 * @param customer string
 * @param success_url string
 * @param cancel_url string
 * @returns `IStripeCheckoutSession`
 */
export const getCheckoutSessionForSetup = async (
  stripeAccount: string | undefined,
  subscription: string | undefined,
  customer: string,
  success_url: string,
  cancel_url: string
): Promise<IStripeCheckoutSession> => {
  const url = `${CF_API_BASE_URL}/stripe/create_session/setup`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    stripeAccount,
    subscription,
    customer,
    success_url,
    cancel_url,
  }
  return (
    await axios.post<{ checkout_session: IStripeCheckoutSession }>(url, data, {
      headers,
    })
  ).data.checkout_session
}

/**
 * Stripeで比例配分のプレビューを取得する
 * @param stripeAccount string | undefined
 * @param customer string
 * @param subscription string
 * @param price string
 * @returns `IInvoice`
 */
export const previewUpcoming = async (
  stripeAccount: string | undefined,
  customer: string,
  subscription: string,
  price: string
): Promise<IInvoice> => {
  const url = `${CF_API_BASE_URL}/stripe/preview_upcoming`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, customer, subscription, price }
  return (await axios.post<{ invoice: IInvoice }>(url, data, { headers })).data
    .invoice
}

/**
 * Stripeでサブスク商品を変更する
 * @param stripeAccount string | undefined
 * @param subscription string
 * @param price string
 * @returns void
 */
export const changePrice = async (
  stripeAccount: string | undefined,
  subscription: string,
  price: string
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/stripe/change_price`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, subscription, price }
  await axios.post(url, data, { headers })
}

/**
 * Stripeでsubscriptionの継続課金を新規作成する
 * @param stripeAccount string | undefined
 * @param isTeam boolean
 * @param firebaseUID string
 * @param userId string
 * @param customerId string | undefined
 * @param email string | undefined
 * @param coupon string | undefined
 * @param name string | undefined
 * @param price string
 * @param tax_rate string
 * @returns `{ client_secret: string }`
 */
export const createSubscription = async (
  stripeAccount: string | undefined,
  isTeam = false,
  firebaseUID: string,
  userId: string,
  customerId: string | undefined,
  email: string | undefined,
  coupon: string | undefined,
  name: string | undefined,
  price: string,
  tax_rate: string
): Promise<{
  invoice: IInvoice | null
  payment_intent: IPaymentIntent | null
}> => {
  const url = `${CF_API_BASE_URL}/stripe/create_subscription`
  const headers = { 'Content-Type': 'application/json' }
  const data = {
    stripeAccount,
    isTeam,
    firebaseUID,
    userId,
    customerId,
    email,
    coupon,
    name,
    price,
    tax_rate,
  }

  const res = await axios.post<{
    success: boolean
    invoice: IInvoice | null
    payment_intent: IPaymentIntent | null
  }>(url, data, { headers })
  return res.data
}

/**
 * Stripeでsubscriptionの継続課金をキャンセルする
 * @param stripeAccount string | undefined
 * @param subscription string
 * @returns `ICancelSubscription`
 */
export const deleteSubscription = async (
  stripeAccount: string | undefined,
  subscription: string
): Promise<ICancelSubscription> => {
  const url = `${CF_API_BASE_URL}/stripe/cancel_subscription`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, subscription }
  return (await axios.post(url, data, { headers })).data
}

/**
 * Stripeでsubscriptionの継続課金を再開をする
 * @param stripeAccount string | undefined
 * @param subscription string
 * @returns `ICancelSubscription`
 */
export const resumeSubscription = async (
  stripeAccount: string | undefined,
  subscription: string
): Promise<ICancelSubscription> => {
  const url = `${CF_API_BASE_URL}/stripe/restart_subscription`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, subscription }
  return (await axios.post(url, data, { headers })).data
}

/**
 * Stripeで特定の決済情報（インボイス）を取得する
 * @param stripeAccount string | undefined
 * @param invoice string
 * @returns `IInvoice`
 */
export const fetchInvoice = async (
  stripeAccount: string | undefined,
  invoice: string
): Promise<IInvoice> => {
  const url = `${CF_API_BASE_URL}/stripe/get_invoice`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, invoice }
  return (await axios.post<{ invoice: IInvoice }>(url, data, { headers })).data
    .invoice
}

/**
 * Stripeで決済履歴を顧客を用いて取得する
 * @param stripeAccount string | undefined
 * @param customer string
 * @returns `IInvoice[]`
 */
export const getInvoices = async (
  stripeAccount: string | undefined,
  customer: string
): Promise<IInvoice[]> => {
  const url = `${CF_API_BASE_URL}/stripe/get_invoices`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, customer }
  return (await axios.post<{ invoices: IInvoice[] }>(url, data, { headers }))
    .data.invoices
}

/**
 * Stripeで決済履歴を作成日を用いて取得する
 * @param stripeAccount string | undefined
 * @param gte number
 * @param lte number
 * @returns `IPaymentIntent[]`
 */
export const getPaymentIntentsByCreated = async (
  stripeAccount: string | undefined,
  gte: number,
  lte: number
): Promise<IPaymentIntent[]> => {
  const url = `${CF_API_BASE_URL}/stripe/get_payment_intents_by_created`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, gte, lte }
  return (
    await axios.post<{ payment_intents: IPaymentIntent[] }>(url, data, {
      headers,
    })
  ).data.payment_intents
}

/**
 * Stripeで一括決済履歴を顧客を用いて取得する
 * @param stripeAccount string | undefined
 * @param customer string
 * @returns `IPaymentIntent[]`
 */
export const getPaymentIntents = async (
  stripeAccount: string | undefined,
  customer: string
): Promise<IPaymentIntent[]> => {
  const url = `${CF_API_BASE_URL}/stripe/get_payment_intents`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, customer }
  return (
    await axios.post<{ payment_intents: IPaymentIntent[] }>(url, data, {
      headers,
    })
  ).data.payment_intents
}

/**
 * PaymentIntentを新規作成し取得する
 * @param stripeAccount string | undefined
 * @param customerId string
 * @param paymentMethodId string
 * @returns `boolean` is succeeded
 */
export const updatePaymentMethod = async (
  stripeAccount: string | undefined,
  customerId: string,
  paymentMethodId: string
): Promise<boolean> => {
  const url = `${CF_API_BASE_URL}/stripe/update_payment_method`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, customerId, paymentMethodId }

  const res = await axios.post<{ success: boolean }>(url, data, { headers })
  return res.data.success
}

/**
 * PaymentIntentステータス未完了の存在確認をおこなう
 * @param stripeAccount string | undefined
 * @param customer string
 * @returns `IPaymentIntent` | null
 */
export const hasIncompletePaymentIntent = async (
  stripeAccount: string | undefined,
  customer: string
): Promise<IPaymentIntent | null> => {
  const url = `${CF_API_BASE_URL}/stripe/confirm_incomplete_payment_intent`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, customer }
  return (
    await axios.post<{ payment_intent: IPaymentIntent | null }>(url, data, {
      headers,
    })
  ).data.payment_intent
}

/**
 * Stripeで購入している全料金IDを取得する
 * @param stripeAccount string
 * @param customer string
 * @returns `string[]`
 */
export const fetchPurchasedInfos = async (
  stripeAccount: string,
  customer: string
): Promise<IPurchasedInfo[]> => {
  const url = `${CF_API_BASE_URL}/stripe/purchased/get_infos`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, customer }
  return (
    await axios.post<{ purchased_infos: IPurchasedInfo[] }>(url, data, {
      headers,
    })
  ).data.purchased_infos
}

/**
 * Stripeで継続課金中の `subscription` 関連オブジェクトを取得する
 * @param stripeAccount string | undefined
 * @param customer string
 * @returns `ISubscriptionObject | null`
 */
export const fetchSubscriptionObj = async (
  stripeAccount: string | undefined,
  customer: string
): Promise<ISubscriptionObject | null> => {
  const url = `${CF_API_BASE_URL}/stripe/get_subscription_object`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, customer }
  return (
    await axios.post<{ subscription_obj: ISubscriptionObject | null }>(
      url,
      data,
      { headers }
    )
  ).data.subscription_obj
}

/**
 * Stripeで購入しているサブスク商品の料金IDを取得する
 * @param stripeAccount string | undefined
 * @param customer string
 * @returns `string`
 */
export const fetchPriceIdBySubscription = async (
  stripeAccount: string | undefined,
  customer: string
): Promise<string> => {
  const url = `${CF_API_BASE_URL}/stripe/subscription/get_price_id`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, customer }
  return (await axios.post<{ price_id: string }>(url, data, { headers })).data
    .price_id
}

/**
 * Stripeで購入しているサブスクステータスが`unpaid`なものを取得
 * @param stripeAccount string | undefined
 * @param customer string
 * @returns `string`
 */
export const getUnpaidSubscription = async (
  stripeAccount: string | undefined,
  customer: string
): Promise<ISubscription | null> => {
  const url = `${CF_API_BASE_URL}/stripe/subscription/unpaid`
  const headers = { 'Content-Type': 'application/json' }
  const data = { stripeAccount, customer }
  return (
    await axios.post<{ subscription: ISubscription | null }>(url, data, {
      headers,
    })
  ).data.subscription
}

/**
 * Stripeでアカウントを作成する
 * @param redirectUri string
 * @returns `IStripeAccountLink`
 */
export const getAccountLinks = async (
  redirectUri: string
): Promise<IStripeAccountLink> => {
  const url = `${CF_API_BASE_URL}/stripe/account_create`
  const headers = { 'Content-Type': 'application/json' }
  const data = { redirectUri }
  return (
    await axios.post<{ account_links: IStripeAccountLink }>(url, data, {
      headers,
    })
  ).data.account_links
}
