import { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { makeLoginTeamPath } from 'common/link_path'
import {
  addTimestampDate,
  dateFormat,
  formatUNIXToDate,
  isAfterTimestamp,
} from 'common/times'
import { isBasicPrice, isProPrice } from 'common/utils'
import { History } from 'history'
import { i18nAlert, i18nCommon } from 'i18n/i18n'
import {
  checkAllowedIp,
  checkSingleLogin,
} from 'repositories/functions/functions_auth'
import { fetchSubscriptionObj } from 'repositories/functions/functions_stripe'
import { addLoggedOutLog } from 'repositories/store/firebase_log'
import { get as getStripe } from 'repositories/store/firebase_stripe'
import { alertService } from './alert'

/**
 * 他端末でログインしていないかチェックする
 * @returns {Promise<boolean>} ログインしていない場合はtrue
 */
export const checkRestrictedSingleLogin = async (
  team: ITeam,
  user: IUser
): Promise<boolean> => {
  if (!team.stripeId || user.admin) return true

  const subscriptionObj = await fetchSubscriptionObj(undefined, team.stripeId)
  const stripe = await getStripe()
  const isBasic = isBasicPrice(subscriptionObj, stripe)
  const isPro = isProPrice(subscriptionObj, stripe)
  if (!isBasic && !isPro && team.is_single_login && user.recent_ip) {
    return checkSingleLogin(team.id, user.id)
  }

  return true
}

/**
 * IP制限されているか且つ、IPが許容されているかチェックする
 * @returns {Promise<boolean>} IPが許容されている場合はtrue
 */
export const checkRestrictedIpAndAllowedIp = async (
  { id, allow_ips }: ITeam,
  user: IUser
): Promise<boolean> => {
  const teamAllowIps = user.admin ? allow_ips.admin : allow_ips.user
  if (teamAllowIps.length > 0) return checkAllowedIp(id, user.id)
  return true
}

/**
 * 無効化されている又は、有効期限が過ぎているかチェックする
 * @returns {boolean} 無効化されている又は、有効期限が過ぎている場合はtrue
 */
export const checkDisabled = ({ user }: IStoreCache): boolean =>
  !user ||
  user.disabled === true ||
  (user.expire.seconds !== 0 && isAfterTimestamp(user.expire))

const transitionToLogin = async (
  history: History,
  { team, user }: IStoreCache,
  message: string
): Promise<void> => {
  if (team && user) {
    await addLoggedOutLog(team, user)
  }
  await auth.signOut()
  alertService.show(false, message)
  history.replace(makeLoginTeamPath(team?.id ?? ''))
}

/**
 * シングルログインでないときの認証処理
 */
export const restrictedSingleLogin = async (
  history: History,
  storeCache: IStoreCache
): Promise<void> => {
  await transitionToLogin(
    history,
    storeCache,
    i18nAlert('auth.loggedoutToLoginOtherDevice')
  )
}

/**
 * IP制限されているときの認証処理
 */
export const restrictedIp = async (
  history: History,
  storeCache: IStoreCache
): Promise<void> => {
  await transitionToLogin(
    history,
    storeCache,
    i18nAlert('auth.cantLoginForLimitedIp')
  )
}

/**
 * ユーザーが無効化されているときの認証処理
 */
export const disabledUser = async (
  history: History,
  storeCache: IStoreCache
): Promise<void> => {
  await transitionToLogin(
    history,
    storeCache,
    i18nAlert('account.disabled.cantLogin')
  )
}

/**
 * チーム自体解約済みのときの認証処理
 */
export const cancelledTeam = async (
  history: History,
  storeCache: IStoreCache
): Promise<void> => {
  let message = i18nAlert('auth.failedLogin')
  if (storeCache.user?.admin) {
    const cancelledDate = dateFormat(storeCache.team!.cancelled_date!)
    const deleteDate = formatUNIXToDate(
      addTimestampDate(storeCache.team!.cancelled_date!, 30, 'day'),
      i18nCommon('format.date')
    )
    message = i18nAlert('account.cancelled.deleteLater', {
      cancelledDate,
      deleteDate,
    })
  }
  await transitionToLogin(history, storeCache, message)
}
