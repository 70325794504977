import { ISubscriptionObject } from 'common/interfaces/subscription'
import { IFindTeam, ITeam } from 'common/interfaces/team'
import {
  catalogRootPath,
  makeCatalogPath,
  makeJoinUserPath,
  makeLoginTeamPath,
} from 'common/link_path'
import { isTrialPlan } from 'common/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
} from 'reactstrap'
import { UsingTrialNavbar } from '../User/UsingTrialNavbar/UsingTrialNavbar'
import './header.css'

interface IHeader {
  team: ITeam | IFindTeam
  subscriptionObj: ISubscriptionObject | null
  isCatalogPage?: boolean
}

const Header: React.FC<IHeader> = ({
  team,
  subscriptionObj,
  isCatalogPage,
}: IHeader) => {
  const { t } = useTranslation('common')

  return (
    <div>
      {isTrialPlan(subscriptionObj) && <UsingTrialNavbar />}
      <Navbar
        className="header user-header navbar-horizontal navbar-dark py-0"
        expand="lg"
      >
        <Container>
          {logoElement(team, isCatalogPage)}
          {!isCatalogPage && team.is_site_public && (
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  to={makeCatalogPath(team.id)}
                  tag={Link}
                  className="team-text-color"
                >
                  {t('landingPage.header.videoList')}
                </NavLink>
              </NavItem>
            </Nav>
          )}

          <Nav className="flex-row" navbar>
            <NavItem>
              <NavLink
                to={makeLoginTeamPath(team.id)}
                tag={Link}
                className="p-2 p-md-3 team-text-color"
              >
                {t('landingPage.header.login')}
              </NavLink>
            </NavItem>
            {team.urls.account_register && (
              <NavItem>
                <NavLink
                  to={makeJoinUserPath(team.urls.account_register)}
                  tag={Link}
                  className="p-2 p-md-3 team-text-color"
                >
                  {t('landingPage.header.register')}
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </Container>
      </Navbar>
    </div>
  )
}

const logoElement = (
  team: ITeam | IFindTeam,
  isCatalogPage?: boolean
): JSX.Element => {
  const logoPath = 'logo' in team ? team.logo?.path : team.logo_path
  if (logoPath) {
    return (
      <NavbarBrand
        tag={isCatalogPage ? Link : undefined}
        to={isCatalogPage ? catalogRootPath() : undefined}
      >
        <img src={logoPath} alt={team.name} />
      </NavbarBrand>
    )
  }
  if (isCatalogPage) return <Link to={catalogRootPath()}>{team.name}</Link>

  return <div>{team.name}</div>
}

export default Header
