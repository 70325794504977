import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardTitle, Col, Container, Row } from 'reactstrap'

interface ICardComponent {
  title: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
}

const CardComponent: React.FC<ICardComponent> = ({
  title,
  onClick,
  children,
}: ICardComponent) => {
  const { t } = useTranslation('userSetting')
  return (
    <Col lg={6}>
      <Card className="email-setting-card">
        <CardTitle className="display-4 px-4 my-2">{title}</CardTitle>
        <Container className="px-4 pt-2 pb-3">
          {children}
          <Row>
            <Col md="12" className="text-center">
              <Button className="change-btn" onClick={onClick} type="button">
                {t('cardComponent.change')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Card>
    </Col>
  )
}

export default CardComponent
