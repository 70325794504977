import { IStoreCache } from 'common/interfaces/auth_provider'
import { IFilelist } from 'common/interfaces/filelist'
import { isLoggedIn } from 'common/utils'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { reloadCachedFilelists } from 'providers/AuthProvider'
import { removeImageFile } from 'repositories/storage/firebase_filelist'
import { remove } from 'repositories/store/firebase_filelist'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * ファイルを使用している動画/配信の件数を返却する
 */
export const getFilelistUseCount = (
  filelist: IFilelist,
  storeCache: IStoreCache
) => {
  return [...storeCache.videos, ...storeCache.lives].filter(
    (video) => video.filelist_ids.indexOf(filelist.id) !== -1
  ).length
}

/**
 * ファイルレコード削除処理
 */
export const removeFilelist = (
  filelist: IFilelist,
  storeCache: IStoreCache
) => {
  if (!isLoggedIn(storeCache)) return
  if (getFilelistUseCount(filelist, storeCache) > 0) {
    alertService.show(false, i18nValidation('cantDelete.inUse.filelist'))
    return
  }

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      if (filelist.path) {
        await removeImageFile(storeCache.team!, filelist)
      }
      await remove(storeCache.team!, filelist)
      await reloadCachedFilelists(storeCache)

      alertService.show(true, i18nAlert('deleted.message'))
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('cantDeleted'))
    }
  })
}
