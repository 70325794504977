import firebase from 'common/firebase'
import { IPublicationPeriod, VideoStatus } from './video'

export enum LivePlatform {
  ZOOM_MEETING = 'zoom',
  ZOOM_WEBINAR = 'zoom_webinar',
  YOUTUBE = 'youtube',
  VIMEO_VIDEO = 'vimeo_video',
  VIMEO_LIVE = 'vimeo_live',
  VIMEO_WEBINAR = 'vimeo_webinar',
}

export interface ILive {
  id: string
  name: string
  image: string | null
  overview: string
  category_id: string
  tag_ids: string[]
  filelist_ids: string[]
  creator_id: string
  status: VideoStatus
  is_list_hidden: boolean
  group_ids: string[]
  price_ids: string[]
  publication_period: IPublicationPeriod | null
  hold_from: firebase.firestore.Timestamp
  hold_to: firebase.firestore.Timestamp
  platform: LivePlatform
  meeting_number: string
  meeting_password: string
  youtube_video_id: string
  vimeo_id: string
  is_public: boolean
  is_registered: boolean
  created_at: firebase.firestore.Timestamp
  updated_at: firebase.firestore.Timestamp
}
