import { EventFormat, Routes } from 'common/enums'
import {
  findCachedCategory,
  findCachedTagsNonNull,
  findCachedVideoAndLivesNonNull,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEvent } from 'common/interfaces/event'
import { VideoStatus } from 'common/interfaces/video'
import {
  date2Timestamp,
  nowTimestamp,
  todayFirebaseTimestamp,
} from 'common/times'
import {
  checkFirebaseError,
  createTagOptions,
  createVideoAndLiveOptions,
  getDefaultPublicationPeriod,
  isLoggedIn,
} from 'common/utils'
import { createCategoryJSXOptions } from 'components/atoms/Table/CreateTable'
import { History } from 'history'
import { reloadCachedEvents } from 'providers/AuthProvider'
import { removeImageFile, upload } from 'repositories/storage/firebase_event'
import { removeImage, store, update } from 'repositories/store/firebase_event'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/event_form'
import { v4 as uuidv4 } from 'uuid'

/**
 * `ILive`の初期値を返却する
 */
export const initEvent = (): IEvent => {
  const today = todayFirebaseTimestamp()
  return {
    id: uuidv4(),
    name: '',
    format: EventFormat.APPLICATION,
    status: VideoStatus.PRIVATE,
    publication_period: null,
    application_period_from: today[0],
    application_period_to: today[1],
    hold_from: today[0],
    hold_to: today[1],
    image: '',
    category_id: '',
    tag_ids: [],
    overview: '',
    contents: [],
    is_public: false,
    created_at: nowTimestamp(),
    updated_at: nowTimestamp(),
  }
}

/**
 * Input/Selectで使用するoptionsを返却する
 */
export const getFormOptions = ({
  categories,
  tags,
  videos,
  lives,
}: IStoreCache) => {
  return {
    categoryOptions: createCategoryJSXOptions(categories),
    tagOptions: createTagOptions(tags),
    videoAndLiveOptions: createVideoAndLiveOptions(videos, lives),
  }
}

/**
 * デフォルト値を返却する
 */
export const getDefaults = (
  storeCache: IStoreCache,
  { category_id, tag_ids, contents }: IEvent
) => {
  return {
    defaultCategory: findCachedCategory(storeCache, category_id),
    defaultTags: findCachedTagsNonNull(storeCache, tag_ids).map(
      ({ id, name }) => ({ value: id, label: name })
    ),
    defaultContentss: findCachedVideoAndLivesNonNull(storeCache, contents).map(
      ({ id, name }) => ({ value: id, label: name })
    ),
  }
}

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
  FORMAT,
  STATUS,
  APPLICATION_PERIOD_FROM,
  APPLICATION_PERIOD_TO,
  HOLD_FROM,
  HOLD_TO,
  CATEGORY_ID,
  TAG_IDS,
  OVERVIEW,
  CONTENTS,
  IS_PUBLIC,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  event: IEvent,
  setData: React.Dispatch<React.SetStateAction<IEvent>>,
  type: InputType,
  e: React.ChangeEvent<HTMLInputElement> | any
) => {
  const val = e.target?.value
  const checked = e.target?.checked
  const period: IEvent['publication_period'] = (() => {
    switch (Number(val)) {
      case VideoStatus.PUBLIC:
        return { start: nowTimestamp(), end: null }
      case VideoStatus.PUBLICATION_PERIOD:
        return getDefaultPublicationPeriod(event)
      default:
        return null
    }
  })()
  setData({
    ...event,
    name: type === InputType.NAME ? val : event.name,
    format: type === InputType.FORMAT ? val : event.format,
    status: type === InputType.STATUS ? Number(val) : event.status,
    publication_period:
      type === InputType.STATUS ? period : event.publication_period,
    application_period_from:
      type === InputType.APPLICATION_PERIOD_FROM
        ? date2Timestamp(new Date(val))
        : event.application_period_from,
    application_period_to:
      type === InputType.APPLICATION_PERIOD_TO
        ? date2Timestamp(new Date(val))
        : event.application_period_to,
    hold_from:
      type === InputType.HOLD_FROM
        ? date2Timestamp(new Date(val))
        : event.hold_from,
    hold_to:
      type === InputType.HOLD_TO
        ? date2Timestamp(new Date(val))
        : event.hold_to,
    category_id: type === InputType.CATEGORY_ID ? val : event.category_id,
    tag_ids:
      type === InputType.TAG_IDS ? e.map((x: any) => x.value) : event.tag_ids,
    overview: type === InputType.OVERVIEW ? e : event.overview,
    contents:
      type === InputType.CONTENTS ? e.map((x: any) => x.value) : event.contents,
    is_public: type === InputType.IS_PUBLIC ? checked : event.is_public,
  })
}

/**
 * 公開日/公開期限Inputの`onChange`イベントハンドラ
 */
export const onChangeLimitedAccess = (
  event: IEvent,
  setData: React.Dispatch<React.SetStateAction<IEvent>>,
  type: 'start' | 'end',
  { target }: React.ChangeEvent<HTMLInputElement>
) => {
  const date = date2Timestamp(new Date(target.value))
  setData({
    ...event,
    publication_period: {
      start:
        type === 'start'
          ? date
          : event.publication_period?.start ?? date2Timestamp(new Date()),
      end:
        type === 'end'
          ? date
          : event.publication_period?.end ?? date2Timestamp(new Date()),
    },
  })
}

/**
 * レコード作成/更新処理
 */
export const saveEvent = async (
  { push }: History,
  event: IEvent,
  imageBlob: Blob | undefined,
  isImageDelete: boolean,
  isImageCropped: boolean,
  storeCache: IStoreCache,
  isCreate: boolean
) => {
  if (!isLoggedIn(storeCache)) return

  try {
    validateForm(event, isImageCropped)

    if (isCreate) {
      await createEvent(event, imageBlob, storeCache)
    } else {
      await updateEvent(event, imageBlob, isImageDelete, storeCache)
    }
    await reloadCachedEvents(storeCache)

    push(Routes.AdminEvent)
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}

const createEvent = async (
  event: IEvent,
  imageBlob: Blob | undefined,
  { team }: IStoreCache
) => {
  event.image = await upload(team!, event, imageBlob)
  event.created_at = nowTimestamp()
  event.updated_at = nowTimestamp()
  await store(team!, event)
}

const updateEvent = async (
  event: IEvent,
  imageBlob: Blob | undefined,
  isImageDelete: boolean,
  storeCache: IStoreCache
) => {
  if (imageBlob && !isImageDelete) {
    event.image = await upload(storeCache.team!, event, imageBlob)
  }
  if (isImageDelete) {
    await deleteImage(event, storeCache)
  }

  await update(storeCache.team!, event)
}

/**
 * 画像を削除する(ドキュメント更新 & ファイル削除)
 */
const deleteImage = async (
  event: IEvent,
  { team }: IStoreCache
): Promise<void> => {
  await removeImageFile(team!, event)
  await removeImage(team!, event)
}
