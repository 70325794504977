import { ICoupon } from 'common/interfaces/stripe/coupon'
import { IProductInfo } from 'common/interfaces/stripe/product_price'
import { makeSettingPath } from 'common/link_path'
import { goBackWithErrorAlert } from 'common/utils'
import Item from 'components/atoms/Item/Item'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { getStripeCoupons } from 'services/admin/stripe/coupon'
import {
  getPurchasedPriceId,
  getStripeProductAndPrice,
} from 'services/item_select'
import { changePriceForUser } from 'services/user/change_price'

type TList = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const List: React.FC<TList> = (props) => {
  const { t } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [productInfos, setProductInfos] = useState<IProductInfo[]>([])
  const [coupons, setCoupons] = useState<ICoupon[]>([])
  const [purchasedPriceId, setPurchasedPriceId] = useState<string>('')

  useEffect(() => {
    ;(async () => {
      await Promise.all([
        getPurchasedPriceId(storeCache, setPurchasedPriceId),
        getStripeCoupons(storeCache, setCoupons),
        getStripeProductAndPrice(storeCache, setProductInfos),
      ])
    })()
  }, [storeCache])

  const user = storeCache.user!
  if (!user.customer_id) {
    goBackWithErrorAlert(history, t('plans.notJoined'))
    return null
  }

  const changePrice = (selectedPriceId: string) =>
    changePriceForUser(
      history,
      storeCache,
      selectedPriceId,
      makeSettingPath(),
      props.setLoading
    )

  const Items = () =>
    productInfos.map((productInfo: IProductInfo, key: number) => {
      if (productInfo.hidden) return <></>

      return (
        <Item
          team={storeCache.team}
          user={user}
          subscriptionObj={storeCache.selfSubscriptionObj}
          productInfo={productInfo}
          coupons={coupons}
          purchasedPriceId={purchasedPriceId}
          transitionToAuth={() => undefined}
          checkoutSession={() => undefined}
          changePrice={() => changePrice(productInfo.id)}
          createInvoicePayment={() => undefined}
          key={key}
        />
      )
    })

  return <>{Items()}</>
}

export default List
