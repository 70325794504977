import firebase from 'common/firebase'

export interface ITest {
  id: string
  name: string
  questions: ITestQuestion[]
  pass_correct_count: number
  retest: {
    is_enable: boolean
    is_stop_when_passed: boolean
    limit_count: number
  }
  passed: {
    button_text: string | null
    button_url: string | null
    note: string
  }
  failed: {
    is_show_answer: boolean
    is_show_explain: boolean
  }
  created_at: firebase.firestore.Timestamp
  updated_at: firebase.firestore.Timestamp
}

export interface ITestQuestion {
  type: ITestQuestionType
  name: string
  options: string[]
  correct_index: number | number[]
  explain: string
}

export enum ITestQuestionType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}
