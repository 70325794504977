import { Routes } from 'common/enums'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import { changeCard, signOut } from 'services/user/setting'

const Buttons: React.FC = () => {
  const { t } = useTranslation('common')
  const { storeCache } = useContext(AuthContext)

  const history = useHistory()

  if (!storeCache.user) {
    history.replace(Routes.LoginAdmin)
    return null
  }

  const changeCardInfo = () => changeCard(storeCache)

  const logOut = () => signOut(history, storeCache)

  return (
    <div className="main-content">
      <div className="header py-7 py-lg-8" />

      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-3">
                <div className="text-center mb-5">
                  <Row className="my-5 text-left">
                    <Trans
                      t={t}
                      i18nKey="creditCardUpdate.failedReccuringPaymentMessage"
                      components={{ mb4Div: <div className="mb-4" /> }}
                    />
                  </Row>
                  <Row className="my-4">
                    <Col>
                      <Button
                        onClick={changeCardInfo}
                        color="secondary"
                        type="button"
                      >
                        {t('creditCardUpdate.changeCreditCard')}
                      </Button>
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Col>
                      <Button onClick={logOut} color="info" type="button">
                        {t('logout')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Buttons
