import { makeUserRootPath } from 'common/link_path'
import CreatorDetailBody from 'components/organisms/User/CreatorDetail/CreatorDetailBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { findCreator } from 'services/user/creator_detail'

const CreatorDetail: React.FC = () => {
  const { t } = useTranslation('userVideoList')
  const { storeCache } = useContext(AuthContext)
  const { creatorId } = useParams<{ creatorId: string }>()
  const userUrl = makeUserRootPath(storeCache.team!.id)
  const creator = findCreator(storeCache, creatorId)
  if (!creator) {
    return (
      <div className="mt-5 text-center">
        <Trans
          t={t}
          i18nKey="creatorDetail.notFoundCreator"
          components={{ topLink: <Link to={userUrl} /> }}
        />
      </div>
    )
  }

  return <CreatorDetailBody creator={creator} />
}

export default CreatorDetail
