import { LocalStorageVideoResume } from 'common/interfaces/local_storage'

const KEY_TEAM_ID = 'team_id'
const KEY_VIDEO_RESUMES = 'video_resumes'

export const LOCAL_STORAGE_TEAM_ID = localStorage.getItem(KEY_TEAM_ID)

export const setTeamId = (teamId: string) =>
  localStorage.setItem(KEY_TEAM_ID, teamId)

/** Get VideoResume array object from localStorage */
const getLsVideoResumes = (): LocalStorageVideoResume[] =>
  JSON.parse(localStorage.getItem(KEY_VIDEO_RESUMES) || '[]')

/** Get specified video resume time */
export const getVideoResume = (videoUrl: string): number | null => {
  const videoResumes = getLsVideoResumes()
  const resumeObj = videoResumes.find((r) => r.videoUrl === videoUrl)
  return resumeObj ? resumeObj.time : null
}

/** Set specified video resume time */
export const setVideoResume = (videoUrl: string, time: number): void => {
  const videoResumes = getLsVideoResumes()
  const resumeObj = videoResumes.find((r) => r.videoUrl === videoUrl)
  if (resumeObj) {
    resumeObj.time = time
  } else {
    videoResumes.push({ videoUrl, time })
  }
  localStorage.setItem(KEY_VIDEO_RESUMES, JSON.stringify(videoResumes))
}
