import List from 'components/organisms/User/News/List'
import React from 'react'
import { Container } from 'reactstrap'

const News: React.FC = () => {
  return (
    <Container>
      <List />
    </Container>
  )
}

export default News
