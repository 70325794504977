import { ICategory } from 'common/interfaces/category'
import { ICreator } from 'common/interfaces/creator'
import { HeaderItemOrderType } from 'common/interfaces/header_item_order'
import { IPlaylist } from 'common/interfaces/playlist'
import { ITag } from 'common/interfaces/tag'
import {
  makeBookmarkPath,
  makeCategoryVideoPath,
  makeCreatorDetailPath,
  makeNewsPath,
  makePlaylistPath,
  makePurchasedPath,
  makeSettingPath,
  makeTagVideoPath,
  makeViewLogPath,
  makeWatchLaterPath,
} from 'common/link_path'
import { sortByContentsOrderType, sortBySortNumber } from 'common/sort_contents'
import { isTrialPlan } from 'common/utils'
import Logo from 'components/atoms/Nav/logo'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  UncontrolledDropdown,
} from 'reactstrap'
import { changeCard, signOut } from 'services/user/setting'
import { UsingTrialNavbar } from '../User/UsingTrialNavbar/UsingTrialNavbar'
import './header.scss'

const Header: React.FC = () => {
  const { t } = useTranslation('userCommon')
  const { storeCache } = useContext(AuthContext)

  const itemOrder = storeCache.team?.header_item_order

  const changeCardInfo = () => changeCard(storeCache)

  return (
    <div>
      {isTrialPlan(storeCache.subscriptionObj) && <UsingTrialNavbar />}
      <Navbar
        className="header user-header navbar-horizontal navbar-dark py-0"
        expand="lg"
      >
        <Container>
          <Logo />
          {!storeCache.user!.admin &&
            storeCache.selfSubscriptionObj &&
            storeCache.selfSubscriptionObj.subscription.status === 'unpaid' && (
              <>
                <span className="text-red">{t('header.unpaid')}</span>
                <div className="clickable-element" onClick={changeCardInfo}>
                  {t('header.updatePayment')}
                </div>
              </>
            )}

          <Nav className="flex-row" navbar>
            <NavItem>
              <NavLink to={makeNewsPath()} tag={Link} className="p-2 p-md-3">
                <i
                  className="header-avatar ni ni-bell-55"
                  style={{ top: '0px' }}
                />
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav>
              <DropdownToggle className="p-2 p-md-3" nav>
                <i className="header-avatar fas fa-bars" />
              </DropdownToggle>
              <DropdownMenu right>
                {itemOrder?.map((i, key) => (
                  <Item key={key} itemType={i.type} />
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </div>
  )
}

const Item: React.FC<{ itemType: HeaderItemOrderType }> = ({ itemType }) => {
  const { t } = useTranslation('userCommon')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  switch (itemType) {
    case HeaderItemOrderType.DIVIDER:
      return <DropdownItem divider />
    case HeaderItemOrderType.MY_ACCOUNT:
      return (
        <DropdownItem to={makeSettingPath()} tag={Link}>
          <i className="ni ni-single-02" />
          <span>{t('header.dropdownMenu.myAccount')}</span>
        </DropdownItem>
      )
    case HeaderItemOrderType.USER_VIEW_LOGS:
      return (
        <DropdownItem to={makeViewLogPath()} tag={Link}>
          <i className="ni ni-calendar-grid-58" />
          <span>{t('header.dropdownMenu.viewHistory')}</span>
        </DropdownItem>
      )
    case HeaderItemOrderType.USER_BOOKMARKS:
      return (
        <DropdownItem to={makeBookmarkPath()} tag={Link}>
          <i className="fas fa-heart" />
          <span>{t('header.dropdownMenu.bookmark')}</span>
        </DropdownItem>
      )
    case HeaderItemOrderType.USER_WATCH_LATER:
      return (
        <DropdownItem to={makeWatchLaterPath()} tag={Link}>
          <i className="fas fa-clock" />
          <span>{t('header.dropdownMenu.watchLater')}</span>
        </DropdownItem>
      )
    case HeaderItemOrderType.USER_PURCHASEDS:
      return (
        <DropdownItem to={makePurchasedPath()} tag={Link}>
          <i className="fas fa-shopping-basket" />
          <span>{t('header.dropdownMenu.rental')}</span>
        </DropdownItem>
      )
    case HeaderItemOrderType.LOGOUT:
      const logout = () => signOut(history, storeCache)
      return (
        <DropdownItem style={{ cursor: 'pointer' }} onClick={logout}>
          <i className="fas fa-sign-out-alt" />
          <span>{t('header.dropdownMenu.logout')}</span>
        </DropdownItem>
      )
    case HeaderItemOrderType.CATEGORIES:
      return listItems(
        t('header.dropdownMenu.category'),
        sortBySortNumber(storeCache.categories),
        makeCategoryVideoPath
      )
    case HeaderItemOrderType.TAGS:
      return listItems(
        t('header.dropdownMenu.tag'),
        sortBySortNumber(storeCache.tags),
        makeTagVideoPath,
        '#'
      )
    case HeaderItemOrderType.PLAYLISTS: {
      const playlistOrder = storeCache.team!.default_contents_orders.playlist
      return listItems(
        t('header.dropdownMenu.playlist'),
        sortByContentsOrderType(storeCache.playlists, playlistOrder),
        makePlaylistPath
      )
    }
    case HeaderItemOrderType.CREATORS:
      return listItems(
        t('header.dropdownMenu.creator'),
        sortBySortNumber(storeCache.creators),
        makeCreatorDetailPath
      )
  }
}

const listItems = (
  title: string,
  data: (ICategory | ITag | IPlaylist | ICreator)[],
  makeLink: (id: string) => string,
  itemNamePrefix = ''
): JSX.Element => (
  <DropdownItem toggle={false}>
    <UncontrolledDropdown direction="left">
      <DropdownToggle tag="div">{title}</DropdownToggle>
      <DropdownMenu>
        {data.map((d, i) => (
          <DropdownItem key={i} to={makeLink(d.id)} tag={Link}>
            {itemNamePrefix + d.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  </DropdownItem>
)

export default Header
