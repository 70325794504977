import { IStoreCache } from 'common/interfaces/auth_provider'
import { IInvoice } from 'common/interfaces/stripe/Invoice'
import { IPaymentIntent } from 'common/interfaces/stripe/payment_intent'
import { isLoggedIn } from 'common/utils'
import {
  getInvoices,
  getPaymentIntents,
} from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'

/**
 * 会員：サブスク商品決済履歴取得処理
 */
export const getPaidHistories = async (
  storeCache: IStoreCache,
  customerId: string,
  setData: React.Dispatch<React.SetStateAction<IInvoice[]>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    setData(await getInvoices(storeCache.team!.stripe.account_id, customerId))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * 会員：一括料金決済履歴取得処理
 */
export const getOneTimePaidHistories = async (
  storeCache: IStoreCache,
  customerId: string,
  setData: React.Dispatch<React.SetStateAction<IPaymentIntent[]>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    setData(
      await getPaymentIntents(storeCache.team!.stripe.account_id, customerId)
    )
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
