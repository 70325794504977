import firebase from 'common/firebase'
import { EffectiveDaysStatus, IFormInvite, IInvite } from './interfaces/invite'
import {
  date2Timestamp,
  datetimeLocalFormat,
  tomorrowDatetimeLocalFormat,
} from './times'

/**
 * `IFormInvite` to `IInvite`
 */
export const formInvite2Invite = (formInvite: IFormInvite): IInvite => {
  return {
    id: formInvite.id,
    admin: formInvite.admin,
    name: formInvite.name,
    count: formInvite.count_infinity ? 0 : formInvite.count,
    expire: formInvite.expire_infinity
      ? firebase.firestore.Timestamp.fromMillis(0)
      : date2Timestamp(new Date(formInvite.expire_datetime)),
    effective_days:
      formInvite.effective_days_status !== EffectiveDaysStatus.DAYS
        ? 0
        : formInvite.effective_days,
    effective_period:
      formInvite.effective_days_status !== EffectiveDaysStatus.PERIOD
        ? 0
        : formInvite.effective_period,
    join_group_ids: formInvite.join_group_ids,
    approval: formInvite.approval,
    activate_field: { name: false, phone: false },
    activate_customize_fields: false,
    comment: formInvite.comment,
    price_ids: [],
    created_at: formInvite.created_at,
    updated_at: formInvite.updated_at,
  }
}

/**
 * `IFormInvite` to `IInvite`
 */
export const formInvite2InviteByUser = (formInvite: IFormInvite): IInvite => {
  return {
    id: formInvite.id,
    admin: formInvite.admin,
    name: formInvite.name,
    count: formInvite.count_infinity ? 0 : formInvite.count,
    expire: formInvite.expire_infinity
      ? firebase.firestore.Timestamp.fromMillis(0)
      : date2Timestamp(new Date(formInvite.expire_datetime)),
    effective_days:
      formInvite.effective_days_status !== EffectiveDaysStatus.DAYS
        ? 0
        : formInvite.effective_days,
    effective_period:
      formInvite.effective_days_status !== EffectiveDaysStatus.PERIOD
        ? 0
        : formInvite.effective_period,
    join_group_ids: formInvite.join_group_ids,
    approval: formInvite.approval,
    activate_field: {
      name: formInvite.activate_name,
      phone: formInvite.activate_phone,
    },
    activate_customize_fields: formInvite.activate_customize_fields,
    comment: formInvite.comment,
    price_ids: formInvite.price_ids,
    created_at: formInvite.created_at,
    updated_at: formInvite.updated_at,
  }
}

/**
 * `IInvite` to `IFormInvite`
 */
export const invite2FormInvite = (invite: IInvite): IFormInvite => {
  return {
    ...invite,
    count_infinity: invite.count === 0,
    expire_datetime:
      invite.expire.seconds === 0
        ? tomorrowDatetimeLocalFormat()
        : datetimeLocalFormat(invite.expire),
    expire_infinity: invite.expire.seconds === 0,
    effective_days_status:
      invite.effective_days !== 0
        ? EffectiveDaysStatus.DAYS
        : invite.effective_period !== 0
        ? EffectiveDaysStatus.PERIOD
        : EffectiveDaysStatus.INFINITY,
    activate_name: invite.activate_field.name,
    activate_phone: invite.activate_field.phone,
  }
}
