import { IStoreCache } from 'common/interfaces/auth_provider'
import { defaultColors, ITeamColors } from 'common/interfaces/team'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedTeam } from 'providers/AuthProvider'
import { updateColors } from 'repositories/store/firebase_team'
import { alertService } from 'services/alert'

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  TEXT_PRIMARY,
  BACKGROUND,
  ACCENT,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  teamColors: ITeamColors,
  setTeamColors: React.Dispatch<React.SetStateAction<ITeamColors>>,
  type: InputType,
  e: React.ChangeEvent<HTMLInputElement>
) => {
  const val = e.target.value
  setTeamColors({
    ...teamColors,
    text_primary:
      type === InputType.TEXT_PRIMARY ? val : teamColors.text_primary,
    background: type === InputType.BACKGROUND ? val : teamColors.background,
    accent: type === InputType.ACCENT ? val : teamColors.accent,
  })
}

/**
 * ライトテーマ（デフォルト）に設定する
 */
export const setLightTheme = (
  setTeamColors: React.Dispatch<React.SetStateAction<ITeamColors>>
) => {
  setTeamColors({ ...defaultColors })
}

/**
 * ダークテーマに設定する
 */
export const setDarkTheme = (
  setTeamColors: React.Dispatch<React.SetStateAction<ITeamColors>>
) => {
  setTeamColors({
    text_primary: '#c9d1d9',
    background: '#0d1117',
    accent: '#238636',
  })
}

/**
 * hide theme colors
 */
export const hideThemeColors = {
  sakura: {
    text_primary: '#91a305',
    background: '#fbdaef',
    accent: '#f291e0',
  },
  chocolate: {
    text_primary: '#c9d1d9',
    background: '#3e170f',
    accent: '#6f4d4d',
  },
  nature: {
    text_primary: '#1e4d35',
    background: '#def8c4',
    accent: '#74d327',
  },
  novel: {
    text_primary: '#775408',
    background: '#e7e6d9',
    accent: '#a3c087',
  },
}

/**
 * set hide theme
 */
export const setHideTheme = (
  setTeamColors: React.Dispatch<React.SetStateAction<ITeamColors>>,
  type: 'sakura' | 'chocolate' | 'nature' | 'novel'
) => {
  const { text_primary, background, accent } = (() => {
    switch (type) {
      case 'sakura':
        return hideThemeColors.sakura
      case 'chocolate':
        return hideThemeColors.chocolate
      case 'nature':
        return hideThemeColors.nature
      case 'novel':
        return hideThemeColors.novel
    }
  })()
  setTeamColors({
    text_primary,
    background,
    accent,
  })
}

/**
 * チームレコード更新処理
 */
export const saveTeamColors = async (
  teamColors: ITeamColors,
  storeCache: IStoreCache
): Promise<void> => {
  try {
    await updateColors(storeCache.team!, teamColors)
    await reloadCachedTeam(storeCache)

    alertService.show(true, i18nAlert('updated.color'))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
