export interface IContentsOrders {
  video: ContentsOrderType
  live: ContentsOrderType
  event: ContentsOrderType
  playlist: ContentsOrderType
}

export enum ContentsOrderType {
  NEWER = 'newer',
  PUBLISH = 'publish',
  UPDATE = 'update',
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
}
