import { ColumnDef } from '@tanstack/react-table'
import { IEnquete } from 'common/interfaces/enquete'
import { IFlattedLog, ILog } from 'common/interfaces/log'
import { ITest } from 'common/interfaces/test'
import { IUser } from 'common/interfaces/user'
import {
  flattedLog,
  getAccountCreatedLog,
  getLogTableColumns,
  isLogEmpty,
} from 'common/utils_log'
import { useTanstackTableWithCsvExport } from 'components/atoms/Table/CreateTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getEnquetes } from 'services/admin/enquete'
import { getTests } from 'services/admin/test'
import { getUserLog } from 'services/admin/user_view_logs'

interface ITableBody {
  user: IUser
}

const TableBody: React.FC<ITableBody> = ({ user }: ITableBody) => {
  const { t } = useTranslation('adminUser')
  const { storeCache } = useContext(AuthContext)
  const [log, setLog] = useState<ILog | null | undefined>()
  const [enquetes, setEnquetes] = useState<IEnquete[]>([])
  const [tests, setTests] = useState<ITest[]>([])

  useEffect(() => {
    getUserLog(storeCache, user, setLog)
    getEnquetes(storeCache, setEnquetes)
    getTests(storeCache, setTests)
  }, [storeCache, user])

  if (!log || isLogEmpty(log)) return <>{t('viewHistory.noHistory')}</>

  return <Table user={user} userLog={log} enquetes={enquetes} tests={tests} />
}

const Table: React.FC<{
  user: IUser
  userLog: ILog
  enquetes: IEnquete[]
  tests: ITest[]
}> = ({ user, userLog, enquetes, tests }) => {
  const { storeCache } = useContext(AuthContext)

  const columns = useMemo<ColumnDef<IFlattedLog>[]>(
    () => getLogTableColumns(storeCache, { enquetes, tests }),
    [storeCache, enquetes, tests]
  )
  const data = useMemo<IFlattedLog[]>(
    () => [getAccountCreatedLog(user), ...flattedLog(userLog)],
    [user, userLog]
  )

  return useTanstackTableWithCsvExport<IFlattedLog>(
    columns,
    data,
    'view_logs.csv'
  )
}

export default TableBody
