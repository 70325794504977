import { ITeam } from 'common/interfaces/team'
import { IVideo } from 'common/interfaces/video'
import { storageMetadata } from 'common/utils'
import { TeamsDirRef } from './firebase_team'

const DirRef = (team: ITeam) => TeamsDirRef.child(team.id).child('videos')

/**
 * upload video image blob
 * @throws Firebase error
 */
export const upload = async (
  team: ITeam,
  video: IVideo,
  blob: Blob | undefined
): Promise<string | null> => {
  if (blob) {
    const uploadTask = await DirRef(team)
      .child(video.id)
      .put(blob, storageMetadata())
    return uploadTask.ref.getDownloadURL()
  }
  return null
}

/**
 * delete video image file
 * @throws Firebase error
 */
export const removeImageFile = async (
  team: ITeam,
  video: IVideo
): Promise<void> => {
  await DirRef(team).child(video.id).delete()
}
