import { Routes } from 'common/enums'
import {
  findCachedCategory,
  findCachedCreator,
  findCachedFilelistsNonNull,
  findCachedGroupsNonNull,
  findCachedTagsNonNull,
  getDefaultPrices,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ILive, LivePlatform } from 'common/interfaces/live'
import { VideoStatus } from 'common/interfaces/video'
import { addedTimestamp, date2Timestamp, nowTimestamp } from 'common/times'
import {
  checkFirebaseError,
  createFilelistOptions,
  createGroupOptions,
  createPriceOptions,
  createTagOptions,
  getDefaultPublicationPeriod,
  isLoggedIn,
} from 'common/utils'
import {
  createCategoryJSXOptions,
  createCreatorJSXOptions,
} from 'components/atoms/Table/CreateTable'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedLives } from 'providers/AuthProvider'
import { removeImageFile, upload } from 'repositories/storage/firebase_live'
import { removeImage, store, update } from 'repositories/store/firebase_live'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/live_form'
import { v4 as uuidv4 } from 'uuid'

/**
 * `ILive`の初期値を返却する
 */
export const initLive = (): ILive => {
  return {
    id: uuidv4(),
    name: '',
    image: null,
    overview: '',
    category_id: '',
    tag_ids: [],
    filelist_ids: [],
    creator_id: '',
    status: VideoStatus.PRIVATE,
    is_list_hidden: false,
    group_ids: [],
    price_ids: [],
    publication_period: null,
    hold_from: nowTimestamp(),
    hold_to: addedTimestamp(2, 'hour'),
    platform: LivePlatform.ZOOM_MEETING,
    meeting_number: '',
    meeting_password: '',
    youtube_video_id: '',
    vimeo_id: '',
    is_public: false,
    is_registered: false,
    created_at: nowTimestamp(),
    updated_at: nowTimestamp(),
  }
}

/**
 * 配信のデフォルト値を返却する
 */
export const getLiveDefaults = (storeCache: IStoreCache, live: ILive) => {
  return {
    defaultCategory: findCachedCategory(storeCache, live.category_id),
    defaultCreator: findCachedCreator(storeCache, live.creator_id),
    defaultTags: findCachedTagsNonNull(storeCache, live.tag_ids).map((tag) => ({
      value: tag.id,
      label: tag.name,
    })),
    defaultFilelists: findCachedFilelistsNonNull(
      storeCache,
      live.filelist_ids
    ).map((filelist) => ({ value: filelist.id, label: filelist.name })),
    defaultGroups: findCachedGroupsNonNull(storeCache, live.group_ids).map(
      (group) => ({ value: group.id, label: group.name })
    ),
    defaultPrices: getDefaultPrices(storeCache, live),
  }
}

/**
 * Input/Selectで使用するoptionsを返却する
 */
export const getFormOptions = (storeCache: IStoreCache) => {
  return {
    tagOptions: createTagOptions(storeCache.tags),
    filelistOptions: createFilelistOptions(storeCache.filelists),
    categoryOptions: createCategoryJSXOptions(storeCache.categories),
    creatorOptions: createCreatorJSXOptions(storeCache.creators),
    groupOptions: createGroupOptions(storeCache.groups),
    priceOptions: createPriceOptions(storeCache.prices),
  }
}

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
  PLATFORM,
  MEETING_NUMBER,
  MEETING_PASSWORD,
  YOUTUBE_VIDEO_ID,
  VIMEO_ID,
  HOLD_FROM,
  HOLD_TO,
  CATEGORY_ID,
  TAG_IDS,
  FILELIST_IDS,
  OVERVIEW,
  CREATOR_ID,
  IS_LIST_HIDDEN,
  GROUP_IDS,
  PRICE_IDS,
}

/**
 * Zoom meeting id to number
 * @param oldValue Old meeting id
 * @param newValue New meeting id
 * @returns Zoom meeting id number
 */
const zoomMeetingId2number = (oldValue: string, newValue: string): string => {
  newValue = newValue.replace(/\s+/g, '')

  if (/[０-９]/g.test(newValue)) {
    return newValue.replace(/[０-９]/g, (m) =>
      '０１２３４５６７８９'.indexOf(m).toString()
    )
  }

  return Number.isNaN(Number(newValue)) ? oldValue : newValue
}

/**
 * YouTube URL/ID to VideoID
 */
const ytUrl2videoId = (str: string): string => {
  const ytVideoReg =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const ytStudioReg = /^.*(youtube.com\/video\/)([^#&?]*)?\/.*/
  return str.match(ytVideoReg)?.[2] || str.match(ytStudioReg)?.[2] || str
}

/**
 * Vimeo URL/ID to ID
 */
const vimeoUrl2Id = (str: string): string => {
  const vimeoReg =
    /vimeo\.com\/(?:manage\/)?((?:videos?|events?|webinars\/events|webinars)\/)?((?:\d+|[\w-]+)+)/
  return str.match(vimeoReg)?.[2] || str
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  live: ILive,
  setLive: React.Dispatch<React.SetStateAction<ILive>>,
  type: InputType,
  e: any
) => {
  const val = e.target?.value
  setLive({
    ...live,
    name: type === InputType.NAME ? val : live.name,
    platform: type === InputType.PLATFORM ? val : live.platform,
    meeting_number:
      type === InputType.MEETING_NUMBER
        ? zoomMeetingId2number(live.meeting_number, val)
        : live.meeting_number,
    meeting_password:
      type === InputType.MEETING_PASSWORD ? val : live.meeting_password,
    youtube_video_id:
      type === InputType.YOUTUBE_VIDEO_ID
        ? ytUrl2videoId(val)
        : live.youtube_video_id,
    vimeo_id: type === InputType.VIMEO_ID ? vimeoUrl2Id(val) : live.vimeo_id,
    hold_from:
      type === InputType.HOLD_FROM
        ? date2Timestamp(new Date(val))
        : live.hold_from,
    hold_to:
      type === InputType.HOLD_TO ? date2Timestamp(new Date(val)) : live.hold_to,
    category_id: type === InputType.CATEGORY_ID ? val : live.category_id,
    tag_ids:
      type === InputType.TAG_IDS ? e.map((x: any) => x.value) : live.tag_ids,
    filelist_ids:
      type === InputType.FILELIST_IDS
        ? e.map((x: any) => x.value)
        : live.filelist_ids,
    overview: type === InputType.OVERVIEW ? e : live.overview,
    creator_id: type === InputType.CREATOR_ID ? val : live.creator_id,
    is_list_hidden:
      type === InputType.IS_LIST_HIDDEN
        ? e.target.checked
        : live.is_list_hidden,
    group_ids:
      type === InputType.GROUP_IDS
        ? e.map((x: any) => x.value)
        : live.group_ids,
    price_ids:
      type === InputType.PRICE_IDS
        ? e.map((x: any) => x.value)
        : live.price_ids,
  })
}

/**
 * 公開/非公開Inputの`onChange`イベントハンドラ
 */
export const onChangeStatus = (
  live: ILive,
  setLive: React.Dispatch<React.SetStateAction<ILive>>,
  e: React.ChangeEvent<HTMLInputElement>
) => {
  const val = Number(e.target.value)
  const period: ILive['publication_period'] = (() => {
    switch (val) {
      case VideoStatus.PUBLIC:
        return { start: nowTimestamp(), end: null }
      case VideoStatus.PUBLICATION_PERIOD:
        return getDefaultPublicationPeriod(live)
      default:
        return null
    }
  })()
  setLive({
    ...live,
    status: val,
    publication_period: period,
  })
}

/**
 * 公開日/公開期限Inputの`onChange`イベントハンドラ
 */
export const onChangeLimitedAccess = (
  live: ILive,
  setLive: React.Dispatch<React.SetStateAction<ILive>>,
  type: 'start' | 'end',
  e: React.ChangeEvent<HTMLInputElement>
) => {
  const date = date2Timestamp(new Date(e.target.value))
  setLive({
    ...live,
    publication_period: {
      start: type === 'start' ? date : live.publication_period!.start,
      end: type === 'end' ? date : live.publication_period!.end,
    },
  })
}

/**
 * 配信を未ログインでも表示するチェックボックスの`onChange`イベントハンドラ
 */
export const onChangeIsPublic = (
  live: ILive,
  setLive: React.Dispatch<React.SetStateAction<ILive>>,
  e: React.ChangeEvent<HTMLInputElement>
) => setLive({ ...live, is_public: e.target.checked, group_ids: [] })

/**
 * 再生制限を会員登録必須 or 必須でないにするラジオボタンの`onChange`イベントハンドラ
 */
export const onChangeIsRegistered = (
  live: ILive,
  setLive: React.Dispatch<React.SetStateAction<ILive>>,
  { target }: React.ChangeEvent<HTMLInputElement>
) => setLive({ ...live, is_registered: target.value === 'true' })

/**
 * 配信レコード作成/更新処理
 */
export const saveLive = async (
  { push }: History,
  live: ILive,
  imageBlob: Blob | undefined,
  isImageDelete: boolean,
  isImageCropped: boolean,
  storeCache: IStoreCache,
  isCreate: boolean,
  isLimitPlayActive: boolean
) => {
  if (!isLoggedIn(storeCache)) return

  try {
    validateForm(live, isImageCropped)

    if (imageBlob && !isImageDelete) {
      live.image = await upload(storeCache.team!, live, imageBlob)
    }
    if (!isCreate && isImageDelete) {
      await deleteImage(live, storeCache)
    }
    if (live.platform === LivePlatform.YOUTUBE || !isLimitPlayActive) {
      live.is_registered = false
      live.price_ids = []
    }
    if (live.is_registered) live.price_ids = []

    if (isCreate) {
      live.created_at = nowTimestamp()
      live.updated_at = nowTimestamp()
      await store(storeCache.team!, live)
    } else {
      await update(storeCache.team!, live)
    }

    await reloadCachedLives(storeCache)

    push(Routes.AdminLive)
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}

/**
 * 配信の画像を削除する(ドキュメント更新 & ファイル削除)
 */
const deleteImage = async (
  live: ILive,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    await removeImageFile(storeCache.team!, live)
    await removeImage(storeCache.team!, live)

    alertService.show(true, i18nAlert('deleted.thumbnail'))
  } catch (error) {
    console.log(error)
    alertService.show(false, i18nAlert('deleted.fail.thumbnail'))
  }
}
