import { Routes } from 'common/enums'
import { IUser } from 'common/interfaces/user'
import ButtonRight from 'components/atoms/Button/Right'
import AuthInfo from 'components/atoms/Head/AuthInfo'
import H1 from 'components/atoms/Head/H1'
import H2 from 'components/atoms/Head/H2'
import LinkBody from 'components/organisms/Admin/UserPaidHistory/LinkBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'

const UserPaidHistory: React.FC = () => {
  const { t } = useTranslation('adminUser')
  const { team } = useContext(AuthContext).storeCache
  const { state } = useLocation<IUser>()

  return (
    <>
      <H1 title={t('paidHistory.title')} />
      <H2 title={state.name ?? ''} />
      <AuthInfo team={team!} user={state} />

      <Container fluid>
        <ButtonRight nextPage={Routes.AdminUser} content={t('backToList')} />
        <LinkBody user={state} />
      </Container>
    </>
  )
}

export default UserPaidHistory
