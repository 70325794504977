import { IStoreCache } from 'common/interfaces/auth_provider'
import { ITeam } from 'common/interfaces/team'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchSubscriptionObj } from 'repositories/functions/functions_stripe'
import { get as getCategories } from 'repositories/store/firebase_category'
import { get as getCreators } from 'repositories/store/firebase_creator'
import { getForNonAuthUser as getForNonAuthUserEvents } from 'repositories/store/firebase_event'
import { getForNonAuthUser as getForNonAuthUserLives } from 'repositories/store/firebase_live'
import { getForNonAuthUser as getForNonAuthUserPlaylists } from 'repositories/store/firebase_playlist'
import { get as getTags } from 'repositories/store/firebase_tag'
import { findById } from 'repositories/store/firebase_team'
import { getForNonAuthUser as getForNonAuthUserVideos } from 'repositories/store/firebase_video'
import { AuthContext } from './AuthProvider'

export const NonAuthProvider = ({
  children,
}: {
  children: any
}): JSX.Element => {
  const [storeCache, setStoreCache] = useState<IStoreCache>({
    isLoading: true,
    isSingleLogin: false,
    isIpAllowed: false,
    isAnswered: false,
    setStoreCache: null,
    team: null,
    user: null,
    subscriptionObj: null,
    selfSubscriptionObj: null,
    videos: [],
    lives: [],
    events: [],
    creators: [],
    categories: [],
    tags: [],
    playlists: [],
    filelists: [],
    newslists: [],
    groups: [],
    prices: [],
  })

  const { teamId } = useParams<{ teamId: string }>()

  useEffect(() => {
    ;(async () => {
      const team = await findById({ id: teamId } as ITeam)
      const isTeamPublic = team !== null && team.is_site_public

      const [
        videos,
        creators,
        categories,
        tags,
        playlists,
        lives,
        events,
        subscriptionObj,
      ] = await Promise.all([
        isTeamPublic ? getForNonAuthUserVideos(team!) : [],
        isTeamPublic ? getCreators(team!) : [],
        isTeamPublic ? getCategories(team!) : [],
        isTeamPublic ? getTags(team!) : [],
        isTeamPublic ? getForNonAuthUserPlaylists(team!) : [],
        isTeamPublic ? getForNonAuthUserLives(team!) : [],
        isTeamPublic ? getForNonAuthUserEvents(team!) : [],
        isTeamPublic ? fetchSubscriptionObj(undefined, team.stripeId) : null,
      ])
      setStoreCache({
        isLoading: false,
        isSingleLogin: false,
        isIpAllowed: false,
        isAnswered: false,
        setStoreCache: null,
        team,
        user: null,
        subscriptionObj,
        selfSubscriptionObj: null,
        videos,
        lives,
        events,
        creators,
        categories,
        tags,
        playlists,
        filelists: [],
        newslists: [],
        groups: [],
        prices: [],
      })
    })()
  }, [teamId])

  return (
    <AuthContext.Provider value={{ currentUser: null, storeCache }}>
      {children}
    </AuthContext.Provider>
  )
}
