import classNames from 'classnames'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IInvoice } from 'common/interfaces/stripe/Invoice'
import { IPaymentIntent } from 'common/interfaces/stripe/payment_intent'
import { formatUNIXToDate } from 'common/times'
import { formatPrice } from 'common/utils'
import PaidList from 'components/molecules/User/PaidHistory/PaidList'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  ListGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import {
  getOneTimePaidHistories,
  getPaidHistories,
} from 'services/admin/user_paid_history'
import './tabbody.scss'

type TTabBody = {
  storeCache: IStoreCache
}

const TabBody: React.FC<TTabBody> = ({ storeCache }: TTabBody) => {
  const { t } = useTranslation('userSubscription')
  const customerId = storeCache.user!.customer_id!

  const [invoices, setInvoices] = useState<IInvoice[]>([])
  const [paymentIntents, setPaymentIntents] = useState<IPaymentIntent[]>([])
  const [activeTab, setActiveTab] = useState<'subscription' | 'oneTime'>(
    'subscription'
  )

  useEffect(() => {
    ;(async () => {
      await Promise.all([
        getPaidHistories(storeCache, customerId, setInvoices),
        getOneTimePaidHistories(storeCache, customerId, setPaymentIntents),
      ])
    })()
  }, [storeCache, customerId])

  return (
    <Container className="user-paid-history">
      <h1 className="title">{t('paidHistory.tabBody.paidHistory')}</h1>
      <Nav tabs className="tabs">
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === 'subscription' })}
            onClick={() => setActiveTab('subscription')}
          >
            {t('paidHistory.tabBody.subscriptionItem')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === 'oneTime' })}
            onClick={() => setActiveTab('oneTime')}
          >
            {t('paidHistory.tabBody.sumPrice')}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="subscription">
          <ListGroup>
            {invoices.map((i: IInvoice, index: number) => (
              <PaidList
                index={index}
                col_1={i.lines.data[0].price.nickname}
                col_2={formatPrice(i.total, i.currency)}
                col_3={formatUNIXToDate(i.created)}
                col_4={
                  <a href={i.invoice_pdf}>
                    {t('paidHistory.tabBody.downloadInvoice')}
                  </a>
                }
              />
            ))}
          </ListGroup>
        </TabPane>
        <TabPane tabId="oneTime">
          <ListGroup>
            {paymentIntents.map((p: IPaymentIntent, index: number) => (
              <PaidList
                index={index}
                col_1={p.nickname}
                col_2={formatPrice(p.amount, p.currency)}
                col_3={formatUNIXToDate(p.created)}
                col_4={
                  <a href={p.charges.data[0].receipt_url} target="_brank">
                    {t('paidHistory.tabBody.openReceipt')}
                  </a>
                }
              />
            ))}
          </ListGroup>
        </TabPane>
      </TabContent>
    </Container>
  )
}

export default TabBody
