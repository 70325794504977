import { IStoreCache } from 'common/interfaces/auth_provider'
import { ILog } from 'common/interfaces/log'
import { IUser } from 'common/interfaces/user'
import { isLoggedIn } from 'common/utils'
import { get } from 'repositories/store/firebase_log'

/**
 * 特定のユーザーのログを取得する
 */
export const getUserLog = async (
  storeCache: IStoreCache,
  user: IUser,
  setUserLog: React.Dispatch<React.SetStateAction<ILog | null | undefined>>
) => {
  if (!isLoggedIn(storeCache)) return

  const logs = await get(storeCache.team!, user)
  setUserLog(logs)
}
