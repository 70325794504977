import { EntryType, Routes, ViewType } from 'common/enums'
import { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { makeEntryPath, makeUserRootPath } from 'common/link_path'
import AnyTeamItemFormModal from 'components/atoms/Alert/AnyTeamItemFormModal'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import Header from 'components/molecules/Admin/Header'
import Sidebar from 'components/molecules/Admin/Sidebar'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import ChannelService from 'services/admin/ChannelService'
import { AnyTeamItemFormModalService } from 'services/any_team_item_form_modal'
import {
  cancelledTeam,
  checkDisabled,
  disabledUser,
  restrictedIp,
} from 'services/auth'
import { isNotTrialAndNotSubscribe } from 'services/subscribe'
import AdminEmailLogShow from 'template/admin/AdminEmailLogShow'
import Administrator from 'template/admin/Administrator'
import AdministratorInviteForm from 'template/admin/AdministratorInviteForm'
import AdminSetting from 'template/admin/AdminSetting'
import AdminSettingZoomSetting from 'template/admin/AdminSettingZoomSetting'
import AdminSettingZoomSettingAuthorized from 'template/admin/AdminSettingZoomSettingAuthorized'
import Analytics from 'template/admin/Analytics'
import AnalyticsLiveUsers from 'template/admin/AnalyticsLiveUsers'
import AnalyticsLogUser from 'template/admin/AnalyticsLogUser'
import AnalyticsLogUserVideos from 'template/admin/AnalyticsLogUserVideos'
import AnalyticsTotal from 'template/admin/AnalyticsTotal'
import AnalyticsUsers from 'template/admin/AnalyticsUsers'
import AnalyticsVideos from 'template/admin/AnalyticsVideos'
import AnalyticsVideoUsers from 'template/admin/AnalyticsVideoUsers'
import AnalyticsWatchLogUser from 'template/admin/AnalyticsWatchLogUser'
import AnalyticsWatchLogUserVideos from 'template/admin/AnalyticsWatchLogUserVideos'
import ApprovalPendingAllUsers from 'template/admin/ApprovalPendingAllUsers'
import ApprovalPendingUser from 'template/admin/ApprovalPendingUser'
import BasicInfo from 'template/admin/BasicInfo'
import Billing from 'template/admin/Billing'
import Category from 'template/admin/Category'
import CategoryForm from 'template/admin/CategoryForm'
import Comment from 'template/admin/Comment'
import CommentReply from 'template/admin/CommentReply'
import ContractingPlan from 'template/admin/ContractingPlan'
import Creator from 'template/admin/Creator'
import CreatorForm from 'template/admin/CreatorForm'
import EmailLog from 'template/admin/EmailLog'
import Enquete from 'template/admin/Enquete'
import EnqueteAnswer from 'template/admin/EnqueteAnswer'
import EnqueteCustomize from 'template/admin/EnqueteCustomize'
import Event from 'template/admin/Event'
import EventForm from 'template/admin/EventForm'
import FieldCustomize from 'template/admin/FieldCustomize'
import Filelist from 'template/admin/Filelist'
import FilelistCreate from 'template/admin/FilelistCreate'
import Group from 'template/admin/Group'
import GroupForm from 'template/admin/GroupForm'
import Invite from 'template/admin/Invite'
import InvitedUser from 'template/admin/InvitedUser'
import InviteMail from 'template/admin/InviteMail'
import Live from 'template/admin/Live'
import LiveForm from 'template/admin/LiveForm'
import MailTemplate from 'template/admin/MailTemplate'
import MailTemplateForm from 'template/admin/MailTemplateForm'
import News from 'template/admin/News'
import NewsForm from 'template/admin/NewsForm'
import PaidHistory from 'template/admin/PaidHistory'
import Payment from 'template/admin/Payment'
import Playlist from 'template/admin/Playlist'
import PlaylistForm from 'template/admin/PlaylistForm'
import SendEmail from 'template/admin/SendEmail'
import SendEmailForm from 'template/admin/SendEmailForm'
import SiteCustomize from 'template/admin/SiteCustomize'
import SiteCustomizeColor from 'template/admin/SiteCustomizeColor'
import SiteCustomizeHeaderItemOrder from 'template/admin/SiteCustomizeHeaderItemOrder'
import SiteCustomizeImage from 'template/admin/SiteCustomizeImage'
import SiteCustomizeLp from 'template/admin/SiteCustomizeLp'
import SiteCustomizeTopItemOrder from 'template/admin/SiteCustomizeTopItemOrder'
import Stripe from 'template/admin/Stripe'
import StripeCoupon from 'template/admin/StripeCoupon'
import StripeCouponForm from 'template/admin/StripeCouponForm'
import StripePrice from 'template/admin/StripePrice'
import StripePriceForm from 'template/admin/StripePriceForm'
import StripeProduct from 'template/admin/StripeProduct'
import StripeProductForm from 'template/admin/StripeProductForm'
import StripeTaxRate from 'template/admin/StripeTaxRate'
import StripeTaxRateForm from 'template/admin/StripeTaxRateForm'
import Tag from 'template/admin/Tag'
import TagForm from 'template/admin/TagForm'
import Test from 'template/admin/Test'
import TestAnswer from 'template/admin/TestAnswer'
import TestAnswerDetail from 'template/admin/TestAnswerDetail'
import TestForm from 'template/admin/TestForm'
import User from 'template/admin/User'
import UserEdit from 'template/admin/UserEdit'
import UserImport from 'template/admin/UserImport'
import UserInviteForm from 'template/admin/UserInviteForm'
import UserOneTimePaidHistory from 'template/admin/UserOneTimePaidHistory'
import UserPaidHistory from 'template/admin/UserPaidHistory'
import UserSubscriptionPaidHistory from 'template/admin/UserSubscriptionPaidHistory'
import UserViewLogs from 'template/admin/UserViewLogs'
import Video from 'template/admin/Video'
import VideoForm from 'template/admin/VideoForm'

const Admin: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const [channelIoBooted, setChannelIoBooted] = useState<boolean>(false)
  const [viewType, setViewType] = useState<ViewType>(ViewType.LOADING)

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (user === null) {
        setViewType(ViewType.LOGIN)
      } else if (isLoading(storeCache)) {
        setViewType(ViewType.LOADING)
      } else if (checkDisabled(storeCache)) {
        setViewType(ViewType.DISABLED_USER)
      } else if (!storeCache.isIpAllowed) {
        setViewType(ViewType.RESTRICTED_IP)
      } else if (isNotTrialAndNotSubscribe(storeCache)) {
        storeCache.user?.admin
          ? setViewType(ViewType.NOTSUBSCRIBE)
          : setViewType(ViewType.ENTRY)
      } else if (storeCache.user?.is_approved === true) {
        setViewType(ViewType.UNAPPROVED)
      } else if (storeCache.team?.cancelled_date) {
        setViewType(ViewType.CANCELLED)
      } else if (storeCache.user?.admin ?? false) {
        setViewType(ViewType.VISIBLE)
      } else {
        setViewType(ViewType.GONE)
      }
    })
  }, [setViewType, storeCache])

  useEffect(() => {
    if (viewType === ViewType.VISIBLE && !storeCache.team?.company) {
      AnyTeamItemFormModalService.show()
    }
  }, [viewType, storeCache])

  switch (viewType) {
    case ViewType.NOTPAID:
    case ViewType.UPDATE_FAILURE:
    case ViewType.ANSWER_TO_ENQUETE:
    case ViewType.VISIBLE:
      if (!channelIoBooted) {
        ChannelService.boot({
          pluginKey: '6408a396-439f-4eaa-880b-69df9e9d4b31',
          profile: {
            firebaseTeamId: storeCache.team?.id ?? 'null',
            firebaseUserId: storeCache.user?.id ?? 'null',
            firebaseUserEmail: storeCache.user?.email ?? 'null',
            firebaseUserName: storeCache.user?.name ?? 'null',
            firebaseSiteName: storeCache.team?.name ?? 'null',
            stripeId: storeCache.team?.stripeId ?? 'null',
          },
        })
        setChannelIoBooted(true)
      }
      break
    default:
      if (channelIoBooted) {
        ChannelService.shutdown()
        setChannelIoBooted(false)
      }
      break
  }

  const history = useHistory()

  switch (viewType) {
    case ViewType.LOGIN:
      history.replace(Routes.LoginAdmin)
      return null
    case ViewType.LOADING:
      return <FullLoadingSpinner />
    case ViewType.GONE:
      history.replace(makeUserRootPath(storeCache.team?.id))
      return null
    case ViewType.DISABLED_USER:
      disabledUser(history, storeCache)
      return null
    case ViewType.RESTRICTED_IP:
      restrictedIp(history, storeCache)
      return null
    case ViewType.NOTSUBSCRIBE: {
      const path = makeEntryPath(storeCache.team?.id, storeCache.user?.admin)
      history.replace(path, { type: EntryType.NOT_SUBSCRIBE })
      return null
    }
    case ViewType.UNAPPROVED:
      history.replace(Routes.Unapproved)
      return null
    case ViewType.CANCELLED:
      cancelledTeam(history, storeCache)
      return null
    case ViewType.ENTRY: {
      const path = makeEntryPath(storeCache.team?.id, storeCache.user?.admin)
      history.replace(path, { type: EntryType.NOT_TEAM })
      return null
    }
    case ViewType.RESTRICTED_SINGLE_LOGIN:
    case ViewType.NOTPAID:
    case ViewType.UPDATE_FAILURE:
    case ViewType.ANSWER_TO_ENQUETE:
    case ViewType.CHANGE_PASSWORD:
    case ViewType.VISIBLE:
      return adminRouteSwitch()
  }
}

const isLoading = (storeCache: IStoreCache) =>
  storeCache.user === null || storeCache.isLoading

const adminRouteSwitch = (): JSX.Element => (
  <>
    <Sidebar />

    <div className="main-content">
      <Header />

      <Switch>
        <Route path={Routes.AdminVideo} component={() => <Video />} exact />
        <Route
          path={[
            Routes.AdminVideoCreate,
            Routes.AdminVideoReCreate,
            Routes.AdminVideoEdit,
          ]}
          component={() => <VideoForm />}
          exact
        />
        <Route
          path={Routes.AdminPlaylist}
          component={() => <Playlist />}
          exact
        />
        <Route
          path={[Routes.AdminPlaylistCreate, Routes.AdminPlaylistEdit]}
          component={() => <PlaylistForm />}
          exact
        />
        <Route path={Routes.AdminLive} component={() => <Live />} exact />
        <Route
          path={[Routes.AdminLiveCreate, Routes.AdminLiveEdit]}
          component={() => <LiveForm />}
          exact
        />
        <Route path={Routes.AdminEvent} component={() => <Event />} exact />
        <Route
          path={[Routes.AdminEventCreate, Routes.AdminEventEdit]}
          component={() => <EventForm />}
          exact
        />
        <Route
          path={Routes.AdminFilelist}
          component={() => <Filelist />}
          exact
        />
        <Route
          path={Routes.AdminFilelistCreate}
          component={() => <FilelistCreate />}
          exact
        />
        <Route path={Routes.AdminNews} component={() => <News />} exact />
        <Route
          path={[Routes.AdminNewsCreate, Routes.AdminNewsEdit]}
          component={() => <NewsForm />}
          exact
        />
        <Route
          path={Routes.AdminMailTemplate}
          component={() => <MailTemplate />}
          exact
        />
        <Route
          path={Routes.AdminMailTemplateEdit}
          component={() => <MailTemplateForm />}
          exact
        />
        <Route path={Routes.AdminNews} component={() => <News />} exact />
        <Route
          path={[Routes.AdminNewsCreate, Routes.AdminNewsEdit]}
          component={() => <NewsForm />}
          exact
        />
        <Route
          path={Routes.AdminSendEmail}
          component={() => <SendEmail />}
          exact
        />
        <Route
          path={[Routes.AdminSendEmailCreate, Routes.AdminSendEmailEdit]}
          component={() => <SendEmailForm />}
          exact
        />
        <Route path={Routes.AdminEnquete} component={() => <Enquete />} exact />
        <Route
          path={[Routes.AdminEnqueteCreate, Routes.AdminEnqueteEdit]}
          component={() => <EnqueteCustomize />}
          exact
        />
        <Route
          path={Routes.AdminEnqueteAnswer}
          component={() => <EnqueteAnswer />}
          exact
        />
        <Route path={Routes.AdminTest} component={() => <Test />} exact />
        <Route
          path={[Routes.AdminTestCreate, Routes.AdminTestEdit]}
          component={() => <TestForm />}
          exact
        />
        <Route
          path={Routes.AdminTestAnswer}
          component={() => <TestAnswer />}
          exact
        />
        <Route
          path={Routes.AdminTestAnswerDetail}
          component={() => <TestAnswerDetail />}
          exact
        />
        <Route path={Routes.AdminComment} component={() => <Comment />} exact />
        <Route
          path={Routes.AdminCommentReply}
          component={() => <CommentReply />}
          exact
        />
        <Route
          path={Routes.AdminAnalytics}
          component={() => <Analytics />}
          exact
        />
        <Route
          path={Routes.AdminAnalyticsTotal}
          component={() => <AnalyticsTotal />}
          exact
        />
        <Route
          path={Routes.AdminAnalyticsVideos}
          component={() => <AnalyticsVideos />}
          exact
        />
        <Route
          path={Routes.AdminAnalyticsUsers}
          component={() => <AnalyticsUsers />}
          exact
        />
        <Route
          path={Routes.AdminAnalyticsVideoUsers}
          component={() => <AnalyticsVideoUsers />}
          exact
        />
        <Route
          path={Routes.AdminAnalyticsLiveUsers}
          component={() => <AnalyticsLiveUsers />}
          exact
        />
        <Route
          path={Routes.AdminAnalyticsLogUser}
          component={() => <AnalyticsLogUser />}
          exact
        />
        <Route
          path={Routes.AdminAnalyticsLogUserVideos}
          component={() => <AnalyticsLogUserVideos />}
          exact
        />
        <Route
          path={Routes.AdminAnalyticsWatchLogUser}
          component={() => <AnalyticsWatchLogUser />}
          exact
        />
        <Route
          path={Routes.AdminAnalyticsWatchLogUserVideos}
          component={() => <AnalyticsWatchLogUserVideos />}
          exact
        />
        <Route
          path={Routes.AdminSiteCustomize}
          component={() => <SiteCustomize />}
          exact
        />
        <Route
          path={Routes.AdminSiteCustomizeLp}
          component={() => <SiteCustomizeLp />}
          exact
        />
        <Route
          path={Routes.AdminSiteCustomizeImage}
          component={() => <SiteCustomizeImage />}
          exact
        />
        <Route
          path={Routes.AdminSiteCustomizeHeaderItemOrder}
          component={() => <SiteCustomizeHeaderItemOrder />}
          exact
        />
        <Route
          path={Routes.AdminSiteCustomizeTopItemOrder}
          component={() => <SiteCustomizeTopItemOrder />}
          exact
        />
        <Route
          path={Routes.AdminSiteCustomizeColor}
          component={() => <SiteCustomizeColor />}
          exact
        />
        <Route path={Routes.AdminUser} component={() => <User />} exact />
        <Route
          path={Routes.AdminUserImport}
          component={() => <UserImport />}
          exact
        />
        <Route
          path={[Routes.AdminUserInviteCreate, Routes.AdminUserInviteEdit]}
          component={() => <UserInviteForm />}
          exact
        />
        <Route
          path={Routes.AdminUserViewLog}
          component={() => <UserViewLogs />}
          exact
        />
        <Route
          path={Routes.AdminUserPaidHistory}
          component={() => <UserPaidHistory />}
          exact
        />
        <Route
          path={Routes.AdminUserSubscriptionPaidHistory}
          component={() => <UserSubscriptionPaidHistory />}
          exact
        />
        <Route
          path={Routes.AdminUserOneTimePaidHistory}
          component={() => <UserOneTimePaidHistory />}
          exact
        />
        <Route
          path={Routes.AdminUserEdit}
          component={() => <UserEdit />}
          exact
        />
        <Route path={Routes.AdminGroup} component={() => <Group />} exact />
        <Route
          path={[Routes.AdminGroupCreate, Routes.AdminGroupEdit]}
          component={() => <GroupForm />}
          exact
        />
        <Route
          path={Routes.AdminAdministrator}
          component={() => <Administrator />}
          exact
        />
        <Route
          path={[
            Routes.AdminAdministratorInviteCreate,
            Routes.AdminAdministratorInviteEdit,
          ]}
          component={() => <AdministratorInviteForm />}
          exact
        />
        <Route path={Routes.AdminInvite} component={() => <Invite />} exact />
        <Route
          path={Routes.AdminInviteMail}
          component={() => <InviteMail />}
          exact
        />
        <Route
          path={[Routes.AdminInviteEmailLog, Routes.AdminSendEmailLog]}
          component={() => <EmailLog />}
          exact
        />
        <Route
          path={Routes.AdminApprovalPendingUser}
          component={() => <ApprovalPendingUser />}
          exact
        />
        <Route
          path={Routes.AdminApprovalPendingAllUsers}
          component={() => <ApprovalPendingAllUsers />}
          exact
        />
        <Route
          path={Routes.AdminInvitedUser}
          component={() => <InvitedUser />}
          exact
        />
        <Route
          path={Routes.AdminFieldCustomize}
          component={() => <FieldCustomize />}
          exact
        />
        <Route
          path={Routes.AdminCategory}
          component={() => <Category />}
          exact
        />
        <Route
          path={[Routes.AdminCategoryCreate, Routes.AdminCategoryEdit]}
          component={() => <CategoryForm />}
          exact
        />
        <Route path={Routes.AdminTag} component={() => <Tag />} exact />
        <Route
          path={[Routes.AdminTagCreate, Routes.AdminTagEdit]}
          component={() => <TagForm />}
          exact
        />
        <Route path={Routes.AdminCreator} component={() => <Creator />} exact />
        <Route
          path={[Routes.AdminCreatorCreate, Routes.AdminCreatorEdit]}
          component={() => <CreatorForm />}
          exact
        />
        <Route path={Routes.AdminPayment} component={() => <Payment />} exact />
        <Route path={Routes.AdminBilling} component={() => <Billing />} exact />
        <Route path={Routes.AdminStripe} component={() => <Stripe />} exact />
        <Route
          path={Routes.AdminStripeProduct}
          component={() => <StripeProduct />}
          exact
        />
        <Route
          path={[
            Routes.AdminStripeProductCreate,
            Routes.AdminStripeProductEdit,
          ]}
          component={() => <StripeProductForm />}
          exact
        />
        <Route
          path={Routes.AdminStripePrice}
          component={() => <StripePrice />}
          exact
        />
        <Route
          path={[Routes.AdminStripePriceCreate, Routes.AdminStripePriceEdit]}
          component={() => <StripePriceForm />}
          exact
        />
        <Route
          path={Routes.AdminStripeCoupon}
          component={() => <StripeCoupon />}
          exact
        />
        <Route
          path={[Routes.AdminStripeCouponCreate]}
          component={() => <StripeCouponForm />}
          exact
        />
        <Route
          path={Routes.AdminStripeTaxRate}
          component={() => <StripeTaxRate />}
          exact
        />
        <Route
          path={[
            Routes.AdminStripeTaxRateCreate,
            Routes.AdminStripeTaxRateEdit,
          ]}
          component={() => <StripeTaxRateForm />}
          exact
        />
        <Route
          path={Routes.AdminBasicInfo}
          component={() => <BasicInfo />}
          exact
        />
        <Route
          path={Routes.AdminContractingPlan}
          component={() => <ContractingPlan />}
          exact
        />
        <Route
          path={Routes.AdminPaidHistory}
          component={() => <PaidHistory />}
          exact
        />
        <Route
          path={Routes.AdminSetting}
          component={() => <AdminSetting />}
          exact
        />
        <Route
          path={Routes.AdminSettingZoomSetting}
          component={() => <AdminSettingZoomSetting />}
          exact
        />
        <Route
          path={Routes.AdminSettingZoomSettingAuthorized}
          component={() => <AdminSettingZoomSettingAuthorized />}
          exact
        />
        <Route
          path={Routes.AdminEmailLogShow}
          component={() => <AdminEmailLogShow />}
          exact
        />
        <Redirect from={Routes.Admin} to={Routes.AdminVideo} />
      </Switch>
    </div>
    <AnyTeamItemFormModal />
  </>
)

export default Admin
