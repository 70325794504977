import { Routes } from 'common/enums'
import { IUser } from 'common/interfaces/user'
import { goBackWithErrorAlert } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import AuthInfo from 'components/atoms/Head/AuthInfo'
import H1 from 'components/atoms/Head/H1'
import H2 from 'components/atoms/Head/H2'
import TableBody from 'components/organisms/Admin/UserSubscriptionPaidHistory/TableBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { Container } from 'reactstrap'

const UserSubscriptionPaidHistory: React.FC = () => {
  const { t } = useTranslation('adminUser')
  const { t: i18nAlert } = useTranslation('alert')
  const { team } = useContext(AuthContext).storeCache
  const { state } = useLocation<IUser>()
  const history = useHistory()
  if (!state.customer_id) {
    goBackWithErrorAlert(history, i18nAlert('subscription.paidHistory.cantGet'))
    return null
  }

  return (
    <>
      <H1 title={t('userSubscriptionPaidHistory.title')} />
      <H2 title={state.name ?? ''} />
      <AuthInfo team={team!} user={state} />

      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminUserPaidHistory}
          state={state}
          content={t('backToList')}
        />
        <TableBody user={state} />
      </Container>
    </>
  )
}

export default UserSubscriptionPaidHistory
