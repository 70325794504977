import { IVideoChapter } from 'common/interfaces/video_metadata'

/**
 * `(hh:)?mm:ss` to seconds
 * @param time `(hh:)?mm:ss` string
 * @returns seconds
 */
const timeFormat2Seconds = (time: string): number => {
  const t = time.split(':').map(Number)
  switch (t.length) {
    case 3:
      return t[0] * 3600 + t[1] * 60 + t[2]
    case 2:
      return t[0] * 60 + t[1]
  }
  throw new Error('Invalid time format')
}

/**
 * seconds to `(hh:)?mm:ss` string
 * @param seconds seconds
 * @returns `(hh:)?mm:ss` string
 */
const seconds2TimeFormat = (seconds: number): string => {
  const pad = (n: number) => n.toString().padStart(2, '0')
  const h = Math.floor(seconds / 3600)
  const m = pad(Math.floor((seconds % 3600) / 60))
  const s = pad(Math.floor(seconds % 60))
  return h > 0 ? `${h}:${m}:${s}` : `${m}:${s}`
}

/**
 * Create video chapters from video's overview
 * @param html video's overview
 * @param duration video's duration
 * @returns `IVideoChapter[]`
 */
export const parseVideoChapterFromHtml = (
  html: string | null,
  duration: number | null
): IVideoChapter[] => {
  if (!html) return []

  const newLineRegex = /<p><br(\s*\/)?><\/p>|<(br(\s*\/)?|\/(div|p))>/g
  const stripTagRegex = /<\/?[^>]+(>|$)/g
  const stripped = html.replace(newLineRegex, '\n').replace(stripTagRegex, '')
  const lines = stripped.split('\n')

  const zeroChapterRegex = /^00:00(:00)?\s+(.+)$/
  const chapterRegex = /^(\d{1,2}:\d{2}(:\d{2})?)\s+(.+)$/
  const chapterLines = lines.filter(
    (line) => zeroChapterRegex.test(line) || chapterRegex.test(line)
  )

  const chapters: IVideoChapter[] = []
  let chapterFound = false
  chapterLines.forEach((line) => {
    if (!chapterFound && zeroChapterRegex.test(line)) {
      chapterFound = true
      const title = line.match(zeroChapterRegex)![2]
      chapters.push({ title, start: 0, end: -1 })
    } else if (chapterFound && chapterRegex.test(line)) {
      const match = line.match(chapterRegex)!
      const title = match[3]
      const startSecond = timeFormat2Seconds(match[1])
      if (startSecond > chapters[chapters.length - 1].start) {
        chapters[chapters.length - 1].end = startSecond
        chapters.push({ title, start: startSecond, end: -1 })
      }
    }
  })

  if (chapters.length > 0) {
    const end = duration ?? timeFormat2Seconds('99:99:99')
    chapters[chapters.length - 1].end = end
  }
  return chapters
}

/**
 * Add `onclick` to time of video's overview
 * @param html video's overview
 * @param duration video's duration
 * @returns clickable video's overview
 */
export const overviewTimeClickable = (
  html: string | null,
  duration: number | null
): string | null => {
  if (!html) return null

  const script = (c: IVideoChapter) =>
    `
  window.scrollTo({top: 0, behavior: 'smooth'});
  window.hlsVideoSeek(${c.start});
  if (window.hlsVideoPaused()) window.hlsVideoPlay();
  `.replace(/\n|\s/g, '')

  const chapters = parseVideoChapterFromHtml(html, duration)
  chapters.forEach((chapter) => {
    const time = seconds2TimeFormat(chapter.start)
    html = html!.replace(
      time,
      `<span class="time-seek" onclick="${script(chapter)}">${time}</span>`
    )
  })

  return html
}

/**
 * Create video's chapter WebVTT blob url
 * @param chapters `IVideoChapter[]`
 * @returns null if `chapter` is empty, otherwise blob url
 */
export const createVideoChapterWebVttUrl = (
  chapters: IVideoChapter[]
): string | null => {
  if (chapters.length <= 0) return null

  const webVttContent = chapters.reduce((acc, chapter) => {
    const start = seconds2TimeFormat(chapter.start)
    const end = seconds2TimeFormat(chapter.end)
    return `${acc}\n${start}.000 --> ${end}.000\n${chapter.title}\n`
  }, 'WEBVTT\n')

  const blob = new Blob([webVttContent], { type: 'text/vtt' })
  return URL.createObjectURL(blob)
}
