import classNames from 'classnames'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ILog } from 'common/interfaces/log'
import CardSwiper from 'components/molecules/User/CardSwiper/CardSwiper'
import SideBySideTitle from 'components/molecules/User/SideBySideTitle/SideBySideTitle'
import VideoCard from 'components/molecules/User/VideoCard/VideoCard'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import {
  createShowAllLink,
  createTitle,
  get7VideoData,
  getUserViewLog,
  ListType,
} from 'services/user/sidescroll_video_list'
import { SwiperSlide } from 'swiper/react'
import './sidescrollvideolist.scss'

interface ISideScrollVideoList {
  className?: string
  listType: ListType
  targetId?: string
}

const SideScrollVideoList: React.FC<ISideScrollVideoList> = ({
  className,
  listType,
  targetId,
}: ISideScrollVideoList) => {
  const { t } = useTranslation('userIndex')
  const { storeCache } = useContext(AuthContext)
  const [userLog, setUserLog] = useState<ILog | null | undefined>()

  useEffect(() => {
    getUserViewLog(storeCache, listType, setUserLog)
  }, [storeCache, listType])

  const title = createTitle(listType, targetId, storeCache)
  const showAllLink = createShowAllLink(listType, targetId, storeCache)
  const videoCards = createVideoCards(listType, targetId, userLog, storeCache)

  if (
    (listType === ListType.VIDEOS_IN_PLAYLIST && title === '') ||
    videoCards.length <= 0
  ) {
    return null
  }

  return (
    <div className={classNames('side-scroll-video-list', className)}>
      <Container>
        <SideBySideTitle
          title={title}
          subTitle={t('sideScrollVideoList.showAll')}
          subTitleLink={showAllLink}
        />
        <CardSwiper className="video-container" showAllLink={showAllLink}>
          {videoCards}
        </CardSwiper>
      </Container>
    </div>
  )
}

const createVideoCards = (
  type: ListType,
  targetId: string | undefined,
  userLog: ILog | null | undefined,
  storeCache: IStoreCache
): JSX.Element[] => {
  return get7VideoData(type, targetId, userLog, storeCache).map(
    ({ video, link, overwriteTitle }, i) => (
      <SwiperSlide key={i + video.id}>
        <Link to={link}>
          <VideoCard video={video} overwriteTitle={overwriteTitle} />
        </Link>
      </SwiperSlide>
    )
  )
}

export default SideScrollVideoList
