import classNames from 'classnames'
import { Routes } from 'common/enums'
import { IEnquete } from 'common/interfaces/enquete'
import { IStripe } from 'common/interfaces/stripe'
import { ITest } from 'common/interfaces/test'
import { IVideo, VideoStatus } from 'common/interfaces/video'
import { makeCatalogVideoUrl, makeVideoUrl } from 'common/link_url'
import { standardModules } from 'common/quill'
import { datetimeLocalFormat, nowTimestamp } from 'common/times'
import {
  canCreateVideo,
  copyClipboard,
  getDefaultPublicationPeriod,
  getFileSize,
  getStatusOptions,
  isBasicPrice,
  isBusinessPrice,
  isProPrice,
  isTrialPlan,
  nl2br,
  openNewTab,
} from 'common/utils'
import {
  createBunnyEmbedExampleCode,
  createBunnyEmbedUrl,
  createBunnyFallbackMP4Urls,
  createBunnyHlsUrl,
  createVideoThumbnailUrl,
  getBunnyThumbnailUrls,
} from 'common/utils_bunny'
import FromToDateTime from 'components/atoms/Form/FromToDateTime'
import LimitPlayInput from 'components/atoms/Form/LimitPlayInput'
import QuillForm from 'components/atoms/Input/QuillForm'
import ButtonLoading from 'components/atoms/Loading/ButtonLoading'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import HlsVideo from 'components/atoms/Video/HlsVideo'
import ThumbnailCropper from 'components/molecules/Admin/ThumbnailCropper'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { DropzoneRootProps, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from 'reactstrap'
import { getEnquetes } from 'services/admin/enquete'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'
import { getTests } from 'services/admin/test'
import {
  InputType,
  dropzoneStyle,
  getDefaultPlayablePeriod,
  getFormOptions,
  getGroupValues,
  getReleaseDate,
  getVideoDefaults,
  onDropVideoFile,
  saveVideo,
  onChangeActionOnEnd as serviceOnChangeActionOnEnd,
  onChangeActionOnEndCheck as serviceOnChangeActionOnEndCheck,
  onChangeActiveQuestions as serviceOnChangeActiveQuestions,
  onChangeActiveQuestionsId as serviceOnChangeActiveQuestionsId,
  onChangeInput as serviceOnChangeInput,
  onChangeIsPublic as serviceOnChangeIsPublic,
  onChangeIsRegistered as serviceOnChangeIsRegistered,
  onChangeLimitedAccess as serviceOnChangeLimitedAccess,
  onChangePlayablePeriod as serviceOnChangePlayablePeriod,
  onChangePlayablePeriodStatus as serviceOnChangePlayablePeriodStatus,
  onChangePremiere as serviceOnChangePremiere,
  onChangeStatus as serviceOnChangeStatus,
  onChangeVideoFile as serviceOnChangeVideoFile,
  videoSizeOverText,
} from 'services/admin/video_form'
import { modalService } from 'services/modal'
import './formbody.scss'

interface IFormBodyProps {
  isCreate: boolean
  videoObj: IVideo
  history: History
}

const FormBody: React.FC<IFormBodyProps> = ({
  isCreate,
  videoObj,
  history,
}: IFormBodyProps) => {
  const adminVideo = useTranslation('adminVideo')
  const adminCommon = useTranslation('adminCommon')
  const { storeCache } = useContext(AuthContext)
  const videoTitleRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)
  const [video, setVideo] = useState<IVideo>(videoObj)
  const [videoFile, setVideoFile] = useState<File>()
  const [imageBlob, setImageBlob] = useState<Blob>()
  const [isImageDelete, setIsImageDelete] = useState<boolean>(false)
  const [isImageCropped, setIsImageCropped] = useState<boolean>(true)
  const [imageSuggestionUrl, setImageSuggestionUrl] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [enableSaveButton, setEnableSaveButton] = useState<boolean>(true)
  const [enquetes, setEnquetes] = useState<IEnquete[]>([])
  const [tests, setTests] = useState<ITest[]>([])
  const [stripe, setStripe] = useState<IStripe>(initStripe())
  const [playablePeriod, setPlayablePeriod] = useState<boolean>(
    video.playable_period !== null
  )
  const [isLimitPlayActive, setIsLimitPlayActive] = useState<boolean>(
    video.price_ids.length > 0 || video.is_registered
  )
  const [isPrepared, setIsPrepared] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      await Promise.all([
        getEnquetes(storeCache, setEnquetes),
        getTests(storeCache, setTests),
        getStripe(setStripe),
      ])
      setIsPrepared(true)
    })()
  }, [storeCache])

  const onDrop = (acceptedFiles: File[]) =>
    onDropVideoFile(acceptedFiles, setVideoFile, video, setVideo, videoTitleRef)

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ onDrop })

  const { baseStyle, borderNormalStyle, borderDragStyle } = dropzoneStyle

  const style: DropzoneRootProps = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? borderDragStyle : borderNormalStyle),
    }),
    [isDragActive, baseStyle, borderDragStyle, borderNormalStyle]
  )

  const isBasicPriceTeam = useMemo(
    () => isBasicPrice(storeCache.subscriptionObj, stripe),
    [storeCache.subscriptionObj, stripe]
  )
  const isShowEmbedCodes = useMemo(
    () =>
      !isTrialPlan(storeCache.subscriptionObj) &&
      !isBasicPrice(storeCache.subscriptionObj, stripe) &&
      !isProPrice(storeCache.subscriptionObj, stripe) &&
      !isBusinessPrice(storeCache.subscriptionObj, stripe),
    [storeCache.subscriptionObj, stripe]
  )

  const {
    defaultCategory,
    defaultCreator,
    defaultTags,
    defaultFilelists,
    defaultGroups,
    defaultPrices,
    defaultQuestion,
  } = getVideoDefaults(storeCache, video)

  const {
    tagOptions,
    filelistOptions,
    categoryOptions,
    creatorOptions,
    groupOptions,
    priceOptions,
  } = getFormOptions(storeCache)

  const statusOptions = getStatusOptions().map((option, key) => (
    <option key={key} value={option.value}>
      {option.label}
    </option>
  ))

  const questionOptions = [
    <option key="0" value="none">
      {adminVideo.t('form.videoQuestion.select.none')}
    </option>,
    <option key="1" value="active_enquete">
      {adminVideo.t('form.videoQuestion.select.enquete')}
    </option>,
    <option key="2" value="active_test">
      {adminVideo.t('form.videoQuestion.select.test')}
    </option>,
    <option key="3" value="active_next_video">
      {adminVideo.t('form.videoQuestion.select.nextVideo')}
    </option>,
  ]

  const enqueteOptions = [<option key="-1" />]
  enquetes.forEach((enquete, key) =>
    enqueteOptions.push(
      <option key={key} value={enquete.id}>
        {enquete.title}
      </option>
    )
  )

  const testOptions = [<option key="-1" />]
  tests.forEach((test, key) =>
    testOptions.push(
      <option key={key} value={test.id}>
        {test.name}
      </option>
    )
  )

  const nextVideoOptions = [<option key="-1" />]
  storeCache.videos.forEach((video) =>
    nextVideoOptions.push(
      <option key={video.id} value={video.id}>
        {video.name}
      </option>
    )
  )

  const selectedGroupValues = getGroupValues(storeCache, video)

  const userVideoUrl = makeVideoUrl(video.id, storeCache.team?.id ?? '')
  const copyUserVideoUrl = () => copyClipboard(userVideoUrl)
  const openQrCodeModal = () => modalService.showQrCode(userVideoUrl)
  const openUserVideoUrl = () => openNewTab(userVideoUrl)
  const publicVideoUrl = makeCatalogVideoUrl(storeCache.team!.id, video.id)
  const copyPublicVideoUrl = () => copyClipboard(publicVideoUrl)
  const openPublicVideoQrCodeModal = () =>
    modalService.showQrCode(publicVideoUrl)
  const embedVideoUrl = createBunnyEmbedUrl(storeCache.team!, video)
  const copyEmbedVideoUrl = () => copyClipboard(embedVideoUrl)
  const embedExampleCode = createBunnyEmbedExampleCode(storeCache.team!, video)

  const onChangeImageSuggestion = (url: string) => {
    const newUrl = imageSuggestionUrl === url ? undefined : url
    setImageSuggestionUrl(newUrl)
    if (imageBlob) setImageBlob(undefined)
  }

  const onChangeInput = (type: InputType, e: any) =>
    serviceOnChangeInput(video, setVideo, type, e)

  const defaultPublicationPeriod = getDefaultPublicationPeriod(video)
  const defaultPlayablePeriod = getDefaultPlayablePeriod(video)

  const onChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeStatus(video, setVideo, e)

  const onChangeLimitedAccess = (
    type: 'start' | 'end',
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeLimitedAccess(video, setVideo, type, e)

  const onChangePlayablePeriodStatus = (
    e: React.ChangeEvent<HTMLInputElement>
  ) =>
    serviceOnChangePlayablePeriodStatus(video, setVideo, e, setPlayablePeriod)
  const onChangePlayablePeriod = (
    type: 'from' | 'to',
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangePlayablePeriod(video, setVideo, type, e)
  const onChangePremiere = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangePremiere(video, setVideo, e)

  const onChangeIsPublic = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeIsPublic(video, setVideo, e)

  const onChangeIsRegistered = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeIsRegistered(video, setVideo, e)

  const onChangeActionOnEndCheck = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeActionOnEndCheck(video, setVideo, e)

  const onChangeActionOnEnd = (
    type: 'text' | 'link' | 'as_new_tab',
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeActionOnEnd(video, setVideo, type, e)

  const onChangeActiveQuestions = (
    type: 'none' | 'active_enquete' | 'active_test' | 'active_next_video'
  ) => serviceOnChangeActiveQuestions(video, setVideo, type)

  const onChangeActiveQuestionsId = (
    type: 'enquete_id' | 'test_id' | 'next_video_id',
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeActiveQuestionsId(video, setVideo, type, e)

  const onChangeVideoFile = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeVideoFile(e, setVideoFile, video, setVideo, videoTitleRef)

  const uploadVideoFile = useMemo(() => {
    const file: File | undefined = acceptedFiles[0]
    if (file !== undefined) {
      return (
        <p className="mb-0">
          {file.name} - {getFileSize(file.size)}
        </p>
      )
    }
  }, [acceptedFiles])

  const save = () =>
    saveVideo(
      history,
      video,
      videoFile,
      imageBlob,
      imageSuggestionUrl,
      isImageDelete,
      isImageCropped,
      storeCache,
      isCreate,
      isLimitPlayActive,
      setEnableSaveButton,
      setIsLoading
    )

  if (isCreate && isPrepared && !canCreateVideo(storeCache, stripe)) {
    return (
      <>
        <h1>{adminVideo.t('form.sizeOver.title')}</h1>
        <h2>{videoSizeOverText(storeCache, stripe)}</h2>
      </>
    )
  }

  return (
    <Form>
      {isCreate ? (
        <FormGroup row>
          <Col md="6">
            <Label for="videoFileForm">
              <InfoTooltip
                content={adminVideo.t('form.videoFile.title')}
                tooltipContent={adminVideo.t('form.videoFile.tips')}
                required
              />
            </Label>
            <div {...getRootProps({ style })}>
              <input
                {...getInputProps({
                  id: 'videoFileForm',
                  accept: 'video/*,audio/*',
                  multiple: false,
                  onChange: onChangeVideoFile,
                })}
              />
              <p>{nl2br(adminVideo.t('form.videoFile.drop'))}</p>
            </div>
            {uploadVideoFile}
          </Col>
        </FormGroup>
      ) : (
        <FormGroup row className="mb-5">
          <Col md="10">
            <Label for="userVideoUrlForm">
              <InfoTooltip
                content={adminVideo.t('form.publishUrl.title')}
                tooltipContent={adminVideo.t('form.publishUrl.tips')}
              />
            </Label>
            <InputGroup id="userVideoUrlForm">
              <Input
                value={userVideoUrl}
                readOnly
                onFocus={(e) => e.target.select()}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary" onClick={copyUserVideoUrl}>
                  {adminCommon.t('copy')}
                </Button>
                <Button color="primary" outline onClick={openQrCodeModal}>
                  {adminCommon.t('qrCode')}
                </Button>
                <Button color="primary" outline onClick={openUserVideoUrl}>
                  {adminCommon.t('openAsNewTab')}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
      )}
      <FormGroup row>
        <Col md="10">
          <Label for="videoTitleForm">
            <InfoTooltip
              content={adminVideo.t('form.videoTitle.title')}
              tooltipContent={adminVideo.t('form.videoTitle.tips')}
            />
          </Label>
          <Input
            id="videoTitleForm"
            innerRef={videoTitleRef}
            value={video.name}
            onChange={(e) => onChangeInput(InputType.NAME, e)}
            type="text"
          />
        </Col>
      </FormGroup>
      <ThumbnailCropper
        type="video"
        croppedData={imageBlob}
        setCroppedData={setImageBlob}
        handleIsImageCropped={setIsImageCropped}
        edit={{
          defaultImageUrl: imageSuggestionUrl || video.image,
          isImageDelete,
          setIsImageDelete,
        }}
      />
      {!isCreate && video.bunny.status === 'Finished' && (
        <Row className="mb-3">
          <Col md="10">
            <Label>{adminVideo.t('form.thumbnailSuggest')}</Label>
          </Col>
          <Col md="10">
            <Row>
              {getBunnyThumbnailUrls(storeCache.team!, video).map((url, i) => (
                <Col key={i} xs="4" xl="2" className="mb-2">
                  <div
                    className={classNames('thumbnail-suggest', {
                      'thumbnail-suggest__selected': imageSuggestionUrl === url,
                    })}
                    onClick={() => onChangeImageSuggestion(url)}
                  >
                    <img src={url} alt={`thumb${i}`} />
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      )}
      {!isCreate && (
        <Row className="mb-4 video-preview">
          <Col>
            <Label>
              {adminVideo.t('form.preview')}
              <a
                className="preview-icon"
                href={userVideoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-external-link-alt" />
              </a>
            </Label>

            <Row>
              <Col md="6">
                <HlsVideo
                  className="preview-container"
                  hlsUrl={createBunnyHlsUrl(storeCache.team!, video)}
                  fallbackMP4Urls={createBunnyFallbackMP4Urls(
                    storeCache.team!,
                    video
                  )}
                  posterUrl={createVideoThumbnailUrl(storeCache.team!, video)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <FormGroup row>
        <Col md="10">
          <Label for="videoCategoryForm">
            <InfoTooltip
              content={adminVideo.t('form.videoCategory.title')}
              tooltipContent={adminVideo.t('form.videoCategory.tips')}
            />
          </Label>
          <div className="category-edit">
            <Link
              to={Routes.AdminCategory}
              target="_blank"
              rel="noopener noreferrer"
            >
              {adminVideo.t('form.videoCategory.edit')}
              <i className="fas fa-external-link-alt ml-2" />
            </Link>
          </div>
          <Input
            id="videoCategoryForm"
            value={defaultCategory?.id}
            onChange={(e) => onChangeInput(InputType.CATEGORY_ID, e)}
            type="select"
          >
            {categoryOptions}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="8">
          <Label for="videoTagsForm">
            <InfoTooltip
              content={adminVideo.t('form.videoTag.title')}
              tooltipContent={adminVideo.t('form.videoTag.tips')}
            />
          </Label>
          <div className="tag-edit">
            <Link
              to={Routes.AdminTag}
              target="_blank"
              rel="noopener noreferrer"
            >
              {adminVideo.t('form.videoTag.edit')}
              <i className="fas fa-external-link-alt ml-2" />
            </Link>
          </div>
          <Select
            id="videoTagsForm"
            placeholder={adminVideo.t('form.videoTag.placeholder')}
            defaultValue={defaultTags}
            onChange={(e) => onChangeInput(InputType.TAG_IDS, e)}
            options={tagOptions}
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="8">
          <Label for="videoFilelistsForm">
            <InfoTooltip
              content={adminVideo.t('form.videoFilelist.title')}
              tooltipContent={adminVideo.t('form.videoFilelist.tips')}
            />
          </Label>
          <div className="filelist-edit">
            <Link
              to={Routes.AdminFilelist}
              target="_blank"
              rel="noopener noreferrer"
            >
              {adminVideo.t('form.videoFilelist.edit')}
              <i className="fas fa-external-link-alt ml-2" />
            </Link>
          </div>
          <Select
            id="videoFilelistsForm"
            placeholder={adminVideo.t('form.videoFilelist.placeholder')}
            defaultValue={defaultFilelists}
            onChange={(e) => onChangeInput(InputType.FILELIST_IDS, e)}
            options={filelistOptions}
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={adminVideo.t('form.videoOverview.title')}
              tooltipContent={adminVideo.t('form.videoOverview.tips')}
            />
          </Label>
          <div className="chapter-settings">
            <a
              href="https://one-stream.oopy.io/9be3a4a1-1f7e-41b5-9806-b48ac1441083"
              target="_blank"
              rel="noopener noreferrer"
            >
              {adminVideo.t('form.videoOverview.chapterSettings')}
              <i className="fas fa-external-link-alt ml-2" />
            </a>
          </div>
          <pre>
            <QuillForm
              value={video.overview || ''}
              onChange={(e) => onChangeInput(InputType.OVERVIEW, e)}
              modules={standardModules()}
            />
          </pre>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="videoCreatorForm">
            <InfoTooltip
              content={adminVideo.t('form.videoCreator.title')}
              tooltipContent={adminVideo.t('form.videoCreator.tips')}
            />
          </Label>
          <div className="creator-edit">
            <Link
              to={Routes.AdminCreator}
              target="_blank"
              rel="noopener noreferrer"
            >
              {adminVideo.t('form.videoCreator.edit')}
              <i className="fas fa-external-link-alt ml-2" />
            </Link>
          </div>
          <Input
            id="videoCreatorForm"
            value={defaultCreator?.id}
            onChange={(e) => onChangeInput(InputType.CREATOR_ID, e)}
            type="select"
          >
            {creatorOptions}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="4">
          <Label for="videoStatusForm">
            <InfoTooltip
              content={adminVideo.t('form.videoStatus.title')}
              tooltipContent={adminVideo.t('form.videoStatus.tips')}
            />
          </Label>
          <Input
            id="videoStatusForm"
            value={video.status}
            onChange={onChangeStatus}
            type="select"
          >
            {statusOptions}
          </Input>
        </Col>
        {video.status !== VideoStatus.PRIVATE && (
          <Col md="4">
            <Label for="releasedAt">{adminVideo.t('form.releasedAt')}</Label>
            <Input
              id="releasedAt"
              onChange={(e) => onChangeInput(InputType.RELEASED_AT, e)}
              value={getReleaseDate(video)}
              max={datetimeLocalFormat(nowTimestamp())}
              type="datetime-local"
              disabled={video.status === VideoStatus.PUBLICATION_PERIOD}
            />
          </Col>
        )}
      </FormGroup>

      {video.status === VideoStatus.PUBLICATION_PERIOD && (
        <FromToDateTime
          fromLabel={adminVideo.t('form.publicationPeriod.from')}
          toLabel={adminVideo.t('form.publicationPeriod.to')}
          from={defaultPublicationPeriod.start}
          to={defaultPublicationPeriod.end!}
          onChange={(e, isFrom) =>
            onChangeLimitedAccess(isFrom ? 'start' : 'end', e)
          }
        />
      )}

      {storeCache.team?.is_site_public && (
        <FormGroup check row className="mb-4">
          <Col md="10">
            <Label check>
              <Input
                defaultChecked={video.is_public}
                onChange={onChangeIsPublic}
                type="checkbox"
              />
              {adminVideo.t('form.videoPublic.title')}
              <Detail>{adminVideo.t('form.videoPublic.tips')}</Detail>
            </Label>
            {!isCreate && video.is_public && videoObj.is_public && (
              <div className="mt-2">
                <InputGroup>
                  <Input
                    value={publicVideoUrl}
                    readOnly
                    onFocus={(e) => e.target.select()}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="primary" onClick={copyPublicVideoUrl}>
                      {adminCommon.t('copy')}
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={openPublicVideoQrCodeModal}
                    >
                      {adminCommon.t('qrCode')}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>

                {isShowEmbedCodes && (
                  <>
                    <div className="mt-3">
                      {adminVideo.t('form.videoPublic.embedUrl')}
                    </div>
                    <InputGroup>
                      <Input
                        value={embedVideoUrl}
                        readOnly
                        onFocus={(e) => e.target.select()}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="primary" onClick={copyEmbedVideoUrl}>
                          {adminCommon.t('copy')}
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                    <div className="mt-2">
                      {adminVideo.t('form.videoPublic.embedSampleCode')}
                    </div>
                    <Input
                      type="textarea"
                      className="video-embed-sample-code"
                      value={embedExampleCode}
                    />
                  </>
                )}
              </div>
            )}
          </Col>
        </FormGroup>
      )}

      <FormGroup check row className="mb-4">
        <Col md="10">
          <Label check>
            <Input
              defaultChecked={video.is_list_hidden}
              onChange={(e) => onChangeInput(InputType.IS_LIST_HIDDEN, e)}
              type="checkbox"
            />
            {adminVideo.t('form.videoListHidden.title')}
            <Detail>{adminVideo.t('form.videoListHidden.tips')}</Detail>
          </Label>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col md="8">
          <Label for="videoGroupForm">
            <InfoTooltip
              content={adminVideo.t('form.videoGroup.title')}
              tooltipContent={adminVideo.t('form.videoGroup.tips')}
            />
            <div className="d-flex align-items-center">
              <Detail>{adminVideo.t('form.videoGroup.tips2')}</Detail>
              <div className="group-edit">
                <Link
                  to={Routes.AdminGroup}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {adminVideo.t('form.videoGroup.edit')}
                  <i className="fas fa-external-link-alt ml-2" />
                </Link>
              </div>
            </div>
          </Label>
          <Select
            id="videoGroupForm"
            placeholder={adminVideo.t('form.videoGroup.allGroup')}
            value={selectedGroupValues}
            isDisabled={video.is_public}
            defaultValue={defaultGroups}
            onChange={(e) => onChangeInput(InputType.GROUP_IDS, e)}
            options={groupOptions}
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
      </FormGroup>

      <FormGroup check row className="mb-3">
        <Col md="10">
          <Label check>
            <Input
              checked={playablePeriod}
              onChange={(e) => onChangePlayablePeriodStatus(e)}
              type="checkbox"
            />
            {adminVideo.t('form.videoPlayablePeriod.title')}
          </Label>
        </Col>
      </FormGroup>

      {playablePeriod && (
        <>
          <FromToDateTime
            fromLabel={adminVideo.t('form.videoPlayablePeriod.from')}
            toLabel={adminVideo.t('form.videoPlayablePeriod.to')}
            isDisabledTo={video.is_premiere}
            from={defaultPlayablePeriod.from}
            to={defaultPlayablePeriod.to}
            onChange={(e, isFrom) =>
              onChangePlayablePeriod(isFrom ? 'from' : 'to', e)
            }
          />
          <FormGroup check row className="mb-3">
            <Col md="10">
              <Label check>
                <Input
                  checked={video.is_premiere}
                  disabled={isBasicPriceTeam}
                  onChange={onChangePremiere}
                  type="checkbox"
                />
                {adminVideo.t('form.videoPlayablePeriod.premiere.title')}
                <Detail>
                  {adminVideo.t('form.videoPlayablePeriod.premiere.tips')}
                </Detail>
              </Label>
            </Col>
          </FormGroup>
        </>
      )}

      <LimitPlayInput
        storeCache={storeCache}
        stripe={stripe}
        content={video}
        isLimitPlayActive={isLimitPlayActive}
        setIsLimitPlayActive={setIsLimitPlayActive}
        defaultPrices={defaultPrices}
        priceOptions={priceOptions}
        onChange={(e) => onChangeInput(InputType.PRICE_IDS, e)}
        onChangeIsRegistered={(e) => onChangeIsRegistered(e)}
      />

      <FormGroup check row className="mb-3">
        <Col md="10">
          <Label check>
            <Input
              checked={!video.is_seekable}
              disabled={video.is_premiere || isBasicPriceTeam}
              onChange={(e) => onChangeInput(InputType.IS_DISABLE_SEEK, e)}
              type="checkbox"
            />
            {adminVideo.t('form.videoDisableSeek.title')}
            <Detail>{adminVideo.t('form.videoDisableSeek.tips')}</Detail>
          </Label>
        </Col>
      </FormGroup>

      <FormGroup check row>
        <Col md="10">
          <Label check>
            <Input
              defaultChecked={video.action_onend !== null}
              onChange={(e) => onChangeActionOnEndCheck(e)}
              type="checkbox"
            />
            {adminVideo.t('form.videoOnEndButton.title')}
          </Label>
        </Col>
      </FormGroup>
      {video.action_onend !== null && (
        <Row className="mt-3">
          <Col md="5">
            <FormGroup>
              <Label for="actionOnEndText">
                {adminVideo.t('form.videoOnEndButton.buttonText')}
              </Label>
              <Input
                id="actionOnEndText"
                defaultValue={video.action_onend.text}
                onChange={(e) => onChangeActionOnEnd('text', e)}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="5">
            <FormGroup>
              <Label for="actionOnEndLink">
                {adminVideo.t('form.videoOnEndButton.buttonLink')}
              </Label>
              <Input
                id="actionOnEndLink"
                defaultValue={video.action_onend.link}
                onChange={(e) => onChangeActionOnEnd('link', e)}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="5">
            <FormGroup check>
              <Label check>
                <Input
                  defaultChecked={video.action_onend.as_new_tab}
                  onChange={(e) => onChangeActionOnEnd('as_new_tab', e)}
                  type="checkbox"
                />
                {adminVideo.t('form.videoOnEndButton.asNewTab')}
              </Label>
            </FormGroup>
          </Col>
        </Row>
      )}

      <FormGroup row className="mt-4">
        <Col md="8">
          <Label for="questionsForm">
            <InfoTooltip
              content={adminVideo.t('form.videoQuestion.title')}
              tooltipContent={adminVideo.t('form.videoQuestion.tips')}
            />
          </Label>
          <Input
            id="questionsForm"
            placeholder={adminVideo.t('form.videoQuestion.placeholder')}
            disabled={isBasicPriceTeam}
            defaultValue={defaultQuestion}
            onChange={(e) =>
              onChangeActiveQuestions(
                e.target.value as
                  | 'none'
                  | 'active_enquete'
                  | 'active_test'
                  | 'active_next_video'
              )
            }
            type="select"
          >
            {questionOptions}
          </Input>
        </Col>
      </FormGroup>

      {video.enquete?.activate_enquete && (
        <FormGroup row>
          <Col md="4">
            <Label for="enqueteForm">
              <InfoTooltip
                content={adminVideo.t('form.videoQuestion.enquete.title')}
                tooltipContent={adminVideo.t('form.videoQuestion.enquete.tips')}
              />
            </Label>
            <Input
              id="enqueteForm"
              disabled={isBasicPriceTeam}
              onChange={(e) => onChangeActiveQuestionsId('enquete_id', e)}
              type="select"
              value={video.enquete.enquete_id}
            >
              {enqueteOptions}
            </Input>
          </Col>
        </FormGroup>
      )}

      {video.test?.activate_test && (
        <FormGroup row>
          <Col md="4">
            <Label for="testForm">
              <InfoTooltip
                content={adminVideo.t('form.videoQuestion.test.title')}
                tooltipContent={adminVideo.t('form.videoQuestion.test.tips')}
              />
            </Label>
            <Input
              id="testForm"
              disabled={isBasicPriceTeam}
              onChange={(e) => onChangeActiveQuestionsId('test_id', e)}
              type="select"
              value={video.test.test_id}
            >
              {testOptions}
            </Input>
          </Col>
        </FormGroup>
      )}

      {video.next_video?.activate_next_video && (
        <FormGroup row>
          <Col md="4">
            <Label for="nextVideoForm">
              <InfoTooltip
                content={adminVideo.t('form.videoQuestion.nextVideo.title')}
                tooltipContent={adminVideo.t(
                  'form.videoQuestion.nextVideo.tips'
                )}
              />
            </Label>
            <Input
              id="nextVideoForm"
              disabled={isBasicPriceTeam}
              onChange={(e) => onChangeActiveQuestionsId('next_video_id', e)}
              type="select"
              value={video.next_video.next_video_id}
            >
              {nextVideoOptions}
            </Input>
          </Col>
        </FormGroup>
      )}

      <div className="text-center">
        {isLoading ? (
          <ButtonLoading className="my-4" />
        ) : (
          <Button
            onClick={save}
            className="my-4"
            color="primary"
            type="button"
            disabled={!enableSaveButton}
          >
            {adminCommon.t('save')}
          </Button>
        )}
      </div>
    </Form>
  )
}

const Detail = ({ children }: { children: React.ReactNode }) => (
  <div style={{ fontSize: '0.8rem' }}>{children}</div>
)

export default FormBody
