import { IPlaylist } from 'common/interfaces/playlist'
import { makePlaylistUrl, makecatalogPlaylistUrl } from 'common/link_url'
import { standardModules } from 'common/quill'
import { copyClipboard, openNewTab } from 'common/utils'
import QuillForm from 'components/atoms/Input/QuillForm'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import ThumbnailCropper from 'components/molecules/Admin/ThumbnailCropper'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from 'reactstrap'
import {
  InputType,
  getFormOptions,
  getPlaylistDefaults,
  savePlaylist,
  onChangeInput as serviceOnChangeInput,
  onChangeIsPublic as serviceOnChangeIsPublic,
} from 'services/admin/playlist_form'
import { modalService } from 'services/modal'
import VideoOrderModal from './VideoOrderModal'
import './formbody.css'

interface IFormBodyProps {
  isCreate: boolean
  playlistObj: IPlaylist
  history: History
}

const FormBody: React.FC<IFormBodyProps> = ({
  isCreate,
  playlistObj,
  history,
}: IFormBodyProps) => {
  const adminPlaylist = useTranslation('adminPlaylist')
  const adminCommon = useTranslation('adminCommon')
  const { storeCache } = useContext(AuthContext)
  const [playlist, setPlaylist] = useState<IPlaylist>(playlistObj)

  const [imageBlob, setImageBlob] = useState<Blob>()
  const [isImageDelete, setIsImageDelete] = useState<boolean>(false)
  const [isImageCropped, setIsImageCropped] = useState<boolean>(true)
  const [videoOrderModal, setVideoOrderModal] = useState<boolean>(false)

  const userPlaylistUrl = makePlaylistUrl(
    playlist.id,
    storeCache.team?.id ?? ''
  )
  const copyUserPlaylistUrl = () => copyClipboard(userPlaylistUrl)
  const openUserPlaylistUrl = () => openNewTab(userPlaylistUrl)
  const publicPlaylistUrl = makecatalogPlaylistUrl(
    playlist.id,
    storeCache.team!.id
  )
  const copyPublicPlaylistUrl = () => copyClipboard(publicPlaylistUrl)
  const openPublicPlaylistQrCodeModal = () =>
    modalService.showQrCode(publicPlaylistUrl)

  const { categoryOptions, tagOptions, videoOptions } =
    getFormOptions(storeCache)
  const { defaultVideos: playlistVideosValue, defaultTags } =
    getPlaylistDefaults(storeCache, playlist)

  const onChangeInput = (type: InputType, e: any) =>
    serviceOnChangeInput(playlist, setPlaylist, type, e)
  const onChangeIsPublic = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeIsPublic(playlist, setPlaylist, e)

  const toggleVideoOrderModal = () => setVideoOrderModal(!videoOrderModal)

  const save = () =>
    savePlaylist(
      history,
      playlist,
      imageBlob,
      isImageDelete,
      isImageCropped,
      storeCache,
      isCreate
    )

  return (
    <Form>
      <VideoOrderModal
        isOpen={videoOrderModal}
        toggleModal={toggleVideoOrderModal}
        playlist={playlist}
        setPlaylist={setPlaylist}
      />
      {!isCreate && (
        <FormGroup row className="mb-5">
          <Col md="10">
            <Label for="userPlaylistUrlForm">
              <InfoTooltip
                content={adminPlaylist.t('form.publishUrl.title')}
                tooltipContent={adminPlaylist.t('form.publishUrl.tips')}
              />
            </Label>
            <InputGroup id="userPlaylistUrlForm">
              <Input
                value={userPlaylistUrl}
                readOnly
                onFocus={(e) => e.target.select()}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary" onClick={copyUserPlaylistUrl}>
                  {adminCommon.t('copy')}
                </Button>
                <Button color="primary" outline onClick={openUserPlaylistUrl}>
                  {adminCommon.t('openAsNewTab')}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
      )}
      <FormGroup row>
        <Col md="10">
          <Label for="playlistTitleForm">
            <InfoTooltip
              content={adminPlaylist.t('form.playlistTitle.title')}
              tooltipContent={adminPlaylist.t('form.playlistTitle.tips')}
              required
            />
          </Label>
          <Input
            id="playlistTitleForm"
            value={playlist.name}
            onChange={(e) => onChangeInput(InputType.NAME, e)}
            type="text"
          />
        </Col>
      </FormGroup>
      <ThumbnailCropper
        type="playlist"
        croppedData={imageBlob}
        setCroppedData={setImageBlob}
        handleIsImageCropped={setIsImageCropped}
        edit={{
          defaultImageUrl: playlist.image,
          isImageDelete,
          setIsImageDelete,
        }}
      />
      <FormGroup row>
        <Col md="10">
          <Label for="playlistCategoryForm">
            <InfoTooltip
              content={adminPlaylist.t('form.playlistCategory.title')}
              tooltipContent={adminPlaylist.t('form.playlistCategory.tips')}
            />
          </Label>
          <Input
            id="playlistCategoryForm"
            value={playlist.category_id}
            onChange={(e) => onChangeInput(InputType.CATEGORY_ID, e)}
            type="select"
          >
            {categoryOptions}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="8">
          <Label for="playlistTagsForm">
            <InfoTooltip
              content={adminPlaylist.t('form.playlistTag.title')}
              tooltipContent={adminPlaylist.t('form.playlistTag.tips')}
            />
          </Label>
          <Select
            id="playlistTagsForm"
            placeholder={adminPlaylist.t('form.playlistTag.placeholder')}
            defaultValue={defaultTags}
            onChange={(e) => onChangeInput(InputType.TAG_IDS, e)}
            options={tagOptions}
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="8">
          <Label for="playlistVideosForm">
            <InfoTooltip
              content={adminPlaylist.t('form.playlistVideos.title')}
              tooltipContent={adminPlaylist.t('form.playlistVideos.tips')}
            />
            <button
              className="text-blue text-xs playlist-video-button"
              onClick={toggleVideoOrderModal}
              type="button"
            >
              {adminPlaylist.t('form.playlistVideos.orderEdit')}
              <i className="fas fa-sort-amount-down ml-2" />
            </button>
          </Label>
          <Select
            id="playlistVideosForm"
            placeholder={adminPlaylist.t('form.playlistVideos.placeholder')}
            value={playlistVideosValue}
            onChange={(e) => onChangeInput(InputType.VIDEO_IDS, e)}
            options={videoOptions}
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
      </FormGroup>
      {!isCreate && (
        <Row className="mb-4 playlist-preview">
          <Col>
            <Label>
              {adminPlaylist.t('form.preview')}
              <a
                className="preview-icon"
                href={makePlaylistUrl(playlist.id, storeCache.team!.id)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-external-link-alt" />
              </a>
            </Label>
          </Col>
        </Row>
      )}
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={adminPlaylist.t('form.playlistOverview.title')}
              tooltipContent={adminPlaylist.t('form.playlistOverview.tips')}
            />
          </Label>
          <pre>
            <QuillForm
              defaultValue={playlist.overview ? playlist.overview : ''}
              onChange={(e) => onChangeInput(InputType.OVERVIEW, e)}
              modules={standardModules()}
            />
          </pre>
        </Col>
      </FormGroup>

      {storeCache.team?.is_site_public && (
        <FormGroup check row className="mb-4">
          <Col md="10">
            <Label check>
              <Input
                defaultChecked={playlist.is_public}
                onChange={onChangeIsPublic}
                type="checkbox"
              />
              {adminPlaylist.t('form.playlistPublic.title')}
              <div style={{ fontSize: '0.8rem' }}>
                {adminPlaylist.t('form.playlistPublic.tips')}
              </div>
            </Label>
            {!isCreate && playlist.is_public && playlistObj.is_public && (
              <div className="mt-2">
                <InputGroup>
                  <Input
                    value={publicPlaylistUrl}
                    readOnly
                    onFocus={(e) => e.target.select()}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="primary" onClick={copyPublicPlaylistUrl}>
                      {adminCommon.t('copy')}
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={openPublicPlaylistQrCodeModal}
                    >
                      {adminCommon.t('qrCode')}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            )}
          </Col>
        </FormGroup>
      )}

      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {adminCommon.t('save')}
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
