import { IStoreCache } from 'common/interfaces/auth_provider'
import { ITag } from 'common/interfaces/tag'
import { isLoggedIn } from 'common/utils'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { reloadCachedTags } from 'providers/AuthProvider'
import { removeImageFile } from 'repositories/storage/firebase_tag'
import { remove } from 'repositories/store/firebase_tag'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * タグを使用している動画/配信の件数を返却する
 */
export const getTagUseCount = (tag: ITag, storeCache: IStoreCache) => {
  return [...storeCache.videos, ...storeCache.lives].filter((video) =>
    video.tag_ids.includes(tag.id)
  ).length
}

/**
 * タグレコード削除処理
 */
export const removeTag = (tag: ITag, storeCache: IStoreCache) => {
  if (!isLoggedIn(storeCache)) return
  if (getTagUseCount(tag, storeCache) > 0) {
    alertService.show(false, i18nValidation('cantDelete.inUse.tag'))
    return
  }

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      if (tag.image) {
        await removeImageFile(storeCache.team!, tag)
      }
      await remove(storeCache.team!, tag)
      await reloadCachedTags(storeCache)
      alertService.show(true, i18nAlert('deleted.message'))
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('cantDeleted'))
    }
  })
}
