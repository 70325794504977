export const isCatalogPage = () =>
  window.location.pathname.startsWith('/catalog')
export const teamUid = () => window.location.pathname.split('/')[2]
export const isEventPage = () =>
  window.location.pathname.split('/')[3] === 'event'

/**
 * カタログページを通常ページに変換する
 * @returns `/catalog/:thisTeamId`
 */
export const convertCatalogIntoNormal = (): string =>
  window.location.pathname.replace(`catalog`, 'user')
