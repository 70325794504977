import { IEvent } from 'common/interfaces/event'
import { ILive } from 'common/interfaces/live'
import { IPlaylist } from 'common/interfaces/playlist'
import { IVideo } from 'common/interfaces/video'
import {
  makeEventPath,
  makeLivePath,
  makePlaylistPath,
  makeVideoPath,
} from 'common/link_path'
import { isLiveVideo } from 'common/utils'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import GenreCard from 'components/molecules/User/GenreCard/GenreCard'
import VideoCard from 'components/molecules/User/VideoCard/VideoCard'
import { TFunction } from 'i18next'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'
import { useContentTypeListParams } from 'services/user/type_contents_list/page_params'
import {
  createTitle,
  getFilteredContents,
} from 'services/user/type_contents_list/type_contents_list'
import './typecontentslist.scss'

const TypeContentsList: React.FC = () => {
  const { t } = useTranslation('userVideoList')
  const { storeCache } = useContext(AuthContext)
  const params = useContentTypeListParams()

  const title = createTitle(storeCache, params)
  const [contents, setContents] = useState<
    IVideo[] | ILive[] | IEvent[] | IPlaylist[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { type, tagId, categoryId, creatorId, playStatus, query } = params
  useEffect(() => {
    const params = { type, tagId, categoryId, creatorId, playStatus, query }

    ;(async () => {
      const contents = await getFilteredContents(storeCache, params)
      setContents(contents)
      setIsLoading(false)
    })()
  }, [storeCache, type, tagId, categoryId, creatorId, playStatus, query])

  const [elements, setElements] = useState<JSX.Element[]>([])

  if (isLoading) {
    return <LoadingSpinner />
  }

  const countPerPage = 15
  const loadMore = (page: number) => {
    const sliceStart = (page - 1) * countPerPage
    const sliceEnd = sliceStart + countPerPage
    const showContents = contents.slice(sliceStart, sliceEnd)
    if (showContents.length <= 0) {
      setElements([createNoContent(t)])
    } else {
      setElements([...elements, ...createContents(showContents)])
    }
  }

  if (!contents) return null

  const count = contents.length
  const countElement =
    count > 0 ? (
      <p className="result-count">
        {t('typeContentsList.searchResultCount', { count })}
      </p>
    ) : null

  return (
    <div className="content-list">
      <h1 className="title">{title}</h1>
      {countElement}
      <InfiniteScroll
        element="div"
        className="row"
        loadMore={loadMore}
        hasMore={
          (contents.length === 0 && elements.length === 0) ||
          count > elements.length
        }
      >
        {elements}
      </InfiniteScroll>
    </div>
  )
}

const createContents = (
  contents: IVideo[] | ILive[] | IEvent[] | IPlaylist[]
): JSX.Element[] => {
  if (contents.length <= 0) return []
  if ('creator_id' in contents[0]) {
    return createVideoLiveContents(contents as IVideo[])
  }
  if ('contents' in contents[0]) {
    return createListContents(contents as IEvent[], (id) => makeEventPath(id))
  }

  return createListContents(contents as IPlaylist[], (id) =>
    makePlaylistPath(id)
  )
}

const createVideoLiveContents = (
  contents: IVideo[] | ILive[]
): JSX.Element[] => {
  const createLink = (video: IVideo | ILive) =>
    isLiveVideo(video) ? makeLivePath(video.id) : makeVideoPath(video.id)

  return contents.map((content) => (
    <Col key={content.id} className="video-card-container" xs="6" md="4">
      <Link to={createLink(content)}>
        <VideoCard className="video-card" video={content} />
      </Link>
    </Col>
  ))
}

const createListContents = (
  contents: IEvent[] | IPlaylist[],
  makeLink: (id: string) => string
): JSX.Element[] =>
  contents.map(({ id, name, image }) => (
    <Col key={id} className="playlist-card-container" xs="6" md="4">
      <GenreCard
        className="playlist-card"
        name={name}
        link={makeLink(id)}
        image={image}
        is16x9
      />
    </Col>
  ))

const createNoContent = (
  t: TFunction<'userVideoList', undefined>
): JSX.Element => (
  <Col key="0" xs="12">
    <h2 className="no-content-text">{t('typeContentsList.noSearchResult')}</h2>
  </Col>
)

export default TypeContentsList
