import { IUser } from 'common/interfaces/user'
import FooterButtons from 'components/atoms/Modal/FooterButtons'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Form, Input, Modal, ModalBody } from 'reactstrap'
import {
  UpdateType,
  onUpdateUsers as serviceOnUpdateUsers,
} from 'services/admin/user'
import { getFormOptions } from 'services/admin/user_edit'

interface IUserMultiSelectModalProps {
  isOpen: boolean
  toggleModal: () => void
  type: UpdateType
  selectedUsers: IUser[]
}

const UserMultiSelectModal: React.FC<IUserMultiSelectModalProps> = ({
  isOpen,
  type,
  selectedUsers,
  toggleModal,
}) => {
  const { storeCache } = useContext(AuthContext)
  const { t } = useTranslation('alert')
  const [groupIds, setGroupIds] = useState<string[]>([])
  const [expiration, setExpiration] = useState<string>('')
  const { groupOptions } = getFormOptions(storeCache)

  const onUpdateUsers = () => {
    serviceOnUpdateUsers(
      storeCache,
      type,
      selectedUsers,
      toggleModal,
      groupIds,
      expiration
    )
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered>
      <ModalBody>
        {type === UpdateType.DISABLE &&
          t('modal.confirm.selectedUsers.disabled')}
        {type === UpdateType.ENABLE && t('modal.confirm.selectedUsers.enable')}
        {type === UpdateType.ADDGROUPS && (
          <Trans
            t={t}
            i18nKey="modal.confirm.selectedUsers.addGroups"
            components={{
              selectGroup: (
                <Select
                  id="groupNameForm"
                  onChange={(e) => setGroupIds(e.map((x) => x.value))}
                  options={groupOptions}
                  closeMenuOnSelect={false}
                  isMulti
                />
              ),
            }}
          />
        )}
        {type === UpdateType.EXPIRATION && (
          <Trans
            t={t}
            i18nKey="modal.confirm.selectedUsers.expiration"
            components={{
              editExpiration: (
                <Input
                  onChange={(e) => setExpiration(e.target.value)}
                  type="datetime-local"
                />
              ),
            }}
          />
        )}
        <Form>
          <FooterButtons
            leftContent="キャンセル"
            rightContent="OK"
            leftOnClick={toggleModal}
            rightOnClick={onUpdateUsers}
          />
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default UserMultiSelectModal
