import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { IPrice } from 'common/interfaces/stripe/price'
import { IProduct } from 'common/interfaces/stripe/product'
import { formatUNIXToDate } from 'common/times'
import {
  formatInterval,
  formatPrice,
  goBackWithErrorAlert,
  nl2br,
} from 'common/utils'
import NormalButton from 'components/atoms/Button/Normal'
import ButtonRight from 'components/atoms/Button/Right'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeActiveBadge,
  makeCustomOperationButton,
  makeCustomOperationButtons,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { Col, Row } from 'reactstrap'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminStripePrice')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const { product } = useLocation<{ product: IProduct }>().state

  const [showArchive, setShowArchive] = useState<boolean>(false)
  const onChangeToggle = () => setShowArchive(!showArchive)

  if (!storeCache.team!.stripe.account_id) {
    goBackWithErrorAlert(
      history,
      i18nAlert('stripe.setting.unfinish.notFunction')
    )
    return null
  }

  const prices = storeCache.prices.filter((p) => p.product === product.id)
  if (prices.length <= 0) {
    return (
      <>
        <ButtonRight
          nextPage={Routes.AdminStripeProduct}
          content={t('list.back')}
        />
        <RegisterItemButton type="stripe_price" states={[{ product }]} />
      </>
    )
  }

  return (
    <>
      <Row className="justify-content-end mb-3 mr-1 mr-md-0">
        <NormalButton
          onClick={() => history.push(Routes.AdminStripeProduct)}
          content={t('list.back')}
        />
        <NormalButton
          onClick={() =>
            history.push(Routes.AdminStripePriceCreate, { product })
          }
          content={t('list.new')}
        />
      </Row>
      <Row className="justify-content-start">
        <Col>
          <h4>{t('list.viewArchivedRates')}</h4>
          <label className="custom-toggle">
            <input
              type="checkbox"
              onChange={onChangeToggle}
              checked={showArchive}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </Col>
      </Row>
      <Table product={product} prices={prices} showArchive={showArchive} />
    </>
  )
}

const Table: React.FC<{
  product: IProduct
  prices: IPrice[]
  showArchive: boolean
}> = ({ product, prices, showArchive }) => {
  const { t } = useTranslation('adminStripePrice')
  const { t: commonI18n } = useTranslation('common')
  const history = useHistory()

  const columns = useMemo<ColumnDef<IPrice>[]>(
    () => [
      {
        header: t('list.table.name'),
        accessorFn: () => product.name,
      },
      {
        header: t('list.table.unitAmount'),
        accessorFn: (p) => formatPrice(p.unit_amount, p.currency),
      },
      {
        header: t('list.table.recurring'),
        accessorFn: (p) =>
          p.recurring === null ? t('oneTime') : t('subscription'),
      },
      {
        header: t('list.table.interval'),
        accessorFn: (p) =>
          p.recurring === null ? '' : formatInterval(p.recurring.interval),
      },
      {
        header: t('list.table.nickname'),
        accessorKey: 'nickname',
      },
      {
        header: t('list.table.description'),
        accessorFn: (p) => nl2br(p.metadata.description ?? null),
      },
      {
        header: t('list.table.trial'),
        accessorFn: (p) =>
          commonI18n('format.day', { date: p.metadata.trial ?? 0 }),
      },
      {
        header: t('list.table.validDays'),
        accessorFn: (p) =>
          commonI18n('format.day', { date: p.metadata.valid_days ?? 0 }),
      },
      {
        header: t('list.table.created'),
        accessorFn: (p) => formatUNIXToDate(p.created),
      },
      {
        header: t('list.table.active'),
        accessorFn: (p) => makeActiveBadge(p.active),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (p) =>
          makeCustomOperationButtons(
            makeCustomOperationButton(t('list.edit'), () =>
              history.push(Routes.AdminStripePriceEdit, { product, price: p })
            )
          ),
      },
    ],
    [history, product, t, commonI18n]
  )

  const data = useMemo(
    () => prices.filter((p) => showArchive || p.active),
    [prices, showArchive]
  )
  return useTanstackTable<IPrice>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
