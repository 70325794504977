import { EffectiveDaysStatus, IFormInvite } from 'common/interfaces/invite'
import {
  nowTimeFormat,
  nowTimestamp,
  tomorrowDatetimeLocalFormat,
} from 'common/times'
import { i18nAdminInvite } from 'i18n/i18n'
import { v4 as uuidv4 } from 'uuid'

/**
 * 期限の初期値を返却する
 */
export const defaultExpireDatetime = () => tomorrowDatetimeLocalFormat()

/**
 * `IFormInvite`の初期値を返却する
 */
export const initInvite = (): IFormInvite => {
  const name = nowTimeFormat(
    i18nAdminInvite('inviteForm.initInvite.inviteDateFormat')
  )
  const defaultDatetime = defaultExpireDatetime()
  return {
    id: uuidv4(),
    admin: true,
    name,
    count: 1,
    count_infinity: true,
    expire_datetime: defaultDatetime,
    expire_infinity: true,
    effective_days: 1,
    effective_days_status: EffectiveDaysStatus.INFINITY,
    effective_period: 0,
    join_group_ids: [],
    approval: false,
    activate_name: false,
    activate_phone: false,
    activate_customize_fields: false,
    comment: '',
    price_ids: [],
    created_at: nowTimestamp(),
    updated_at: nowTimestamp(),
  }
}

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
  COUNT,
  COUNT_INFINITY,
  EXPIRE_DATETIME,
  EXPIRE_INFINITY,
  COMMENT,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  invite: IFormInvite,
  setInvite: React.Dispatch<React.SetStateAction<IFormInvite>>,
  type: InputType,
  e: React.ChangeEvent<HTMLInputElement>
) => {
  const val = e.target.value
  const checked = e.target.checked
  setInvite({
    ...invite,
    name: type === InputType.NAME ? val : invite.name,
    count: type === InputType.COUNT ? Number(val) : invite.count,
    count_infinity:
      type === InputType.COUNT_INFINITY ? checked : invite.count_infinity,
    expire_datetime:
      type === InputType.EXPIRE_DATETIME ? val : invite.expire_datetime,
    expire_infinity:
      type === InputType.EXPIRE_INFINITY ? checked : invite.expire_infinity,
    comment: type === InputType.COMMENT ? val : invite.comment,
  })
}

/**
 * 無制限系の`onChange`イベントハンドラ
 */
export const onChangeInfinity = (
  invite: IFormInvite,
  setInvite: React.Dispatch<React.SetStateAction<IFormInvite>>,
  infinity: { count?: boolean; expire?: boolean }
) => {
  setInvite({
    ...invite,
    count_infinity: infinity.count ?? invite.count_infinity,
    expire_infinity: infinity.expire ?? invite.expire_infinity,
  })
}
