import { findCachedCategory } from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IPlaylist } from 'common/interfaces/playlist'
import { isLoggedIn } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedPlaylists } from 'providers/AuthProvider'
import { removeImageFile } from 'repositories/storage/firebase_playlist'
import { remove } from 'repositories/store/firebase_playlist'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * プレイリストのカテゴリ名を返却する
 */
export const getCategoryName = (
  storeCache: IStoreCache,
  playlist: IPlaylist
) => {
  return findCachedCategory(storeCache, playlist.category_id)?.name ?? ''
}

/**
 * 動画のタイトルを返却する
 */
export const getVideoTitles = (storeCache: IStoreCache, videoIds: string[]) => {
  return videoIds.map((vId) => {
    return storeCache.videos.find((v) => v.id === vId)?.name ?? ''
  })
}

/**
 * プレイリストレコード削除処理
 */
export const removePlaylist = (
  playlist: IPlaylist,
  storeCache: IStoreCache
) => {
  if (!isLoggedIn(storeCache)) return

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      if (playlist.image) {
        await removeImageFile(storeCache.team!, playlist)
      }
      await remove(storeCache.team!, playlist)
      await reloadCachedPlaylists(storeCache)
      alertService.show(true, i18nAlert('deleted.message'))
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('cantDeleted'))
    }
  })
}
