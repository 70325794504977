import Spinner from 'assets/images/spinner.svg'
import classNames from 'classnames'
import { LimitPlay } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEvent } from 'common/interfaces/event'
import { ILive } from 'common/interfaces/live'
import { IVideo } from 'common/interfaces/video'
import { makeJoinUserPath, makeLoginTeamPath } from 'common/link_path'
import PlayablePeriodCard from 'components/atoms/Card/PlayablePeriodCard'
import { History } from 'history'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import {
  getBeforeAfterTexts,
  transitionItemSelect,
} from 'services/user/limit_play'
import './limitplaycontainer.scss'

interface TLimitPlayContainer {
  storeCache: IStoreCache
  history: History
  isCatalogPage: boolean
  event: IEvent | null
  content: IVideo | ILive
  limitPlay: LimitPlay
  onToggle: () => void
}

const LimitPlayContainer: React.FC<TLimitPlayContainer> = ({
  storeCache,
  history,
  isCatalogPage,
  event,
  content,
  limitPlay,
  onToggle,
}: TLimitPlayContainer) => {
  const i18n = useTranslation('userCommon').t('limitPlayContainer', {
    returnObjects: true,
  })
  const { name, image } = content

  const CommonContainer: React.FC<{ buttons?: boolean }> = ({
    children,
    buttons,
  }) => (
    <div className={classNames('common-container', 'limit-play-container')}>
      <div>
        <div className="thumbnail__mask" />
        {image ? (
          <img src={image} alt={name} className="thumbnail__image" />
        ) : (
          <div className="thumbnail__no-image" />
        )}
      </div>
      <div className={classNames('content', { buttons })}>{children}</div>
    </div>
  )

  switch (limitPlay) {
    case LimitPlay.LOADING:
      return (
        <div className="common-container loading-container">
          <img src={Spinner} alt="spinner" className="spinner-img" />
        </div>
      )
    case LimitPlay.REGISTERED:
      return (
        <CommonContainer buttons>
          {storeCache.team!.urls.account_register && (
            <div className="limit-play-register">
              <div>{i18n.needRegisterForViewing}</div>
              <Button
                className="limit-play-button"
                onClick={() =>
                  history.push(
                    makeJoinUserPath(storeCache.team!.urls.account_register!)
                  )
                }
              >
                {i18n.userRegister}
              </Button>
            </div>
          )}
          <div className="limit-play-login">
            <div>{i18n.forRegisteredUser}</div>
            <Button
              className="limit-play-outline-button"
              onClick={() =>
                history.push(makeLoginTeamPath(storeCache.team!.id))
              }
            >
              {i18n.login}
            </Button>
          </div>
        </CommonContainer>
      )
    case LimitPlay.UNPURCHASED:
      return (
        <CommonContainer buttons>
          <div className="limit-play-unpurchased">
            <div>{i18n.needPurchasingItemForViewing}</div>
            <Button
              className="limit-play-button"
              onClick={() =>
                transitionItemSelect(isCatalogPage, history, content)
              }
            >
              {i18n.seePrices}
            </Button>
          </div>
          {!storeCache.user && (
            <div className="limit-play-login">
              <div>{i18n.forRegisteredUser}</div>
              <Button
                className="limit-play-outline-button"
                onClick={() =>
                  history.push(makeLoginTeamPath(storeCache.team!.id))
                }
              >
                {i18n.login}
              </Button>
            </div>
          )}
        </CommonContainer>
      )
    case LimitPlay.UNAPPLIED:
      return (
        <CommonContainer>
          <Button className="limit-play-color" onClick={onToggle}>
            {i18n.applyEvent}
          </Button>
        </CommonContainer>
      )
    case LimitPlay.OUTSIDE_APPLICATION_PERIOD:
      return (
        <CommonContainer>
          <PlayablePeriodCard
            className="limit-play-color"
            title={i18n.outsideApplicationPeriod}
            from={event!.application_period_from}
            to={event!.application_period_to}
          />
        </CommonContainer>
      )
    case LimitPlay.BEFORE:
    case LimitPlay.AFTER:
      const texts = getBeforeAfterTexts(content, limitPlay)
      return (
        <CommonContainer buttons>
          <div className="limit-play-before-after">
            <h1 className="message">
              {texts.note}
              <br />
              {texts.date}
            </h1>

            {limitPlay === LimitPlay.BEFORE && (
              <div className="calendar-buttons">
                <a
                  href={texts.calendarLink('google')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button className="limit-play-outline-button">
                    {i18n.video.googleCalendar}
                  </Button>
                </a>
                <a
                  href={texts.calendarLink('office')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button className="limit-play-outline-button">
                    {i18n.video.officeCalendar}
                  </Button>
                </a>
              </div>
            )}
          </div>
        </CommonContainer>
      )
    case LimitPlay.BEFORE_EVENT:
    case LimitPlay.AFTER_EVENT:
      return (
        <CommonContainer>
          <PlayablePeriodCard
            className="limit-play-color"
            title={
              limitPlay === LimitPlay.BEFORE_EVENT
                ? i18n.event.before
                : i18n.event.after
            }
            from={event!.hold_from}
            to={event!.hold_to}
          />
        </CommonContainer>
      )
    default:
      return <></>
  }
}

export default LimitPlayContainer
