import Spinner from 'assets/images/spinner.svg'
import React from 'react'
import { Row } from 'reactstrap'
import './loadingspinner.scss'

const LoadingSpinner: React.FC = () => (
  <Row>
    <div className="loading-spinner-wrapper">
      <img src={Spinner} alt="spinner" className="loading-spinner" />
    </div>
  </Row>
)

export default LoadingSpinner
