import firebase, { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IAuthForm } from 'common/interfaces/setting/auth_form'
import { IUser } from 'common/interfaces/user'
import { checkFirebaseError, isLoggedIn } from 'common/utils'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { reloadCachedUser } from 'providers/AuthProvider'
import { update } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * `IAuthForm`の初期値を返却する
 */
export const initAuthForm = (
  { user }: IStoreCache,
  currentUser: firebase.User | null
): IAuthForm => ({
  name: user?.name ?? '',
  email: currentUser?.email ?? null,
  new_email: '',
  notification_encode_complete:
    user?.admin_notification_setting.encode_completed ?? true,
  notification_user_first_subscription_payment:
    user?.admin_notification_setting.user_first_subscription_payment ?? true,
  notification_user_payment_completed:
    user?.admin_notification_setting.user_payment_completed ?? true,
  notification_user_registration_completed:
    user?.admin_notification_setting.user_registration_completed ?? true,
  notification_user_video_commented:
    user?.admin_notification_setting.user_video_commented ?? true,
  notification_user_video_enquete_answered:
    user?.admin_notification_setting.user_video_enquete_answered ?? true,
  notification_user_subscription_removed:
    user?.admin_notification_setting.user_subscription_removed ?? true,
  notification_user_account_deleted:
    user?.admin_notification_setting.user_account_deleted ?? true,
  password: '',
  new_password: '',
  new_password_confirm: '',
})

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
  EMAIL,
  NEW_EMAIL,
  NOTIFICATION_ENCODE_COMPLETE,
  NOTIFICATION_USER_FIRST_SUBSCRIPTION_PAYMENT,
  NOTIFICATION_USER_PAYMENT_COMPLETED,
  NOTIFICATION_USER_REGISTRATION_COMPLETED,
  NOTIFICATION_USER_VIDEO_COMMENTED,
  USER_VIDEO_ENQUETE_ANSWERED,
  NOTIFICATION_USER_SUBSCRIPTION_REMOVED,
  NOTIFICATION_USER_ACCOUNT_DELETED,
  PASSWORD,
  NEW_PASSWORD,
  NEW_PASSWORD_CONFIRM,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  authForm: IAuthForm,
  setAuthForm: React.Dispatch<React.SetStateAction<IAuthForm>>,
  type: InputType,
  { target: { value, checked } }: React.ChangeEvent<HTMLInputElement>
) =>
  setAuthForm({
    ...authForm,
    name: type === InputType.NAME ? value : authForm.name,
    email: type === InputType.EMAIL ? value : authForm.email,
    new_email: type === InputType.NEW_EMAIL ? value : authForm.new_email,
    notification_encode_complete:
      type === InputType.NOTIFICATION_ENCODE_COMPLETE
        ? checked
        : authForm.notification_encode_complete,
    notification_user_first_subscription_payment:
      type === InputType.NOTIFICATION_USER_FIRST_SUBSCRIPTION_PAYMENT
        ? checked
        : authForm.notification_user_first_subscription_payment,
    notification_user_payment_completed:
      type === InputType.NOTIFICATION_USER_PAYMENT_COMPLETED
        ? checked
        : authForm.notification_user_payment_completed,
    notification_user_registration_completed:
      type === InputType.NOTIFICATION_USER_REGISTRATION_COMPLETED
        ? checked
        : authForm.notification_user_registration_completed,
    notification_user_video_commented:
      type === InputType.NOTIFICATION_USER_VIDEO_COMMENTED
        ? checked
        : authForm.notification_user_video_commented,
    notification_user_video_enquete_answered:
      type === InputType.USER_VIDEO_ENQUETE_ANSWERED
        ? checked
        : authForm.notification_user_video_enquete_answered,
    notification_user_subscription_removed:
      type === InputType.NOTIFICATION_USER_SUBSCRIPTION_REMOVED
        ? checked
        : authForm.notification_user_subscription_removed,
    notification_user_account_deleted:
      type === InputType.NOTIFICATION_USER_ACCOUNT_DELETED
        ? checked
        : authForm.notification_user_account_deleted,
    password: type === InputType.PASSWORD ? value : authForm.password,
    new_password:
      type === InputType.NEW_PASSWORD ? value : authForm.new_password,
    new_password_confirm:
      type === InputType.NEW_PASSWORD_CONFIRM
        ? value
        : authForm.new_password_confirm,
  })

/**
 * `onClick`で使用する更新処理タイプ
 */
export enum UpdateType {
  NAME,
  ADMIN_NOTIFICATION_SETTING,
  EMAIL,
  PASSWORD,
}

/**
 * ユーザアカウント名の更新処理
 */
export const updateUser = async (
  type: UpdateType,
  authForm: IAuthForm,
  storeCache: IStoreCache,
  currentUser: firebase.User | null
): Promise<void> => {
  if (!isLoggedIn(storeCache, true)) return

  try {
    switch (type) {
      case UpdateType.NAME:
        await updateName(authForm, storeCache)
        break
      case UpdateType.ADMIN_NOTIFICATION_SETTING:
        await updateAdminNotificationSetting(authForm, storeCache)
        break
      case UpdateType.EMAIL:
        await updateAuthEmail(authForm, storeCache, currentUser)
        break
      case UpdateType.PASSWORD:
        await updateAuthPassword(authForm, currentUser)
        break
    }
  } catch (error) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, message)
  }
}

const updateName = async (
  { name }: IAuthForm,
  storeCache: IStoreCache
): Promise<void> => {
  if (!name) throw new Error(i18nValidation('input.name'))

  const user: IUser = storeCache.user!
  user.name = name

  await update(storeCache.team!, user)
  await reloadCachedUser(storeCache)

  alertService.show(true, i18nAlert('updated.name'))
}

export const updateAdminNotificationSetting = async (
  {
    notification_encode_complete,
    notification_user_first_subscription_payment,
    notification_user_payment_completed,
    notification_user_registration_completed,
    notification_user_video_commented,
    notification_user_video_enquete_answered,
    notification_user_subscription_removed,
    notification_user_account_deleted,
  }: IAuthForm,
  storeCache: IStoreCache
): Promise<void> => {
  const user: IUser = storeCache.user!
  user.admin_notification_setting = {
    encode_completed: notification_encode_complete,
    user_first_subscription_payment:
      notification_user_first_subscription_payment,
    user_payment_completed: notification_user_payment_completed,
    user_registration_completed: notification_user_registration_completed,
    user_video_commented: notification_user_video_commented,
    user_video_enquete_answered: notification_user_video_enquete_answered,
    user_subscription_removed: notification_user_subscription_removed,
    user_account_deleted: notification_user_account_deleted,
  }

  await update(storeCache.team!, user)
  await reloadCachedUser(storeCache)

  alertService.show(true, i18nAlert('updated.notification'))
}

export const updateAuthEmail = async (
  { new_email, password }: IAuthForm,
  storeCache: IStoreCache,
  currentUser: firebase.User | null
): Promise<void> => {
  if (!new_email || !password) throw new Error(i18nValidation('input.field'))

  await auth.signInWithEmailAndPassword(String(currentUser?.email), password)
  modalService.show(i18nAlert('modal.confirm.change.email'), async () => {
    try {
      const user: IUser = storeCache.user!
      user.email = new_email

      await currentUser?.updateEmail(new_email!)
      await update(storeCache.team!, user)
      await reloadCachedUser(storeCache)

      alertService.show(true, i18nAlert('updated.email'))
    } catch (error) {
      const message = checkFirebaseError(error)
      console.log(message)
      alertService.show(false, message)
    }
  })
}

export const updateAuthPassword = async (
  { password, new_password, new_password_confirm }: IAuthForm,
  currentUser: firebase.User | null
): Promise<void> => {
  if (!password || !new_password || !new_password_confirm) {
    throw new Error(i18nValidation('input.field'))
  }
  if (new_password !== new_password_confirm) {
    throw new Error(
      i18nValidation('setting.password.different.confirmPassword')
    )
  }

  await auth.signInWithEmailAndPassword(String(currentUser?.email), password)
  modalService.show(i18nAlert('modal.confirm.change.password'), async () => {
    try {
      await currentUser?.updatePassword(new_password)

      alertService.show(true, i18nAlert('updated.password'))
    } catch (error) {
      const message = checkFirebaseError(error)
      console.log(message)
      alertService.show(false, message)
    }
  })
}
