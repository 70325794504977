import { Routes } from 'common/enums'
import { goBackWithErrorAlert } from 'common/utils'
import { AuthContext } from 'providers/AuthProvider'
import React, { CSSProperties, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Card, CardText, CardTitle, Col, Row } from 'reactstrap'
import { transitionCreateAccountInStripe } from 'services/admin/billing'
import { modalService } from 'services/modal'

const LinkBody: React.FC = () => {
  const { t } = useTranslation('adminStripeBilling')
  const { t: i18nAlert } = useTranslation('alert')
  const { team } = useContext(AuthContext).storeCache
  const history = useHistory()

  if (!team) {
    goBackWithErrorAlert(history, i18nAlert('noOperationAuthority'))
    return null
  }

  const showStripeConnectModal = () =>
    modalService.showStripeConnect(() => transitionCreateAccountInStripe(team))

  const cardStyle: CSSProperties = { color: 'unset', cursor: 'pointer' }

  return (
    <Row>
      <Col sm="12" className="mb-4">
        <Card body onClick={showStripeConnectModal} style={cardStyle}>
          <CardTitle tag="h4">{t('cards.connect.title')}</CardTitle>
          <CardText>{t('cards.connect.note')}</CardText>
        </Card>
      </Col>
      <Col sm="12" className="mb-4">
        <Link to={Routes.AdminStripe} style={cardStyle}>
          <Card body>
            <CardTitle tag="h4">{t('cards.management.title')}</CardTitle>
            <CardText>{t('cards.management.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-4">
        <Link to={Routes.AdminStripeTaxRate} style={cardStyle}>
          <Card body>
            <CardTitle tag="h4">{t('cards.taxRate.title')}</CardTitle>
            <CardText>{t('cards.taxRate.note')}</CardText>
          </Card>
        </Link>
      </Col>
    </Row>
  )
}

export default LinkBody
