import { ColumnDef } from '@tanstack/react-table'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEnquete } from 'common/interfaces/enquete'
import { IFlattedLog, ILog } from 'common/interfaces/log'
import { ITest } from 'common/interfaces/test'
import { IUser } from 'common/interfaces/user'
import { isLoggedIn, userUid } from 'common/utils'
import {
  flattedLog,
  getAccountCreatedLog,
  getLogTableColumns,
} from 'common/utils_log'
import { customFieldColumnDef } from 'components/atoms/Table/CreateTable'
import { i18nAdminAnalytics } from 'i18n/i18n'
import { getAll as getAllLogs } from 'repositories/store/firebase_log'
import { getNewer } from 'repositories/store/firebase_user'

/**
 * すべてのユーザーを取得する
 */
export const getUsers = async (
  storeCache: IStoreCache,
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>
) => {
  if (!isLoggedIn(storeCache)) return

  const users = await getNewer(storeCache.team!)
  setUsers(users)
}

/**
 * 全ユーザーのログを取得する
 */
export const getAllUserLogs = async (
  storeCache: IStoreCache,
  setAllUserLogs: React.Dispatch<React.SetStateAction<ILog[]>>
) => {
  if (!isLoggedIn(storeCache)) return

  const logs = await getAllLogs(storeCache.team!)
  setAllUserLogs(logs)
}

/**
 * 全ユーザーのログを出力するCSVのカラム
 */
export const getAllUserLogColumns = (
  storeCache: IStoreCache,
  allUsers: IUser[],
  isAuthEmail: boolean,
  allData: {
    enquetes: IEnquete[]
    tests: ITest[]
  }
): ColumnDef<IFlattedLog>[] => {
  const baseColumns = getLogTableColumns(storeCache, {
    enquetes: allData.enquetes,
    tests: allData.tests,
  })

  const i18n = i18nAdminAnalytics('userTable', { returnObjects: true })
  return [
    {
      header: 'ID',
      accessorFn: (l) => (isAuthEmail ? l.user_id : userUid(l.user_id)),
    },
    {
      header: i18n.email,
      accessorFn: (l) => allUsers.find((u) => u.id === l.user_id)?.email ?? '',
    },
    {
      header: i18n.name,
      accessorFn: (l) => allUsers.find((u) => u.id === l.user_id)?.name ?? '',
    },
    baseColumns[0],
    ...customFieldColumnDef<IFlattedLog>(storeCache.team, allUsers),
    ...baseColumns.slice(1),
  ]
}

/**
 * 全ユーザーのログを出力するCSVのデータ
 */
export const getAllUserLogData = (
  allUsers: IUser[],
  allUserLogs: ILog[]
): IFlattedLog[] => {
  return allUserLogs.flatMap((log) => {
    const user = allUsers.find((u) => u.id === log.user_id)
    const logs = flattedLog(log)
    if (user) {
      logs.unshift(getAccountCreatedLog(user))
    }
    return logs
  })
}
