import { IStoreCache } from 'common/interfaces/auth_provider'
import { ITest } from 'common/interfaces/test'
import { IVideo } from 'common/interfaces/video'
import { isLoggedIn } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { get, remove } from 'repositories/store/firebase_test'
import { get as getTestAnswers } from 'repositories/store/firebase_test_answer'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

export interface ITestData {
  test: ITest
  answerCount: number
}

/**
 * テスト取得処理
 */
export const getTests = async (
  storeCache: IStoreCache,
  setTests: React.Dispatch<React.SetStateAction<ITest[]>>
) => {
  setTests(await get(storeCache.team!))
}

/**
 * テストを使用している動画の件数を返却する
 */
export const getTestUseCount = (test: ITest, videos: IVideo[]) => {
  return videos.filter((video) => video.test?.test_id === test.id).length
}

/**
 * テストと回答数を取得
 */
export const getTestsWithAnswerCount = async (
  storeCache: IStoreCache,
  setTestDatas: React.Dispatch<React.SetStateAction<ITestData[]>>
): Promise<void> => {
  try {
    const result = (await get(storeCache.team!)).map((e) => ({
      test: e,
      answerCount: 0,
    }))

    const putTestAnswerCount = async (data: ITestData) => {
      data.answerCount = (
        await getTestAnswers(storeCache.team!, data.test)
      ).length
    }

    const getAnswersTasks = result.map((d) => putTestAnswerCount(d))
    await Promise.all(getAnswersTasks)
    setTestDatas(result)
  } catch (error) {
    console.log(error)
  }
}

/**
 * テスト削除処理
 */
export const removeTest = async (
  storeCache: IStoreCache,
  test: ITest,
  setTests: React.Dispatch<React.SetStateAction<ITestData[]>>
) => {
  if (!isLoggedIn(storeCache)) return
  if (getTestUseCount(test, storeCache.videos) > 0) {
    alertService.show(false, i18nAlert('cantDelete.inUse.test'))
    return
  }

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      await remove(storeCache.team!, test)
      getTestsWithAnswerCount(storeCache, setTests)
    } catch (error) {
      console.log(error)
    }
  })
}
