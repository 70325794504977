import FooterLogo from 'assets/images/footer_logo.png'
import { IFindTeam, ITeam } from 'common/interfaces/team'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap'
import './footer.scss'

interface IFooter {
  team?: ITeam | IFindTeam
}

const Footer: React.FC<IFooter> = (props: IFooter) => {
  const { t } = useTranslation('common')
  const team = useContext(AuthContext).storeCache.team || props.team
  const teamCompany = team?.company || ''
  const { about, terms, contact, faq, law } = team?.urls ?? {
    about: null,
    terms: null,
    contact: null,
    faq: null,
    law: null,
  }

  return (
    <footer className="footer" id="footer">
      <Container>
        <Row>
          <Col>
            <Nav className="justify-content-center">
              {about && (
                <NavItem>
                  <NavLink
                    href={about}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('footer.companyInfo')}
                  </NavLink>
                </NavItem>
              )}
              {terms && (
                <NavItem>
                  <NavLink
                    href={terms}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('footer.terms')}
                  </NavLink>
                </NavItem>
              )}
              {contact && (
                <NavItem>
                  <NavLink
                    href={contact}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('footer.contact')}
                  </NavLink>
                </NavItem>
              )}
              {faq && (
                <NavItem>
                  <NavLink href={faq} target="_blank" rel="noopener noreferrer">
                    {t('footer.faq')}
                  </NavLink>
                </NavItem>
              )}
              {law && (
                <NavItem>
                  <NavLink href={law} target="_blank" rel="noopener noreferrer">
                    {t('footer.law')}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Nav className="justify-content-center small">
              <NavItem>
                <NavLink>Copyright {teamCompany}. All Rights Reserved.</NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        {team?.hide_footer_logo || (
          <Row className="mt-2">
            <Col>
              <Nav className="justify-content-center small">
                <NavItem>
                  <NavLink
                    href="https://one-stream.site"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Powered by
                    <img
                      src={FooterLogo}
                      alt="footer logo"
                      style={{
                        height: '1rem',
                        verticalAlign: 'text-bottom',
                        marginLeft: '0.4rem',
                      }}
                    />
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        )}
      </Container>
    </footer>
  )
}

export default Footer
