import { IComment, ICommentInfo } from 'common/interfaces/comment'
import { ITeam } from 'common/interfaces/team'
import { IVideo } from 'common/interfaces/video'
import { removeAllReplyCommentInComment } from 'repositories/store/firebase_reply_comment'
import { Teams } from './firebase_team'

export const Comments = (team: ITeam, video: IVideo) =>
  Teams.doc(team.id).collection('videos').doc(video.id).collection('comments')

/**
 * get comments
 */
export const get = async (team: ITeam, video: IVideo): Promise<IComment[]> => {
  const commentDatas = await Comments(team, video)
    .orderBy('created_at', 'desc')
    .get()
  return commentDatas.docs.map((comment) => comment.data() as IComment)
}

/**
 * add comment in video
 * @throws Firebase error
 */
export const store = async (
  team: ITeam,
  video: IVideo,
  comment: IComment
): Promise<void> => {
  await Comments(team, video).doc(comment.id).set(comment)
}

/**
 * delete comment in video
 * @throws Firebase error
 */
export const remove = async (
  team: ITeam,
  video: IVideo,
  comment: IComment | ICommentInfo
): Promise<void> => {
  await Comments(team, video).doc(comment.id).delete()
  await removeAllReplyCommentInComment(team, video, comment)
}

/**
 * delete all comments in video
 * @throws Firebase error
 */
export const removeAllCommentsInVideo = async (
  team: ITeam,
  video: IVideo
): Promise<void> => {
  const comments = await get(team, video)
  const removeTask = comments.map((comment) => remove(team, video, comment))
  await Promise.all(removeTask)
}
