import { ColumnDef } from '@tanstack/react-table'
import { ILiveUsersViewCount } from 'common/interfaces/analytics'
import { IFlattedLog } from 'common/interfaces/log'
import { IFromTo } from 'common/interfaces/time'
import { IUser } from 'common/interfaces/user'
import { isAuthMethodEmail } from 'common/utils'
import {
  customFieldColumnDef,
  useTanstackTableWithCsvExport,
} from 'components/atoms/Table/CreateTable'
import { makePrimaryBadges } from 'components/atoms/Table/ElementsOnTable'
import AnalyticsRangeSelector from 'components/molecules/Admin/AnalyticsRangeSelector'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'reactstrap'
import {
  DatePeriod,
  getSortedUserLogs,
  initFromTo,
  setMonthSelectOptions,
} from 'services/admin/analytics'
import { setLiveUsersViewCountData } from 'services/admin/analytics_live_users'
import { getUsers } from 'services/admin/analytics_video_users'

const AnalyticsLiveUsers: React.FC = () => {
  const { t } = useTranslation('adminAnalytics')
  const { storeCache } = useContext(AuthContext)
  const [users, setUsers] = useState<IUser[]>([])
  const [allUserLogs, setAllUserLogs] = useState<IFlattedLog[]>([])
  const [monthOptions, setMonthOptions] = useState<JSX.Element[]>([])
  const [selectedMonth, setSelectedMonth] = useState<string>('')
  const [currentLiveId, setCurrentLiveId] = useState<string>('')
  const [liveUsersViewCounts, setLiveUsersViewCounts] = useState<
    ILiveUsersViewCount[]
  >([])
  const [selectedDatePeriod, setSelectedDatePeriod] = useState<DatePeriod>(
    DatePeriod.DAYS_7
  )
  const [fromTo, setFromTo] = useState<IFromTo>(initFromTo())

  useEffect(() => {
    getUsers(storeCache, setUsers)
    getSortedUserLogs(storeCache, setAllUserLogs)
  }, [storeCache])

  useEffect(() => {
    setMonthSelectOptions(allUserLogs, setMonthOptions, setSelectedMonth)
  }, [allUserLogs])

  useEffect(() => {
    setLiveUsersViewCountData(
      storeCache,
      users,
      allUserLogs,
      currentLiveId,
      selectedDatePeriod,
      setLiveUsersViewCounts,
      { date: selectedMonth, fromTo }
    )
  }, [
    storeCache,
    users,
    allUserLogs,
    currentLiveId,
    selectedDatePeriod,
    selectedMonth,
    fromTo,
  ])

  if (!storeCache.team) return null

  const liveOptions = storeCache.lives.map(({ id, name }) => (
    <option key={id} value={id}>
      {name}
    </option>
  ))

  return (
    <>
      <AnalyticsRangeSelector
        className="mb-3"
        monthOptions={monthOptions}
        selectedDatePeriod={[selectedDatePeriod, setSelectedDatePeriod]}
        selectedMonth={[selectedMonth, setSelectedMonth]}
        fromTo={[fromTo, setFromTo]}
      >
        <Input onChange={(e) => setCurrentLiveId(e.target.value)} type="select">
          <option value="">{t('pleaseSelect')}</option>
          {liveOptions}
        </Input>
      </AnalyticsRangeSelector>
      <div>
        {liveUsersViewCounts.length <= 0 && (
          <div className="mt-5">{t('noHistory')}</div>
        )}
        {liveUsersViewCounts.length > 0 && (
          <Table viewCounts={liveUsersViewCounts} allUsers={users} />
        )}
      </div>
    </>
  )
}

const Table: React.FC<{
  viewCounts: ILiveUsersViewCount[]
  allUsers: IUser[]
}> = ({ viewCounts, allUsers }) => {
  const { t } = useTranslation('adminAnalytics')
  const { storeCache } = useContext(AuthContext)
  const isAuthEmail = isAuthMethodEmail(storeCache.team!)

  const columns = useMemo<ColumnDef<ILiveUsersViewCount>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: 'user_id',
        meta: { hidden: isAuthEmail },
      },
      {
        header: t('userTable.email'),
        accessorKey: 'user_email',
        meta: { hidden: !isAuthEmail, csvExport: isAuthEmail },
      },
      {
        header: t('userTable.name'),
        accessorKey: 'user_name',
      },
      {
        header: t('userTable.phone'),
        accessorKey: 'user_phone',
        meta: { hidden: !isAuthEmail, csvExport: isAuthEmail },
      },
      {
        header: t('userTable.groups'),
        accessorKey: 'user_groups',
        cell: (cell) => makePrimaryBadges(cell.getValue<string[]>()),
        meta: { hidden: !isAuthEmail, csvExport: isAuthEmail },
      },
      ...customFieldColumnDef<ILiveUsersViewCount>(storeCache.team!, allUsers),
      {
        header: t('logType.livePageOpen'),
        accessorKey: 'page_open_count',
      },
    ],
    [storeCache.team, isAuthEmail, allUsers, t]
  )

  return useTanstackTableWithCsvExport<ILiveUsersViewCount>(
    columns,
    viewCounts,
    'live_users_view_counts.csv'
  )
}

export default AnalyticsLiveUsers
