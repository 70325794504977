import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IUser } from 'common/interfaces/user'
import { makeLoginTeamPath } from 'common/link_path'
import { isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAdminCommon, i18nAlert } from 'i18n/i18n'
import { removeUser } from 'repositories/functions/functions_auth'
import { getNewer } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * adminがtrueのユーザーのみを取得
 */
export const getNewerAdministrators = async (
  setData: React.Dispatch<React.SetStateAction<IUser[]>>,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  const users = await getNewer(storeCache.team!)
  setData(users.filter(({ admin }) => admin))
}

/**
 * 削除可能な管理者かどうか
 */
export const canRemoveAdministrator = (
  storeCache: IStoreCache,
  { id }: IUser,
  isSelf = false
): boolean => {
  return (
    (isLoggedIn(storeCache, true) &&
      id !== storeCache.team!.id &&
      id !== storeCache.user!.id) ||
    isSelf
  )
}

/**
 * Zoomの設定画面へ遷移
 */
export const moveZoomSetting = (history: History): void => {
  history.push(Routes.AdminSettingZoomSetting)
}

/**
 * 管理者の削除処理
 */
export const removeAdministrator = async (
  storeCache: IStoreCache,
  user: IUser,
  history: History,
  isSelf = false
) => {
  if (!canRemoveAdministrator(storeCache, user, isSelf)) return

  const content = isSelf ? i18nAdminCommon('account') : i18nAdminCommon('admin')

  modalService.show(
    i18nAlert('modal.confirm.deleteAccount', { account: content }),
    async () => {
      try {
        if (isSelf) {
          const administrators = (await getNewer(storeCache.team!)).filter(
            ({ admin }) => admin
          )
          if (administrators.length <= 1) {
            throw new Error(i18nAlert('cantDelete.notExistAdmin'))
          }
        }

        await removeUser(storeCache.team!.id, storeCache.user!.id, user.id)
        alertService.show(
          true,
          i18nAlert('deleted.account', { account: content })
        )
        history.replace(
          isSelf
            ? makeLoginTeamPath(storeCache.team?.id ?? '')
            : Routes.AdminAdministrator
        )
      } catch (error: any) {
        alertService.show(false, error.message)
        console.error(error)
      }
    }
  )
}
