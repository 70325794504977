import i18next from 'i18next'

export const i18nCommon = i18next.getFixedT(null, 'common')
export const i18nAlert = i18next.getFixedT(null, 'alert')
export const i18nAdminCommon = i18next.getFixedT(null, 'adminCommon')
export const i18nAdminVideo = i18next.getFixedT(null, 'adminVideo')
export const i18nAdminPlaylist = i18next.getFixedT(null, 'adminPlaylist')
export const i18nAdminLive = i18next.getFixedT(null, 'adminLive')
export const i18nAdminEvent = i18next.getFixedT(null, 'adminEvent')
export const i18nAdminNews = i18next.getFixedT(null, 'adminNews')
export const i18nAdminSendEmail = i18next.getFixedT(null, 'adminSendEmail')
export const i18nAdminMailTemplate = i18next.getFixedT(
  null,
  'adminMailTemplate'
)
export const i18nAdminEnquete = i18next.getFixedT(null, 'adminEnquete')
export const i18nAdminTest = i18next.getFixedT(null, 'adminTest')
export const i18nAdminAnalytics = i18next.getFixedT(null, 'adminAnalytics')
export const i18nAdminUser = i18next.getFixedT(null, 'adminUser')
export const i18nAdminUserGroup = i18next.getFixedT(null, 'adminUserGroup')
export const i18nAdminAdministrator = i18next.getFixedT(
  null,
  'adminAdministrator'
)
export const i18nAdminInvite = i18next.getFixedT(null, 'adminInvite')
export const i18nAdminCustomizeFields = i18next.getFixedT(
  null,
  'adminCustomizeFields'
)
export const i18nAdminSiteCustomize = i18next.getFixedT(
  null,
  'adminSiteCustomize'
)
export const i18nAdminCategory = i18next.getFixedT(null, 'adminCategory')
export const i18nAdminTag = i18next.getFixedT(null, 'adminTag')
export const i18nAdminCreator = i18next.getFixedT(null, 'adminCreator')
export const i18nAdminFilelist = i18next.getFixedT(null, 'adminFilelist')
export const i18nAdminStripeProduct = i18next.getFixedT(
  null,
  'adminStripeProduct'
)
export const i18nAdminStripePrice = i18next.getFixedT(null, 'adminStripePrice')
export const i18nAdminStripeCoupon = i18next.getFixedT(
  null,
  'adminStripeCoupon'
)
export const i18nAdminBasicInfo = i18next.getFixedT(null, 'adminBasicInfo')
export const i18nAdminSetting = i18next.getFixedT(null, 'adminSetting')
export const i18nAdminContractingPlan = i18next.getFixedT(
  null,
  'adminContractingPlan'
)
export const i18nAdminPaidHistory = i18next.getFixedT(null, 'adminPaidHistory')
export const i18nUserCommon = i18next.getFixedT(null, 'userCommon')
export const i18nUserIndex = i18next.getFixedT(null, 'userIndex')
export const i18nUserVideo = i18next.getFixedT(null, 'userVideo')
export const i18nUserVideoList = i18next.getFixedT(null, 'userVideoList')
export const i18nUserLive = i18next.getFixedT(null, 'userLive')
export const i18nUserSetting = i18next.getFixedT(null, 'userSetting')
export const i18nUserSubscription = i18next.getFixedT(null, 'userSubscription')
export const i18nRegister = i18next.getFixedT(null, 'register')
export const i18nJoin = i18next.getFixedT(null, 'join')
export const i18nItemSelect = i18next.getFixedT(null, 'itemSelect')
export const i18nMailTemplate = i18next.getFixedT(null, 'mailTemplate')
export const i18nValidation = i18next.getFixedT(null, 'validation')
