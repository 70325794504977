import React from 'react'
import { Col, ListGroupItem, Row } from 'reactstrap'

type TPaidList = {
  index: number
  col_1: string
  col_2: string
  col_3: string
  col_4: JSX.Element
}

const PaidList: React.FC<TPaidList> = ({
  index,
  col_1,
  col_2,
  col_3,
  col_4,
}: TPaidList) => (
  <ListGroupItem key={index} className="paid-list-group-item">
    <Row>
      <Col sm={3}>{col_1}</Col>
      <Col sm={3}>{col_2}</Col>
      <Col sm={3}>{col_3}</Col>
      <Col sm={3}>{col_4}</Col>
    </Row>
  </ListGroupItem>
)

export default PaidList
