import firebase from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IFilelist } from 'common/interfaces/filelist'
import { ILive } from 'common/interfaces/live'
import { IVideo } from 'common/interfaces/video'
import {
  isAfterTimestamp,
  isBeforeTimestamp,
  unix2Timestamp,
} from 'common/times'
import { downloadFile as commonDlFile, isLiveVideo } from 'common/utils'
import { addFilelistDownloadedLog } from 'repositories/store/firebase_log'

/**
 * 添付のファイルをダウンロードする
 */
export const downloadFile = (
  filelist: IFilelist,
  { team, user }: IStoreCache,
  obj: IVideo | ILive
): void => {
  const isDownloaded = commonDlFile(filelist)
  if (isDownloaded && !isLiveVideo(obj)) {
    addFilelistDownloadedLog(team!, user!, obj as IVideo, filelist)
  }
}

/**
 * プレミア公開の動画の終了日時を取得
 * @returns premiere end date
 */
export const getPremiereEndDate = (
  video: IVideo
): firebase.firestore.Timestamp | null => {
  if (!video.is_premiere || !video.playable_period) {
    return null
  }
  const endUnixtime = video.playable_period.from.seconds + video.bunny.length
  return unix2Timestamp(endUnixtime)
}

/**
 * 動画の再生開始日時より前か
 */
export const isBeforePlayablePeriod = (video: IVideo): boolean => {
  if (!video.playable_period) return false
  return isBeforeTimestamp(video.playable_period.from)
}

/**
 * 動画の再生終了日時を過ぎているか
 */
export const isAfterPlayablePeriod = (video: IVideo): boolean => {
  const premiereEndTime = getPremiereEndDate(video)
  if (premiereEndTime) {
    return isAfterTimestamp(premiereEndTime)
  }
  if (!video.is_premiere && video.playable_period) {
    return isAfterTimestamp(video.playable_period.to)
  }
  return false
}
