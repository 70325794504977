import { EmailAction, Routes, SendEmailStatus } from 'common/enums'
import { findCachedGroupsNonNull } from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ISendEmail } from 'common/interfaces/send_email'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { makeSettingPath } from 'common/link_path'
import {
  date2Timestamp,
  formatStartOfHour,
  nowTimeFormat,
  nowTimestamp,
} from 'common/times'
import { createGroupOptions, isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAdminSendEmail, i18nAlert } from 'i18n/i18n'
import { send } from 'repositories/functions/functions_mail'
import { store, update } from 'repositories/store/firebase_send_email'
import { getNotifyNewsUsers } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/send_email'
import { v4 as uuidv4 } from 'uuid'

/**
 * `ISendEmail`の初期値を返却する
 */
export const initSendEmail = (): ISendEmail => ({
  id: uuidv4(),
  title: i18nAdminSendEmail('form.initSendEmail.title', {
    nowTime: nowTimeFormat(),
  }),
  subject: '',
  body: '',
  group_ids: [],
  status: SendEmailStatus.SEND_NOW,
  send_at: date2Timestamp(new Date(formatStartOfHour())),
  created_at: nowTimestamp(),
  updated_at: nowTimestamp(),
})

/**
 * Input/Selectで使用するoptionsを返却する
 */
export const getFormOptions = ({ groups }: IStoreCache) => ({
  groupOptions: createGroupOptions(groups),
})

/**
 * デフォルト値を返却する
 */
export const getSendEmailDefaults = (
  storeCache: IStoreCache,
  { group_ids }: ISendEmail
) => ({
  defaultGroups: findCachedGroupsNonNull(storeCache, group_ids).map(
    ({ id, name }) => ({ value: id, label: name })
  ),
})

/**
 * メール通知が有効になっているユーザーを取得する
 */
export const getNotifyUsers = async (
  storeCache: IStoreCache,
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>
) => {
  if (!isLoggedIn(storeCache)) return

  const users = await getNotifyNewsUsers(storeCache.team!)
  setUsers(users)
}

/**
 * メール通知の対象になっているユーザーを返却する
 */
export const sendEmailUsers = (users: IUser[], groupIds: string[]) => {
  return users.filter((user) => {
    const isNotify = Boolean(
      !user.admin && user.email && user.notification_setting.news
    )
    const isBelongGroup = user.group_ids.some((groupId) =>
      groupIds.some((gi) => groupId === gi)
    )
    return groupIds.length > 0 ? isNotify && isBelongGroup : isNotify
  })
}

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  TITLE,
  SUBJECT,
  BODY,
  GROUP_IDS,
  STATUS,
  SEND_AT,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  sendEmail: ISendEmail,
  setSendEmail: React.Dispatch<React.SetStateAction<ISendEmail>>,
  type: InputType,
  e: any
) => {
  const val = e.target?.value
  setSendEmail({
    ...sendEmail,
    title: type === InputType.TITLE ? val : sendEmail.title,
    subject: type === InputType.SUBJECT ? val : sendEmail.subject,
    body: type === InputType.BODY ? e : sendEmail.body,
    group_ids:
      type === InputType.GROUP_IDS
        ? e.map(({ value }: any) => value)
        : sendEmail.group_ids,
    status:
      type === InputType.STATUS
        ? (Number(val) as SendEmailStatus)
        : sendEmail.status,
    send_at:
      type === InputType.SEND_AT
        ? date2Timestamp(new Date(val))
        : sendEmail.send_at,
  })
}

/**
 * メールレコード作成処理
 */
export const saveSendEmail = async (
  { push }: History,
  sendEmail: ISendEmail,
  storeCache: IStoreCache,
  isCreate: boolean,
  fromNews = false
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    validateForm(sendEmail)

    const team = storeCache.team!

    sendEmail.send_at = date2Timestamp(
      new Date(formatStartOfHour(sendEmail.send_at))
    )

    if (isCreate) {
      sendEmail.created_at = nowTimestamp()
      sendEmail.updated_at = nowTimestamp()
      await store(team, sendEmail)
    } else {
      if (sendEmail.status === SendEmailStatus.SENT) {
        throw new Error(i18nAlert('cantUpdate.sentEmail'))
      }

      sendEmail.updated_at = nowTimestamp()
      await update(team, sendEmail)
    }

    await sendMailToMember(team, sendEmail, fromNews)

    if (fromNews) return

    push(Routes.AdminSendEmail)
    alertService.show(true, i18nAlert('saved.email'))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

const sendMailToMember = async (
  team: ITeam,
  { id, status, group_ids, subject, body }: ISendEmail,
  fromNews: boolean
): Promise<void> => {
  if (status !== SendEmailStatus.SEND_NOW) return

  const users = await getNotifyNewsUsers(team)
  const emails = sendEmailUsers(users, group_ids).map(({ email }) => email)

  const settingPath = `${window.location.origin}${makeSettingPath(team.id)}`
  const unsubscribeFlow = `<br />
  ${i18nAlert('unsubscribeFlow', {
    unsubscribePath: settingPath,
  })}`

  if (fromNews) {
    body = `${i18nAlert('fromTeam', { teamName: team.name, lng: team.lang })}
    <br />
    ${body}
    ${unsubscribeFlow}`
  } else {
    body += unsubscribeFlow
  }

  await send(
    team.id,
    EmailAction.SENT,
    id,
    undefined,
    { name: team.name, email: team.email! },
    emails,
    { name: team.name, email: team.email! },
    subject,
    body,
    body,
    [],
    true
  )
}
