import {
  FieldCustomizePublicStatus,
  IFieldCustomize,
  IFieldCustomizeType,
} from 'common/interfaces/field_customize'
import { ICustomizeFields } from 'common/interfaces/user'
import { inputType, str2hash } from 'common/utils'
import React from 'react'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'

interface ICustomizeFieldForm {
  colNum: number
  customizeField: ICustomizeFields[] | null | undefined
  customizeFieldObj: IFieldCustomize
  userCustomizeField: ICustomizeFields[] | null | undefined
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  hideOnlyAdmin?: boolean
}

const CustomizeFieldForm: React.VFC<ICustomizeFieldForm> = (props) => {
  const { fieldName, type, options, publicStatus } = props.customizeFieldObj

  if (
    props.hideOnlyAdmin &&
    publicStatus === FieldCustomizePublicStatus.ONLY_ADMIN
  ) {
    return null
  }

  const userCustomizeFieldObj = props.userCustomizeField?.find(
    (ucf) => Object.keys(ucf)[0] === fieldName
  )
  const userSelectedObj = props.customizeField?.find(
    (ucf) => Object.keys(ucf)[0] === fieldName
  )
  const val = userCustomizeFieldObj && Object.values(userCustomizeFieldObj)[0]
  const selectedVal = userSelectedObj && Object.values(userSelectedObj)[0]
  switch (type) {
    case IFieldCustomizeType.TEXT:
    case IFieldCustomizeType.TEXTAREA:
    case IFieldCustomizeType.NUMBER:
    case IFieldCustomizeType.DATE:
      return (
        <FormGroup className="mb-3" row>
          <Col md={props.colNum}>
            <Label>{fieldName}</Label>
            <Input
              placeholder={fieldName}
              type={inputType(type)}
              onChange={props.onChange}
              defaultValue={val}
            />
          </Col>
        </FormGroup>
      )
    case IFieldCustomizeType.BOOL:
      return (
        <FormGroup row check className="mb-3">
          <Col>
            <Label check>
              <Input
                onChange={props.onChange}
                type="checkbox"
                defaultChecked={val}
              />
              {fieldName}
            </Label>
          </Col>
        </FormGroup>
      )
    case IFieldCustomizeType.RADIO:
      return (
        <FormGroup row check className="mb-3">
          <Row>
            <Col md="10">
              <Label>{fieldName}</Label>
            </Col>
          </Row>
          {options?.map((option, optionkey) => {
            const nameHash = str2hash(fieldName)
            const name = `customizeRadio${nameHash}`
            const idName = `customizeRadio${nameHash}-${optionkey}`
            return (
              <div
                className="custom-control custom-control-alternative custom-radio mb-3"
                key={optionkey}
              >
                <input
                  className="custom-control-input"
                  id={idName}
                  name={name}
                  type="radio"
                  value={option}
                  onChange={props.onChange}
                  checked={selectedVal === option}
                />
                <Label className="custom-control-label" for={idName}>
                  {option}
                </Label>
              </div>
            )
          })}
        </FormGroup>
      )
    case IFieldCustomizeType.PULLDOWN:
      return (
        <FormGroup row className="mb-3">
          <Col md={props.colNum}>
            <Label>{fieldName}</Label>
            <Input type="select" value={selectedVal} onChange={props.onChange}>
              <option />
              {options?.map((option, key) => (
                <option key={key} value={option}>
                  {option}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
      )
  }
}

export default CustomizeFieldForm
