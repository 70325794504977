import firebase, { db } from 'common/firebase'
import { IBookmark } from 'common/interfaces/bookmark'
import { IInvite, IInviteAndApproved } from 'common/interfaces/invite'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { IVideo } from 'common/interfaces/video'
import { nowTimestamp } from 'common/times'
import { i18nAlert } from 'i18n/i18n'
import { Teams } from './firebase_team'
import {
  initWatchLater,
  removeAll as removeAllWatchlist,
} from './firebase_watchlist'

export const Users = (team: ITeam) => Teams.doc(team.id).collection('users')
const UsersGroup = db.collectionGroup('users')

/**
 * get all users in team
 *
 * order by created_at
 */
export const getNewer = async (team: ITeam): Promise<IUser[]> => {
  try {
    const userDatas = await Users(team).orderBy('created_at', 'desc').get()
    return userDatas.docs.map((user) => user.data() as IUser)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * find user in team
 */
export const findById = async (
  team: ITeam,
  userId: string
): Promise<IUser | null> => {
  try {
    const userData = await Users(team).doc(userId).get()
    if (userData.exists) {
      return userData.data() as IUser
    }
  } catch (error) {
    console.log(error)
  }
  return null
}

/**
 * find team and user
 */
export const findTeamUserById = async (
  uid: string
): Promise<{ team: ITeam; user: IUser }[] | null> => {
  try {
    const userDocs = (await UsersGroup.where('id', '==', uid).get()).docs
    if (userDocs.length <= 0) {
      throw new Error(i18nAlert('noUser'))
    }

    const getTeamTasks = userDocs.map(async (userDoc) => {
      const user = userDoc.data() as IUser

      const teamRef = userDoc.ref.parent.parent!
      const teamDoc = await teamRef.get()
      if (!teamDoc.exists) {
        throw new Error(i18nAlert('noTeam'))
      }
      const team = teamDoc.data() as ITeam

      return { team, user }
    })

    return await Promise.all(getTeamTasks)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * get approved user and unapproved user and registeredAdminUsers objects
 */
export const getApprovedUserAndUnapprovedUserObjects = async (
  team: ITeam,
  invites: IInvite[]
): Promise<IInviteAndApproved[]> => {
  try {
    const userDatas = await Users(team).where('invited_id', '!=', null).get()

    const users = userDatas.docs.map((user) => user.data() as IUser)

    return invites.map((invite) => {
      const inviteUsers = users.filter((u) => u.invited_id === invite.id)

      const approvedUsers = inviteUsers.filter((u) => u.is_approved === false)
      const unapprovedUsers = inviteUsers.filter((u) => u.is_approved === true)
      const registeredAdminUsers = inviteUsers.filter(
        (u) => u.is_approved === 'undefined'
      )
      return { invite, approvedUsers, unapprovedUsers, registeredAdminUsers }
    })
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get approved users and registered adminusers
 */
export const getApprovedUser = async (
  team: ITeam,
  invitedId: string
): Promise<IUser[]> => {
  try {
    const userDatas = await Users(team)
      .where('invited_id', '==', invitedId)
      .where('is_approved', 'in', [false, 'undefined'])
      .get()
    return userDatas.docs.map((user) => user.data() as IUser)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get unapproved users
 */
export const getUnapprovedUser = async (
  team: ITeam,
  invitedId: string
): Promise<IUser[]> => {
  try {
    const userDatas = await Users(team)
      .where('invited_id', '==', invitedId)
      .where('is_approved', '==', true)
      .get()
    return userDatas.docs.map((user) => user.data() as IUser)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get unapproved all users
 */
export const getUnapprovedAllUsers = async (team: ITeam): Promise<IUser[]> => {
  try {
    const userDatas = await Users(team).where('is_approved', '==', true).get()
    return userDatas.docs.map((user) => user.data() as IUser)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get not unapproved all users
 */
export const getNotUnapprovedAllUsers = async (
  team: ITeam
): Promise<IUser[]> => {
  try {
    const userDatas = await Users(team)
      .where('is_approved', 'in', [false, 'undefined'])
      .orderBy('created_at', 'desc')
      .get()
    return userDatas.docs.map((user) => user.data() as IUser)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get users for notification enabled
 *
 * `admin: false` and `notification_setting.news: true`
 */
export const getNotifyNewsUsers = async (team: ITeam): Promise<IUser[]> => {
  try {
    const userDatas = await Users(team)
      .where('admin', '==', false)
      .where('notification_setting.news', '==', true)
      .get()
    return userDatas.docs.map((user) => user.data() as IUser)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * add user in team
 * @throws Firebase error
 */
export const store = async (team: ITeam, user: IUser): Promise<void> => {
  await Users(team).doc(user.id).set(user)
  await initWatchLater(team, user.id)
}

/**
 * update user in team
 * @throws Firebase error
 */
export const update = async (team: ITeam, user: IUser): Promise<void> => {
  user.updated_at = nowTimestamp()
  await Users(team).doc(user.id).update(user)
}

/**
 * approve
 * @param team `ITeam`
 * @param userId `string`
 */
export const approve = async (team: ITeam, userId: string): Promise<void> => {
  await Users(team).doc(userId).update({ is_approved: false })
}

/**
 * add user bookmark
 * @param team `ITeam`
 * @param user `IUser`
 * @param video target `IVideo`
 * @throws Firebase error
 */
export const addBookmark = async (
  team: ITeam,
  user: IUser,
  video: IVideo
): Promise<void> => {
  const bookmark: IBookmark = {
    video_id: video.id,
    created_at: nowTimestamp(),
  }

  await Users(team)
    .doc(user.id)
    .update({
      bookmarks: firebase.firestore.FieldValue.arrayUnion(bookmark),
    })
}

/**
 * remove user bookmark
 * @param team `ITeam`
 * @param user `IUser`
 * @param video target `IVideo`
 * @throws Firebase error
 */
export const removeBookmark = async (
  team: ITeam,
  user: IUser,
  video: IVideo
): Promise<void> => {
  await Users(team)
    .doc(user.id)
    .update({
      bookmarks: user.bookmarks.filter(
        (bookmark) => bookmark.video_id !== video.id
      ),
    })
}

/**
 * delete user
 * @throws Firebase error
 */
export const remove = async (team: ITeam, user: IUser): Promise<void> => {
  await removeAllWatchlist(team, user.id)
  await Users(team).doc(user.id).delete()
}
