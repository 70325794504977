import classNames from 'classnames'
import { findCachedTagsNonNull } from 'common/find_store_cache'
import { ILive } from 'common/interfaces/live'
import { IVideo } from 'common/interfaces/video'
import { duration2str, isLiveVideo } from 'common/utils'
import { createBunnyAnimationUrl, createBunnyHlsUrl } from 'common/utils_bunny'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Card, CardImg } from 'reactstrap'
import { getVideoResume } from 'services/local_storage'
import './videocard.scss'

interface IVideoCard {
  className?: string
  video: IVideo | ILive
  overwriteTitle?: string
}

const VideoCard: React.FC<IVideoCard> = (props: IVideoCard) => {
  const { storeCache } = useContext(AuthContext)

  // issue for #1220
  // const title = props.overwriteTitle || props.video.name
  const title = props.video.name

  const tagElements = findCachedTagsNonNull(
    storeCache,
    props.video.tag_ids
  ).map((tag) => <span key={tag.id}>{tag.name}</span>)

  return (
    <Card className={classNames('video-card', props.className)}>
      <div className="thumbnail">
        <VideoThumbnailCard obj={props.video} alt={title} />
      </div>
      <div className="body">
        <h3 className="title">{title}</h3>
        <div className="tags">{tagElements}</div>
      </div>
    </Card>
  )
}

const VideoThumbnailCard: React.FC<{ obj: IVideo | ILive; alt: string }> = ({
  obj,
  alt,
}) => {
  const { storeCache } = useContext(AuthContext)

  if (isLiveVideo(obj)) {
    return (
      <>
        {obj.image && <CardImg top src={obj.image} alt={alt} />}
        <div className="live">LIVE</div>
      </>
    )
  }

  const video = obj as IVideo
  const animationUrl = createBunnyAnimationUrl(storeCache.team!, video)
  const playedPercent = (() => {
    const pos = getVideoResume(createBunnyHlsUrl(storeCache.team!, video))
    if (video.bunny.length <= 0 || pos === null || pos <= 0) return 0
    return Math.floor((pos / video.bunny.length) * 100)
  })()
  return (
    <>
      {video.image && <CardImg top src={video.image} alt={alt} />}
      <CardImg top className="video-preview" src={animationUrl} alt="" />
      <div className="video-duration">{duration2str(video.bunny.length)}</div>
      <div className="played-position" style={{ width: `${playedPercent}%` }} />
    </>
  )
}

export default VideoCard
