import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import './genrebadge.scss'

interface IGenreBadge {
  className?: string
  name: string
  linkPath: string
  isTag?: boolean
  isEnable?: boolean
}

const GenreBadge: React.FC<IGenreBadge> = (props: IGenreBadge) => {
  return (
    <Link className="genre-badge" to={props.linkPath}>
      <Badge
        className={classNames('badge', props.className, {
          'badge-enable': props.isEnable,
        })}
        pill
      >
        {props.isTag && '#'}
        {props.name}
      </Badge>
    </Link>
  )
}

export default GenreBadge
