import { ITeam } from 'common/interfaces/team'
import { IVideo, VideoStatus } from 'common/interfaces/video'
import {
  MultiVideoUpdateType,
  ValidationModal,
} from 'common/interfaces/video_multi_update'
import { isAfterFromTo, nowTimestamp } from 'common/times'
import { i18nValidation } from 'i18n/i18n'

/**
 * 動画登録・編集用バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param video 動画ドキュメント
 * @param isImageCropped boolean サムネイル設定時に切り取りボタンが押下されたかどうか
 */
export const validateForm = (
  {
    name,
    enquete,
    test,
    next_video,
    playable_period,
    released_at,
    publication_period,
    status,
    is_public,
    group_ids,
  }: IVideo,
  isImageCropped: boolean
): void => {
  if (!name) throw new Error(i18nValidation('input.title'))
  if (
    enquete !== undefined &&
    enquete.activate_enquete &&
    !enquete.enquete_id
  ) {
    throw new Error(i18nValidation('set.enquete'))
  }
  if (test !== undefined && test.activate_test && !test.test_id) {
    throw new Error(i18nValidation('set.test'))
  }
  if (
    next_video !== undefined &&
    next_video.activate_next_video &&
    !next_video.next_video_id
  ) {
    throw new Error(i18nValidation('set.nextVideo'))
  }
  if (
    playable_period !== null &&
    isAfterFromTo(playable_period.from, playable_period.to)
  ) {
    throw new Error(i18nValidation('set.playablePeriodFrom.before.to'))
  }
  if (
    status === VideoStatus.PUBLIC &&
    released_at &&
    isAfterFromTo(released_at, nowTimestamp())
  ) {
    throw new Error(i18nValidation('set.releasedAt.before.now'))
  }
  if (
    status === VideoStatus.PUBLICATION_PERIOD &&
    publication_period?.end &&
    isAfterFromTo(publication_period.start, publication_period.end)
  ) {
    throw new Error(i18nValidation('set.publicationPeriod.after.end'))
  }
  if (is_public && group_ids.length > 0) {
    throw new Error(i18nValidation('publicVideoHasNoGroups'))
  }
  if (!isImageCropped) {
    throw new Error(i18nValidation('thumbnail.incompleteCut'))
  }
}

/**
 * 動画削除用バリデーション
 *
 * 前提としてtryの中で使用すること
 * @param team `ITeam`
 * @param video 対象動画ドキュメント
 */
export const validateDeleteVideo = (team: ITeam, video: IVideo): void => {
  if (team.landing_page.intro_video_bunny_id === video.bunny.videoId) {
    throw new Error(i18nValidation('cantDelete.video.setLandingPage'))
  }
}

/**
 * 動画の一括設定用バリデーション
 * @param type `MultiVideoUpdateType`
 * @param videos `IVideo[]`
 * @returns `ValidationModal | null`
 */
export const validateMultiVideoUpdate = (
  type: MultiVideoUpdateType,
  videos: IVideo[]
): ValidationModal | null => {
  if (type === MultiVideoUpdateType.ADD_PUBLISH_GROUP) {
    const publicVideos = videos.filter((v) => v.is_public)
    if (publicVideos.length > 0) {
      return {
        open: true,
        invalidVideos: publicVideos,
        error: 'publicVideoHasNoGroups',
      }
    }
  }
  return null
}
