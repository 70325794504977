import H1 from 'components/atoms/Head/H1'
import ZoomSettingBody from 'components/organisms/Admin/AdminSettingZoomSetting/ZoomSettingBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'

const AdminSettingZoomSetting: React.FC = () => {
  const { t } = useTranslation('adminSetting')
  return (
    <>
      <H1 title={t('zoomSetting.title')} />

      <Container fluid>
        <ZoomSettingBody />
      </Container>
    </>
  )
}

export default AdminSettingZoomSetting
