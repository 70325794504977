import { ITest } from 'common/interfaces/test'
import { i18nValidation } from 'i18n/i18n'

/**
 * テスト回答の不足判定
 * @throws Error
 */
export const validateTestAnswer = (
  test: ITest,
  answers: (number | number[])[]
) => {
  if (answers.filter((a) => a !== undefined).length !== test.questions.length) {
    throw new Error(i18nValidation('answer.allFields'))
  }
}
