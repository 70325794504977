import { ColumnDef } from '@tanstack/react-table'
import { IEnquete } from 'common/interfaces/enquete'
import { IFlattedLog, ILog } from 'common/interfaces/log'
import { ITest } from 'common/interfaces/test'
import { IUser } from 'common/interfaces/user'
import { isAuthMethodEmail, userUid } from 'common/utils'
import {
  flattedLog,
  getAccountCreatedLog,
  getLogTableColumns,
} from 'common/utils_log'
import { useTanstackTableWithCsvExport } from 'components/atoms/Table/CreateTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'reactstrap'
import {
  getAllUserLogColumns,
  getAllUserLogData,
  getAllUserLogs,
  getUsers,
} from 'services/admin/analytics_log_user'
import { getEnquetes } from 'services/admin/enquete'
import { getTests } from 'services/admin/test'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminAnalytics')
  const { storeCache } = useContext(AuthContext)
  const [users, setUsers] = useState<IUser[]>([])
  const [currentUserId, setCurrentUserId] = useState<string>('')
  const [allUserLogs, setAllUserLogs] = useState<ILog[]>([])
  const [enquetes, setEnquetes] = useState<IEnquete[]>([])
  const [tests, setTests] = useState<ITest[]>([])

  useEffect(() => {
    getUsers(storeCache, setUsers)
    getAllUserLogs(storeCache, setAllUserLogs)
    getEnquetes(storeCache, setEnquetes)
    getTests(storeCache, setTests)
  }, [storeCache])

  if (!storeCache.team) return null

  const userOptions = users.map(({ id, email }) => (
    <option key={id} value={id}>
      {email || userUid(id)}
    </option>
  ))
  const targetUser = users.find((u) => u.id === currentUserId)
  const targetUserLog = allUserLogs.find((l) => l.user_id === targetUser?.id)

  return (
    <>
      <AllDataTable
        allUsers={users}
        allUserLogs={allUserLogs}
        enquetes={enquetes}
        tests={tests}
      />
      <Input onChange={(e) => setCurrentUserId(e.target.value)} type="select">
        <option>{t('pleaseSelect')}</option>
        {userOptions}
      </Input>
      <div className="mt-5">
        {targetUser && targetUserLog && (
          <Table
            enquetes={enquetes}
            tests={tests}
            targetUser={targetUser}
            targetUserLog={targetUserLog}
          />
        )}
      </div>
    </>
  )
}

const AllDataTable: React.FC<{
  allUsers: IUser[]
  allUserLogs: ILog[]
  enquetes: IEnquete[]
  tests: ITest[]
}> = ({ allUsers, allUserLogs, enquetes, tests }) => {
  const { storeCache } = useContext(AuthContext)
  const isAuthEmail = isAuthMethodEmail(storeCache.team!)

  const columns = getAllUserLogColumns(storeCache, allUsers, isAuthEmail, {
    enquetes,
    tests,
  })
  const data = getAllUserLogData(allUsers, allUserLogs)

  return useTanstackTableWithCsvExport<IFlattedLog>(
    columns,
    data,
    'analytics_log_user.csv',
    { hideTable: true }
  )
}

const Table: React.FC<{
  enquetes: IEnquete[]
  tests: ITest[]
  targetUser: IUser
  targetUserLog: ILog
}> = ({ enquetes, tests, targetUser, targetUserLog }) => {
  const { storeCache } = useContext(AuthContext)

  const columns = useMemo<ColumnDef<IFlattedLog>[]>(
    () => getLogTableColumns(storeCache, { enquetes, tests }),
    [storeCache, enquetes, tests]
  )
  const data = useMemo<IFlattedLog[]>(
    () =>
      [
        getAccountCreatedLog(targetUser),
        ...flattedLog(targetUserLog),
      ].reverse(),
    [targetUser, targetUserLog]
  )

  return useTanstackTableWithCsvExport<IFlattedLog>(
    columns,
    data,
    'analytics_log_user.csv'
  )
}

export default TableBody
