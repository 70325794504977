import { DateRange } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IPaymentIntent } from 'common/interfaces/stripe/payment_intent'
import { IFromTo } from 'common/interfaces/time'
import { getUnixTimesByDateRange } from 'common/times'
import { isLoggedIn } from 'common/utils'
import { getPaymentIntentsByCreated } from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'

export const initFromTo = (): IFromTo => ({ from: '', to: '' })

export const getPayments = async (
  storeCache: IStoreCache,
  dateRange: DateRange,
  fromTo: IFromTo,
  setData: React.Dispatch<React.SetStateAction<IPaymentIntent[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache) || isGetData(dateRange, fromTo)) {
    return setIsLoading(false)
  }

  setIsLoading(true)
  try {
    const { account_id } = storeCache.team!.stripe
    if (!account_id) return setIsLoading(false)

    const [gte, lte] = getUnixTimesByDateRange(dateRange, fromTo)
    const payments = await getPaymentIntentsByCreated(account_id, gte, lte)
    setData(payments)
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
  setIsLoading(false)
}

export const isGetData = (
  dateRange: DateRange,
  { from, to }: IFromTo
): boolean =>
  dateRange === DateRange.NONE ||
  (dateRange === DateRange.FROM_TO && !from) ||
  (dateRange === DateRange.FROM_TO && !to)

/**
 * `onChangeFromTo`で使用する入力タイプ
 */
export enum InputType {
  FROM,
  TO,
}

/**
 * From~Toの`onChange`イベントハンドラ
 * @param value `string`
 * @param type `InputType`
 * @param data `IFromTo`
 * @param setData `React.Dispatch<React.SetStateAction<string[]>>`
 * @returns {string[from, to]}
 */
export const onChangeFromTo = (
  value: string,
  type: InputType,
  data: IFromTo,
  setData: React.Dispatch<React.SetStateAction<IFromTo>>
): void => {
  try {
    setData({
      ...data,
      from: type === InputType.FROM ? value : data.from,
      to: type === InputType.TO ? value : data.to,
    })
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
