import { FieldCustomizePublicStatus } from 'common/interfaces/field_customize'
import { ITeam } from 'common/interfaces/team'
import { ICustomizeFields } from 'common/interfaces/user'
import { i18nValidation } from 'i18n/i18n'

/**
 * カスタムフィールド用バリデーション
 * 設定してあるカスタムフィールドが必須かつ公開の場合に値が入っているかチェックする
 * 前提としてtryの中で使用すること
 */
export const checkRequiredCustomizeField = (
  team: ITeam,
  customizeFields: ICustomizeFields[]
): void => {
  if (customizeFields.length <= 0) return

  team.customize_field?.forEach((customizeField) => {
    if (
      customizeField.publicStatus === FieldCustomizePublicStatus.PUBLIC &&
      customizeField.isRequired
    ) {
      const customizeFieldObj = customizeFields.find(
        (cf) => Object.keys(cf)[0] === customizeField.fieldName
      )

      if (!customizeFieldObj?.[customizeField.fieldName]) {
        throw new Error(
          i18nValidation('input.args.customizeField', {
            customizeField: customizeField.fieldName,
          })
        )
      }
    }
  })
}
