import {
  CouponDiscountType,
  CouponDurationType,
  CurrencyType,
  Routes,
} from 'common/enums'
import { getDefaultProducts } from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICoupon } from 'common/interfaces/stripe/coupon'
import { IProduct } from 'common/interfaces/stripe/product'
import { formatDateToUNIX } from 'common/times'
import { createProductOptions, isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { saveCoupon } from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/coupon_form'

/**
 * `ICoupon`初期値設定
 */
export const initCouponForm = (): ICoupon => ({
  id: '',
  name: '',
  currency: CurrencyType.JPY,
  amount_off: 0,
  percent_off: 0,
  duration: CouponDurationType.FOREVER,
  redeem_by: undefined,
  created: 0,
})

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
  CURRENCY,
  AMOUNT_OFF,
  PERCENT_OFF,
  DURATION,
  DURATION_IN_MONTHS,
  REDEEM_BY,
  PRODUCT_IDS,
}

/**
 * クーポンのデフォルト値を返却する
 */
export const getCouponDefaults = (coupon: ICoupon, products: IProduct[]) => {
  return {
    defaultProducts: getDefaultProducts(
      coupon.applies_to?.products ?? [],
      products
    ),
  }
}

/**
 * Selectで使用するoptionsを返却する
 */
export const getFormOptions = (products: IProduct[]) => {
  return { productOptions: createProductOptions(products) }
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  coupon: ICoupon,
  setCoupon: React.Dispatch<React.SetStateAction<ICoupon>>,
  type: InputType,
  e: React.ChangeEvent<HTMLInputElement> | any
) => {
  const num = Number(e.target?.value)
  setCoupon({
    ...coupon,
    name: type === InputType.NAME ? e.target.value : coupon.name,
    currency: type === InputType.CURRENCY ? e.target.value : coupon.currency,
    amount_off: type === InputType.AMOUNT_OFF ? num : coupon.amount_off,
    percent_off:
      type === InputType.PERCENT_OFF
        ? num > 100
          ? 100
          : num
        : coupon.percent_off,
    duration:
      type === InputType.DURATION
        ? (e.target.value as CouponDurationType)
        : coupon.duration,
    duration_in_months:
      type === InputType.DURATION_IN_MONTHS ? num : coupon.duration_in_months,
    redeem_by:
      type === InputType.REDEEM_BY
        ? formatDateToUNIX(e.target.value)
        : coupon.redeem_by,
    applies_to: {
      products:
        type === InputType.PRODUCT_IDS
          ? e.map((x: any) => x.value)
          : coupon.applies_to?.products ?? [],
    },
  })
}

/**
 * Stripeクーポンレコード作成処理
 */
export const saveStripeCoupon = async (
  history: History,
  coupon: ICoupon,
  storeCache: IStoreCache,
  type: CouponDiscountType
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    validateForm(coupon, type)

    if (coupon.currency === CurrencyType.USD && coupon.amount_off) {
      coupon.amount_off *= 100
    }
    await saveCoupon(
      storeCache.team!.stripe.account_id,
      coupon.name,
      type === CouponDiscountType.AMOUNT && coupon.currency
        ? coupon.currency
        : undefined,
      type === CouponDiscountType.AMOUNT && coupon.amount_off
        ? coupon.amount_off
        : undefined,
      type === CouponDiscountType.PERCENT && coupon.percent_off
        ? coupon.percent_off
        : undefined,
      coupon.duration,
      coupon.duration_in_months,
      coupon.redeem_by === 0 ? undefined : coupon.redeem_by,
      coupon.applies_to?.products ?? null
    )

    history.push(Routes.AdminStripeCoupon)
    alertService.show(true, i18nAlert('saved.coupon'))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
