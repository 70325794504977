import { findCachedGroupsNonNull } from 'common/find_store_cache'
import { IVideoUsersViewCount } from 'common/interfaces/analytics'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IFlattedLog, LogType } from 'common/interfaces/log'
import { IFromTo } from 'common/interfaces/time'
import { IUser } from 'common/interfaces/user'
import { isAuthMethodEmail, isLoggedIn, userUid } from 'common/utils'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { getNewer } from 'repositories/store/firebase_user'
import { DatePeriod, getDatePeriodDuration } from 'services/admin/analytics'
import { alertService } from 'services/alert'
import { validateAnalyticsLimitPeriod } from 'services/validation/analytics'

/**
 * すべてのユーザーを取得する
 */
export const getUsers = async (
  storeCache: IStoreCache,
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>
) => {
  if (!isLoggedIn(storeCache)) return
  const users = await getNewer(storeCache.team!)
  setUsers(users)
}

/**
 * 動画の会員毎の分析を算出する共通関数
 * @param storeCache `IStoreCache`
 * @param allUsers `IUser[]`
 * @param allUserLogs `IFlattedLog[]`
 * @param targetVideoId `sting`
 * @param setViewCounts state setter
 * @param startDate `dayjs.Dayjs`
 * @param endDate `dayjs.Dayjs`
 * @returns `void`
 */
const setVideoUsersViewCounts = (
  storeCache: IStoreCache,
  allUsers: IUser[],
  allUserLogs: IFlattedLog[],
  targetVideoId: string,
  setViewCounts: React.Dispatch<React.SetStateAction<IVideoUsersViewCount[]>>,
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs
): void => {
  if (!storeCache.team) return

  dayjs.extend(isBetween)

  const uid = (userId: string) =>
    isAuthMethodEmail(storeCache.team!) ? userId : userUid(userId)

  const result: IVideoUsersViewCount[] = allUsers.map((u) => ({
    user_id: uid(u.id),
    user_email: u.email,
    user_name: u.name ?? '',
    user_phone: u.phone ?? '',
    user_groups: findCachedGroupsNonNull(storeCache, u.group_ids).map(
      ({ name }) => name
    ),
    page_open_count: 0,
    video_play_count: 0,
    not_play_percent: '-',
    video_ended_count: 0,
    video_ended_percent: '-',
  }))

  allUserLogs
    .filter(
      (l) =>
        l.video_id === targetVideoId &&
        dayjs
          .unix(l.timestamp.seconds)
          .isBetween(startDate, endDate, null, '[]')
    )
    .forEach((l) => {
      const resultTarget = result.find((r) => r.user_id === uid(l.user_id))
      if (!resultTarget) return

      switch (l.type) {
        case LogType.VIDEO_PAGE_OPEN:
          resultTarget.page_open_count += 1
          break
        case LogType.VIDEO_PLAY:
          resultTarget.video_play_count += 1
          break
        case LogType.VIDEO_ENDED:
          resultTarget.video_ended_count += 1
          break
      }
    })

  const filtered = result.filter(
    (r) =>
      r.page_open_count > 0 || r.video_play_count > 0 || r.video_ended_count > 0
  )
  filtered.forEach((vuvc) => {
    if (vuvc.page_open_count > 0 || vuvc.video_play_count > 0) {
      const notPlayPercent =
        (1 - vuvc.video_play_count / vuvc.page_open_count) * 100
      vuvc.not_play_percent = `${Math.round(notPlayPercent)}%`
    }
    if (vuvc.video_play_count > 0 || vuvc.video_ended_count > 0) {
      const videoEndedPercent =
        (vuvc.video_ended_count / vuvc.video_play_count) * 100
      vuvc.video_ended_percent = `${Math.round(videoEndedPercent)}%`
    }
  })
  const sorted = filtered.sort((a, b) => (a.user_email > b.user_email ? 1 : -1))
  setViewCounts(sorted)
}

/**
 * 動画の会員毎の分析を日付タイプに応じて算出
 * @param storeCache `IStoreCache`
 * @param allUsers `IUser[]`
 * @param allUserLogs `IFlattedLog[]`
 * @param targetVideoId `sting`
 * @param datePeriodType `DatePeriod`
 * @param setViewCounts state setter
 * @param target `{ date: string, fromTo: IFromTo }`
 * @returns `void`
 */
export const setVideoUsersViewCountData = (
  storeCache: IStoreCache,
  allUsers: IUser[],
  allUserLogs: IFlattedLog[],
  targetVideoId: string,
  datePeriodType: DatePeriod,
  setViewCounts: React.Dispatch<React.SetStateAction<IVideoUsersViewCount[]>>,
  target: {
    date: string
    fromTo: IFromTo
  }
): void => {
  const duration = getDatePeriodDuration(datePeriodType, setViewCounts, target)
  if (!duration) return

  try {
    validateAnalyticsLimitPeriod(duration.start, duration.end)
  } catch (error: any) {
    alertService.show(false, error.message)
    setViewCounts([])
    return
  }

  setVideoUsersViewCounts(
    storeCache,
    allUsers,
    allUserLogs,
    targetVideoId,
    setViewCounts,
    duration.start,
    duration.end
  )
}
