import { ColumnDef } from '@tanstack/react-table'
import 'chart.js/auto'
import { Routes } from 'common/enums'
import { findCachedGroupsNonNull } from 'common/find_store_cache'
import { ITest } from 'common/interfaces/test'
import { ITestAnswer } from 'common/interfaces/test_answer'
import { IUser } from 'common/interfaces/user'
import { isAuthMethodEmail, userUid } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import {
  customFieldColumnDef,
  useTanstackTableWithCsvExport,
} from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makePrimaryBadges,
} from 'components/atoms/Table/ElementsOnTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { createPassedPieData, getTestAnswers } from 'services/admin/test_answer'
import { getNewerUsers } from 'services/admin/user'

interface ITableBodyProps {
  test: ITest
}

const TableBody: React.FC<ITableBodyProps> = ({ test }: ITableBodyProps) => {
  const { t } = useTranslation('adminTest')
  const { storeCache } = useContext(AuthContext)

  const [answers, setAnswers] = useState<ITestAnswer[]>([])
  const [users, setUsers] = useState<IUser[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await Promise.all([
        getTestAnswers(storeCache, test, setAnswers),
        getNewerUsers(setUsers, storeCache),
      ])
      setIsLoading(false)
    })()
  }, [storeCache, test])

  if (!storeCache.team) return null
  if (isLoading) return <LoadingSpinner />
  if (answers.length <= 0) return <>{t('answer.noAnswer')}</>

  const { chartData, chartOptions } = createPassedPieData(answers)

  return (
    <>
      <ButtonRight nextPage={Routes.AdminTest} content={t('answer.back')} />
      <Row className="justify-content-center mb-4">
        <Col md="6">
          <Chart type="pie" data={chartData} options={chartOptions} />
        </Col>
      </Row>

      <Table test={test} answers={answers} users={users} />
    </>
  )
}

const Table: React.FC<{
  test: ITest
  answers: ITestAnswer[]
  users: IUser[]
}> = ({ test, answers, users }) => {
  const { t } = useTranslation('adminTest')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const isAuthEmail = isAuthMethodEmail(storeCache.team!)

  const columns = useMemo<ColumnDef<ITestAnswer>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => i + 1,
        meta: { csvExport: false },
      },
      {
        header: t('answer.table.userId'),
        accessorFn: (a) => (isAuthEmail ? a.user_id : userUid(a.user_id)),
        meta: { hidden: isAuthEmail, csvExport: !isAuthEmail },
      },
      {
        header: t('answer.table.email'),
        accessorFn: (a) => users.find((u) => u.id === a.user_id)?.email ?? '',
        meta: { hidden: !isAuthEmail, csvExport: isAuthEmail },
      },
      {
        header: t('answer.table.name'),
        accessorFn: (a) => users.find((u) => u.id === a.user_id)?.name ?? '',
      },
      {
        header: t('answer.table.groups'),
        accessorFn: (a) => {
          const groups = users.find((u) => u.id === a.user_id)?.group_ids ?? []
          return findCachedGroupsNonNull(storeCache, groups).map((g) => g.name)
        },
        cell: (cell) => makePrimaryBadges(cell.getValue<string[]>()),
      },
      ...customFieldColumnDef<ITestAnswer>(storeCache.team, users),
      {
        header: t('answer.table.videoId'),
        accessorKey: 'video_id',
      },
      {
        header: t('answer.table.videoName'),
        accessorFn: (a) =>
          storeCache.videos.find((v) => v.id === a.video_id)?.name ?? '',
      },
      {
        header: t('answer.table.passedCount'),
        accessorFn: (a) => {
          const passedCount = a.answers.filter((ans) => ans.is_passed).length
          return t('answer.count', { count: passedCount })
        },
      },
      {
        header: t('answer.table.failedCount'),
        accessorFn: (a) => {
          const passedCount = a.answers.filter((ans) => ans.is_passed).length
          const failedCount = a.answers.length - passedCount
          return t('answer.count', { count: failedCount })
        },
      },
      {
        header: t('list.table.operations'),
        accessorFn: (answer) =>
          makeCustomOperationButton(t('answer.answerDetail'), () =>
            history.push({
              pathname: Routes.AdminTestAnswerDetail,
              state: { test, answer },
            })
          ),
      },
    ],
    [storeCache, isAuthEmail, test, users, t, history]
  )

  const data = answers.sort((a, b) => a.user_id.localeCompare(b.user_id))
  return useTanstackTableWithCsvExport<ITestAnswer>(
    columns,
    data,
    t('answer.csvName', { testName: test.name })
  )
}

export default TableBody
