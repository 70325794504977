import { IPlaylist } from 'common/interfaces/playlist'
import { ITeam } from 'common/interfaces/team'
import { nowTimestamp } from 'common/times'
import { Teams } from './firebase_team'

const Playlists = (team: ITeam) => Teams.doc(team.id).collection('playlists')

/**
 * get all playlists
 */
export const get = async (team: ITeam): Promise<IPlaylist[]> => {
  try {
    const playlistDatas = await Playlists(team).get()
    return playlistDatas.docs.map((playlist) => playlist.data() as IPlaylist)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get for non auth user playlists order by created_at
 *
 * `team.is_site_public` must be true
 */
export const getForNonAuthUser = async (team: ITeam): Promise<IPlaylist[]> => {
  try {
    const playlistDatas = await Playlists(team)
      .where('is_public', '==', true)
      .get()
    return playlistDatas.docs.map((playlist) => playlist.data() as IPlaylist)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * add playlist
 * @throws Firebase error
 */
export const store = async (
  team: ITeam,
  playlist: IPlaylist
): Promise<void> => {
  await Playlists(team).doc(playlist.id).set(playlist)
}

/**
 * update playlist
 * @throws Firebase error
 */
export const update = async (
  team: ITeam,
  playlist: IPlaylist
): Promise<void> => {
  playlist.updated_at = nowTimestamp()
  await Playlists(team).doc(playlist.id).update(playlist)
}

/**
 * delete playlist
 * @throws Firebase error
 */
export const remove = async (
  team: ITeam,
  playlist: IPlaylist
): Promise<void> => {
  await Playlists(team).doc(playlist.id).delete()
}

/**
 * remove playlist image
 * @throws Firebase error
 */
export const removeImage = async (
  team: ITeam,
  playlist: IPlaylist
): Promise<void> => {
  playlist.image = null
  playlist.updated_at = nowTimestamp()
  await Playlists(team).doc(playlist.id).update(playlist)
}
