import { auth } from 'common/firebase'
import { checkFirebaseError } from 'common/utils'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { alertService } from 'services/alert'

/**
 * パスワードをリセットするためのメールを操作ユーザに送付する
 */
export const sendPasswordResetEmail = async (email: string): Promise<void> => {
  if (!email) {
    alertService.show(false, i18nValidation('input.mail.address'))
    return
  }

  try {
    await auth.sendPasswordResetEmail(email)

    alertService.show(true, i18nAlert('sentMailInputtedAddress'))
  } catch (error) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, message)
  }
}
