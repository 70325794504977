import { ILive } from 'common/interfaces/live'
import React from 'react'
import YouTube from 'react-youtube'
import './livedetailbodycommon.scss'

interface IYTLiveDetailBody {
  live: ILive
}

const YTLiveDetailBody: React.FC<IYTLiveDetailBody> = ({ live }) => {
  return (
    <div className="live-detail-yt">
      <YouTube
        className="yt-live-video"
        videoId={live.youtube_video_id}
        opts={{ playerVars: { autoplay: 1, rel: 0, modestbranding: 1 } }}
      />
    </div>
  )
}

export default YTLiveDetailBody
