import { CurrencyType } from 'common/enums'
import { IInvoice } from 'common/interfaces/stripe/Invoice'
import { formatPrice } from 'common/utils'
import FooterButtons from 'components/atoms/Modal/FooterButtons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Modal, Row } from 'reactstrap'

type TPreviewUpcomingModal = {
  invoice: IInvoice | null
  isOpen: boolean
  onToggleModal: () => void
  onClick: (selectedPriceId: string) => void
}

const PreviewUpcomingModal: React.FC<TPreviewUpcomingModal> = ({
  invoice,
  isOpen,
  onToggleModal,
  onClick,
}: TPreviewUpcomingModal) => {
  const { t } = useTranslation('itemSelect')
  if (!invoice) return <></>

  const isJPY = invoice.currency === CurrencyType.JPY
  const currentPlan = invoice.lines.data[0]
  const newPlan = invoice.lines.data[1]
  const subtotal = newPlan.amount + currentPlan.amount
  const tax = newPlan.tax_amounts[0].amount + currentPlan.tax_amounts[0].amount
  const total = subtotal + (isJPY ? tax : 0)

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={onToggleModal}
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">
          {t('previewUpcomingModal.previewForDifference')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onToggleModal}
        >
          <span aria-hidden>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>{t('previewUpcomingModal.priceInTimeUsedNewPlan')}</Col>
          <Col>{formatPrice(newPlan.amount, invoice.currency)}</Col>
        </Row>
        <Row>
          <Col>{t('previewUpcomingModal.priceInTimeNotUseCurrentPlan')}</Col>
          <Col>{formatPrice(currentPlan.amount, invoice.currency)}</Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Row>
              <Col>{t('previewUpcomingModal.subtotal')}</Col>
              <Col>{formatPrice(subtotal, invoice.currency)}</Col>
            </Row>
            {isJPY && (
              <Row>
                <Col>{t('previewUpcomingModal.consumptionTax')}</Col>
                <Col>{formatPrice(tax, invoice.currency)}</Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row className="my-4">
          <Col>{t('previewUpcomingModal.billingAmount.title')}</Col>
          <Col>{formatPrice(total, invoice.currency)}</Col>
        </Row>
        {t('previewUpcomingModal.billingAmount.caution')}
        <FooterButtons
          leftContent={t('previewUpcomingModal.changePlans')}
          rightContent={t('close')}
          leftOnClick={() => onClick(newPlan.price.id)}
          rightOnClick={onToggleModal}
        />
      </div>
    </Modal>
  )
}

export default PreviewUpcomingModal
