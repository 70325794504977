import { IStoreCache } from 'common/interfaces/auth_provider'
import { ITest, ITestQuestion, ITestQuestionType } from 'common/interfaces/test'
import { ITestAnswer } from 'common/interfaces/test_answer'
import { IVideo } from 'common/interfaces/video'
import { nowTimestamp } from 'common/times'
import { checkFirebaseError, isLoggedIn } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import React from 'react'
import { addTestAnsweredLog } from 'repositories/store/firebase_log'
import { findById } from 'repositories/store/firebase_test'
import {
  findByUserIdAndVideoId,
  store,
} from 'repositories/store/firebase_test_answer'
import { alertService } from 'services/alert'
import { validateTestAnswer } from 'services/validation/test_answer'
import { v4 as uuidv4 } from 'uuid'

/**
 * テストが存在するかどうかを返却する
 */
export const existsTest = (video: IVideo) =>
  video.test && video.test.activate_test && video.test.test_id

/**
 * 動画に設定されているテストを取得する
 */
export const getTest = async (
  storeCache: IStoreCache,
  video: IVideo,
  setTest: React.Dispatch<React.SetStateAction<ITest | null>>
) => {
  if (!existsTest(video) || !isLoggedIn(storeCache)) return

  setTest(await findById(storeCache.team!, video.test!.test_id))
}

/**
 * テスト回答オブジェクトを取得する（存在しない場合は新規作成）
 */
export const getOldTestAnswer = async (
  storeCache: IStoreCache,
  video: IVideo,
  test: ITest | null,
  setOldTestAnswer: React.Dispatch<React.SetStateAction<ITestAnswer | null>>
) => {
  if (!existsTest(video) || !isLoggedIn(storeCache, true) || !test) return

  let oldTestAnswer = await findByUserIdAndVideoId(
    storeCache.team!,
    test,
    storeCache.user!.id,
    video.id
  )

  if (oldTestAnswer === undefined) {
    alertService.show(false, i18nAlert('video.failedGetOldTestAnswer'))
    setTimeout(() => window.location.reload(), 3000)
    return
  }
  if (oldTestAnswer === null) {
    oldTestAnswer = {
      id: uuidv4(),
      user_id: storeCache.user!.id,
      video_id: video.id,
      answers: [],
    }
  }
  setOldTestAnswer(oldTestAnswer)
}

/**
 * テスト受験資格の可否を返却する
 */
export const canAnswerTest = (test: ITest, oldTestAnswer: ITestAnswer) =>
  oldTestAnswer.answers.length <= 0 ||
  (test.retest.is_enable &&
    ((!test.retest.is_stop_when_passed && test.retest.limit_count <= 0) ||
      (test.retest.is_stop_when_passed &&
        test.retest.limit_count > 0 &&
        !oldTestAnswer.answers[oldTestAnswer.answers.length - 1].is_passed &&
        oldTestAnswer.answers.length < test.retest.limit_count) ||
      (!test.retest.is_stop_when_passed &&
        test.retest.limit_count > 0 &&
        oldTestAnswer.answers.length < test.retest.limit_count) ||
      (test.retest.is_stop_when_passed &&
        test.retest.limit_count <= 0 &&
        !oldTestAnswer.answers[oldTestAnswer.answers.length - 1].is_passed)))

/**
 * 過去に合格済みかを返却する
 */
export const isAlreadyPassed = (oldTestAnswer: ITestAnswer) =>
  oldTestAnswer.answers.some((a) => a.is_passed)

/**
 * テストの回答Inputの`onChange`イベントハンドラ
 */
export const onChangeInput = (
  answers: (number | number[])[],
  setAnswers: React.Dispatch<React.SetStateAction<(number | number[])[]>>,
  questionIndex: number,
  { target: { value, checked } }: React.ChangeEvent<HTMLInputElement>,
  questionType: ITestQuestionType
) => {
  const optionIndex = Number(value)
  if (questionType === ITestQuestionType.RADIO) {
    answers[questionIndex] = optionIndex
  } else {
    const checkAnswers = (answers[questionIndex] as number[]) ?? []
    if (checked) checkAnswers.push(optionIndex)
    else checkAnswers.splice(checkAnswers.indexOf(optionIndex), 1)
    answers[questionIndex] =
      checkAnswers.length > 0 ? checkAnswers : (undefined as any)
  }
  setAnswers([...(answers as number[])])
}

/**
 * テストの合格判定
 */
export const isTestPass = (
  test: ITest,
  answers: (number | number[])[]
): boolean => {
  try {
    validateTestAnswer(test, answers)
  } catch (_) {
    return false
  }

  const correctAnswerCount = test.questions.reduce((count, question, index) => {
    return isCorrect(question, answers, index) ? count + 1 : count
  }, 0)

  return correctAnswerCount >= test.pass_correct_count
}

const isCorrect = (
  { type, correct_index }: ITestQuestion,
  answers: (number | number[])[],
  i: number
): boolean => {
  if (type === ITestQuestionType.RADIO) {
    return (answers[i] as number) === correct_index
  }
  return isCorrectForCheckbox(correct_index, answers, i)
}

export const isCorrectForCheckbox = (
  correctIndex: number | number[],
  answers: (number | number[])[],
  i: number
): boolean => {
  const answer = answers[i] as number[]
  const correctIndexs = correctIndex as number[]
  const correctNum = answer.filter(
    (a) => correctIndexs.includes(a) ?? undefined
  ).length
  return (
    correctNum === correctIndexs.length &&
    answer.length === correctIndexs.length
  )
}

/**
 * 回答処理
 */
export const onAnswer = async (
  storeCache: IStoreCache,
  test: ITest,
  videoId: string,
  states: {
    oldTestAnswer: ITestAnswer
    setOldTestAnswer: React.Dispatch<React.SetStateAction<ITestAnswer | null>>
    answers: (number | number[])[]
    setAnswers: React.Dispatch<React.SetStateAction<(number | number[])[]>>
    isShowCorrectAnswer: boolean
    setIsShowCorrectAnswer: React.Dispatch<React.SetStateAction<boolean>>
    setIsTestPassed: React.Dispatch<React.SetStateAction<boolean>>
    setIsTestPassedModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  }
) => {
  if (states.isShowCorrectAnswer) {
    states.setIsTestPassed(false)
    states.setIsShowCorrectAnswer(false)
    states.setAnswers([])
    return
  }
  if (!isLoggedIn(storeCache)) return

  try {
    validateTestAnswer(test, states.answers)

    const isPassed = isTestPass(test, states.answers)
    const testAnswers = states.answers.map((a) =>
      typeof a === 'number' ? a : JSON.stringify(a)
    )
    states.oldTestAnswer.answers.push({
      answer: testAnswers,
      is_passed: isPassed,
      answered_at: nowTimestamp(),
    })

    await store(storeCache.team!, test, states.oldTestAnswer)
    await addTestAnsweredLog(
      storeCache.team!,
      storeCache.user!,
      videoId,
      test.id
    )
    states.setOldTestAnswer({ ...states.oldTestAnswer })
    states.setIsShowCorrectAnswer(true)
    states.setIsTestPassed(isPassed)
    if (isPassed) states.setIsTestPassedModalOpen(true)
  } catch (error) {
    console.log(error)
    const message = checkFirebaseError(error)
    alertService.show(false, message)
  }
}

/**
 * テスト合格Modalのボタン遷移先URLを返却する
 */
export const getTestPassedModalButtonUrl = (
  storeCache: IStoreCache,
  test: ITest
) => {
  if (!isLoggedIn(storeCache, true) || test.passed.button_url === null) {
    return null
  }

  const team = storeCache.team!
  const user = storeCache.user!

  let url = test.passed.button_url
    .replace('{teamId}', team.id)
    .replace('{teamName}', team.name)
    .replace('{userId}', user.id)
    .replace('{userName}', user.name ?? '')

  if (user.customize_fields) {
    const customFields = url.match(/\{customField\.(.+?)\}/g)
    customFields?.forEach((field) => {
      const fieldName = field.replace('{customField.', '').replace('}', '')
      const userTargetCustomField = user.customize_fields!.find(
        (f) => f[fieldName] !== undefined
      )
      if (userTargetCustomField) {
        url = url.replace(field, userTargetCustomField[fieldName])
      }
    })
  }

  return url
}
