import { IZoomSignature } from 'common/interfaces/broadcast'
import { ILive } from 'common/interfaces/live'
import { openNewTab } from 'common/utils'
import AccentButton from 'components/atoms/Button/Block/AccentButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Input } from 'reactstrap'
import {
  ZOOM_TYPE,
  getZoomMeetingPath,
  getZoomSignature,
  isEmailRequired,
} from 'services/user/zoom'
import './livedetailbodycommon.scss'

interface IZoomLiveDetailBody {
  live: ILive
}

const ZoomLiveDetailBody: React.FC<IZoomLiveDetailBody> = ({ live }) => {
  const { t } = useTranslation('userLive')
  const { storeCache } = useContext(AuthContext)
  const [zoomType, setZoomType] = useState<ZOOM_TYPE>(ZOOM_TYPE.PROMPT)
  const [signature, setSignature] = useState<IZoomSignature | null>(null)
  const [userEmail, setUserEmail] = useState<string>(
    storeCache.user?.email ?? ''
  )
  const [meetingPath, setMeetingPath] = useState<string | null>(null)

  useEffect(() => {
    getZoomSignature(storeCache, live, setSignature)
  }, [storeCache, live])

  useEffect(() => {
    if (!signature) return
    getZoomMeetingPath(storeCache, live, signature, userEmail, setMeetingPath)
  }, [storeCache, live, signature, userEmail])

  const zoomElement = (() => {
    if (!signature || !meetingPath) {
      return (
        <div className="initialize-wrapper">
          <h1 className="initialize">
            {t('liveDetail.zoomLiveDetailBody.initializing')}
          </h1>
        </div>
      )
    }

    if (isEmailRequired(storeCache, live) && !userEmail) {
      return <InputMailAddress setUserEmail={setUserEmail} />
    }

    switch (zoomType) {
      case ZOOM_TYPE.PROMPT:
        return (
          <ZoomTypePrompt
            onNewTab={() => openNewTab(meetingPath)}
            onEmbed={() => setZoomType(ZOOM_TYPE.EMBED)}
            live={live}
          />
        )
      case ZOOM_TYPE.EMBED:
        return <EmbeddedZoomInnerBody path={meetingPath} />
    }
  })()

  return <div className="live-detail-zoom">{zoomElement}</div>
}

const InputMailAddress: React.FC<{
  setUserEmail: React.Dispatch<React.SetStateAction<string>>
}> = ({ setUserEmail }) => {
  const { t } = useTranslation('userLive')
  const { team } = useContext(AuthContext).storeCache
  const email = useRef<HTMLInputElement>(null)
  return (
    <div className="zoom-email-input">
      <div className="zoom-email-input__inner">
        <Trans t={t} i18nKey="liveDetail.zoomLiveDetailBody.inputEmail" />
        <Input type="email" innerRef={email} />
        <AccentButton
          className="mt-2"
          onClick={() => setUserEmail(email.current!.value)}
          content="OK"
          colors={team?.colors}
        />
      </div>
    </div>
  )
}

const ZoomTypePrompt: React.FC<{
  onNewTab: () => void
  onEmbed: () => void
  live: ILive
}> = ({ onNewTab, onEmbed, live }) => {
  const { t } = useTranslation('userLive')
  const { team } = useContext(AuthContext).storeCache
  const zoomAppUrl = `https://us02web.zoom.us/j/${live.meeting_number}`

  return (
    <div className="zoom-type-prompt">
      <div className="zoom-type-prompt__inner">
        <p className="zoom-type-prompt__title h2">
          <Trans
            t={t}
            i18nKey="liveDetail.zoomLiveDetailBody.zoomTypePrompt.title"
          />
        </p>
        <div className="zoom-type-prompt__contents mt-5">
          <div>
            <AccentButton
              onClick={onNewTab}
              content={t(
                'liveDetail.zoomLiveDetailBody.zoomTypePrompt.onNewTab'
              )}
              colors={team?.colors}
            />
          </div>
          <div className="mt-3">
            <Button className="zoom-embedded-button" onClick={onEmbed}>
              {t('liveDetail.zoomLiveDetailBody.zoomTypePrompt.onEmbedded')}
            </Button>
          </div>
          {live.meeting_password === '' && (
            <div className="mt-3">
              <a href={zoomAppUrl}>
                <Button color="link">
                  {t('liveDetail.zoomLiveDetailBody.zoomTypePrompt.onZoomApp')}
                </Button>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const EmbeddedZoomInnerBody: React.FC<{ path: string }> = ({ path }) => {
  const { t } = useTranslation('userLive')
  const [isLeftMeeting, setIsLeftMeeting] = useState<boolean>(false)

  const iframeOnLoad = (e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
    if (e.currentTarget.contentWindow!.location.href.endsWith('/leftMeeting')) {
      setIsLeftMeeting(true)
    }
  }

  if (isLeftMeeting) {
    return (
      <h1 className="left-meeting">
        {t('liveDetail.zoomLiveDetailBody.embeddedZoomInnerBody.leftMeeting')}
      </h1>
    )
  }
  return (
    <iframe
      title="live"
      className="zoom-live-video"
      onLoad={iframeOnLoad}
      src={path}
    />
  )
}

export default ZoomLiveDetailBody
