import { findCachedGroupsNonNull } from 'common/find_store_cache'
import { IUserViewCount } from 'common/interfaces/analytics'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IFlattedLog, LogType } from 'common/interfaces/log'
import { IFromTo } from 'common/interfaces/time'
import { IUser } from 'common/interfaces/user'
import { isLoggedIn } from 'common/utils'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { i18nAdminAnalytics } from 'i18n/i18n'
import { getNewer } from 'repositories/store/firebase_user'
import { DatePeriod, getDatePeriodDuration } from 'services/admin/analytics'
import { alertService } from 'services/alert'
import { validateAnalyticsLimitPeriod } from 'services/validation/analytics'

/**
 * get all users
 * @param storeCache `IStoreCache`
 * @param setUsers state setter
 * @returns `Promise<void>`
 */
export const getUsers = async (
  storeCache: IStoreCache,
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>
) => {
  if (!isLoggedIn(storeCache)) return
  const users = await getNewer(storeCache.team!)
  setUsers(users)
}

/**
 * 会員ごとの分析を算出する共通関数
 * @param storeCache `IStoreCache`
 * @param allUsers `IUser[]`
 * @param allUserLogs `IFlattedLog[]`
 * @param setViewCounts state setter
 * @param startDate `dayjs.Dayjs`
 * @param endDate `dayjs.Dayjs`
 * @returns `void`
 */
const setUserViewCounts = (
  storeCache: IStoreCache,
  allUsers: IUser[],
  allUserLogs: IFlattedLog[],
  setViewCounts: React.Dispatch<React.SetStateAction<IUserViewCount[]>>,
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs
): void => {
  if (!storeCache.team) return

  dayjs.extend(isBetween)

  const result: IUserViewCount[] = allUsers.map((u) => ({
    user_id: u.id,
    user_email: u.email,
    user_name: u.name ?? '',
    user_phone: u.phone ?? '',
    user_groups: findCachedGroupsNonNull(storeCache, u.group_ids).map(
      ({ name }) => name
    ),
    page_open_count: 0,
    video_play_count: 0,
    not_play_percent: '-',
    video_ended_count: 0,
    video_ended_percent: '-',
  }))

  allUsers.forEach((u) => {
    allUserLogs
      .filter(
        (l) =>
          l.user_id === u.id &&
          dayjs
            .unix(l.timestamp.seconds)
            .isBetween(startDate, endDate, null, '[]')
      )
      .forEach((l) => {
        const resultTarget = result.find((r) => r.user_id === u.id)
        if (!resultTarget) return

        switch (l.type) {
          case LogType.VIDEO_PAGE_OPEN:
            resultTarget.page_open_count += 1
            break
          case LogType.VIDEO_PLAY:
            resultTarget.video_play_count += 1
            break
          case LogType.VIDEO_ENDED:
            resultTarget.video_ended_count += 1
            break
        }
      })
  })

  const filtered = result.filter(
    (r) =>
      r.page_open_count > 0 || r.video_play_count > 0 || r.video_ended_count > 0
  )
  filtered.forEach((uvc) => {
    if (uvc.page_open_count > 0 || uvc.video_play_count > 0) {
      const percent = (1 - uvc.video_play_count / uvc.page_open_count) * 100
      uvc.not_play_percent = `${Math.round(percent)}%`
    }
    if (uvc.video_play_count > 0 || uvc.video_ended_count > 0) {
      const videoEndedPercent =
        (uvc.video_ended_count / uvc.video_play_count) * 100
      uvc.video_ended_percent = `${Math.round(videoEndedPercent)}%`
    }
  })
  const sorted = filtered.sort((a, b) => (b.user_email > a.user_email ? 1 : -1))
  setViewCounts(sorted)
}

/**
 * 会員ごとの分析を日付タイプに応じて算出
 * @param storeCache `IStoreCache`
 * @param allUsers `IUser[]`
 * @param allUserLogs `IFlattedLog[]`
 * @param datePeriodType `DatePeriod`
 * @param setViewCounts state setter
 * @param target `{ date: string, fromTo: IFromTo }`
 * @returns `void`
 */
export const setUserViewCountData = (
  storeCache: IStoreCache,
  allUsers: IUser[],
  allUserLogs: IFlattedLog[],
  datePeriodType: DatePeriod,
  setViewCounts: React.Dispatch<React.SetStateAction<IUserViewCount[]>>,
  target: {
    date: string
    fromTo: IFromTo
  }
): void => {
  const duration = getDatePeriodDuration(datePeriodType, setViewCounts, target)
  if (!duration) return

  try {
    validateAnalyticsLimitPeriod(duration.start, duration.end)
  } catch (error: any) {
    alertService.show(false, error.message)
    setViewCounts([])
    return
  }

  setUserViewCounts(
    storeCache,
    allUsers,
    allUserLogs,
    setViewCounts,
    duration.start,
    duration.end
  )
}

/**
 * 指定期間のタイトルを返却
 * @param datePeriodType `DatePeriod`
 * @param target `{ date: string, fromTo: IFromTo }`
 * @returns
 */
export const getDurationTitle = (
  datePeriodType: DatePeriod,
  target: {
    date: string
    fromTo: IFromTo
  }
): string => {
  if (datePeriodType === DatePeriod.EVERY_MONTH) {
    if (target.date === '') return ''
    const [year, month] = target.date.split('-')
    return i18nAdminAnalytics('users.date.month', { year, month })
  }

  const duration = (() => {
    if (datePeriodType === DatePeriod.SPECIFIED_PERIOD) {
      if (target.fromTo.from === '' || target.fromTo.to === '') {
        return null
      }
      return [target.fromTo.from, target.fromTo.to]
    }

    const dateFormat = 'YYYY-MM-DD'
    const nowDateTime = dayjs()
    const dayCount = Number(datePeriodType)
    const startDateTime = nowDateTime.subtract(dayCount, 'day')
    return [startDateTime.format(dateFormat), nowDateTime.format(dateFormat)]
  })()

  if (!duration) return ''

  const start = duration[0].split('-')
  const end = duration[1].split('-')
  return i18nAdminAnalytics('users.date.specifiedPeriod', {
    startYear: start[0],
    startMonth: start[1],
    startDay: start[2],
    endYear: end[0],
    endMonth: end[1],
    endDay: end[2],
  })
}
