import { Routes } from 'common/enums'
import { ICoupon } from 'common/interfaces/stripe/coupon'
import { goBackWithErrorAlert } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/StripeCouponForm/FormBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initCouponForm } from 'services/admin/coupon_form'

const StripeCouponForm: React.FC = () => {
  const { t } = useTranslation('adminStripeCoupon')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const { pathname, state } = useLocation<{ coupon: ICoupon }>()

  if (!storeCache.team!.stripe.account_id) {
    goBackWithErrorAlert(
      history,
      i18nAlert('stripe.setting.unfinish.notFunction')
    )
    return null
  }

  const isCreate = pathname === Routes.AdminStripeCouponCreate

  return (
    <>
      <H1 title={isCreate ? t('form.new') : t('form.edit')} />

      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminStripeCoupon}
          content={t('form.back')}
        />
        <FormBody
          couponObj={isCreate ? initCouponForm() : state.coupon}
          isCreate={isCreate}
        />
      </Container>
    </>
  )
}

export default StripeCouponForm
