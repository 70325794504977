import classNames from 'classnames'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEnqueteAnswerField } from 'common/interfaces/enquete_answer'
import { IEnqueteCustomize } from 'common/interfaces/enquete_customize'
import { IEventApplication } from 'common/interfaces/event_application'
import { IVideo } from 'common/interfaces/video'
import { isEventPage } from 'common/link'
import { dateFormat } from 'common/times'
import { makeGenreBadge } from 'components/atoms/VideoDetail/ElementsOnVideoDetail'
import BunnyVideo from 'components/molecules/User/VideoDetail/BunnyVideo'
import Comment from 'components/molecules/User/VideoDetail/Comment'
import EnqueteModal from 'components/molecules/User/VideoDetail/EnqueteModal'
import EventApplicationModal from 'components/molecules/User/VideoDetail/EventApplicationModal'
import Overview from 'components/molecules/User/VideoDetail/Overview'
import Test from 'components/molecules/User/VideoDetail/Test'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import {
  initEnqueteField,
  onChangeInputForEnquete as serviceOnChangeInputForEnquete,
} from 'services/user/enquete'
import { saveEnqueteAnswer } from 'services/user/enquete_answer'
import {
  EventApplicationInputType,
  createEventApplication,
  initEventApplication,
  onChangeInputForEventApplication as serviceOnChangeInputForEventApplication,
} from 'services/user/event_application'
import {
  addUserBookmark,
  checkWatchLater,
  isAutoPlay,
  isCommentActive,
  isNotLimitPlayAndCatalogPage,
  isTestActive,
  removeUserBookmark,
  onChangeWatchLater as serviceOnChangeWatchLater,
  useVideoData,
} from 'services/user/video_detail'
import { useVideoEnqueteData } from 'services/user/video_detail_enquete'
import './videodetailbody.scss'

interface IVideoDetailBody {
  isCatalogPage: boolean
  parentId: string | undefined
  video: IVideo
}

const VideoDetailBody: React.FC<IVideoDetailBody> = ({
  isCatalogPage,
  parentId,
  video,
}) => {
  const { t } = useTranslation('userVideo')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation()

  const isEvent = isEventPage()
  const {
    event,
    creator,
    nextContent,
    sameCategory3Videos,
    limitPlay,
    isBookmarked,
  } = useVideoData(isCatalogPage, isEvent, storeCache, parentId, video)
  const { enquete, enqueteAnswer, setEnqueteAnswer } = useVideoEnqueteData(
    isCatalogPage,
    storeCache,
    video
  )

  const [bookmarked, setBookmark] = useState<boolean>(isBookmarked)
  const [watchLater, setWatchLater] = useState<boolean>(false)
  const [enqueteField, setEnqueteField] = useState<IEnqueteAnswerField[]>([])
  const [enqueteModal, setEnqueteModal] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<string>('overview')
  const [eventApplicationModal, setEventApplicationModal] =
    useState<boolean>(false)
  const [eventApplication, setEventApplication] = useState<IEventApplication>(
    initEventApplication(storeCache.user)
  )

  useMemo(() => {
    if (isCatalogPage) return
    checkWatchLater(storeCache, video, setWatchLater)
  }, [storeCache, video, isCatalogPage])

  useMemo(() => {
    if (!enquete?.questions) return

    setEnqueteField(initEnqueteField(enquete))
  }, [enquete])

  const isNotLimitPlayCatalogPage = useMemo(
    () => isNotLimitPlayAndCatalogPage(limitPlay, isCatalogPage),
    [limitPlay, isCatalogPage]
  )

  if (
    !video ||
    (!isCatalogPage && video.enquete?.activate_enquete && !enquete)
  ) {
    return null
  }

  const onToggleModal = () => setEnqueteModal(!enqueteModal)

  const onChangeInputForEnquete = (
    e: React.ChangeEvent<HTMLInputElement>,
    enquete: IEnqueteCustomize,
    index: number
  ) =>
    serviceOnChangeInputForEnquete(e, enquete, index, {
      enqueteField,
      setEnqueteField,
    })

  const onAnswerEnquete = () =>
    saveEnqueteAnswer(
      storeCache,
      video,
      enqueteField,
      enquete?.questions,
      setEnqueteAnswer,
      setEnqueteModal
    )

  const bookmark = () =>
    addUserBookmark(storeCache, video, bookmarked, setBookmark)
  const unBookmark = () =>
    removeUserBookmark(storeCache, video, bookmarked, setBookmark)

  const onChangeWatchLater = () =>
    serviceOnChangeWatchLater(storeCache, video, watchLater, setWatchLater)

  const autoplay = isAutoPlay(location)

  const isShowComments = isCommentActive(
    storeCache.team,
    limitPlay,
    isCatalogPage
  )
  const isShowTest = isTestActive(video, limitPlay, isCatalogPage)

  const showTestAndScroll = () => {
    setActiveTab('test')
    const bodyRect = document.body.getBoundingClientRect()
    const { top, height } = document
      .getElementById('video-container')!
      .getBoundingClientRect()
    const videoBottomPos =
      Math.abs(Math.abs(bodyRect.top) - Math.abs(top)) + height + 1
    window.scrollTo({ top: videoBottomPos, behavior: 'smooth' })
  }

  const onToggleEventApplicationModal = () =>
    setEventApplicationModal(!eventApplicationModal)

  const onChangeInputForEventApplication = (
    type: EventApplicationInputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) =>
    serviceOnChangeInputForEventApplication(type, e, {
      eventApplication,
      setEventApplication,
    })

  const onApplyToEvent = () =>
    createEventApplication(
      history,
      storeCache,
      isEvent,
      event,
      eventApplication,
      onToggleEventApplicationModal
    )

  // To force re-render when enqueteAnswer is updated.
  const bunnyVideoKey = enqueteAnswer?.id
  return (
    <div className="user-video-detail">
      <BunnyVideo
        key={bunnyVideoKey}
        isCatalogPage={isCatalogPage}
        id="video-container"
        event={event}
        video={{ video, autoplay }}
        limitPlay={limitPlay}
        enquete={{ enquete, enqueteAnswer, setEnqueteModal }}
        test={{ isShowTest, onShowTest: showTestAndScroll }}
        onToggleEventApplicationModal={onToggleEventApplicationModal}
      />

      <Container>
        {genreBadgeRow(storeCache, video)}
        {videoTitleRow(
          isCatalogPage,
          video.name,
          bookmarked,
          { bookmark, unBookmark },
          watchLater,
          onChangeWatchLater
        )}

        <Nav tabs className="tabs">
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === 'overview' })}
              onClick={() => setActiveTab('overview')}
            >
              {t('navItem.overview')}
            </NavLink>
          </NavItem>
          {isShowComments && (
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === 'comments' })}
                onClick={() => setActiveTab('comments')}
              >
                {t('navItem.comment')}
              </NavLink>
            </NavItem>
          )}
          {isShowTest && (
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === 'test' })}
                onClick={() => setActiveTab('test')}
              >
                {t('navItem.test')}
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="overview">
            <Overview
              isCatalogPage={isCatalogPage}
              parentId={parentId}
              video={video}
              creator={creator}
              nextContent={nextContent}
              sameCategory3Videos={sameCategory3Videos}
              limitPlay={limitPlay}
              isNotLimitPlayCatalogPage={isNotLimitPlayCatalogPage}
              enquete={enquete}
              enqueteAnswer={enqueteAnswer}
              onToggleEnqueteModal={onToggleModal}
            />
          </TabPane>
          {isShowComments && (
            <TabPane tabId="comments">
              <Comment video={video} />
            </TabPane>
          )}
          {isShowTest && (
            <TabPane tabId="test">
              <Test video={video} />
            </TabPane>
          )}
        </TabContent>

        {isNotLimitPlayCatalogPage && (
          <EnqueteModal
            enquete={enquete}
            enqueteModal={enqueteModal}
            onToggleModal={onToggleModal}
            onChange={onChangeInputForEnquete}
            onAnswer={onAnswerEnquete}
          />
        )}
        <EventApplicationModal
          isEvent={isEvent}
          event={event}
          showModal={eventApplicationModal}
          onToggleModal={onToggleEventApplicationModal}
          onChange={onChangeInputForEventApplication}
          onApplyToEvent={onApplyToEvent}
        />
      </Container>
    </div>
  )
}

const videoTitleRow = (
  isCatalogPage: boolean,
  title: string,
  bookmarked: boolean,
  { bookmark, unBookmark }: { bookmark: () => void; unBookmark: () => void },
  isWatchLater: boolean,
  onChangeWatchLater: () => void
): JSX.Element => (
  <Row className="video-title-row">
    <Col xs={isCatalogPage ? 12 : 10}>
      <h1 className="video-title">{title}</h1>
    </Col>
    {!isCatalogPage && (
      <Col xs="2" className="action-icons">
        <i
          className={classNames('fa-heart', {
            fas: bookmarked,
            far: !bookmarked,
          })}
          onClick={bookmarked ? unBookmark : bookmark}
        />
        <i
          className={classNames('fa-clock', {
            fas: isWatchLater,
            far: !isWatchLater,
          })}
          onClick={onChangeWatchLater}
        />
      </Col>
    )}
  </Row>
)

const genreBadgeRow = (storeCache: IStoreCache, video: IVideo): JSX.Element => (
  <div className="d-flex justify-content-between">
    {makeGenreBadge(storeCache, video.category_id)}
    {video.released_at && dateFormat(video.released_at, 'YYYY/MM/DD')}
  </div>
)

export default VideoDetailBody
