import { InvoiceStatusType } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IInvoice } from 'common/interfaces/stripe/Invoice'
import { isLoggedIn } from 'common/utils'
import { getInvoices } from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'

/**
 * 決済履歴取得処理
 */
export const getPaidHistories = async (
  storeCache: IStoreCache,
  setData: React.Dispatch<React.SetStateAction<IInvoice[]>>
): Promise<void> => {
  const { stripeId } = storeCache.team!
  if (!isLoggedIn(storeCache) || !stripeId) return

  try {
    const invoices = await getInvoices(undefined, stripeId)
    const paidVoidInvoices = invoices.filter((i) =>
      [InvoiceStatusType.PAID, InvoiceStatusType.VOID].includes(i.status)
    )
    setData(paidVoidInvoices)
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
