import { IFormImage, ITeam } from 'common/interfaces/team'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import {
  deleteFile,
  getTopImageDefaults,
  onChangeTopImage as serviceOnChangeTopImage,
  onChangeTopLink as serviceOnChangeTopLink,
  onChangeTopTab as serviceOnChangeTopTab,
  TOP_IMAGE_COUNT,
  updateImage,
} from 'services/admin/site_customize_image'

const FormBody: React.FC = () => {
  const { t } = useTranslation('adminSiteCustomize')
  const { storeCache } = useContext(AuthContext)
  const team = storeCache.team || ({} as ITeam)
  const [logo, setLogo] = useState<File>()

  const [topImages, setTopImages] = useState<IFormImage[]>(
    getTopImageDefaults(team)
  )

  if (!storeCache.team) return null

  const onChangeLogoImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setLogo(e.target.files[0])
    }
  }

  const onChangeTopImage = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => serviceOnChangeTopImage(topImages, setTopImages, e, index)
  const onChangeTopLink = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => serviceOnChangeTopLink(topImages, setTopImages, e, index)
  const onChangeTopTab = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    serviceOnChangeTopTab(topImages, setTopImages, e, index)
  }

  const deleteImage = (isLogo: boolean, fileId: string) =>
    deleteFile(storeCache, isLogo, fileId)
  const update = () => updateImage(logo, topImages, storeCache)

  return (
    <Form>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="teamLogoForm">
              {t('imageCustomize.form.logoImage')}
            </Label>
            <CustomInput
              id="teamLogoForm"
              onChange={onChangeLogoImage}
              type="file"
              accept="image/*"
            />
          </FormGroup>
        </Col>
      </Row>

      {team.logo !== null && (
        <Row className="mb-3">
          <Col>
            <Label>{t('imageCustomize.form.settedLogo')}</Label>
            <Row>
              <Col md="4">
                <img
                  height="auto"
                  width={200}
                  src={team.logo.path}
                  alt={team.name}
                />
              </Col>
              <Col md="8">
                <Button
                  onClick={() => deleteImage(true, team.logo!.id)}
                  className="my-4"
                  color="danger"
                  type="button"
                >
                  {t('delete')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {[...Array(TOP_IMAGE_COUNT)].map((_, index) => (
        <div key={index}>
          <Row>
            <Col md="6">
              <FormGroup className="mb-4 mb-md-0">
                <Label for={`teamTopImageForm${index}`}>
                  {t('imageCustomize.form.topImage', { index: index + 1 })}
                </Label>
                <CustomInput
                  id={`teamTopImageForm${index}`}
                  onChange={(e) => onChangeTopImage(e, index)}
                  type="file"
                  accept="image/*"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup className="mb-0">
                <Label for={`teamTopLinkForm${index}`}>
                  {t('imageCustomize.form.link', { index: index + 1 })}
                </Label>
                <Input
                  id={`teamTopLinkForm${index}`}
                  defaultValue={topImages[index].link}
                  type="url"
                  onChange={(e) => onChangeTopLink(e, index)}
                />
              </FormGroup>
            </Col>
            <Col md={{ size: 6, offset: 6 }}>
              <FormGroup check className="mb-4">
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={(e) => onChangeTopTab(e, index)}
                    defaultChecked={topImages[index].asNewTab}
                  />
                  {t('imageCustomize.form.asNewTab')}
                </Label>
              </FormGroup>
            </Col>
          </Row>

          {team.top_images[index] && (
            <Row className="mb-3">
              <Col>
                <Label>
                  {t('imageCustomize.form.settedTopImage', {
                    index: index + 1,
                  })}
                </Label>
                <Row className="my-2">
                  <Col md="4">
                    <img
                      height="auto"
                      width={280}
                      src={team.top_images[index].path}
                      alt={team.name}
                    />
                  </Col>
                  <Col md="8">
                    <Button
                      onClick={() =>
                        deleteImage(false, team.top_images[index].id)
                      }
                      className="my-4"
                      color="danger"
                      type="button"
                    >
                      {t('delete')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      ))}

      <div className="text-center">
        <Button onClick={update} className="my-4" color="primary" type="button">
          {t('save')}
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
