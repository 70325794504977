import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { findCachedCategory, findCachedCreator } from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IVideo } from 'common/interfaces/video'
import {
  MultiVideoUpdateType,
  ValidationModal,
} from 'common/interfaces/video_multi_update'
import { datetimeFormat } from 'common/times'
import { duration2str, getFileSize } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import PreparingVideoLibrary from 'components/atoms/Loading/PreparingVideoLibrary'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  createOverviewContent,
  makeEditDeleteOperationButtons,
  makeFilelistBadges,
  makeGroupBadges,
  makeTagBadges,
  makeThumbnailElement,
  makeVideoStatusBadge,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { History } from 'history'
import { TFunction } from 'i18next'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import { uploadProgressService } from 'services/admin/upload_progress'
import {
  isUploading,
  removeVideo,
  showUploadErrorModal,
  streamPreparingVideoLibrary,
  streamVideos,
} from 'services/admin/video'
import { toggleVideoMultiSelectModal as serviceToggleVideoMultiSelectModal } from 'services/admin/video_multi_update'
import { ValidateModal } from './ValidateModal'
import VideoMultiSelectModal from './VideoMultiSelectModal'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminVideo')
  const { storeCache } = useContext(AuthContext)
  const [videos, setVideos] = useState<IVideo[]>([])
  const [selectedVideos, setSelectedVideos] = useState<IVideo[]>([])
  const [videoUpdateType, setVideoUpdateType] = useState<MultiVideoUpdateType>(
    MultiVideoUpdateType.ADD_TAGS
  )
  const [uploadingVideoIds, setUploadingVideoIds] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPreparing, setIsPreparing] = useState<boolean>(true)

  const [videoMultiSelectModal, setVideoMultiSelectModal] =
    useState<boolean>(false)
  const [validateModal, setValidateModal] = useState<ValidationModal>({
    open: false,
    invalidVideos: [],
    error: null,
  })

  const toggleVideoMultiSelectModal = (type?: MultiVideoUpdateType) => {
    serviceToggleVideoMultiSelectModal(
      selectedVideos,
      videoMultiSelectModal,
      setVideoMultiSelectModal,
      setValidateModal,
      setVideoUpdateType,
      type
    )
  }

  useEffect(() => {
    const unsubVideos = streamVideos(storeCache, setVideos, setIsLoading)

    let unsubPreparing: (() => void) | null = null
    ;(async () => {
      unsubPreparing = await streamPreparingVideoLibrary(
        storeCache,
        setIsPreparing
      )
    })()
    return () => {
      unsubVideos()
      unsubPreparing?.()
    }
  }, [storeCache])

  useEffect(() => {
    const subscription = uploadProgressService.onChange().subscribe((tasks) => {
      if (uploadingVideoIds.length !== tasks.length) {
        setUploadingVideoIds(tasks.map((t) => t.videoId))
      }
    })
    return () => subscription.unsubscribe()
  }, [uploadingVideoIds.length])

  if (isLoading) return <LoadingSpinner />
  if (isPreparing && videos.length <= 0) return <PreparingVideoLibrary />
  if (videos.length <= 0) {
    return <RegisterItemButton type="video" />
  }
  return (
    <>
      <div>{t('list.tips')}</div>
      <ButtonRight nextPage={Routes.AdminVideoCreate} content={t('list.new')} />

      <VideoMultiSelectModal
        isOpen={videoMultiSelectModal}
        toggleModal={toggleVideoMultiSelectModal}
        type={videoUpdateType}
        selectedVideos={selectedVideos}
      />

      <ValidateModal
        toggleModal={() => setValidateModal({ ...validateModal, open: false })}
        {...validateModal}
      />

      {selectedVideos.length > 0 && (
        <div className="mb-4">
          <Trans
            t={t}
            i18nKey="list.dropdown.action"
            components={[
              <UncontrolledDropdown>
                <DropdownToggle caret color="secondary">
                  <></>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() =>
                      toggleVideoMultiSelectModal(MultiVideoUpdateType.ADD_TAGS)
                    }
                  >
                    {t('list.dropdown.addTags')}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      toggleVideoMultiSelectModal(
                        MultiVideoUpdateType.CHANGE_CATEGORY
                      )
                    }
                  >
                    {t('list.dropdown.changeCategory')}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      toggleVideoMultiSelectModal(
                        MultiVideoUpdateType.CHANGE_VIDEO_STATUS
                      )
                    }
                  >
                    {t('list.dropdown.changeVideoStatus')}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      toggleVideoMultiSelectModal(
                        MultiVideoUpdateType.ADD_PUBLISH_GROUP
                      )
                    }
                  >
                    {t('list.dropdown.addPublishGroups')}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      toggleVideoMultiSelectModal(
                        MultiVideoUpdateType.CHANGE_CREATOR
                      )
                    }
                  >
                    {t('list.dropdown.changeCreator')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>,
            ]}
          />
        </div>
      )}

      <Table
        videos={videos}
        uploadingVideoIds={uploadingVideoIds}
        onChangeSelectedVideos={setSelectedVideos}
      />
    </>
  )
}

const Table: React.FC<{
  videos: IVideo[]
  uploadingVideoIds: string[]
  onChangeSelectedVideos: (videos: IVideo[]) => void
}> = ({ videos, uploadingVideoIds, onChangeSelectedVideos }) => {
  const { t } = useTranslation('adminVideo')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const columns = useMemo<ColumnDef<IVideo>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => videos.length - i,
      },
      {
        header: t('list.table.status'),
        accessorFn: (v) =>
          getVideoStatus(t, history, storeCache, uploadingVideoIds, v),
      },
      {
        header: t('list.table.thumbnail'),
        accessorFn: (v) => makeThumbnailElement(v.image, v.name, 'video'),
      },
      {
        header: t('list.table.title'),
        accessorKey: 'name',
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        header: t('list.table.category'),
        accessorFn: (v) =>
          findCachedCategory(storeCache, v.category_id)?.name ?? '',
      },
      {
        header: t('list.table.tag'),
        accessorFn: (v) => makeTagBadges(storeCache, v.tag_ids),
      },
      {
        header: t('list.table.file'),
        accessorFn: (v) => makeFilelistBadges(storeCache, v.filelist_ids),
      },
      {
        header: t('list.table.overview'),
        accessorFn: (v) => createOverviewContent(v.overview),
      },
      {
        header: t('list.table.creator'),
        accessorFn: (v) =>
          findCachedCreator(storeCache, v.creator_id)?.name ?? '',
      },
      {
        header: t('list.table.publishGroup'),
        accessorFn: (v) => makeGroupBadges(storeCache, v.group_ids),
      },
      {
        header: t('list.table.duration'),
        accessorKey: 'bunny.length',
        cell: (cell) => duration2str(cell.getValue<number>()),
        enableSorting: true,
      },
      {
        header: t('list.table.fileSize'),
        accessorKey: 'bunny.storageSize',
        cell: (cell) => getFileSize(cell.getValue<number>()),
        enableSorting: true,
      },
      {
        header: t('list.table.createdAt'),
        accessorFn: (v) => datetimeFormat(v.created_at),
        enableSorting: true,
      },
      {
        header: t('list.table.operations'),
        accessorFn: (v) =>
          makeEditDeleteOperationButtons<IVideo>(
            history,
            'video',
            Routes.AdminVideoEdit,
            (e) => removeVideo(e, storeCache),
            v
          ),
      },
    ],
    [storeCache, history, videos.length, uploadingVideoIds, t]
  )

  const data = videos.sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  )
  return useTanstackTable<IVideo>(columns, data, {
    withSearch: true,
    fixedLastColumn: true,
    enableMultiRowSelection: true,
    onChangeSelectedRows: onChangeSelectedVideos,
  })
}

const getVideoStatus = (
  t: TFunction<'adminVideo'>,
  history: History,
  storeCache: IStoreCache,
  uploadingVideoIds: string[],
  video: IVideo
) => {
  if (isUploading(uploadingVideoIds, video.id)) {
    return t('list.status.uploading')
  }
  if (!video.is_uploaded) {
    return uploadFailedButton(t, history, storeCache, video)
  }
  if (video.bunny.status !== 'Finished') {
    return t('list.status.encoding')
  }
  return makeVideoStatusBadge(video.status)
}

const uploadFailedButton = (
  t: TFunction<'adminVideo'>,
  history: History,
  storeCache: IStoreCache,
  video: IVideo
) => {
  return (
    <Button
      onClick={() => showUploadErrorModal(history, storeCache, video)}
      size="sm"
      color="warning"
    >
      {t('list.status.uploadFailed')}
    </Button>
  )
}

export default TableBody
