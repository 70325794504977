import { IGroup } from 'common/interfaces/group'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  InputType,
  onChangeInput as serviceOnChangeInput,
  saveGroup,
} from 'services/admin/group_form'

interface IFormBodyProps {
  isCreate: boolean
  groupObj: IGroup
  history: History
}

const FormBody: React.FC<IFormBodyProps> = ({
  isCreate,
  groupObj,
  history,
}: IFormBodyProps) => {
  const { t } = useTranslation('adminUserGroup')
  const { storeCache } = useContext(AuthContext)
  const [group, setGroup] = useState<IGroup>(groupObj)

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(group, setGroup, type, e)
  const save = () => saveGroup(history, group, storeCache, isCreate)

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="groupNameForm">
              <InfoTooltip
                content={t('form.groupNameForm.title')}
                tooltipContent={t('form.groupNameForm.tips')}
                required
              />
            </Label>
            <Input
              id="groupNameForm"
              onChange={(e) => onChangeInput(InputType.NAME, e)}
              type="text"
              value={group.name}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {t('form.save')}
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
