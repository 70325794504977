import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICategory } from 'common/interfaces/category'
import { nowTimestamp } from 'common/times'
import { checkFirebaseError, emptyNumStr2Null, isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedCategories } from 'providers/AuthProvider'
import { removeImageFile, upload } from 'repositories/storage/firebase_category'
import {
  removeImage,
  store,
  update,
} from 'repositories/store/firebase_category'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/category_form'
import { v4 as uuidv4 } from 'uuid'

/**
 * `ICategory`の初期値を返却する
 */
export const initCategory = (): ICategory => {
  return {
    id: uuidv4(),
    name: '',
    image: null,
    sort_num: null,
    created_at: nowTimestamp(),
    updated_at: nowTimestamp(),
  }
}

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
  SORT_NUM,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  category: ICategory,
  setCategory: React.Dispatch<React.SetStateAction<ICategory>>,
  type: InputType,
  e: React.ChangeEvent<HTMLInputElement>
) => {
  setCategory({
    ...category,
    name: type === InputType.NAME ? e.target.value : category.name,
    sort_num:
      type === InputType.SORT_NUM
        ? emptyNumStr2Null(e.target.value)
        : category.sort_num,
  })
}

/**
 * カテゴリレコード作成/更新処理
 */
export const saveCategory = async (
  history: History,
  category: ICategory,
  imageBlob: Blob | undefined,
  isImageDelete: boolean,
  isImageCropped: boolean,
  storeCache: IStoreCache,
  isCreate: boolean
) => {
  if (!isLoggedIn(storeCache)) return

  try {
    validateForm(category, isImageCropped)

    if (imageBlob && !isImageDelete) {
      category.image = await upload(storeCache.team!, category, imageBlob)
    }
    if (!isCreate && isImageDelete) {
      await deleteImage(category, storeCache)
    }

    if (isCreate) {
      category.created_at = nowTimestamp()
      category.updated_at = nowTimestamp()
      await store(storeCache.team!, category)
    } else {
      await update(storeCache.team!, category)
    }
    await reloadCachedCategories(storeCache)

    history.push(Routes.AdminCategory)
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}

/**
 * カテゴリの画像を削除する(ドキュメント更新 & ファイル削除)
 */
const deleteImage = async (
  category: ICategory,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    await removeImageFile(storeCache.team!, category)
    await removeImage(storeCache.team!, category)

    alertService.show(true, i18nAlert('deleted.thumbnail'))
  } catch (error) {
    console.log(error)
    alertService.show(false, i18nAlert('deleted.fail.thumbnail'))
  }
}
