import { Routes } from 'common/enums'
import { ICategory } from 'common/interfaces/category'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/CategoryForm/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initCategory } from 'services/admin/category_form'

const CategoryForm: React.FC = () => {
  const { t } = useTranslation('adminCategory')
  const history = useHistory()
  const location = useLocation<{ category: ICategory }>()
  const isCreate = location.pathname === Routes.AdminCategoryCreate
  const category = isCreate ? initCategory() : location.state.category

  return (
    <>
      <H1 title={isCreate ? t('form.title.new') : t('form.title.edit')} />

      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminCategory}
          content={t('form.backToList')}
        />
        <FormBody
          isCreate={isCreate}
          categoryObj={category}
          history={history}
        />
      </Container>
    </>
  )
}

export default CategoryForm
