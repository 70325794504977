import { IStoreCache } from 'common/interfaces/auth_provider'
import {
  ITopItemOrder,
  TopItemOrderType,
} from 'common/interfaces/top_item_order'
import { checkFirebaseError, isLoggedIn } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedTeam } from 'providers/AuthProvider'
import { DropResult } from 'react-beautiful-dnd'
import { updateItemOrder } from 'repositories/store/firebase_team'
import { alertService } from 'services/alert'

/**
 * 「一覧」選択時の`value`
 */
export const ITEM_LIST = 'list'

/**
 * 選択項目から追加ボタンを押下した際
 */
export const onAddItem = (
  type: TopItemOrderType,
  states: {
    video: {
      addPopularGenreId: string
      addCategoryId: string
      addTagId: string
      addCreatorId: string
    }
    live: {
      addCategoryId: string
      addTagId: string
      addCreatorId: string
    }
    playlist: {
      addCategoryId: string
      addTagId: string
    }
    addPlaylistId: string
  },
  itemOrderStates: {
    selectedItems: ITopItemOrder[]
    setSelectedItems: React.Dispatch<React.SetStateAction<ITopItemOrder[]>>
  }
) => {
  const targetId = (() => {
    switch (type) {
      case TopItemOrderType.VIDEOS_POPULAR_IN_GENRE:
        if (states.video.addPopularGenreId === ITEM_LIST) {
          type = TopItemOrderType.VIDEOS_POPULAR
          return undefined
        }
        return states.video.addPopularGenreId
      case TopItemOrderType.VIDEOS_IN_CATEGORY:
        if (states.video.addCategoryId === ITEM_LIST) {
          type = TopItemOrderType.CATEGORIES_VIDEO
          return undefined
        }
        return states.video.addCategoryId
      case TopItemOrderType.VIDEOS_IN_TAG:
        if (states.video.addTagId === ITEM_LIST) {
          type = TopItemOrderType.TAGS_VIDEO
          return undefined
        }
        return states.video.addTagId
      case TopItemOrderType.VIDEOS_IN_CREATOR:
        if (states.video.addCreatorId === ITEM_LIST) {
          type = TopItemOrderType.CREATORS_VIDEO
          return undefined
        }
        return states.video.addCreatorId
      case TopItemOrderType.LIVES_IN_CATEGORY:
        if (states.live.addCategoryId === ITEM_LIST) {
          type = TopItemOrderType.CATEGORIES_LIVE
          return undefined
        }
        return states.live.addCategoryId
      case TopItemOrderType.LIVES_IN_TAG:
        if (states.live.addTagId === ITEM_LIST) {
          type = TopItemOrderType.TAGS_LIVE
          return undefined
        }
        return states.live.addTagId
      case TopItemOrderType.LIVES_IN_CREATOR:
        if (states.live.addCreatorId === ITEM_LIST) {
          type = TopItemOrderType.CREATORS_LIVE
          return undefined
        }
        return states.live.addCreatorId
      case TopItemOrderType.PLAYLISTS_IN_CATEGORY:
        if (states.playlist.addCategoryId === ITEM_LIST) {
          type = TopItemOrderType.CATEGORIES_PLAYLIST
          return undefined
        }
        return states.playlist.addCategoryId
      case TopItemOrderType.PLAYLISTS_IN_TAG:
        if (states.playlist.addTagId === ITEM_LIST) {
          type = TopItemOrderType.TAGS_PLAYLIST
          return undefined
        }
        return states.playlist.addTagId
      case TopItemOrderType.VIDEOS_IN_PLAYLIST:
        if (states.addPlaylistId === ITEM_LIST) {
          type = TopItemOrderType.PLAYLISTS
          return undefined
        }
        return states.addPlaylistId
    }
  })()
  const item: ITopItemOrder = targetId ? { type, targetId } : { type }
  itemOrderStates.setSelectedItems([...itemOrderStates.selectedItems, item])
}

/**
 * 表示順のxボタンを押下した際
 */
export const onRemoveItem = (
  position: number,
  itemOrderStates: {
    selectedItems: ITopItemOrder[]
    setSelectedItems: React.Dispatch<React.SetStateAction<ITopItemOrder[]>>
  }
) => {
  itemOrderStates.selectedItems.splice(position, 1)
  itemOrderStates.setSelectedItems([...itemOrderStates.selectedItems])
}

/**
 * 表示順のInputを変更した際
 */
export const onChangeSelectedItem = (
  index: number,
  type: TopItemOrderType,
  targetId: string,
  itemOrderStates: {
    selectedItems: ITopItemOrder[]
    setSelectedItems: React.Dispatch<React.SetStateAction<ITopItemOrder[]>>
  }
) => {
  type = (() => {
    switch (type) {
      case TopItemOrderType.VIDEOS_POPULAR:
      case TopItemOrderType.VIDEOS_POPULAR_IN_GENRE:
        return targetId === ITEM_LIST
          ? TopItemOrderType.VIDEOS_POPULAR
          : TopItemOrderType.VIDEOS_POPULAR_IN_GENRE
      case TopItemOrderType.CATEGORIES_VIDEO:
      case TopItemOrderType.VIDEOS_IN_CATEGORY:
        return targetId === ITEM_LIST
          ? TopItemOrderType.CATEGORIES_VIDEO
          : TopItemOrderType.VIDEOS_IN_CATEGORY
      case TopItemOrderType.TAGS_VIDEO:
      case TopItemOrderType.VIDEOS_IN_TAG:
        return targetId === ITEM_LIST
          ? TopItemOrderType.TAGS_VIDEO
          : TopItemOrderType.VIDEOS_IN_TAG
      case TopItemOrderType.CREATORS_VIDEO:
      case TopItemOrderType.VIDEOS_IN_CREATOR:
        return targetId === ITEM_LIST
          ? TopItemOrderType.CREATORS_VIDEO
          : TopItemOrderType.VIDEOS_IN_CREATOR
      case TopItemOrderType.CATEGORIES_LIVE:
      case TopItemOrderType.LIVES_IN_CATEGORY:
        return targetId === ITEM_LIST
          ? TopItemOrderType.CATEGORIES_LIVE
          : TopItemOrderType.LIVES_IN_CATEGORY
      case TopItemOrderType.TAGS_LIVE:
      case TopItemOrderType.LIVES_IN_TAG:
        return targetId === ITEM_LIST
          ? TopItemOrderType.TAGS_LIVE
          : TopItemOrderType.LIVES_IN_TAG
      case TopItemOrderType.CREATORS_LIVE:
      case TopItemOrderType.LIVES_IN_CREATOR:
        return targetId === ITEM_LIST
          ? TopItemOrderType.CREATORS_LIVE
          : TopItemOrderType.LIVES_IN_CREATOR
      case TopItemOrderType.PLAYLISTS:
      case TopItemOrderType.VIDEOS_IN_PLAYLIST:
        return targetId === ITEM_LIST
          ? TopItemOrderType.PLAYLISTS
          : TopItemOrderType.VIDEOS_IN_PLAYLIST
      case TopItemOrderType.CATEGORIES_PLAYLIST:
      case TopItemOrderType.PLAYLISTS_IN_CATEGORY:
        return targetId === ITEM_LIST
          ? TopItemOrderType.CATEGORIES_PLAYLIST
          : TopItemOrderType.PLAYLISTS_IN_CATEGORY
      case TopItemOrderType.TAGS_PLAYLIST:
      case TopItemOrderType.PLAYLISTS_IN_TAG:
        return targetId === ITEM_LIST
          ? TopItemOrderType.TAGS_PLAYLIST
          : TopItemOrderType.PLAYLISTS_IN_TAG
    }
    return type
  })()
  const item: ITopItemOrder =
    targetId !== ITEM_LIST ? { type, targetId } : { type }
  itemOrderStates.selectedItems.splice(index, 1, item)
  itemOrderStates.setSelectedItems([...itemOrderStates.selectedItems])
}

/**
 * 表示順のドラッグが終了した際
 */
export const onDragEnd = (
  result: DropResult,
  itemOrderStates: {
    selectedItems: ITopItemOrder[]
    setSelectedItems: React.Dispatch<React.SetStateAction<ITopItemOrder[]>>
  }
) => {
  if (!result.destination) {
    return
  }

  const items = (() => {
    const data = Array.from(itemOrderStates.selectedItems)
    const [removed] = data.splice(result.source.index, 1)
    data.splice(result.destination.index, 0, removed)
    return data
  })()
  itemOrderStates.setSelectedItems(items)
}

/**
 * チームのトップページの表示順の更新処理
 */
export const updateTeamTopItemOrder = async (
  topItemOrder: ITopItemOrder[],
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    await updateItemOrder(storeCache.team!, topItemOrder)
    await reloadCachedTeam(storeCache)

    alertService.show(true, i18nAlert('updated.order'))
  } catch (error) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, i18nAlert('updated.fail.order'))
  }
}
