import { IEmailLog } from 'common/interfaces/email_logs'
import { formatUNIXToDate } from 'common/times'
import NormalButton from 'components/atoms/Button/Normal'
import QuillHtml from 'components/atoms/Quill/QuillHtml'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

const ShowBody: React.FC = () => {
  const { t } = useTranslation('adminSendEmail')
  const { goBack } = useHistory()
  const {
    state: { emailLog },
  } = useLocation<{ emailLog: IEmailLog }>()

  return (
    <>
      <Row className="justify-content-end mb-3 mr-1 mr-md-0">
        <NormalButton
          className="justify-content-end mb-3 mr-1 mr-md-0"
          onClick={() => goBack()}
          content={t('sendEmailLogsDetail.backToList')}
        />
      </Row>
      <h3>{t('sendEmailLogsDetail.status')}</h3>
      <Row className="mb-4">
        <Col md="12">{emailLog.event}</Col>
      </Row>
      <h3>{t('sendEmailLogsDetail.emailTo')}</h3>
      <Row className="mb-4">
        <Col md="12">{emailLog.email}</Col>
      </Row>
      <h3>{t('sendEmailLogsDetail.emailTitle')}</h3>
      <Row className="mb-4">
        <Col md="12">{emailLog.email_title}</Col>
      </Row>
      <h3>{t('sendEmailLogsDetail.emailContent')}</h3>
      <Row className="mb-4">
        <Col md="12">
          <QuillHtml html={emailLog.email_content} />
        </Col>
      </Row>
      <h3>{t('sendEmailLogsDetail.sendTime')}</h3>
      <Row className="mb-4">
        <Col md="12">
          {formatUNIXToDate(emailLog.timestamp, 'YYYY-MM-DD HH:mm:ss')}
        </Col>
      </Row>
    </>
  )
}

export default ShowBody
