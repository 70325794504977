import H1 from 'components/atoms/Head/H1'
import ShowBody from 'components/organisms/Admin/AdminEmailLogShow/ShowBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'

const AdminEmailLogShow: React.FC = () => {
  const { t } = useTranslation('adminSendEmail')
  return (
    <>
      <H1 title={t('sendEmailLogsDetail.title')} />

      <Container fluid>
        <ShowBody />
      </Container>
    </>
  )
}

export default AdminEmailLogShow
