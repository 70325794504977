import { IStoreCache } from 'common/interfaces/auth_provider'
import { isNowThanBefore } from 'common/times'

/**
 * 無料体験期間中ではない且つプラン未加入状態なのか
 *
 * @param storeCache `IStoreCache`
 * @returns boolean
 */
export const isNotTrialAndNotSubscribe = ({
  team,
  subscriptionObj,
}: IStoreCache): boolean =>
  !isNowThanBefore(team!.expire_date) && !subscriptionObj

/**
 * 店舗が課金必須の設定にしている場合、ユーザが店舗に対して支払いしたのかを判定する
 *
 * @param storeCache `IStoreCache`
 * @returns boolean
 */
export const isNotPaidUser = ({
  team,
  user,
  selfSubscriptionObj,
}: IStoreCache): boolean =>
  team !== null &&
  user !== null &&
  team.stripe.is_products_activate &&
  !selfSubscriptionObj &&
  !user!.admin
