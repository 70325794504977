import firebase from 'common/firebase'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { i18nCommon, i18nValidation } from 'i18n/i18n'
import { DateRange } from './enums'
import { IFromTo } from './interfaces/time'

/**
 * @returns get relative time by japanese
 */
export const getRelativeTimeByJa = (
  timestamp: firebase.firestore.Timestamp
): string => {
  dayjs.extend(relativeTime)
  dayjs.locale('ja')
  return dayjs(timestamp.toDate()).fromNow()
}

/**
 * get formatted now date
 *
 * @param format Default: `YYYY-MM-DD`
 */
export const nowTimeFormat = (format = 'YYYY-MM-DD'): string =>
  dayjs().format(format)

/**
 * get formatted now date
 *
 * @param date `string | number | Date`  Default: `firebase.firestore.Timestamp.now().seconds`
 * @param format Default: `YYYY-MM-DDTHH:mm`
 */
export const formatStartOfHour = (
  timestamp?: firebase.firestore.Timestamp,
  format = 'YYYY-MM-DDTHH:mm'
): string =>
  timestamp
    ? dayjs.unix(timestamp.seconds).startOf('hour').format(format)
    : dayjs().startOf('hour').format(format)

/**
 * @returns now firestore Timestamp
 */
export const nowTimestamp = (): firebase.firestore.Timestamp =>
  firebase.firestore.Timestamp.now()

/**
 * Date to firestore Timestamp
 * @param date `Date`
 * @returns firestore Timestamp
 */
export const date2Timestamp = (date: Date): firebase.firestore.Timestamp =>
  firebase.firestore.Timestamp.fromDate(date)

/**
 * Unix time to firestore Timestamp
 * @param unix number
 * @returns firestore Timestamp
 */
export const unix2Timestamp = (unix: number): firebase.firestore.Timestamp =>
  date2Timestamp(dayjs.unix(unix).toDate())

/**
 * @returns today [ 00:00:00 timestamp, 23:59:59 timestamp ]
 */
export const todayFirebaseTimestamp = (): firebase.firestore.Timestamp[] => {
  const times = todayUnixtimes()
  return [
    firebase.firestore.Timestamp.fromMillis(times[0] * 1000),
    firebase.firestore.Timestamp.fromMillis(times[1] * 1000),
  ]
}

/**
 * @returns tomorrow `YYYY-MM-DDTHH:mm`
 */
export const tomorrowDatetimeLocalFormat = (): string =>
  dayjs().add(1, 'day').format('YYYY-MM-DDTHH:mm')

/**
 * @returns 31 days later `YYYY-MM-DD`
 */
export const thirtyOneDaysLaterDateFormat = (): string =>
  dayjs().add(31, 'day').format('YYYY-MM-DD')

/**
 * @returns today [ 00:00:00 unixtime, 23:59:59 unixtime ]
 */
const todayUnixtimes = (): number[] => {
  const startUnixtime = dayjs().startOf('date').unix()
  const endUnixtime = dayjs().endOf('date').unix()
  return [startUnixtime, endUnixtime]
}

/**
 * @param timestamp firebase.firestore.Timestamp
 * @param value number
 * @param unit dayjs.ManipulateType
 * @returns `Date`  Add Days
 */
export const addTimestampDate = (
  { seconds }: firebase.firestore.Timestamp,
  value: number,
  unit: dayjs.ManipulateType
): number => dayjs.unix(seconds).add(value, unit).unix()

/**
 * @param value number
 * @param unit dayjs.UnitType
 * @returns Added timestamp
 */
export const addedTimestamp = (
  value: number,
  unit: dayjs.UnitType
): firebase.firestore.Timestamp =>
  firebase.firestore.Timestamp.fromMillis(
    dayjs().add(value, unit).unix() * 1000
  )

/**
 * @param days number
 * @param unit dayjs.ManipulateType
 * @returns unixtime Added Days
 */
export const addedUnixTime = (
  days: number,
  unit?: dayjs.ManipulateType
): number => dayjs().add(days, unit).unix()

/**
 * is today's time?
 * @param timestamp judge target timestamp
 */
export const isInTodayTime = (
  timestamp: firebase.firestore.Timestamp
): boolean => {
  const todayTimes = todayUnixtimes()
  const unixtime = timestamp.seconds
  return todayTimes[0] <= unixtime && unixtime <= todayTimes[1]
}

/**
 * to date format
 * @param timestamp timestamp
 * @returns YYYY年M月D日
 */
export const dateFormat = (
  { seconds }: firebase.firestore.Timestamp,
  format = i18nCommon('format.date')
): string => dayjs.unix(seconds).format(format)

/**
 * to **UTC** date format
 * @param timestamp timestamp
 * @param format Date format
 * @returns Formatted date string
 */
export const utcDateFormat = (
  { seconds }: firebase.firestore.Timestamp,
  format: string
): string => {
  dayjs.extend(utc)
  return dayjs.utc(seconds * 1000).format(format)
}

/**
 * to datetime format
 * @param timestamp timestamp
 * @returns YYYY年M月D日 HH時mm分
 */
export const datetimeFormat = ({
  seconds,
}: firebase.firestore.Timestamp): string =>
  dayjs.unix(seconds).format(i18nCommon('format.dateTime'))

/**
 * to Input's `datetime-local` format
 * @param timestamp timestamp
 * @returns YYYY-MM-DDTHH:mm:ss.SSSZ
 */
export const datetimeLocalFormat = ({
  seconds,
}: firebase.firestore.Timestamp): string =>
  dayjs.unix(seconds).format('YYYY-MM-DDTHH:mm')

/**
 * to period datetime format
 * @param from timestamp
 * @param to timestamp
 * @returns YYYY/MM/DD HH:mm 〜 [[YYYY/]MM/DD] HH:mm
 */
export const periodDatetimeFormat = (
  from: firebase.firestore.Timestamp,
  to: firebase.firestore.Timestamp
): string => {
  const fromDayjs = dayjs.unix(from.seconds)
  const toDayjs = dayjs.unix(to.seconds)

  const fromYear = fromDayjs.year()
  const fromDate = fromDayjs.format('MM/DD')
  const fromTime = fromDayjs.format('HH:mm')
  const toYear = toDayjs.year()
  const toDate = toDayjs.format('MM/DD')
  const toTime = toDayjs.format('HH:mm')

  if (fromYear !== toYear) {
    return `${fromYear}/${fromDate} ${fromTime} 〜 ${toYear}/${toDate} ${toTime}`
  }
  if (fromDate !== toDate) {
    return `${fromYear}/${fromDate} ${fromTime} 〜 ${toDate} ${toTime}`
  }
  return `${fromYear}/${fromDate} ${fromTime} 〜 ${toTime}`
}

/**
 * datetime to unixtime
 * @param date string
 * @returns unixtime
 */
export const formatDateToUNIX = (date: string): number => dayjs(date).unix()

/**
 * unixtime to datetime format
 * @param timestamp timestamp
 * @param format Default: `YYYY-MM-DD`
 * @returns formatted date
 */
export const formatUNIXToDate = (
  timestamp: number,
  format = 'YYYY-MM-DD'
): string => dayjs.unix(timestamp).format(format)

/**
 * 特定の日付から今日の日付の差分を算出する
 * @param date string
 * @returns number
 */
export const getDateDiff = (date: string): number =>
  Math.abs(dayjs().diff(dayjs(date), 'day'))

/**
 * 操作日よりも前の日時なのか（文字列型の日時専用）
 * @param date string
 * @returns true or false
 */
export const isNowThanBefore = (date: string): boolean => dayjs().isBefore(date)

/**
 * 操作日以前の日時なのか
 * @param date firebase.firestore.Timestamp
 * @returns true or false
 */
export const isBeforeTimestamp = ({
  seconds,
}: firebase.firestore.Timestamp): boolean =>
  dayjs().isBefore(dayjs.unix(seconds))

/**
 * 操作日以上の日時なのか
 * @param date firebase.firestore.Timestamp
 * @returns true or false
 */
export const isAfterTimestamp = ({
  seconds,
}: firebase.firestore.Timestamp): boolean =>
  dayjs().isAfter(dayjs.unix(seconds))

/**
 * FromよりもToが後なのか
 * @param from firebase.firestore.Timestamp
 * @param to firebase.firestore.Timestamp
 * @returns true or false
 */
export const isAfterFromTo = (
  from: firebase.firestore.Timestamp,
  to: firebase.firestore.Timestamp
): boolean => dayjs.unix(from.seconds).isAfter(dayjs.unix(to.seconds))

/**
 * 今日よりも後なのか
 * @param day string
 * @returns true or false
 */
export const isTodayThanAfter = (day: string): boolean =>
  dayjs(day).isAfter(dayjs())

/**
 * フォーマット判定
 * @param date string
 * @param format string | undefined
 * @returns true or false
 */
export const isValid = (date: string, format = 'YYYY-MM-DD'): boolean =>
  dayjs(date, format).format(format) === date

/**
 * ２つのUnixtimeを取得
 * @param dateRange `DateRange`
 * @param fromTo `IFromTo`
 * @returns number[start, end]
 */
export const getUnixTimesByDateRange = (
  dateRange: DateRange,
  fromTo: IFromTo
): number[] => {
  let startDayjs = dayjs()
  let endDayjs = dayjs()
  let unit: 'month' | 'day' = 'month'
  switch (dateRange) {
    case DateRange.CURRENT_MONTH:
      break
    case DateRange.LAST_MONTH:
      startDayjs = dayjs().subtract(1, 'month')
      endDayjs = dayjs().subtract(1, 'month')
      break
    case DateRange.FROM_TO:
      if (fromTo.from > fromTo.to) {
        throw new Error(i18nValidation('date.input.fromBeforeTo'))
      }

      startDayjs = dayjs(fromTo.from)
      endDayjs = dayjs(fromTo.to)
      unit = 'day'
  }
  return [startDayjs.startOf(unit).unix(), endDayjs.endOf(unit).unix()]
}
