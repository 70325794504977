import { EntryType } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ISubscription } from 'common/interfaces/subscription'
import { isLoggedIn } from 'common/utils'
import { getUnpaidSubscription } from 'repositories/functions/functions_stripe'
import { alertService } from './alert'

/**
 * 無料体験期間中ではない且つプラン未加入状態なのか
 *
 * @param storeCache `IStoreCache`
 * @returns boolean
 */
export const setSubscription = async (
  storeCache: IStoreCache,
  setUnpaidSubscription: React.Dispatch<
    React.SetStateAction<ISubscription | null>
  >,
  setEntryType: React.Dispatch<React.SetStateAction<EntryType>>
): Promise<void> => {
  if (
    !isLoggedIn(storeCache) ||
    !storeCache.team ||
    !storeCache.team.stripeId
  ) {
    return
  }

  try {
    const unpaidSubscription = await getUnpaidSubscription(
      undefined,
      storeCache.team!.stripeId
    )
    setUnpaidSubscription(unpaidSubscription)

    if (unpaidSubscription) {
      storeCache.user!.admin
        ? setEntryType(EntryType.UNPAID_ADMIN)
        : setEntryType(EntryType.UNPAID_UESR)
    }
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
