import { AuthType } from 'common/auth_type'
import { Routes } from 'common/enums'
import {
  FieldCustomizePublicStatus,
  IFieldCustomize,
  IFieldCustomizeType,
} from 'common/interfaces/field_customize'
import { IInvite } from 'common/interfaces/invite'
import { ILogin, ILoginByJoinForUser } from 'common/interfaces/login'
import { ISubscriptionObject } from 'common/interfaces/subscription'
import { ITeam } from 'common/interfaces/team'
import { ICustomizeFields } from 'common/interfaces/user'
import { IVideoPurchaseLead } from 'common/interfaces/video'
import { makeLoginTeamPath } from 'common/link_path'
import { inputType, isAuthMethodEmail, isTrialPlan } from 'common/utils'
import AccentButton from 'components/atoms/Button/Block/AccentButton'
import SignInWithGoogle from 'components/atoms/Button/SignInWithGoogle'
import ButtonLoading from 'components/atoms/Loading/ButtonLoading'
import RequiredText from 'components/atoms/Required/RequiredText'
import Footer from 'components/molecules/Footer'
import { UsingTrialNavbar } from 'components/molecules/User/UsingTrialNavbar/UsingTrialNavbar'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap'
import {
  IAnyFields,
  InputType,
  findTeamUser,
  initAnyFields,
  initAnyJoinState,
  initJoinState,
  joinUser,
  onChangeInputForCustomizeFields,
  serviceOnChangeInput,
  serviceOnChangeInputForAnyState,
} from 'services/join'

const FormBody: React.FC = () => {
  const { t } = useTranslation('join')
  const { inviteId } = useParams<{ inviteId: string }>()
  const videoPurchaseLead =
    useLocation<IVideoPurchaseLead | null>().state ?? null
  const history = useHistory()

  const [joinState, setJoinState] = useState<ILogin>(initJoinState())
  const [anyJoinState, setAnyJoinState] = useState<ILoginByJoinForUser>(
    initAnyJoinState()
  )
  const [customizeField, setCustomizeField] = useState<
    ICustomizeFields[] | null | undefined
  >([])
  const [team, setTeam] = useState<ITeam | null>(null)
  const [invite, setIInvite] = useState<IInvite | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [errorReason, setErrorReason] = useState<string>('')
  const [agreement, setAgreement] = useState<boolean>(false)
  const [isRegisterLoading, setIsRegisterLoading] = useState<boolean>(false)
  const [subscriptionObj, setSubscriptionObj] =
    useState<ISubscriptionObject | null>(null)

  useEffect(() => {
    setAgreement(!team?.urls.terms)
  }, [team])

  useEffect(() => {
    findTeamUser(
      inviteId,
      setTeam,
      setIInvite,
      setIsLoading,
      setErrorReason,
      setSubscriptionObj,
      setCustomizeField
    )
  }, [inviteId])

  if (isLoading) return null
  if (!team || !invite || errorReason) {
    history.replace(Routes.InvalidInviteCode, { message: errorReason })
    return null
  }

  const anyFields: IAnyFields[] = initAnyFields()

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(type, e, { joinState, setJoinState })

  const onChangeInputForAnyState = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) =>
    serviceOnChangeInputForAnyState(type, e, { anyJoinState, setAnyJoinState })

  const doJoin = (authType: AuthType) =>
    joinUser(
      history,
      setIsRegisterLoading,
      team,
      invite,
      inviteId,
      joinState,
      anyJoinState,
      customizeField,
      authType,
      agreement,
      videoPurchaseLead
    )

  const standardFormElement = (
    <>
      {formType('email', t('form.email'), (e) =>
        onChangeInput(InputType.EMAIL, e)
      )}
      {formType('password', t('form.password'), (e) =>
        onChangeInput(InputType.PASS, e)
      )}

      <TermsForm onChange={(e) => setAgreement(e.target.checked)} team={team} />

      <div className="text-center">
        {isRegisterLoading ? (
          <ButtonLoading className="my-2" />
        ) : (
          <AccentButton
            onClick={() => doJoin(AuthType.EMAIL)}
            className="my-4"
            content={t('form.createAccount')}
            colors={team.colors}
          />
        )}
      </div>

      <ToLoginForm team={team} videoPurchaseLead={videoPurchaseLead} />

      {team.social_logins.google && (
        <SignInWithGoogle onClick={() => doJoin(AuthType.GOOGLE)} centered />
      )}
    </>
  )

  const customFormElement = (
    <Form role="form">
      {formType('text', 'ID', (e) => onChangeInput(InputType.CUSTOM, e))}
      {formType('password', t('form.password'), (e) =>
        onChangeInput(InputType.PASS, e)
      )}

      <TermsForm onChange={(e) => setAgreement(e.target.checked)} team={team} />

      <div className="text-center">
        <AccentButton
          onClick={() => doJoin(AuthType.CUSTOM)}
          className="my-4"
          content={t('form.createAccount')}
          colors={team.colors}
        />
      </div>

      <ToLoginForm team={team} videoPurchaseLead={videoPurchaseLead} />
    </Form>
  )

  const formElement = () => {
    if (team.saml_provider_id) {
      return (
        <>
          <div className="text-center mt-3">
            <Button color="primary" onClick={() => doJoin(AuthType.SAML)}>
              {t('form.saml')}
            </Button>
          </div>

          <div className="text-center mt-4">
            <TermsForm
              onChange={(e) => setAgreement(e.target.checked)}
              team={team}
            />
          </div>

          <div className="text-center mt-4">
            <ToLoginForm team={team} videoPurchaseLead={videoPurchaseLead} />
          </div>
        </>
      )
    }
    return isAuthMethodEmail(team) ? standardFormElement : customFormElement
  }

  return (
    <div>
      {!isLoading && isTrialPlan(subscriptionObj) && <UsingTrialNavbar />}
      <div className="main-content">
        <Container className="mt-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <Trans t={t} i18nKey="title" values={{ team: team.name }} />
                  </div>
                  {team.logo && (
                    <div className="text-center mb-4">
                      <img
                        src={team.logo.path}
                        alt={team.name}
                        width="200"
                        height="auto"
                        style={{ borderRadius: '4px' }}
                      />
                    </div>
                  )}
                  <Form role="form">
                    {anyFields.map((field, key) => {
                      const activateField: any = invite.activate_field
                      if (!activateField[field.field]) return null

                      return (
                        <FormGroup className="mb-3" key={key}>
                          <RequiredText labelFor="expireForm" label>
                            {field.title}
                          </RequiredText>
                          <InputGroup className="input-group-alternative">
                            <Input
                              onChange={(e) =>
                                onChangeInputForAnyState(field.type, e)
                              }
                              placeholder={field.title}
                              type={inputType(field.field)}
                            />
                          </InputGroup>
                        </FormGroup>
                      )
                    })}
                    {customizeFieldsForm(invite, team, {
                      customizeField,
                      setCustomizeField,
                    })}
                    {formElement()}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Footer team={team} />
      </div>
    </div>
  )
}

const formType = (
  type: string,
  placeholder: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
): JSX.Element => (
  <FormGroup className="my-3">
    <InputGroup className="input-group-alternative">
      <Input
        onChange={onChange}
        placeholder={placeholder}
        type={inputType(type)}
      />
    </InputGroup>
  </FormGroup>
)

const customizeFieldsForm = (
  invite: IInvite,
  team: ITeam,
  state: {
    customizeField: ICustomizeFields[] | null | undefined
    setCustomizeField: React.Dispatch<
      React.SetStateAction<ICustomizeFields[] | null | undefined>
    >
  }
): JSX.Element[] => {
  if (!invite.activate_customize_fields || !team.customize_field) return []

  return team.customize_field.map((cf: IFieldCustomize, key: number) =>
    formTypeForCustomizeField(key, cf, (e) =>
      onChangeInputForCustomizeFields(
        key,
        team.customize_field ?? [],
        cf,
        e,
        state
      )
    )
  )
}

const formTypeForCustomizeField = (
  key: number,
  fieldCustomize: IFieldCustomize,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
): JSX.Element => {
  const { fieldName, type, isRequired, options, publicStatus } = fieldCustomize

  if (publicStatus === FieldCustomizePublicStatus.ONLY_ADMIN) return <></>

  switch (type) {
    case IFieldCustomizeType.TEXT:
    case IFieldCustomizeType.TEXTAREA:
    case IFieldCustomizeType.NUMBER:
    case IFieldCustomizeType.DATE:
      return (
        <FormGroup row className="mb-3" key={key}>
          <Col sm={12}>
            <RequiredText required={isRequired}>{fieldName}</RequiredText>
            <InputGroup className="input-group-alternative">
              <Input
                placeholder={fieldName}
                type={inputType(type)}
                onChange={onChange}
              />
            </InputGroup>
          </Col>
        </FormGroup>
      )
    case IFieldCustomizeType.BOOL:
      return (
        <FormGroup row check className="mb-3" key={key}>
          <Col sm={12}>
            <Label check>
              <Input onChange={onChange} type="checkbox" />
              {fieldName}
            </Label>
          </Col>
        </FormGroup>
      )
    case IFieldCustomizeType.RADIO:
      return (
        <FormGroup row check className="mb-3" key={key}>
          <Row>
            <Col sm={12}>
              <RequiredText required={isRequired}>{fieldName}</RequiredText>
            </Col>
          </Row>
          {options?.map((option, optionkey) => {
            const name = `enqueteRadio${key}`
            const idName = `enqueteRadio${key}-${optionkey}`
            return (
              <div
                className="custom-control custom-control-alternative custom-radio mb-3"
                key={optionkey}
              >
                <input
                  className="custom-control-input"
                  id={idName}
                  name={name}
                  type="radio"
                  value={option}
                  onChange={onChange}
                />
                <Label className="custom-control-label" for={idName}>
                  {option}
                </Label>
              </div>
            )
          })}
        </FormGroup>
      )
    case IFieldCustomizeType.PULLDOWN:
      return (
        <FormGroup row className="mb-3" key={key}>
          <Col sm={12}>
            <RequiredText required={isRequired}>{fieldName}</RequiredText>
            <Input type="select" onChange={onChange}>
              <option />
              {options?.map((option, key) => (
                <option key={key} value={option}>
                  {option}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
      )
  }
}

interface IToLoginFormProps {
  team: ITeam
  videoPurchaseLead: IVideoPurchaseLead | null
}

const ToLoginForm: React.FC<IToLoginFormProps> = (props) => {
  const { t } = useTranslation('join')
  return (
    <div className="text-center mb-3">
      <Link
        to={{
          pathname: makeLoginTeamPath(props.team.id),
          state: props.videoPurchaseLead,
        }}
      >
        {t('form.hasAccount')}
      </Link>
    </div>
  )
}

interface ITermsFormProps {
  team: ITeam
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

const TermsForm: React.FC<ITermsFormProps> = (props) => {
  const { t } = useTranslation('join')

  if (!props.team.urls.terms) return null

  return (
    <FormGroup check inline>
      <Label check>
        <Input type="checkbox" onChange={props.onChange} />
        <Trans
          t={t}
          i18nKey="form.agreeTerms"
          components={{
            termsLink: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={props.team.urls.terms}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      </Label>
    </FormGroup>
  )
}

export default FormBody
