import { ISubscriptionObject } from 'common/interfaces/subscription'
import { IFindTeam } from 'common/interfaces/team'
import { fetchSubscriptionObj } from 'repositories/functions/functions_stripe'
import { findByNonAuthUser } from 'repositories/functions/functions_team'

/**
 * ランディングページのチーム情報を取得する
 */
export const findTeam = async (
  teamId: string,
  setTeam: React.Dispatch<React.SetStateAction<IFindTeam | null>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setSubscriptionObj: React.Dispatch<
    React.SetStateAction<ISubscriptionObject | null>
  >
) => {
  if (teamId) {
    const team = await findByNonAuthUser(teamId)
    setTeam(team)

    if (team) {
      const subscriptionObj = await fetchSubscriptionObj(
        undefined,
        team.stripeId
      )
      setSubscriptionObj(subscriptionObj)
    }
  }
  setIsLoading(false)
}
