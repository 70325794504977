import { invite2FormInvite } from 'common/converter_invite'
import { Routes } from 'common/enums'
import { IInvite } from 'common/interfaces/invite'
import NormalButton from 'components/atoms/Button/Normal'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/UserInvite/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { Container, Row } from 'reactstrap'
import { initInvite } from 'services/admin/user_invite_form'

const UserInviteForm: React.FC = () => {
  const { t } = useTranslation('adminInvite')
  const history = useHistory()
  const location = useLocation<{ invite: IInvite }>()

  const isCreate = location.pathname === Routes.AdminUserInviteCreate
  const invite = isCreate
    ? initInvite()
    : invite2FormInvite(location.state.invite)

  return (
    <>
      <H1
        title={
          isCreate
            ? t('inviteForm.user.title.new')
            : t('inviteForm.user.title.edit')
        }
      />

      <Container fluid>
        <Row className="justify-content-end mb-3 mr-1 mr-md-0">
          <NormalButton
            onClick={() => history.push(Routes.AdminInvite)}
            content={t('inviteForm.backToInviteList')}
          />
          <NormalButton
            onClick={() => history.push(Routes.AdminUser)}
            content={t('inviteForm.backToList')}
          />
        </Row>
        <FormBody isCreate={isCreate} inviteObj={invite} />
      </Container>
    </>
  )
}

export default UserInviteForm
