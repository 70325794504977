import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import './genrecard.scss'

interface IGenreCard {
  className?: string
  name: string
  link: string
  image: string | null
  is16x9: boolean
}

const GenreCard: React.FC<IGenreCard> = ({
  className,
  name,
  link,
  image,
  is16x9,
}: IGenreCard) => {
  const imageElement = image ? (
    <img className="image" src={image} alt={name} />
  ) : (
    <div className="box" />
  )

  return (
    <div className={classNames('genre-card', className)}>
      <Link className="genre-link" to={link}>
        <div className="genre">
          <div className={classNames('thumbnail', { aspect16x9: is16x9 })}>
            {imageElement}
          </div>
          <h3 className="name">{name}</h3>
        </div>
      </Link>
    </div>
  )
}

export default GenreCard
