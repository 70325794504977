import { IStoreCache } from 'common/interfaces/auth_provider'
import { IFlattedLog, ILog } from 'common/interfaces/log'
import { IFromTo } from 'common/interfaces/time'
import { isLoggedIn } from 'common/utils'
import { flattedLog } from 'common/utils_log'
import dayjs from 'dayjs'
import { i18nAdminAnalytics } from 'i18n/i18n'
import React from 'react'
import { getAll } from 'repositories/store/firebase_log'

export enum DatePeriod {
  DAYS_7 = '7',
  DAYS_30 = '30',
  DAYS_90 = '90',
  EVERY_MONTH = 'EVERY_MONTH',
  SPECIFIED_PERIOD = 'SPECIFIED_PERIOD',
}

/**
 * 会員たちのログをタイムスタンプ順で取得
 * @param logs `ILog[]`
 * @returns `IFlattedLog[]`
 */
export const getSortedLogsFromLogs = (logs: ILog[]): IFlattedLog[] => {
  return logs
    .flatMap((l) => flattedLog(l))
    .sort((a, b) => a.timestamp.seconds - b.timestamp.seconds)
}

/**
 * タイムスタンプでソートされた `IFlattedLog[]` をセット
 * @param storeCache `IStoreCache`
 * @param setFlattedLogs state setter
 * @returns `void`
 */
export const getSortedUserLogs = async (
  storeCache: IStoreCache,
  setFlattedLogs: React.Dispatch<React.SetStateAction<IFlattedLog[]>>
) => {
  if (!isLoggedIn(storeCache)) return

  const allLogs = await getAll(storeCache.team!)
  const sortedLogs = getSortedLogsFromLogs(allLogs)
  setFlattedLogs(sortedLogs)
}

/**
 * `IFlattedLog[]` から月選択のOptionを生成
 * @param flattedLogs `IFlattedLog[]`
 * @param setMonthOptions month options setter
 * @param setSelectedMonth selected month setter
 * @returns `void`
 */
export const setMonthSelectOptions = (
  flattedLogs: IFlattedLog[],
  setMonthOptions: React.Dispatch<React.SetStateAction<JSX.Element[]>>,
  setSelectedMonth: React.Dispatch<React.SetStateAction<string>>
) => {
  if (flattedLogs.length <= 0) return

  const firstDate = dayjs
    .unix(flattedLogs[0].timestamp.seconds)
    .startOf('month')
  const lastDate = dayjs
    .unix(flattedLogs[flattedLogs.length - 1].timestamp.seconds)
    .startOf('month')

  const options = {} as any
  const monthCount = lastDate.diff(firstDate, 'month') + 1
  for (let i = 0; i < monthCount; i++) {
    const date = firstDate.add(i, 'month')
    const year = date.year()
    const month = date.month() + 1
    if (options[year] === undefined) {
      options[year] = []
    }
    options[year] = [...options[year], month]
  }

  const padMonth = (month: number) => month.toString().padStart(2, '0')
  const lastValue = (() => {
    const lastYear = lastDate.year()
    const lastMonth = lastDate.month() + 1
    return `${lastYear}-${padMonth(lastMonth)}-01`
  })()
  setSelectedMonth(lastValue)

  const result = Object.keys(options).map((year) => {
    const monthOptions = options[year].map((month: number) => {
      return React.createElement(
        'option',
        {
          key: `${year}-${padMonth(month)}-01`,
          value: `${year}-${padMonth(month)}-01`,
        },
        i18nAdminAnalytics('monthSelector.month', { month })
      )
    })
    return React.createElement(
      'optgroup',
      {
        key: year,
        label: i18nAdminAnalytics('monthSelector.year', { year }),
      },
      monthOptions
    )
  })
  setMonthOptions(result)
}

/**
 * `IFromTo`の初期化
 */
export const initFromTo = (): IFromTo => ({ from: '', to: '' })

/**
 * `DatePeriod`のタイプに応じて期間を算出
 * @param datePeriodType `DatePeriod`
 * @param setViewCounts state setter
 * @param target `{ date: string, fromTo: IFromTo }`
 * @returns `{ start: dayjs.Dayjs, end: dayjs.Dayjs } | null`
 */
export const getDatePeriodDuration = <T>(
  datePeriodType: DatePeriod,
  setViewCounts: React.Dispatch<React.SetStateAction<T[]>>,
  target: {
    date: string
    fromTo: IFromTo
  }
): {
  start: dayjs.Dayjs
  end: dayjs.Dayjs
} | null => {
  if (datePeriodType === DatePeriod.EVERY_MONTH) {
    if (target.date === '') {
      return null
    }
    return {
      start: dayjs(target.date).startOf('month'),
      end: dayjs(target.date).endOf('month'),
    }
  }
  if (datePeriodType === DatePeriod.SPECIFIED_PERIOD) {
    if (target.fromTo.from === '' || target.fromTo.to === '') {
      setViewCounts([])
      return null
    }
    return {
      start: dayjs(target.fromTo.from),
      end: dayjs(target.fromTo.to),
    }
  }

  const nowDateTime = dayjs()
  const dayCount = Number(datePeriodType)
  const startDateTime = nowDateTime.subtract(dayCount, 'day').format()
  return {
    start: dayjs(startDateTime),
    end: dayjs(nowDateTime),
  }
}
