import { IStoreCache } from 'common/interfaces/auth_provider'
import {
  HeaderItemOrderType,
  IHeaderItemOrder,
} from 'common/interfaces/header_item_order'
import { checkFirebaseError, isLoggedIn } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedTeam } from 'providers/AuthProvider'
import React from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { updateHeaderItemOrder } from 'repositories/store/firebase_team'
import { alertService } from 'services/alert'

/**
 * 選択項目から追加ボタンを押下した際
 */
export const onAddItem = (
  type: HeaderItemOrderType,
  itemOrderStates: {
    selectedItems: IHeaderItemOrder[]
    setSelectedItems: React.Dispatch<React.SetStateAction<IHeaderItemOrder[]>>
  }
) => {
  itemOrderStates.setSelectedItems([...itemOrderStates.selectedItems, { type }])
}

/**
 * 表示順のxボタンを押下した際
 */
export const onRemoveItem = (
  position: number,
  itemOrderStates: {
    selectedItems: IHeaderItemOrder[]
    setSelectedItems: React.Dispatch<React.SetStateAction<IHeaderItemOrder[]>>
  }
) => {
  itemOrderStates.selectedItems.splice(position, 1)
  itemOrderStates.setSelectedItems([...itemOrderStates.selectedItems])
}

/**
 * 表示順のドラッグが終了した際
 */
export const onDragEnd = (
  result: DropResult,
  itemOrderStates: {
    selectedItems: IHeaderItemOrder[]
    setSelectedItems: React.Dispatch<React.SetStateAction<IHeaderItemOrder[]>>
  }
) => {
  if (!result.destination) {
    return
  }

  const items = (() => {
    const data = Array.from(itemOrderStates.selectedItems)
    const [removed] = data.splice(result.source.index, 1)
    data.splice(result.destination.index, 0, removed)
    return data
  })()
  itemOrderStates.setSelectedItems(items)
}

/**
 * チームのヘッダーの表示順の更新処理
 */
export const updateTeamHeaderItemOrder = async (
  headerItemOrder: IHeaderItemOrder[],
  storeCache: IStoreCache
) => {
  if (!isLoggedIn(storeCache)) return

  try {
    await updateHeaderItemOrder(storeCache.team!, headerItemOrder)
    await reloadCachedTeam(storeCache)
    alertService.show(true, i18nAlert('updated.order'))
  } catch (error) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, i18nAlert('updated.fail.order'))
  }
}
