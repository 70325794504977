import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/SiteCustomizeHeaderItemOrder/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'

const SiteCustomizeHeaderItemOrder: React.FC = () => {
  const { t } = useTranslation('adminSiteCustomize')
  return (
    <>
      <H1 title={t('headerCustomize.title')} />

      <Container fluid>
        <FormBody />
      </Container>
    </>
  )
}

export default SiteCustomizeHeaderItemOrder
