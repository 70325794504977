import React from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDraggableProps,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import { Modal } from 'reactstrap'

import { IPlaylist } from 'common/interfaces/playlist'
import { onDragEnd as serviceOnDragEnd } from 'services/admin/playlist_customize'
import { SelectedCard } from '../PlaylistCustomize/ElementsOnPlaylistCusyomize'
import '../order-customize-style.scss'

interface IVideoOrderModal {
  isOpen: boolean
  toggleModal: () => void
  playlist: IPlaylist
  setPlaylist: React.Dispatch<React.SetStateAction<IPlaylist>>
}

const VideoOrderModal: React.FC<IVideoOrderModal> = (props) => {
  const onDragEnd = (result: DropResult) => {
    serviceOnDragEnd(result, props.playlist, props.setPlaylist)
  }

  const fixDraggingStyle = (
    style: DraggableProvidedDraggableProps['style']
  ): DraggableProvidedDraggableProps['style'] => {
    if (!style?.transform) return style

    const match = /translate\(([0-9.-]+)px,\s*([0-9.-]+)px\)/.exec(
      style.transform
    )!
    const x = Number(match[1])
    const y = Number(match[2]) - 30
    const transform = `translate(${x}px, ${y}px)`

    return { ...style, transform }
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggleModal} centered>
      <div className="order-customize-style">
        <div className="selected-items px-2">
          <div className="scroll-box">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {props.playlist.video_ids.map((videoId, index) => (
                      <Draggable
                        key={videoId}
                        draggableId={videoId}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...fixDraggingStyle(
                                provided.draggableProps.style
                              ),
                              left: 'auto !important',
                            }}
                          >
                            <SelectedCard index={index} videoId={videoId} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default VideoOrderModal
