import {
  AuthMethodType,
  EmailAction,
  IMailTemplateEventName,
  Routes,
} from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IInvitePendingUser } from 'common/interfaces/invite'
import { IUser } from 'common/interfaces/user'
import { makeLoginTeamUrl } from 'common/link_url'
import { checkFirebaseError, isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedUser } from 'providers/AuthProvider'
import { removeUser } from 'repositories/functions/functions_auth'
import { send } from 'repositories/functions/functions_mail'
import { consumeCount, findById } from 'repositories/store/firebase_invite'
import { approve } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 招待されて登録した会員を承認する
 */
export const approveUser = async (
  storeCache: IStoreCache,
  user: IUser,
  invitedId: string
): Promise<void> => {
  if (!isLoggedIn(storeCache, true)) return

  try {
    const team = storeCache.team!
    const invite = await findById(team, invitedId)
    if (!invite) throw new Error(i18nAlert('invite.cantFind.code'))
    if (invite.count < 0) {
      throw new Error(i18nAlert('invite.reached.limitInviteUsers'))
    }

    const mailTemplate = team.mail_templates.find(
      (mt) => mt.event_name === IMailTemplateEventName.APPROVAL
    )

    await approve(team, user.id)
    await consumeCount(team, invitedId)

    // 認証がカスタム以外・イベントメール有効の場合メール送信
    if (team.auth_method !== AuthMethodType.CUSTOM && mailTemplate?.status) {
      const loginUrl = makeLoginTeamUrl(team.id)
      const mailContent = mailTemplate.email_content
        .replace('{{user_name}}', user.name || user.email)
        .replace('{{site_name}}', team.name || '')
        .replace('{{login_url}}', `<a href="${loginUrl}">${loginUrl}</a>`)
      await send(
        team.id,
        EmailAction.SENT,
        IMailTemplateEventName.APPROVAL,
        undefined,
        null,
        user.email,
        { name: team.name, email: team.email! },
        mailTemplate.email_title,
        mailContent,
        mailContent
      )
    }
    await reloadCachedUser(storeCache)

    alertService.show(true, i18nAlert('approved'))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * 承認待ちの会員を削除する
 */
export const removePendingUser = async (
  storeCache: IStoreCache,
  history: {
    history: History
    invitedId: string
  },
  removeUserId: string,
  isPendingAllUsersPage = false
) => {
  if (!isLoggedIn(storeCache, true)) return

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      await removeUser(
        storeCache.team!.id,
        storeCache.user!.id,
        removeUserId,
        storeCache.team!.stripe.account_id
      )

      alertService.show(true, i18nAlert('deleted.message'))
      if (isPendingAllUsersPage) {
        history.history.push(Routes.AdminApprovalPendingAllUsers)
        return
      }
      history.history.replace(Routes.AdminApprovalPendingUser, {
        id: history.invitedId,
      })
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('cantDeleted'))
    }
  })
}

/**
 * Tableで選択した行の未承認ユーザーを承認する
 */
export const onUpdateInvitePendingUsers = async (
  storeCache: IStoreCache,
  selectedInvitePendingUsers: IInvitePendingUser[],
  closeModal: () => void
): Promise<void> => {
  try {
    if (selectedInvitePendingUsers.length === 0) {
      throw new Error(i18nAlert('select'))
    }
    const tasks = selectedInvitePendingUsers.map(async (su) => {
      await approveUser(storeCache, su, su.invited_id!)
    })
    await Promise.all(tasks)

    closeModal()
  } catch (error: any) {
    alertService.show(false, checkFirebaseError(error))
    console.log(error)
  }
}
