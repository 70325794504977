import Loading from 'assets/images/button-loading.svg'
import React from 'react'

interface IButtonLoadingProps {
  className?: string
}

const ButtonLoading: React.FC<IButtonLoadingProps> = ({
  className,
}: IButtonLoadingProps) => {
  return (
    <img
      className={className}
      src={Loading}
      alt="button_loading"
      width="45"
      height="auto"
    />
  )
}

export default ButtonLoading
