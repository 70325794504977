import { IEnquete } from 'common/interfaces/enquete'
import { IEnqueteAnswer } from 'common/interfaces/enquete_answer'
import { ITeam } from 'common/interfaces/team'
import { Teams } from './firebase_team'

const EnqueteAnswers = (team: ITeam, enqueteId: string) =>
  Teams.doc(team.id).collection('enquetes').doc(enqueteId).collection('answers')

/**
 * get all Answers of Enquete
 */
export const get = async (
  team: ITeam,
  enqueteId: string
): Promise<IEnqueteAnswer[]> => {
  try {
    const answerDatas = await EnqueteAnswers(team, enqueteId).get()
    return answerDatas.docs.map((answer) => answer.data() as IEnqueteAnswer)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get Answer by user_id and video_id
 */
export const findByUserIdAndVideoId = async (
  team: ITeam,
  enqueteId: string,
  userId: string,
  videoId: string
): Promise<IEnqueteAnswer | null> => {
  try {
    const answerDatas = await EnqueteAnswers(team, enqueteId)
      .where('user_id', '==', userId)
      .where('video_id', '==', videoId)
      .get()
    return answerDatas.empty
      ? null
      : (answerDatas.docs[0].data() as IEnqueteAnswer)
  } catch (error) {
    console.log(error)
  }
  return null
}

/**
 * get Answer by user_id and not video_id
 */
export const findByUserId = async (
  team: ITeam,
  enqueteId: string,
  userId: string
): Promise<IEnqueteAnswer | null> => {
  try {
    const answerDatas = await EnqueteAnswers(team, enqueteId)
      .where('user_id', '==', userId)
      .where('video_id', '==', '')
      .get()
    return answerDatas.empty
      ? null
      : (answerDatas.docs[0].data() as IEnqueteAnswer)
  } catch (error) {
    console.log(error)
  }
  return null
}

/**
 * add Enquete's Answer
 * @throws Firebase error
 */
export const store = async (
  team: ITeam,
  enqueteId: string,
  enqueteAnswer: IEnqueteAnswer
): Promise<void> => {
  await EnqueteAnswers(team, enqueteId).doc(enqueteAnswer.id).set(enqueteAnswer)
}

/**
 * delete Answer of Enquete
 * @throws Firebase error
 */
export const remove = async (
  team: ITeam,
  enqueteId: string,
  enqueteAnswer: IEnqueteAnswer
): Promise<void> => {
  await EnqueteAnswers(team, enqueteId).doc(enqueteAnswer.id).delete()
}

/**
 * delete all Answers of Enquete
 * @throws Firebase error
 */
export const removeAllAnswersInEnquete = async (
  team: ITeam,
  enquete: IEnquete
): Promise<void> => {
  const answers = await get(team, enquete.id)
  const removeTask = answers.map((answer) => remove(team, enquete.id, answer))
  await Promise.all(removeTask)
}
