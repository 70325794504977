import {
  FieldCustomizePublicStatus,
  IFieldCustomize,
  IFieldCustomizeType,
} from 'common/interfaces/field_customize'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  ChangeAddCardType,
  onChangeCustomizeField as serviceOnChangeCustomizeField,
} from 'services/admin/field_customize'

/**
 * 表示順で使用する各カードの生成
 */
export const createItemCard = (
  index: number,
  customizeFieldStates: {
    customizeFields: IFieldCustomize[]
    setCustomizeFields: React.Dispatch<React.SetStateAction<IFieldCustomize[]>>
  },
  onRemove: (index: number) => void
): JSX.Element => {
  const item = customizeFieldStates.customizeFields[index]
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    changeType: ChangeAddCardType
  ) => serviceOnChangeCustomizeField(index, customizeFieldStates, e, changeType)

  return (
    <SelectedCard
      index={index}
      fieldCustomize={item}
      onChange={onChange}
      onRemove={() => onRemove(index)}
    />
  )
}

interface ISelectedCardProps {
  index: number
  fieldCustomize: IFieldCustomize
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    changeType: ChangeAddCardType
  ) => void
  onRemove: () => void
}

/**
 * 表示順で使用するカードの生成
 */
const SelectedCard: React.FC<ISelectedCardProps> = (props): JSX.Element => {
  const { t } = useTranslation('adminCustomizeFields')
  return (
    <div className="placed-card">
      <TitleRow
        index={props.index}
        type={props.fieldCustomize.type}
        onRemove={props.onRemove}
      />
      <Input
        value={props.fieldCustomize.fieldName}
        placeholder={t('list.card.fieldPlaceholder')}
        type="text"
        onChange={(e) => props.onChange(e, ChangeAddCardType.TITLE)}
      />
      {props.fieldCustomize.options && props.fieldCustomize.options.length > 0 && (
        <div className="mt-3 ml-3">
          {t('list.card.optionsList')}
          {props.fieldCustomize.options.map((option, key) => (
            <div key={key} className="mt-2">{`${key + 1}. ${option}`}</div>
          ))}
        </div>
      )}
      <div className="mt-3">
        {[
          [FieldCustomizePublicStatus.PUBLIC, t('list.card.public')],
          [FieldCustomizePublicStatus.ONLY_ADMIN, t('list.card.onlyAdmin')],
        ].map(([status, text]) => (
          <FormGroup key={status} check className="mt-2">
            <Label check>
              <Input
                onChange={(e) =>
                  props.onChange(e, ChangeAddCardType.PUBLIC_STATUS)
                }
                type="radio"
                value={status}
                checked={props.fieldCustomize.publicStatus === status}
              />
              {text}
            </Label>
          </FormGroup>
        ))}
      </div>
      <div className="mt-2">
        <h6>{t('list.card.required')}</h6>
        <label className="custom-toggle">
          <input
            type="checkbox"
            onChange={(e) => props.onChange(e, ChangeAddCardType.REQUIRED)}
            checked={props.fieldCustomize.isRequired}
            disabled={
              props.fieldCustomize.publicStatus ===
              FieldCustomizePublicStatus.ONLY_ADMIN
            }
          />
          <span className="custom-toggle-slider rounded-circle" />
        </label>
      </div>
    </div>
  )
}

interface ITitleRowProps {
  index: number
  type: IFieldCustomizeType
  onRemove: () => void
}

const TitleRow: React.FC<ITitleRowProps> = (props) => {
  const i18n = useTranslation('adminCustomizeFields').t('list.card.typeTitle', {
    returnObjects: true,
    index: props.index + 1,
  })
  const typeTitle = (type: IFieldCustomizeType) => {
    switch (type) {
      case IFieldCustomizeType.TEXT:
        return i18n.text
      case IFieldCustomizeType.TEXTAREA:
        return i18n.textArea
      case IFieldCustomizeType.NUMBER:
        return i18n.number
      case IFieldCustomizeType.DATE:
        return i18n.date
      case IFieldCustomizeType.BOOL:
        return i18n.bool
      case IFieldCustomizeType.RADIO:
        return i18n.radio
      case IFieldCustomizeType.PULLDOWN:
        return i18n.pulldown
    }
  }

  return (
    <Row>
      <Col xs="10">{typeTitle(props.type)}</Col>
      <Col xs="2" className="text-right">
        <i
          className="fas fa-times"
          style={{ cursor: 'default' }}
          onClick={props.onRemove}
        />
      </Col>
    </Row>
  )
}
