import { IStoreCache } from 'common/interfaces/auth_provider'
import { IVideo } from 'common/interfaces/video'
import {
  MultiVideoUpdateType,
  ValidationModal,
} from 'common/interfaces/video_multi_update'
import { checkFirebaseError } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedVideos } from 'providers/AuthProvider'
import React from 'react'
import { update } from 'repositories/store/firebase_video'
import { alertService } from 'services/alert'
import {
  validateForm,
  validateMultiVideoUpdate,
} from 'services/validation/video'

/**
 * Tableで選択した行の更新処理を実施する
 */
export const onUpdateVideos = async (
  storeCache: IStoreCache,
  selectedVideos: IVideo[],
  closeModal: () => void,
  type: MultiVideoUpdateType,
  newVideo: IVideo
): Promise<void> => {
  try {
    if (
      selectedVideos.length === 0 ||
      (type === MultiVideoUpdateType.ADD_TAGS &&
        newVideo.tag_ids.length === 0) ||
      (type === MultiVideoUpdateType.CHANGE_CATEGORY &&
        newVideo.category_id === '')
    ) {
      throw new Error(i18nAlert('select'))
    }

    validateForm(newVideo, true)

    const tasks = selectedVideos.map(async (video) => {
      if (type === MultiVideoUpdateType.ADD_TAGS) {
        const addTagIds = newVideo.tag_ids
        const uniqueTagIds = Array.from(
          new Set([...video.tag_ids, ...addTagIds])
        )
        video.tag_ids = uniqueTagIds
      } else if (type === MultiVideoUpdateType.CHANGE_CATEGORY) {
        video.category_id = newVideo.category_id
      } else if (type === MultiVideoUpdateType.CHANGE_VIDEO_STATUS) {
        video.status = newVideo.status
        video.publication_period = newVideo.publication_period
        video.released_at = newVideo.released_at
      } else if (type === MultiVideoUpdateType.ADD_PUBLISH_GROUP) {
        const addGroupIds = newVideo.group_ids
        const uniqueGroupIds = Array.from(
          new Set([...video.group_ids, ...addGroupIds])
        )
        video.group_ids = uniqueGroupIds
      } else if (type === MultiVideoUpdateType.CHANGE_CREATOR) {
        video.creator_id = newVideo.creator_id
      }
      validateForm(video, true)
      await update(storeCache.team!, video)
    })
    await Promise.all(tasks)

    await reloadCachedVideos(storeCache)
    closeModal()
  } catch (error: any) {
    alertService.show(false, checkFirebaseError(error))
    console.log(error)
  }
}

/**
 * 複数選択モーダルの表示・非表示を切り替える
 */
export const toggleVideoMultiSelectModal = (
  selectedVideos: IVideo[],
  videoMultiSelectModal: boolean,
  setVideoMultiSelectModal: React.Dispatch<React.SetStateAction<boolean>>,
  setValidateModal: React.Dispatch<React.SetStateAction<ValidationModal>>,
  setMultiVideoUpdateType: React.Dispatch<
    React.SetStateAction<MultiVideoUpdateType>
  >,
  type?: MultiVideoUpdateType
) => {
  if (type) {
    setMultiVideoUpdateType(type)
    const validation = validateMultiVideoUpdate(type, selectedVideos)
    if (validation) {
      setValidateModal(validation)
      return
    }
  }
  setVideoMultiSelectModal(!videoMultiSelectModal)
}
