import { LivePlatform } from 'common/interfaces/live'

/**
 * Get vimeo iframe `src` url
 */
export const getVimeoIframeSource = (
  platform: LivePlatform,
  vimeoId: string
) => {
  switch (platform) {
    case LivePlatform.VIMEO_VIDEO:
      return `https://player.vimeo.com/video/${vimeoId}`
    case LivePlatform.VIMEO_LIVE:
      return `https://vimeo.com/event/${vimeoId}/embed`
    case LivePlatform.VIMEO_WEBINAR:
      return `https://vimeo.com/webinars/events/${vimeoId}/embed`
  }
  throw new Error('Invalid platform')
}
