import { ColumnDef } from '@tanstack/react-table'
import { CurrencyType, Routes } from 'common/enums'
import { findProductsNonNull } from 'common/find_store_cache'
import { ICoupon } from 'common/interfaces/stripe/coupon'
import { IProduct } from 'common/interfaces/stripe/product'
import { formatUNIXToDate } from 'common/times'
import { formatCouponInterval, formatPrice } from 'common/utils'
import NormalButton from 'components/atoms/Button/Normal'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeCustomOperationButtons,
  makePrimaryBadges,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Row } from 'reactstrap'
import { delCoupon, getStripeCoupons } from 'services/admin/stripe/coupon'
import { getStripeProducts } from 'services/admin/stripe/product'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminStripeCoupon')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [coupons, setCoupons] = useState<ICoupon[]>([])
  const [products, setProducts] = useState<IProduct[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await Promise.all([
        getStripeCoupons(storeCache, setCoupons),
        getStripeProducts(storeCache, setProducts),
      ])
      setIsLoading(false)
    })()
  }, [storeCache])

  if (isLoading) return <LoadingSpinner />

  const disabled = !storeCache.team!.stripe.account_id
  if (coupons.length <= 0) {
    return <RegisterItemButton type="stripe_coupon" disabled={disabled} />
  }
  return (
    <>
      <Row className="justify-content-end mb-3 mr-1 mr-md-0">
        <NormalButton
          onClick={() => history.push(Routes.AdminStripeCouponCreate)}
          content={t('list.new')}
          disabled={disabled}
        />
      </Row>
      <Table coupons={coupons} products={products} setCoupons={setCoupons} />
    </>
  )
}

interface ITableProps {
  coupons: ICoupon[]
  products: IProduct[]
  setCoupons: React.Dispatch<React.SetStateAction<ICoupon[]>>
}

const Table: React.FC<ITableProps> = (props) => {
  const { t } = useTranslation('adminStripeCoupon')
  const { storeCache } = useContext(AuthContext)
  const columns = useMemo<ColumnDef<ICoupon>[]>(
    () => [
      { header: t('list.table.id'), accessorKey: 'id' },
      { header: t('list.table.name'), accessorKey: 'name' },
      {
        header: t('list.table.type.title'),
        accessorFn: (c) =>
          c.percent_off
            ? t('list.table.type.percent')
            : t('list.table.type.amount'),
      },
      {
        header: t('list.table.percentOff'),
        accessorFn: (c) => (c.percent_off ? `${c.percent_off}%` : ''),
      },
      {
        header: t('list.table.amountOff'),
        accessorFn: (c) =>
          c.amount_off
            ? formatPrice(c.amount_off, c.currency ?? CurrencyType.JPY)
            : '',
      },
      {
        header: t('list.table.duration'),
        accessorFn: (c) => formatCouponInterval(c.duration),
      },
      {
        header: t('list.table.redeemBy'),
        accessorFn: (c) =>
          c.redeem_by
            ? formatUNIXToDate(c.redeem_by)
            : t('list.table.noTimeLimit'),
      },
      {
        header: t('list.table.created'),
        accessorFn: (c) => formatUNIXToDate(c.created),
      },
      {
        header: t('list.table.products'),
        accessorFn: (c) =>
          makePrimaryBadges(
            findProductsNonNull(
              props.products,
              c.applies_to?.products ?? []
            ).map((p) => p.name)
          ),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (c) =>
          makeCustomOperationButtons(
            makeCustomOperationButton(t('list.delete'), () =>
              delCoupon(storeCache, c.id, props.setCoupons)
            )
          ),
      },
    ],
    [t, storeCache, props.products, props.setCoupons]
  )

  return useTanstackTable<ICoupon>(columns, props.coupons, {
    fixedLastColumn: true,
  })
}

export default TableBody
