import { AuthMethodType } from 'common/enums'
import { ICustomizeFields, IUser } from 'common/interfaces/user'
import { datetimeLocalFormat } from 'common/times'
import CustomizeFieldForm from 'components/atoms/CustomizeFields/CustomizeFieldForm'
import TextInput from 'components/atoms/Form/TextInput'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import Select from 'react-select'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  InputType,
  getFormOptions,
  getUserDefaults,
  removeUser,
  onChangeInput as serviceOnChangeInput,
  toggleUserDisabled,
  updateUser,
} from 'services/admin/user_edit'
import { onChangeInputForCustomizeFields } from 'services/join'

const FormBody: React.FC = () => {
  const { t } = useTranslation('adminUser')
  const { storeCache } = useContext(AuthContext)
  const user = useLocation<IUser>().state
  const history = useHistory()

  const [userEdit, setUserEdit] = useState<IUser>({ ...user })
  const [customizeField, setCustomizeField] = useState<
    ICustomizeFields[] | null | undefined
  >(userEdit.customize_fields)

  const { defaultGroups } = getUserDefaults(storeCache, user)
  const { groupOptions } = getFormOptions(storeCache)

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement> | any
  ) => serviceOnChangeInput(userEdit, setUserEdit, type, e)

  const changeUserDisabled = () =>
    toggleUserDisabled(history, userEdit, storeCache, customizeField)

  const remove = () => removeUser(history, userEdit, storeCache)

  const update = () => updateUser(history, userEdit, storeCache, customizeField)

  return (
    <Form>
      <TextInput
        id="emailForm"
        label={t('edit.form.email')}
        value={userEdit.email}
        col={10}
        readOnly
      />
      <TextInput
        id="nameForm"
        label={t('edit.form.name')}
        value={userEdit.name}
        col={10}
        onChange={(e) => onChangeInput(InputType.NAME, e)}
      />
      <TextInput
        id="phoneForm"
        label={t('edit.form.phone')}
        value={userEdit.phone}
        col={10}
        onChange={(e) => onChangeInput(InputType.PHONE, e)}
      />
      <FormGroup row>
        <Col md="10">
          <Label for="groupNameForm">{t('edit.form.groupName')}</Label>
          <Select
            id="groupNameForm"
            defaultValue={defaultGroups}
            onChange={(e) => onChangeInput(InputType.GROUP_IDS, e)}
            options={groupOptions}
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
      </FormGroup>
      {storeCache.team?.customize_field &&
        storeCache.team.customize_field.map((customizeFieldObj, key) => (
          <CustomizeFieldForm
            key={key}
            colNum={10}
            customizeField={customizeField}
            customizeFieldObj={customizeFieldObj}
            userCustomizeField={user.customize_fields}
            onChange={(e) =>
              onChangeInputForCustomizeFields(
                key,
                storeCache.team!.customize_field!,
                customizeFieldObj,
                e,
                { customizeField, setCustomizeField }
              )
            }
          />
        ))}
      <FormGroup row>
        <Col md="5">
          <Label>{t('edit.form.expiration')}</Label>
          <Input
            defaultValue={
              userEdit.expire.seconds === 0
                ? undefined
                : datetimeLocalFormat(userEdit.expire)
            }
            onChange={(e) => onChangeInput(InputType.EXPIRE, e)}
            type="datetime-local"
          />
        </Col>
      </FormGroup>
      {storeCache.team!.auth_method === AuthMethodType.CUSTOM && (
        <FormGroup>
          <Label for="isPasswordChangeable">
            {t('edit.form.is_password_changeable')}
          </Label>
          <Row>
            <Col md="9">
              <label className="custom-toggle" id="isPasswordChangeable">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    onChangeInput(InputType.IS_PASSWORD_CHANGEABLE, e)
                  }
                  checked={userEdit.is_password_changeable}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
          </Row>
        </FormGroup>
      )}
      <h3>{t('edit.form.notification')}</h3>
      <FormGroup row>
        <Col xs="6" md="4" lg="3">
          <Label for="checkForm">{t('edit.form.news')}</Label>
        </Col>
        <Col>
          <label className="custom-toggle">
            <input
              type="checkbox"
              defaultChecked={user.notification_setting.news}
              disabled
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </Col>
      </FormGroup>
      <Row className="mt-2">
        <Col md="10">
          <FormGroup>
            <Button color="warning" onClick={changeUserDisabled}>
              {userEdit.disabled
                ? t('edit.form.activateUser')
                : t('edit.form.disabledUser')}
            </Button>
          </FormGroup>
          <FormGroup>
            <Button color="danger" onClick={remove}>
              {t('edit.form.deleteUser')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={update} className="my-4" color="primary" type="button">
          {t('edit.form.save')}
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
