import { IAdministratorsNotifyType } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import {
  IEnqueteAnswer,
  IEnqueteAnswerField,
} from 'common/interfaces/enquete_answer'
import { IEnqueteCustomize } from 'common/interfaces/enquete_customize'
import { IVideo } from 'common/interfaces/video'
import { makeUserRootPath } from 'common/link_path'
import { makeVideoUrl } from 'common/link_url'
import { nowTimestamp } from 'common/times'
import { i18nAlert } from 'i18n/i18n'
import { sendToAdministrators } from 'repositories/functions/functions_mail'
import {
  findByUserIdAndVideoId,
  store,
} from 'repositories/store/firebase_enquete_answer'
import { addEnqueteAnsweredLog } from 'repositories/store/firebase_log'
import { alertService } from 'services/alert'
import {
  validateForm,
  validateFormForUser,
} from 'services/validation/enquete_answer'
import { v4 as uuidv4 } from 'uuid'

/**
 * 当該アンケートでユーザ・動画のIDで絞って回答取得処理
 */
export const getEnqueteAnswerByUserIdAndVideoId = async (
  storeCache: IStoreCache,
  enqueteId: string,
  userId: string,
  videoId: string,
  setEnqueteAnswer: React.Dispatch<React.SetStateAction<IEnqueteAnswer | null>>
): Promise<void> =>
  setEnqueteAnswer(
    await findByUserIdAndVideoId(storeCache.team!, enqueteId, userId, videoId)
  )

/**
 * アンケートの回答保存処理
 */
export const saveEnqueteAnswer = async (
  { team, user }: IStoreCache,
  { id, enquete, name }: IVideo,
  answers: IEnqueteAnswerField[],
  enqueteQuestions: IEnqueteCustomize[] | null | undefined,
  setAnswer: React.Dispatch<React.SetStateAction<IEnqueteAnswer | null>>,
  setEnqueteModal: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  try {
    validateForm(enquete, answers, enqueteQuestions!)

    const answer: IEnqueteAnswer = {
      id: uuidv4(),
      user_id: user!.id,
      video_id: id,
      answers,
      created_at: nowTimestamp(),
    }
    await store(team!, enquete!.enquete_id, answer)
    await addEnqueteAnsweredLog(team!, user!, id, enquete!.enquete_id)

    await sendToAdministrators(
      team!.id,
      IAdministratorsNotifyType.VideoEnqueteAnswered,
      '',
      '',
      '',
      '',
      name,
      makeVideoUrl(id, team!.id)
    )

    setAnswer(answer)
    setEnqueteModal(false)

    alertService.show(true, i18nAlert('answered.enquete'))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * アンケートへの回答登録処理（会員アカウント登録後）
 */
export const saveEnqueteAnswerForUser = async (
  { team, user }: IStoreCache,
  answers: IEnqueteAnswerField[],
  enqueteQuestions: IEnqueteCustomize[] | null
): Promise<void> => {
  try {
    const { id, enquete_id } = team!

    validateFormForUser(enquete_id, answers, enqueteQuestions!)

    const answer: IEnqueteAnswer = {
      id: uuidv4(),
      user_id: user!.id,
      video_id: '',
      answers,
      created_at: nowTimestamp(),
    }
    await store(team!, enquete_id, answer)
    await addEnqueteAnsweredLog(team!, user!, '', enquete_id)

    alertService.show(true, i18nAlert('answered.enquete'))
    window.location.href = makeUserRootPath(id)
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
