import { IStoreCache } from 'common/interfaces/auth_provider'
import { IStripe as ITeamStripe } from 'common/interfaces/team'
import { isLoggedIn } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedTeam } from 'providers/AuthProvider'
import { updateStripe } from 'repositories/store/firebase_team'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/stripe'

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  ACCOUNT_ID,
  PUBLIC_KEY,
  IS_PRODUCTS_ACTIVATE,
  ALLOW_INVOICE_PAYMENT,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  teamStripe: ITeamStripe,
  setTeamStripe: React.Dispatch<React.SetStateAction<ITeamStripe>>,
  type: InputType,
  e: any
) => {
  const val = e.target?.value
  const { checked } = e.target
  setTeamStripe({
    ...teamStripe,
    account_id: type === InputType.ACCOUNT_ID ? val : teamStripe.account_id,
    is_products_activate:
      type === InputType.IS_PRODUCTS_ACTIVATE
        ? checked
        : teamStripe.is_products_activate,
    allow_invoice_payment:
      type === InputType.ALLOW_INVOICE_PAYMENT
        ? checked
        : teamStripe.allow_invoice_payment,
    subscription_product_id: teamStripe.subscription_product_id,
  })
}

/**
 * Stripeの設定保存処理
 */
export const saveStripe = async (
  teamStripe: ITeamStripe,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    validateForm(teamStripe, storeCache)

    await updateStripe(storeCache.team!, teamStripe)
    await reloadCachedTeam(storeCache)

    alertService.show(true, i18nAlert('updated.stripe.info'))
  } catch (error: any) {
    console.error(error)
    alertService.show(false, error.message)
  }
}
