import { IEmailLog } from 'common/interfaces/email_logs'
import { ITeam } from 'common/interfaces/team'
import { Teams } from './firebase_team'

const EmailLogs = (team: ITeam) => Teams.doc(team.id).collection('email_logs')

/**
 * get email logs by foreign_id
 */
export const getByForeignId = async (
  team: ITeam,
  foreign_id: string
): Promise<IEmailLog[]> => {
  const emailLogDatas = await EmailLogs(team)
    .where('foreign_id', '==', foreign_id)
    .get()
  return emailLogDatas.docs.map((e) => e.data() as IEmailLog)
}
