import { storage } from 'common/firebase'
import { ITeam } from 'common/interfaces/team'
import { storageMetadata } from 'common/utils'

export const TeamsDirRef = storage.ref().child('teams')

const DirRef = (team: ITeam) => TeamsDirRef.child(team.id)

export enum FileType {
  LOGO = 'logo',
  TOP_IMAGES = 'top_images',
  LANDING_IMAGES = 'landing_images',
}

/**
 * upload team image file
 * @throws Firebase error
 */
export const upload = async (
  team: ITeam,
  file: File | undefined,
  fileId: string,
  type: FileType
): Promise<string> => {
  if (file) {
    const uploadTask = await DirRef(team)
      .child(type)
      .child(fileId)
      .put(file, storageMetadata())
    return uploadTask.ref.getDownloadURL()
  }
  return ''
}

/**
 * delete team image file
 * @throws Firebase error
 */
export const removeImageFile = async (
  team: ITeam,
  fileId: string,
  type: FileType
): Promise<void> => {
  await DirRef(team).child(type).child(fileId).delete()
}
