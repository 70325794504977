import {
  Cell,
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  Header,
  RowSelectionState,
  SortingState,
  Table,
  Row as TableRow,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import classNames from 'classnames'
import { ICategory } from 'common/interfaces/category'
import { ContentsOrderType } from 'common/interfaces/contents_orders'
import { ICreator } from 'common/interfaces/creator'
import { IFieldCustomizeType } from 'common/interfaces/field_customize'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { sortByContentsOrderType } from 'common/sort_contents'
import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Label, Row } from 'reactstrap'
import { createCsvBlobFromColumnDef } from 'services/admin/csv_create'
import './createtable.scss'

/**
 * inputで使用するカテゴリのoptionを生成する
 */
export const createCategoryJSXOptions = (
  categories: ICategory[]
): JSX.Element[] => {
  const result = [<option key={0} />]
  sortByContentsOrderType(categories, ContentsOrderType.NEWER).map(
    (category, key) =>
      result.push(
        <option key={key + 1} value={category.id}>
          {category.name}
        </option>
      )
  )
  return result
}

/**
 * inputで使用する投稿者のoptionを生成する
 */
export const createCreatorJSXOptions = (creators: ICreator[]): JSX.Element[] =>
  createCategoryJSXOptions(creators as any[] as ICategory[])

/**
 * カスタム項目のカラムを生成する
 */
export const customFieldColumnDef = <T extends { user_id: string } | IUser>(
  team: ITeam | null,
  allUsers: IUser[] | null
): ColumnDef<T>[] => {
  if (!team || !team.customize_field) return []

  return team.customize_field.map((cf, index) => {
    const id = `custom_field_${index}_${cf.fieldName}`
    const header = cf.fieldName
    const accessorFn = (row: T) => {
      const userId = 'user_id' in row ? row.user_id : row.id
      const user = allUsers?.find((u) => u.id === userId)
      if (!user || !user.customize_fields) return ''

      const customField = user.customize_fields.find(
        (c) => Object.keys(c)[0] === cf.fieldName
      )
      const fieldValue = customField?.[cf.fieldName] ?? ''
      return cf.type === IFieldCustomizeType.BOOL
        ? fieldValue
          ? 'Yes'
          : 'No'
        : fieldValue
    }
    return { id, header, accessorFn }
  })
}

interface TanstackTableOptions {
  withSearch?: boolean
  fixedLastColumn?: boolean
  tableHeightSmall?: boolean
}

interface TanstackTableSelectionOptions<T> {
  enableMultiRowSelection?: boolean
  enableAllRowSelection?: boolean
  onChangeSelectedIndexes?: (e: number[]) => void
  onChangeSelectedRows?: (e: T[]) => void
}

export const useTanstackTableWithCsvExport = <T,>(
  columns: ColumnDef<T>[],
  data: T[],
  csvName: string,
  options: TanstackTableOptions &
    TanstackTableSelectionOptions<T> & { hideTable?: boolean } = {},
  optionalElement?: JSX.Element
): JSX.Element => {
  const { t } = useTranslation('adminCommon')
  const table = useInitTanstackTable(columns, data, options)
  const csvBlob = createCsvBlobFromColumnDef(
    columns,
    table.getPreExpandedRowModel().rows
  )
  return (
    <>
      <Row className="justify-content-end mb-3 mr-1 mr-md-0">
        <a href={csvBlob} download={csvName}>
          <Button color="dark" size="sm">
            {t('createTable.outputCSV')}
          </Button>
        </a>
        {optionalElement}
      </Row>
      {!options?.hideTable && (
        <TanstackTableElement table={table} options={options} />
      )}
    </>
  )
}

export const useTanstackTable = <T,>(
  columns: ColumnDef<T>[],
  data: T[],
  options: TanstackTableOptions & TanstackTableSelectionOptions<T> = {}
): JSX.Element => {
  const table = useInitTanstackTable(columns, data, options)
  return <TanstackTableElement table={table} options={options} />
}

const useInitTanstackTable = <T,>(
  columns: ColumnDef<T>[],
  data: T[],
  options: TanstackTableSelectionOptions<T>
) => {
  columns.forEach((c) => {
    if (c.id === undefined) {
      c.id = c.header as string
    }
    if (c.enableSorting === undefined) {
      c.enableSorting = false
    }
    if (c.enableColumnFilter === undefined) {
      c.enableColumnFilter = false
    }
    if (c.cell === undefined) {
      c.cell = (props) => props.getValue()
    }
  })

  const columnVisibility = columns.reduce((acc, c) => {
    acc[c.header as string] = !(c.meta && c.meta.hidden)
    return acc
  }, {} as Record<string, boolean>)

  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState<string>('')
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const { onChangeSelectedIndexes, onChangeSelectedRows } = options ?? {}
  useEffect(() => {
    if (!onChangeSelectedIndexes && !onChangeSelectedRows) {
      return
    }
    const keys = Object.keys(rowSelection)
    const selectedIndexes = keys.flatMap((k) =>
      rowSelection[k] ? Number(k) : []
    )
    onChangeSelectedIndexes?.(selectedIndexes)
    if (onChangeSelectedRows) {
      const selectedRows = selectedIndexes.map((i) => data[i])
      onChangeSelectedRows(selectedRows)
    }
  }, [rowSelection, data, onChangeSelectedIndexes, onChangeSelectedRows])

  const defaultPageSize = 50

  const globalFilterFn: FilterFn<T> = (row, columnId, filterValue: string) => {
    const rowValue = row.getValue(columnId)
    const value =
      typeof rowValue === 'number' ? String(rowValue) : (rowValue as string)
    return value?.toLowerCase().includes(filterValue.toLowerCase())
  }

  return useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      sorting,
      columnFilters,
      globalFilter,
      rowSelection,
    },
    initialState: {
      pagination: { pageSize: defaultPageSize },
    },
    enableMultiRowSelection: options?.enableMultiRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    globalFilterFn,
  })
}

const TanstackTableElement: React.FC<{
  table: Table<any>
  options?: TanstackTableOptions & TanstackTableSelectionOptions<any>
}> = ({ table, options }) => {
  const { t } = useTranslation('adminCommon')
  const pageSizes = [10, 20, 50, 100]

  const tableWrapClassName = classNames('responsive-table', {
    'fixed-last-col': options?.fixedLastColumn,
    'table-height-small': options?.tableHeightSmall,
  })

  return (
    <div className="list-table">
      <div
        className={classNames({
          'd-flex justify-content-between align-items-center':
            options?.withSearch,
          'text-right mb-2': !options?.withSearch,
        })}
      >
        {options?.withSearch && (
          <Label>
            <SearchBar
              value={table.getState().globalFilter}
              onChange={(value) => table.setGlobalFilter(String(value))}
            />
          </Label>
        )}
        <div>
          <Trans
            t={t}
            i18nKey="createTable.showContentsCount"
            components={{
              select: (
                <select
                  className="form-control form-control-sm size-per-page-select"
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => table.setPageSize(Number(e.target.value))}
                >
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              ),
            }}
          />
        </div>
      </div>
      <div className={tableWrapClassName}>
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              {options?.enableMultiRowSelection && (
                <th>
                  {options.enableAllRowSelection && (
                    <AllRowSelectionCheckbox table={table} />
                  )}
                </th>
              )}
              {table.getHeaderGroups().map((headerGroup) => (
                <TableHeadCells
                  key={headerGroup.id}
                  headers={headerGroup.headers}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {options?.enableMultiRowSelection && (
                  <td>
                    <RowSelectionCheckbox row={row} />
                  </td>
                )}
                <TableBodyCells key={row.id} cells={row.getVisibleCells()} />
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <TablePaginationButtons table={table} />
    </div>
  )
}

const TableHeadCells: React.FC<{ headers: Header<any, unknown>[] }> = ({
  headers,
}) => {
  return (
    <>
      {headers.map((header) => (
        <th key={header.id} colSpan={header.colSpan}>
          {header.isPlaceholder || (
            <div
              className={classNames({
                'sortable-column': header.column.getCanSort(),
              })}
              onClick={header.column.getToggleSortingHandler()}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
              {header.column.getCanSort() && (
                <span
                  className={classNames('sort-icon', {
                    'sort-asc': header.column.getIsSorted() === 'asc',
                    'sort-desc': header.column.getIsSorted() === 'desc',
                  })}
                />
              )}
            </div>
          )}
          {header.column.getCanFilter() && (
            <div>
              <input
                type="text"
                className="column-filter"
                placeholder={
                  header.column.columnDef.meta?.columnFilterPlaceholder
                }
                value={header.column.getFilterValue() as string}
                onChange={(e) => header.column.setFilterValue(e.target.value)}
              />
            </div>
          )}
        </th>
      ))}
    </>
  )
}

const TableBodyCells: React.FC<{ cells: Cell<any, unknown>[] }> = ({
  cells,
}) => {
  return (
    <>
      {cells.map((cell) => (
        <td key={cell.id}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </>
  )
}

const AllRowSelectionCheckbox: React.FC<{ table: Table<any> }> = ({
  table,
}) => {
  const ref = useRef<HTMLInputElement>(null!)
  const indeterminate = table.getIsSomeRowsSelected()

  useEffect(() => {
    ref.current.indeterminate = indeterminate
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      checked={table.getIsAllRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
    />
  )
}

const RowSelectionCheckbox: React.FC<{ row: TableRow<any> }> = ({ row }) => {
  return (
    <input
      type="checkbox"
      checked={row.getIsSelected()}
      onChange={row.getToggleSelectedHandler()}
    />
  )
}

const SearchBar: React.FC<{
  value: string | number
  onChange: (value: string | number) => void
}> = ({ value: initialValue, onChange }) => {
  const [value, setValue] = useState<string | number>(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, 500)

    return () => clearTimeout(timeout)
  }, [onChange, value])

  return (
    <input
      type="text"
      className="form-control"
      value={value}
      placeholder="Search"
      onChange={(e) => setValue(e.target.value)}
    />
  )
}

const TablePageButton: React.FC<{
  title: string
  onClick: () => void
  isActive?: boolean
}> = ({ title, onClick, isActive, children }) => (
  <li className={classNames('page-item', { active: isActive })} title={title}>
    <div className="page-link" onClick={onClick}>
      {children}
    </div>
  </li>
)

const TablePaginationButtons: React.FC<{ table: Table<any> }> = ({ table }) => {
  const toFirstPage = () => table.setPageIndex(0)
  const toPrevPage = () => table.previousPage()
  const toNextPage = () => table.nextPage()
  const toLastPage = () => table.setPageIndex(table.getPageCount() - 1)

  const currentIndex = table.getState().pagination.pageIndex

  const start = Math.max(0, currentIndex - 2)
  const end = Math.min(table.getPageCount(), currentIndex + 3)
  const showingIndexes = table.getPageOptions().slice(start, end)

  return (
    <ul className="pagination">
      {table.getCanPreviousPage() && currentIndex > 2 && (
        <TablePageButton title="first page" onClick={toFirstPage}>
          {'<<'}
        </TablePageButton>
      )}
      {table.getCanPreviousPage() && (
        <TablePageButton title="previous page" onClick={toPrevPage}>
          {'<'}
        </TablePageButton>
      )}
      {showingIndexes.map((index) => (
        <TablePageButton
          key={index}
          title={(index + 1).toString()}
          onClick={() => table.setPageIndex(index)}
          isActive={index === currentIndex}
        >
          {index + 1}
        </TablePageButton>
      ))}
      {table.getCanNextPage() && (
        <TablePageButton title="next page" onClick={toNextPage}>
          {'>'}
        </TablePageButton>
      )}
      {table.getCanNextPage() && table.getPageCount() - currentIndex > 3 && (
        <TablePageButton title="last page" onClick={toLastPage}>
          {'>>'}
        </TablePageButton>
      )}
    </ul>
  )
}
