import React from 'react'
import { useTranslation } from 'react-i18next'
import { Progress } from 'reactstrap'
import { IUploadProgress } from 'services/admin/upload_progress'
import './uploadprogressitem.scss'

interface IUploadProgressItemProps {
  uploadProgress: IUploadProgress
  onRemove: () => void
}

const UploadProgressItem: React.FC<IUploadProgressItemProps> = ({
  uploadProgress,
  onRemove,
}) => {
  const { t } = useTranslation('adminCommon')
  const [total, loaded, progress] = [
    uploadProgress.progressEvent.total ?? 0,
    uploadProgress.progressEvent.loaded,
    uploadProgress.progressEvent.progress ?? 0,
  ]
  const percentage = Math.round(progress * 100)

  const totalHuman = byte2human(total)
  const loadedHuman = byte2human(loaded)
  const fileSizeText =
    total === 0 && loaded === 0
      ? t('header.uploadProgress.waiting')
      : `${loadedHuman.num.toFixed(2)} / ${totalHuman.num}${totalHuman.unit}`

  return (
    <div className="upload-progress-item">
      <div className="progress-wrappter">
        <div className="upload-progress-item__title">
          <div className="upload-progress-item__name">
            {uploadProgress.videoName}
          </div>
          <button type="button" className="close" onClick={onRemove}>
            ×
          </button>
        </div>
        <div className="progress-info">
          <div className="progress-percentage">
            <span>{fileSizeText}</span>
          </div>
          <div className="progress-percentage">
            <span>{percentage}%</span>
          </div>
        </div>
        <Progress max={100} value={percentage} color="info" />
      </div>
    </div>
  )
}

const byte2human = (
  byte: number
): {
  num: number
  unit: string
} => {
  const gb = Math.round((byte / 1000 / 1000 / 1000) * 100) / 100
  if (gb < 1) {
    return { num: Math.round((byte / 1000 / 1000) * 100) / 100, unit: 'MB' }
  }
  return { num: gb, unit: 'GB' }
}

export default UploadProgressItem
