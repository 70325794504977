import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { IEmailLog } from 'common/interfaces/email_logs'
import { formatUNIXToDate } from 'common/times'
import { formatEmailEvent } from 'common/utils'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeCustomOperationButtons,
} from 'components/atoms/Table/ElementsOnTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getEmailLogs } from 'services/admin/email_log'

interface ITablebody {
  foreignId: string
}

const TableBody: React.FC<ITablebody> = ({ foreignId }: ITablebody) => {
  const { t } = useTranslation('adminInvite')
  const { storeCache } = useContext(AuthContext)

  const [emailLogs, setEmailLogs] = useState<IEmailLog[]>([])

  useEffect(() => {
    getEmailLogs(storeCache, foreignId, setEmailLogs)
  }, [storeCache, foreignId])

  if (emailLogs.length <= 0) {
    return <>{t('emailLog.noEmailLogs')}</>
  }
  return <Table emailLogs={emailLogs} />
}

const Table: React.FC<{ emailLogs: IEmailLog[] }> = ({ emailLogs }) => {
  const i18n = useTranslation('adminInvite').t('emailLog.table', {
    returnObjects: true,
  })
  const history = useHistory()

  const columns = useMemo<ColumnDef<IEmailLog>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => emailLogs.length - i,
      },
      {
        header: i18n.name,
        accessorFn: (el) => el.name ?? '',
      },
      {
        header: i18n.email,
        accessorKey: 'email',
      },
      {
        header: i18n.status.title,
        accessorFn: (el) => formatEmailEvent(el.event),
      },
      {
        header: i18n.timestamp,
        accessorFn: (el) =>
          formatUNIXToDate(el.timestamp, 'YYYY-MM-DD HH:mm:ss'),
      },
      {
        header: i18n.operations,
        accessorFn: (el) =>
          makeCustomOperationButtons(
            makeCustomOperationButton(i18n.detail, () =>
              history.push({
                pathname: Routes.AdminEmailLogShow,
                state: { emailLog: el },
              })
            )
          ),
      },
    ],
    [history, emailLogs.length, i18n]
  )

  const data = emailLogs.sort((a, b) => b.timestamp - a.timestamp)
  return useTanstackTable<IEmailLog>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
