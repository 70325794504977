import { Subject } from 'rxjs'

interface IAnyTeamItemFormModal {
  isShow: boolean
}

const modalSubject = new Subject<IAnyTeamItemFormModal>()

export const AnyTeamItemFormModalService = {
  onModal,
  show,
  close,
}

function onModal() {
  return modalSubject.asObservable()
}

function show() {
  modalSubject.next({ isShow: true })
}

function close(closeModal: IAnyTeamItemFormModal) {
  modalSubject.next({ ...closeModal, isShow: false })
}
