import { InvoiceActionType } from 'common/enums'
import { ICoupon } from 'common/interfaces/stripe/coupon'
import { IPaymentIntent } from 'common/interfaces/stripe/payment_intent'
import { IProductInfo } from 'common/interfaces/stripe/product_price'
import { IVideoPurchaseLead } from 'common/interfaces/video'
import { makeItemSelectPath, makeUserRootPath } from 'common/link_path'
import { makePath2Url, makeUserRootUrl } from 'common/link_url'
import Item from 'components/atoms/Item/Item'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getStripeCoupons } from 'services/admin/stripe/coupon'
import { IInvoicePayment } from 'services/invoice_payment_modal'
import {
  getStripeProductAndPrice,
  transitionCheckoutSession,
} from 'services/item_select'
import {
  cancelPayment,
  createInvoicePaymentForUser,
  resendInvoice,
} from 'services/item_select_video'
import InvoicePaymentModal from '../Admin/AdminItemSelect/InvoicePaymentModal'

type TList = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const List: React.FC<TList> = (props) => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [productInfos, setProductInfos] = useState<IProductInfo[]>([])
  const [coupons, setCoupons] = useState<ICoupon[]>([])
  const [couponId, setCouponId] = useState<string | undefined>(undefined)
  const [isOpenInvoicePayment, setIsOpenInvoicePayment] =
    useState<boolean>(false)
  const [selectProductInfo, setSlectProductInfo] =
    useState<IProductInfo | null>(null)
  const [actionType, setActionType] = useState<InvoiceActionType>(
    InvoiceActionType.DEFAULT
  )
  const [createdPaymentIntent, setCreatedPaymentIntent] =
    useState<IPaymentIntent | null>(null)

  useEffect(() => {
    if (storeCache.selfSubscriptionObj) {
      history.replace(makeUserRootPath(storeCache.team?.id))
    }
  }, [storeCache, history])

  useEffect(() => {
    ;(async () => {
      await Promise.all([
        getStripeProductAndPrice(storeCache, setProductInfos),
        getStripeCoupons(storeCache, setCoupons),
      ])
    })()
  }, [storeCache, setProductInfos])

  const selectedProductInfo = (
    selectProductInfo: IProductInfo,
    couponId: string | undefined
  ) => {
    setSlectProductInfo(selectProductInfo)
    setCouponId(couponId)
    setIsOpenInvoicePayment(true)
  }

  const checkoutSession = (
    productInfo: IProductInfo,
    couponId: string | undefined
  ): Promise<void> =>
    transitionCheckoutSession(
      storeCache,
      productInfo,
      couponId,
      makeUserRootUrl(storeCache.team?.id),
      makePath2Url(makeItemSelectPath(storeCache.team!.id))
    )

  const changeIsOpenInvoicePayment = () => {
    setIsOpenInvoicePayment(!isOpenInvoicePayment)
    setActionType(InvoiceActionType.DEFAULT)
  }

  const createInvoicePayment = (
    selectedProductInfo: IProductInfo,
    invoicePayment: IInvoicePayment,
    couponId: string | undefined
  ) =>
    createInvoicePaymentForUser(
      false,
      storeCache,
      history,
      selectedProductInfo,
      invoicePayment,
      props.setLoading,
      setActionType,
      setCreatedPaymentIntent,
      { redirectPath: '', couponId } as IVideoPurchaseLead
    )

  const resend = (invoicePayment: IInvoicePayment) =>
    resendInvoice(
      storeCache,
      invoicePayment,
      createdPaymentIntent,
      props.setLoading
    )

  const cancel = (
    selectedProductInfo: IProductInfo,
    invoicePayment: IInvoicePayment
  ) =>
    cancelPayment(
      storeCache,
      history,
      invoicePayment,
      createdPaymentIntent,
      selectedProductInfo,
      couponId,
      '',
      props.setLoading
    )

  const Items = () =>
    productInfos.map((productInfo: IProductInfo, key: number) => {
      if (productInfo.hidden) return <></>

      return (
        <Item
          team={storeCache.team}
          user={storeCache.user!}
          subscriptionObj={null}
          productInfo={productInfo}
          coupons={coupons}
          purchasedPriceId=""
          transitionToAuth={() => undefined}
          checkoutSession={(couponId: string | undefined) =>
            checkoutSession(productInfo, couponId)
          }
          changePrice={() => undefined}
          createInvoicePayment={(couponId: string | undefined) =>
            selectedProductInfo(productInfo, couponId)
          }
          key={key}
        />
      )
    })

  return (
    <>
      {Items()}

      <InvoicePaymentModal
        selectProductInfo={selectProductInfo}
        couponId={couponId}
        isOpen={isOpenInvoicePayment}
        actionType={actionType}
        onToggleModal={changeIsOpenInvoicePayment}
        onClick={createInvoicePayment}
        resendInvoice={resend}
        cancelPayment={cancel}
      />
    </>
  )
}

export default List
