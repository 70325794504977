import { IStripe } from 'common/interfaces/stripe'
import { IStripe as ITeamStripe } from 'common/interfaces/team'
import { makeItemSelectPath } from 'common/link_path'
import {
  goBackWithErrorAlert,
  isBasicPrice,
  isTrialPlan,
  openNewTab,
} from 'common/utils'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  InputType,
  saveStripe,
  onChangeInput as serviceOnChangeInput,
} from 'services/admin/stripe'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'

const FormBody: React.FC = () => {
  const { t } = useTranslation('adminStripeBilling')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const [teamStripe, setTeamStripe] = useState<ITeamStripe>(
    storeCache.team?.stripe ?? ({} as ITeamStripe)
  )
  const [stripe, setStripe] = useState<IStripe>(initStripe())

  useEffect(() => {
    getStripe(setStripe)
  }, [])

  if (!storeCache.team) {
    goBackWithErrorAlert(history, i18nAlert('noOperationAuthority'))
    return null
  }

  const onChangeInput = (type: InputType, e: any) =>
    serviceOnChangeInput(teamStripe, setTeamStripe, type, e)
  const save = () => saveStripe(teamStripe, storeCache)

  return (
    <Form>
      <FormGroup row>
        <Col md="10">
          <Label for="teamAccountIdForm">
            <InfoTooltip
              content={t('stripeManagement.form.accountId.title')}
              tooltipContent={t('stripeManagement.form.accountId.tips')}
            />
          </Label>
          <Input
            id="teamAccountIdForm"
            onChange={(e) => onChangeInput(InputType.ACCOUNT_ID, e)}
            defaultValue={teamStripe.account_id}
            type="text"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="subscriptionProductIdForm">
            <InfoTooltip
              content={t('stripeManagement.form.subscription.title')}
              tooltipContent={t('stripeManagement.form.subscription.tips')}
            />
          </Label>
          <Input
            id="subscriptionProductIdForm"
            defaultValue={teamStripe.subscription_product_id}
            type="text"
            disabled
          />
        </Col>
      </FormGroup>
      <Row>
        <Col>
          <Button
            onClick={() => openNewTab(makeItemSelectPath(storeCache.team!.id))}
            disabled={!teamStripe.subscription_product_id}
            className="mb-4"
            color="default"
            type="button"
            size="sm"
          >
            {t('stripeManagement.form.confirmSubscription')}
          </Button>
        </Col>
      </Row>
      <FormGroup>
        <Label>
          <InfoTooltip
            content={t('stripeManagement.form.productsActive.title')}
            tooltipContent={t('stripeManagement.form.productsActive.tips')}
          />
        </Label>
        <Row>
          <Col md="10">
            <label className="custom-toggle">
              <input
                type="checkbox"
                onChange={(e) =>
                  onChangeInput(InputType.IS_PRODUCTS_ACTIVATE, e)
                }
                defaultChecked={teamStripe.is_products_activate}
                disabled={
                  teamStripe.subscription_product_id === '' ||
                  isTrialPlan(storeCache.subscriptionObj) ||
                  isBasicPrice(storeCache.subscriptionObj, stripe)
                }
              />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Label>
          <InfoTooltip
            content={t('stripeManagement.form.invoiceActive.title')}
            tooltipContent={t('stripeManagement.form.invoiceActive.tips')}
          />
        </Label>
        <Row>
          <Col md="10">
            <label className="custom-toggle">
              <input
                type="checkbox"
                onChange={(e) =>
                  onChangeInput(InputType.ALLOW_INVOICE_PAYMENT, e)
                }
                defaultChecked={teamStripe.allow_invoice_payment}
              />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
          </Col>
        </Row>
      </FormGroup>
      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {t('save')}
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
