/**
 * standard modules of quill
 */
export const standardModules = () => {
  return {
    toolbar: {
      container: [
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ align: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ color: [] }, { background: [] }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['link', 'image', 'clean'],
      ],
    },
    imageCompress: {
      quality: 0.7,
      maxWidth: 1024,
      maxHeight: 1024,
      imageType: 'image/jpeg',
      debug: false,
      suppressErrorLogging: false,
    },
  }
}

/**
 * hide toolbar modules of quill
 */
export const hideToolbarModules = () => {
  return {
    ...standardModules(),
    toolbar: false,
  }
}
