import { IEvent } from 'common/interfaces/event'
import { isAfterFromTo } from 'common/times'
import { i18nValidation } from 'i18n/i18n'

/**
 * イベント登録・編集用バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param event `IEvent` イベントドキュメント
 * @param isImageCropped boolean サムネイル設定時に切り取りボタンが押下されたかどうか
 */
export const validateForm = (
  {
    name,
    application_period_from,
    application_period_to,
    hold_from,
    hold_to,
    contents,
  }: IEvent,
  isImageCropped: boolean
): void => {
  if (!name) {
    throw new Error(i18nValidation('input.title'))
  }
  if (isAfterFromTo(application_period_from, application_period_to)) {
    throw new Error(i18nValidation('event.applicationFrom.beforeApplicationTo'))
  }
  if (isAfterFromTo(application_period_from, hold_from)) {
    throw new Error(i18nValidation('event.applicationFrom.beforeHoldFrom'))
  }
  if (isAfterFromTo(application_period_to, hold_to)) {
    throw new Error(i18nValidation('event.applicationTo.beforeHoldTo'))
  }
  if (isAfterFromTo(hold_from, hold_to)) {
    throw new Error(i18nValidation('event.holdFrom.beforeHoldTo'))
  }
  if (contents.length === 0) {
    throw new Error(i18nValidation('event.select.contentsMoreThanOne'))
  }
  if (!isImageCropped) {
    throw new Error(i18nValidation('thumbnail.incompleteCut'))
  }
}
