import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { ICreator } from 'common/interfaces/creator'
import ButtonRight from 'components/atoms/Button/Right'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  createOverviewContent,
  makeEditDeleteOperationButtons,
  makeThumbnailElement,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { getCreatorUseCount, removeCreator } from 'services/admin/creator'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminCreator')
  const { creators } = useContext(AuthContext).storeCache

  if (creators.length <= 0) {
    return <RegisterItemButton type="creator" />
  }
  return (
    <>
      <ButtonRight
        nextPage={Routes.AdminCreatorCreate}
        content={t('list.newRegister')}
      />
      <Table />
    </>
  )
}

const Table: React.FC = () => {
  const { t } = useTranslation('adminCreator')
  const { storeCache } = useContext(AuthContext)
  const { creators } = storeCache
  const history = useHistory()

  const columns = useMemo<ColumnDef<ICreator>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => creators.length - i,
      },
      {
        header: t('list.table.name'),
        accessorKey: 'name',
      },
      {
        header: t('list.table.thumbnail'),
        accessorFn: (c) => makeThumbnailElement(c.image, c.name, 'creator'),
      },
      {
        header: t('list.table.sortNumber'),
        accessorFn: (c) => (c.sort_num === null ? '' : c.sort_num),
      },
      {
        header: t('list.table.profile'),
        accessorFn: (c) => createOverviewContent(c.profile),
      },
      {
        header: t('list.table.usingVideoCount.title'),
        accessorFn: (c) =>
          t('list.table.usingVideoCount.count', {
            usingCreatorCount: getCreatorUseCount(c, storeCache),
          }),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (c) =>
          makeEditDeleteOperationButtons<ICreator>(
            history,
            'creator',
            Routes.AdminCreatorEdit,
            (e) => removeCreator(e, storeCache),
            c
          ),
      },
    ],
    [storeCache, history, creators.length, t]
  )

  const data = creators.sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  )
  return useTanstackTable<ICreator>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
