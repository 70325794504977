import '@fortawesome/fontawesome-free/css/all.min.css'
import App from 'App'
import 'assets/plugins/nucleo/css/nucleo.css'
import 'assets/scss/argon-dashboard-react.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import './i18n/config'

const gtmId = String(process.env.REACT_APP_GTM_ID)
TagManager.initialize({ gtmId })
ReactDOM.render(<App />, document.getElementById('root'))
