import { AuthMethodType, LangType } from 'common/enums'
import { ContentsOrderType } from 'common/interfaces/contents_orders'
import { IEnquete } from 'common/interfaces/enquete'
import { IInvite } from 'common/interfaces/invite'
import { IStripe } from 'common/interfaces/stripe'
import { ITeamBasicInfo } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { makeLoginTeamUrl } from 'common/link_url'
import {
  copyClipboard,
  isBasicPrice,
  isCustomerBalance,
  isProPrice,
  isTrialPlan,
  null2str,
} from 'common/utils'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { OptionTypeBase, OptionsType } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from 'reactstrap'
import { MAIL_SEND_DOMAIN } from 'repositories/functions/functions_mail'
import {
  InputType,
  changeCard,
  controlSubscription,
  getDefaultAllowIps,
  removeTeam,
  saveBasicInfo,
  onChangeAllowIps as serviceOnChangeAllowIps,
  onChangeAuthMethod as serviceOnChangeAuthMethod,
  onChangeInput as serviceOnChangeInput,
} from 'services/admin/basic_info'
import { getEnquetes } from 'services/admin/enquete'
import { getForUserInvites } from 'services/admin/invite'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'
import { getNewerUsers } from 'services/admin/user'
import { modalService } from 'services/modal'

const FormBody: React.FC = () => {
  const { t } = useTranslation('adminBasicInfo')
  const { t: i18nCommon } = useTranslation('adminCommon')
  const { storeCache } = useContext(AuthContext)
  const { team } = storeCache
  const history = useHistory()

  const [basicInfo, setBasicInfo] = useState<ITeamBasicInfo>(
    team || ({} as ITeamBasicInfo)
  )
  const [enquetes, setEnquetes] = useState<IEnquete[]>([])
  const [invite, setInvite] = useState<IInvite[]>([])
  const [stripe, setStripe] = useState<IStripe>(initStripe())
  const [users, setUsers] = useState<IUser[]>([])

  const loginTeamUrl = makeLoginTeamUrl(team?.id ?? '')

  const copyLoginTeamUrl = () => copyClipboard(loginTeamUrl)
  const openQrCodeModal = () => modalService.showQrCode(loginTeamUrl)

  useEffect(() => {
    ;(async () => {
      await Promise.all([
        getStripe(setStripe),
        getEnquetes(storeCache, setEnquetes),
        getForUserInvites(storeCache, setInvite),
        getNewerUsers(setUsers, storeCache),
      ])
    })()
  }, [storeCache])

  if (!team) return null

  const inviteOptions = [<option key="-1" />]
  invite.map(({ id, name }, key) =>
    inviteOptions.push(
      <option key={key} value={id}>
        {name}
      </option>
    )
  )
  const enqueteOptions = [<option key="-1" />]
  enquetes.map(({ id, title }, key) =>
    enqueteOptions.push(
      <option key={key} value={id}>
        {title}
      </option>
    )
  )

  const { admin: defaultAllowIpAdmin, user: defaultAllowIpUser } =
    getDefaultAllowIps(basicInfo)

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(basicInfo, setBasicInfo, type, e)

  const onChangeAuthMethod = (type: AuthMethodType) =>
    serviceOnChangeAuthMethod(basicInfo, setBasicInfo, type)

  const onChangeAllowIps = (type: InputType, e: OptionsType<OptionTypeBase>) =>
    serviceOnChangeAllowIps(basicInfo, setBasicInfo, type, e)

  const changeCardInfo = () => changeCard(storeCache)
  const cancel = () => controlSubscription(storeCache, true)
  const restart = () => controlSubscription(storeCache, false)
  const remove = () => removeTeam(storeCache, history)
  const update = () => saveBasicInfo(basicInfo, storeCache)

  return (
    <Form>
      <FormGroup row>
        <Col md="10">
          <label htmlFor="teamIdForm">ID</label>
          <Input id="teamIdForm" value={team.id} type="text" readOnly />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="teamCompanyForm">
            <InfoTooltip
              content={t('form.basic.company.title')}
              tooltipContent={t('form.basic.company.tips')}
            />
          </Label>
          <Input
            id="teamCompanyForm"
            value={basicInfo.company}
            onChange={(e) => onChangeInput(InputType.COMPANY, e)}
            type="text"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="teamTelForm">
            <InfoTooltip
              content={t('form.basic.tel.title')}
              tooltipContent={t('form.basic.tel.tips')}
            />
          </Label>
          <Input
            id="teamTelForm"
            value={basicInfo.tel}
            onChange={(e) => onChangeInput(InputType.TEL, e)}
            type="tel"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="teamNameForm">
            <InfoTooltip
              content={t('form.basic.site.title')}
              tooltipContent={t('form.basic.site.tips')}
            />
          </Label>
          <Input
            id="teamNameForm"
            value={basicInfo.name}
            onChange={(e) => onChangeInput(InputType.NAME, e)}
            type="text"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="teamEmailForm">
            <InfoTooltip
              content={t('form.basic.email.title')}
              tooltipContent={t('form.basic.email.tips', {
                mailSendDomain: MAIL_SEND_DOMAIN,
              })}
            />
          </Label>
          <Input
            id="teamEmailForm"
            value={null2str(basicInfo.email)}
            onChange={(e) => onChangeInput(InputType.EMAIL, e)}
            type="text"
          />
        </Col>
      </FormGroup>
      <h2>{t('form.urls.title')}</h2>
      <FormGroup row>
        <Col md="10">
          <Label for="aboutUrl">
            <InfoTooltip
              content={t('form.urls.companyInfo.title')}
              tooltipContent={t('form.urls.companyInfo.tips')}
            />
          </Label>
          <Input
            id="aboutUrl"
            value={null2str(basicInfo.urls.about)}
            onChange={(e) => onChangeInput(InputType.URL_ABOUT, e)}
            placeholder="https://..."
            type="url"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="termsUrl">
            <InfoTooltip
              content={t('form.urls.terms.title')}
              tooltipContent={t('form.urls.terms.tips')}
            />
          </Label>
          <Input
            id="termsUrl"
            value={null2str(basicInfo.urls.terms)}
            onChange={(e) => onChangeInput(InputType.URL_TERMS, e)}
            placeholder="https://..."
            type="url"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="contactUrl">
            <InfoTooltip
              content={t('form.urls.contact.title')}
              tooltipContent={t('form.urls.contact.tips')}
            />
          </Label>
          <Input
            id="contactUrl"
            value={null2str(basicInfo.urls.contact)}
            onChange={(e) => onChangeInput(InputType.URL_CONTACT, e)}
            placeholder="https://..."
            type="url"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="faqUrl">
            <InfoTooltip
              content={t('form.urls.faq.title')}
              tooltipContent={t('form.urls.faq.tips')}
            />
          </Label>
          <Input
            id="faqUrl"
            value={null2str(basicInfo.urls.faq)}
            onChange={(e) => onChangeInput(InputType.URL_FAQ, e)}
            placeholder="https://..."
            type="url"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="lawUrl">
            <InfoTooltip
              content={t('form.urls.law.title')}
              tooltipContent={t('form.urls.law.tips')}
            />
          </Label>
          <Input
            id="lawUrl"
            value={null2str(basicInfo.urls.law)}
            onChange={(e) => onChangeInput(InputType.URL_LAW, e)}
            placeholder="https://..."
            type="url"
          />
        </Col>
      </FormGroup>
      <h2>{t('form.hideOneStreamLogo')}</h2>
      <FormGroup>
        <div>
          <label className="custom-toggle">
            <input
              type="checkbox"
              checked={basicInfo.hide_footer_logo}
              disabled={
                isTrialPlan(storeCache.subscriptionObj) ||
                isBasicPrice(storeCache.subscriptionObj, stripe) ||
                isProPrice(storeCache.subscriptionObj, stripe)
              }
              onChange={(e) => onChangeInput(InputType.HIDE_FOOTER_LOGO, e)}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </div>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="accountRegisterUrl">
            <InfoTooltip
              content={t('form.urls.accountRegisterCode.title')}
              tooltipContent={t('form.urls.accountRegisterCode.tips')}
            />
          </Label>
          <Input
            id="accountRegisterUrl"
            value={null2str(basicInfo.urls.account_register)}
            onChange={(e) => onChangeInput(InputType.URL_ACCOUNT_REGISTER, e)}
            type="select"
          >
            {inviteOptions}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="loginUrlForm">
            <InfoTooltip
              content={t('form.urls.loginUrl.title')}
              tooltipContent={t('form.urls.loginUrl.tips')}
            />
          </Label>
          <InputGroup id="loginUrlForm">
            <Input
              value={loginTeamUrl}
              readOnly
              onFocus={(e) => e.target.select()}
            />
            <InputGroupAddon addonType="append">
              <Button color="primary" onClick={copyLoginTeamUrl}>
                {i18nCommon('copy')}
              </Button>
              <Button color="primary" outline onClick={openQrCodeModal}>
                {i18nCommon('qrCode')}
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </FormGroup>
      <h2>{t('form.socialLogin')}</h2>
      <FormGroup className="d-flex">
        <div>
          <label className="custom-toggle">
            <input
              type="checkbox"
              checked={basicInfo.social_logins.google}
              onChange={(e) => onChangeInput(InputType.IS_SOCIAL_GOOGLE, e)}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </div>
        <div className="ml-3">Google</div>
      </FormGroup>
      <h2>{t('form.requestComplexPassword')}</h2>
      <FormGroup>
        <label className="custom-toggle">
          <input
            type="checkbox"
            checked={basicInfo.is_password_complex ?? false}
            onChange={(e) => onChangeInput(InputType.IS_PASSWORD_COMPLEX, e)}
          />
          <span className="custom-toggle-slider rounded-circle" />
        </label>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="teamSamlForm">
            <InfoTooltip
              content={t('form.basic.saml.title')}
              tooltipContent={t('form.basic.saml.tips')}
            />
          </Label>
          <Input
            id="teamSamlForm"
            value={null2str(basicInfo.saml_provider_id)}
            onChange={(e) => onChangeInput(InputType.SAML_PROVIDER_ID, e)}
            type="text"
          />
        </Col>
      </FormGroup>
      <h2>{t('form.others.title')}</h2>
      <FormGroup row>
        <Col md="10">
          <Label for="enqueteIdForm">
            <InfoTooltip
              content={t('form.others.registeredEnquete.title')}
              tooltipContent={t('form.others.registeredEnquete.tips')}
            />
          </Label>
          <Input
            id="enqueteIdForm"
            value={basicInfo.enquete_id}
            onChange={(e) => onChangeInput(InputType.ENQUETE_ID, e)}
            type="select"
          >
            {enqueteOptions}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row check className="mb-4">
        <Row>
          <Col md="10">
            <Label for="checkForm">{t('form.others.commentActive')}</Label>
          </Col>
        </Row>
        <Row>
          <Col md="10">
            <label className="custom-toggle" id="checkForm">
              <input
                type="checkbox"
                checked={basicInfo.is_comment_active}
                onChange={(e) => onChangeInput(InputType.IS_COMMENT_ACTIVE, e)}
              />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup row check className="mb-4">
        <Row>
          <Col md="10">
            <Label>{t('form.others.authMethod.title')}</Label>
          </Col>
        </Row>
        <div className="custom-control custom-control-alternative custom-radio mb-3">
          <input
            className="custom-control-input"
            id="authRadio1"
            type="radio"
            value={AuthMethodType.EMAIL}
            onClick={() => onChangeAuthMethod(AuthMethodType.EMAIL)}
            checked={
              basicInfo.auth_method === AuthMethodType.EMAIL ||
              basicInfo.auth_method === undefined
            }
            disabled={users.length > 0}
          />
          <Label className="custom-control-label" for="authRadio1">
            {t('form.others.authMethod.standard')}
          </Label>
        </div>
        <div className="custom-control custom-control-alternative custom-radio mb-3">
          <input
            className="custom-control-input"
            id="authRadio2"
            type="radio"
            value={AuthMethodType.CUSTOM}
            onClick={() => onChangeAuthMethod(AuthMethodType.CUSTOM)}
            checked={basicInfo.auth_method === AuthMethodType.CUSTOM}
            disabled={users.length > 0}
          />
          <Label className="custom-control-label" for="authRadio2">
            {t('form.others.authMethod.anyId')}
          </Label>
        </div>
      </FormGroup>
      <FormGroup row>
        <Col md="4">
          <Label>
            <InfoTooltip
              content={t('form.others.sortSetting.title')}
              tooltipContent={t('form.others.sortSetting.tips')}
            />
          </Label>
          <Input
            value={basicInfo.default_contents_orders.video}
            onChange={(e) => onChangeInput(InputType.DEFAULT_CONTENTS_ORDER, e)}
            type="select"
          >
            <option value={ContentsOrderType.NEWER}>
              {t('form.others.sortSetting.options.newer')}
            </option>
            <option value={ContentsOrderType.PUBLISH}>
              {t('form.others.sortSetting.options.publish')}
            </option>
            <option value={ContentsOrderType.UPDATE}>
              {t('form.others.sortSetting.options.update')}
            </option>
            <option value={ContentsOrderType.NAME_ASC}>
              {t('form.others.sortSetting.options.nameAsc')}
            </option>
            <option value={ContentsOrderType.NAME_DESC}>
              {t('form.others.sortSetting.options.nameDesc')}
            </option>
          </Input>
        </Col>
      </FormGroup>
      <h2>{t('form.allowIp.title')}</h2>
      <FormGroup row>
        <Col md="10">
          <Label for="adminAllowIpForm">
            <InfoTooltip
              content={t('form.allowIp.admin.title')}
              tooltipContent={t('form.allowIp.admin.tips')}
            />
          </Label>
          <CreatableSelect
            id="adminAllowIpForm"
            defaultValue={defaultAllowIpAdmin}
            onChange={(e) => onChangeAllowIps(InputType.ALLOW_IPS_ADMIN, e)}
            placeholder={t('form.allowIp.unlimited')}
            isMulti
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.allowIp.user.title')}
              tooltipContent={t('form.allowIp.user.tips')}
            />
          </Label>
          <CreatableSelect
            defaultValue={defaultAllowIpUser}
            onChange={(e) => onChangeAllowIps(InputType.ALLOW_IPS_USER, e)}
            placeholder={t('form.allowIp.unlimited')}
            isMulti
          />
        </Col>
      </FormGroup>
      <h2>{t('form.activePublic.title')}</h2>
      <div className="mb-3 small">{t('form.activePublic.text')}</div>
      <FormGroup>
        <label className="custom-toggle">
          <input
            type="checkbox"
            checked={basicInfo.is_site_public}
            onChange={(e) => onChangeInput(InputType.IS_SITE_PUBLIC, e)}
          />
          <span className="custom-toggle-slider rounded-circle" />
        </label>
      </FormGroup>

      <h2>{t('form.activeSingleLogin.title')}</h2>
      <div className="mb-3 small">{t('form.activeSingleLogin.text')}</div>
      <FormGroup>
        <label className="custom-toggle">
          <input
            type="checkbox"
            checked={basicInfo.is_single_login}
            onChange={(e) => onChangeInput(InputType.IS_SINGLE_LOGIN, e)}
            disabled={
              isBasicPrice(storeCache.subscriptionObj, stripe) ||
              isProPrice(storeCache.subscriptionObj, stripe)
            }
          />
          <span className="custom-toggle-slider rounded-circle" />
        </label>
      </FormGroup>
      <FormGroup row>
        <Col md="4">
          <Label>
            <InfoTooltip
              content={t('form.languageSetting.title')}
              tooltipContent={t('form.languageSetting.tips')}
            />
          </Label>
          <Input
            value={basicInfo.lang}
            onChange={(e) => onChangeInput(InputType.LANG, e)}
            type="select"
          >
            <option value={LangType.JA}>
              {t('form.languageSetting.options.japanese')}
            </option>
            <option value={LangType.EN}>
              {t('form.languageSetting.options.english')}
            </option>
          </Input>
        </Col>
      </FormGroup>

      <SubscriptionButton
        changeCard={changeCardInfo}
        cancel={cancel}
        restart={restart}
        remove={remove}
      />

      <div className="text-center">
        <Button onClick={update} className="my-4" color="primary" type="button">
          {t('form.save')}
        </Button>
      </div>
    </Form>
  )
}

const SubscriptionButton: React.FC<{
  changeCard: React.MouseEventHandler<HTMLButtonElement>
  cancel: React.MouseEventHandler<HTMLButtonElement>
  restart: React.MouseEventHandler<HTMLButtonElement>
  remove: React.MouseEventHandler<HTMLButtonElement>
}> = ({ changeCard, cancel, restart, remove }) => {
  const { t } = useTranslation('adminBasicInfo')
  const { subscriptionObj } = useContext(AuthContext).storeCache
  const rowButton = (
    content: string,
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    color = 'danger'
  ) => (
    <Row>
      {subscriptionObj && !isCustomerBalance(subscriptionObj) && (
        <Button onClick={changeCard} color="info" type="button">
          {t('form.changeCreditCardInfo')}
        </Button>
      )}
      <Button onClick={onClick} color={color} type="button">
        {content}
      </Button>
    </Row>
  )
  if (!subscriptionObj) {
    return rowButton(t('form.remove'), remove)
  }
  if (!subscriptionObj.subscription.cancel_at_period_end) {
    return rowButton(t('form.cancelUpdateSubscription'), cancel, '')
  }
  return rowButton(t('form.restartSubscription'), restart)
}

export default FormBody
