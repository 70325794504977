export interface ITopItemOrder {
  type: TopItemOrderType
  targetId?: string
}

export enum TopItemOrderType {
  DIVIDER = 'divider',
  TOP_IMAGES = 'top_images',
  CHOOSE_CONTENT_TYPE = 'choose_content_type',
  CHOOSE_GENRE_CATEGORY = 'choose_genre_category',
  CHOOSE_GENRE_TAG = 'choose_genre_tag',
  CHOOSE_GENRE_CREATOR = 'choose_genre_creator',
  CHOOSE_PLAY_STATUS = 'choose_play_status',
  SEARCH_VIDEO_INPUT = 'search_video_input',
  VIDEOS_NEWER = 'videos_newer',
  VIDEOS_POPULAR = 'videos_popular',
  VIDEOS_POPULAR_IN_GENRE = 'videos_popular_in_genre',
  CATEGORIES_VIDEO = 'categories',
  TAGS_VIDEO = 'tags',
  CREATORS_VIDEO = 'creators',
  VIDEOS_IN_CATEGORY = 'videos_in_category',
  VIDEOS_IN_TAG = 'videos_in_tag',
  VIDEOS_IN_CREATOR = 'videos_in_creator',
  LIVES_NEWER = 'lives_newer',
  CATEGORIES_LIVE = 'categories_live',
  TAGS_LIVE = 'tags_live',
  CREATORS_LIVE = 'creators_live',
  LIVES_IN_CATEGORY = 'lives_in_category',
  LIVES_IN_TAG = 'lives_in_tag',
  LIVES_IN_CREATOR = 'lives_in_creator',
  EVENTS_NEWER = 'events_newer',
  PLAYLISTS_NEWER = 'playlists_newer',
  CATEGORIES_PLAYLIST = 'categories_playlist',
  TAGS_PLAYLIST = 'tags_playlist',
  PLAYLISTS_IN_CATEGORY = 'playlists_in_category',
  PLAYLISTS_IN_TAG = 'playlists_in_tag',
  PLAYLISTS = 'playlists',
  VIDEOS_IN_PLAYLIST = 'videos_in_playlist',
  USER_BOOKMARKS = 'user_bookmarks',
  USER_VIEW_LOGS = 'user_view_histories',
}
