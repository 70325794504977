import { LimitPlay } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEvent } from 'common/interfaces/event'
import { ILive } from 'common/interfaces/live'
import { IVideo } from 'common/interfaces/video'
import { isCatalogPage } from 'common/link'
import { isLoggedIn } from 'common/utils'
import { useEffect, useState } from 'react'
import { addLivePageOpenLog } from 'repositories/store/firebase_log'
import { checkLimitPlay, nextContent } from './video_detail'

/**
 * 動画詳細情報を取得する
 */
export const useLiveData = (
  storeCache: IStoreCache,
  isEventPage: boolean,
  parentId: string | undefined,
  liveId: string
): {
  isCatalog: boolean
  event: IEvent | null
  live: ILive | null
  nextContent: ILive | IVideo | null
  limitPlay: LimitPlay
} => {
  const isCatalog = isCatalogPage()
  const [limitPlay, setLimitPlay] = useState<LimitPlay>(LimitPlay.LOADING)

  const event = storeCache.events.find(({ id }) => id === parentId) ?? null
  const live = storeCache.lives.find(({ id }) => id === liveId) ?? null

  useEffect(() => {
    if (!live) return

    checkLimitPlay(storeCache, isEventPage, live, event, setLimitPlay, false)

    if (isCatalog || !isLoggedIn(storeCache, true)) return

    addLivePageOpenLog(storeCache.team!, storeCache.user!, live)
  }, [storeCache, isCatalog, isEventPage, live, event])

  useEffect(() => {
    if (!live || limitPlay !== LimitPlay.BEFORE) return

    const startDate = live.hold_from.seconds * 1000
    const diffMillSec = startDate - Date.now()

    const timer = setTimeout(() => {
      checkLimitPlay(storeCache, isEventPage, live, event, setLimitPlay, false)
    }, diffMillSec + 1000)

    return () => clearTimeout(timer)
  }, [storeCache, isEventPage, live, event, limitPlay])

  if (!live && !isCatalog && !isLoggedIn(storeCache, true)) {
    return { isCatalog, event: null, live: null, nextContent: null, limitPlay }
  }

  return {
    isCatalog,
    event,
    live,
    nextContent: nextContent(storeCache, parentId, live!.id),
    limitPlay,
  }
}
