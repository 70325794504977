import { IEvent } from 'common/interfaces/event'
import { ILive } from 'common/interfaces/live'
import { IPlaylist } from 'common/interfaces/playlist'
import { IVideo } from 'common/interfaces/video'
import {
  makeEventVideoPath,
  makePlaylistVideoPath,
  makeUserRootPath,
} from 'common/link_path'
import { periodDatetimeFormat } from 'common/times'
import QuillHtml from 'components/atoms/Quill/QuillHtml'
import WideContentCard from 'components/molecules/User/WideContentCard/WideContentCard'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Container } from 'reactstrap'
import { PageType, pageType } from 'services/user/playlist_menu'
import './playlistmenu.scss'

const PlaylistMenu: React.FC = () => {
  const { t } = useTranslation('userVideoList')
  const { team, videos, lives, events, playlists } =
    useContext(AuthContext).storeCache
  const { eventId, playlistId } =
    useParams<{ eventId: string | undefined; playlistId: string | undefined }>()
  const page = pageType((eventId || playlistId) ?? '')
  const userUrl = makeUserRootPath(team!.id)

  const doc = searchDoc(page, eventId || playlistId, events, playlists)
  if (!doc) {
    return (
      <div className="mt-5 text-center">
        <Trans
          t={t}
          i18nKey="playlistMenu.notFoundPlaylist"
          components={{ topLink: <Link to={userUrl} /> }}
        />
      </div>
    )
  }

  return (
    <div className="user-playlist-menu">
      <Container>
        <h1 className="title">{doc.name}</h1>
        {page === PageType.EVENT && holderDate(doc as IEvent)}
        <QuillHtml html={doc.overview} />
        <div className="menu-video-cards">
          {createVideoCards(page, videos, lives, doc)}
        </div>
      </Container>
    </div>
  )
}

const searchDoc = (
  pageType: PageType,
  targetId = '',
  events: IEvent[],
  playlists: IPlaylist[]
): IEvent | IPlaylist | undefined => {
  switch (pageType) {
    case PageType.EVENT:
      return events.find((e) => e.id === targetId)
    case PageType.PLAYLIST:
      return playlists.find((p) => p.id === targetId)
  }
}

const holderDate = ({ hold_from, hold_to }: IEvent): JSX.Element => (
  <>{periodDatetimeFormat(hold_from, hold_to)}</>
)

const createVideoCards = (
  pageType: PageType,
  videos: IVideo[],
  lives: ILive[],
  doc: IEvent | IPlaylist
): JSX.Element[] => {
  switch (pageType) {
    case PageType.EVENT:
      doc = doc as IEvent
      return doc.contents
        .filter(
          (id) =>
            videos.find((v) => v.id === id) || lives.find((l) => l.id === id)
        )
        .map((contentId, index) =>
          createVideoElement(
            pageType,
            doc.id,
            contentId,
            index + 1,
            lives.some(({ id }) => id === contentId)
          )
        )
    case PageType.PLAYLIST:
      doc = doc as IPlaylist
      return doc.video_ids
        .filter((id) => videos.find((v) => v.id === id))
        .map((videoId, index) =>
          createVideoElement(pageType, doc.id, videoId, index + 1)
        )
  }
}

const createVideoElement = (
  pageType: PageType,
  targetId: string,
  childId: string,
  index: number,
  isLive?: boolean
): JSX.Element => {
  const link = (() => {
    switch (pageType) {
      case PageType.EVENT:
        return makeEventVideoPath(targetId, childId, isLive)
      case PageType.PLAYLIST:
        return makePlaylistVideoPath(targetId, childId)
    }
  })()
  return (
    <div key={childId} className="menu-video-card">
      <Link to={link}>
        <WideContentCard
          videoId={childId}
          customTitle={`${index}. {{title}}`}
        />
      </Link>
    </div>
  )
}

export default PlaylistMenu
