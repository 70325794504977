import Loading from 'assets/images/button-loading.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'

const PreparingVideoLibrary: React.FC = () => {
  const { t } = useTranslation('adminVideo')
  return (
    <h2 className="d-flex align-items-center">
      <img
        src={Loading}
        alt="loading"
        className="mr-2"
        style={{ height: '2.5em' }}
      />
      {t('preparingVideoLibrary.waitForPreparingVideoLibrary')}
    </h2>
  )
}

export default PreparingVideoLibrary
