import { IInviteMail, IUserAndEmail } from 'common/interfaces/admin/invite_mail'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IInvite } from 'common/interfaces/invite'
import { standardModules } from 'common/quill'
import QuillForm from 'components/atoms/Input/QuillForm'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { History } from 'history'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  generateSampleCSV,
  initInviteMail,
  InputType,
  onChangeInput as serviceOnChangeInput,
  sendInviteMail,
} from 'services/admin/invite_mail'

interface IFormBody {
  storeCache: IStoreCache
  invite: IInvite
  history: History
}

const FormBody: React.FC<IFormBody> = ({
  storeCache,
  invite,
  history,
}: IFormBody) => {
  const { t } = useTranslation('adminInvite')
  const [inviteMail, setInviteMail] = useState<IInviteMail>(
    initInviteMail(invite.admin, storeCache.team!)
  )

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement> | string
  ) => serviceOnChangeInput(inviteMail, setInviteMail, type, e)
  const downloadSampleCSV = () => generateSampleCSV()
  const sendMail = () => sendInviteMail(history, inviteMail, invite, storeCache)

  return (
    <Form>
      <FormGroup row>
        <Col md="10">
          <Label for="nameForm">
            <InfoTooltip
              content={t('inviteMail.form.nameForm.title')}
              tooltipContent={t('inviteMail.form.nameForm.tips')}
            />
          </Label>
          <Input
            id="nameForm"
            onChange={(e) => onChangeInput(InputType.NAME, e)}
            type="text"
            disabled={inviteMail.import_csv.length > 0}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="emailForm">
            <InfoTooltip
              content={t('inviteMail.form.emailForm.title')}
              tooltipContent={t('inviteMail.form.emailForm.tips')}
            />
          </Label>
          <Input
            id="emailForm"
            onChange={(e) => onChangeInput(InputType.EMAIL, e)}
            type="text"
            disabled={inviteMail.import_csv.length > 0}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="7">
          <Label for="uploadCSVForm">
            <InfoTooltip
              content={t('inviteMail.form.csvForm.title')}
              tooltipContent={t('inviteMail.form.csvForm.tips')}
            />
          </Label>
          <Input
            id="uploadCSVForm"
            onChange={(e) => onChangeInput(InputType.UPLOAD_CSV, e)}
            type="file"
            accept="text/csv"
          />
        </Col>
        <Col md="5">
          <h5>{t('inviteMail.form.csvTemplate')}</h5>
          <Button color="dark" onClick={() => downloadSampleCSV()}>
            {t('inviteMail.form.download')}
          </Button>
        </Col>
      </FormGroup>
      {inviteMail.import_csv.length > 0 &&
        inviteMail.import_csv.map((importCSV: IUserAndEmail, i: number) => {
          const totalCount = (): JSX.Element => {
            return (
              <Row className="mb-4">
                <Col md="10">
                  {t('inviteMail.form.totalCount', {
                    importUsers: inviteMail.import_csv.length,
                  })}
                </Col>
              </Row>
            )
          }
          return (
            <div key={i}>
              <Row className="my-2">
                <Col md="10">
                  {t('inviteMail.form.importUser', {
                    userName: importCSV.name,
                    userEmail: importCSV.email,
                  })}
                </Col>
              </Row>
              {inviteMail.import_csv.length === i + 1 && totalCount()}
            </div>
          )
        })}
      <FormGroup row>
        <Col md="10">
          <Label for="titleForm">{t('inviteMail.form.emailTitle')}</Label>
          <Input
            id="titleForm"
            defaultValue={inviteMail.title}
            onChange={(e) => onChangeInput(InputType.TITLE, e)}
            type="text"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('inviteMail.form.mailContent.title')}
              tooltipContent={t('inviteMail.form.mailContent.tips')}
            />
          </Label>
          <div>{t('inviteMail.form.mailContent.explanation')}</div>
          <div>{t('inviteMail.form.mailContent.userName')}</div>
          <div>{t('inviteMail.form.mailContent.siteName')}</div>
          <div>{t('inviteMail.form.mailContent.inviteUrl')}</div>
          <div>{t('inviteMail.form.mailContent.loginUrl')}</div>
          <pre>
            <QuillForm
              defaultValue={inviteMail.content}
              onChange={(e) => onChangeInput(InputType.CONTENT, e)}
              modules={standardModules()}
            />
          </pre>
        </Col>
      </FormGroup>
      <div className="text-center">
        <Button
          onClick={sendMail}
          className="my-4"
          color="primary"
          type="button"
        >
          {t('inviteMail.form.submitEmail')}
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
