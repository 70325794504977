import firebase from 'common/firebase'
import { ILive } from 'common/interfaces/live'
import { ITeam } from 'common/interfaces/team'
import { IVideo, VideoStatus } from 'common/interfaces/video'
import { nowTimestamp } from 'common/times'
import { removeAllCommentsInVideo } from './firebase_comment'
import { Teams } from './firebase_team'

const Videos = (team: ITeam) => Teams.doc(team.id).collection('videos')

/**
 * is show video?
 * @param data `IVideo | ILive`
 * @param userGroupIds group ids of logged in user
 * @param isOnlyForUser is video for user?
 * @param nowTime current unixtime
 */
export const isShowVideo = (
  data: IVideo | ILive,
  userGroupIds: string[],
  isOnlyForUser: boolean,
  nowTime: number
): boolean =>
  (isOnlyForUser &&
    data.status === VideoStatus.PUBLIC &&
    (data.group_ids.length === 0 ||
      data.group_ids.some((id) => userGroupIds.some((ugid) => id === ugid)))) ||
  (isOnlyForUser &&
    data.status === VideoStatus.PUBLICATION_PERIOD &&
    data.publication_period!.start.seconds <= nowTime &&
    data.publication_period!.end!.seconds >= nowTime &&
    (data.group_ids.length === 0 ||
      data.group_ids.some((id) => userGroupIds.some((ugid) => id === ugid)))) ||
  !isOnlyForUser

const getVideos = async (
  team: ITeam,
  userGroupIds: string[],
  isOnlyForUser: boolean
): Promise<IVideo[]> => {
  try {
    const videoDatas = await Videos(team).orderBy('created_at', 'desc').get()
    const nowTime = nowTimestamp().seconds
    return videoDatas.docs
      .map((video) => video.data() as IVideo)
      .filter((video) =>
        isShowVideo(video, userGroupIds, isOnlyForUser, nowTime)
      )
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get videos order by created_at
 *
 * includes secret videos
 */
export const get = async (team: ITeam, userGroupIds: string[]) =>
  getVideos(team, userGroupIds, false)

/**
 * get for user videos order by created_at
 *
 * excludes secret videos
 */
export const getForUser = async (team: ITeam, userGroupIds: string[]) =>
  getVideos(team, userGroupIds, true)

/**
 * get for non auth user videos order by created_at
 *
 * `team.is_site_public` must be true
 */
export const getForNonAuthUser = async (team: ITeam): Promise<IVideo[]> => {
  try {
    const nowTime = nowTimestamp().seconds
    const videoDatas = await Videos(team)
      .where('is_public', '==', true)
      .orderBy('created_at', 'desc')
      .get()
    return videoDatas.docs
      .map((video) => video.data() as IVideo)
      .filter(
        (v) =>
          v.status === VideoStatus.PUBLIC ||
          (v.status === VideoStatus.PUBLICATION_PERIOD &&
            v.publication_period!.start.seconds <= nowTime &&
            v.publication_period!.end!.seconds >= nowTime)
      )
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * streaming video data
 *
 * includes secret videos
 */
export const onSnapshot = (
  team: ITeam,
  onSnapshot: (videos: IVideo[]) => void
): (() => void) => {
  return Videos(team)
    .orderBy('created_at', 'desc')
    .onSnapshot((snapshot) => {
      const videos = snapshot.docs.map((video) => video.data() as IVideo)
      onSnapshot(videos)
    })
}

/**
 * add video
 * @throws Firebase error
 */
export const store = async (team: ITeam, video: IVideo): Promise<void> => {
  await Videos(team).doc(video.id).set(video)
}

/**
 * update video
 * @throws Firebase error
 */
export const update = async (team: ITeam, video: IVideo): Promise<void> => {
  video.updated_at = nowTimestamp()
  await Videos(team).doc(video.id).update(video)
}

/**
 * update video uploaded
 * @throws Firebase error
 */
export const updateUploaded = async (
  team: ITeam,
  video: IVideo,
  isUploaded: boolean
): Promise<void> => {
  await Videos(team).doc(video.id).update({ is_uploaded: isUploaded })
}

/**
 * increment video view_count
 */
export const incrementViewCount = async (
  team: ITeam,
  video: IVideo
): Promise<void> => {
  try {
    await Videos(team)
      .doc(video.id)
      .update({
        view_count: firebase.firestore.FieldValue.increment(1),
      })
  } catch (error) {
    console.log(error)
  }
}

/**
 * delete video
 * @throws Firebase error
 */
export const remove = async (team: ITeam, video: IVideo): Promise<void> => {
  await Videos(team).doc(video.id).delete()
  await removeAllCommentsInVideo(team, video)
}

/**
 * remove video image
 * @throws Firebase error
 */
export const removeImage = async (
  team: ITeam,
  video: IVideo
): Promise<void> => {
  video.image = null
  video.updated_at = nowTimestamp()
  await Videos(team).doc(video.id).update(video)
}
