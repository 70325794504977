import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { isLoggedIn, openNewTab } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedUser } from 'providers/AuthProvider'
import { update as updateUser } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

const CF_API_BASE_URL = String(process.env.REACT_APP_FIREBASE_FUNCTIONS_URI)

export const zoomOAuthStartUrl = `${CF_API_BASE_URL}/zoom/oauth`

/**
 * Save user's Zoom user id
 * @param storeCache `IStoreCache`
 * @param userId Zoom user id
 */
export const saveZoomUserId = async (
  storeCache: IStoreCache,
  userId: string
) => {
  if (!isLoggedIn(storeCache, true)) return

  const user = storeCache.user!
  user.zoom_user_id = userId
  try {
    await updateUser(storeCache.team!, user)
  } catch (e) {
    console.error(e)
    alertService.show(false, i18nAlert('zoom.failed.connect'))
  }
}

/**
 * Redirect to Zoom setting page
 */
export const redirectToZoomSettingPage = () => {
  window.location.replace(Routes.AdminSettingZoomSetting)
}

/**
 * Disconnect zoom account
 * @param storeCache `IStoreCache`
 */
export const disconnectZoomAccount = async (storeCache: IStoreCache) => {
  if (!isLoggedIn(storeCache, true)) return

  modalService.show(i18nAlert('modal.confirm.disconnectZoom'), async () => {
    const user = storeCache.user!
    user.zoom_user_id = null
    try {
      await updateUser(storeCache.team!, user)
      openNewTab('https://marketplace.zoom.us/user/installed')

      await reloadCachedUser(storeCache)

      alertService.show(true, i18nAlert('zoom.disconnected'))
    } catch (e) {
      console.error(e)
      alertService.show(false, i18nAlert('zoom.failed.disconnect'))
    }
  })
}
