import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { INews } from 'common/interfaces/news'
import { nowTimestamp } from 'common/times'
import { createGroupOptions, isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedNews } from 'providers/AuthProvider'
import { store, update } from 'repositories/store/firebase_news'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/news'
import { v4 as uuidv4 } from 'uuid'
import { initSendEmail, saveSendEmail } from './send_email_form'

/**
 * `INews`の初期値を返却する
 */
export const initNews = (): INews => ({
  id: uuidv4(),
  title: '',
  content: '',
  group_ids: [],
  created_at: nowTimestamp(),
})

/**
 * Input/Selectで使用するoptionsを返却する
 */
export const getFormOptions = (storeCache: IStoreCache) => ({
  groupOptions: createGroupOptions(storeCache.groups),
})

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  TITLE,
  CONTENT,
  GROUP_IDS,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  news: INews,
  setNews: React.Dispatch<React.SetStateAction<INews>>,
  type: InputType,
  e: any
) => {
  const val = e.target?.value
  setNews({
    ...news,
    title: type === InputType.TITLE ? val : news.title,
    content: type === InputType.CONTENT ? e : news.content,
    group_ids:
      type === InputType.GROUP_IDS
        ? e.map((x: any) => x.value)
        : news.group_ids ?? [],
  })
}

/**
 * お知らせレコード作成処理
 */
export const saveNews = async (
  history: History,
  news: INews,
  sendMail: boolean,
  storeCache: IStoreCache,
  isCreate: boolean
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    validateForm(news)

    if (isCreate) {
      news.created_at = nowTimestamp()
      await store(storeCache.team!, news)
      await sendMailToMember(history, sendMail, news, storeCache)
    } else {
      await update(storeCache.team!, news)
    }
    await reloadCachedNews(storeCache)

    history.push(Routes.AdminNews)
    alertService.show(true, i18nAlert('saved.news'))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * チーム内全ての会員にメール送信
 */
const sendMailToMember = async (
  history: History,
  sendMail: boolean,
  news: INews,
  storeCache: IStoreCache
) => {
  if (!sendMail) return

  // 送信メールドキュメントを作成
  const sendEmail = initSendEmail()
  sendEmail.title = news.title
  sendEmail.subject = news.title
  sendEmail.body = news.content
  sendEmail.group_ids = news.group_ids
  await saveSendEmail(history, sendEmail, storeCache, true, true)
}
