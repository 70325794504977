import { ILive } from 'common/interfaces/live'
import { IVideo } from 'common/interfaces/video'
import { isEventPage } from 'common/link'
import { makeEventVideoPath, makePlaylistVideoPath } from 'common/link_path'
import { isLiveVideo } from 'common/utils'
import WideContentCard from 'components/molecules/User/WideContentCard/WideContentCard'
import React from 'react'
import { Link } from 'react-router-dom'

interface INextStep {
  parentId: string | undefined
  nextContent: IVideo | ILive | null
}

const NextStep: React.FC<INextStep> = ({
  parentId,
  nextContent,
}: INextStep) => {
  if (!parentId || !nextContent) return <></>

  const link = isEventPage()
    ? makeEventVideoPath(parentId, nextContent.id, isLiveVideo(nextContent))
    : makePlaylistVideoPath(parentId, nextContent.id)

  return (
    <div className="next-step-video">
      <h2>Next Step</h2>
      <Link to={link}>
        <WideContentCard content={nextContent} />
      </Link>
    </div>
  )
}

export default NextStep
