import axios from 'axios'
import { IHubSpotFormField } from 'common/interfaces/hubspot'

/**
 * Submit a form to HubSpot
 * @param portalId HubSpot Portal ID
 * @param formGuid HubSpot Form ID
 * @param fields Form fields
 */
export const submitForm = async (
  portalId: number,
  formGuid: string,
  fields: IHubSpotFormField[]
): Promise<boolean> => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`

  const data = {
    submittedAt: Date.now(),
    fields,
  }

  try {
    await axios.post(url, data)
    return true
  } catch (error) {
    return false
  }
}
