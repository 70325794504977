import { IPrice } from 'common/interfaces/stripe/price'
import { IVideo } from 'common/interfaces/video'
import { i18nValidation } from 'i18n/i18n'

/**
 * Stripe料金マスタ登録・編集用バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param price `IPrice`  Stripe料金オブジェクト
 * @param videos `IVodeo[]`
 * @param isCreate boolean  新規登録画面なのか
 * @returns void
 */
export const validateForm = (
  { id, unit_amount, nickname, active, metadata, recurring }: IPrice,
  videos: IVideo[],
  isCreate: boolean
): void => {
  if (
    (isCreate &&
      (!unit_amount ||
        !nickname ||
        (recurring !== null && recurring.interval === undefined))) ||
    (!isCreate && !nickname)
  ) {
    throw new Error(i18nValidation('input.allFields'))
  }
  if (metadata.description && metadata.description.length > 500) {
    throw new Error(i18nValidation('input.description.fiveHundredOrLess'))
  }
  if (Number(metadata.valid_days) < 0) {
    throw new Error(i18nValidation('stripe.price.cantSetNegativeValue'))
  }
  if ([1, 2].includes(Number(metadata.trial))) {
    throw new Error(i18nValidation('stripe.price.setTrial3DaysOrMore'))
  }
  if (!isCreate && !active) {
    videos.forEach(({ price_ids }) => {
      if (price_ids.some((p) => p === id)) {
        throw new Error(
          i18nValidation('stripe.price.cantInactiveStripePriceInUsedVideo')
        )
      }
    })
  }
}
