import { Routes } from 'common/enums'
import { INews } from 'common/interfaces/news'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/NewsForm/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initNews } from 'services/admin/news_form'

const NewsForm: React.FC = () => {
  const { t } = useTranslation('adminNews')
  const { pathname, state } = useLocation<{ news: INews }>()

  const isCreate = pathname === Routes.AdminNewsCreate
  const news: INews = isCreate ? initNews() : state.news

  return (
    <>
      <H1 title={t(isCreate ? 'form.new' : 'form.edit')} />

      <Container fluid>
        <ButtonRight nextPage={Routes.AdminNews} content={t('form.back')} />
        <FormBody isCreate={isCreate} newsObj={news} />
      </Container>
    </>
  )
}

export default NewsForm
