import { IStoreCache } from 'common/interfaces/auth_provider'
import { IVideo } from 'common/interfaces/video'
import { IVideoWatchLog } from 'common/interfaces/video_watch_log'
import { nowTimestamp } from 'common/times'
import { TimeUpdateEvent } from 'components/atoms/Video/HlsVideo'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  addVideoEndedLog,
  addVideoPlayLog,
} from 'repositories/store/firebase_log'
import { incrementViewCount } from 'repositories/store/firebase_video'
import { mergeLog as mergeVideoWatchLog } from 'repositories/store/firebase_video_watch_log'
import { v4 as uuidv4 } from 'uuid'

/** Video watch log write interval */
const WATCH_LOG_WRITE_INTERVAL = 60 * 1000 // 1 min

/**
 * Video watch log hook
 * @returns `play` `pause` `ended` event handlers
 */
export const useVideoWatchLog = (
  { team, user }: IStoreCache,
  video: IVideo
) => {
  const sessionId = useMemo(() => uuidv4(), [])
  const lastLog = useRef<IVideoWatchLog>({
    id: sessionId,
    video_id: video.id,
    start: -1,
    end: -1,
    started_at: nowTimestamp(),
    ended_at: nowTimestamp(),
  })

  const onChangePlayState = useCallback(
    async (newState: 'start' | 'playing' | 'pause' | 'end') => {
      if (!window.hlsVideoCurrentTime) return
      const currentTime = Math.round(window.hlsVideoCurrentTime())

      if (
        (newState === 'start' &&
          lastLog.current.start !== -1 &&
          (lastLog.current.start < currentTime ||
            lastLog.current.start - currentTime < 5)) ||
        ((newState === 'playing' || newState === 'pause') &&
          lastLog.current.end !== -1 &&
          (lastLog.current.end > currentTime ||
            currentTime - lastLog.current.end < 5)) ||
        (newState === 'end' && lastLog.current.end === -1)
      ) {
        return
      }

      const ts = nowTimestamp()
      const newLog: IVideoWatchLog = (() => {
        if (newState === 'start' && lastLog.current.end === -1) {
          return {
            ...lastLog.current,
            start: currentTime,
            end: currentTime,
            started_at: ts,
            ended_at: ts,
          }
        }
        if (newState === 'start') {
          return {
            ...lastLog.current,
            start: currentTime,
            started_at: ts,
          }
        }
        return {
          ...lastLog.current,
          end: currentTime,
          ended_at: ts,
        }
      })()

      lastLog.current = newLog
      await mergeVideoWatchLog(team!, user!.id, lastLog.current)
    },
    [team, user]
  )

  useEffect(() => {
    const timer = setInterval(() => {
      if (!window.hlsVideoPaused || window.hlsVideoPaused()) return
      onChangePlayState('playing')
    }, WATCH_LOG_WRITE_INTERVAL)

    return () => clearInterval(timer)
  }, [onChangePlayState])

  return {
    onPlay: () => onChangePlayState('start'),
    onPause: () => onChangePlayState('pause'),
    onEnded: () => onChangePlayState('end'),
  }
}

/**
 * Video oncePlay event
 */
export const videoOncePlay = ({ team, user }: IStoreCache, video: IVideo) => {
  if (team && user) {
    incrementViewCount(team, video)
    addVideoPlayLog(team, user, video)
  }
}

/**
 * Video onTimeUpdate event
 */
export const videoOnTimeUpdate = (
  e: TimeUpdateEvent,
  isTimeOver90perState: React.MutableRefObject<boolean>,
  { team, user }: IStoreCache,
  video: IVideo
) => {
  if (e.percent > 0.9 && !isTimeOver90perState.current) {
    isTimeOver90perState.current = true
    if (team && user) {
      addVideoEndedLog(team, user, video)
    }
  }
}

/**
 * プレミア公開の動画が開始して何秒経過したか
 * @returns premiere diff seconds
 */
export const getPremiereStartOverSeconds = (video: IVideo): number | null => {
  if (!video.is_premiere || !video.playable_period) {
    return null
  }
  const now = Date.now() / 1000
  const startUnixtime = video.playable_period.from.seconds
  return now - startUnixtime
}
