export interface IFieldName {
  text: string
  textarea: string
  number: string
  date: string
  bool: string
  radio: string
  pulldown: string
  text_public_status: FieldCustomizePublicStatus
  textarea_public_status: FieldCustomizePublicStatus
  number_public_status: FieldCustomizePublicStatus
  date_public_status: FieldCustomizePublicStatus
  bool_public_status: FieldCustomizePublicStatus
  radio_public_status: FieldCustomizePublicStatus
  pulldown_public_status: FieldCustomizePublicStatus
  text_required: boolean
  textarea_required: boolean
  number_required: boolean
  date_required: boolean
  bool_required: boolean
  radio_required: boolean
  pulldown_required: boolean
}

export interface IFieldCustomize {
  type: IFieldCustomizeType
  fieldName: string
  publicStatus: FieldCustomizePublicStatus
  isRequired?: boolean
  options?: string[]
}

export enum IFieldCustomizeType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  DATE = 'date',
  BOOL = 'bool',
  RADIO = 'radio',
  PULLDOWN = 'pulldown',
}

export enum FieldCustomizePublicStatus {
  PUBLIC = 'public',
  ONLY_ADMIN = 'only_admin',
}
