import {
  ITopItemOrder,
  TopItemOrderType,
} from 'common/interfaces/top_item_order'
import ChooseGenre from 'components/molecules/User/ChooseGenre/ChooseGenre'
import SearchVideoInput from 'components/molecules/User/SearchVideoInput/SearchVideoInput'
import SideScrollGenreList from 'components/organisms/User/SideScrollGenreList/SideScrollGenreList'
import SideScrollVideoList from 'components/organisms/User/SideScrollVideoList/SideScrollVideoList'
import TopImage from 'components/organisms/User/TopImage/TopImage'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Container } from 'reactstrap'
import { GenreType } from 'services/user/sidescroll_genre_list'
import { ListType } from 'services/user/sidescroll_video_list'
import './index.scss'

interface IIndexProps {
  isCatalogPage?: boolean
}

const Index: React.FC<IIndexProps> = (props: IIndexProps) => {
  const itemOrder = useContext(AuthContext).storeCache.team?.item_order ?? []
  const isCatalogPage = props.isCatalogPage ?? false

  return (
    <div className="user-top">
      {createTopElements(itemOrder, isCatalogPage)}
    </div>
  )
}

const createTopElements = (
  itemOrder: ITopItemOrder[],
  isCatalogPage: boolean
): (JSX.Element | null)[] =>
  itemOrder.map((i, key) => parseItemOrder(i, key, isCatalogPage))

const parseItemOrder = (
  { type, targetId }: ITopItemOrder,
  key: number,
  isCatalogPage: boolean
): JSX.Element | null => {
  const wrapContainer = (el: JSX.Element) => (
    <Container key={key}>{el}</Container>
  )

  switch (type) {
    case TopItemOrderType.DIVIDER:
      return <hr key={key} />
    case TopItemOrderType.TOP_IMAGES:
      return wrapContainer(<TopImage />)
    case TopItemOrderType.CHOOSE_CONTENT_TYPE:
      return wrapContainer(<ChooseGenre type="content_type" />)
    case TopItemOrderType.CHOOSE_GENRE_CATEGORY:
      return wrapContainer(<ChooseGenre type="category" />)
    case TopItemOrderType.CHOOSE_GENRE_TAG:
      return wrapContainer(<ChooseGenre type="tag" />)
    case TopItemOrderType.CHOOSE_GENRE_CREATOR:
      return wrapContainer(<ChooseGenre type="creator" />)
    case TopItemOrderType.CHOOSE_PLAY_STATUS:
      return wrapContainer(<ChooseGenre type="playStatus" />)
    case TopItemOrderType.SEARCH_VIDEO_INPUT:
      return wrapContainer(<SearchVideoInput />)
    case TopItemOrderType.CATEGORIES_VIDEO:
      return (
        <SideScrollGenreList key={key} genreType={GenreType.CATEGORY_VIDEO} />
      )
    case TopItemOrderType.TAGS_VIDEO:
      return <SideScrollGenreList key={key} genreType={GenreType.TAG_VIDEO} />
    case TopItemOrderType.CREATORS_VIDEO:
      return (
        <SideScrollGenreList key={key} genreType={GenreType.CREATOR_VIDEO} />
      )
    case TopItemOrderType.CATEGORIES_LIVE:
      return (
        <SideScrollGenreList key={key} genreType={GenreType.CATEGORY_LIVE} />
      )
    case TopItemOrderType.TAGS_LIVE:
      return <SideScrollGenreList key={key} genreType={GenreType.TAG_LIVE} />
    case TopItemOrderType.CREATORS_LIVE:
      return (
        <SideScrollGenreList key={key} genreType={GenreType.CREATOR_LIVE} />
      )
    case TopItemOrderType.EVENTS_NEWER:
      return <SideScrollGenreList key={key} genreType={GenreType.NEW_EVENTS} />
    case TopItemOrderType.PLAYLISTS_NEWER:
      return (
        <SideScrollGenreList key={key} genreType={GenreType.NEW_PLAYLISTS} />
      )
    case TopItemOrderType.CATEGORIES_PLAYLIST:
      return (
        <SideScrollGenreList
          key={key}
          genreType={GenreType.CATEGORY_PLAYLIST}
        />
      )
    case TopItemOrderType.TAGS_PLAYLIST:
      return (
        <SideScrollGenreList key={key} genreType={GenreType.TAG_PLAYLIST} />
      )
    case TopItemOrderType.PLAYLISTS_IN_CATEGORY:
      return (
        <SideScrollGenreList
          key={key}
          genreType={GenreType.PLAYLIST_IN_CATEGORY}
          targetId={targetId}
        />
      )
    case TopItemOrderType.PLAYLISTS_IN_TAG:
      return (
        <SideScrollGenreList
          key={key}
          genreType={GenreType.PLAYLIST_IN_TAG}
          targetId={targetId}
        />
      )
    case TopItemOrderType.PLAYLISTS:
      return <SideScrollGenreList key={key} genreType={GenreType.PLAYLIST} />
  }

  const catalogListType = (type: ListType) => (!isCatalogPage ? type : null)
  const listType: ListType | null = (() => {
    switch (type) {
      case TopItemOrderType.VIDEOS_NEWER:
        return ListType.NEW_VIDEOS
      case TopItemOrderType.VIDEOS_POPULAR:
        return ListType.POPULAR_VIDEOS
      case TopItemOrderType.VIDEOS_POPULAR_IN_GENRE:
        return ListType.POPULAR_VIDEOS_IN_GENRE
      case TopItemOrderType.VIDEOS_IN_CATEGORY:
        return ListType.VIDEOS_IN_CATEGORY
      case TopItemOrderType.VIDEOS_IN_TAG:
        return ListType.VIDEOS_IN_TAG
      case TopItemOrderType.VIDEOS_IN_CREATOR:
        return ListType.VIDEOS_IN_CREATOR
      case TopItemOrderType.LIVES_NEWER:
        return ListType.NEW_LIVES
      case TopItemOrderType.LIVES_IN_CATEGORY:
        return ListType.LIVES_IN_CATEGORY
      case TopItemOrderType.LIVES_IN_TAG:
        return ListType.LIVES_IN_TAG
      case TopItemOrderType.LIVES_IN_CREATOR:
        return ListType.LIVES_IN_CREATOR
      case TopItemOrderType.VIDEOS_IN_PLAYLIST:
        return ListType.VIDEOS_IN_PLAYLIST
      case TopItemOrderType.USER_BOOKMARKS:
        return catalogListType(ListType.USER_BOOKMARKS)
      case TopItemOrderType.USER_VIEW_LOGS:
        return catalogListType(ListType.USER_VIEW_LOGS)
    }
  })()

  return listType !== null ? (
    <SideScrollVideoList key={key} listType={listType} targetId={targetId} />
  ) : null
}

export default Index
