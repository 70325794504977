import { ITaxRate } from 'common/interfaces/stripe/tax_rate'
import { i18nValidation } from 'i18n/i18n'

/**
 * Stripe税率マスタ登録・編集用バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param taxRate ITaxRate
 */
export const validateForm = ({ display_name, percentage }: ITaxRate): void => {
  if (!display_name || !percentage) {
    throw new Error(i18nValidation('input.allFields'))
  }
  if (percentage > 100) {
    throw new Error(i18nValidation('input.taxRatePercent.hundredOrLess'))
  }
}
