import {
  findCachedCategory,
  findCachedCreator,
  findCachedTag,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ContentsOrderType } from 'common/interfaces/contents_orders'
import { IPlaylist } from 'common/interfaces/playlist'
import { sortByContentsOrderType } from 'common/sort_contents'
import { showingPlaylists } from 'common/utils'
import { filterContentsByPlayStatus } from 'common/utils_log'
import { i18nUserIndex, i18nUserVideoList } from 'i18n/i18n'
import React from 'react'
import { ContentTypeListPageType, IContentTypeListParams } from './page_params'

export const getPlaylistListPageType = (
  params: IContentTypeListParams
): ContentTypeListPageType => {
  if (
    [
      params.categoryId,
      params.tagId,
      params.creatorId,
      params.playStatus,
    ].filter(Boolean).length > 0
  ) {
    return ContentTypeListPageType.COMBINE_PLAYLISTS
  }
  return ContentTypeListPageType.NEWER_PLAYLISTS
}

export const createPlaylistListTitle = (
  pageType: ContentTypeListPageType,
  storeCache: IStoreCache,
  params: IContentTypeListParams
): JSX.Element[] => {
  const titles: string[] = []

  switch (pageType) {
    case ContentTypeListPageType.NEWER_PLAYLISTS:
      titles.push(i18nUserVideoList('typeContentsList.titles.newPlaylist'))
      break
    case ContentTypeListPageType.COMBINE_PLAYLISTS:
      if (params.categoryId) {
        const category = findCachedCategory(storeCache, params.categoryId)
        titles.push(category?.name ?? '')
      }
      if (params.tagId) {
        const tag = findCachedTag(storeCache, params.tagId)
        titles.push(tag?.name ? `#${tag.name}` : '')
      }
      if (params.creatorId) {
        const creator = findCachedCreator(storeCache, params.creatorId)
        titles.push(creator?.name ?? '')
      }
      if (params.playStatus) {
        const i18n = i18nUserIndex('chooseGenre.badges.playStatus', {
          returnObjects: true,
        })
        const name = i18n[params.playStatus]
        titles.push(name)
      }
      break
  }

  return titles.map((title, index) =>
    React.createElement('span', { key: index }, title)
  )
}

export const getFilteredPlaylists = async (
  pageType: ContentTypeListPageType,
  storeCache: IStoreCache,
  params: IContentTypeListParams
): Promise<IPlaylist[]> => {
  const playlists = showingPlaylists(storeCache)
  const playlistSortType = storeCache.team!.default_contents_orders.playlist
  switch (pageType) {
    case ContentTypeListPageType.NEWER_PLAYLISTS:
      return sortByContentsOrderType(playlists, ContentsOrderType.NEWER)
    case ContentTypeListPageType.COMBINE_PLAYLISTS:
      let filtered = playlists
      if (params.categoryId) {
        filtered = filtered.filter((p) => p.category_id === params.categoryId)
      }
      if (params.tagId) {
        filtered = filtered.filter((p) => p.tag_ids.includes(params.tagId!))
      }
      if (params.creatorId) {
        filtered = []
      }
      if (params.playStatus) {
        filtered = await filterContentsByPlayStatus(
          storeCache,
          params.playStatus,
          filtered
        )
      }
      return sortByContentsOrderType(filtered, playlistSortType)
  }

  return []
}
