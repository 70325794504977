import { ColumnDef } from '@tanstack/react-table'
import { IPaymentIntent } from 'common/interfaces/stripe/payment_intent'
import { IUser } from 'common/interfaces/user'
import { formatUNIXToDate } from 'common/times'
import { formatPrice } from 'common/utils'
import { useTanstackTableWithCsvExport } from 'components/atoms/Table/CreateTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getOneTimePaidHistories } from 'services/admin/user_paid_history'

interface ITableBody {
  user: IUser
}

const useI18n = () =>
  useTranslation('adminUser').t('userOneTimePaidHistory', {
    returnObjects: true,
  })

const TableBody: React.FC<ITableBody> = ({ user: { customer_id } }) => {
  const { storeCache } = useContext(AuthContext)

  const [paymentIntents, setPaymentIntents] = useState<IPaymentIntent[]>([])

  useEffect(() => {
    getOneTimePaidHistories(storeCache, customer_id!, setPaymentIntents)
  }, [storeCache, customer_id])

  const i18n = useI18n()
  if (paymentIntents.length <= 0) return <>{i18n.noPaidHistory}</>
  return <Table paymentIntents={paymentIntents} />
}

const Table: React.FC<{ paymentIntents: IPaymentIntent[] }> = ({
  paymentIntents,
}) => {
  const i18n = useI18n().table

  const columns = useMemo<ColumnDef<IPaymentIntent>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => i + 1,
      },
      {
        header: i18n.amount,
        accessorFn: (pi) => formatPrice(pi.amount, pi.currency),
      },
      {
        header: i18n.created,
        accessorFn: (pi) => formatUNIXToDate(pi.created),
      },
      {
        header: i18n.receipt,
        accessorFn: (pi) => pi.charges.data[0].receipt_url,
        cell: (cell) => (
          <a href={cell.getValue<string>()} target="_brank">
            {cell.getValue<string>()}
          </a>
        ),
      },
      {
        header: i18n.priceID,
        accessorFn: (pi) => pi.metadata.price,
      },
    ],
    [i18n]
  )

  return useTanstackTableWithCsvExport<IPaymentIntent>(
    columns,
    paymentIntents,
    'paid_histories.csv'
  )
}

export default TableBody
