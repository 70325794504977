import Footer from 'components/molecules/Footer'
import Buttons from 'components/molecules/User/CreditCardUpdate/Buttons'
import React from 'react'

const CreditCardUpdate: React.FC = () => (
  <>
    <Buttons />
    <Footer />
  </>
)

export default CreditCardUpdate
