import firebase from 'common/firebase'
import { datetimeLocalFormat } from 'common/times'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, FormGroup, Input, Label } from 'reactstrap'

type TFromToDateTime = {
  fromLabel?: string
  toLabel?: string
  isDisabledFrom?: boolean
  isDisabledTo?: boolean
  from: firebase.firestore.Timestamp
  to: firebase.firestore.Timestamp
  onChange: (e: React.ChangeEvent<HTMLInputElement>, isFrom: boolean) => void
}

const FromToDateTime: React.FC<TFromToDateTime> = ({
  fromLabel,
  toLabel,
  isDisabledFrom,
  isDisabledTo,
  from,
  to,
  onChange,
}: TFromToDateTime) => {
  const { t } = useTranslation('adminCommon')

  return (
    <FormGroup row>
      <Col md="5">
        <Label>{fromLabel ?? t('fromToDateTime.startTime')}</Label>
        <Input
          defaultValue={datetimeLocalFormat(from)}
          disabled={isDisabledFrom}
          onChange={(e) => onChange(e, true)}
          type="datetime-local"
        />
      </Col>
      <Col md="5">
        <Label>{toLabel ?? t('fromToDateTime.endTime')}</Label>
        <Input
          defaultValue={datetimeLocalFormat(to)}
          disabled={isDisabledTo}
          onChange={(e) => onChange(e, false)}
          type="datetime-local"
        />
      </Col>
    </FormGroup>
  )
}

export default FromToDateTime
