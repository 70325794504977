import { IStripe } from 'common/interfaces/stripe'
import { canCreateFilelist } from 'common/utils'
import FileInfo from 'components/atoms/Dropzone/FileInfo'
import ButtonLoading from 'components/atoms/Loading/ButtonLoading'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { DropzoneRootProps, useDropzone } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Button, Col, Form, FormGroup, Label } from 'reactstrap'
import {
  createPDFFile,
  dropzoneStyle,
  filelistSizeOverText,
} from 'services/admin/filelist_create'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'

const FormBody: React.FC = () => {
  const { t } = useTranslation('adminFilelist')
  const { t: i18nAdminFilelist } = useTranslation('adminFilelist')
  const { storeCache } = useContext(AuthContext)
  const [pdfFile, setPDFFile] = useState<File>()
  const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [stripe, setStripe] = useState<IStripe>(initStripe())

  const history: History = useHistory()

  useEffect(() => {
    ;(async () => {
      await getStripe(setStripe)
      setIsLoading(false)
    })()
  }, [storeCache])

  const onDrop = (acceptedFiles: File[]) =>
    acceptedFiles[0] && setPDFFile(acceptedFiles[0])
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ onDrop })

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>): void | null =>
    e.target.files && setPDFFile(e.target.files[0])
  const uploadFile = useMemo(() => {
    const file: File | undefined = acceptedFiles[0]
    if (file !== undefined) {
      return <FileInfo fileName={file.name} fileSize={file.size} />
    }
  }, [acceptedFiles])

  const { baseStyle, borderNormalStyle, borderDragStyle } = dropzoneStyle
  const style: DropzoneRootProps = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? borderDragStyle : borderNormalStyle),
    }),
    [isDragActive, baseStyle, borderDragStyle, borderNormalStyle]
  )

  const create = () =>
    createPDFFile(history, pdfFile, setIsCreateLoading, storeCache, stripe)

  if (isLoading) return <LoadingSpinner />

  if (!canCreateFilelist(storeCache, stripe)) {
    return (
      <>
        <h1>{i18nAdminFilelist('form.sizeOver.title')}</h1>
        <h2>{filelistSizeOverText(storeCache, stripe)}</h2>
      </>
    )
  }

  return (
    <Form>
      <FormGroup row>
        <Col md="6">
          <Label for="fileForm">
            <InfoTooltip
              content={t('form.filelistForm.title')}
              tooltipContent={t('form.filelistForm.tips')}
              required
            />
          </Label>
          <div {...getRootProps({ style })}>
            <input
              {...getInputProps({
                id: 'fileForm',
                multiple: false,
                onChange: onChangeFile,
              })}
            />
            <p>
              <Trans t={t} i18nKey="form.filelistForm.placeholder" />
            </p>
          </div>
          {uploadFile}
        </Col>
      </FormGroup>

      <div className="text-center">
        {isCreateLoading ? (
          <ButtonLoading className="my-4" />
        ) : (
          <Button
            onClick={create}
            className="my-4"
            color="primary"
            type="button"
          >
            {t('form.save')}
          </Button>
        )}
      </div>
    </Form>
  )
}

export default FormBody
