import { makeEventPath, makePlaylistPath } from 'common/link_path'

/**
 * PageType
 */
export enum PageType {
  EVENT,
  PLAYLIST,
}

/**
 * 開かれてるPageTypeを返却
 */
export const pageType = (targetId: string): PageType => {
  switch (window.location.pathname) {
    case makeEventPath(targetId):
      return PageType.EVENT
    case makePlaylistPath(targetId):
      return PageType.PLAYLIST
  }
  return PageType.EVENT
}
