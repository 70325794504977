import { Routes } from 'common/enums'
import React from 'react'
import { useHistory } from 'react-router'

const Top: React.FC = () => {
  const { replace } = useHistory()
  replace(Routes.LoginAdmin)

  return <></>
}

export default Top
