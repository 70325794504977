import { REGEXP_FORBID_FULLWIDTH } from 'common/const'
import { i18nValidation } from 'i18n/i18n'

/**
 * 新しいパスワード用バリデーション
 * 前提としてtryの中で使用すること
 */
export const validateFormForNewPassword = (
  password: string,
  confirmPassword: string
): void => {
  if (password !== confirmPassword) {
    throw new Error(i18nValidation('notMatch.password'))
  }
}

/**
 * 会員新規登録用バリデーション
 * 前提としてtryの中で使用すること
 */
export const validateFormForCreateUser = (
  id: string,
  password: string
): void => {
  if (!id || !password) throw new Error(i18nValidation('input.allFields'))
  if (REGEXP_FORBID_FULLWIDTH.test(id)) {
    throw new Error(i18nValidation('input.id.halfWidth'))
  }
  if (REGEXP_FORBID_FULLWIDTH.test(password)) {
    throw new Error(i18nValidation('input.password.halfWidth'))
  }
}
