import { Routes } from 'common/enums'
import { ITest } from 'common/interfaces/test'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/TestForm/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'

const TestForm: React.FC = () => {
  const { t } = useTranslation('adminTest')
  const history = useHistory()
  const { pathname, state } = useLocation<{ test: ITest }>()
  const isCreate = pathname === Routes.AdminTestCreate
  const test = isCreate ? ({} as ITest) : state.test

  return (
    <>
      <H1 title={isCreate ? t('form.new') : t('form.edit')} />

      <Container fluid>
        <ButtonRight nextPage={Routes.AdminTest} content={t('form.back')} />
        <FormBody isCreate={isCreate} testObj={test} history={history} />
      </Container>
    </>
  )
}

export default TestForm
