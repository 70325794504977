import { IVideo } from './video'

export enum MultiVideoUpdateType {
  ADD_TAGS = 'addTags',
  CHANGE_CATEGORY = 'changeCategory',
  CHANGE_VIDEO_STATUS = 'changeVideoStatus',
  ADD_PUBLISH_GROUP = 'addPublishGroups',
  CHANGE_CREATOR = 'changeCreator',
}

export interface ValidationModal {
  open: boolean
  invalidVideos: IVideo[]
  error: 'publicVideoHasNoGroups' | null
}
