import { ColumnDef } from '@tanstack/react-table'
import { IComment } from 'common/interfaces/comment'
import { IReplyComment } from 'common/interfaces/reply_comment'
import { IUser } from 'common/interfaces/user'
import { datetimeFormat } from 'common/times'
import { isAuthMethodEmail } from 'common/utils'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getUsers, getVideoReplyComments } from 'services/admin/comment_reply'

interface ICommentReplyProps {
  comment: IComment
  videoId: string
}

const CommentReply: React.FC<ICommentReplyProps> = (props) => {
  const { t } = useTranslation('adminComment')
  const { storeCache } = useContext(AuthContext)

  const [replyComments, setReplyComments] = useState<IReplyComment[]>([])
  const [users, setUsers] = useState<IUser[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getUsers(storeCache, setUsers)
  }, [storeCache])

  useEffect(() => {
    ;(async () => {
      await getVideoReplyComments(
        storeCache,
        props.comment,
        props.videoId,
        setReplyComments
      )
      setIsLoading(false)
    })()
  }, [storeCache, props])

  if (isLoading) return <LoadingSpinner />

  return (
    <div className="mt-4">
      {replyComments.length <= 0 && (
        <div className="mt-5">{t('noComment')}</div>
      )}
      {replyComments.length > 0 && (
        <Table replyComments={replyComments} users={users} />
      )}
    </div>
  )
}

const Table: React.FC<{
  replyComments: IReplyComment[]
  users: IUser[]
}> = ({ replyComments, users }) => {
  const { t } = useTranslation('adminComment')
  const { storeCache } = useContext(AuthContext)

  const isAuthEmail = isAuthMethodEmail(storeCache.team!)

  const columns = useMemo<ColumnDef<IReplyComment>[]>(
    () => [
      {
        header: t('table.email'),
        accessorFn: (r) => users.find((u) => u.id === r.user_id)?.email ?? '',
        meta: { hidden: !isAuthEmail },
      },
      {
        header: t('table.name'),
        accessorFn: (r) => users.find((u) => u.id === r.user_id)?.name ?? '',
      },
      {
        header: t('table.comment'),
        accessorKey: 'content',
      },
      {
        header: t('table.createdAt'),
        accessorFn: (r) => datetimeFormat(r.created_at),
      },
    ],
    [isAuthEmail, users, t]
  )

  return useTanstackTable<IReplyComment>(columns, replyComments)
}

export default CommentReply
