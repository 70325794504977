import { CurrencyType, PriceIntervalType } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IPrice } from 'common/interfaces/stripe/price'
import { IProduct } from 'common/interfaces/stripe/product'
import { ITeam } from 'common/interfaces/team'
import { formatInterval, formatPrice, notOnKeyDownHyphen } from 'common/utils'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { History } from 'history'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import {
  InputMetadataType,
  InputType,
  isSubscriptionPrice,
  onChangeInput as serviceOnChangeInput,
  onChangeIntervalInput as serviceOnChangeIntervalInput,
  onChangeMetadataInput as serviceOnChangeMetadataInput,
  onChangeRecurringInput as serviceOnChangeRecurringInput,
  saveStripePrice,
} from 'services/admin/price_form'

type TFormBody = {
  isCreate: boolean
  storeCache: IStoreCache
  team: ITeam
  product: IProduct
  priceObj: IPrice
  history: History
}

const FormBody: React.FC<TFormBody> = ({
  isCreate,
  storeCache,
  team,
  priceObj,
  product,
  history,
}: TFormBody) => {
  const { t } = useTranslation('adminStripePrice')

  const [price, setPrice] = useState<IPrice>(priceObj)
  const [validDaysCheck, setValidDaysCheck] = useState<boolean>(
    price.metadata.valid_days ? price.metadata.valid_days > 0 : false
  )
  const [trialCheck, setTrialCheck] = useState<boolean>(
    price.metadata.trial ? price.metadata.trial > 0 : false
  )

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(price, setPrice, type, e)

  const onChangeRecurringInput = (
    isRecurring: boolean,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeRecurringInput(price, setPrice, isRecurring, e)

  const onChangeIntervalInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeIntervalInput(price, setPrice, e)

  const onChangeMetadataInput = (
    type: InputMetadataType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeMetadataInput(price, setPrice, type, e)

  const save = () =>
    saveStripePrice(history, product, price, storeCache, isCreate)

  return (
    <Form>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.unitAmount.title')}
              tooltipContent={t('form.unitAmount.tips')}
              required
            />
            {unitAmountInput(price, isCreate, (e) =>
              onChangeInput(InputType.UNIT_AMOUNT, e)
            )}
          </Label>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.nickname.title')}
              tooltipContent={t('form.nickname.tips')}
              required
            />
          </Label>
          <Input
            onChange={(e) => onChangeInput(InputType.NICKNAME, e)}
            type="text"
            defaultValue={isCreate ? '' : price.nickname}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.description.title')}
              tooltipContent={t('form.description.tips')}
            />
          </Label>
          <Input
            onChange={(e) =>
              onChangeMetadataInput(InputMetadataType.DESCRIPTION, e)
            }
            type="textarea"
            maxLength={500}
            defaultValue={isCreate ? '' : price.metadata.description}
          />
        </Col>
      </FormGroup>
      <FormGroup row check>
        <Row>
          <Col md="10">
            <Label>
              <InfoTooltip
                content={t('form.recurring.title')}
                tooltipContent={t('form.recurring.tips')}
              />
            </Label>
          </Col>
        </Row>
        <div className="custom-control custom-control-alternative custom-radio mb-3">
          <input
            className="custom-control-input"
            type="radio"
            defaultChecked={!isSubscriptionPrice(product, team)}
            onChange={(e) => onChangeRecurringInput(false, e)}
            disabled
          />
          <Label className="custom-control-label">{t('oneTime')}</Label>
        </div>
        <div className="custom-control custom-control-alternative custom-radio mb-3">
          <input
            className="custom-control-input"
            type="radio"
            defaultChecked={isSubscriptionPrice(product, team)}
            onChange={(e) => onChangeRecurringInput(true, e)}
            disabled
          />
          <Label className="custom-control-label">{t('subscription')}</Label>
        </div>
      </FormGroup>
      {price.recurring !== null && (
        <FormGroup row>
          <Col md="10">
            <Label>
              <InfoTooltip
                content={t('form.interval.title')}
                tooltipContent={t('form.interval.tips')}
              />
            </Label>
            <Input
              onChange={onChangeIntervalInput}
              type="select"
              defaultValue={price.recurring ? price.recurring!.interval : ''}
              disabled={!isCreate}
            >
              <option value={PriceIntervalType.DAY}>
                {formatInterval(PriceIntervalType.DAY)}
              </option>
              <option value={PriceIntervalType.WEEK}>
                {formatInterval(PriceIntervalType.WEEK)}
              </option>
              <option value={PriceIntervalType.MONTH}>
                {formatInterval(PriceIntervalType.MONTH)}
              </option>
              <option value={PriceIntervalType.YEAR}>
                {formatInterval(PriceIntervalType.YEAR)}
              </option>
            </Input>
          </Col>
        </FormGroup>
      )}
      <FormGroup>
        <Label>
          <InfoTooltip
            content={t('form.active.title')}
            tooltipContent={t('form.active.tips')}
          />
        </Label>
        <Row>
          <Col md="10">
            <label className="custom-toggle">
              <input
                type="checkbox"
                onChange={(e) => onChangeInput(InputType.ACTIVE, e)}
                defaultChecked={price.active}
              />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
          </Col>
        </Row>
      </FormGroup>
      {team.stripe.subscription_product_id !== product.id ? (
        <CheckAvailableForm
          disabled={!isCreate}
          title={t('form.checkAvailable.validDaysTitle')}
          check={validDaysCheck}
          setCheck={setValidDaysCheck}
          value={price.metadata.valid_days}
          onChange={(e) =>
            onChangeMetadataInput(InputMetadataType.VALID_DAYS, e)
          }
        />
      ) : (
        <CheckAvailableForm
          disabled={false}
          title={t('form.checkAvailable.trialTitle')}
          check={trialCheck}
          setCheck={setTrialCheck}
          value={price.metadata.trial}
          onChange={(e) => onChangeMetadataInput(InputMetadataType.TRIAL, e)}
        />
      )}
      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {t('form.save')}
        </Button>
      </div>
    </Form>
  )
}

const CheckAvailableForm: React.FC<{
  disabled: boolean
  title: string
  check: boolean
  setCheck: (value: React.SetStateAction<boolean>) => void
  value: number | undefined
  onChange: React.ChangeEventHandler<HTMLInputElement>
}> = ({ disabled, title, check, setCheck, value, onChange }) => {
  const { t } = useTranslation('adminStripePrice')
  return (
    <>
      <FormGroup check row>
        <Col md="10">
          <Label check>
            <Input
              defaultChecked={check}
              onChange={({ target }) => setCheck(target.checked)}
              type="checkbox"
              disabled={disabled}
            />
            {t('form.checkAvailable.tips', { title })}
          </Label>
        </Col>
      </FormGroup>
      {check && (
        <FormGroup row className="mt-3">
          <Col md="10">
            <Label>
              <InfoTooltip
                content={title}
                tooltipContent={t('form.checkAvailable.tips2', { title })}
              />
            </Label>
            <InputGroup>
              <Input
                onChange={onChange}
                value={value}
                type="number"
                min={0}
                disabled={disabled}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>{t('form.checkAvailable.day')}</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
      )}
    </>
  )
}

const unitAmountInput = (
  { currency, unit_amount }: IPrice,
  isCreate: boolean,
  onChange: React.ChangeEventHandler<HTMLInputElement>
) => {
  const input = (
    <Input
      onChange={onChange}
      type={isCreate ? 'number' : 'text'}
      defaultValue={isCreate ? '' : formatPrice(unit_amount, currency)}
      disabled={!isCreate}
      min="0"
      onKeyDown={notOnKeyDownHyphen}
    />
  )

  if (isCreate) {
    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            {currency === CurrencyType.JPY ? '￥' : '$'}
          </InputGroupText>
        </InputGroupAddon>
        {input}
      </InputGroup>
    )
  }
  return input
}

export default FormBody
