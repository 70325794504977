import { Stripe, StripeElements } from '@stripe/stripe-js'
import { InvoiceActionType } from 'common/enums'
import { IInvoice } from 'common/interfaces/stripe/Invoice'
import { ICoupon } from 'common/interfaces/stripe/coupon'
import { IPaymentIntent } from 'common/interfaces/stripe/payment_intent'
import { IProductInfo } from 'common/interfaces/stripe/product_price'
import Item from 'components/atoms/Item/Item'
import PaymentFormModal from 'components/molecules/PaymentFormModal/PaymentFormModal'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Button, Collapse } from 'reactstrap'
import {
  changePriceForTeam,
  createInvoicePaymentForTeam,
  getClientSecret,
  getPreviewUpcoming,
  init,
  resendInvoiceForTeam,
  handleSubmit as serviceHandleSubmit,
  selectedProductInfo as serviceSelectedProductInfo,
} from 'services/admin/item_select'
import { IInvoicePayment } from 'services/invoice_payment_modal'
import InvoicePaymentModal from './InvoicePaymentModal'
import PreviewUpcomingModal from './PreviewUpcomingModal'

type TList = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const List: React.FC<TList> = (props) => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const { purchasedPriceId } =
    useParams<{ purchasedPriceId: string | undefined }>()

  const [productInfos, setProductInfos] = useState<IProductInfo[]>([])
  const [isHiddenOpen, setIsHiddenOpen] = useState<boolean>(false)
  const [couponId, setCouponId] = useState<string | undefined>(undefined)
  const [coupons, setCoupons] = useState<ICoupon[]>([])
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null)
  const [clientSecret, setClientSecret] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [isOpenPreviewUpcoming, setIsOpenPreviewUpcoming] =
    useState<boolean>(false)
  const [paymentInvoice, setPaymentInvoice] = useState<IInvoice | null>(null)
  const [previewUpcomingInvoice, setPreviewUpcomingInvoice] =
    useState<IInvoice | null>(null)
  const [isOpenInvoicePayment, setIsOpenInvoicePayment] =
    useState<boolean>(false)
  const [selectProductInfo, setSlectProductInfo] =
    useState<IProductInfo | null>(null)
  const [actionType, setActionType] = useState<InvoiceActionType>(
    InvoiceActionType.DEFAULT
  )
  const [createdPaymentIntent, setCreatedPaymentIntent] =
    useState<IPaymentIntent | null>(null)

  useEffect(() => {
    init(storeCache, setProductInfos, setCoupons, setStripePromise)
  }, [storeCache])

  const changeIsOpenInvoicePayment = () => {
    setIsOpenInvoicePayment(!isOpenInvoicePayment)
    setActionType(InvoiceActionType.DEFAULT)
  }

  const selectedProductInfo = (
    selectProductInfo: IProductInfo,
    couponId: string | undefined
  ) =>
    serviceSelectedProductInfo(
      storeCache,
      selectProductInfo,
      coupons,
      couponId,
      setIsOpenInvoicePayment,
      setSlectProductInfo,
      setCouponId
    )

  const createPaymentForm = (
    selectProductInfo: IProductInfo,
    couponId: string | undefined
  ) =>
    getClientSecret(
      storeCache,
      selectProductInfo,
      coupons,
      couponId,
      setPaymentInvoice,
      setClientSecret,
      setIsOpen
    )

  const onSubmit = (stripe: Stripe | null, elements: StripeElements | null) =>
    serviceHandleSubmit(stripe, elements, storeCache, setButtonLoading)

  const previewUpcoming = (selectedPriceId: string) =>
    getPreviewUpcoming(
      storeCache,
      selectedPriceId,
      setIsOpenPreviewUpcoming,
      setPreviewUpcomingInvoice
    )

  const changePrice = (selectedPriceId: string) =>
    changePriceForTeam(storeCache, history, selectedPriceId, props.setLoading)

  const createInvoicePayment = (
    selectedProductInfo: IProductInfo,
    invoicePayment: IInvoicePayment,
    couponId: string | undefined
  ) =>
    createInvoicePaymentForTeam(
      storeCache,
      selectedProductInfo,
      invoicePayment,
      couponId,
      props.setLoading,
      setActionType,
      setCreatedPaymentIntent
    )

  const resendInvoice = (invoicePayment: IInvoicePayment) =>
    resendInvoiceForTeam(
      storeCache,
      invoicePayment,
      createdPaymentIntent,
      props.setLoading
    )

  const Items = (hieddenOpen: boolean) =>
    productInfos &&
    productInfos.map((productInfo: IProductInfo, key: number) => {
      if (
        (!hieddenOpen && productInfo.hidden) ||
        (hieddenOpen && !productInfo.hidden)
      ) {
        return <></>
      }

      return (
        <Item
          team={storeCache.team}
          user={storeCache.user}
          subscriptionObj={storeCache.subscriptionObj}
          productInfo={productInfo}
          coupons={coupons}
          purchasedPriceId={purchasedPriceId ?? ''}
          transitionToAuth={() => undefined}
          checkoutSession={(couponId: string | undefined) =>
            createPaymentForm(productInfo, couponId)
          }
          changePrice={() => previewUpcoming(productInfo.id)}
          createInvoicePayment={(couponId: string | undefined) =>
            selectedProductInfo(productInfo, couponId)
          }
          key={key}
        />
      )
    })

  return (
    <>
      {Items(false)}
      <div className="text-center mb-3">
        <Button color="default" onClick={() => setIsHiddenOpen(!isHiddenOpen)}>
          さらに表示
        </Button>
      </div>
      <Collapse isOpen={isHiddenOpen}>{Items(true)}</Collapse>

      <PaymentFormModal
        stripe={stripePromise}
        invoice={paymentInvoice}
        clientSecret={clientSecret}
        isOpen={isOpen}
        buttonLoading={buttonLoading}
        onToggleModal={() => setIsOpen(!isOpen)}
        onClick={(stripe, card) => onSubmit(stripe, card)}
      />
      <PreviewUpcomingModal
        invoice={previewUpcomingInvoice}
        isOpen={isOpenPreviewUpcoming}
        onToggleModal={() => setIsOpenPreviewUpcoming(!isOpenPreviewUpcoming)}
        onClick={(selectedPriceId) => changePrice(selectedPriceId)}
      />
      <InvoicePaymentModal
        selectProductInfo={selectProductInfo}
        isOpen={isOpenInvoicePayment}
        actionType={actionType}
        couponId={couponId}
        onToggleModal={changeIsOpenInvoicePayment}
        onClick={createInvoicePayment}
        resendInvoice={(invoicePayment) => resendInvoice(invoicePayment)}
      />
    </>
  )
}

export default List
