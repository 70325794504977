import { makeSearchPath } from 'common/link_path'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import './searchvideoinput.scss'

const SearchVideoInput: React.FC = () => {
  const { t } = useTranslation('userIndex')
  const history = useHistory()
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    if (e.key === 'Enter' && value.trim() !== '') {
      history.push(makeSearchPath(value))
    }
  }

  return (
    <InputGroup className="search-video-input">
      <InputGroupAddon addonType="prepend">
        <InputGroupText className="search-video-input__icon">
          <i className="fas fa-search" />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        className="search-video-input__input"
        placeholder={t('searchVideo')}
        onKeyPress={onKeyPress}
      />
    </InputGroup>
  )
}

export default SearchVideoInput
