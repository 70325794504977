import { InvoiceActionType, PriceType } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ILive } from 'common/interfaces/live'
import { IPaymentIntent } from 'common/interfaces/stripe/payment_intent'
import { IProductInfo } from 'common/interfaces/stripe/product_price'
import { IVideo, IVideoPurchaseLead } from 'common/interfaces/video'
import { addedUnixTime } from 'common/times'
import { isLoggedIn, stripeAPIError, transitionAuth } from 'common/utils'
import { History } from 'history'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { reloadCachedUser } from 'providers/AuthProvider'
import {
  cancelPaymentIntent,
  createInvoicePayment,
  fetchProductInfoByPriceIds,
  hasIncompletePaymentIntent,
  sendInvoice,
} from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'
import { IInvoicePayment } from './invoice_payment_modal'

export interface IListLocation {
  isCatalogPage: boolean
  content: IVideo | ILive
  redirectPath: string
}

/**
 * 料金IDから必要なデータを取得し、Stateに格納する
 */
export const getProductInfoByPriceIds = async (
  isCatalogPage: boolean,
  storeCache: IStoreCache,
  priceIds: string[],
  setData: React.Dispatch<React.SetStateAction<IProductInfo[]>>
): Promise<void> => {
  if (!isCatalogPage && !isLoggedIn(storeCache)) return

  try {
    setData(
      await fetchProductInfoByPriceIds(
        storeCache.team!.stripe.account_id,
        priceIds
      )
    )
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * stripeで請求書払いを作成する（会員専用）
 *
 * @param storeCache `IStoreCache`
 * @param history History
 * @param setLoading `React.Dispatch<React.SetStateAction<boolean>>`
 */
export const createInvoicePaymentForUser = async (
  isCatalogPage: boolean,
  storeCache: IStoreCache,
  history: History,
  productInfo: IProductInfo,
  invoicePayment: IInvoicePayment,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setActionType: React.Dispatch<React.SetStateAction<InvoiceActionType>>,
  setCreatedPaymentIntent: React.Dispatch<
    React.SetStateAction<IPaymentIntent | null>
  >,
  state: IVideoPurchaseLead
): Promise<void> => {
  const { team, user } = storeCache
  if (isCatalogPage) {
    await transitionAuth(history, team!, productInfo, state)
    return
  }
  if (!isLoggedIn(storeCache)) return

  try {
    if (
      productInfo.type === PriceType.RECURRING &&
      (!invoicePayment.name || !invoicePayment.email)
    ) {
      throw new Error(i18nValidation('input.invoicePayment'))
    }
    if (user!.customer_id) {
      const paymentIntent = await hasIncompletePaymentIntent(
        team!.stripe.account_id,
        user!.customer_id
      )
      if (paymentIntent) {
        if (paymentIntent.metadata.price === productInfo.id) {
          setActionType(InvoiceActionType.RESEND)
        } else {
          setActionType(InvoiceActionType.CANCEL)
        }

        setCreatedPaymentIntent(paymentIntent)
        return
      }
    }

    setLoading(true)

    await createInvoicePayment(
      team!.stripe.account_id,
      false,
      team!.id,
      user!.customer_id,
      user!,
      invoicePayment,
      productInfo.id,
      productInfo.currency,
      productInfo.unit_amount_wit_tax,
      state.couponId,
      productInfo.tax_rate.id,
      productInfo.type,
      productInfo.valid_days,
      !productInfo.trial ? undefined : addedUnixTime(productInfo.trial + 1, 'd') // Element化したら、無料期間の追加１日は取り消すこと！！！！！！！！！！！！！！！！！！！！！！
    )
    await reloadCachedUser(storeCache)

    if (state.redirectPath) history.replace(state.redirectPath)

    alertService.show(true, i18nAlert('sentInvoice'))
  } catch (error: any) {
    stripeAPIError(error)
  }
  setLoading(false)
}

/**
 * 請求書再送処理（会員専用）
 */
export const resendInvoice = async (
  storeCache: IStoreCache,
  invoicePayment: IInvoicePayment,
  createdPaymentIntent: IPaymentIntent | null,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache) || !createdPaymentIntent) return

  setLoading(true)
  try {
    const { team, user } = storeCache
    const { id, invoice } = createdPaymentIntent

    await sendInvoice(
      team!.stripe.account_id,
      user!.id,
      user!.customer_id ?? '',
      invoicePayment,
      id,
      invoice
    )

    alertService.show(true, i18nAlert('sentInvoice'))
  } catch (error) {
    stripeAPIError(error)
  }
  setLoading(false)
}

/**
 * 支払いキャンセル処理（会員専用）
 */
export const cancelPayment = async (
  storeCache: IStoreCache,
  { replace }: History,
  invoicePayment: IInvoicePayment,
  createdPaymentIntent: IPaymentIntent | null,
  {
    id,
    tax_rate,
    type,
    currency,
    valid_days,
    trial,
    unit_amount_wit_tax,
  }: IProductInfo,
  couponId: string | undefined,
  redirectPath: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache) || !createdPaymentIntent) {
    return
  }

  setLoading(true)
  try {
    const { team, user } = storeCache

    if (createdPaymentIntent.invoice) {
      throw new Error(i18nAlert('subscription.cantCancel'))
    }

    await cancelPaymentIntent(
      team!.stripe.account_id,
      createdPaymentIntent.id,
      createdPaymentIntent.invoice,
      user!.customer_id,
      invoicePayment,
      id,
      currency,
      unit_amount_wit_tax,
      couponId,
      tax_rate.id,
      type,
      valid_days,
      !trial ? undefined : addedUnixTime(trial + 1, 'd')
    )

    alertService.show(
      true,
      i18nAlert('subscription.sentInvoice.afterCancelIssuedInvoice')
    )
    if (redirectPath) replace(redirectPath)
  } catch (error) {
    stripeAPIError(error)
  }
  setLoading(false)
}
