import { EntryType, Routes, ViewType } from 'common/enums'
import { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { convertCatalogIntoNormal, teamUid } from 'common/link'
import {
  makeAnswerToEnquetePath,
  makeEntryPath,
  makeItemSelectPath,
  makeLoginTeamPath,
} from 'common/link_path'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import Footer from 'components/molecules/Footer'
import Header from 'components/molecules/Header/Header'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router'
import {
  cancelledTeam,
  checkDisabled,
  disabledUser,
  restrictedIp,
  restrictedSingleLogin,
} from 'services/auth'
import { isNotPaidUser, isNotTrialAndNotSubscribe } from 'services/subscribe'
import { calcUserColorCssProperties } from 'services/user/colors'
import ChangePrice from 'template/user/ChangePrice'
import CreatorDetail from 'template/user/CreatorDetail'
import Genres from 'template/user/Genres'
import Index from 'template/user/Index'
import LiveDetail from 'template/user/LiveDetail'
import MyVideoList from 'template/user/MyVideoList'
import News from 'template/user/News'
import PaidHistory from 'template/user/PaidHistory'
import PlaylistMenu from 'template/user/PlaylistMenu'
import Setting from 'template/user/Setting'
import TypeContents from 'template/user/TypeContents'
import VideoDetail from 'template/user/VideoDetail'
import './user.css'

const User: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const [viewType, setViewType] = useState<ViewType>(ViewType.LOADING)

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (user === null) {
        setViewType(ViewType.LOGIN)
      } else if (isLoading(storeCache)) {
        setViewType(ViewType.LOADING)
      } else if (checkDisabled(storeCache)) {
        setViewType(ViewType.DISABLED_USER)
      } else if (!storeCache.isSingleLogin) {
        setViewType(ViewType.RESTRICTED_SINGLE_LOGIN)
      } else if (!storeCache.isIpAllowed) {
        setViewType(ViewType.RESTRICTED_IP)
      } else if (isNotTrialAndNotSubscribe(storeCache)) {
        storeCache.user?.admin
          ? setViewType(ViewType.NOTSUBSCRIBE)
          : setViewType(ViewType.ENTRY)
      } else if (isNotPaidUser(storeCache)) {
        storeCache.user?.customer_id
          ? setViewType(ViewType.UPDATE_FAILURE)
          : setViewType(ViewType.NOTPAID)
      } else if (storeCache.user?.is_approved === true) {
        setViewType(ViewType.UNAPPROVED)
      } else if (storeCache.user?.is_password_changeable) {
        setViewType(ViewType.CHANGE_PASSWORD)
      } else if (storeCache.team?.cancelled_date) {
        setViewType(ViewType.CANCELLED)
      } else if (
        !storeCache.user?.admin &&
        storeCache.team?.enquete_id &&
        !storeCache.isAnswered
      ) {
        setViewType(ViewType.ANSWER_TO_ENQUETE)
      } else {
        setViewType(ViewType.VISIBLE)
      }
    })
  }, [storeCache])

  const history = useHistory()

  switch (viewType) {
    case ViewType.LOGIN:
      history.replace(makeLoginTeamPath(teamUid()), {
        redirectPath: convertCatalogIntoNormal(),
      })
      return null
    case ViewType.LOADING:
    case ViewType.GONE:
      return <FullLoadingSpinner />
    case ViewType.DISABLED_USER:
      disabledUser(history, storeCache)
      return null
    case ViewType.RESTRICTED_SINGLE_LOGIN:
      restrictedSingleLogin(history, storeCache)
      return null
    case ViewType.RESTRICTED_IP:
      restrictedIp(history, storeCache)
      return null
    case ViewType.NOTSUBSCRIBE: {
      const path = makeEntryPath(storeCache.team?.id, storeCache.user?.admin)
      history.replace(path, { type: EntryType.NOT_SUBSCRIBE })
      return null
    }
    case ViewType.NOTPAID:
      history.replace(makeItemSelectPath(storeCache.team!.id))
      return null
    case ViewType.UPDATE_FAILURE:
      history.replace(Routes.CreditCardUpdate)
      return null
    case ViewType.UNAPPROVED:
      history.replace(Routes.Unapproved)
      return null
    case ViewType.CANCELLED:
      cancelledTeam(history, storeCache)
      return null
    case ViewType.ANSWER_TO_ENQUETE: {
      const path = makeAnswerToEnquetePath(storeCache.team!.id)
      history.replace(path)
      return null
    }
    case ViewType.CHANGE_PASSWORD:
      history.replace(Routes.ChangePassword)
      return null
    case ViewType.ENTRY: {
      const path = makeEntryPath(storeCache.team?.id, storeCache.user?.admin)
      history.replace(path, { type: EntryType.NOT_TEAM })
      return null
    }
    case ViewType.VISIBLE:
      return userRouteSwitch(storeCache)
  }
}

const isLoading = (storeCache: IStoreCache) =>
  storeCache.user === null || storeCache.isLoading

const userRouteSwitch = (storeCache: IStoreCache): JSX.Element => (
  <div style={calcUserColorCssProperties(storeCache.team)}>
    <Header />
    <Switch>
      <Route path={Routes.User} component={() => <Index />} exact />
      <Route path={Routes.UserNews} component={() => <News />} exact />
      <Route path={Routes.UserSetting} component={() => <Setting />} exact />
      <Route
        path={Routes.UserPaidHistory}
        component={() => <PaidHistory />}
        exact
      />
      <Route
        path={Routes.UserChangePrice}
        component={() => <ChangePrice />}
        exact
      />
      <Route
        path={[
          Routes.UserViewLog,
          Routes.UserBookmark,
          Routes.UserWatchLater,
          Routes.UserPurchased,
        ]}
        component={() => <MyVideoList />}
        exact
      />
      <Route
        path={[
          Routes.UserContentCategories,
          Routes.UserContentTags,
          Routes.UserContentCreators,
        ]}
        component={() => <Genres />}
        exact
      />
      <Route
        path={[
          Routes.UserVideosPopular,
          Routes.UserSearchVideos,
          Routes.UserContentsNewer,
          Routes.UserCombineContents,
        ]}
        component={() => <TypeContents />}
        exact
      />
      <Route
        path={[Routes.UserPlaylistVideos, Routes.UserEventContents]}
        component={() => <PlaylistMenu />}
        exact
      />
      <Route
        path={[Routes.UserVideoDetail, Routes.UserContentVideoDetail]}
        component={() => <VideoDetail />}
        exact
      />
      <Route
        path={[Routes.UserLiveDetail, Routes.UserContentLiveDetail]}
        component={() => <LiveDetail />}
        exact
      />
      <Route
        path={Routes.UserCreatorDetail}
        component={() => <CreatorDetail />}
        exact
      />
    </Switch>
    <Footer />
  </div>
)

export default User
