import { Routes } from 'common/enums'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import TableBody from 'components/organisms/Admin/Administrator/TableBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'

const Administrator: React.FC = () => {
  const { t } = useTranslation('adminAdministrator')

  return (
    <>
      <H1 title={t('list.title')} />

      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminAdministratorInviteCreate}
          content={t('list.newInvite')}
        />
        <TableBody />
      </Container>
    </>
  )
}

export default Administrator
