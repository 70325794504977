import {
  IAuthEmailForm,
  IAuthPasswordForm,
} from 'common/interfaces/setting/auth_form'
import { isAuthMethodEmail } from 'common/utils'
import CardComponent from 'components/molecules/User/Setting/CardComponent'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  initAuthEmailForm,
  initAuthPasswordForm,
  updateEmail,
  updatePassword,
} from 'services/user/setting'

const AuthFormRow: React.FC = () => {
  const { t } = useTranslation('userSetting')
  const { storeCache } = useContext(AuthContext)

  const [authEmailForm, setAuthEmailForm] = useState<IAuthEmailForm>(
    initAuthEmailForm()
  )
  const [authPassForm, setAuthPassForm] = useState<IAuthPasswordForm>(
    initAuthPasswordForm()
  )

  const { team, user } = storeCache
  if (!team || !user) return null

  const isAuthEmail = isAuthMethodEmail(team)

  enum EmailInputType {
    EMAIL,
    PASSWORD,
  }

  const onChangeEmailInput = (
    type: EmailInputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = e.target.value
    setAuthEmailForm({
      email: type === EmailInputType.EMAIL ? val : authEmailForm.email,
      password: type === EmailInputType.PASSWORD ? val : authEmailForm.password,
    })
  }

  enum PassInputType {
    CURRENT_PASS,
    NEW_PASS,
    NEW_PASS_CONFIRM,
  }

  const onChangePassInput = (
    type: PassInputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = e.target.value
    setAuthPassForm({
      password:
        type === PassInputType.CURRENT_PASS ? val : authPassForm.password,
      new_password:
        type === PassInputType.NEW_PASS ? val : authPassForm.new_password,
      new_password_confirm:
        type === PassInputType.NEW_PASS_CONFIRM
          ? val
          : authPassForm.new_password_confirm,
    })
  }

  const doUpdateEmail = () => updateEmail(storeCache, authEmailForm)

  const doUpdatePass = () =>
    updatePassword(team, user, authPassForm, setAuthPassForm)

  return (
    <Row className="user-setting-auth-row my-2">
      {isAuthEmail && (
        <CardComponent
          title={t('authFormRow.settingEmail')}
          onClick={doUpdateEmail}
        >
          <Row>
            <Col md="11">
              <FormGroup>
                <Label>{t('authFormRow.emailForm.old')}</Label>
                <Input value={user.email} type="text" disabled />
              </FormGroup>
            </Col>
            <Col md="11">
              <FormGroup>
                <Label>{t('authFormRow.emailForm.new')}</Label>
                <Input
                  value={authEmailForm.email}
                  onChange={(e) => onChangeEmailInput(EmailInputType.EMAIL, e)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="11">
              <FormGroup>
                <Label>{t('authFormRow.emailForm.password')}</Label>
                <Input
                  value={authEmailForm.password}
                  onChange={(e) =>
                    onChangeEmailInput(EmailInputType.PASSWORD, e)
                  }
                  type="password"
                />
              </FormGroup>
            </Col>
          </Row>
        </CardComponent>
      )}
      <CardComponent
        title={t('authFormRow.settingPassword')}
        onClick={doUpdatePass}
      >
        <Row>
          {isAuthEmail && (
            <Col md="11">
              <FormGroup>
                <Label>{t('authFormRow.passwordForm.old')}</Label>
                <Input
                  value={authPassForm.password}
                  onChange={(e) =>
                    onChangePassInput(PassInputType.CURRENT_PASS, e)
                  }
                  type="password"
                />
              </FormGroup>
            </Col>
          )}
          <Col md="11">
            <FormGroup>
              <Label>{t('authFormRow.passwordForm.new')}</Label>
              <Input
                value={authPassForm.new_password}
                onChange={(e) => onChangePassInput(PassInputType.NEW_PASS, e)}
                type="password"
              />
            </FormGroup>
          </Col>
          <Col md="11">
            <FormGroup>
              <Label>{t('authFormRow.passwordForm.confirm')}</Label>
              <Input
                value={authPassForm.new_password_confirm}
                onChange={(e) =>
                  onChangePassInput(PassInputType.NEW_PASS_CONFIRM, e)
                }
                type="password"
              />
            </FormGroup>
          </Col>
        </Row>
      </CardComponent>
    </Row>
  )
}

export default AuthFormRow
