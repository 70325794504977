import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect } from 'react'
import {
  redirectToZoomSettingPage,
  saveZoomUserId,
} from 'services/admin/admin_setting_zoom_setting'

interface IZoomAuthorizedBodyProps {
  userId: string
}

const ZoomAuthorizedBody: React.FC<IZoomAuthorizedBodyProps> = ({ userId }) => {
  const { storeCache } = useContext(AuthContext)

  useEffect(() => {
    ;(async () => {
      await saveZoomUserId(storeCache, userId)
      redirectToZoomSettingPage()
    })()
  }, [storeCache, userId])

  return null
}

export default ZoomAuthorizedBody
