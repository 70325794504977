import { ITaxRate } from 'common/interfaces/stripe/tax_rate'
import { notOnKeyDownHyphen } from 'common/utils'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, FormGroup, Input, Label } from 'reactstrap'
import {
  InputType,
  onChangeInput as serviceOnChangeInput,
  saveStripeTaxRate,
} from 'services/admin/tax_rate_form'

type TFormBody = {
  isCreate: boolean
  taxRateObj: ITaxRate
  history: History
}

const FormBody: React.FC<TFormBody> = (props: TFormBody) => {
  const { t } = useTranslation('adminStripeBilling')
  const { isCreate, taxRateObj, history } = props
  const storeCache = useContext(AuthContext).storeCache
  const [taxRate, setTaxRate] = useState<ITaxRate>(taxRateObj)

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(taxRate, setTaxRate, type, e)

  const save = () => saveStripeTaxRate(history, taxRate, storeCache, isCreate)

  return (
    <Form>
      <FormGroup row>
        <Col md="10">
          <Label for="displayNameForm">
            <InfoTooltip
              content={t('stripeTaxRate.form.displayName.title')}
              tooltipContent={t('stripeTaxRate.form.displayName.tips')}
              required
            />
          </Label>
          <Input
            id="displayNameForm"
            list="displayNameFormDataList"
            onChange={(e) => onChangeInput(InputType.DISPLAY_NAME, e)}
            type="text"
            defaultValue={taxRate.display_name}
          />
          <datalist id="displayNameFormDataList">
            <option
              value={t('stripeTaxRate.form.displayName.options.consumptionTax')}
            />
            <option
              value={t('stripeTaxRate.form.displayName.options.reducedTaxRate')}
            />
          </datalist>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="percentageForm">
            <InfoTooltip
              content={t('stripeTaxRate.form.taxRatePercentage.title')}
              tooltipContent={t('stripeTaxRate.form.taxRatePercentage.tips')}
              required
            />
          </Label>
          <Input
            id="percentageForm"
            onChange={(e) => onChangeInput(InputType.PERCENTAGE, e)}
            type="number"
            value={taxRate.percentage}
            defaultValue={taxRate.percentage}
            disabled={!isCreate}
            min="0"
            onKeyDown={notOnKeyDownHyphen}
          />
        </Col>
      </FormGroup>
      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {t('save')}
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
