import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js'
import { InvoiceActionType, PriceType, Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICoupon } from 'common/interfaces/stripe/coupon'
import { IInvoice } from 'common/interfaces/stripe/Invoice'
import { IPaymentIntent } from 'common/interfaces/stripe/payment_intent'
import { IProductInfo } from 'common/interfaces/stripe/product_price'
import { isAfterTimestamp, unix2Timestamp } from 'common/times'
import { getCurrency, isLoggedIn, stripeAPIError } from 'common/utils'
import { History } from 'history'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import {
  reloadCachedSubscriptionObj,
  reloadCachedTeam,
} from 'providers/AuthProvider'
import {
  changePrice,
  createInvoicePayment,
  createSubscription,
  fetchProductInfo,
  getCoupons,
  hasIncompletePaymentIntent,
  previewUpcoming,
  sendInvoice,
  updatePaymentMethod,
} from 'repositories/functions/functions_stripe'
import { get as getStripe } from 'repositories/store/firebase_stripe'
import { alertService } from 'services/alert'
import { IInvoicePayment } from 'services/invoice_payment_modal'
import { modalService } from 'services/modal'

/**
 * 該当の商品に紐づく全料金を取得し、Stateに格納する
 */
export const init = async (
  storeCache: IStoreCache,
  setProductInfo: React.Dispatch<React.SetStateAction<IProductInfo[]>>,
  setCoupons: React.Dispatch<React.SetStateAction<ICoupon[]>>,
  setStripePromise: React.Dispatch<React.SetStateAction<Stripe | null>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    const { product_id, tax_rates, publishableKey } = await getStripe()
    const [productInfo, coupons, stripe] = await Promise.all([
      fetchProductInfo(undefined, product_id, tax_rates, getCurrency()),
      getCoupons(),
      loadStripe(publishableKey),
    ])

    setProductInfo(productInfo)
    setCoupons(coupons)
    setStripePromise(stripe)
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * Stripe ElementのFormを送信
 */
export const handleSubmit = async (
  stripe: Stripe | null,
  elements: StripeElements | null,
  storeCache: IStoreCache,
  setButtonLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache) || !stripe || !elements) return

  setButtonLoading(true)
  try {
    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    })
    if (result.error) throw new Error(result.error.message)
    if (!result.paymentIntent || !result.paymentIntent.payment_method) {
      throw new Error(i18nValidation('input.paymentMethod'))
    }
    if (!storeCache.team?.stripeId) {
      throw new Error(i18nValidation('input.invalidPayment'))
    }

    await updatePaymentMethod(
      undefined,
      storeCache.team.stripeId,
      result.paymentIntent.payment_method
    )
    await reloadCachedTeam(storeCache)

    modalService.showPaymentThanks(i18nAlert('paymentThanksMessage'), () => {
      window.location.href = Routes.AdminVideo
    })
  } catch (error: any) {
    stripeAPIError(error)
  }
  setButtonLoading(false)
}

/**
 * 料金選択画面で料金を選択した時の処理
 * @param storeCache `IStoreCache`
 * @param selectProductInfo `IProductInfo`
 * @param coupons `ICoupon[]`
 * @param couponId string | undefined
 * @param setIsOpenInvoicePayment `React.Dispatch<React.SetStateAction<boolean>>`
 * @param setSlectProductInfo `React.Dispatch<React.SetStateAction<IProductInfo | null>>`
 * @param setCouponId `React.Dispatch<React.SetStateAction<string | undefined>>`
 * @throws {Error} クーポンが無効な場合
 */
export const selectedProductInfo = (
  storeCache: IStoreCache,
  selectProductInfo: IProductInfo,
  coupons: ICoupon[],
  couponId: string | undefined,
  setIsOpenInvoicePayment: React.Dispatch<React.SetStateAction<boolean>>,
  setSlectProductInfo: React.Dispatch<
    React.SetStateAction<IProductInfo | null>
  >,
  setCouponId: React.Dispatch<React.SetStateAction<string | undefined>>
): void => {
  if (!isLoggedIn(storeCache)) return

  try {
    checkCoupon(coupons, couponId, selectProductInfo)

    setIsOpenInvoicePayment(true)
    setSlectProductInfo(selectProductInfo)
    setCouponId(couponId)
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * Elementで使用する`client_secret`を取得する
 */
export const getClientSecret = async (
  storeCache: IStoreCache,
  productInfo: IProductInfo,
  coupons: ICoupon[],
  couponId: string | undefined,
  setInvoice: React.Dispatch<React.SetStateAction<IInvoice | null>>,
  setClientSecret: React.Dispatch<React.SetStateAction<string>>,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    checkCoupon(coupons, couponId, productInfo)

    const res = await createSubscription(
      undefined,
      true,
      storeCache.team!.id,
      storeCache.user!.id,
      storeCache.team!.stripeId,
      storeCache.user!.email,
      couponId,
      storeCache.user!.name,
      productInfo.id,
      productInfo.tax_rate.id
    )
    if (!res.invoice || !res.payment_intent) {
      throw new Error(i18nValidation('payment.notCreated'))
    }

    await reloadCachedTeam(storeCache)

    setInvoice(res.invoice)
    setClientSecret(res.payment_intent.client_secret)
    setIsOpen(true)
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * stripeで比例配分のプレビューを取得する
 */
export const getPreviewUpcoming = async (
  storeCache: IStoreCache,
  priceId: string,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setInvoice: React.Dispatch<React.SetStateAction<IInvoice | null>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    const { team, subscriptionObj } = storeCache
    if (!subscriptionObj) {
      throw new Error(i18nAlert('subscription.notPurchase'))
    }

    setInvoice(
      await previewUpcoming(
        undefined,
        team!.stripeId,
        subscriptionObj.subscription.id,
        priceId
      )
    )
    setIsOpen(true)
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * stripeで料金（プラン）を変更する（チーム専用）
 *
 * @param storeCache `IStoreCache`
 * @param history History
 * @param priceId string
 * @param setLoading `React.Dispatch<React.SetStateAction<boolean>>`
 */
export const changePriceForTeam = async (
  storeCache: IStoreCache,
  { replace }: History,
  priceId: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  setLoading(true)
  try {
    const { subscriptionObj } = storeCache
    if (!subscriptionObj) {
      throw new Error(i18nAlert('plans.notJoined'))
    }

    await changePrice(undefined, subscriptionObj.subscription.id, priceId)
    await reloadCachedSubscriptionObj(storeCache)

    replace(Routes.AdminContractingPlan)
    alertService.show(true, i18nAlert('plans.changed'))
  } catch (error: any) {
    stripeAPIError(error)
  }
  setLoading(false)
}

/**
 * stripeで請求書払いを作成する（チーム専用）
 *
 * @param storeCache `IStoreCache`
 * @param history History
 * @param priceId number
 * @param setLoading `React.Dispatch<React.SetStateAction<boolean>>`
 */
export const createInvoicePaymentForTeam = async (
  storeCache: IStoreCache,
  productInfo: IProductInfo,
  invoicePayment: IInvoicePayment,
  couponId: string | undefined,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setActionType: React.Dispatch<React.SetStateAction<InvoiceActionType>>,
  setCreatedPaymentIntent: React.Dispatch<
    React.SetStateAction<IPaymentIntent | null>
  >
): Promise<void> => {
  if (!isLoggedIn(storeCache, true)) return

  try {
    const team = storeCache.team!
    if (
      productInfo.type === PriceType.RECURRING &&
      (!invoicePayment.name || !invoicePayment.email)
    ) {
      throw new Error(i18nValidation('input.invoicePayment'))
    }
    if (team.stripeId) {
      const paymentIntent = await hasIncompletePaymentIntent(
        undefined,
        team.stripeId
      )
      setCreatedPaymentIntent(paymentIntent)

      if (paymentIntent) {
        setActionType(InvoiceActionType.RESEND)
        return
      }
    }

    setLoading(true)

    await createInvoicePayment(
      undefined,
      true,
      team.id,
      team.stripeId,
      storeCache.user!,
      invoicePayment,
      productInfo.id,
      productInfo.currency,
      productInfo.unit_amount_wit_tax,
      couponId,
      productInfo.tax_rate.id,
      productInfo.type,
      undefined,
      undefined
    )

    alertService.show(true, i18nAlert('sentInvoice'))
    window.location.href = Routes.AdminVideo
  } catch (error: any) {
    stripeAPIError(error)
  }
  setLoading(false)
}

/**
 * 請求書再送処理（チーム専用）
 */
export const resendInvoiceForTeam = async (
  storeCache: IStoreCache,
  invoicePayment: IInvoicePayment,
  createdPaymentIntent: IPaymentIntent | null,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache) || !createdPaymentIntent) return

  setLoading(true)
  try {
    await sendInvoice(
      undefined,
      undefined,
      storeCache.team!.stripeId,
      invoicePayment,
      undefined,
      createdPaymentIntent.invoice
    )

    alertService.show(true, i18nAlert('sentInvoice'))
  } catch (error) {
    stripeAPIError(error)
  }
  setLoading(false)
}

/**
 * クーポンが有効か判定する
 *
 * @param coupons `ICoupon[]`
 * @param couponId string | undefined
 * @param productInfo `IProductInfo`
 * @throws {Error} クーポンが無効な場合
 */
const checkCoupon = (
  coupons: ICoupon[],
  couponId: string | undefined,
  productInfo: IProductInfo
): void => {
  if (!couponId) return

  const coupon = coupons.find(({ id }) => id === couponId)
  if (
    !coupon ||
    (coupon.applies_to &&
      coupon.applies_to.products.length > 0 &&
      coupon.applies_to.products.find((p) => p !== productInfo.product_id))
  ) {
    throw new Error(i18nValidation('coupon.disabled'))
  }
  if (coupon.redeem_by && isAfterTimestamp(unix2Timestamp(coupon.redeem_by))) {
    throw new Error(i18nValidation('expired.couponCode.inputted'))
  }
}
