import firebase from 'common/firebase'

export interface ILog {
  user_id: string
  video_page_opens: ILogVideoPageOpen[]
  video_plays: ILogVideoPlay[]
  video_endeds: ILogVideoEnded[]
  filelist_downloadeds: ILogFilelistDownloaded[]
  video_bookmarks: ILogVideoBookmark[]
  video_unbookmarks: ILogVideoUnBookmark[]
  logged_ins: ILogLoggedIn[]
  logged_outs: ILogLoggedOut[]
  enquete_answereds: ILogEnqueteAnswered[]
  test_answereds: ILogTestAnswered[]
  live_page_opens: ILogLivePageOpen[]
  video_commenteds: ILogVideoCommented[]
  video_reply_commenteds: ILogVideoReplyCommented[]
  user_published_subscriptions: ILogUserPublishedSubscription[]
}

export interface ILogVideoPageOpen {
  timestamp: firebase.firestore.Timestamp
  video_id: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILogVideoPlay extends ILogVideoPageOpen {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILogVideoEnded extends ILogVideoPageOpen {}

export interface ILogFilelistDownloaded extends ILogVideoPageOpen {
  filelist_id: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILogVideoBookmark extends ILogVideoPageOpen {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILogVideoUnBookmark extends ILogVideoPageOpen {}

export interface ILogLoggedIn {
  timestamp: firebase.firestore.Timestamp
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILogLoggedOut extends ILogLoggedIn {}

export interface ILogEnqueteAnswered extends ILogVideoPageOpen {
  enquete_id: string
}

export interface ILogTestAnswered extends ILogVideoPageOpen {
  test_id: string
}

export interface ILogLivePageOpen {
  timestamp: firebase.firestore.Timestamp
  live_id: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILogVideoCommented extends ILogVideoPageOpen {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILogVideoReplyCommented extends ILogVideoPageOpen {}

export interface ILogUserPublishedSubscription {
  timestamp: firebase.firestore.Timestamp
}

export enum LogType {
  VIDEO_PAGE_OPEN = 'video_page_open',
  VIDEO_PLAY = 'video_play',
  VIDEO_ENDED = 'video_ended',
  FILELIST_DOWNLOADED = 'filelist_downloaded',
  VIDEO_BOOKMARK = 'video_bookmark',
  VIDEO_UNBOOKMARK = 'video_unbookmark',
  LOGGED_IN = 'logged_in',
  LOGGED_OUT = 'logged_out',
  ACCOUNT_CREATED = 'account_created',
  ENQUETE_ANSWERED = 'enquete_answered',
  TEST_ANSWERED = 'test_answered',
  LIVE_PAGE_OPEN = 'live_page_open',
  VIDEO_COMMENTED = 'video_commented',
  VIDEO_REPLY_COMMENTED = 'video_reply_commented',
  USER_PUBLISHED_SUBSCRIPTION = 'user_published_subscription',
}

export interface IFlattedLog extends ILogVideoPageOpen {
  user_id: string
  type: LogType
  live_id?: string
  enquete_id?: string
  test_id?: string
  filelist_id?: string
}
