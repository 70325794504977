import { IFormInvite } from 'common/interfaces/invite'
import { makeJoinAdministratorUrl } from 'common/link_url'
import { copyClipboard, notOnKeyDownHyphen } from 'common/utils'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import RequiredText from 'components/atoms/Required/RequiredText'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import {
  InputType,
  defaultExpireDatetime,
  onChangeInfinity as serviceOnChangeInfinity,
  onChangeInput as serviceOnChangeInput,
} from 'services/admin/administrator_invite_form'
import {
  saveInviteAdministrator,
  onClickInviteList as serviceOnClickInviteList,
} from 'services/admin/invite'

interface IFormBodyProps {
  isCreate: boolean
  inviteObj: IFormInvite
}

const FormBody: React.FC<IFormBodyProps> = (props) => {
  const { t } = useTranslation('adminInvite')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [invite, setInvite] = useState<IFormInvite>(props.inviteObj)
  const [isInviteSuccess, setIsInviteSuccess] = useState<boolean>(false)

  const joinUrl = makeJoinAdministratorUrl(invite.id)

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(invite, setInvite, type, e)

  const onChangeInfinityCount = (isInfinity: boolean) =>
    serviceOnChangeInfinity(invite, setInvite, { count: isInfinity })

  const onChangeInfinityExpire = (isInfinity: boolean) =>
    serviceOnChangeInfinity(invite, setInvite, { expire: isInfinity })

  const save = () =>
    saveInviteAdministrator(
      props.isCreate,
      invite,
      setIsInviteSuccess,
      storeCache
    )
  const copyUrl = () => copyClipboard(joinUrl)
  const onClickInviteList = () => serviceOnClickInviteList(history)

  return (
    <Form>
      <FormGroup row>
        <Col md="8">
          <RequiredText labelFor="nameForm" label>
            {t('inviteForm.title')}
          </RequiredText>
          <Input
            id="nameForm"
            disabled={isInviteSuccess}
            value={invite.name}
            onChange={(e) => onChangeInput(InputType.NAME, e)}
            type="text"
          />
        </Col>
      </FormGroup>
      <div className="mb-4">
        <Label>
          <InfoTooltip
            content={t('inviteForm.canRegisterPeoples.title')}
            tooltipContent={t('inviteForm.canRegisterPeoples.tips')}
          />
        </Label>
        <FormGroup check>
          <Label check>
            <Input
              disabled={isInviteSuccess}
              onChange={() => onChangeInfinityCount(true)}
              type="radio"
              checked={invite.count_infinity}
            />
            {t('inviteForm.unlimited')}
          </Label>
        </FormGroup>
        <FormGroup check className="mt-2">
          <Label check>
            <Input
              disabled={isInviteSuccess}
              onChange={() => onChangeInfinityCount(false)}
              type="radio"
              checked={!invite.count_infinity}
            />
            {t('inviteForm.limitPeoples')}
          </Label>
        </FormGroup>
        {!invite.count_infinity && (
          <Row className="mt-2">
            <Col md="2">
              <InputGroup>
                <Input
                  disabled={invite.count_infinity || isInviteSuccess}
                  onChange={(e) => onChangeInput(InputType.COUNT, e)}
                  defaultValue={invite.count}
                  type="number"
                  step="1"
                  min="0"
                  onKeyDown={notOnKeyDownHyphen}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    {t('inviteForm.peoplesCount')}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        )}
      </div>

      <div className="mb-4">
        <Label>
          <InfoTooltip
            content={t('inviteForm.registerDeadline.title')}
            tooltipContent={t('inviteForm.registerDeadline.tips')}
          />
        </Label>
        <FormGroup check>
          <Label check>
            <Input
              disabled={isInviteSuccess}
              onChange={() => onChangeInfinityExpire(true)}
              type="radio"
              checked={invite.expire_infinity}
            />
            {t('inviteForm.unlimited')}
          </Label>
        </FormGroup>
        <FormGroup check className="mt-2">
          <Label check>
            <Input
              disabled={isInviteSuccess}
              onChange={() => onChangeInfinityExpire(false)}
              type="radio"
              checked={!invite.expire_infinity}
            />
            {t('inviteForm.setExpire')}
          </Label>
        </FormGroup>
        {!invite.expire_infinity && (
          <Row className="mt-2">
            <Col md="5">
              <Input
                disabled={invite.expire_infinity || isInviteSuccess}
                onChange={(e) => onChangeInput(InputType.EXPIRE_DATETIME, e)}
                defaultValue={defaultExpireDatetime()}
                type="datetime-local"
              />
            </Col>
          </Row>
        )}
      </div>

      <FormGroup row>
        <Col md="8">
          <Label for="commentForm">
            <InfoTooltip
              content={t('inviteForm.comment.title')}
              tooltipContent={t('inviteForm.comment.tips')}
            />
          </Label>
          <Input
            id="commentForm"
            disabled={isInviteSuccess}
            defaultValue={invite.comment}
            onChange={(e) => onChangeInput(InputType.COMMENT, e)}
            type="text"
          />
        </Col>
      </FormGroup>

      {isInviteSuccess ? (
        <div className="my-5">
          <Label for="joinUrl">{t('inviteForm.registerAndShareURL')}</Label>
          <Input
            id="joinUrl"
            value={joinUrl}
            readOnly
            onFocus={(e) => e.target.select()}
          />
          <div className="d-flex mt-4">
            <Button onClick={copyUrl} color="primary" type="button" size="sm">
              {t('inviteForm.copyClipboard')}
            </Button>
            <Button
              onClick={onClickInviteList}
              color="primary"
              type="button"
              size="sm"
            >
              {t('inviteForm.backToInviteList')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <Button onClick={save} className="my-4" color="primary" type="button">
            {props.isCreate ? t('inviteForm.create') : t('inviteForm.update')}
          </Button>
        </div>
      )}
    </Form>
  )
}

export default FormBody
