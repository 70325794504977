import { SendEmailFormLimits } from 'common/enums'
import { INews } from 'common/interfaces/news'
import { i18nValidation } from 'i18n/i18n'

/**
 * お知らせ登録・編集用バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param news send_emailsドキュメント
 */
export const validateForm = ({ title, content }: INews): void => {
  if (!title) {
    throw new Error(i18nValidation('input.title'))
  }
  const stripTagRegex = /<\/?[^>]+(>|$)/g
  const strippedBody = content.replace(stripTagRegex, '')
  if (!strippedBody) {
    throw new Error(i18nValidation('input.news.content'))
  }
  if (strippedBody.length > SendEmailFormLimits.CONTENT_LENGTH) {
    throw new Error(i18nValidation('input.news.contentOverLimit'))
  }
}
