import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { IComment } from 'common/interfaces/comment'
import { IUser } from 'common/interfaces/user'
import { datetimeFormat } from 'common/times'
import { isAuthMethodEmail } from 'common/utils'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeCustomOperationButtons,
} from 'components/atoms/Table/ElementsOnTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Input } from 'reactstrap'
import { getUsers, getVideoComments } from 'services/admin/comment'

interface ICommentProps {
  initVideoId: string | null
}

const Comment: React.FC<ICommentProps> = (props) => {
  const { t } = useTranslation('adminComment')
  const { storeCache } = useContext(AuthContext)

  const [videoId, setVideoId] = useState<string>(props.initVideoId ?? '')
  const [videoComments, setVideoComments] = useState<IComment[]>([])
  const [users, setUsers] = useState<IUser[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getUsers(storeCache, setUsers)
  }, [storeCache])

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      await getVideoComments(storeCache, videoId, setVideoComments)
      setIsLoading(false)
    })()
  }, [storeCache, videoId])

  if (isLoading) return <LoadingSpinner />

  if (!storeCache.team) return null

  const videoOptions = storeCache.videos.map((v) => (
    <option key={v.id} value={v.id}>
      {v.name}
    </option>
  ))

  const onChangeVideoIdInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVideoId(e.target.value)
    window.history.replaceState(
      window.history.state,
      '',
      `?videoId=${e.target.value}`
    )
  }

  return (
    <>
      <Input onChange={onChangeVideoIdInput} value={videoId} type="select">
        <option value="">{t('pleaseSelect')}</option>
        {videoOptions}
      </Input>
      {videoId && (
        <div className="mt-4">
          {videoComments.length <= 0 && (
            <div className="mt-5">{t('noComment')}</div>
          )}
          {videoComments.length > 0 && (
            <Table comments={videoComments} users={users} videoId={videoId} />
          )}
        </div>
      )}
    </>
  )
}

const Table: React.FC<{
  comments: IComment[]
  users: IUser[]
  videoId: string
}> = ({ comments, users, videoId }) => {
  const { t } = useTranslation('adminComment')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const isAuthEmail = isAuthMethodEmail(storeCache.team!)

  const columns = useMemo<ColumnDef<IComment>[]>(
    () => [
      {
        header: t('table.email'),
        accessorFn: (c) => users.find((u) => u.id === c.user_id)?.email ?? '',
        meta: { hidden: !isAuthEmail },
      },
      {
        header: t('table.name'),
        accessorFn: (c) => users.find((u) => u.id === c.user_id)?.name ?? '',
      },
      {
        header: t('table.comment'),
        accessorKey: 'content',
      },
      {
        header: t('table.createdAt'),
        accessorFn: (c) => datetimeFormat(c.created_at),
      },
      {
        header: t('table.operations'),
        accessorFn: (comment) =>
          makeCustomOperationButtons([
            makeCustomOperationButton(t('table.replyComment'), () =>
              history.push({
                pathname: Routes.AdminCommentReply,
                state: { comment, videoId },
              })
            ),
          ]),
      },
    ],
    [isAuthEmail, history, users, videoId, t]
  )

  return useTanstackTable<IComment>(columns, comments)
}

export default Comment
