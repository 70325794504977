import React, { CSSProperties, useEffect, useState } from 'react'
import { Alert as ReactstrapAlert } from 'reactstrap'
import { alertService, AlertType } from 'services/alert'

const Alert: React.FC = () => {
  const [alert, setAlert] = useState<{
    isShow: boolean
    type: AlertType
    message: string
  }>({
    isShow: false,
    type: AlertType.Success,
    message: '',
  })

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    const subscription = alertService.onAlert().subscribe((alert) => {
      setAlert(alert)
      if (alert.isShow) {
        if (timeoutId !== null) {
          clearTimeout(timeoutId)
        }
        const timeout = alert.type === AlertType.Success ? 3 : 6
        timeoutId = setTimeout(() => {
          alertService.close(alert)
          timeoutId = null
        }, timeout * 1000)
      }
    })

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }
      subscription.unsubscribe()
    }
  }, [])

  let alertColor = 'success'
  if (alert.type === AlertType.Danger) {
    alertColor = 'danger'
  }

  const style: CSSProperties = {
    textAlign: 'center',
    fontSize: 'unset',
    borderRadius: 'unset',
    zIndex: 1000,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  }

  return (
    <ReactstrapAlert
      isOpen={alert.isShow}
      toggle={() => alertService.close(alert)}
      color={alertColor}
      style={style}
    >
      {alert.message}
    </ReactstrapAlert>
  )
}

export default Alert
