import { IMailTemplateEventName, LangType } from 'common/enums'
import firebase from 'common/firebase'
import { IContentsOrders } from './contents_orders'
import { IFieldCustomize } from './field_customize'
import { IHeaderItemOrder } from './header_item_order'
import { ITopItemOrder } from './top_item_order'

export interface ITeam extends ITeamBasicInfo {
  readonly id: string
  bunny: ITeamBunny
  colors: ITeamColors
  logo: IImage | null
  top_images: IImage[]
  address: string | null
  apply_date: firebase.firestore.Timestamp
  cancelled_date: firebase.firestore.Timestamp | null
  expire_date: string
  auth_method: string
  is_invoice_payment: boolean
  item_order: ITopItemOrder[]
  header_item_order: IHeaderItemOrder[]
  customize_field?: IFieldCustomize[]
  landing_page: ILandingPage
  saml_provider_id: string
  stripe: IStripe
  stripeId: string
  mail_templates: IMailTemplate[]
  lang: LangType
}

interface ITeamBunny {
  pullZoneId: number
  pullZoneSubDomain: string
  videoLibraryId: number
}

interface ITeamUrls {
  about: string | null
  terms: string | null
  contact: string | null
  faq: string | null
  law: string | null
  account_register: string | null
}

export interface ITeamBasicInfo {
  name: string
  email: string | null
  urls: ITeamUrls
  company: string
  tel: string
  hide_footer_logo: boolean
  social_logins: ISocialLogins
  is_password_complex?: boolean
  is_comment_active: boolean
  is_single_login: boolean
  is_site_public: boolean
  auth_method: string
  enquete_id: string
  allow_ips: IAllowIps
  default_contents_orders: IContentsOrders
  saml_provider_id: string
  lang: LangType
}

export interface ITeamColors {
  text_primary: string
  background: string
  accent: string
}

export const defaultColors: ITeamColors = {
  text_primary: '#32325d',
  background: '#ffffff',
  accent: '#6856ff',
}

export interface IImage {
  id: string
  path: string
  link: string
  asNewTab: boolean
}

export interface IFormImage {
  id: string
  path: string
  link: string
  asNewTab: boolean
  newImage: File | undefined
}

export interface ISocialLogins {
  google: boolean
}

export interface ILandingPage {
  banner_image: IImage | null
  site_overview: string
  intro_video_bunny_id: string | null
}

export interface IAllowIps {
  admin: string[]
  user: string[]
}

export interface IStripe {
  account_id: string
  is_products_activate: boolean
  allow_invoice_payment: boolean
  subscription_product_id: string
}

export interface IMailTemplate {
  event_name: IMailTemplateEventName
  event_detail: string
  status: boolean
  email_title: string
  email_content: string
  updated_at: firebase.firestore.Timestamp
}

export interface IFindTeamLandingPage extends ILandingPage {
  intro_video_thumb_url: string | null
}

export interface IFindTeam {
  id: string
  name: string
  urls: ITeamUrls
  colors: ITeamColors
  logo_path: string | null
  company: string
  auth_method: string
  hide_footer_logo: boolean
  social_logins: ISocialLogins
  bunny_pull_zone_sub_domain: string
  landing_page: IFindTeamLandingPage
  is_site_public: boolean
  saml_provider_id: string
  stripeId: string
}
