import { makeUserRootPath } from 'common/link_path'
import VideoDetailBody from 'components/organisms/User/VideoDetail/VideoDetailBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { getVideoData } from 'services/user/video_detail'

interface IVideoDetailProps {
  isCatalogPage?: boolean
}

const VideoDetail: React.FC<IVideoDetailProps> = (props: IVideoDetailProps) => {
  const { t } = useTranslation('userVideo')
  const { storeCache } = useContext(AuthContext)
  const { parentId, videoId } =
    useParams<{ parentId: string | undefined; videoId: string }>()
  const userUrl = makeUserRootPath(storeCache.team!.id)

  const video = getVideoData(storeCache, videoId)
  if (!video) {
    return (
      <div className="mt-5 text-center">
        <Trans
          t={t}
          i18nKey="noVideo"
          components={{ topLink: <Link to={userUrl} /> }}
        />
      </div>
    )
  }

  return (
    <VideoDetailBody
      isCatalogPage={props.isCatalogPage ?? false}
      parentId={parentId}
      video={video}
    />
  )
}

export default VideoDetail
