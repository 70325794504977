import { ITeam } from 'common/interfaces/team'
import { ICustomizeFields } from 'common/interfaces/user'
import { checkRequiredCustomizeField } from './customize_field'

/**
 * 会員更新フォーム用バリデーション
 * 前提としてtryの中で使用すること
 */
export const validateForm = (
  team: ITeam,
  customizeFields: ICustomizeFields[] | null | undefined
): void => {
  checkRequiredCustomizeField(team, customizeFields || [])
}
