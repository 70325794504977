import firebase from 'common/firebase'
import { IInvite } from 'common/interfaces/invite'
import { ITeam } from 'common/interfaces/team'
import { nowTimestamp } from 'common/times'
import { Teams } from './firebase_team'

const Invites = (team: ITeam) => Teams.doc(team.id).collection('invites')

/**
 * get all invites in team
 */
export const get = async (team: ITeam): Promise<IInvite[]> => {
  try {
    const inviteDatas = await Invites(team).get()
    return inviteDatas.docs.map((invite) => invite.data() as IInvite)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get for user (admin == false) invites in team
 */
export const getForUser = async (team: ITeam): Promise<IInvite[]> => {
  return (await Invites(team).where('admin', '==', false).get()).docs.map(
    (doc) => doc.data() as IInvite
  )
}

/**
 * find invite by id
 */
export const findById = async (
  team: ITeam,
  inviteId: string
): Promise<IInvite | null> => {
  try {
    const inviteData = await Invites(team).doc(inviteId).get()
    return inviteData.data() as IInvite
  } catch (error) {
    console.log(error)
  }
  return null
}

/**
 * add invite in team
 * @throws Firebase error
 */
export const store = async (team: ITeam, invite: IInvite) => {
  await Invites(team).doc(invite.id).set(invite)
}

/**
 * update invite in team
 * @throws Firebase error
 */
export const update = async (team: ITeam, invite: IInvite) => {
  invite.updated_at = nowTimestamp()
  await Invites(team).doc(invite.id).update(invite)
}

/**
 * consume invite count
 * @throws Firebase error
 */
export const consumeCount = async (team: ITeam, inviteId: string) => {
  const invite = await findById(team, inviteId)
  if (!invite || invite.count === 0) {
    return
  }

  if (invite.count - 1 === 0) {
    await Invites(team).doc(inviteId).update({
      count: -1,
    })
  } else {
    await Invites(team)
      .doc(inviteId)
      .update({
        count: firebase.firestore.FieldValue.increment(-1),
      })
  }
}

/**
 * delete invite
 * @throws Firebase error
 */
export const remove = async (team: ITeam, invite: IInvite) => {
  await Invites(team).doc(invite.id).delete()
}
