import { IProduct } from 'common/interfaces/stripe/product'
import { makeChangePricePath, makePaidHistoryPath } from 'common/link_path'
import {
  getExpirationAtInfo,
  goBackWithErrorAlert,
  isCustomerBalanceUser,
} from 'common/utils'
import DeleteAccountButton from 'components/molecules/User/Setting/DeleteAccountButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Button, Row } from 'reactstrap'
import {
  cancelSubscription,
  changeCard,
  getProductNameInProducts,
} from 'services/user/setting'

interface IOperationRow {
  products: IProduct[]
}

const OperationRow: React.FC<IOperationRow> = (props) => {
  const { t } = useTranslation('userSetting')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const { team, user, selfSubscriptionObj } = storeCache
  if (!user) {
    goBackWithErrorAlert(history, i18nAlert('noOperationAuthority'))
    return null
  }
  if (selfSubscriptionObj?.subscription.cancel_at_period_end) {
    return (
      <p>
        {getExpirationAtInfo(
          team!,
          selfSubscriptionObj!.subscription_item.price,
          selfSubscriptionObj!.subscription.cancel_at
        )}
      </p>
    )
  }

  const productName = getProductNameInProducts(
    props.products,
    selfSubscriptionObj
  )

  const cancel = () =>
    cancelSubscription(storeCache, selfSubscriptionObj, productName)

  if (selfSubscriptionObj) {
    return (
      <Row className="mt-4">
        <StripeButtons onClick={() => changeCard(storeCache)} />
        {!storeCache.user?.admin && (
          <Button
            color="secondary"
            type="button"
            onClick={() => history.replace(makeChangePricePath())}
          >
            {t('operationRow.changePlans')}
          </Button>
        )}
        <Button color="danger" type="button" onClick={cancel}>
          {t('operationRow.cancelSubscription')}
        </Button>
      </Row>
    )
  }
  if (user.customer_id) {
    return (
      <Row className="mt-4">
        <StripeButtons onClick={() => changeCard(storeCache)} />
        <DeleteAccountButton />
      </Row>
    )
  }
  return <DeleteAccountButton className="mt-4" />
}

const StripeButtons: React.FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement>
}> = (props) => {
  const { t } = useTranslation('userSetting')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  return (
    <>
      <Button
        color="default"
        type="button"
        onClick={() => history.push(makePaidHistoryPath())}
      >
        {t('operationRow.paidHistory')}
      </Button>
      {storeCache.user &&
        !isCustomerBalanceUser(
          storeCache.user,
          storeCache.selfSubscriptionObj
        ) && (
          <Button color="info" type="button" onClick={props.onClick}>
            {t('operationRow.changeCreditcardInformation')}
          </Button>
        )}
    </>
  )
}

export default OperationRow
