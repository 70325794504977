import { ISendEmail } from 'common/interfaces/send_email'
import { ITeam } from 'common/interfaces/team'
import { Teams } from './firebase_team'

const SendEmails = (team: ITeam) => Teams.doc(team.id).collection('send_emails')

/**
 * get all sendEmails in team
 */
export const get = async (team: ITeam): Promise<ISendEmail[]> =>
  (await SendEmails(team).get()).docs.map(
    (sendEmail) => sendEmail.data() as ISendEmail
  )

/**
 * add sendEmail
 * @throws Firebase error
 */
export const store = async (
  team: ITeam,
  sendEmail: ISendEmail
): Promise<void> => {
  await SendEmails(team).doc(sendEmail.id).set(sendEmail)
}

/**
 * update sendEmail
 * @throws Firebase error
 */
export const update = async (
  team: ITeam,
  sendEmail: ISendEmail
): Promise<void> => {
  await SendEmails(team).doc(sendEmail.id).update(sendEmail)
}

/**
 * delete sendEmail
 * @throws Firebase error
 */
export const remove = async (
  team: ITeam,
  { id }: ISendEmail
): Promise<void> => {
  await SendEmails(team).doc(id).delete()
}
