/**
 * 全角を許容しない（カスタムIDで使用）
 * @returns `RegExp`
 */
export const REGEXP_FORBID_FULLWIDTH = /[^a-z0-9\-#$%&@'*+.^_`|~!]/i

/**
 * one-steam管理者のメールアドレスを指定する
 * @returns `string`
 */
export const ONE_STEAM_ADMIN_EMAIL = 'sawai@rootteam.co.jp'

// メールアドレスバリデーション
export const EMAIL_REG = /^\S+@\S+$/i
