import classNames from 'classnames'
import { PriceType, Routes } from 'common/enums'
import { ICoupon } from 'common/interfaces/stripe/coupon'
import { IProductInfo } from 'common/interfaces/stripe/product_price'
import { ISubscriptionObject } from 'common/interfaces/subscription'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import {
  makeChangePricePath,
  makeItemSelectContentPath,
  makeItemSelectPath,
} from 'common/link_path'
import { formatPrice, nl2br } from 'common/utils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Input,
  Row,
} from 'reactstrap'
import './item.scss'

type TItem = {
  team?: ITeam | null
  user: IUser | null
  subscriptionObj: ISubscriptionObject | null
  productInfo: IProductInfo
  coupons?: ICoupon[]
  purchasedPriceId: string
  transitionToAuth: (couponId: string | undefined) => Promise<void> | undefined
  checkoutSession: (
    couponId: string | undefined
  ) => Promise<void> | void | undefined
  changePrice: React.MouseEventHandler<HTMLButtonElement>
  createInvoicePayment: (couponId: string | undefined) => void | undefined
}

const Item: React.FC<TItem> = ({
  team,
  user,
  subscriptionObj,
  productInfo,
  coupons,
  purchasedPriceId,
  transitionToAuth,
  checkoutSession,
  changePrice,
  createInvoicePayment,
}: TItem) => {
  const { t } = useTranslation('userSubscription')
  const { pathname } = useLocation()

  const [isCouponOpen, setIsCouponOpen] = useState<boolean>(false)
  const [couponId, setCouponId] = useState<string | undefined>(undefined)

  if (!productInfo.active) return <></>

  const isPurchased = productInfo.id === purchasedPriceId
  const isItemSelectPage = pathname === makeItemSelectPath(team!.id)
  const isAdminItemSelectPage = pathname === Routes.AdminItemSelect
  const isItemSelectVideoPage =
    pathname === Routes.ItemSelectContent ||
    pathname === makeItemSelectContentPath()
  const isChangePricePage =
    pathname === Routes.AdminItemSelect || pathname === makeChangePricePath()

  return (
    <Card
      className={classNames('card-pricing', 'border-0', 'text-center', 'my-4', {
        'bg-gradient-gray': isPurchased,
        'bg-gradient-success': !isPurchased,
      })}
    >
      <CardHeader className="bg-transparent">
        <h4 className="product-description">{productInfo.nickname}</h4>
      </CardHeader>
      <CardBody className="px-lg-7">
        <div className="tax-excluded">
          {formatPrice(productInfo.unit_amount, productInfo.currency)}
          {productInfo.currency === 'jpy' && (
            <>{t('changePrice.item.excludingTax')}</>
          )}
        </div>
        {productInfo.currency === 'jpy' && (
          <div className="tax-included">
            {t('changePrice.item.includingTax', {
              price: formatPrice(
                productInfo.unit_amount_wit_tax,
                productInfo.currency
              ),
            })}
          </div>
        )}
        <div className="my-4 text-white">
          {nl2br('description' in productInfo ? productInfo.description : null)}
        </div>
        <div className="my-4">
          <RenderButton
            team={team}
            priceId={purchasedPriceId}
            user={user}
            subscriptionObj={subscriptionObj}
            productInfo={productInfo}
            isPurchase={isPurchased}
            isAdminItemSelectPage={isAdminItemSelectPage}
            transitionToAuth={() => transitionToAuth(couponId)}
            checkoutSession={() => checkoutSession(couponId)}
            changePrice={changePrice}
          />
        </div>
        <InvoiceButton
          team={team}
          subscriptionObj={subscriptionObj}
          productInfo={productInfo}
          couponId={couponId}
          isPurchase={isPurchased}
          isAdminItemSelectPage={isAdminItemSelectPage}
          isItemSelectPage={isItemSelectPage}
          isItemSelectVideoPage={isItemSelectVideoPage}
          createInvoicePayment={() => createInvoicePayment(couponId)}
        />
        <RenderRemarks
          productInfo={productInfo}
          isChangePricePage={isChangePricePage}
        />
        <RenderInputCoupon
          coupons={coupons}
          productInfo={productInfo}
          isPurchase={isPurchased}
          isItemSelectPage={isItemSelectPage}
          isItemSelectVideoPage={isItemSelectVideoPage}
          user={user}
          subscriptionObj={subscriptionObj}
          isCouponOpen={isCouponOpen}
          setIsCouponOpen={() => setIsCouponOpen(!isCouponOpen)}
          setCouponId={setCouponId}
        />
      </CardBody>
    </Card>
  )
}

const RenderButton: React.FC<{
  team: ITeam | null | undefined
  priceId: string
  user: IUser | null
  subscriptionObj: ISubscriptionObject | null
  productInfo: IProductInfo
  isPurchase: boolean
  isAdminItemSelectPage: boolean
  transitionToAuth: () => void
  checkoutSession: React.MouseEventHandler<HTMLButtonElement>
  changePrice: React.MouseEventHandler<HTMLButtonElement>
}> = ({
  team,
  priceId,
  user,
  subscriptionObj,
  productInfo,
  isPurchase,
  isAdminItemSelectPage,
  transitionToAuth,
  checkoutSession,
  changePrice,
}): JSX.Element => {
  const { t } = useTranslation('userSubscription')
  const { type } = productInfo
  const primaryButton = (
    content: string,
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined,
    disabled?: boolean
  ) => (
    <Button onClick={onClick} color="primary" type="button" disabled={disabled}>
      {content}
    </Button>
  )

  if (!user) {
    return primaryButton(
      t('changePrice.item.renderButton.toPurchasePage'),
      transitionToAuth
    )
  }
  if (isPurchase) {
    return primaryButton(
      t('changePrice.item.renderButton.purchased'),
      () => null,
      true
    )
  }
  if (
    type === PriceType.ONE_TIME ||
    (isAdminItemSelectPage && user.admin && !priceId) ||
    (type === PriceType.RECURRING && !user.admin && !subscriptionObj)
  ) {
    return isAdminItemSelectPage && team!.is_invoice_payment ? (
      <></>
    ) : (
      primaryButton(
        t('changePrice.item.renderButton.toPurchasePage'),
        checkoutSession
      )
    )
  }
  return primaryButton(
    t('changePrice.item.renderButton.changePlan'),
    changePrice
  )
}

const InvoiceButton: React.FC<{
  team: ITeam | null | undefined
  subscriptionObj: ISubscriptionObject | null
  productInfo: IProductInfo
  couponId: string | undefined
  isPurchase: boolean
  isAdminItemSelectPage: boolean
  isItemSelectPage: boolean
  isItemSelectVideoPage: boolean
  createInvoicePayment: () => void
}> = (props): JSX.Element => {
  const { t } = useTranslation('userSubscription')
  const { type } = props.productInfo

  if (
    (props.isAdminItemSelectPage &&
      props.team &&
      props.team.is_invoice_payment) ||
    ((props.isItemSelectPage || props.isItemSelectVideoPage) &&
      props.team &&
      props.team.stripe.allow_invoice_payment &&
      ((type === PriceType.RECURRING && !props.subscriptionObj) ||
        type === PriceType.ONE_TIME))
  ) {
    return (
      <div className="my-4">
        <Button
          onClick={props.createInvoicePayment}
          color="primary"
          type="button"
          disabled={
            props.isPurchase ||
            Boolean(
              props.couponId &&
                props.couponId.length > 0 &&
                !props.isAdminItemSelectPage
            )
          }
        >
          {t('changePrice.item.invoiceButton.toInvoicePayment')}
        </Button>
      </div>
    )
  }

  return <></>
}

const RenderRemarks: React.FC<{
  productInfo: IProductInfo
  isChangePricePage: boolean
}> = ({ productInfo, isChangePricePage }): JSX.Element => {
  const { t } = useTranslation('userSubscription')
  const { type, trial, valid_days } = productInfo
  const remarks = (content: string) => (
    <div className="my-4">
      <Row className="mb-2">
        <Col className="mx-auto text-white">{content}</Col>
      </Row>
    </div>
  )

  if (type === PriceType.RECURRING && trial > 0 && !isChangePricePage) {
    return remarks(t('changePrice.item.renderRemarks.trialPeriod', { trial }))
  }
  if (type === PriceType.ONE_TIME && valid_days > 0) {
    return remarks(
      t('changePrice.item.renderRemarks.validPeriod', { valid_days })
    )
  }
  return <></>
}

const RenderInputCoupon: React.FC<{
  coupons: ICoupon[] | undefined
  productInfo: IProductInfo
  isPurchase: boolean
  isItemSelectPage: boolean
  isItemSelectVideoPage: boolean
  user: IUser | null
  subscriptionObj: ISubscriptionObject | null
  isCouponOpen: boolean
  setIsCouponOpen: () => void
  setCouponId: React.Dispatch<React.SetStateAction<string | undefined>>
}> = ({
  coupons,
  productInfo: { type },
  isPurchase,
  isItemSelectPage,
  isItemSelectVideoPage,
  user,
  subscriptionObj,
  isCouponOpen,
  setIsCouponOpen,
  setCouponId,
}): JSX.Element => {
  const { t } = useTranslation('userSubscription')
  if (getCoupons(user, coupons)) return <></>
  if (
    (!isPurchase && !user && isItemSelectVideoPage) ||
    (!isPurchase &&
      !isAdmin(user) &&
      !user!.customer_id &&
      (isItemSelectPage || isItemSelectVideoPage)) ||
    (!isAdmin(user) && user!.customer_id && type === PriceType.ONE_TIME) ||
    (!isAdmin(user) &&
      user!.customer_id &&
      type === PriceType.RECURRING &&
      !subscriptionObj) ||
    (isAdmin(user) && type === PriceType.RECURRING && !subscriptionObj)
  ) {
    return (
      <div className="my-4">
        <span onClick={setIsCouponOpen} className="coupon-toggle">
          {t('changePrice.item.renderInputCoupon.hasCoupon')}
          <i
            className={classNames('ni ml-1', {
              'ni-bold-down': isCouponOpen,
              'ni-bold-up': !isCouponOpen,
            })}
          />
        </span>
        <Collapse isOpen={isCouponOpen}>
          <div className="mt-4">
            <Row className="mb-2">
              <Col sm={3} className="mx-auto">
                <Input
                  onChange={({ target }) => setCouponId(target.value)}
                  type="text"
                  placeholder={t(
                    'changePrice.item.renderInputCoupon.couponCode'
                  )}
                />
              </Col>
            </Row>
          </div>
        </Collapse>
      </div>
    )
  }
  return <></>
}

const isAdmin = (user: IUser | null) => user && user.admin

const getCoupons = (user: IUser | null, coupons: ICoupon[] | undefined) =>
  (!isAdmin(user) && !coupons) ||
  (!isAdmin(user) && coupons && coupons.length === 0)

export default Item
