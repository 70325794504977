import dayjs from 'dayjs'
import { i18nValidation } from 'i18n/i18n'

const dateLimitPeriod = {
  oneYear: 365,
}

/**
 * 指定した分析期間が一定期間以内に設定されているかどうかを判定する
 * @param startDate `dayjs.Dayjs`
 * @param endDate `dayjs.Dayjs`
 */
export const validateAnalyticsLimitPeriod = (
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs
): void => {
  if (endDate.diff(startDate, 'day') > dateLimitPeriod.oneYear) {
    throw new Error(i18nValidation('set.analyticsPeriod'))
  }
}
