import { Routes } from 'common/enums'
import { ILive } from 'common/interfaces/live'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/LiveForm/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initLive } from 'services/admin/live_form'

const LiveForm: React.FC = () => {
  const { t } = useTranslation('adminLive')
  const history = useHistory()
  const location = useLocation<{ live: ILive }>()

  const isCreate = location.pathname === Routes.AdminLiveCreate
  const live = isCreate ? initLive() : location.state.live

  return (
    <>
      <H1 title={t(isCreate ? 'form.new' : 'form.edit')} />

      <Container fluid>
        <ButtonRight nextPage={Routes.AdminLive} content={t('form.back')} />
        <FormBody isCreate={isCreate} liveObj={live} history={history} />
      </Container>
    </>
  )
}

export default LiveForm
