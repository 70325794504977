import { EventFormat } from 'common/enums'
import { IEvent } from 'common/interfaces/event'
import { VideoStatus } from 'common/interfaces/video'
import { makeCatalogEventUrl } from 'common/link_url'
import { standardModules } from 'common/quill'
import {
  copyClipboard,
  formatEventFormat,
  getDefaultPublicationPeriod,
  getStatusOptions,
} from 'common/utils'
import FromToDateTime from 'components/atoms/Form/FromToDateTime'
import QuillForm from 'components/atoms/Input/QuillForm'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import ThumbnailCropper from 'components/molecules/Admin/ThumbnailCropper'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap'
import {
  InputType,
  getDefaults,
  getFormOptions,
  saveEvent,
  onChangeInput as serviceOnChangeInput,
  onChangeLimitedAccess as serviceOnChangeLimitedAccess,
} from 'services/admin/event_form'

interface IFormBody {
  isCreate: boolean
  eventObj: IEvent
}

const FormBody: React.FC<IFormBody> = ({ isCreate, eventObj }) => {
  const { t } = useTranslation('adminEvent')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [event, setEvent] = useState<IEvent>(eventObj)
  const [imageBlob, setImageBlob] = useState<Blob>()
  const [isImageDelete, setIsImageDelete] = useState<boolean>(false)
  const [isImageCropped, setIsImageCropped] = useState<boolean>(true)

  const { defaultCategory, defaultTags, defaultContentss } = getDefaults(
    storeCache,
    event
  )
  const defaultPublicationPeriod = getDefaultPublicationPeriod(event)
  const { tagOptions, categoryOptions, videoAndLiveOptions } =
    getFormOptions(storeCache)
  const statusOptions = getStatusOptions().map((option, key) => (
    <option key={key} value={option.value}>
      {option.label}
    </option>
  ))

  const publicUrl = makeCatalogEventUrl(storeCache.team!.id, event.id)
  const copyPublicUrl = () => copyClipboard(publicUrl)

  const onChangeInput = (type: InputType, e: any) =>
    serviceOnChangeInput(event, setEvent, type, e)
  const onChangeLimitedAccess = (
    type: 'start' | 'end',
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeLimitedAccess(event, setEvent, type, e)

  const save = () =>
    saveEvent(
      history,
      event,
      imageBlob,
      isImageDelete,
      isImageCropped,
      storeCache,
      isCreate
    )

  return (
    <Form>
      <FormGroup row>
        <Col md="10">
          <Label for="name">{t('form.name')}</Label>
          <Input
            id="name"
            type="text"
            onChange={(e) => onChangeInput(InputType.NAME, e)}
            value={event.name}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="format">
            <InfoTooltip
              content={t('form.format.title')}
              tooltipContent={t('form.format.tips')}
            />
          </Label>
          <Input
            id="format"
            value={event.format}
            onChange={(e) => onChangeInput(InputType.FORMAT, e)}
            type="select"
          >
            <option value={EventFormat.APPLICATION}>
              {formatEventFormat(EventFormat.APPLICATION)}
            </option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="4">
          <Label for="status">
            <InfoTooltip
              content={t('form.status.title')}
              tooltipContent={t('form.status.tips')}
            />
          </Label>
          <Input
            id="status"
            value={event.status}
            onChange={(e) => onChangeInput(InputType.STATUS, e)}
            type="select"
          >
            {statusOptions}
          </Input>
        </Col>
      </FormGroup>

      {event.status === VideoStatus.PUBLICATION_PERIOD && (
        <FromToDateTime
          fromLabel={t('form.publicationPeriod.from')}
          toLabel={t('form.publicationPeriod.to')}
          from={defaultPublicationPeriod.start}
          to={defaultPublicationPeriod.end!}
          onChange={(e, isFrom) =>
            onChangeLimitedAccess(isFrom ? 'start' : 'end', e)
          }
        />
      )}

      <FromToDateTime
        fromLabel={t('form.applicationPeriod.from')}
        toLabel={t('form.applicationPeriod.to')}
        from={event.application_period_from}
        to={event.application_period_to}
        onChange={(e, isFrom) =>
          onChangeInput(
            isFrom
              ? InputType.APPLICATION_PERIOD_FROM
              : InputType.APPLICATION_PERIOD_TO,
            e
          )
        }
      />
      <FromToDateTime
        fromLabel={t('form.holdPeriod.from')}
        toLabel={t('form.holdPeriod.to')}
        from={event.hold_from}
        to={event.hold_to}
        onChange={(e, isFrom) =>
          onChangeInput(isFrom ? InputType.HOLD_FROM : InputType.HOLD_TO, e)
        }
      />
      <ThumbnailCropper
        type="event"
        croppedData={imageBlob}
        setCroppedData={setImageBlob}
        handleIsImageCropped={setIsImageCropped}
        edit={{ defaultImageUrl: event.image, isImageDelete, setIsImageDelete }}
      />
      <FormGroup row>
        <Col md="10">
          <Label for="category">
            <InfoTooltip
              content={t('form.category.title')}
              tooltipContent={t('form.category.tips')}
            />
          </Label>
          <Input
            id="category"
            value={defaultCategory?.id}
            onChange={(e) => onChangeInput(InputType.CATEGORY_ID, e)}
            type="select"
          >
            {categoryOptions}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="8">
          <Label for="tags">
            <InfoTooltip
              content={t('form.tag.title')}
              tooltipContent={t('form.tag.tips')}
            />
          </Label>
          <Select
            id="tags"
            defaultValue={defaultTags}
            onChange={(e) => onChangeInput(InputType.TAG_IDS, e)}
            options={tagOptions}
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.overview.title')}
              tooltipContent={t('form.overview.tips')}
            />
          </Label>
          <pre>
            <QuillForm
              value={event.overview}
              onChange={(e) => onChangeInput(InputType.OVERVIEW, e)}
              modules={standardModules()}
            />
          </pre>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="8">
          <Label for="contents">
            <InfoTooltip
              content={t('form.contents.title')}
              tooltipContent={t('form.contents.tips')}
            />
          </Label>
          <Select
            id="contents"
            defaultValue={defaultContentss}
            onChange={(e) => onChangeInput(InputType.CONTENTS, e)}
            options={videoAndLiveOptions}
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
      </FormGroup>
      {storeCache.team?.is_site_public && (
        <FormGroup check row className="mb-4">
          <Col md="10">
            <Label check>
              <Input
                defaultChecked={event.is_public}
                onChange={(e) => onChangeInput(InputType.IS_PUBLIC, e)}
                type="checkbox"
              />
              {t('form.public.title')}
              {detail(t('form.public.detail'))}
            </Label>
            {!isCreate && event.is_public && eventObj.is_public && (
              <div className="mt-2">
                <InputGroup>
                  <Input
                    value={publicUrl}
                    readOnly
                    onFocus={({ target }) => target.select()}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="primary" onClick={copyPublicUrl}>
                      {t('form.copy')}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            )}
          </Col>
        </FormGroup>
      )}
      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {t('form.save')}
        </Button>
      </div>
    </Form>
  )
}

const detail = (content: string) => (
  <div style={{ fontSize: '0.8rem' }}>{content}</div>
)

export default FormBody
