import { IAdministratorsNotifyType } from 'common/enums'
import { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IComment, ICommentInfo } from 'common/interfaces/comment'
import {
  IReplyComment,
  IReplyCommentContent,
  IReplyCommentInfo,
} from 'common/interfaces/reply_comment'
import { IUser } from 'common/interfaces/user'
import { IVideo } from 'common/interfaces/video'
import { makeVideoUrl } from 'common/link_url'
import { getRelativeTimeByJa, nowTimestamp } from 'common/times'
import { isLoggedIn } from 'common/utils'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { sendToAdministrators } from 'repositories/functions/functions_mail'
import {
  get as getComment,
  remove as removeComment,
  store as storeComment,
} from 'repositories/store/firebase_comment'
import { addVideoCommentedLog } from 'repositories/store/firebase_log'
import {
  get as getReplyComment,
  remove as removeReplyComment,
  store as stpreReplyComment,
} from 'repositories/store/firebase_reply_comment'
import { findById } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'
import { v4 as uuidv4 } from 'uuid'

/**
 * 動画に対するコメントを取得し、返信コメント用オブジェクトを生成する
 */
export const getComments = async (
  storeCache: IStoreCache,
  video: IVideo | null,
  setComments: React.Dispatch<React.SetStateAction<ICommentInfo[]>>,
  setReplyCommentContent: React.Dispatch<
    React.SetStateAction<IReplyCommentContent[]>
  >
): Promise<void> => {
  if (!video || !auth.currentUser) {
    return
  }

  const userCache: IUser[] = []
  const getUser = async (userId: string): Promise<IUser | null> => {
    let user = userCache.find((user) => user.id === userId) ?? null
    if (!user) {
      user = await findById(storeCache.team!, userId)
      if (user) {
        userCache.push(user)
      }
    }
    return user
  }

  try {
    const commentInfo: ICommentInfo[] = []
    const comments = await getComment(storeCache.team!, video)
    for (const comment of comments) {
      const commentUser = await getUser(comment.user_id)

      const replyCommentInfo: IReplyCommentInfo[] = []
      const replyComments = await getReplyComment(
        storeCache.team!,
        video,
        comment
      )
      for (const replyComment of replyComments) {
        const replyUser = await getUser(replyComment.user_id)
        replyCommentInfo.push({
          ...replyComment,
          user_name: replyUser?.name ?? '',
          created_at: getRelativeTimeByJa(replyComment.created_at),
        })
      }
      commentInfo.push({
        ...comment,
        user_name: commentUser?.name ?? '',
        created_at: getRelativeTimeByJa(comment.created_at),
        reply_comments: replyCommentInfo,
      })
    }

    const tmpReplyCommentContent: IReplyCommentContent[] = commentInfo.map(
      (info) => ({
        comment_id: info.id,
        show: false,
        content: '',
      })
    )
    setReplyCommentContent(tmpReplyCommentContent)
    setComments(commentInfo)
  } catch (error) {
    console.log(error)
    alertService.show(false, i18nAlert('video.failedGetComment'))
  }
}

/**
 * 動画に対するコメントを保存する
 */
export const saveComment = async (
  storeCache: IStoreCache,
  video: IVideo,
  commentInputRef: React.RefObject<HTMLTextAreaElement>,
  reloadComments: () => void
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  const commentStr = commentInputRef.current?.value?.trim() ?? ''
  try {
    if (!commentStr) {
      throw new Error(i18nValidation('input.comment'))
    }

    const commentObj: IComment = {
      id: uuidv4(),
      user_id: storeCache.user!.id,
      content: commentStr,
      created_at: nowTimestamp(),
    }

    await storeComment(storeCache.team!, video, commentObj)
    await sendToAdministrators(
      storeCache.team!.id,
      IAdministratorsNotifyType.VideoCommented,
      '',
      '',
      '',
      '',
      video.name,
      makeVideoUrl(video.id, storeCache.team!.id)
    )
    await addVideoCommentedLog(
      storeCache.team!,
      storeCache.user!,
      video.id,
      'video_commenteds'
    )
    commentInputRef.current && (commentInputRef.current.value = '')
    reloadComments()
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * コメントに対する返信コメントを保存する
 */
export const saveReplyComment = async (
  storeCache: IStoreCache,
  video: IVideo,
  comment: ICommentInfo,
  replyCommentContent: IReplyCommentContent,
  reloadComments: () => void
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    if (!replyCommentContent.content) {
      throw new Error(i18nValidation('input.replyComment'))
    }

    const replyCommentObj: IReplyComment = {
      id: uuidv4(),
      user_id: storeCache.user!.id,
      content: replyCommentContent.content,
      created_at: nowTimestamp(),
    }

    await stpreReplyComment(storeCache.team!, video, comment, replyCommentObj)
    await sendToAdministrators(
      storeCache.team!.id,
      IAdministratorsNotifyType.VideoReplyCommented,
      '',
      '',
      '',
      '',
      video.name,
      makeVideoUrl(video.id, storeCache.team!.id)
    )
    await addVideoCommentedLog(
      storeCache.team!,
      storeCache.user!,
      video.id,
      'video_reply_commenteds'
    )
    reloadComments()
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * コメントを削除する
 */
export const deleteComment = (
  storeCache: IStoreCache,
  video: IVideo,
  comment: ICommentInfo,
  reloadComments: () => void
): void => {
  if (!isLoggedIn(storeCache)) return

  try {
    const { id, admin } = storeCache.user!
    if (!admin && id !== comment.user_id) {
      throw new Error(i18nAlert('video.noDeleteAuthority'))
    }

    modalService.show(i18nAlert('modal.confirm.deleteComment'), async () => {
      await removeComment(storeCache.team!, video, comment)
      reloadComments()
    })
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * 返信コメントを削除する
 */
export const deleteReplyComment = (
  storeCache: IStoreCache,
  video: IVideo,
  comment: ICommentInfo,
  replyComment: IReplyCommentInfo,
  reloadComments: () => void
): void => {
  if (!isLoggedIn(storeCache)) return

  try {
    const { id, admin } = storeCache.user!
    if (!admin && id !== replyComment.user_id) {
      throw new Error(i18nAlert('video.noDeleteAuthority'))
    }

    modalService.show(i18nAlert('modal.confirm.deleteComment'), async () => {
      await removeReplyComment(storeCache.team!, video, comment, replyComment)
      reloadComments()
    })
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
