import { Routes } from 'common/enums'
import { IEnquete } from 'common/interfaces/enquete'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/EnqueteCustomize/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { Container } from 'reactstrap'

const EnqueteCustomize: React.FC = () => {
  const { t } = useTranslation('adminEnquete')
  const history = useHistory()
  const { pathname, state } = useLocation<{ enquete: IEnquete }>()
  const isCreate = pathname === Routes.AdminEnqueteCreate
  const enquete: IEnquete = isCreate ? ({} as IEnquete) : state.enquete

  return (
    <>
      <H1 title={t('form.title')} />

      <Container fluid>
        <ButtonRight nextPage={Routes.AdminEnquete} content={t('form.back')} />
        <FormBody isCreate={isCreate} enqueteObj={enquete} history={history} />
      </Container>
    </>
  )
}

export default EnqueteCustomize
