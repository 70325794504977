import axios from 'axios'
import {
  ICheckVideoLibraryReady,
  ICreateVideoLibrary,
} from 'common/interfaces/video_library'

const CF_API_BASE_URL = String(process.env.REACT_APP_FIREBASE_FUNCTIONS_URI)

/**
 * Create video library
 * @param teamId team id
 * @param operatedUserId operated user id
 * @returns if success return `ICreateVideoLibrary`, otherwise return null
 */
export const createVideoLibrary = async (
  teamId: string,
  operatedUserId: string
): Promise<ICreateVideoLibrary | null> => {
  const url = `${CF_API_BASE_URL}/video_library`
  const data = { teamId, operatedUserId }
  try {
    const res = await axios.post<{
      success: boolean
      data: ICreateVideoLibrary | null
    }>(url, data)
    if (res.data.success) {
      return res.data.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

/**
 * Check video library ready
 * @param teamId operated team id
 * @param userId operated user id
 * @returns is ready or not
 */
export const checkVideoLibraryReady = async (
  teamId: string,
  userId: string
): Promise<boolean> => {
  const url = `${CF_API_BASE_URL}/video_library/ready`
  const data = {
    operatedTeamId: teamId,
    operatedUserId: userId,
  }
  try {
    const res = await axios.post<ICheckVideoLibraryReady>(url, data)
    return res.data.success && res.data.data.isReady
  } catch (e) {
    console.error(e)
  }
  return false
}
