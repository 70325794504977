import { ITestQuestion, ITestQuestionType } from 'common/interfaces/test'
import { hideToolbarModules } from 'common/quill'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Button, Col, Input, InputGroup, InputGroupAddon } from 'reactstrap'
import { AddQuestionInputType } from 'services/admin/test_form'
import QuillForm from '../Input/QuillForm'
import RequiredText from '../Required/RequiredText'
import './addcard.scss'

type TAddCard = {
  title: string
  question: ITestQuestion
  onChangeQuestionInput: (type: AddQuestionInputType, e: any) => void
  onChangeOption: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void
  onAddOption: React.MouseEventHandler<HTMLButtonElement> | undefined
  onDeleteOption: (index: number) => void
  onAddItem: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const AddCard: React.FC<TAddCard> = ({
  title,
  question,
  onChangeQuestionInput,
  onChangeOption,
  onAddOption,
  onDeleteOption,
  onAddItem,
}) => {
  const { t } = useTranslation('adminTest')

  return (
    <Col xs="12">
      <div className="add-card-options">
        <div>{title}</div>
        <RequiredText className="mt-2">
          {t('form.questions.card.question')}
        </RequiredText>
        <pre className="mb-0">
          <QuillForm
            value={question.name}
            onChange={(e) =>
              onChangeQuestionInput(AddQuestionInputType.TITLE, e)
            }
            modules={hideToolbarModules()}
          />
        </pre>
        <RequiredText className="mt-2">
          {t('form.questions.card.optionsList')}
        </RequiredText>
        {question.options.map((option, index) => (
          <InputGroup className="mb-3" key={index}>
            <Input
              type="text"
              value={option}
              onChange={(e) => onChangeOption(e, index)}
            />
            <InputGroupAddon addonType="append">
              <Button
                onClick={() => onDeleteOption(index)}
                color="primary"
                outline
              >
                -
              </Button>
            </InputGroupAddon>
          </InputGroup>
        ))}
        <div className="text-right">
          <Button onClick={onAddOption} color="primary" outline>
            +
          </Button>
        </div>

        <RequiredText className="mt-2">
          {t('form.questions.card.correctInput.title')}
        </RequiredText>

        <CorrectInput
          question={question}
          onChange={(e) =>
            onChangeQuestionInput(AddQuestionInputType.CORRECT_INDEX, e)
          }
        />

        <div className="mt-3">{t('form.questions.card.explain')}</div>
        <Input
          value={question.explain}
          onChange={(e) =>
            onChangeQuestionInput(AddQuestionInputType.EXPLAIN, e)
          }
        />

        <div className="text-right mt-3">
          <Button onClick={onAddItem} color="primary" size="sm" type="button">
            {t('form.questions.card.add')}
          </Button>
        </div>
      </div>
    </Col>
  )
}

const CorrectInput: React.FC<{
  question: ITestQuestion
  onChange: (e: any) => void
}> = ({ question, onChange }) => {
  const { t } = useTranslation('adminTest')
  const { correct_index, type, options } = question

  if (type === ITestQuestionType.RADIO) {
    return (
      <Input type="select" value={correct_index as number} onChange={onChange}>
        <option value="-1" disabled>
          {t('form.questions.card.correctInput.placeholder')}
        </option>
        {options.map((option, index) => (
          <option key={index} value={index}>
            {option}
          </option>
        ))}
      </Input>
    )
  }

  return (
    <Select
      onChange={onChange}
      options={options.map((op, i) => ({ label: op, value: i }))}
      closeMenuOnSelect={false}
      isMulti
    />
  )
}

export default AddCard
