import Chart from 'chart.js'
import { IDailyViewCount } from 'common/interfaces/analytics'
import { IFlattedLog, LogType } from 'common/interfaces/log'
import { IFromTo } from 'common/interfaces/time'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import isBetween from 'dayjs/plugin/isBetween'
import { i18nAdminAnalytics } from 'i18n/i18n'
import { DatePeriod, getDatePeriodDuration } from 'services/admin/analytics'
import { alertService } from 'services/alert'
import { validateAnalyticsLimitPeriod } from 'services/validation/analytics'

/**
 * 日毎の分析を算出する共通関数
 * @param allUserLogs `IFlattedLog[]`
 * @param setViewCounts state setter
 * @param startDate `dayjs.Dayjs`
 * @param endDate `dayjs.Dayjs`
 * @returns `void`
 */
const setDailyViewCounts = (
  allUserLogs: IFlattedLog[],
  setViewCounts: React.Dispatch<React.SetStateAction<IDailyViewCount[]>>,
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs
): void => {
  dayjs.extend(isBetween)

  const dayCount = endDate.diff(startDate, 'day') + 1
  const dateFormat = i18nAdminAnalytics('total.table.dateFormat')

  const result: IDailyViewCount[] = []
  for (let i = 0; i < dayCount; i++) {
    const date = startDate.locale('ja').add(i, 'day').format(dateFormat)
    result.push({
      date,
      page_open_count: 0,
      video_play_count: 0,
      not_play_percent: '-',
      video_ended_count: 0,
      video_ended_percent: '-',
    })
  }

  allUserLogs
    .filter((l) =>
      dayjs.unix(l.timestamp.seconds).isBetween(startDate, endDate, null, '[]')
    )
    .forEach((vh) => {
      const date = dayjs
        .unix(vh.timestamp.seconds)
        .locale('ja')
        .format(dateFormat)
      const dateObj = result.find((r) => r.date === date)
      if (!dateObj) return

      switch (vh.type) {
        case LogType.VIDEO_PAGE_OPEN:
          dateObj.page_open_count += 1
          break
        case LogType.VIDEO_PLAY:
          dateObj.video_play_count += 1
          break
        case LogType.VIDEO_ENDED:
          dateObj.video_ended_count += 1
          break
      }
    })

  result.forEach((dvc) => {
    if (dvc.page_open_count > 0 || dvc.video_play_count > 0) {
      const percent = (1 - dvc.video_play_count / dvc.page_open_count) * 100
      dvc.not_play_percent = `${Math.round(percent)}%`
    }
    if (dvc.video_play_count > 0 || dvc.video_ended_count > 0) {
      const videoEndedPercent =
        (dvc.video_ended_count / dvc.video_play_count) * 100
      dvc.video_ended_percent = `${Math.round(videoEndedPercent)}%`
    }
  })
  setViewCounts(result)
}

/**
 * 日毎の分析を日付タイプに応じて算出
 * @param allUserLogs `IFlattedLog[]`
 * @param datePeriodType `DatePeriod`
 * @param setViewCounts state setter
 * @param target `{ date: string, fromTo: IFromTo }`
 * @returns `void`
 */
export const setDailyViewCountData = (
  allUserLogs: IFlattedLog[],
  datePeriodType: DatePeriod,
  setViewCounts: React.Dispatch<React.SetStateAction<IDailyViewCount[]>>,
  target: {
    date: string
    fromTo: IFromTo
  }
): void => {
  const duration = getDatePeriodDuration(datePeriodType, setViewCounts, target)
  if (!duration) return

  try {
    validateAnalyticsLimitPeriod(duration.start, duration.end)
  } catch (error: any) {
    alertService.show(false, error.message)
    setViewCounts([])
    return
  }

  setDailyViewCounts(allUserLogs, setViewCounts, duration.start, duration.end)
}

/**
 * `IDailyViewCount[]`からChartjsのデータを作成する
 */
export const createDailyViewCountChartData = (
  viewCounts: IDailyViewCount[],
  selectedDatePeriod: string
): {
  chartData: Chart.ChartData
  chartOption: Chart.ChartOptions
} => {
  const lineColors = ['#a541f7', '#7370f7', '#19cbfb']

  const title = (() => {
    if (viewCounts.length <= 0) return ''
    if (selectedDatePeriod === DatePeriod.EVERY_MONTH) {
      const [year, month] = viewCounts[0].date.split('-')
      return i18nAdminAnalytics('total.chart.title.month', { year, month })
    }
    const start = viewCounts[0].date.split('-')
    const end = viewCounts[viewCounts.length - 1].date.split('-')
    return i18nAdminAnalytics('total.chart.title.specifiedPeriod', {
      startYear: start[0],
      startMonth: start[1],
      startDay: start[2],
      endYear: end[0],
      endMonth: end[1],
      endDay: end[2],
    })
  })()

  const i18n = i18nAdminAnalytics('logType', { returnObjects: true })
  const chartData: Chart.ChartData = {
    labels: viewCounts.map((v) =>
      v.date
        .replace(/ \(.*\)$/, '')
        .replace(/^\d+?-/, '')
        .replace('-', '/')
    ),
    datasets: [
      {
        label: i18n.videoPageOpen,
        data: viewCounts.map((v) => v.page_open_count),
        borderColor: lineColors[0],
        backgroundColor: lineColors[0],
        fill: false,
        tension: 0.4,
      },
      {
        label: i18n.videoPlay,
        data: viewCounts.map((v) => v.video_play_count),
        borderColor: lineColors[1],
        backgroundColor: lineColors[1],
        fill: false,
        tension: 0.4,
      },
      {
        label: i18n.videoEnded,
        data: viewCounts.map((v) => v.video_ended_count),
        borderColor: lineColors[2],
        backgroundColor: lineColors[2],
        fill: false,
        tension: 0.4,
      },
    ],
  }

  const chartOption: Chart.ChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        font: { size: 20 },
        text: title,
      },
    },
  }

  return { chartData, chartOption }
}
