import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICreator } from 'common/interfaces/creator'
import { isLoggedIn } from 'common/utils'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { reloadCachedCreators } from 'providers/AuthProvider'
import { removeImageFile } from 'repositories/storage/firebase_creator'
import { remove } from 'repositories/store/firebase_creator'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 投稿者を使用している動画/配信の件数を返却する
 */
export const getCreatorUseCount = (
  creator: ICreator,
  storeCache: IStoreCache
) => {
  return [...storeCache.videos, ...storeCache.lives].filter(
    (video) => video.creator_id === creator.id
  ).length
}

/**
 * 投稿者削除処理
 */
export const removeCreator = (creator: ICreator, storeCache: IStoreCache) => {
  if (!isLoggedIn(storeCache)) return
  if (getCreatorUseCount(creator, storeCache) > 0) {
    alertService.show(false, i18nValidation('cantDelete.inUse.creator'))
    return
  }

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      if (creator.image) {
        await removeImageFile(storeCache.team!, creator)
      }
      await remove(storeCache.team!, creator)
      await reloadCachedCreators(storeCache)
      alertService.show(true, i18nAlert('deleted.message'))
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('cantDeleted'))
    }
  })
}
