import { IStoreCache } from 'common/interfaces/auth_provider'
import { ILandingPage } from 'common/interfaces/team'
import { checkFirebaseError, isLoggedIn } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedTeam } from 'providers/AuthProvider'
import {
  FileType,
  removeImageFile,
  upload,
} from 'repositories/storage/firebase_team'
import {
  updateLandingPage,
  updateLandingPageBannerImage,
} from 'repositories/store/firebase_team'
import { alertService } from 'services/alert'
import { v4 as uuidv4 } from 'uuid'

/**
 * 各ファイルの`onChange`イベントハンドラ
 */
export const onChangeFile = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>
) => {
  e.target.files && e.target.files.length > 0 && setFile(e.target.files[0])
}

/**
 * バナー画像を削除する(ドキュメント更新 & ファイル削除)
 */
export const deleteBannerImage = async (
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  const team = storeCache.team!
  if (!team.landing_page.banner_image) return

  try {
    const fileId = team.landing_page.banner_image.id
    await removeImageFile(team, fileId, FileType.LANDING_IMAGES)

    await updateLandingPageBannerImage(team, null)
    await reloadCachedTeam(storeCache)
    alertService.show(true, i18nAlert('deleted.bannerImage'))
  } catch (error) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, i18nAlert('deleted.fail.bannerImage'))
  }
}

/**
 * `Team.landing_page`更新処理
 */
export const update = async (
  storeCache: IStoreCache,
  bannerFile: File | undefined,
  lp: ILandingPage,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (!isLoggedIn(storeCache)) return

  const team = storeCache.team!
  setIsLoading(true)

  try {
    if (bannerFile) {
      const fileId = team.landing_page.banner_image?.id || uuidv4()
      lp.banner_image = {
        id: fileId,
        path: await upload(team, bannerFile, fileId, FileType.LANDING_IMAGES),
        link: '',
        asNewTab: false,
      }
    }

    await updateLandingPage(team, lp)
    await reloadCachedTeam(storeCache)

    alertService.show(true, i18nAlert('updated.landingPage'))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }

  setIsLoading(false)
}
