import { EventFormat, LimitPlay } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IBookmark } from 'common/interfaces/bookmark'
import { ICreator } from 'common/interfaces/creator'
import { IEvent } from 'common/interfaces/event'
import { ILive } from 'common/interfaces/live'
import { IPlaylist } from 'common/interfaces/playlist'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { IVideo } from 'common/interfaces/video'
import { isEventPage } from 'common/link'
import { isAfterTimestamp, isBeforeTimestamp } from 'common/times'
import { checkFirebaseError, isLoggedIn } from 'common/utils'
import { Location } from 'history'
import { useEffect, useState } from 'react'
import { fetchPurchasedInfos } from 'repositories/functions/functions_stripe'
import { get as getEventApplications } from 'repositories/store/firebase_event_application'
import {
  addVideoBookmarkLog,
  addVideoPageOpenLog,
  addVideoUnBookmarkLog,
} from 'repositories/store/firebase_log'
import { addBookmark, removeBookmark } from 'repositories/store/firebase_user'
import {
  addContentInWatchLater,
  deleteContentInWatchLater,
  getWatchLater,
} from 'repositories/store/firebase_watchlist'
import { alertService } from 'services/alert'
import { isLiveEnded, isLiveStarted } from './live'
import { isAfterPlayablePeriod, isBeforePlayablePeriod } from './video'

/**
 * 動画オブジェクトを取得する
 */
export const getVideoData = (
  { videos }: IStoreCache,
  videoId: string
): IVideo | null => videos.find((v) => v.id === videoId) ?? null

/**
 * プレイリストだった場合、次の動画を返却する。
 * 次の動画が存在しない場合、nullを返却する。
 * 次の動画が存在する且つ次の動画IDがnullの場合、次の動画IDを用いて再度この関数を実行する。
 *
 * @param storeCache `IStoreCache`
 * @param parentId string | undefined
 * @param thisContentId string  再帰した場合、nextVideoIdが指定される
 * @returns `IVideo` | null
 */
export const nextContent = (
  storeCache: IStoreCache,
  parentId: string | undefined,
  thisContentId: string
): IVideo | ILive | null => {
  if (parentId) {
    const thisDoc = isEventPage()
      ? storeCache.events.find((p) => p.id === parentId)
      : storeCache.playlists.find((p) => p.id === parentId)
    if (thisDoc) {
      let contentIds = []
      if (isEventPage()) {
        const doc = thisDoc as IEvent
        contentIds = doc.contents
      } else {
        const doc = thisDoc as IPlaylist
        contentIds = doc.video_ids
      }
      const thisIndex = contentIds.indexOf(thisContentId)
      if (thisIndex + 1 < contentIds.length) {
        const nextId = contentIds[thisIndex + 1]
        return (
          storeCache.videos.find((v) => v.id === nextId) ??
          storeCache.lives.find((l) => l.id === nextId) ??
          nextContent(storeCache, parentId, nextId)
        )
      }
    }
  }
  return null
}

/**
 * 同じカテゴリに属するこの動画とは違う動画を3つ返却する
 */
const sameCategory3Videos = (
  allVideos: IVideo[],
  thisVideo: IVideo
): IVideo[] => {
  if (!thisVideo.category_id) return []
  return allVideos
    .filter(
      (v) =>
        v.id !== thisVideo.id &&
        v.category_id === thisVideo.category_id &&
        !v.is_list_hidden
    )
    .slice(0, 3)
}

/**
 * この動画がお気に入り登録されてるかどうか
 */
const isBookmarked = (
  userBookmarks: IBookmark[] | undefined,
  targetVideoId: string
): boolean =>
  userBookmarks?.find((v) => v.video_id === targetVideoId) !== undefined

/**
 * 動画詳細情報を取得する
 */
export const useVideoData = (
  isCatalogPage: boolean,
  isEventPage: boolean,
  storeCache: IStoreCache,
  parentId: string | undefined,
  video: IVideo
): {
  event: IEvent | null
  creator: ICreator | null
  nextContent: IVideo | ILive | null
  sameCategory3Videos: IVideo[]
  limitPlay: LimitPlay
  isBookmarked: boolean
} => {
  const [limitPlay, setLimitPlay] = useState<LimitPlay>(LimitPlay.LOADING)

  const event = storeCache.events.find(({ id }) => id === parentId) ?? null

  useEffect(() => {
    ;(async () => {
      await checkLimitPlay(storeCache, isEventPage, video, event, setLimitPlay)

      if (isCatalogPage || !isLoggedIn(storeCache, true) || !video) return

      addVideoPageOpenLog(storeCache.team!, storeCache.user!, video)
    })()
  }, [isCatalogPage, isEventPage, storeCache, video, event])

  useEffect(() => {
    if (!video.playable_period || limitPlay !== LimitPlay.BEFORE) return

    const startDate = video.playable_period.from.seconds * 1000
    const diffMillSec = startDate - Date.now()

    const timer = setTimeout(() => {
      checkLimitPlay(storeCache, isEventPage, video, event, setLimitPlay)
    }, diffMillSec + 1000)

    return () => clearTimeout(timer)
  }, [storeCache, isEventPage, video, event, limitPlay])

  if (!isCatalogPage && (!isLoggedIn(storeCache, true) || !video)) {
    return {
      event: null,
      creator: null,
      nextContent: null,
      sameCategory3Videos: [],
      limitPlay,
      isBookmarked: false,
    }
  }

  return {
    event,
    creator: storeCache.creators.find((c) => c.id === video.creator_id) ?? null,
    nextContent: nextContent(storeCache, parentId, video.id),
    sameCategory3Videos: sameCategory3Videos(storeCache.videos, video),
    limitPlay,
    isBookmarked: isBookmarked(storeCache.user?.bookmarks, video.id),
  }
}

/**
 * 再生制限が無効かとカタログページでないかどうかを返却
 */
export const isNotLimitPlayAndCatalogPage = (
  limitPlay: LimitPlay,
  isCatalogPage: boolean
): boolean => limitPlay === LimitPlay.VISIBLE && !isCatalogPage

/**
 * コメント機能が有効かどうかを返却する
 */
export const isCommentActive = (
  team: ITeam | null,
  limitPlay: LimitPlay,
  isCatalogPage: boolean
): boolean =>
  isNotLimitPlayAndCatalogPage(limitPlay, isCatalogPage) && team
    ? team.is_comment_active || team.is_comment_active === undefined
    : false

/**
 * テスト機能が有効かどうかを返却する
 */
export const isTestActive = (
  video: IVideo,
  limitPlay: LimitPlay,
  isCatalogPage: boolean
): boolean =>
  isNotLimitPlayAndCatalogPage(limitPlay, isCatalogPage) &&
  (video.test?.activate_test ?? false)

const isEventApplication = (event: IEvent): boolean =>
  event.format === EventFormat.APPLICATION

/**
 * イベント内且つ、イベントが申込制且つ、ユーザがイベントに申し込んでない場合、'unapplied'を返却する
 * イベント内且つ、イベントが申込制且つ、ユーザがイベントに申し込んでない且つ、申込期間外の場合'outside_application_period'を返却する
 *
 * @param isEvent boolean
 * @param event `IEvent` | null
 * @param team `ITeam` | null
 * @param user `IUser` | null
 * @returns boolean
 */
const isEventApplicant = async (
  isEvent: boolean,
  event: IEvent | null,
  team: ITeam | null,
  user: IUser | null
): Promise<
  'before' | 'after' | 'unapplied' | 'outside_application_period' | null
> => {
  if (!isEvent || !event || !isEventApplication(event) || !team) return null

  const { application_period_from, application_period_to, hold_from, hold_to } =
    event
  const eventApplications = await getEventApplications(team, event.id)
  if (user && !eventApplications.some(({ user_id }) => user_id === user.id)) {
    if (
      isBeforeTimestamp(application_period_from) ||
      isAfterTimestamp(application_period_to)
    ) {
      return 'outside_application_period'
    }
    return 'unapplied'
  }
  if (isBeforeTimestamp(hold_from)) return 'before'
  if (isAfterTimestamp(hold_to)) return 'after'
  return null
}

/**
 * 再生開始日時より前か判定
 */
const checkContentIsBefore = (isVideo: boolean, content: IVideo | ILive) => {
  return (
    (isVideo && isBeforePlayablePeriod(content as IVideo)) ||
    (!isVideo && !isLiveStarted(content as ILive))
  )
}

/**
 * 再生制限かけている場合、ユーザが設定してある料金を購入しているか判定
 */
export const checkLimitPlay = async (
  { team, user }: IStoreCache,
  isEvent: boolean,
  content: IVideo | ILive,
  event: IEvent | null,
  setData: React.Dispatch<React.SetStateAction<LimitPlay>>,
  isVideo = true
): Promise<void> => {
  try {
    if (
      (isVideo && isAfterPlayablePeriod(content as IVideo)) ||
      (!isVideo && isLiveEnded(content as ILive))
    ) {
      return setData(LimitPlay.AFTER)
    }
    if ((!user && content.is_registered) || (!user && isEvent)) {
      return setData(LimitPlay.REGISTERED)
    }

    const eventApplicant = await isEventApplicant(isEvent, event, team, user)
    switch (eventApplicant) {
      case 'before':
        return setData(LimitPlay.BEFORE_EVENT)
      case 'after':
        return setData(LimitPlay.AFTER_EVENT)
      case 'unapplied':
        return setData(LimitPlay.UNAPPLIED)
      case 'outside_application_period':
        return setData(LimitPlay.OUTSIDE_APPLICATION_PERIOD)
    }

    if (content.price_ids.length === 0 || user?.admin) {
      if (checkContentIsBefore(isVideo, content)) {
        return setData(LimitPlay.BEFORE)
      }
      return setData(LimitPlay.VISIBLE)
    }
    if (!user || !user.customer_id) return setData(LimitPlay.UNPURCHASED)

    const purchasedPriceIds = await fetchPurchasedInfos(
      team!.stripe.account_id,
      user!.customer_id
    )
    if (
      !content.price_ids.find((priceId) =>
        purchasedPriceIds.some(({ id }) => priceId === id)
      )
    ) {
      return setData(LimitPlay.UNPURCHASED)
    }
    if (checkContentIsBefore(isVideo, content)) {
      return setData(LimitPlay.BEFORE)
    }
    setData(LimitPlay.VISIBLE)
  } catch (error: any) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, error.message)
  }
}

/**
 * お気に入りにvideoを追加する
 */
export const addUserBookmark = async (
  storeCache: IStoreCache,
  video: IVideo,
  bookmarked: boolean,
  setBookmarked: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache, true)) return

  try {
    await addBookmark(storeCache.team!, storeCache.user!, video)
    await addVideoBookmarkLog(storeCache.team!, storeCache.user!, video)
    setBookmarked(!bookmarked)
  } catch (error: any) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, error.message)
  }
}

/**
 * お気に入りにvideoを削除する
 */
export const removeUserBookmark = async (
  storeCache: IStoreCache,
  video: IVideo,
  bookmarked: boolean,
  setBookmarked: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache, true)) return

  try {
    await removeBookmark(storeCache.team!, storeCache.user!, video)
    await addVideoUnBookmarkLog(storeCache.team!, storeCache.user!, video)
    setBookmarked(!bookmarked)
  } catch (error: any) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, error.message)
  }
}

/**
 * 後で見るにvideoを追加しているかどうか
 */
export const checkWatchLater = async (
  storeCache: IStoreCache,
  video: IVideo,
  setWatchLater: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache, true)) return

  try {
    const watchLater = await getWatchLater(
      storeCache.team!,
      storeCache.user!.id
    )

    const isWatchLater = watchLater?.contents.some(
      (c) => c.content_id === video.id
    )
    setWatchLater(isWatchLater ?? false)
  } catch (error: any) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, error.message)
  }
}

/**
 * 後で見るにvideoを追加/削除する
 */
export const onChangeWatchLater = async (
  storeCache: IStoreCache,
  video: IVideo,
  watchLater: boolean,
  setWatchLater: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  if (!isLoggedIn(storeCache, true)) return

  try {
    if (!watchLater) {
      await addContentInWatchLater(
        storeCache.team!,
        storeCache.user!.id,
        video.id
      )
    } else {
      await deleteContentInWatchLater(
        storeCache.team!,
        storeCache.user!.id,
        video.id
      )
    }
    setWatchLater(!watchLater)
  } catch (error: any) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, error.message)
  }
}

/**
 * URL Queryから自動再生が有効かどうか返却する
 */
export const isAutoPlay = (location: Location): boolean => {
  const params = new URLSearchParams(location.search)
  return params.get('autoplay') === 'true'
}
