import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { IMailTemplate } from 'common/interfaces/team'
import { dateFormat } from 'common/times'
import { isLoggedIn } from 'common/utils'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  createOverviewContent,
  getMailTemplateEventName,
  makeCustomOperationButton,
  makeCustomOperationButtons,
} from 'components/atoms/Table/ElementsOnTable'
import { AuthContext } from 'providers/AuthProvider'
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { sendTestEMail } from 'services/admin/mail_template'
import TestMailModal from './TestMailModal'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminMailTemplate')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [template, setTemplate] = useState<IMailTemplate | undefined>(undefined)

  // テスト送信モーダルの開閉
  const onToggleModal = useCallback(() => {
    setShowModal((prev) => !prev)
  }, [setShowModal])

  // モーダルの「送信」ボタンで送信する
  const handleOnSubmit = useCallback(
    async (email: string) => {
      if (!isLoggedIn(storeCache, true)) {
        return
      }
      await sendTestEMail(email, storeCache, template)
    },
    [storeCache, template]
  )

  const mailTemplates = [...storeCache.team!.mail_templates]

  const columns = useMemo<ColumnDef<IMailTemplate>[]>(
    () => [
      { header: 'No', accessorFn: (_, i) => mailTemplates.length - i },
      {
        header: t('list.table.eventName'),
        accessorFn: (mt) => getMailTemplateEventName(mt.event_name),
      },
      { header: t('list.table.eventDetail'), accessorKey: 'event_detail' },
      {
        header: t('list.table.status.title'),
        accessorFn: (mt) =>
          mt.status
            ? t('list.table.status.submit')
            : t('list.table.status.notSubmit'),
      },
      { header: t('list.table.emailTitle'), accessorKey: 'email_title' },
      {
        header: t('list.table.emailContent'),
        accessorFn: (mt) => createOverviewContent(mt.email_content),
      },
      {
        header: t('list.table.updateAt'),
        accessorFn: (mt) => dateFormat(mt.updated_at),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (mt) =>
          makeCustomOperationButtons([
            makeCustomOperationButton(t('list.table.edit'), () =>
              history.push({
                pathname: Routes.AdminMailTemplateEdit,
                state: { mailTemplate: mt },
              })
            ),
            makeCustomOperationButton(t('list.table.sendEmailLog'), () =>
              history.push({
                pathname: Routes.AdminSendEmailLog,
                state: { foreignId: mt.event_name },
              })
            ),
            // 「送信テスト」押下でテンプレートをセットしてモーダルを開く
            makeCustomOperationButton('送信テスト', () => {
              setTemplate(mt)
              onToggleModal()
            }),
          ]),
      },
    ],
    [history, mailTemplates.length, t, onToggleModal, setTemplate]
  )

  const data = useRef<IMailTemplate[]>(mailTemplates.reverse())
  return (
    <>
      {useTanstackTable<IMailTemplate>(columns, data.current, {
        fixedLastColumn: true,
      })}
      <TestMailModal
        isOpen={showModal}
        onToggleModal={onToggleModal}
        onSubmit={handleOnSubmit}
      />
    </>
  )
}

export default TableBody
