import firebase from 'common/firebase'
import { IUser } from './user'

export enum EffectiveDaysStatus {
  INFINITY = 'infinity',
  DAYS = 'days',
  PERIOD = 'period',
}

export interface IInvite {
  id: string
  admin: boolean
  name: string
  count: number
  expire: firebase.firestore.Timestamp
  effective_days: number
  effective_period: number
  join_group_ids: string[]
  approval: boolean
  activate_field: IActivateField
  activate_customize_fields: boolean
  comment: string
  price_ids: string[]
  created_at: firebase.firestore.Timestamp
  updated_at: firebase.firestore.Timestamp
}

export interface IActivateField {
  name: boolean
  birthdate?: boolean
  address?: boolean
  phone: boolean
  company?: boolean
  any_id?: boolean
}

export interface IFormInvite {
  id: string
  admin: boolean
  name: string
  count: number
  count_infinity: boolean
  expire_datetime: string
  expire_infinity: boolean
  effective_days: number
  effective_days_status: EffectiveDaysStatus
  effective_period: number
  join_group_ids: string[]
  approval: boolean
  activate_name: boolean
  activate_phone: boolean
  activate_customize_fields: boolean
  comment: string
  price_ids: string[]
  created_at: firebase.firestore.Timestamp
  updated_at: firebase.firestore.Timestamp
}

export interface IInviteAndApproved {
  invite: IInvite
  approvedUsers: IUser[]
  unapprovedUsers: IUser[]
  registeredAdminUsers: IUser[]
}

export interface IInvitePendingUser extends IUser {
  invite_name: string
}
