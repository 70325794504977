import { ITest } from 'common/interfaces/test'
import { standardModules } from 'common/quill'
import { notOnKeyDownHyphen, null2str } from 'common/utils'
import QuillForm from 'components/atoms/Input/QuillForm'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import { InputType } from 'services/admin/test_form'
import './formpartbasic.css'

interface IFormPartBasicProps {
  test: ITest
  onChangeInput: (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement> | string
  ) => void
}

const FormPartBasic: React.FC<IFormPartBasicProps> = ({
  test,
  onChangeInput,
}: IFormPartBasicProps) => {
  const { t } = useTranslation('adminTest')
  const [isLimitOpen, setIsLimitOpen] = useState<boolean>(
    test.retest.limit_count > 0
  )
  const toggleLimitOpen = (open: boolean) => {
    onChangeInput(InputType.RETEST_LIMIT_COUNT, {
      target: { value: '0' },
    } as React.ChangeEvent<HTMLInputElement>)
    setIsLimitOpen(open)
  }

  const [isButtonUrlDescriptionModalOpen, setIsButtonUrlDescriptionModalOpen] =
    useState<boolean>(false)
  const toggleButtonUrlDescriptionModal = () =>
    setIsButtonUrlDescriptionModalOpen(!isButtonUrlDescriptionModalOpen)

  return (
    <>
      <FormGroup row>
        <Col md="10">
          <Label for="testTitleForm">
            <InfoTooltip
              content={t('form.basic.testTitle.title')}
              tooltipContent={t('form.basic.testTitle.tips')}
              required
            />
          </Label>
          <Input
            id="testTitleForm"
            defaultValue={test.name}
            onChange={(e) => onChangeInput(InputType.TITLE, e)}
          />
        </Col>
      </FormGroup>
      <FormGroup check className="mb-2">
        <Label check>
          <Input
            type="checkbox"
            defaultChecked={test.retest.is_enable}
            onChange={(e) => onChangeInput(InputType.RETEST_ENABLE, e)}
          />
          {t('form.basic.isRetest.title')}
          <div style={{ fontSize: '0.8rem' }}>
            {t('form.basic.isRetest.tips')}
          </div>
        </Label>
      </FormGroup>
      {test.retest.is_enable && (
        <>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                defaultChecked={test.retest.is_stop_when_passed}
                onChange={(e) =>
                  onChangeInput(InputType.RETEST_STOP_WHEN_PASSED, e)
                }
              />
              {t('form.basic.stopRetest.title')}
              <div style={{ fontSize: '0.8rem' }}>
                {t('form.basic.stopRetest.tips')}
              </div>
            </Label>
          </FormGroup>
          <FormGroup check className="mt-2">
            <Label check>
              <Input
                type="checkbox"
                defaultChecked={test.retest.limit_count > 0}
                onChange={(e) => toggleLimitOpen(e.target.checked)}
              />
              {t('form.basic.limitRetest.title')}
            </Label>
            {isLimitOpen && (
              <div className="d-flex align-items-end">
                <Input
                  className="form-part-basic__retest-limit-input"
                  type="number"
                  defaultValue={test.retest.limit_count.toString()}
                  onChange={(e) =>
                    onChangeInput(InputType.RETEST_LIMIT_COUNT, e)
                  }
                  min="0"
                  onKeyDown={notOnKeyDownHyphen}
                />
                <span>{t('form.basic.limitRetest.count')}</span>
              </div>
            )}
          </FormGroup>
        </>
      )}

      <h3 className="mt-4">{t('form.basic.onPass')}</h3>
      <FormGroup row>
        <Col md="10">
          <Label for="passedButtonText">
            <InfoTooltip
              content={t('form.basic.passed.button.text.title')}
              tooltipContent={t('form.basic.passed.button.text.tips')}
            />
          </Label>
          <Input
            id="passedButtonText"
            defaultValue={null2str(test.passed.button_text)}
            onChange={(e) => onChangeInput(InputType.PASSED_BUTTON_TEXT, e)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="passedButtonUrl">
            <InfoTooltip
              content={t('form.basic.passed.button.url.title')}
              tooltipContent={t('form.basic.passed.button.url.tips')}
              onClick={toggleButtonUrlDescriptionModal}
            />
          </Label>
          <Input
            id="passedButtonUrl"
            defaultValue={null2str(test.passed.button_url)}
            onChange={(e) => onChangeInput(InputType.PASSED_BUTTON_URL, e)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.basic.passed.button.bottomNote.title')}
              tooltipContent={t('form.basic.passed.button.bottomNote.tips')}
            />
          </Label>
          <pre>
            <QuillForm
              defaultValue={test.passed.note}
              onChange={(e) => onChangeInput(InputType.PASSED_NOTE, e)}
              modules={standardModules()}
              style={{ backgroundColor: '#fff' }}
            />
          </pre>
        </Col>
      </FormGroup>

      <h3>{t('form.basic.onFail')}</h3>
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            defaultChecked={test.failed.is_show_answer}
            onChange={(e) => onChangeInput(InputType.FAILED_SHOW_ANSWER, e)}
          />
          {t('form.basic.failed.showAnswer')}
        </Label>
      </FormGroup>
      <FormGroup check className="mt-2">
        <Label check>
          <Input
            type="checkbox"
            defaultChecked={test.failed.is_show_explain}
            onChange={(e) => onChangeInput(InputType.FAILED_SHOW_EXPLAIN, e)}
          />
          {t('form.basic.failed.showExplain')}
        </Label>
      </FormGroup>

      <ButtonUrlModal
        isOpen={isButtonUrlDescriptionModalOpen}
        toggle={toggleButtonUrlDescriptionModal}
      />
    </>
  )
}

const ButtonUrlModal: React.FC<{
  isOpen: boolean
  toggle: () => void
}> = ({ isOpen, toggle }) => {
  const { t } = useTranslation('adminTest', {
    keyPrefix: 'form.basic.passed.button.url.tipsModal',
  })

  return (
    <Modal
      className="form-part-basic__button-url-modal"
      isOpen={isOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="pb-0" toggle={toggle}>
        <h3>{t('title')}</h3>
      </ModalHeader>
      <ModalBody>
        <div>
          <code>&#123;teamId&#125;</code>: {t('teamId')}
        </div>
        <div>
          <code>&#123;teamName&#125;</code>: {t('teamName')}
        </div>
        <div>
          <code>&#123;userId&#125;</code>: {t('userId')}
        </div>
        <div>
          <code>&#123;userName&#125;</code>: {t('userName')}
        </div>

        <div className="mt-3">
          <h3>{t('customField.title')}</h3>
          <div>
            <code>
              &#123;
              {t('customField.examplePostcode.field')}
              &#125;
            </code>
            : {t('customField.examplePostcode.description')}
          </div>
          <div>
            <code>
              &#123;
              {t('customField.exampleAddress.field')}
              &#125;
            </code>
            : {t('customField.exampleAddress.description')}
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default FormPartBasic
