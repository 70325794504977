import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { findCachedGroupsNonNull } from 'common/find_store_cache'
import { IInvite, IInviteAndApproved } from 'common/interfaces/invite'
import { makeJoinAdministratorUrl, makeJoinUserUrl } from 'common/link_url'
import { dateFormat, datetimeFormat, formatUNIXToDate } from 'common/times'
import { copyClipboard, openNewTab } from 'common/utils'
import NormalButton from 'components/atoms/Button/Normal'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeCustomOperationButtons,
  makePrimaryBadges,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap'
import { getInvites, removeInvite } from 'services/admin/invite'
import { modalService } from 'services/modal'
import './tablebody.scss'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminInvite')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const [inviteData, setInviteData] = useState<IInviteAndApproved[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await getInvites(setInviteData, storeCache)
      setIsLoading(false)
    })()
  }, [storeCache])

  if (isLoading) return <LoadingSpinner />
  if (inviteData.length <= 0) {
    return <RegisterItemButton type="invite" />
  }
  return (
    <>
      <Row className="justify-content-end mb-3 mr-1 mr-md-0">
        <NormalButton
          onClick={() => history.push(Routes.AdminAdministratorInviteCreate)}
          content={t('list.adminInvite')}
        />
        <NormalButton
          onClick={() => history.push(Routes.AdminUserInviteCreate)}
          content={t('list.userInvite')}
        />
      </Row>
      <Table data={inviteData} />
    </>
  )
}

const Table: React.FC<{ data: IInviteAndApproved[] }> = ({ data }) => {
  const { t } = useTranslation('adminInvite')
  const { t: i18nCommon } = useTranslation('common')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const columns = useMemo<ColumnDef<IInviteAndApproved>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => data.length - i,
      },
      {
        header: t('list.table.link'),
        accessorFn: (d) => <Links invite={d.invite} />,
      },
      {
        header: t('list.table.title'),
        accessorFn: (d) => d.invite.name,
      },
      {
        header: t('list.table.kind'),
        accessorFn: (d) =>
          d.invite.admin ? t('list.table.admin') : t('list.table.user'),
      },
      {
        header: t('list.table.registerUsers'),
        accessorFn: (d) =>
          d.invite.admin
            ? t('list.table.registerUsersCount.adminUsers', {
                adminUsers: d.registeredAdminUsers.length,
              })
            : t('list.table.registerUsersCount.approvedUsers', {
                approvedUsers: d.approvedUsers.length,
              }),
      },
      {
        header: t('list.table.remainingUsers'),
        accessorFn: (d) =>
          d.invite.count === 0
            ? t('list.table.unlimited')
            : t('list.table.remainingUsersCount', {
                inviteCounts: d.invite.count === -1 ? 0 : d.invite.count,
              }),
      },
      {
        header: t('list.table.expire'),
        accessorFn: (d) =>
          d.invite.expire.seconds === 0
            ? t('list.table.indefinite')
            : datetimeFormat(d.invite.expire),
      },
      {
        header: t('list.table.accountsValidPeriod'),
        accessorFn: (d) =>
          d.invite.effective_days !== 0
            ? t('list.table.validPeriodCounts', {
                validPeriod:
                  d.invite.effective_days === -1 ? 0 : d.invite.effective_days,
              })
            : d.invite.effective_period !== 0
            ? formatUNIXToDate(
                d.invite.effective_period,
                i18nCommon('format.dateTime')
              )
            : t('list.table.unlimited'),
      },
      {
        header: t('list.table.joinGroups'),
        accessorFn: (d) =>
          findCachedGroupsNonNull(storeCache, d.invite.join_group_ids).map(
            (g) => g.name
          ),
        cell: (cell) => makePrimaryBadges(cell.getValue<string[]>()),
      },
      {
        header: t('list.table.name'),
        accessorFn: (d) =>
          d.invite.activate_field.name ? t('list.table.yes') : '',
      },
      {
        header: t('list.table.phone'),
        accessorFn: (d) =>
          d.invite.activate_field.phone ? t('list.table.yes') : '',
      },
      {
        header: t('list.table.customizeFields'),
        accessorFn: (d) =>
          d.invite.activate_customize_fields ? t('list.table.yes') : '',
      },
      {
        header: t('list.table.approval'),
        accessorFn: (d) =>
          d.invite.approval ? (
            <Trans
              t={t}
              i18nKey="list.table.approvedUsersCount"
              values={{ unApprovedUsers: d.unapprovedUsers.length }}
            />
          ) : (
            ''
          ),
      },
      {
        header: t('list.table.comment'),
        accessorFn: (d) => d.invite.comment,
      },
      {
        header: t('list.table.createdAt'),
        accessorFn: (d) => dateFormat(d.invite.created_at),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (d) => (
          <Operations
            state={d.invite}
            onEdit={() =>
              history.push({
                pathname: d.invite.admin
                  ? Routes.AdminAdministratorInviteEdit
                  : Routes.AdminUserInviteEdit,
                state: { invite: d.invite },
              })
            }
            onRemove={() =>
              removeInvite(history, d.invite, d.unapprovedUsers, storeCache)
            }
          />
        ),
      },
    ],
    [storeCache, history, data.length, t, i18nCommon]
  )

  const tableData = data.sort(
    (a, b) => b.invite.created_at.seconds - a.invite.created_at.seconds
  )
  return useTanstackTable<IInviteAndApproved>(columns, tableData, {
    fixedLastColumn: true,
  })
}

const Links: React.FC<{ invite: IInvite }> = ({ invite }) => {
  const { t } = useTranslation('adminCommon')
  const link = invite.admin
    ? makeJoinAdministratorUrl(invite.id)
    : makeJoinUserUrl(invite.id)
  return makeCustomOperationButtons([
    makeCustomOperationButton(t('openAsNewTab'), () => openNewTab(link)),
    makeCustomOperationButton(t('copy'), () => copyClipboard(link)),
    makeCustomOperationButton(t('qrCode'), () => modalService.showQrCode(link)),
  ])
}

const Operations: React.FC<{
  state: IInvite
  onEdit: () => void
  onRemove: () => void
}> = ({ state, onEdit, onRemove }): JSX.Element => {
  const i18n = useTranslation('adminInvite').t('list.operations', {
    returnObjects: true,
  })
  const history = useHistory()

  return makeCustomOperationButtons([
    makeCustomOperationButton(i18n.edit, onEdit),
    <UncontrolledDropdown>
      <DropdownToggle className="p-2 p-md-3" nav>
        <i className="fas fa-ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-invite-menu">
        <DropdownItem
          className="dropdown-invite-item"
          onClick={() =>
            history.push({ pathname: Routes.AdminApprovalPendingUser, state })
          }
        >
          {i18n.approvalPending}
        </DropdownItem>
        <DropdownItem
          className="dropdown-invite-item"
          onClick={() =>
            history.push({ pathname: Routes.AdminInvitedUser, state })
          }
        >
          {i18n.registeredList}
        </DropdownItem>
        <DropdownItem
          className="dropdown-invite-item"
          onClick={() =>
            history.push({ pathname: Routes.AdminInviteMail, state })
          }
        >
          {i18n.emailInvite}
        </DropdownItem>
        <DropdownItem
          className="dropdown-invite-item"
          onClick={() =>
            history.push({
              pathname: Routes.AdminInviteEmailLog,
              state: { foreignId: state.id },
            })
          }
        >
          {i18n.emailLogs}
        </DropdownItem>
        <DropdownItem className="dropdown-invite-item" onClick={onRemove}>
          {i18n.delete}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>,
  ])
}

export default TableBody
