import { IStoreCache } from 'common/interfaces/auth_provider'
import { IBookmark } from 'common/interfaces/bookmark'
import { ILogVideoPageOpen } from 'common/interfaces/log'
import { IPurchased } from 'common/interfaces/stripe/price'
import { IWatchlistContent } from 'common/interfaces/watchlist'
import {
  makeBookmarkPath,
  makePurchasedPath,
  makeViewLogPath,
  makeWatchLaterPath,
} from 'common/link_path'
import { isLiveVideo } from 'common/utils'
import { i18nUserVideoList } from 'i18n/i18n'
import { useEffect } from 'react'
import { fetchPurchasedInfos } from 'repositories/functions/functions_stripe'
import { get as getLog } from 'repositories/store/firebase_log'
import { getWatchLater } from 'repositories/store/firebase_watchlist'

/**
 * MyVideoListで使用するデータのinterface
 */
export interface IMyVideoListData {
  videoPageOpenLogs: ILogVideoPageOpen[]
  bookmarks: IBookmark[]
  watchLaters: IWatchlistContent[]
  purchaseds: IPurchased[]
}

/**
 * 視聴履歴かお気に入り動画かのPageType
 */
export enum PageType {
  VIDEO_PAGE_OPEN_LOGS,
  BOOKMARK,
  WATCH_LATER,
  PURCHASED,
}

/**
 * 開かれてるPageTypeを返却
 */
export const pageType = (): PageType => {
  switch (window.location.pathname) {
    case makeViewLogPath():
      return PageType.VIDEO_PAGE_OPEN_LOGS
    case makeBookmarkPath():
      return PageType.BOOKMARK
    case makeWatchLaterPath():
      return PageType.WATCH_LATER
    case makePurchasedPath():
      return PageType.PURCHASED
  }
  return PageType.VIDEO_PAGE_OPEN_LOGS
}

/**
 * タイトルを返却
 */
export const createTitle = (): string => {
  const i18n = i18nUserVideoList('myVideoList.titles', {
    returnObjects: true,
  })
  switch (pageType()) {
    case PageType.VIDEO_PAGE_OPEN_LOGS:
      return i18n.viewHistory
    case PageType.BOOKMARK:
      return i18n.bookmarkVideo
    case PageType.WATCH_LATER:
      return i18n.watchLater
    case PageType.PURCHASED:
      return i18n.rentalList
  }
}

/**
 * 使用するデータを取得
 * @param storeCache `IStoreCache`
 * @param setData `React.Dispatch<React.SetStateAction<IMyVideoListData>>`
 */
export const useData = (
  storeCache: IStoreCache,
  setData: React.Dispatch<React.SetStateAction<IMyVideoListData>>
): void =>
  useEffect(() => {
    switch (pageType()) {
      case PageType.VIDEO_PAGE_OPEN_LOGS:
        getUserViewLogs(storeCache, setData)
        break
      case PageType.BOOKMARK:
        setData({
          videoPageOpenLogs: [],
          bookmarks: storeCache.user?.bookmarks ?? [],
          watchLaters: [],
          purchaseds: [],
        })
        break
      case PageType.WATCH_LATER:
        getWatchLaters(storeCache, setData)
        break
      case PageType.PURCHASED:
        getUserPurchaseds(storeCache, setData)
        break
    }
  }, [storeCache, setData])

/**
 * 動画ページを開いたログを取得
 * @param storeCache `IStoreCache`
 * @param setData `React.Dispatch<React.SetStateAction<IMyVideoListData>>`
 */
const getUserViewLogs = async (
  storeCache: IStoreCache,
  setData: React.Dispatch<React.SetStateAction<IMyVideoListData>>
): Promise<void> => {
  if (!storeCache.team || !storeCache.user) return

  const log = await getLog(storeCache.team, storeCache.user)
  setData({
    videoPageOpenLogs: log?.video_page_opens ?? [],
    bookmarks: [],
    watchLaters: [],
    purchaseds: [],
  })
}

/**
 * 後で見るを取得
 * @param storeCache `IStoreCache`
 * @param setData `React.Dispatch<React.SetStateAction<IMyVideoListData>>`
 */
const getWatchLaters = async (
  storeCache: IStoreCache,
  setData: React.Dispatch<React.SetStateAction<IMyVideoListData>>
): Promise<void> => {
  if (!storeCache.team || !storeCache.user) return

  const watchLaters = await getWatchLater(storeCache.team, storeCache.user.id)
  setData({
    videoPageOpenLogs: [],
    bookmarks: [],
    watchLaters: watchLaters?.contents ?? [],
    purchaseds: [],
  })
}

/**
 * レンタル・購入リストを取得
 *
 * @param storeCache `IStoreCache`
 * @param setData `React.Dispatch<React.SetStateAction<IMyVideoListData>>`
 * @returns `Promise<void>`
 */
const getUserPurchaseds = async (
  { team, user, videos, lives }: IStoreCache,
  setData: React.Dispatch<React.SetStateAction<IMyVideoListData>>
): Promise<void> => {
  const customerId = user?.customer_id
  if (!customerId) return

  const purchasedInfos = await fetchPurchasedInfos(
    team!.stripe.account_id,
    customerId
  )
  const purchased: IPurchased[] = []
  const contents = [...videos, ...lives]

  contents.forEach((c) => {
    const purchasedList = purchasedInfos.filter(({ id }) =>
      c.price_ids.includes(id)
    )
    if (purchasedList.length > 0) {
      const purchaseds = purchasedList.map(
        ({ description, remaining_valid_day }) =>
          remaining_valid_day > 0
            ? i18nUserVideoList('myVideoList.remainingValidDay', {
                description,
                remaining_valid_day,
              })
            : description
      )
      purchased.push({
        video_id: c.id,
        created_at: c.created_at,
        purchaseds,
        is_video: !isLiveVideo(c),
      })
    }
  })
  setData({
    videoPageOpenLogs: [],
    bookmarks: [],
    watchLaters: [],
    purchaseds: purchased,
  })
}
