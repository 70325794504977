import { IFilelist } from 'common/interfaces/filelist'
import { ITeam } from 'common/interfaces/team'
import { storageMetadata } from 'common/utils'
import { TeamsDirRef } from './firebase_team'

const DirRef = (team: ITeam) => TeamsDirRef.child(team.id).child('filelists')

/**
 * upload pdf file
 * @throws Firebase error
 */
export const upload = async (
  team: ITeam,
  filelist: IFilelist,
  file: File | undefined
): Promise<string> => {
  if (file) {
    const uploadTask = await DirRef(team)
      .child(filelist.id)
      .put(file, storageMetadata())
    return uploadTask.ref.getDownloadURL()
  }
  return ''
}

/**
 * delete pdf file
 * @throws Firebase error
 */
export const removeImageFile = async (
  team: ITeam,
  filelist: IFilelist
): Promise<void> => {
  await DirRef(team).child(filelist.id).delete()
}
