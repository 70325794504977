import {
  defaultColors,
  IFindTeam,
  ITeam,
  ITeamColors,
} from 'common/interfaces/team'
import { CSSProperties } from 'react'

/**
 * calc user page colors
 * @returns color object
 */
export const calcUserColors = (teamColors?: ITeamColors | null) => {
  const calcAlphaColor = (hexColor: string, alpha: number) => {
    const m = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)
    const hex = [1, 2, 3].map((i) => parseInt(m![i], 16)).join(',')
    return `rgba(${hex},${alpha})`
  }
  const colors = teamColors ?? defaultColors
  return {
    text_primary: colors.text_primary,
    text_secondary: calcAlphaColor(colors.text_primary, 0.8),
    text_quinary_light: calcAlphaColor(colors.text_primary, 0.1),
    background: colors.background,
    background_thin: calcAlphaColor(colors.background, 0.95),
    accent: colors.accent,
    accent_thin: calcAlphaColor(colors.accent, 0.6),
    border: calcAlphaColor(colors.accent, 0.2),
    no_thumbnail: '#cccccc',
  }
}

/**
 * calc user page colors
 * @returns css style object
 */
export const calcUserColorCssProperties = (
  team: ITeam | IFindTeam | null | undefined,
  isOverwriteBodyStyle = true
) => {
  const colors = calcUserColors(team?.colors)
  if (isOverwriteBodyStyle) {
    document.body.style.backgroundColor = colors.background
  }
  return {
    '--color-text-primary': colors.text_primary,
    '--color-text-secondary': colors.text_secondary,
    '--color-text-quinary-light': colors.text_quinary_light,
    '--color-background': colors.background,
    '--color-background-thin': colors.background_thin,
    '--color-accent': colors.accent,
    '--color-accent-thin': colors.accent_thin,
    '--color-border': colors.border,
    '--color-no-thumbnail': colors.no_thumbnail,
    color: 'var(--color-text-primary)',
    backgroundColor: 'var(--color-background)',
    minHeight: '100vh',
  } as CSSProperties
}
