import { IFilelist } from 'common/interfaces/filelist'
import { ITeam } from 'common/interfaces/team'
import { Teams } from './firebase_team'

const Filelists = (team: ITeam) => Teams.doc(team.id).collection('filelists')

/**
 * get all filelists
 */
export const get = async (team: ITeam): Promise<IFilelist[]> => {
  const filelistDatas = await Filelists(team).get()
  return filelistDatas.docs.map((filelist) => filelist.data() as IFilelist)
}

/**
 * add filelist
 * @throws Firebase error
 */
export const store = async (
  team: ITeam,
  filelist: IFilelist
): Promise<void> => {
  await Filelists(team).doc(filelist.id).set(filelist)
}

/**
 * delete filelist
 * @throws Firebase error
 */
export const remove = async (
  team: ITeam,
  filelist: IFilelist
): Promise<void> => {
  await Filelists(team).doc(filelist.id).delete()
}
