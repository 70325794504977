import { IEnqueteType } from 'common/interfaces/enquete_customize'
import {
  IFieldCustomize,
  IFieldCustomizeType,
  IFieldName,
} from 'common/interfaces/field_customize'
import { IStripe } from 'common/interfaces/stripe'
import { goBackWithErrorAlert, isBasicPrice } from 'common/utils'
import AddCard from 'components/atoms/FieldCustomize/AddCard'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Button, Col, Row } from 'reactstrap'
import {
  ChangeAddCardType,
  initFieldName,
  onAddItem as serviceOnAddItem,
  onChangeAddCard as serviceOnChangeAddCard,
  onDragEnd as serviceOnDragEnd,
  onRemoveItem as serviceOnRemoveItem,
  updateTeamFieldCustomize,
} from 'services/admin/field_customize'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'
import '../order-customize-style.scss'
import { createItemCard } from './ElementsOnFieldCustomize'

const FormBody: React.FC = () => {
  const { t } = useTranslation('adminCustomizeFields')
  const { t: i18nAlert } = useTranslation('alert')
  const storeCache = useContext(AuthContext).storeCache
  const history: History = useHistory()
  const [customizeFields, setCustomizeFields] = useState<IFieldCustomize[]>([
    ...(storeCache.team?.customize_field ?? []),
  ])
  const [fieldName, setFieldName] = useState<IFieldName>(initFieldName())
  const [radioOptions, setRadioOptions] = useState<string[]>([''])
  const [pulldownOptions, setPulldownOptions] = useState<string[]>([''])
  const [stripe, setStripe] = useState<IStripe>(initStripe())

  useEffect(() => {
    getStripe(setStripe)
  }, [setStripe])

  if (isBasicPrice(storeCache.subscriptionObj, stripe)) {
    goBackWithErrorAlert(history, i18nAlert('plans.basic.notUseFeature'))
    return null
  }
  if (!storeCache.team) {
    goBackWithErrorAlert(history, i18nAlert('noOperationAuthority'))
    return null
  }

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: IFieldCustomizeType | IEnqueteType,
    changeType: ChangeAddCardType
  ) =>
    serviceOnChangeAddCard(
      { fieldName, setFieldName },
      e,
      type as IFieldCustomizeType,
      changeType
    )

  const onAddItem = (type: IFieldCustomizeType) =>
    serviceOnAddItem(
      type,
      fieldName,
      { customizeFields, setCustomizeFields },
      type === IFieldCustomizeType.RADIO ? radioOptions : pulldownOptions,
      type === IFieldCustomizeType.RADIO ? setRadioOptions : setPulldownOptions
    )
  const onRemoveItem = (position: number) =>
    serviceOnRemoveItem(position, { customizeFields, setCustomizeFields })
  const onDragEnd = (result: DropResult) =>
    serviceOnDragEnd(result, {
      customizeFields,
      setCustomizeFields,
    })
  const update = () => updateTeamFieldCustomize(customizeFields, storeCache)

  return (
    <div className="order-customize-style">
      <Row>
        <Col md="4" className="all-items">
          <div className="scroll-box">
            <Row>
              <AddCard
                title={t('list.card.title.text')}
                placeholder={t('list.card.fieldPlaceholder')}
                type={IFieldCustomizeType.TEXT}
                changeablePublicStatus
                onChange={onChange}
                onClick={() => onAddItem(IFieldCustomizeType.TEXT)}
              />
              <AddCard
                title={t('list.card.title.textArea')}
                placeholder={t('list.card.fieldPlaceholder')}
                type={IFieldCustomizeType.TEXTAREA}
                changeablePublicStatus
                onChange={onChange}
                onClick={() => onAddItem(IFieldCustomizeType.TEXTAREA)}
              />
              <AddCard
                title={t('list.card.title.number')}
                placeholder={t('list.card.fieldPlaceholder')}
                type={IFieldCustomizeType.NUMBER}
                changeablePublicStatus
                onChange={onChange}
                onClick={() => onAddItem(IFieldCustomizeType.NUMBER)}
              />
              <AddCard
                title={t('list.card.title.date')}
                placeholder={t('list.card.fieldPlaceholder')}
                type={IFieldCustomizeType.DATE}
                changeablePublicStatus
                onChange={onChange}
                onClick={() => onAddItem(IFieldCustomizeType.DATE)}
              />
              <AddCard
                title={t('list.card.title.bool')}
                placeholder={t('list.card.fieldPlaceholder')}
                type={IFieldCustomizeType.BOOL}
                changeablePublicStatus
                onChange={onChange}
                onClick={() => onAddItem(IFieldCustomizeType.BOOL)}
              />
              <AddCard
                title={t('list.card.title.radio')}
                placeholder={t('list.card.fieldPlaceholder')}
                type={IFieldCustomizeType.RADIO}
                changeablePublicStatus
                onChange={onChange}
                onClick={() => onAddItem(IFieldCustomizeType.RADIO)}
                options={radioOptions}
                setOptions={setRadioOptions}
              />
              <AddCard
                title={t('list.card.title.pulldown')}
                placeholder={t('list.card.fieldPlaceholder')}
                type={IFieldCustomizeType.PULLDOWN}
                changeablePublicStatus
                onChange={onChange}
                onClick={() => onAddItem(IFieldCustomizeType.PULLDOWN)}
                options={pulldownOptions}
                setOptions={setPulldownOptions}
              />
            </Row>
          </div>
        </Col>
        <Col md="8" className="selected-items">
          <div className="scroll-box">
            <h2>{t('list.fieldsOrder')}</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {customizeFields.map((customizeField, index) => {
                      const id = customizeField.type + index
                      return (
                        <Draggable key={id} draggableId={id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {createItemCard(
                                index,
                                { customizeFields, setCustomizeFields },
                                onRemoveItem
                              )}
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Col>
      </Row>

      <div className="text-center mt-3">
        <Button onClick={update} className="my-4" color="primary" type="button">
          {t('list.save')}
        </Button>
      </div>
    </div>
  )
}

export default FormBody
