import { ColumnDef } from '@tanstack/react-table'
import { IInvoice } from 'common/interfaces/stripe/Invoice'
import { IUser } from 'common/interfaces/user'
import { formatUNIXToDate } from 'common/times'
import { formatInvoiceStatus, formatPrice } from 'common/utils'
import { useTanstackTableWithCsvExport } from 'components/atoms/Table/CreateTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPaidHistories } from 'services/admin/user_paid_history'

interface ITableBody {
  user: IUser
}

const useI18n = () =>
  useTranslation('adminUser').t('userSubscriptionPaidHistory', {
    returnObjects: true,
  })

const TableBody: React.FC<ITableBody> = ({ user: { customer_id } }) => {
  const { storeCache } = useContext(AuthContext)

  const [invoices, setInvoices] = useState<IInvoice[]>([])

  useEffect(() => {
    getPaidHistories(storeCache, customer_id!, setInvoices)
  }, [storeCache, customer_id])

  const i18n = useI18n()
  if (invoices.length <= 0) return <>{i18n.noPaidHistory}</>
  return <Table invoices={invoices} />
}

const Table: React.FC<{ invoices: IInvoice[] }> = ({ invoices }) => {
  const i18n = useI18n().table

  const columns = useMemo<ColumnDef<IInvoice>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => i + 1,
      },
      {
        header: i18n.number,
        accessorKey: 'number',
      },
      {
        header: i18n.total,
        accessorFn: (i) => formatPrice(i.total, i.currency),
      },
      {
        header: i18n.status,
        accessorFn: (i) => formatInvoiceStatus(i.status),
      },
      {
        header: i18n.invoicePDF,
        accessorKey: 'invoice_pdf',
        cell: (cell) => (
          <a href={cell.getValue<string>()} target="_brank">
            {cell.getValue<string>()}
          </a>
        ),
      },
      {
        header: i18n.created,
        accessorFn: (i) => formatUNIXToDate(i.created),
      },
    ],
    [i18n]
  )

  return useTanstackTableWithCsvExport<IInvoice>(
    columns,
    invoices,
    'paid_histories.csv'
  )
}

export default TableBody
