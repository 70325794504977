import { IInvitePendingUser } from 'common/interfaces/invite'
import FooterButtons from 'components/atoms/Modal/FooterButtons'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Modal, ModalBody } from 'reactstrap'
import { onUpdateInvitePendingUsers as serviceOnUpdateInvitePendingUsers } from 'services/admin/approval_pending_user'

interface InvitePendingUsersModalProps {
  isOpen: boolean
  toggleModal: () => void
  selectedInvitePendingUsers: IInvitePendingUser[]
}

const InvitePendingUsersModal: React.FC<InvitePendingUsersModalProps> = ({
  isOpen,
  selectedInvitePendingUsers,
  toggleModal,
}) => {
  const { storeCache } = useContext(AuthContext)
  const { t } = useTranslation('alert')

  const onUpdateUsers = () => {
    serviceOnUpdateInvitePendingUsers(
      storeCache,
      selectedInvitePendingUsers,
      toggleModal
    )
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered>
      <ModalBody>
        {t('modal.confirm.selectedInvitePendingUsers.approve')}
        <Form>
          <FooterButtons
            leftContent="キャンセル"
            rightContent="OK"
            leftOnClick={toggleModal}
            rightOnClick={onUpdateUsers}
          />
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default InvitePendingUsersModal
