import { IEnquete } from 'common/interfaces/enquete'
import { IEnqueteCustomize } from 'common/interfaces/enquete_customize'
import FooterButtons from 'components/atoms/Modal/FooterButtons'
import { questionElement } from 'components/atoms/Question/Question'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Modal, ModalBody, ModalHeader } from 'reactstrap'

interface IEnqueteModalProps {
  enquete: IEnquete | null
  enqueteModal: boolean
  onToggleModal: () => void
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    enquete: IEnqueteCustomize,
    index: number
  ) => void
  onAnswer: () => void
}

const EnqueteModal: React.FC<IEnqueteModalProps> = ({
  enquete,
  enqueteModal,
  onToggleModal,
  onChange,
  onAnswer,
}: IEnqueteModalProps) => {
  const { t } = useTranslation('userVideo')
  if (!enquete) return null

  return (
    <Modal isOpen={enqueteModal} toggle={onToggleModal} centered>
      <ModalHeader toggle={onToggleModal}>{enquete.title}</ModalHeader>
      <ModalBody>
        <Form>
          {questionElement(enquete, onChange)}
          <FooterButtons
            leftContent={t('enqueteModal.answer')}
            rightContent={t('enqueteModal.close')}
            leftOnClick={onAnswer}
            rightOnClick={onToggleModal}
          />
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default EnqueteModal
