import {
  findCachedCategory,
  findCachedCreator,
  findCachedTag,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ContentsOrderType } from 'common/interfaces/contents_orders'
import { IVideo } from 'common/interfaces/video'
import { newerVideosPath, popularVideosPath } from 'common/link_path'
import { sortByContentsOrderType, sortByViewCount } from 'common/sort_contents'
import { filterContentsByPlayStatus } from 'common/utils_log'
import { i18nUserIndex, i18nUserVideoList } from 'i18n/i18n'
import React from 'react'
import { ContentTypeListPageType, IContentTypeListParams } from './page_params'

export const getVideoListPageType = (
  params: IContentTypeListParams
): ContentTypeListPageType => {
  switch (window.location.pathname) {
    case newerVideosPath():
      return ContentTypeListPageType.NEWER_VIDEOS
    case popularVideosPath():
      return ContentTypeListPageType.POPULAR_VIDEOS
  }
  if (
    [
      params.categoryId,
      params.tagId,
      params.creatorId,
      params.playStatus,
    ].filter(Boolean).length > 0
  ) {
    return ContentTypeListPageType.COMBINE_VIDEOS
  }
  if (params.query) {
    return ContentTypeListPageType.SEARCH_VIDEOS
  }
  return ContentTypeListPageType.NEWER_VIDEOS
}

export const createVideoListTitle = (
  pageType: ContentTypeListPageType,
  storeCache: IStoreCache,
  params: IContentTypeListParams
): JSX.Element[] => {
  const i18n = i18nUserVideoList('typeContentsList.titles', {
    returnObjects: true,
    query: params.query,
  })
  const titles: string[] = []
  switch (pageType) {
    case ContentTypeListPageType.NEWER_VIDEOS:
      titles.push(i18n.newVideo)
      break
    case ContentTypeListPageType.POPULAR_VIDEOS:
      titles.push(i18n.popularVideo)
      break
    case ContentTypeListPageType.SEARCH_VIDEOS:
      if (params.query) {
        titles.push(i18n.searchVideo)
      }
      break
    case ContentTypeListPageType.COMBINE_VIDEOS:
      if (params.categoryId) {
        const category = findCachedCategory(storeCache, params.categoryId)
        titles.push(category?.name ?? '')
      }
      if (params.tagId) {
        const tag = findCachedTag(storeCache, params.tagId)
        titles.push(tag?.name ? `#${tag.name}` : '')
      }
      if (params.creatorId) {
        const creator = findCachedCreator(storeCache, params.creatorId)
        titles.push(creator?.name ?? '')
      }
      if (params.playStatus) {
        const i18n = i18nUserIndex('chooseGenre.badges.playStatus', {
          returnObjects: true,
        })
        const name = i18n[params.playStatus]
        titles.push(name)
      }
      break
  }

  return titles.map((title, index) =>
    React.createElement('span', { key: index }, title)
  )
}

export const getFilteredVideos = async (
  pageType: ContentTypeListPageType,
  storeCache: IStoreCache,
  params: IContentTypeListParams
): Promise<IVideo[]> => {
  const videos = storeCache.videos.filter((v) => !v.is_list_hidden)
  const videoSortType = storeCache.team!.default_contents_orders.video
  switch (pageType) {
    case ContentTypeListPageType.NEWER_VIDEOS:
      return sortByContentsOrderType(videos, ContentsOrderType.NEWER)
    case ContentTypeListPageType.POPULAR_VIDEOS:
      return sortByViewCount(videos)
    case ContentTypeListPageType.SEARCH_VIDEOS:
      if (params.query) {
        const queryFiltered = videos.filter(
          (v) =>
            v.name.includes(params.query!) ||
            v.overview?.includes(params.query!)
        )
        return sortByContentsOrderType(queryFiltered, videoSortType)
      }
      break
    case ContentTypeListPageType.COMBINE_VIDEOS:
      let filtered = videos
      if (params.categoryId) {
        filtered = filtered.filter((v) => v.category_id === params.categoryId)
      }
      if (params.tagId) {
        filtered = filtered.filter((v) => v.tag_ids.includes(params.tagId!))
      }
      if (params.creatorId) {
        filtered = filtered.filter((v) => v.creator_id === params.creatorId)
      }
      if (params.playStatus) {
        filtered = await filterContentsByPlayStatus(
          storeCache,
          params.playStatus,
          filtered
        )
      }
      return sortByContentsOrderType(filtered, videoSortType)
  }

  return []
}
