import { SendEmailStatus } from 'common/enums'
import { ISendEmail } from 'common/interfaces/send_email'
import { IUser } from 'common/interfaces/user'
import { standardModules } from 'common/quill'
import { dateFormat, nowTimestamp } from 'common/times'
import QuillForm from 'components/atoms/Input/QuillForm'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  InputType,
  getFormOptions,
  getNotifyUsers,
  getSendEmailDefaults,
  saveSendEmail,
  sendEmailUsers,
  onChangeInput as serviceOnChangeInput,
} from 'services/admin/send_email_form'

interface IFormBodyProps {
  isCreate: boolean
  sendEmailObj: ISendEmail
  history: History
}

const FormBody: React.FC<IFormBodyProps> = ({
  isCreate,
  sendEmailObj,
  history,
}: IFormBodyProps) => {
  const adminSendEmail = useTranslation('adminSendEmail')
  const adminCommon = useTranslation('adminCommon')
  const { storeCache } = useContext(AuthContext)

  const [sendEmail, setSendEmail] = useState<ISendEmail>(sendEmailObj)
  const [users, setUsers] = useState<IUser[]>([])

  const { defaultGroups } = getSendEmailDefaults(storeCache, sendEmail)
  const { groupOptions } = getFormOptions(storeCache)

  useEffect(() => {
    getNotifyUsers(storeCache, setUsers)
  }, [storeCache])

  const onChangeInput = (type: InputType, e: any) =>
    serviceOnChangeInput(sendEmail, setSendEmail, type, e)

  const sendUserCount = sendEmailUsers(users, sendEmail.group_ids).length

  const save = () => saveSendEmail(history, sendEmail, storeCache, isCreate)

  return (
    <fieldset disabled={sendEmail.status === SendEmailStatus.SENT}>
      <Form>
        <FormGroup row>
          <Col md="10">
            <Label>
              <InfoTooltip
                content={adminSendEmail.t('form.sendEmailTitle.title')}
                tooltipContent={adminSendEmail.t('form.sendEmailTitle.tips')}
              />
            </Label>
            <Input
              onChange={(e) => onChangeInput(InputType.TITLE, e)}
              type="text"
              defaultValue={sendEmail.title}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="10">
            <Label>
              <InfoTooltip
                content={adminSendEmail.t('form.sendEmailGroup.title')}
                tooltipContent={adminSendEmail.t('form.sendEmailGroup.tips')}
              />
              <div style={{ fontSize: '0.8rem' }}>
                {adminSendEmail.t('form.sendEmailGroup.tips2')}
              </div>
            </Label>
            <Select
              placeholder={adminSendEmail.t('form.sendEmailGroup.allGroup')}
              defaultValue={defaultGroups}
              onChange={(e) => onChangeInput(InputType.GROUP_IDS, e)}
              options={groupOptions}
              closeMenuOnSelect={false}
              isMulti
            />
            <div className="mt-3">
              {adminSendEmail.t('form.sendEmailMemberCount', {
                count: sendUserCount,
              })}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="10">
            <Label>
              <InfoTooltip
                content={adminSendEmail.t('form.sendEmailSubject.title')}
                tooltipContent={adminSendEmail.t('form.sendEmailSubject.tips')}
              />
            </Label>
            <Input
              onChange={(e) => onChangeInput(InputType.SUBJECT, e)}
              type="text"
              defaultValue={sendEmail.subject}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="10">
            <Label>
              <InfoTooltip
                content={adminSendEmail.t('form.sendEmailBody.title')}
                tooltipContent={adminSendEmail.t('form.sendEmailBody.tips')}
              />
            </Label>
            <pre>
              <QuillForm
                onChange={(e) => onChangeInput(InputType.BODY, e)}
                modules={standardModules()}
                defaultValue={sendEmail.body}
              />
            </pre>
          </Col>
        </FormGroup>
        <FormGroup row check className="mb-4">
          <Row>
            <Col md="10">
              <Label>{adminSendEmail.t('form.sendEmailStatus.title')}</Label>
            </Col>
          </Row>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                onChange={(e) => onChangeInput(InputType.STATUS, e)}
                value={SendEmailStatus.SEND_NOW}
                checked={SendEmailStatus.SEND_NOW === sendEmail.status}
              />
              {adminSendEmail.t('form.sendEmailStatus.sendNow')}
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                onChange={(e) => onChangeInput(InputType.STATUS, e)}
                value={SendEmailStatus.SPECIFIED_TIME}
                checked={SendEmailStatus.SPECIFIED_TIME === sendEmail.status}
              />
              {adminSendEmail.t('form.sendEmailStatus.specifySendTime')}
              <div style={{ fontSize: '0.8rem' }}>
                {adminSendEmail.t('form.sendEmailStatus.tips')}
              </div>
            </Label>
          </FormGroup>
        </FormGroup>
        {SendEmailStatus.SPECIFIED_TIME === sendEmail.status && (
          <Row className="mt-2">
            <Col md="5">
              <Input
                onChange={(e) => onChangeInput(InputType.SEND_AT, e)}
                defaultValue={dateFormat(sendEmail.send_at, 'YYYY-MM-DDTHH:mm')}
                type="datetime-local"
                min={dateFormat(nowTimestamp(), 'YYYY-MM-DDTHH:mm')}
                step={3600}
              />
            </Col>
          </Row>
        )}
        <div className="text-center">
          <Button onClick={save} className="my-4" color="primary" type="button">
            {adminCommon.t('save')}
          </Button>
        </div>
      </Form>
    </fieldset>
  )
}

export default FormBody
