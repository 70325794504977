import { IGroup } from 'common/interfaces/group'
import { ITeam } from 'common/interfaces/team'
import { nowTimestamp } from 'common/times'
import { Teams } from './firebase_team'

const Groups = (team: ITeam) => Teams.doc(team.id).collection('groups')

/**
 * get all groups
 */
export const get = async (team: ITeam): Promise<IGroup[]> => {
  try {
    const groupDatas = await Groups(team).get()
    return groupDatas.docs.map((group) => group.data() as IGroup)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * add group
 * @throws Firebase error
 */
export const store = async (team: ITeam, group: IGroup): Promise<void> => {
  await Groups(team).doc(group.id).set(group)
}

/**
 * update group
 * @throws Firebase error
 */
export const update = async (team: ITeam, group: IGroup): Promise<void> => {
  group.updated_at = nowTimestamp()
  await Groups(team).doc(group.id).set(group)
}

/**
 * delete group
 * @throws Firebase error
 */
export const remove = async (team: ITeam, group: IGroup): Promise<void> => {
  await Groups(team).doc(group.id).delete()
}
