import ChooseGenre from 'components/molecules/User/ChooseGenre/ChooseGenre'
import SearchVideoInput from 'components/molecules/User/SearchVideoInput/SearchVideoInput'
import GenreList from 'components/organisms/User/GenreList/GenreList'
import React from 'react'
import { Container } from 'reactstrap'
import './genres.scss'

const Genres: React.FC = () => {
  return (
    <div className="user-genres">
      <Container>
        <ChooseGenre type="content_type" />
        <ChooseGenre type="category" />
        <ChooseGenre type="tag" />
        <ChooseGenre type="creator" />
        <SearchVideoInput />
      </Container>
      <hr />
      <Container className="genre-list-container">
        <GenreList />
      </Container>
    </div>
  )
}

export default Genres
