import AdminLogo from 'assets/images/admin_logo.png'
import { AuthType } from 'common/auth_type'
import { Routes } from 'common/enums'
import { IRegister } from 'common/interfaces/register'
import AccentButton from 'components/atoms/Button/Block/AccentButton'
import SignInWithGoogle from 'components/atoms/Button/SignInWithGoogle'
import ButtonLoading from 'components/atoms/Loading/ButtonLoading'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from 'reactstrap'
import {
  InputType,
  initState,
  register,
  onChangeInput as serviceOnChangeInput,
} from 'services/team/register'

const FormBody: React.FC = () => {
  const { t } = useTranslation('register')
  const history = useHistory()

  const [state, setState] = useState<IRegister>(initState())
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(state, setState, type, e)

  const registerTeam = (authType: AuthType) =>
    register(history, state, authType, setIsLoading)

  return (
    <div className="main-content">
      <div className="header py-7 py-lg-8" />

      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center mb-4">
                  <img
                    src={AdminLogo}
                    alt="admin logo"
                    width="200"
                    height="auto"
                  />
                </div>
                <div className="text-center text-muted mb-5">{t('title')}</div>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        onChange={(e) => onChangeInput(InputType.USER_NAME, e)}
                        placeholder={t('form.placeholder.name')}
                        type="text"
                        autoComplete="new-user-name"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        onChange={(e) => onChangeInput(InputType.EMAIL, e)}
                        placeholder={t('form.placeholder.email')}
                        type="email"
                        autoComplete="new-email"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <Input
                        onChange={(e) => onChangeInput(InputType.PASS, e)}
                        placeholder={t('form.placeholder.password')}
                        type="password"
                        autoComplete="new-password"
                      />
                    </InputGroup>
                  </FormGroup>

                  {isLoading ? (
                    <div className="text-center">
                      <ButtonLoading className="my-2" />
                    </div>
                  ) : (
                    <>
                      <div className="text-center">
                        <AccentButton
                          onClick={() => registerTeam(AuthType.EMAIL)}
                          className="my-4 p-3 form-control-lg"
                          content={t('form.accentButton')}
                        />
                      </div>

                      <SignInWithGoogle
                        onClick={() => registerTeam(AuthType.GOOGLE)}
                        centered
                      />

                      <div className="text-center text-muted mt-4">
                        <Link to={Routes.LoginAdmin}>
                          {t('form.haveAccount')}
                        </Link>
                      </div>
                    </>
                  )}

                  <div className="text-center text-muted mt-4">
                    <Trans
                      t={t}
                      i18nKey="attention"
                      components={{
                        termsLink: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            href="https://one-stream.oopy.io/b7dadcd9-b156-47d0-ada4-713fb7e3c478"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#6856FF' }}
                          />
                        ),
                      }}
                    />
                  </div>
                </Form>
              </CardBody>
            </Card>

            {!isLoading && (
              <p>
                <Link to={Routes.Root}>{t('back')}</Link>
              </p>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FormBody
