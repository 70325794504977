import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { IProduct } from 'common/interfaces/stripe/product'
import { formatUNIXToDate } from 'common/times'
import ButtonRight from 'components/atoms/Button/Right'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeCustomOperationButtons,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Col, Row } from 'reactstrap'
import { getStripeProducts } from 'services/admin/stripe/product'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminStripeProduct')
  const { storeCache } = useContext(AuthContext)

  const [products, setProducts] = useState<IProduct[]>([])
  const [showArchive, setShowArchive] = useState<boolean>(false)
  const onChangeToggle = () => setShowArchive(!showArchive)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await getStripeProducts(storeCache, setProducts)
      setIsLoading(false)
    })()
  }, [storeCache])

  if (isLoading) return <LoadingSpinner />

  const disabled = !storeCache.team!.stripe.account_id
  if (products.length <= 0) {
    return <RegisterItemButton type="stripe_product" disabled={disabled} />
  }
  return (
    <>
      <ButtonRight
        nextPage={Routes.AdminStripeProductCreate}
        content={t('list.new')}
        disabled={disabled}
      />
      <Row className="justify-content-start">
        <Col>
          <h4>{t('list.viewArchivedRates')}</h4>
          <label className="custom-toggle">
            <input
              type="checkbox"
              onChange={onChangeToggle}
              checked={showArchive}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </Col>
      </Row>
      <Table products={products} showArchive={showArchive} />
    </>
  )
}

const Table: React.FC<{ products: IProduct[]; showArchive: boolean }> = ({
  products,
  showArchive,
}) => {
  const { t } = useTranslation('adminStripeProduct')
  const history = useHistory()

  const columns = useMemo<ColumnDef<IProduct>[]>(
    () => [
      { header: t('list.table.name'), accessorKey: 'name' },
      { header: t('list.table.description'), accessorKey: 'description' },
      {
        header: t('list.table.created'),
        accessorFn: (p) => formatUNIXToDate(p.created),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (p) =>
          makeCustomOperationButtons([
            makeCustomOperationButton(t('list.prices'), () =>
              history.push(Routes.AdminStripePrice, { product: p })
            ),
            makeCustomOperationButton(t('list.edit'), () =>
              history.push(Routes.AdminStripeProductEdit, { product: p })
            ),
          ]),
      },
    ],
    [history, t]
  )

  const data = useMemo(
    () => products.filter((p) => showArchive || p.active),
    [products, showArchive]
  )
  return useTanstackTable<IProduct>(columns, data, { fixedLastColumn: true })
}

export default TableBody
