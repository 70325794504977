import { CSV_DEFAULT_HEADER, formatUserCSV } from 'common/csv'
import { Routes } from 'common/enums'
import firebase from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IInvitePendingUser } from 'common/interfaces/invite'
import { ITeam } from 'common/interfaces/team'
import { IUser, IUserFromCSV } from 'common/interfaces/user'
import { date2Timestamp } from 'common/times'
import { checkFirebaseError, isDuplicate, isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedTeam, reloadCachedUser } from 'providers/AuthProvider'
import React from 'react'
import {
  changePasswordForCustomAuth,
  createCustomUser,
  createUsersByCSV,
} from 'repositories/functions/functions_auth'
import { get } from 'repositories/store/firebase_invite'
import {
  findById,
  getApprovedUser,
  getNewer,
  getNotUnapprovedAllUsers,
  getUnapprovedAllUsers,
  getUnapprovedUser,
  update,
} from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import {
  validateFormForCreateUser,
  validateFormForNewPassword,
} from 'services/validation/user'
import { createCsvBlobOnlyDataAndNoneDoubleQuote } from './csv_create'

export enum UpdateType {
  DISABLE = 'disable',
  ENABLE = 'enable',
  ADDGROUPS = 'addGroups',
  EXPIRATION = 'expiration',
}

/**
 * adminがfalseのユーザーのみを取得
 */
export const getNewerUsers = async (
  setData: React.Dispatch<React.SetStateAction<IUser[]>>,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  setData((await getNewer(storeCache.team!)).filter(({ admin }) => !admin))
}

/**
 * adminがfalseかつ未承認でないユーザーのみを取得
 */
export const getApprovedNewerUsers = async (
  setData: React.Dispatch<React.SetStateAction<IUser[]>>,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  setData(
    (await getNotUnapprovedAllUsers(storeCache.team!)).filter(
      ({ admin }) => !admin
    )
  )
}

/**
 * 特定の招待IDから加入したユーザーを取得
 */
export const getUsersByInvitedId = async (
  setData: React.Dispatch<React.SetStateAction<IUser[]>>,
  invitedId: string,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache, true)) return

  try {
    setData(await getApprovedUser(storeCache.team!, invitedId))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * 特定の招待IDから加入し承認されたユーザーを取得
 */
export const getUnapprovedUserByInvited = async (
  setData: React.Dispatch<React.SetStateAction<IUser[]>>,
  invitedId: string,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache, true)) return

  try {
    setData(await getUnapprovedUser(storeCache.team!, invitedId))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * 特定の招待IDから加入した全ての未承認ユーザーとそれに紐づく招待タイトルを取得する
 */
export const getInvitePendingUser = async (
  setData: React.Dispatch<React.SetStateAction<IInvitePendingUser[]>>,
  storeCache: IStoreCache
) => {
  if (!isLoggedIn(storeCache, true)) return

  const unApprovedAllUsers = await getUnapprovedAllUsers(storeCache.team!)
  const invites = await get(storeCache.team!)
  const invitePendingUsers: IInvitePendingUser[] = unApprovedAllUsers.map(
    (u) => {
      const invite = invites.find((i) => i.id === u.invited_id)
      return {
        ...u,
        invite_name: invite?.name ?? '',
      }
    }
  )

  setData(invitePendingUsers)
}

export type TPassword = {
  password: string
  confirmPassword: string
}

export type TNewUser = {
  id: string
  password: string
  is_password_changeable: boolean
}

export enum NewPasswordType {
  PASS,
  CONFIRM_PASS,
}

export enum NewUserType {
  ID,
  PASS,
  IS_PASSWORD_CHANGEABLE,
}

/**
 * パスワード初期値設定
 */
export const initPassword = (): TPassword => ({
  password: '',
  confirmPassword: '',
})

/**
 * 会員新規作成設定
 */
export const initNewUser = (): TNewUser => ({
  id: '',
  password: '',
  is_password_changeable: true,
})

/**
 * パスワード変更Modalの`onChange`イベントハンドラ
 */
export const onChangePassword = (
  e: React.ChangeEvent<HTMLInputElement>,
  type: NewPasswordType,
  data: TPassword,
  setData: React.Dispatch<React.SetStateAction<TPassword>>
): void =>
  setData({
    ...data,
    password: type === NewPasswordType.PASS ? e.target.value : data.password,
    confirmPassword:
      type === NewPasswordType.CONFIRM_PASS
        ? e.target.value
        : data.confirmPassword,
  })

/**
 * 会員新規作成Modalの`onChange`イベントハンドラ
 */
export const onChangeCreateUser = (
  e: React.ChangeEvent<HTMLInputElement>,
  type: NewUserType,
  data: TNewUser,
  setData: React.Dispatch<React.SetStateAction<TNewUser>>
): void =>
  setData({
    ...data,
    id: type === NewUserType.ID ? e.target.value : data.id,
    password: type === NewUserType.PASS ? e.target.value : data.password,
    is_password_changeable:
      type === NewUserType.IS_PASSWORD_CHANGEABLE
        ? e.target.checked
        : data.is_password_changeable,
  })

/**
 * パスワード変更Modalの「変更」ボタン押下時
 */
export const changeNewPassword = async (
  { password, confirmPassword }: TPassword,
  user: IUser,
  closeModal: () => void
): Promise<void> => {
  try {
    validateFormForNewPassword(password, confirmPassword)

    await changePasswordForCustomAuth(user.id, password)
    closeModal()
    alertService.show(
      true,
      i18nAlert('user.changedPassword', { userId: user.id })
    )
  } catch (error: any) {
    alertService.show(false, error.message)
    console.log(error)
  }
}

/**
 * 会員新規作成Modalの「新規登録」ボタン押下時
 */
export const createUser = async (
  teamId: string,
  history: History,
  newUser: TNewUser,
  closeModal: () => void
): Promise<void> => {
  try {
    validateFormForCreateUser(newUser.id, newUser.password)

    await createCustomUser(
      teamId,
      newUser.id,
      newUser.password,
      newUser.is_password_changeable
    )
    closeModal()
    alertService.show(
      true,
      i18nAlert('user.registered', { userId: newUser.id })
    )
    history.replace(Routes.AdminUser)
  } catch (error: any) {
    alertService.show(false, error.message)
    console.log(error)
  }
}

/**
 * Tableで選択した行の更新をTypeごとに処理を実施する
 *
 * UpdateType.DISABLE: 選択した行のユーザーを無効にする
 * UpdateType.ENABLE: 選択した行のユーザーを有効にする
 * Update.Type.ADDGROUPS: 選択した行のユーザーを選択したグループに追加する
 */
export const onUpdateUsers = async (
  storeCache: IStoreCache,
  type: UpdateType,
  selectedUsers: IUser[],
  closeModal: () => void,
  groupIds: string[],
  expiration: string
): Promise<void> => {
  try {
    if (
      selectedUsers.length === 0 ||
      (type === UpdateType.ADDGROUPS && groupIds.length === 0)
    ) {
      throw new Error(i18nAlert('select'))
    }

    await Promise.all(
      selectedUsers.map(async (su) => {
        const user = await findById(storeCache.team!, su.id)
        if (!user) return

        switch (type) {
          case UpdateType.DISABLE:
            user.disabled = true
            break
          case UpdateType.ENABLE:
            user.disabled = false
            break
          case UpdateType.ADDGROUPS:
            const uniqueGroupIds = Array.from(
              new Set([...user.group_ids, ...groupIds])
            )
            user.group_ids = uniqueGroupIds
            break
          case UpdateType.EXPIRATION:
            const newExpiration = expiration
              ? date2Timestamp(new Date(expiration))
              : firebase.firestore.Timestamp.fromMillis(0)
            user.expire = newExpiration
        }
        await update(storeCache.team!, user)
      })
    )
    await reloadCachedUser(storeCache)
    closeModal()
  } catch (error: any) {
    alertService.show(false, checkFirebaseError(error))
    console.log(error)
  }
}

/**
 * ユーザ取込のサンプルCSV blob生成処理
 */
export const sampleUserCsvBlob = (): string =>
  createCsvBlobOnlyDataAndNoneDoubleQuote([
    CSV_DEFAULT_HEADER,
    ['id_001', 'password01', '佐藤', 'グループID&グループID', '2042/8/24', '1'],
    ['id_002', 'password02', '田中', 'グループID', '2032/8/24', ''],
    ['id_003', 'password03', '山田', '', '', ''],
  ])

/**
 * インポートされたcsvを元にユーザを作成する
 */
export const registerUserCSV = async (
  users: IUserFromCSV[],
  customHeader: string[],
  storeCache: IStoreCache,
  setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> => {
  setButtonDisabled(true)
  try {
    await createUsersByCSV(storeCache.team!.id, users, customHeader)
    await reloadCachedTeam(storeCache)
    alertService.show(true, i18nAlert('created.user', { number: users.length }))
  } catch (error) {
    alertService.show(false, i18nAlert('user.failedImport'))
    console.log(error)
  }
  setButtonDisabled(false)
}

/**
 * インポートされたcsvからデータを抽出・作成する
 */
export const importUserCSV = (
  team: ITeam | null,
  csvData: any,
  setImportedUsers: React.Dispatch<React.SetStateAction<IUserFromCSV[]>>,
  setCustomHeaders: React.Dispatch<React.SetStateAction<string[]>>
) => {
  if (!team || !csvData) return

  const fileReader = new FileReader()
  fileReader.readAsBinaryString(csvData)
  fileReader.onload = () => {
    try {
      const userFromCSV = formatUserCSV(team, fileReader)
      if (isDuplicate(userFromCSV.data, 'id')) {
        throw new Error(i18nAlert('user.cantReadCSVByIdDuplication'))
      }

      setImportedUsers(userFromCSV.data)
      setCustomHeaders(userFromCSV.customHeaders)
    } catch (error: any) {
      alertService.show(false, error.message)
      console.log(error)
    }
  }
}
