import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { IGroup } from 'common/interfaces/group'
import { IUser } from 'common/interfaces/user'
import ButtonRight from 'components/atoms/Button/Right'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import { makeEditDeleteOperationButtons } from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { getGroupMemberCount, removeGroup } from 'services/admin/group'
import { getNewerUsers } from 'services/admin/user'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminUserGroup')
  const { storeCache } = useContext(AuthContext)
  const { groups } = storeCache

  const [users, setUsers] = useState<IUser[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await getNewerUsers(setUsers, storeCache)
      setIsLoading(false)
    })()
  }, [storeCache])

  if (isLoading) return <LoadingSpinner />
  if (groups.length <= 0) {
    return <RegisterItemButton type="group" />
  }
  return (
    <>
      <ButtonRight
        nextPage={Routes.AdminGroupCreate}
        content={t('list.newRegister')}
      />
      <Table users={users} />
    </>
  )
}

const Table: React.FC<{ users: IUser[] }> = ({ users }) => {
  const { t } = useTranslation('adminUserGroup')
  const { storeCache } = useContext(AuthContext)
  const { groups } = storeCache
  const history = useHistory()

  const columns = useMemo<ColumnDef<IGroup>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => groups.length - i,
      },
      {
        header: 'ID',
        accessorKey: 'id',
      },
      {
        header: t('list.table.name'),
        accessorKey: 'name',
      },
      {
        header: t('list.table.memberCount.columns'),
        accessorFn: (g) =>
          t('list.table.memberCount.data', {
            groupMemberCount: getGroupMemberCount(g, users),
          }),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (g) =>
          makeEditDeleteOperationButtons<IGroup>(
            history,
            'group',
            Routes.AdminGroupEdit,
            (e) => removeGroup(e, users, storeCache),
            g
          ),
      },
    ],
    [storeCache, history, groups.length, users, t]
  )

  const data = groups.sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  )
  return useTanstackTable<IGroup>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
