import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEnqueteAnswer } from 'common/interfaces/enquete_answer'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { findByUserId, get } from 'repositories/store/firebase_enquete_answer'

/**
 * 当該アンケートの回答取得処理
 */
export const getEnqueteAnswersById = async (
  storeCache: IStoreCache,
  enqueteId: string,
  setEnqueteAnswer: React.Dispatch<React.SetStateAction<IEnqueteAnswer[]>>
): Promise<void> => {
  setEnqueteAnswer(await get(storeCache.team!, enqueteId))
}

/**
 * ユーザーがチームのアンケートに回答済みかを返却
 */
export const getIsTeamAnswered = async (
  team: ITeam,
  user: IUser
): Promise<boolean> => {
  if (user.admin || !team.enquete_id) return true

  const result = await findByUserId(team, team.enquete_id, user.id)
  return result !== null
}
