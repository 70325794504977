import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import 'swiper/components/navigation/navigation.min.css'
import SwiperCore, { Navigation } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import './cardswiper.scss'

const CardSwiper: React.FC<{
  children: any
  className?: string
  isGenre?: boolean
  showAllLink: string
}> = ({ children, className, isGenre, showAllLink }) => {
  const { t } = useTranslation('userCommon')
  SwiperCore.use([Navigation])
  return (
    <Swiper
      className={className}
      breakpoints={{
        768: { slidesPerView: 2.5, slidesPerGroup: 2 },
        992: { slidesPerView: 3.5, slidesPerGroup: 3 },
      }}
      slidesPerView={1.5}
      slidesPerGroup={1}
      spaceBetween={16}
      navigation={{ disabledClass: 'd-none' }}
      autoHeight
    >
      {children}
      <SwiperSlide className="h-100">
        <Link to={showAllLink}>
          <div className={classNames('show-all-card', { 'is-genre': isGenre })}>
            <div className="show-all-card__body">
              <div className="card-text">{t('cardSwiper.showAll')}</div>
              <div className="card-arrow">
                <i className="fas fa-chevron-right" />
              </div>
            </div>
          </div>
        </Link>
      </SwiperSlide>
    </Swiper>
  )
}

export default CardSwiper
