import React from 'react'
import { Container } from 'reactstrap'

interface IH1 {
  title: string
}

const H1: React.FC<IH1> = (props: IH1) => {
  return (
    <div className="header pb-3 pt-3">
      <Container fluid>
        <h2>{props.title}</h2>
      </Container>
    </div>
  )
}

export default H1
