import { ILive } from 'common/interfaces/live'
import React from 'react'
import { getVimeoIframeSource } from 'services/user/vimeo'
import './livedetailbodycommon.scss'

interface IVimeoLiveDetailBody {
  live: ILive
}

const VimeoLiveDetailBody: React.FC<IVimeoLiveDetailBody> = ({ live }) => {
  return (
    <div className="live-detail-vimeo">
      <iframe
        className="vimeo-video"
        title="vimeo"
        allow="autoplay; fullscreen; picture-in-picture"
        src={getVimeoIframeSource(live.platform, live.vimeo_id)}
      />
    </div>
  )
}

export default VimeoLiveDetailBody
