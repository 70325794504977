import { Stripe as StripeJs } from '@stripe/stripe-js'
import { ONE_STEAM_ADMIN_EMAIL } from 'common/const'
import { AuthMethodType, LangType, Routes } from 'common/enums'
import { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ContentsOrderType } from 'common/interfaces/contents_orders'
import { IStripe } from 'common/interfaces/stripe'
import { ISubscription } from 'common/interfaces/subscription'
import { ITeamBasicInfo } from 'common/interfaces/team'
import { makePath2Url } from 'common/link_url'
import {
  checkFirebaseError,
  emptyStr2Null,
  isCustomerBalance,
  isCustomerBalanceV2,
  isLoggedIn,
} from 'common/utils'
import { History } from 'history'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import {
  reloadCachedSubscriptionObj,
  reloadCachedTeam,
} from 'providers/AuthProvider'
import { OptionTypeBase, OptionsType } from 'react-select'
import { send } from 'repositories/functions/functions_mail'
import {
  deleteSubscription,
  getCheckoutSessionForSetup,
  resumeSubscription,
} from 'repositories/functions/functions_stripe'
import { deleteTeam } from 'repositories/functions/functions_team'
import { get } from 'repositories/store/firebase_stripe'
import { updateBasicInfo } from 'repositories/store/firebase_team'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * IP制限の初期値を返却する
 */
export const getDefaultAllowIps = ({ allow_ips }: ITeamBasicInfo) => ({
  admin: allow_ips.admin.map((ip) => ({ value: ip, label: ip })),
  user: allow_ips.user.map((ip) => ({ value: ip, label: ip })),
})

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  COMPANY,
  TEL,
  NAME,
  EMAIL,
  ENQUETE_ID,
  IS_COMMENT_ACTIVE,
  URL_ABOUT,
  URL_TERMS,
  URL_CONTACT,
  URL_FAQ,
  URL_LAW,
  HIDE_FOOTER_LOGO,
  URL_ACCOUNT_REGISTER,
  IS_SOCIAL_GOOGLE,
  IS_PASSWORD_COMPLEX,
  IS_SINGLE_LOGIN,
  IS_SITE_PUBLIC,
  DEFAULT_CONTENTS_ORDER,
  ALLOW_IPS_ADMIN,
  ALLOW_IPS_USER,
  SAML_PROVIDER_ID,
  LANG,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  basicInfo: ITeamBasicInfo,
  setBasicInfo: React.Dispatch<React.SetStateAction<ITeamBasicInfo>>,
  type: InputType,
  { target: { value, checked } }: React.ChangeEvent<HTMLInputElement>
) =>
  setBasicInfo({
    ...basicInfo,
    name: type === InputType.NAME ? value : basicInfo.name,
    email: type === InputType.EMAIL ? value : basicInfo.email,
    urls: {
      ...basicInfo.urls,
      about:
        type === InputType.URL_ABOUT
          ? emptyStr2Null(value)
          : basicInfo.urls.about,
      terms:
        type === InputType.URL_TERMS
          ? emptyStr2Null(value)
          : basicInfo.urls.terms,
      contact:
        type === InputType.URL_CONTACT
          ? emptyStr2Null(value)
          : basicInfo.urls.contact,
      faq:
        type === InputType.URL_FAQ ? emptyStr2Null(value) : basicInfo.urls.faq,
      law:
        type === InputType.URL_LAW ? emptyStr2Null(value) : basicInfo.urls.law,
      account_register:
        type === InputType.URL_ACCOUNT_REGISTER
          ? emptyStr2Null(value)
          : basicInfo.urls.account_register,
    },
    company: type === InputType.COMPANY ? value : basicInfo.company,
    tel: type === InputType.TEL ? value : basicInfo.tel,
    hide_footer_logo:
      type === InputType.HIDE_FOOTER_LOGO
        ? checked
        : basicInfo.hide_footer_logo,
    social_logins: {
      ...basicInfo.social_logins,
      google:
        type === InputType.IS_SOCIAL_GOOGLE
          ? checked
          : basicInfo.social_logins.google,
    },
    is_password_complex:
      type === InputType.IS_PASSWORD_COMPLEX
        ? checked
        : basicInfo.is_password_complex ?? false,
    is_comment_active:
      type === InputType.IS_COMMENT_ACTIVE
        ? checked
        : basicInfo.is_comment_active,
    is_single_login:
      type === InputType.IS_SINGLE_LOGIN ? checked : basicInfo.is_single_login,
    is_site_public:
      type === InputType.IS_SITE_PUBLIC ? checked : basicInfo.is_site_public,
    enquete_id: type === InputType.ENQUETE_ID ? value : basicInfo.enquete_id,
    default_contents_orders:
      type === InputType.DEFAULT_CONTENTS_ORDER
        ? {
            video: value as ContentsOrderType,
            live: value as ContentsOrderType,
            event: value as ContentsOrderType,
            playlist: value as ContentsOrderType,
          }
        : basicInfo.default_contents_orders,
    saml_provider_id:
      type === InputType.SAML_PROVIDER_ID ? value : basicInfo.saml_provider_id,
    lang: type === InputType.LANG ? (value as LangType) : basicInfo.lang,
  })

/**
 * IP制限Inputの`onChange`イベントハンドラ
 */
export const onChangeAuthMethod = (
  basicInfo: ITeamBasicInfo,
  setBasicInfo: React.Dispatch<React.SetStateAction<ITeamBasicInfo>>,
  type: AuthMethodType
) => setBasicInfo({ ...basicInfo, auth_method: type })

/**
 * IP制限Inputの`onChange`イベントハンドラ
 */
export const onChangeAllowIps = (
  basicInfo: ITeamBasicInfo,
  setBasicInfo: React.Dispatch<React.SetStateAction<ITeamBasicInfo>>,
  type: InputType,
  e: OptionsType<OptionTypeBase>
) => {
  const ips = e.map((option) => option.value)
  setBasicInfo({
    ...basicInfo,
    allow_ips: {
      ...basicInfo.allow_ips,
      admin:
        type === InputType.ALLOW_IPS_ADMIN ? ips : basicInfo.allow_ips.admin,
      user: type === InputType.ALLOW_IPS_USER ? ips : basicInfo.allow_ips.user,
    },
  })
}

/**
 * SAML認証トグルInputの`onChange`イベントハンドラ
 */
export const onChangeUseSamlAuth = (
  useSamlAuth: boolean,
  setUseSamlAuth: React.Dispatch<React.SetStateAction<boolean>>,
  basicInfo: ITeamBasicInfo,
  setBasicInfo: React.Dispatch<React.SetStateAction<ITeamBasicInfo>>
) => {
  setUseSamlAuth(!useSamlAuth)
  if (useSamlAuth === true) return

  setBasicInfo({ ...basicInfo, saml_provider_id: '' })
}

/**
 * クレジットカード情報変更画面に遷移（チーム専用）
 */
export const changeCard = async (
  storeCache: IStoreCache,
  subscription?: ISubscription
): Promise<void> => {
  const { team, subscriptionObj } = storeCache
  if (!isLoggedIn(storeCache)) return

  try {
    if (
      (!subscription && isCustomerBalance(subscriptionObj)) ||
      (subscription && isCustomerBalanceV2(subscription))
    ) {
      throw new Error(
        '支払い方法が異なるため、クレジットカード情報は変更できません'
      )
    }

    const redirectURL = makePath2Url(Routes.AdminBasicInfo)
    const sessionId = (
      await getCheckoutSessionForSetup(
        undefined,
        subscription ? subscription.id : subscriptionObj!.subscription.id,
        team!.stripeId,
        redirectURL,
        redirectURL
      )
    ).id
    const { publishableKey }: IStripe = await get()
    const { Stripe } = window
    const stripe: StripeJs | undefined = Stripe
      ? Stripe(publishableKey)
      : undefined
    if (stripe) stripe.redirectToCheckout({ sessionId })
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * Stripeでsubscriptionの継続課金をキャンセル or 再開をする
 */
export const controlSubscription = (
  storeCache: IStoreCache,
  isCancel: boolean
) => {
  const modalMessage = isCancel
    ? i18nAlert('modal.confirm.subscription.cancel')
    : i18nAlert('modal.confirm.subscription.restart')
  modalService.show(modalMessage, async () => {
    const { subscriptionObj } = storeCache
    if (!isLoggedIn(storeCache, true) || !subscriptionObj) return

    try {
      isCancel
        ? await deleteSubscription(undefined, subscriptionObj.subscription.id)
        : await resumeSubscription(undefined, subscriptionObj.subscription.id)
      await reloadCachedSubscriptionObj(storeCache)

      const successMessage = isCancel
        ? i18nAlert('subscription.canceled')
        : i18nAlert('subscription.restarted')
      alertService.show(true, successMessage)

      if (isCancel) {
        const html = `<p>以下のチームがサブスクの更新を停止ボタンを押しました</p>
      <p>チームID：${storeCache.team!.id}</p>
      <p>チーム名：${storeCache.team!.name}</p>`

        await send(
          '',
          undefined,
          undefined,
          undefined,
          null,
          ONE_STEAM_ADMIN_EMAIL,
          '',
          `${storeCache.team!.name}(${
            storeCache.team!.id
          })がサブスクの更新を停止ボタンを押しました`,
          html,
          html
        )
      }
    } catch (error) {
      console.log(error)
      const message = checkFirebaseError(error)
      alertService.show(false, message)
    }
  })
}

/**
 * チームに関するデータを全て削除する
 */
export const removeTeam = async (storeCache: IStoreCache, history: History) => {
  modalService.show(i18nAlert('modal.confirm.deleteTeam'), async () => {
    if (!isLoggedIn(storeCache, true)) return

    try {
      const { team, user } = storeCache

      await deleteTeam(team!.id, user!.id)

      const html = `<p>以下のチームが解約されました。</p>
      <p>チームID：${team!.id}</p>
      <p>チーム名：${team!.name}</p>`
      await send(
        '',
        undefined,
        undefined,
        undefined,
        null,
        ONE_STEAM_ADMIN_EMAIL,
        '',
        `${team!.name}(${team!.id})が解約しました`,
        html,
        html
      )
      await auth.signOut()

      alertService.show(true, i18nAlert('thanksMessage'))
      history.push(Routes.LoginAdmin)
    } catch (error) {
      console.log(error)
      const message = checkFirebaseError(error)
      alertService.show(false, message)
    }
  })
}

/**
 * `ITeamBasicInfo` 更新処理
 */
export const saveBasicInfo = async (
  basicInfo: ITeamBasicInfo,
  storeCache: IStoreCache
): Promise<void> => {
  try {
    if (!basicInfo.name) throw new Error(i18nValidation('input.siteName'))
    if (!basicInfo.company) throw new Error(i18nValidation('input.companyName'))
    if (!basicInfo.tel) throw new Error(i18nValidation('input.phoneNumber'))

    await updateBasicInfo(storeCache.team!, basicInfo)
    await reloadCachedTeam(storeCache)

    alertService.show(true, i18nAlert('updated.basicInfo'))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
