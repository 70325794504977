import { ColumnDef } from '@tanstack/react-table'
import { IUser } from 'common/interfaces/user'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import { makeCustomOperationButton } from 'components/atoms/Table/ElementsOnTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  canRemoveAdministrator,
  getNewerAdministrators,
  removeAdministrator,
} from 'services/admin/administrator'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminAdministrator')
  const history = useHistory()
  const { storeCache } = useContext(AuthContext)
  const [users, setUsers] = useState<IUser[]>([])

  useEffect(() => {
    getNewerAdministrators(setUsers, storeCache)
  }, [setUsers, storeCache])

  const columns = useMemo<ColumnDef<IUser>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => users.length - i,
      },
      {
        header: t('list.table.name'),
        accessorFn: (u) => u.name ?? '',
      },
      {
        header: t('list.table.email'),
        accessorKey: 'email',
      },
      {
        header: t('list.table.operations'),
        accessorFn: (u) =>
          canRemoveAdministrator(storeCache, u) ? (
            makeCustomOperationButton(t('list.table.delete'), () =>
              removeAdministrator(storeCache, u, history)
            )
          ) : (
            <></>
          ),
      },
    ],
    [storeCache, history, users.length, t]
  )

  const data = users.sort((a, b) => b.created_at.seconds - a.created_at.seconds)
  return useTanstackTable<IUser>(columns, data)
}

export default TableBody
