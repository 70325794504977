import { CurrencyType } from 'common/enums'
import { IProduct } from 'common/interfaces/stripe/product'
import { ITaxRate } from 'common/interfaces/stripe/tax_rate'
import { ITeam } from 'common/interfaces/team'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  InputType,
  saveStripeProduct,
  onChangeInput as serviceOnChangeInput,
} from 'services/admin/product_form'
import { getStripeTaxRates } from 'services/admin/stripe/tax_rate'

type TFormBody = {
  isCreate: boolean
  productObj: IProduct
  history: History
}

const FormBody: React.FC<TFormBody> = ({
  isCreate,
  productObj,
  history,
}: TFormBody) => {
  const { t } = useTranslation('adminStripeProduct')
  const { storeCache } = useContext(AuthContext)

  const [product, setProduct] = useState<IProduct>(productObj)
  const [isSubscriptionProductIdON, setIsSubscriptionProductIdON] =
    useState<boolean>(false)
  const [taxRates, setTaxRates] = useState<ITaxRate[]>([])

  useEffect(() => {
    getStripeTaxRates(storeCache, setTaxRates)
  }, [storeCache])

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(product, setProduct, type, e)

  const save = () =>
    saveStripeProduct(history, product, isSubscriptionProductIdON, storeCache)

  return (
    <Form>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.name.title')}
              tooltipContent={t('form.name.tips')}
              required
            />
          </Label>
          <Input
            onChange={(e) => onChangeInput(InputType.NAME, e)}
            type="text"
            defaultValue={isCreate ? undefined : product.name}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.description.title')}
              tooltipContent={t('form.description.tips')}
              required
            />
          </Label>
          <Input
            onChange={(e) => onChangeInput(InputType.DESCRIPTION, e)}
            type="text"
            defaultValue={isCreate ? undefined : product.description}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.taxRate.title')}
              tooltipContent={t('form.taxRate.tips')}
              required
            />
          </Label>
          <Input
            onChange={(e) => onChangeInput(InputType.TAXRATE, e)}
            type="select"
            value={isCreate ? undefined : product.metadata.tax_rate}
          >
            {createTaxRateOptions(taxRates)}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>
            <InfoTooltip
              content={t('form.currency.title')}
              tooltipContent={t('form.currency.tips')}
              required
            />
          </Label>
          <Input
            onChange={(e) => onChangeInput(InputType.CURRENCY, e)}
            type="select"
            value={product.metadata.currency ?? CurrencyType.JPY}
            disabled={!isCreate}
          >
            <option value={CurrencyType.JPY}>{t('form.currency.jpy')}</option>
            <option value={CurrencyType.USD}>{t('form.currency.usd')}</option>
          </Input>
        </Col>
      </FormGroup>
      {showSubscriptionProductIdToggle(isCreate, storeCache.team!) && (
        <FormGroup>
          <Label>
            <InfoTooltip
              content={t('form.isSubscriptionProduct.title')}
              tooltipContent={t('form.isSubscriptionProduct.tips')}
            />
          </Label>
          <Row>
            <Col md="10">
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  onChange={() =>
                    setIsSubscriptionProductIdON(!isSubscriptionProductIdON)
                  }
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
          </Row>
        </FormGroup>
      )}
      {showActiveToggle(storeCache.team!, product) && (
        <FormGroup>
          <Label>
            <InfoTooltip
              content={t('form.active.title')}
              tooltipContent={t('form.active.tips')}
            />
          </Label>
          <Row>
            <Col md="10">
              <label className="custom-toggle">
                <input
                  type="checkbox"
                  onChange={(e) => onChangeInput(InputType.ACTIVE, e)}
                  defaultChecked={product.active}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
          </Row>
        </FormGroup>
      )}
      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {t('form.save')}
        </Button>
      </div>
    </Form>
  )
}

export const createTaxRateOptions = (taxRates: ITaxRate[]): JSX.Element[] => {
  const result = [<option />]
  taxRates.map((tr) =>
    result.push(
      <option key={tr.id} value={tr.id}>
        {tr.display_name}：{tr.percentage}%
      </option>
    )
  )
  return result
}

const showSubscriptionProductIdToggle = (
  isCreate: boolean,
  { stripe: { subscription_product_id } }: ITeam
): boolean => isCreate && !subscription_product_id

const showActiveToggle = (team: ITeam, product: IProduct): boolean =>
  team!.stripe.subscription_product_id !== product.id

export default FormBody
