import classNames from 'classnames'
import React from 'react'
import { NavLink as NavLinkRRD } from 'react-router-dom'
import { NavItem, NavLink } from 'reactstrap'
import './itemlink.scss'

interface IItemLink {
  setCollapseOpen: React.Dispatch<React.SetStateAction<boolean>>
  nextPage: string
  iconName: string
  content: string
  asNewTab?: boolean
  tag?: React.ElementType
}

const ItemLink: React.FC<IItemLink> = (props: IItemLink) => {
  return (
    <NavItem>
      <NavLink
        to={props.nextPage}
        href={props.nextPage}
        tag={props.tag ?? NavLinkRRD}
        target={props.asNewTab ? '_blank' : undefined}
        onClick={() => props.setCollapseOpen(false)}
      >
        <i className={classNames('nav-icon', props.iconName)} />
        {props.content}
      </NavLink>
    </NavItem>
  )
}

export default ItemLink
