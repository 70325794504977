import { InvoiceActionType } from 'common/enums'
import { ICoupon } from 'common/interfaces/stripe/coupon'
import { IPaymentIntent } from 'common/interfaces/stripe/payment_intent'
import { IProductInfo } from 'common/interfaces/stripe/product_price'
import { IVideoPurchaseLead } from 'common/interfaces/video'
import { makePath2Url } from 'common/link_url'
import { transitionAuth as utilTransitionToAuth } from 'common/utils'
import Item from 'components/atoms/Item/Item'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { getStripeCoupons } from 'services/admin/stripe/coupon'
import { IInvoicePayment } from 'services/invoice_payment_modal'
import { transitionCheckoutSession } from 'services/item_select'
import {
  IListLocation,
  cancelPayment,
  createInvoicePaymentForUser,
  getProductInfoByPriceIds,
  resendInvoice,
} from 'services/item_select_video'
import { changePriceForUser } from 'services/user/change_price'
import InvoicePaymentModal from '../Admin/AdminItemSelect/InvoicePaymentModal'

interface IList {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const List: React.FC<IList> = (props) => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const { isCatalogPage, content, redirectPath } =
    useLocation<IListLocation>().state

  const [productInfos, setProductInfos] = useState<IProductInfo[]>([])
  const [coupons, setCoupons] = useState<ICoupon[]>([])
  const [couponId, setCouponId] = useState<string | undefined>(undefined)
  const [isOpenInvoicePayment, setIsOpenInvoicePayment] =
    useState<boolean>(false)
  const [selectProductInfo, setSlectProductInfo] =
    useState<IProductInfo | null>(null)
  const [actionType, setActionType] = useState<InvoiceActionType>(
    InvoiceActionType.DEFAULT
  )
  const [createdPaymentIntent, setCreatedPaymentIntent] =
    useState<IPaymentIntent | null>(null)

  useEffect(() => {
    ;(async () => {
      await Promise.all([
        getStripeCoupons(storeCache, setCoupons),
        getProductInfoByPriceIds(
          isCatalogPage,
          storeCache,
          content.price_ids,
          setProductInfos
        ),
      ])
    })()
  }, [isCatalogPage, storeCache, content.price_ids])

  const redirectURL = makePath2Url(redirectPath)

  const changeIsOpenInvoicePayment = () => {
    setIsOpenInvoicePayment(!isOpenInvoicePayment)
    setActionType(InvoiceActionType.DEFAULT)
  }

  const transitionToAuth = (
    price: string,
    couponId: string | undefined,
    isInvoicePayment = false
  ) =>
    utilTransitionToAuth(history, storeCache.team!, selectProductInfo, {
      redirectPath,
      price,
      couponId,
      isInvoicePayment,
    } as IVideoPurchaseLead)

  const checkoutSession = (
    productInfo: IProductInfo,
    couponId: string | undefined
  ) =>
    transitionCheckoutSession(
      storeCache,
      productInfo,
      couponId,
      redirectURL,
      redirectURL
    )

  const changePrice = (price: string) =>
    changePriceForUser(
      history,
      storeCache,
      price,
      redirectPath,
      props.setLoading
    )

  const selectedProductInfo = (
    selectProductInfo: IProductInfo,
    couponId: string | undefined
  ) => {
    if (isCatalogPage) {
      ;(async () => {
        await transitionToAuth(selectProductInfo.id, couponId, true)
      })()
      return
    }

    setSlectProductInfo(selectProductInfo)
    setCouponId(couponId)
    setIsOpenInvoicePayment(true)
  }

  const createInvoicePayment = (
    selectedProductInfo: IProductInfo,
    invoicePayment: IInvoicePayment,
    couponId: string | undefined
  ) =>
    createInvoicePaymentForUser(
      isCatalogPage,
      storeCache,
      history,
      selectedProductInfo,
      invoicePayment,
      props.setLoading,
      setActionType,
      setCreatedPaymentIntent,
      {
        redirectPath,
        price: selectedProductInfo.id,
        couponId,
        isInvoicePayment: true,
      } as IVideoPurchaseLead
    )

  const resend = (invoicePayment: IInvoicePayment) =>
    resendInvoice(
      storeCache,
      invoicePayment,
      createdPaymentIntent,
      props.setLoading
    )

  const cancel = (
    selectedProductInfo: IProductInfo,
    invoicePayment: IInvoicePayment
  ) =>
    cancelPayment(
      storeCache,
      history,
      invoicePayment,
      createdPaymentIntent,
      selectedProductInfo,
      couponId,
      redirectPath,
      props.setLoading
    )

  const Items = () =>
    productInfos.map((productInfo: IProductInfo, key: number) => {
      if (productInfo.hidden) return <></>

      return (
        <Item
          team={storeCache.team}
          user={storeCache.user!}
          subscriptionObj={storeCache.selfSubscriptionObj}
          productInfo={productInfo}
          coupons={coupons}
          purchasedPriceId=""
          transitionToAuth={(couponId: string | undefined) =>
            transitionToAuth(productInfo.id, couponId)
          }
          checkoutSession={(couponId: string | undefined) =>
            checkoutSession(productInfo, couponId)
          }
          changePrice={() => changePrice(productInfo.id)}
          createInvoicePayment={(couponId: string | undefined) =>
            selectedProductInfo(productInfo, couponId)
          }
          key={key}
        />
      )
    })

  return (
    <>
      {Items()}

      <InvoicePaymentModal
        selectProductInfo={selectProductInfo}
        couponId={couponId}
        isOpen={isOpenInvoicePayment}
        actionType={actionType}
        onToggleModal={changeIsOpenInvoicePayment}
        onClick={(
          selectedProductInfo: IProductInfo,
          invoicePayment: IInvoicePayment,
          couponId: string | undefined
        ) =>
          createInvoicePayment(selectedProductInfo, invoicePayment, couponId)
        }
        resendInvoice={(invoicePayment: IInvoicePayment) =>
          resend(invoicePayment)
        }
        cancelPayment={(
          selectedProductInfo: IProductInfo,
          invoicePayment: IInvoicePayment
        ) => cancel(selectedProductInfo, invoicePayment)}
      />
    </>
  )
}

export default List
