import { IStoreCache } from 'common/interfaces/auth_provider'
import CardSwiper from 'components/molecules/User/CardSwiper/CardSwiper'
import GenreCard from 'components/molecules/User/GenreCard/GenreCard'
import SideBySideTitle from 'components/molecules/User/SideBySideTitle/SideBySideTitle'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'
import {
  createShowAllLink,
  createTitle,
  GenreType,
  get7GenreData,
} from 'services/user/sidescroll_genre_list'
import { SwiperSlide } from 'swiper/react'
import './sidescrollgenrelist.scss'

interface ISideScrollGenreList {
  genreType: GenreType
  targetId?: string
}

const SideScrollGenreList: React.FC<ISideScrollGenreList> = ({
  genreType,
  targetId,
}: ISideScrollGenreList) => {
  const { t } = useTranslation('userIndex')
  const { storeCache } = useContext(AuthContext)
  const title = createTitle(storeCache, genreType, targetId)
  const showAllLink = createShowAllLink(genreType, targetId)
  const genreCards = createGenreCards(storeCache, genreType, targetId)

  if (genreCards.length <= 0) return null

  return (
    <div className="side-scroll-genre-list">
      <Container>
        <SideBySideTitle
          title={title}
          subTitle={t('sideScrollGenreList.showAll')}
          subTitleLink={showAllLink}
        />
        <CardSwiper
          className="genre-container"
          isGenre
          showAllLink={showAllLink}
        >
          {genreCards}
        </CardSwiper>
      </Container>
    </div>
  )
}

const createGenreCards = (
  storeCache: IStoreCache,
  type: GenreType,
  targetId?: string
): JSX.Element[] => {
  const is16x9 =
    type === GenreType.NEW_EVENTS ||
    type === GenreType.NEW_PLAYLISTS ||
    type === GenreType.PLAYLIST_IN_CATEGORY ||
    type === GenreType.PLAYLIST_IN_TAG ||
    type === GenreType.PLAYLIST

  return get7GenreData(storeCache, type, targetId).map(
    ({ id, name, link, image }) => (
      <SwiperSlide key={id}>
        <GenreCard
          className="genre-card"
          name={name}
          link={link}
          image={image}
          is16x9={is16x9}
        />
      </SwiperSlide>
    )
  )
}

export default SideScrollGenreList
