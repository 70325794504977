import { IStoreCache } from 'common/interfaces/auth_provider'
import { ILive } from 'common/interfaces/live'
import { IStripe } from 'common/interfaces/stripe'
import { IVideo } from 'common/interfaces/video'
import { isBasicPrice, isTrialPlan } from 'common/utils'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Select, { OptionsType } from 'react-select'
import { Col, FormGroup, Input, Label } from 'reactstrap'

type TLimitPlayInput = {
  storeCache: IStoreCache
  stripe: IStripe
  content: IVideo | ILive
  isLimitPlayActive: boolean
  defaultPrices: { value: string; label: string }[]
  priceOptions: { value: string; label: string }[]
  setIsLimitPlayActive: (value: React.SetStateAction<boolean>) => void
  onChangeIsRegistered: React.ChangeEventHandler<HTMLInputElement>
  onChange: (e: OptionsType<{ value: string; label: string }>) => void
}

const LimitPlayInput: React.FC<TLimitPlayInput> = ({
  storeCache,
  stripe,
  content,
  isLimitPlayActive,
  defaultPrices,
  priceOptions,
  setIsLimitPlayActive,
  onChangeIsRegistered,
  onChange,
}: TLimitPlayInput) => {
  const { t } = useTranslation('adminCommon')

  return (
    <>
      <FormGroup check row className="mb-3">
        <Col md="10">
          <Label check>
            <Input
              checked={isLimitPlayActive}
              onChange={() => setIsLimitPlayActive(!isLimitPlayActive)}
              type="checkbox"
            />
            {t('limitPlayInput.limitPlayActive')}
          </Label>
        </Col>
      </FormGroup>
      {isLimitPlayActive && (
        <>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                value="true"
                onChange={onChangeIsRegistered}
                checked={content.is_registered}
                disabled={!content.is_public}
              />
              {t('limitPlayInput.playMembersOnly')}
            </Label>
          </FormGroup>
          <FormGroup check className="my-3">
            <Label check>
              <Input
                type="radio"
                value="false"
                onChange={onChangeIsRegistered}
                checked={!content.is_registered}
              />
              {t('limitPlayInput.playMembersAndPurchaserOnly')}
            </Label>
          </FormGroup>
          {!content.is_registered && (
            <FormGroup row>
              <Col md="8">
                <Label>
                  <InfoTooltip
                    content={t('limitPlayInput.limitPlay.title')}
                    tooltipContent={t('limitPlayInput.limitPlay.tips')}
                  />
                  <div style={{ fontSize: '0.8rem' }}>
                    {t('limitPlayInput.limitPlay.tips2')}
                  </div>
                </Label>
                <Select
                  value={defaultPrices}
                  defaultValue={defaultPrices}
                  onChange={(e) => onChange(e)}
                  options={priceOptions}
                  closeMenuOnSelect={false}
                  isMulti
                  isDisabled={
                    isTrialPlan(storeCache.subscriptionObj) ||
                    isBasicPrice(storeCache.subscriptionObj, stripe)
                  }
                />
              </Col>
            </FormGroup>
          )}
        </>
      )}
    </>
  )
}

export default LimitPlayInput
