import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './quillform.css'

/* eslint-disable-next-line @typescript-eslint/no-var-requires */
const { imageCompressor } = require('quill-image-compress')

Quill.register('modules/imageCompress', imageCompressor)

/**
 * Wrapper for css omission in `ReactQuill`
 */
export default class QuillForm extends ReactQuill {}
