import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEvent } from 'common/interfaces/event'
import { ILive } from 'common/interfaces/live'
import { IPlaylist } from 'common/interfaces/playlist'
import { IVideo } from 'common/interfaces/video'
import { ContentTypeListPageType, IContentTypeListParams } from './page_params'
import {
  createEventListTitle,
  getEventListPageType,
  getFilteredEvents,
} from './type_event'
import {
  createLiveListTitle,
  getFilteredLives,
  getLiveListPageType,
} from './type_live'
import {
  createPlaylistListTitle,
  getFilteredPlaylists,
  getPlaylistListPageType,
} from './type_playlist'
import {
  createVideoListTitle,
  getFilteredVideos,
  getVideoListPageType,
} from './type_video'

const getPageType = (
  params: IContentTypeListParams
): ContentTypeListPageType => {
  switch (params.type) {
    case 'lives':
      return getLiveListPageType(params)
    case 'events':
      return getEventListPageType(params)
    case 'playlists':
      return getPlaylistListPageType(params)
  }
  return getVideoListPageType(params)
}

/**
 * コンテンツ一覧ページのタイトルを返却
 */
export const createTitle = (
  storeCache: IStoreCache,
  params: IContentTypeListParams
): JSX.Element[] => {
  const pageType = getPageType(params)
  switch (params.type) {
    case 'lives':
      return createLiveListTitle(pageType, storeCache, params)
    case 'events':
      return createEventListTitle(pageType, storeCache, params)
    case 'playlists':
      return createPlaylistListTitle(pageType, storeCache, params)
  }
  return createVideoListTitle(pageType, storeCache, params)
}

/**
 * コンテンツ一覧ページ内のフィルターされたコンテンツを返却
 */
export const getFilteredContents = async (
  storeCache: IStoreCache,
  params: IContentTypeListParams
): Promise<IVideo[] | ILive[] | IEvent[] | IPlaylist[]> => {
  const pageType = getPageType(params)
  switch (params.type) {
    case 'lives':
      return getFilteredLives(pageType, storeCache, params)
    case 'events':
      return getFilteredEvents(pageType, storeCache, params)
    case 'playlists':
      return getFilteredPlaylists(pageType, storeCache, params)
  }
  return getFilteredVideos(pageType, storeCache, params)
}
