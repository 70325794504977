import H1 from 'components/atoms/Head/H1'
import Body from 'components/organisms/Admin/ContractingPlan/Body'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'

const ContractingPlan: React.FC = () => {
  const { t } = useTranslation('adminContractingPlan')
  return (
    <>
      <H1 title={t('title')} />

      <Container fluid>
        <Body />
      </Container>
    </>
  )
}

export default ContractingPlan
