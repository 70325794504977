import { Routes } from 'common/enums'
import { findCachedGroupsNonNull } from 'common/find_store_cache'
import firebase from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICustomizeFields, IUser } from 'common/interfaces/user'
import { date2Timestamp, nowTimestamp } from 'common/times'
import { createGroupOptions, isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { reloadCachedUser } from 'providers/AuthProvider'
import { removeUser as removeUserApi } from 'repositories/functions/functions_auth'
import { update } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'
import { validateForm } from 'services/validation/user_edit'

/**
 * ユーザーのデフォルト値を返却する
 */
export const getUserDefaults = (
  storeCache: IStoreCache,
  { group_ids }: IUser
) => ({
  defaultGroups: findCachedGroupsNonNull(storeCache, group_ids).map(
    ({ id, name }) => ({ value: id, label: name })
  ),
})

/**
 * Input/Selectで使用するoptionsを返却する
 */
export const getFormOptions = ({ groups }: IStoreCache) => ({
  groupOptions: createGroupOptions(groups),
})

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
  PHONE,
  GROUP_IDS,
  EXPIRE,
  IS_PASSWORD_CHANGEABLE,
  NOTIFICATION_SETTING_NEWS,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  user: IUser,
  setUser: React.Dispatch<React.SetStateAction<IUser>>,
  type: InputType,
  e: React.ChangeEvent<HTMLInputElement> | any
) => {
  const val = e.target?.value
  const checked = e.target?.checked
  setUser({
    ...user,
    name: type === InputType.NAME ? val : user.name ?? '',
    phone: type === InputType.PHONE ? val : user.phone ?? '',
    group_ids:
      type === InputType.GROUP_IDS
        ? e.map((x: any) => x.value)
        : user.group_ids,
    expire:
      type === InputType.EXPIRE
        ? val
          ? date2Timestamp(new Date(val))
          : firebase.firestore.Timestamp.fromMillis(0)
        : user.expire,
    is_password_changeable:
      type === InputType.IS_PASSWORD_CHANGEABLE
        ? checked
        : user.is_password_changeable,
    notification_setting: {
      ...user.notification_setting,
      news:
        type === InputType.NOTIFICATION_SETTING_NEWS
          ? checked
          : user.notification_setting.news,
    },
  })
}

/**
 * 会員有効/無効化処理
 */
export const toggleUserDisabled = (
  history: History,
  user: IUser,
  storeCache: IStoreCache,
  customizeFields: ICustomizeFields[] | null | undefined
): void => {
  if (!isLoggedIn(storeCache)) return

  modalService.show(
    user.disabled
      ? i18nAlert('modal.confirm.user.enable')
      : i18nAlert('modal.confirm.user.disabled'),
    async () => {
      user.disabled = !user.disabled
      await updateUser(history, user, storeCache, customizeFields)
    }
  )
}

/**
 * 会員削除処理
 */
export const removeUser = (
  { push }: History,
  { id }: IUser,
  storeCache: IStoreCache
): void => {
  if (!isLoggedIn(storeCache, true)) return

  modalService.show(i18nAlert('modal.confirm.deleteUser'), async () => {
    const { team, user } = storeCache
    const result = await removeUserApi(
      team!.id,
      user!.id,
      id,
      team!.stripe.account_id
    )
    if (result) {
      alertService.show(true, i18nAlert('deleted.user'))
      push(Routes.AdminUser)
    } else {
      alertService.show(false, i18nAlert('cantDeletedUser'))
    }
  })
}

/**
 * ユーザーレコード更新処理
 */
export const updateUser = async (
  history: History,
  user: IUser,
  storeCache: IStoreCache,
  customizeFields: ICustomizeFields[] | null | undefined,
  isTransitionUserList = true,
  isUserPageSetting = false
): Promise<void> => {
  if (!isLoggedIn(storeCache, true)) return

  try {
    if (isUserPageSetting && storeCache.user!.admin) {
      throw new Error(i18nValidation('setting.cantChange.admin'))
    }
    validateForm(storeCache.team!, customizeFields)

    user.customize_fields = customizeFields
    user.updated_at = nowTimestamp()
    await update(storeCache.team!, user)
    if (user.id === storeCache.user?.id) await reloadCachedUser(storeCache)

    alertService.show(true, i18nAlert('updated.user'))
    if (isTransitionUserList) {
      history.push(Routes.AdminUser)
    }
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
