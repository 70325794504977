import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEnquete } from 'common/interfaces/enquete'
import { IEnqueteAnswerField } from 'common/interfaces/enquete_answer'
import {
  IEnqueteCustomize,
  IEnqueteType,
} from 'common/interfaces/enquete_customize'
import { findById } from 'repositories/store/firebase_enquete'

/**
 * 当該アンケート取得処理
 */
export const getEnqueteById = async (
  { team }: IStoreCache,
  enqueteId: string,
  setEnquete: React.Dispatch<React.SetStateAction<IEnquete | null>>
): Promise<void> => setEnquete(await findById(team!, enqueteId))

/**
 * 各アンケート入力欄の`onChange`イベントハンドラ
 */
export const initEnqueteField = ({
  questions,
}: IEnquete): IEnqueteAnswerField[] =>
  questions!.map(({ questionName, type }) => ({
    [questionName]: type !== IEnqueteType.CHECKBOX ? '' : [],
  }))

/**
 * 各アンケート入力欄の`onChange`イベントハンドラ
 */
export const onChangeInputForEnquete = async (
  { target: { value, checked } }: React.ChangeEvent<HTMLInputElement>,
  { questionName, type }: IEnqueteCustomize,
  index: number,
  {
    enqueteField,
    setEnqueteField,
  }: {
    enqueteField: IEnqueteAnswerField[]
    setEnqueteField: React.Dispatch<React.SetStateAction<IEnqueteAnswerField[]>>
  }
): Promise<void> => {
  if (!enqueteField) return
  if (type === IEnqueteType.CHECKBOX) {
    const findObj = enqueteField.find(
      (ef) => Object.keys(ef)[0] === questionName
    )
    if (!findObj) return

    const checkboxField: string[] = findObj[questionName] as string[]

    if (checked) checkboxField.push(value)
    else checkboxField.splice(checkboxField.indexOf(value), 1)

    enqueteField.splice(index, 1, {
      [questionName]: Array.from(new Set(checkboxField)),
    })
  } else {
    enqueteField.splice(index, 1, { [questionName]: value })
  }
  setEnqueteField([...enqueteField])
}
