import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { ITag } from 'common/interfaces/tag'
import ButtonRight from 'components/atoms/Button/Right'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeEditDeleteOperationButtons,
  makeThumbnailElement,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { getTagUseCount, removeTag } from 'services/admin/tag'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminTag')
  const { tags } = useContext(AuthContext).storeCache

  if (tags.length <= 0) {
    return <RegisterItemButton type="tag" />
  }
  return (
    <>
      <ButtonRight
        nextPage={Routes.AdminTagCreate}
        content={t('list.newRegister')}
      />
      <Table />
    </>
  )
}

const Table: React.FC = () => {
  const { t } = useTranslation('adminTag')
  const { storeCache } = useContext(AuthContext)
  const { tags } = storeCache
  const history = useHistory()

  const columns = useMemo<ColumnDef<ITag>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => tags.length - i,
      },
      {
        header: t('list.table.name'),
        accessorKey: 'name',
      },
      {
        header: t('list.table.thumbnail'),
        accessorFn: (tag) => makeThumbnailElement(tag.image, tag.name, 'tag'),
      },
      {
        header: t('list.table.sortNumber'),
        accessorFn: (t) => (t.sort_num === null ? '' : t.sort_num),
        enableSorting: true,
      },
      {
        header: t('list.table.usingVideoCount.title'),
        accessorFn: (tag) =>
          t('list.table.usingVideoCount.count', {
            usingTagCount: getTagUseCount(tag, storeCache),
          }),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (tag) =>
          makeEditDeleteOperationButtons<ITag>(
            history,
            'tag',
            Routes.AdminTagEdit,
            (e) => removeTag(e, storeCache),
            tag
          ),
      },
    ],
    [storeCache, history, tags.length, t]
  )

  const data = tags.sort((a, b) => b.created_at.seconds - a.created_at.seconds)
  return useTanstackTable<ITag>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
