import { AuthType } from 'common/auth_type'
import {
  EmailAction,
  IAdministratorsNotifyType,
  IMailTemplateEventName,
  Routes,
} from 'common/enums'
import firebase, { auth } from 'common/firebase'
import {
  IFieldCustomize,
  IFieldCustomizeType,
} from 'common/interfaces/field_customize'
import { IInvite } from 'common/interfaces/invite'
import {
  IAdminLogin,
  ILogin,
  ILoginByJoinForUser,
} from 'common/interfaces/login'
import { ISubscriptionObject } from 'common/interfaces/subscription'
import { ITeam } from 'common/interfaces/team'
import { ICustomizeFields, IUser } from 'common/interfaces/user'
import { IVideoPurchaseLead } from 'common/interfaces/video'
import { makeUserRootPath } from 'common/link_path'
import { makeLoginTeamUrl, makePath2Url } from 'common/link_url'
import { addedUnixTime, nowTimestamp, unix2Timestamp } from 'common/times'
import {
  checkFirebaseError,
  isAuthMethodEmail,
  isCustomizeFieldEnabled,
} from 'common/utils'
import { History } from 'history'
import { i18nAdminCommon, i18nAlert, i18nValidation } from 'i18n/i18n'
import { getCustomToken } from 'repositories/functions/functions_auth'
import {
  send,
  sendToAdministrators,
} from 'repositories/functions/functions_mail'
import {
  createInvoicePayment,
  fetchProductInfoByPriceIds,
  fetchSubscriptionObj,
} from 'repositories/functions/functions_stripe'
import { consumeCount } from 'repositories/store/firebase_invite'
import {
  findByAdminInviteId,
  findByUserInviteId,
} from 'repositories/store/firebase_team'
import { findTeamUserById, store } from 'repositories/store/firebase_user'
import { alertService } from './alert'
import { IInvoicePayment } from './invoice_payment_modal'
import { redirectToCheckout } from './item_select'
import { setTeamId } from './local_storage'
import { validateForm } from './validation/join'

export const initJoinState = (): ILogin => ({
  email: '',
  password: '',
  custom: '',
})

export const initAnyJoinState = (): ILoginByJoinForUser => ({
  name: '',
  phone: '',
})

export const initAnyFields = (): IAnyFields[] => [
  {
    field: 'name',
    title: i18nAdminCommon('name'),
    type: InputType.NAME,
  },
  {
    field: 'phone',
    title: i18nAdminCommon('phone'),
    type: InputType.PHONE,
  },
]

export enum InputType {
  NAME,
  PHONE,
  EMAIL,
  PASS,
  CUSTOM,
}

export interface IAnyFields {
  field: string
  title: string
  type: InputType
}

export const serviceOnChangeInput = (
  type: InputType,
  { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  {
    joinState: { email, password, custom },
    setJoinState,
  }: {
    joinState: ILogin
    setJoinState: (value: React.SetStateAction<ILogin>) => void
  }
) =>
  setJoinState({
    email: type === InputType.EMAIL ? value : email,
    password: type === InputType.PASS ? value : password,
    custom: type === InputType.CUSTOM ? value : custom,
  })

export const serviceOnChangeInputForAnyState = (
  type: InputType,
  { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  {
    anyJoinState,
    setAnyJoinState,
  }: {
    anyJoinState: ILoginByJoinForUser
    setAnyJoinState: (value: React.SetStateAction<ILoginByJoinForUser>) => void
  }
) =>
  setAnyJoinState({
    name: type === InputType.NAME ? value : anyJoinState.name,
    phone: type === InputType.PHONE ? value : anyJoinState.phone,
  })

export const onChangeInputForCustomizeFields = (
  index: number,
  customizeFieldObjs: IFieldCustomize[],
  customizeFieldObj: IFieldCustomize,
  { target: { value, checked } }: React.ChangeEvent<HTMLInputElement>,
  {
    customizeField,
    setCustomizeField,
  }: {
    customizeField: ICustomizeFields[] | null | undefined
    setCustomizeField: React.Dispatch<
      React.SetStateAction<ICustomizeFields[] | null | undefined>
    >
  }
) => {
  if (!customizeField) return

  customizeFieldObjs.forEach(
    (customizeFieldData: IFieldCustomize, key: number) => {
      const customizeFieldDataFieldName: any = customizeFieldData.fieldName
      const findCustomizeFieldObj =
        customizeField &&
        customizeField.find(
          (findCustomizeField) =>
            Object.keys(findCustomizeField)[0] === customizeFieldDataFieldName
        )
      let result =
        customizeFieldData.type === IFieldCustomizeType.BOOL ? false : ''
      if (findCustomizeFieldObj) {
        result = Object.values(findCustomizeFieldObj)[0]
      }

      customizeField.splice(key, 1, { [customizeFieldDataFieldName]: result })
    }
  )
  const val =
    customizeFieldObj.type === IFieldCustomizeType.BOOL ? checked : value
  customizeField.splice(index, 1, { [customizeFieldObj.fieldName]: val })
  setCustomizeField([...customizeField])
}

const findTeam = async (
  inviteId: string,
  isAdmin: boolean,
  setTeam: React.Dispatch<React.SetStateAction<ITeam | null>>,
  setIInvite: React.Dispatch<React.SetStateAction<IInvite | null>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorReason: React.Dispatch<React.SetStateAction<string>>,
  setSubscriptionObj: React.Dispatch<
    React.SetStateAction<ISubscriptionObject | null>
  >,
  setCustomizeField?: React.Dispatch<
    React.SetStateAction<ICustomizeFields[] | null | undefined>
  >
) => {
  let data: { team: ITeam; invite: IInvite } | string = ''
  if (inviteId.trim() !== '') {
    data = isAdmin
      ? await findByAdminInviteId(inviteId)
      : await findByUserInviteId(inviteId)
  }
  if (typeof data === 'string') {
    setErrorReason(data)
    setTeam(null)
    setIInvite(null)
    setIsLoading(false)
    return
  }

  const { team, invite } = data
  setTeam(team)
  setIInvite(invite)

  const subscriptionObj = await fetchSubscriptionObj(undefined, team.stripeId)
  setSubscriptionObj(subscriptionObj)

  setIsLoading(false)

  if (
    isCustomizeFieldEnabled(team, invite) &&
    setCustomizeField !== undefined
  ) {
    const customizeFields: ICustomizeFields[] = []
    for (const customizeField of data!.team.customize_field!) {
      const tmpCustomizeField: ICustomizeFields = {}
      tmpCustomizeField[customizeField.fieldName] =
        customizeField.type === IFieldCustomizeType.BOOL ? false : ''
      customizeFields.push(tmpCustomizeField)
    }
    setCustomizeField(customizeFields)
  }
}

/**
 * CloudFunctionsの管理者を招待したチームの特定をキックする
 */
export const findTeamAdministrator = async (
  inviteId: string,
  setTeam: React.Dispatch<React.SetStateAction<ITeam | null>>,
  setIInvite: React.Dispatch<React.SetStateAction<IInvite | null>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorReason: React.Dispatch<React.SetStateAction<string>>,
  setSubscriptionObj: React.Dispatch<
    React.SetStateAction<ISubscriptionObject | null>
  >
) =>
  findTeam(
    inviteId,
    true,
    setTeam,
    setIInvite,
    setIsLoading,
    setErrorReason,
    setSubscriptionObj,
    undefined
  )

/**
 * CloudFunctionsの会員を招待したチームの特定をキックする
 */
export const findTeamUser = async (
  inviteId: string,
  setTeam: React.Dispatch<React.SetStateAction<ITeam | null>>,
  setIInvite: React.Dispatch<React.SetStateAction<IInvite | null>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorReason: React.Dispatch<React.SetStateAction<string>>,
  setSubscriptionObj: React.Dispatch<
    React.SetStateAction<ISubscriptionObject | null>
  >,
  setCustomizeField: React.Dispatch<
    React.SetStateAction<ICustomizeFields[] | null | undefined>
  >
) =>
  findTeam(
    inviteId,
    false,
    setTeam,
    setIInvite,
    setIsLoading,
    setErrorReason,
    setSubscriptionObj,
    setCustomizeField
  )

const join = async (
  history: History,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  team: ITeam,
  invite: IInvite,
  inviteId: string,
  joinData: ILogin | IAdminLogin,
  anyJoinData: ILoginByJoinForUser | null,
  customizeFields: ICustomizeFields[] | null | undefined,
  authType: AuthType,
  isAdmin: boolean,
  agreement: boolean,
  videoPurchaseLead: IVideoPurchaseLead | null
) => {
  setIsLoading(true)

  try {
    validateForm(
      team,
      invite,
      authType,
      joinData,
      anyJoinData,
      customizeFields,
      isAdmin,
      agreement
    )

    let userCredential: firebase.auth.UserCredential | undefined
    let customUid = ''
    switch (authType) {
      case AuthType.EMAIL:
        try {
          userCredential = await auth.createUserWithEmailAndPassword(
            joinData.email,
            joinData.password
          )
        } catch (e: any) {
          if (e.code === 'auth/weak-password') {
            throw new Error(i18nValidation('firebaseError.weakPassword'))
          }
          userCredential = await auth.signInWithEmailAndPassword(
            joinData.email,
            joinData.password
          )
        }
        break
      case AuthType.GOOGLE:
        userCredential = await auth.signInWithPopup(
          new firebase.auth.GoogleAuthProvider()
        )
        break
      case AuthType.SAML:
        userCredential = await auth.signInWithPopup(
          new firebase.auth.SAMLAuthProvider(`saml.${team!.saml_provider_id}`)
        )
        break
      case AuthType.CUSTOM:
        const { custom, password } = joinData as ILogin
        userCredential = await auth.signInWithCustomToken(
          await getCustomToken(`${team.id}_${custom}`, password, false)
        )
        customUid = custom
        break
      default:
        break
    }
    if (!userCredential || userCredential.user === null) {
      throw new Error(i18nAlert('created.fail.account'))
    }
    const teamUser = await findTeamUserById(userCredential.user.uid)
    teamUser?.forEach((tu) => {
      if (tu.team.id === team.id) {
        throw new Error(i18nAlert('alreadyBelongToTeam'))
      }
      if (tu.user.admin && isAdmin) {
        throw new Error(i18nAlert('alreadyBelongToAnotherTeamAdmin'))
      }
    })

    const { uid, email } = userCredential.user
    const user: IUser = {
      id: uid,
      origin_id: isAdmin || isAuthMethodEmail(team) ? uid : customUid,
      disabled: false,
      name: isAdmin ? (joinData as IAdminLogin).name : anyJoinData?.name ?? '',
      phone: anyJoinData?.phone ?? '',
      admin: isAdmin,
      email: email ?? '',
      customize_fields: customizeFields,
      bookmarks: [],
      invited_id: inviteId,
      is_approved: isAdmin ? 'undefined' : invite.approval,
      is_password_changeable: false,
      customer_id: null,
      group_ids: invite.join_group_ids,
      recent_ip: '',
      admin_notification_setting: {
        encode_completed: true,
        user_first_subscription_payment: false,
        user_payment_completed: false,
        user_registration_completed: false,
        user_video_commented: false,
        user_video_enquete_answered: false,
        user_subscription_removed: false,
        user_account_deleted: false,
      },
      notification_setting: { news: true },
      zoom_user_id: null,
      expire:
        invite.effective_days !== 0
          ? unix2Timestamp(addedUnixTime(invite.effective_days, 'd'))
          : invite.effective_period !== 0
          ? unix2Timestamp(invite.effective_period)
          : firebase.firestore.Timestamp.fromMillis(0),
      created_at: nowTimestamp(),
      updated_at: nowTimestamp(),
    }
    await store(team, user)
    if (!invite.approval) await consumeCount(team, inviteId)

    if (!isAdmin) {
      // 管理者にメール送信
      await sendToAdministrators(
        team.id,
        IAdministratorsNotifyType.Registered,
        email!,
        user.name ?? '',
        user.phone ?? '',
        user.email
      )
    }
    if (!isAdmin && authType !== AuthType.CUSTOM) {
      const mailTemplate = team.mail_templates.find(
        ({ event_name }) => event_name === IMailTemplateEventName.WELCOME
      )
      // 自動メール有効の場合、登録ユーザにメール送信
      if (mailTemplate && mailTemplate.status) {
        const loginUrl = makeLoginTeamUrl(team.id)
        const { email_title, email_content } = mailTemplate
        const mailContent = email_content
          .replace('{{user_name}}', user.name || user.email)
          .replace('{{site_name}}', team.name || '')
          .replace('{{login_raw_url}}', loginUrl)
          .replace('{{login_url}}', `<a href="${loginUrl}">${loginUrl}</a>`)
        await send(
          team.id,
          EmailAction.SENT,
          IMailTemplateEventName.WELCOME,
          undefined,
          null,
          email!,
          { name: team.name, email: team.email! },
          email_title,
          mailContent,
          mailContent
        )
      }
    }

    // カタログ画面からの遷移且つ、チームが存在且つ、チームがStripe連携している且つ、認証できているか
    const { account_id } = team.stripe
    if (
      videoPurchaseLead &&
      'price' in videoPurchaseLead &&
      team &&
      account_id
    ) {
      // 商品情報の存在確認
      const productInfos = await fetchProductInfoByPriceIds(account_id, [
        videoPurchaseLead.price!,
      ])
      if ('0' in productInfos) {
        const {
          id,
          tax_rate,
          type,
          currency,
          valid_days,
          trial,
          unit_amount_wit_tax,
        } = productInfos[0]
        if (!videoPurchaseLead.isInvoicePayment) {
          const redirectURL = makePath2Url(videoPurchaseLead.redirectPath)
          return await redirectToCheckout(
            account_id,
            id,
            tax_rate.id,
            type,
            undefined,
            email!,
            uid,
            videoPurchaseLead.couponId,
            redirectURL,
            redirectURL,
            valid_days,
            trial
          )
        }

        const invoicePayment: IInvoicePayment = {
          name: user.name ?? '',
          email: user.email,
        }
        await createInvoicePayment(
          account_id,
          false,
          team.id,
          null,
          user!,
          invoicePayment,
          id,
          currency,
          unit_amount_wit_tax,
          undefined,
          tax_rate.id,
          type,
          valid_days,
          !trial ? undefined : addedUnixTime(trial, 'd')
        )
      }
    }
    if (!isAdmin) setTeamId(team.id)
    if (videoPurchaseLead && 'redirectPath' in videoPurchaseLead) {
      history.push(videoPurchaseLead.redirectPath)
      return
    }
    history.push(isAdmin ? Routes.Admin : makeUserRootPath(team.id))
  } catch (error) {
    console.log(error)
    const message = checkFirebaseError(error)
    alertService.show(false, message)
  }

  setIsLoading(false)
}

/**
 * 管理者としてチームに加入する
 */
export const joinAdministrator = async (
  history: History,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  team: ITeam,
  invite: IInvite,
  inviteId: string,
  joinData: IAdminLogin,
  authType: AuthType
) =>
  join(
    history,
    setIsLoading,
    team,
    invite,
    inviteId,
    joinData,
    null,
    null,
    authType,
    true,
    true,
    null
  )

/**
 * 会員としてチームに加入する
 */
export const joinUser = async (
  history: History,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  team: ITeam,
  invite: IInvite,
  inviteId: string,
  joinData: ILogin,
  anyJoinData: ILoginByJoinForUser,
  customizeFields: ICustomizeFields[] | null | undefined,
  authType: AuthType,
  agreement: boolean,
  videoPurchaseLead: IVideoPurchaseLead | null
) =>
  join(
    history,
    setIsLoading,
    team,
    invite,
    inviteId,
    joinData,
    anyJoinData,
    customizeFields,
    authType,
    false,
    agreement,
    videoPurchaseLead
  )
