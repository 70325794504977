import { CurrencyType, PriceIntervalType, Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IPrice } from 'common/interfaces/stripe/price'
import { IProduct } from 'common/interfaces/stripe/product'
import { ITeam } from 'common/interfaces/team'
import { isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedPrices } from 'providers/AuthProvider'
import { savePrice } from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/price_form'

/**
 * `IPrice`の初期値設定
 */
export const initPriceForm = (product: IProduct, team: ITeam): IPrice => ({
  id: '',
  name: '',
  nickname: '',
  description: '',
  recurring: isSubscriptionPrice(product, team)
    ? { interval: PriceIntervalType.MONTH }
    : null,
  unit_amount: 0,
  created: 0,
  active: true,
  currency: product.metadata.currency ?? CurrencyType.JPY,
  metadata: { valid_days: 0, trial: 0 },
  product: '',
})

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  UNIT_AMOUNT,
  NICKNAME,
  ACTIVE,
}

/**
 * `onChangeMetadataInput`で使用する入力タイプ
 */
export enum InputMetadataType {
  DESCRIPTION,
  VALID_DAYS,
  TRIAL,
}

/**
 * 一括か継続の料金タイプなのか判定する
 *
 * @param product `IProduct`
 * @param team `ITeam`
 * @returns boolean  one_time or subscription
 */
export const isSubscriptionPrice = (
  { id }: IProduct,
  { stripe: { subscription_product_id } }: ITeam
): boolean =>
  subscription_product_id === '' ? false : subscription_product_id === id

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  price: IPrice,
  setPrice: React.Dispatch<React.SetStateAction<IPrice>>,
  type: InputType,
  { target: { value, checked } }: React.ChangeEvent<HTMLInputElement>
) =>
  setPrice({
    ...price,
    unit_amount:
      type === InputType.UNIT_AMOUNT ? Number(value) : price.unit_amount,
    nickname: type === InputType.NICKNAME ? value : price.nickname,
    active: type === InputType.ACTIVE ? checked : price.active,
  })

/**
 * ラジオボタンの`onChange`イベントハンドラ
 */
export const onChangeRecurringInput = (
  price: IPrice,
  setPrice: React.Dispatch<React.SetStateAction<IPrice>>,
  isRecurring: boolean,
  { target: { checked } }: React.ChangeEvent<HTMLInputElement>
) =>
  setPrice({
    ...price,
    recurring:
      isRecurring && checked ? { interval: PriceIntervalType.MONTH } : null,
  })

/**
 * interval専用の`onChange`イベントハンドラ
 */
export const onChangeIntervalInput = (
  price: IPrice,
  setPrice: React.Dispatch<React.SetStateAction<IPrice>>,
  { target: { value } }: React.ChangeEvent<HTMLInputElement>
) =>
  setPrice({
    ...price,
    recurring: { ...price.recurring, interval: value as PriceIntervalType },
  })

/**
 * trial専用の`onChange`イベントハンドラ
 */
export const onChangeMetadataInput = (
  price: IPrice,
  setPrice: React.Dispatch<React.SetStateAction<IPrice>>,
  type: InputMetadataType,
  { target: { value } }: React.ChangeEvent<HTMLInputElement>
) => {
  const num = Number(value)
  setPrice({
    ...price,
    metadata: {
      ...price.metadata,
      description:
        type === InputMetadataType.DESCRIPTION
          ? value
          : price.metadata.description ?? '',
      valid_days:
        type === InputMetadataType.VALID_DAYS
          ? num
          : price.metadata.valid_days ?? 0,
      trial: type === InputMetadataType.TRIAL ? num : price.metadata.trial ?? 0,
    },
  })
}

/**
 * Stripeの料金オブジェクト作成処理
 */
export const saveStripePrice = async (
  { push }: History,
  product: IProduct,
  price: IPrice,
  storeCache: IStoreCache,
  isCreate: boolean
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    const { team, videos } = storeCache
    validateForm(price, videos, isCreate)

    if (isCreate && price.currency === CurrencyType.USD) {
      price.unit_amount *= 100
    }
    await savePrice(
      team!.stripe.account_id,
      product.id,
      price,
      price.recurring?.interval ?? null
    )
    await reloadCachedPrices(storeCache)

    push(Routes.AdminStripePrice, { product })
    alertService.show(true, i18nAlert('saved.stripe.price'))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
