import { IEvent } from 'common/interfaces/event'
import { ITeam } from 'common/interfaces/team'
import { storageMetadata } from 'common/utils'
import { TeamsDirRef } from './firebase_team'

const DirRef = ({ id }: ITeam) => TeamsDirRef.child(id).child('events')

/**
 * upload event image blob
 * @throws Firebase error
 */
export const upload = async (
  team: ITeam,
  { id }: IEvent,
  blob: Blob | undefined
): Promise<string | null> => {
  if (blob) {
    const uploadTask = await DirRef(team).child(id).put(blob, storageMetadata())
    return uploadTask.ref.getDownloadURL()
  }
  return null
}

/**
 * delete event image file
 * @throws Firebase error
 */
export const removeImageFile = async (
  team: ITeam,
  { id }: IEvent
): Promise<void> => {
  await DirRef(team).child(id).delete()
}
