import { AuthMethodType } from 'common/enums'
import { makeUserRootPath } from 'common/link_path'
import AccentButton from 'components/atoms/Button/Block/AccentButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from 'reactstrap'
import {
  IChangePassword,
  InputType,
  initChangeInput,
  onChangeInput as serviceOnChangeInput,
  updatePassword,
} from 'services/change_password'

const FormBody: React.FC = () => {
  const { t } = useTranslation('userSetting')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [changePassword, setChangePassword] = useState<IChangePassword>(
    initChangeInput()
  )

  if (
    !storeCache.user?.is_password_changeable ||
    storeCache.team?.auth_method !== AuthMethodType.CUSTOM
  ) {
    history.replace(makeUserRootPath(storeCache.team?.id))
    return null
  }

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(type, e, changePassword, setChangePassword)

  const update = () => updatePassword(storeCache, history, changePassword)

  return (
    <div className="main-content">
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        onChange={(e) => onChangeInput(InputType.PASS, e)}
                        placeholder={t('authFormRow.passwordForm.new')}
                        type="password"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        onChange={(e) =>
                          onChangeInput(InputType.PASS_CONFIRM, e)
                        }
                        placeholder={t('authFormRow.passwordForm.confirm')}
                        type="password"
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
                <div className="text-center">
                  <AccentButton
                    onClick={update}
                    className="my-4"
                    content={t('authFormRow.settingPassword')}
                    colors={storeCache.team?.colors}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FormBody
