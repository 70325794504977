import { findCachedGroupsNonNull } from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { EffectiveDaysStatus, IFormInvite } from 'common/interfaces/invite'
import {
  formatDateToUNIX,
  nowTimeFormat,
  nowTimestamp,
  tomorrowDatetimeLocalFormat,
} from 'common/times'
import { createGroupOptions } from 'common/utils'
import { i18nAdminInvite } from 'i18n/i18n'
import { v4 as uuidv4 } from 'uuid'

/**
 * 期限の初期値を返却する
 */
export const defaultExpireDatetime = () => tomorrowDatetimeLocalFormat()

/**
 * 招待のデフォルトグループを返却する
 */
export const getInviteDefaultGroups = (
  storeCache: IStoreCache,
  { join_group_ids }: IFormInvite
) => ({
  defaultGroups: findCachedGroupsNonNull(storeCache, join_group_ids).map(
    ({ id, name }) => ({ value: id, label: name })
  ),
})

/**
 * `IFormInvite`の初期値を返却
 */
export const initInvite = (): IFormInvite => {
  const name = nowTimeFormat(
    i18nAdminInvite('inviteForm.initInvite.inviteDateFormat')
  )
  const defaultDatetime = defaultExpireDatetime()
  return {
    id: uuidv4(),
    admin: false,
    name,
    count: 1,
    count_infinity: true,
    expire_datetime: defaultDatetime,
    expire_infinity: true,
    effective_days: 1,
    effective_days_status: EffectiveDaysStatus.INFINITY,
    effective_period: 0,
    join_group_ids: [],
    approval: false,
    activate_name: false,
    activate_phone: false,
    activate_customize_fields: false,
    comment: '',
    price_ids: [],
    created_at: nowTimestamp(),
    updated_at: nowTimestamp(),
  }
}

/**
 * Input/Selectで使用するoptionsを返却する
 */
export const getFormOptions = (storeCache: IStoreCache) => {
  return { groupOptions: createGroupOptions(storeCache.groups) }
}

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
  COUNT,
  EXPIRE_DATETIME,
  EFFECTIVE_DAYS,
  EFFECTIVE_DAYS_PERIOD,
  JOIN_GROUP_IDS,
  APPROVAL,
  ACTIVATE_NAME,
  ACTIVATE_PHONE,
  ACTIVATE_CUSTOMIZE_FIELDS,
  COMMENT,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  invite: IFormInvite,
  setInvite: React.Dispatch<React.SetStateAction<IFormInvite>>,
  type: InputType,
  e: any
) => {
  const val = e.target?.value
  const checked = e.target?.checked
  setInvite({
    ...invite,
    name: type === InputType.NAME ? val : invite.name,
    count: type === InputType.COUNT ? Number(val) : invite.count,
    expire_datetime:
      type === InputType.EXPIRE_DATETIME ? val : invite.expire_datetime,
    effective_days:
      type === InputType.EFFECTIVE_DAYS ? Number(val) : invite.effective_days,
    effective_period:
      type === InputType.EFFECTIVE_DAYS_PERIOD
        ? formatDateToUNIX(val)
        : invite.effective_period,
    join_group_ids:
      type === InputType.JOIN_GROUP_IDS
        ? e.map((x: any) => x.value)
        : invite.join_group_ids,
    approval: type === InputType.APPROVAL ? checked : invite.approval,
    activate_name:
      type === InputType.ACTIVATE_NAME ? checked : invite.activate_name,
    activate_phone:
      type === InputType.ACTIVATE_PHONE ? checked : invite.activate_phone,
    activate_customize_fields:
      type === InputType.ACTIVATE_CUSTOMIZE_FIELDS
        ? checked
        : invite.activate_customize_fields,
    comment: type === InputType.COMMENT ? val : invite.comment,
  })
}

/**
 * 無制限系の`onChange`イベントハンドラ
 */
export const onChangeInfinity = (
  invite: IFormInvite,
  setInvite: React.Dispatch<React.SetStateAction<IFormInvite>>,
  infinity: {
    count?: boolean
    expire?: boolean
    effective_days?: EffectiveDaysStatus
  }
) =>
  setInvite({
    ...invite,
    count_infinity: infinity.count ?? invite.count_infinity,
    expire_infinity: infinity.expire ?? invite.expire_infinity,
    effective_days_status:
      infinity.effective_days ?? invite.effective_days_status,
  })
