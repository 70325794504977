import { IStoreCache } from 'common/interfaces/auth_provider'
import { ILive } from 'common/interfaces/live'
import { isLoggedIn } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedLives } from 'providers/AuthProvider'
import { removeImageFile } from 'repositories/storage/firebase_live'
import { remove } from 'repositories/store/firebase_live'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 配信レコード削除処理
 */
export const removeLive = (live: ILive, storeCache: IStoreCache) => {
  if (!isLoggedIn(storeCache)) return

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      if (live.image) {
        await removeImageFile(storeCache.team!, live)
      }
      await remove(storeCache.team!, live)

      await reloadCachedLives(storeCache)
      alertService.show(true, i18nAlert('deleted.message'))
    } catch (error) {
      console.error(error)
      alertService.show(false, i18nAlert('cantDeleted'))
    }
  })
}
