import axios from 'axios'
import { db } from 'common/firebase'
import { IInvite } from 'common/interfaces/invite'
import { IAnyTeamItem } from 'common/interfaces/register'
import { ITeam, ITeamBasicInfo } from 'common/interfaces/team'
import { i18nAlert } from 'i18n/i18n'

const CF_API_BASE_URL = String(process.env.REACT_APP_FIREBASE_FUNCTIONS_URI)
export const Teams = db.collection('teams')

/**
 * get team
 */
export const findById = async (team: ITeam): Promise<ITeam | null> => {
  try {
    const teamData = (await Teams.doc(team.id).get()).data()
    return teamData ? (teamData as ITeam) : null
  } catch (error) {
    console.log(error)
    return null
  }
}

const findByInviteId = async (
  inviteId: string,
  isAdmin: boolean
): Promise<{ team: ITeam; invite: IInvite } | string> => {
  try {
    const url = `${CF_API_BASE_URL}/join${
      isAdmin ? '/admin/' : '/user/'
    }${inviteId}`
    const res = await axios.get<{
      success: boolean
      reason?: string
      team: ITeam | null
      invite: IInvite | null
    }>(url, {
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 404,
    })
    if (!res.data.success) {
      return res.data.reason ?? i18nAlert('occuredError')
    }

    return { team: res.data.team!, invite: res.data.invite! }
  } catch (error) {
    console.log(error)
    return i18nAlert('occuredError')
  }
}

/**
 * find team from admin invite id
 * @returns `ITeam` or error string
 */
export const findByAdminInviteId = async (inviteId: string) =>
  findByInviteId(inviteId, true)

/**
 * find team from user invite id
 * @returns `ITeam` or error string
 */
export const findByUserInviteId = async (inviteId: string) =>
  findByInviteId(inviteId, false)

/**
 * streaming team data
 */
export const onSnapshot = (
  { id }: ITeam,
  onSnapshot: (team: ITeam) => void
): (() => void) =>
  Teams.doc(id).onSnapshot((snapshot) =>
    // !snapshot.metadata.hasPendingWrites
    //   ? undefined
    //   :
    onSnapshot(snapshot.data() as ITeam)
  )

/**
 * add team
 * @throws Firebase error
 */
export const store = async (team: ITeam): Promise<void> => {
  await Teams.doc(team.id).set(team)
}

/**
 * update team's `ITeamBasicInfo`
 * @throws Firebase error
 */
export const updateBasicInfo = async (
  team: ITeam,
  basicInfo: ITeamBasicInfo
): Promise<void> => {
  await Teams.doc(team.id).update({
    name: basicInfo.name,
    email: basicInfo.email,
    urls: basicInfo.urls,
    company: basicInfo.company,
    tel: basicInfo.tel,
    hide_footer_logo: basicInfo.hide_footer_logo,
    social_logins: basicInfo.social_logins,
    is_password_complex: basicInfo.is_password_complex,
    is_comment_active: basicInfo.is_comment_active,
    is_single_login: basicInfo.is_single_login,
    is_site_public: basicInfo.is_site_public,
    auth_method: basicInfo.auth_method,
    enquete_id: basicInfo.enquete_id,
    allow_ips: basicInfo.allow_ips,
    default_contents_orders: basicInfo.default_contents_orders,
    saml_provider_id: basicInfo.saml_provider_id,
    lang: basicInfo.lang,
  })
}

/**
 * update team `IAnyTeamItem`
 * @throws Firebase error
 */
export const updateIAnyTeamItem = async (
  team: ITeam,
  { name, email, company, phone }: IAnyTeamItem
): Promise<void> => {
  await Teams.doc(team.id).update({ name, email, company, tel: phone })
}

/**
 * update `team.customize_field`
 * @throws Firebase error
 */
export const updateCustomizeField = async (
  team: ITeam,
  customizeField: ITeam['customize_field']
): Promise<void> => {
  await Teams.doc(team.id).update({ customize_field: customizeField })
}

/**
 * update `team.mail_templates`
 * @throws Firebase error
 */
export const updateMailTemplates = async (
  team: ITeam,
  mailTemplates: ITeam['mail_templates']
): Promise<void> => {
  await Teams.doc(team.id).update({ mail_templates: mailTemplates })
}

/**
 * update `team.stripe.subscription_product_id`
 * @throws Firebase error
 */
export const updateStripeSubscriptionProductId = async (
  team: ITeam,
  subscriptionProductId: ITeam['stripe']['subscription_product_id']
): Promise<void> => {
  await Teams.doc(team.id).update({
    'stripe.subscription_product_id': subscriptionProductId,
  })
}

/**
 * update `team.colors`
 * @throws Firebase error
 */
export const updateColors = async (
  team: ITeam,
  colors: ITeam['colors']
): Promise<void> => {
  await Teams.doc(team.id).update({ colors })
}

/**
 * update `team.header_item_order`
 * @throws Firebase error
 */
export const updateHeaderItemOrder = async (
  team: ITeam,
  headerItemOrder: ITeam['header_item_order']
): Promise<void> => {
  await Teams.doc(team.id).update({ header_item_order: headerItemOrder })
}

/**
 * update `team.logo`
 * @throws Firebase error
 */
export const updateLogo = async (
  team: ITeam,
  logo: ITeam['logo']
): Promise<void> => {
  await Teams.doc(team.id).update({ logo })
}

/**
 * update `team.top_images`
 * @throws Firebase error
 */
export const updateTopImages = async (
  team: ITeam,
  topImages: ITeam['top_images']
): Promise<void> => {
  await Teams.doc(team.id).update({ top_images: topImages })
}

/**
 * update `team.landing_page`
 * @throws Firebase error
 */
export const updateLandingPage = async (
  team: ITeam,
  landingPage: ITeam['landing_page']
): Promise<void> => {
  await Teams.doc(team.id).update({ landing_page: landingPage })
}

/**
 * update `team.landing_page.banner_image`
 * @throws Firebase error
 */
export const updateLandingPageBannerImage = async (
  team: ITeam,
  bannerImage: ITeam['landing_page']['banner_image']
): Promise<void> => {
  await Teams.doc(team.id).update({ 'landing_page.banner_image': bannerImage })
}

/**
 * update `team.item_order`
 * @throws Firebase error
 */
export const updateItemOrder = async (
  team: ITeam,
  itemOrder: ITeam['item_order']
): Promise<void> => {
  await Teams.doc(team.id).update({ item_order: itemOrder })
}

/**
 * update `team.stripe`
 * @throws Firebase error
 */
export const updateStripe = async (
  team: ITeam,
  stripe: ITeam['stripe']
): Promise<void> => {
  await Teams.doc(team.id).update({ stripe })
}

/**
 * update `team.is_invoice_payment`
 * @throws Firebase error
 */
export const updateIsInvoicePayment = async (
  team: ITeam,
  isInvoicePayment: boolean
): Promise<void> => {
  await Teams.doc(team.id).update({ is_invoice_payment: isInvoicePayment })
}

/**
 * remove team logo
 * @throws Firebase error
 */
export const removeLogo = async (team: ITeam): Promise<void> => {
  await Teams.doc(team.id).update({ logo: null })
}

/**
 * remove team top image
 * @throws Firebase error
 */
export const removeTopImage = async (
  team: ITeam,
  fileId: string
): Promise<void> => {
  await Teams.doc(team.id).update({
    top_images: team.top_images.filter((topImage) => topImage.id !== fileId),
  })
}
