import { Routes, ViewType } from 'common/enums'
import { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { makeLoginTeamPath } from 'common/link_path'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import Footer from 'components/molecules/Footer'
import Body from 'components/organisms/Entry/Body'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const Entry: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [viewType, setViewType] = useState<ViewType>(ViewType.LOADING)

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (isLoading(storeCache)) {
        setViewType(ViewType.LOADING)
      } else if (user === null) {
        setViewType(ViewType.LOGIN)
      } else {
        setViewType(ViewType.VISIBLE)
      }
    })
  }, [storeCache])

  switch (viewType) {
    case ViewType.LOADING:
      return <FullLoadingSpinner />
    case ViewType.LOGIN: {
      const path =
        storeCache.user?.admin || !storeCache.team
          ? Routes.LoginAdmin
          : makeLoginTeamPath(storeCache.team?.id ?? '')
      history.replace(path)
      return null
    }
    default:
      break
  }

  if (storeCache.subscriptionObj) history.replace(Routes.Admin)

  return (
    <>
      <Body />
      <Footer />
    </>
  )
}

const isLoading = (storeCache: IStoreCache) =>
  storeCache.user === null || storeCache.isLoading

export default Entry
