import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { IUserFromCSV } from 'common/interfaces/user'
import { dateFormat } from 'common/times'
import ButtonRight from 'components/atoms/Button/Right'
import FileInfo from 'components/atoms/Dropzone/FileInfo'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import { makePrimaryBadges } from 'components/atoms/Table/ElementsOnTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo, useState } from 'react'
import { DropzoneRootProps, useDropzone } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap'
import { dropzoneStyle } from 'services/admin/filelist_create'
import {
  importUserCSV,
  registerUserCSV,
  sampleUserCsvBlob,
} from 'services/admin/user'

const FormBody: React.FC = () => {
  const { t } = useTranslation('adminUser')
  const { storeCache } = useContext(AuthContext)

  const [importedUsers, setImportedUsers] = useState<IUserFromCSV[]>([])
  const [customHeaders, setCustomHeaders] = useState<string[]>([])
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

  const onDrop = (acceptedFiles: File[]) =>
    acceptedFiles[0] &&
    importUserCSV(
      storeCache.team,
      acceptedFiles[0],
      setImportedUsers,
      setCustomHeaders
    )
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ onDrop })

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files &&
      e.target.files.length > 0 &&
      importUserCSV(
        storeCache.team,
        e.target.files[0],
        setImportedUsers,
        setCustomHeaders
      )
  }

  const uploadFile = useMemo(() => {
    const file: File | undefined = acceptedFiles[0]
    if (file !== undefined) {
      return <FileInfo fileName={file.name} fileSize={file.size} />
    }
  }, [acceptedFiles])

  const { baseStyle, borderNormalStyle, borderDragStyle } = dropzoneStyle
  const style: DropzoneRootProps = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? borderDragStyle : borderNormalStyle),
    }),
    [isDragActive, baseStyle, borderDragStyle, borderNormalStyle]
  )

  const registerUsers = () =>
    registerUserCSV(importedUsers, customHeaders, storeCache, setButtonDisabled)

  return (
    <>
      <ButtonRight nextPage={Routes.AdminUser} content={t('backToList')} />
      <Form>
        <Row>
          <Col className="mb-4" md="6">
            <Trans
              t={t}
              i18nKey="userImport.csv.download"
              components={{
                // eslint-disable-next-line jsx-a11y/heading-has-content
                h5: <h5 />,
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    className="btn btn-dark"
                    href={sampleUserCsvBlob()}
                    download="sample_user.csv"
                  />
                ),
              }}
            />
          </Col>
        </Row>
        <FormGroup row>
          <Col md="6">
            <Label for="fileForm">{t('userImport.csv.file')}</Label>
            <div {...getRootProps({ style })}>
              <input
                {...getInputProps({
                  id: 'fileForm',
                  accept: 'text/csv',
                  multiple: false,
                  onChange,
                })}
              />
              <p>
                <Trans t={t} i18nKey="userImport.csv.dragAndDrop" />
              </p>
            </div>
            {uploadFile}
          </Col>
        </FormGroup>

        {importedUsers.length > 0 && (
          <Table users={importedUsers} customHeaders={customHeaders} />
        )}

        <div className="text-center">
          <Button
            onClick={registerUsers}
            disabled={buttonDisabled || importedUsers.length === 0}
            className="my-4"
            color="primary"
            type="button"
          >
            {t('userImport.register')}
          </Button>
        </div>
      </Form>
    </>
  )
}

const Table: React.FC<{ users: IUserFromCSV[]; customHeaders: string[] }> = ({
  users,
  customHeaders,
}) => {
  const { t } = useTranslation('adminUser')
  const columns = useMemo<ColumnDef<IUserFromCSV>[]>(
    () => [
      { header: 'ID', accessorKey: 'id' },
      { header: t('userImport.table.password'), accessorKey: 'password' },
      { header: t('userImport.table.name'), accessorKey: 'name' },
      {
        header: t('userImport.table.group'),
        accessorFn: (u) => makePrimaryBadges(u.group_ids),
      },
      {
        header: t('userImport.table.expire'),
        accessorFn: (u) =>
          u.expire.seconds === 0
            ? t('userImport.table.nothing')
            : dateFormat(u.expire),
      },
      {
        header: t('userImport.table.is_password_changeable'),
        accessorFn: (u) => (u.is_password_changeable ? 'YES' : 'NO'),
      },
      ...customHeaders.map((h) => ({ header: h, accessorKey: h })),
    ],
    [customHeaders, t]
  )

  return useTanstackTable<IUserFromCSV>(columns, users, {
    fixedLastColumn: true,
  })
}

export default FormBody
