import { PaymentMethodType } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { checkFirebaseError } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedTeam } from 'providers/AuthProvider'
import { updateIsInvoicePayment } from 'repositories/store/firebase_team'
import { alertService } from 'services/alert'

/**
 * update `is_invoice_payment`
 */
export const saveTeamIsInvoicePayment = async (
  storeCache: IStoreCache,
  selectedPaymentMethod: PaymentMethodType
): Promise<void> => {
  const isInvoicePayment =
    selectedPaymentMethod === PaymentMethodType.CUSTOMER_BALANCE
  try {
    await updateIsInvoicePayment(storeCache.team!, isInvoicePayment)
    await reloadCachedTeam(storeCache)

    alertService.show(true, i18nAlert('updated.invoicePayment'))
  } catch (error) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, message)
  }
}
