import { IEnquete } from 'common/interfaces/enquete'
import { ITeam } from 'common/interfaces/team'
import { removeAllAnswersInEnquete } from './firebase_enquete_answer'
import { Teams } from './firebase_team'

const Enquetes = (team: ITeam) => Teams.doc(team.id).collection('enquetes')

/**
 * get all enquetes
 */
export const get = async (team: ITeam): Promise<IEnquete[]> => {
  try {
    const enqueteDatas = await Enquetes(team).get()
    return enqueteDatas.docs.map((enquete) => enquete.data() as IEnquete)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get enquete by id
 */
export const findById = async (
  team: ITeam,
  enqueteId: string
): Promise<IEnquete | null> => {
  try {
    const enqueteData = await Enquetes(team).doc(enqueteId).get()
    return enqueteData.exists ? (enqueteData.data() as IEnquete) : null
  } catch (error) {
    console.log(error)
  }
  return null
}

/**
 * add enquete
 * @throws Firebase error
 */
export const store = async (team: ITeam, enquete: IEnquete): Promise<void> => {
  await Enquetes(team).doc(enquete.id).set(enquete)
}

/**
 * update enquete
 * @throws Firebase error
 */
export const update = async (team: ITeam, enquete: IEnquete): Promise<void> => {
  await Enquetes(team).doc(enquete.id).update(enquete)
}

/**
 * delete enquete
 * @throws Firebase error
 */
export const remove = async (team: ITeam, enquete: IEnquete): Promise<void> => {
  await Enquetes(team).doc(enquete.id).delete()
  await removeAllAnswersInEnquete(team, enquete)
}
