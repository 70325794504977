import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IVideo } from 'common/interfaces/video'

const CF_API_BASE_URL = String(process.env.REACT_APP_FIREBASE_FUNCTIONS_URI)

interface IBunnyTusUploadData {
  endpoint: string
  headers: {
    AuthorizationSignature: string
    AuthorizationExpire: number
    VideoId: string
    LibraryId: number
  }
}

interface IBunnyTusUploadResponse {
  success: boolean
  data: IBunnyTusUploadData | null
}

interface ISuccess {
  success: boolean
}

interface IUploadResult {
  status: number
  upload_offset: number
}

/**
 * Create video item
 * @param teamId operated team id
 * @param userId operated user id
 * @param videoName video name
 * @returns if request successful TusUploadData else null
 */
const create = async (
  teamId: string | undefined,
  userId: string | undefined,
  videoName: string
): Promise<IBunnyTusUploadData | null> => {
  const url = `${CF_API_BASE_URL}/videos`
  const data = {
    operatedTeamId: teamId,
    operatedUserId: userId,
    videoName,
  }

  try {
    const res = await axios.post<IBunnyTusUploadResponse>(url, data)
    if (res.data.success) {
      return res.data.data
    }
  } catch (e) {
    console.error(e)
  }
  return null
}

/**
 * Delete video of bunny
 * @param teamId operated team id
 * @param userId operated user id
 * @param bunnyVideoId video id of bunny video
 * @returns is delete successful
 */
export const remove = async (
  teamId: string | undefined,
  userId: string | undefined,
  bunnyVideoId: string
): Promise<boolean> => {
  const url = `${CF_API_BASE_URL}/videos/${bunnyVideoId}`
  const data = {
    operatedTeamId: teamId,
    operatedUserId: userId,
  }

  try {
    const res = await axios.request<ISuccess>({
      method: 'DELETE',
      url,
      data,
    })
    return res.data.success
  } catch (e) {
    console.error(e)
  }
  return false
}

/**
 * Delete video of bunny from firestore video object
 * @param storeCache `IStoreCache`
 * @param video `IVideo`
 * @returns is delete successful
 */
export const removeFromFirestoreVideo = async (
  storeCache: IStoreCache,
  video: IVideo
): Promise<boolean> => {
  return remove(storeCache.team?.id, storeCache.user?.id, video.bunny.videoId)
}

/**
 * Register video data
 * @param storeCache `IStoreCache`
 * @param videoName video name
 * @returns if request successful TusUploadData else null
 */
export const register = async (
  storeCache: IStoreCache,
  videoName: string
): Promise<IBunnyTusUploadData | null> => {
  return create(storeCache.team?.id, storeCache.user?.id, videoName)
}

/**
 * Upload video data
 * @param videoFile video data file
 * @param videoName video name
 * @param tusUploadData `IBunnyTusUploadData`
 * @param updateProgressEvent `Function(e: AxiosProgressEvent)`
 * @param abortController upload abort controller
 */
export const upload = async (
  videoFile: File,
  videoName: string,
  tusUploadData: IBunnyTusUploadData,
  updateProgressEvent: (e: AxiosProgressEvent) => void,
  abortController: AbortController
): Promise<IUploadResult | null> => {
  const authHeaders = {
    AuthorizationSignature: tusUploadData.headers.AuthorizationSignature,
    AuthorizationExpire: String(tusUploadData.headers.AuthorizationExpire),
    VideoId: tusUploadData.headers.VideoId,
    LibraryId: String(tusUploadData.headers.LibraryId),
  }
  const fileSize = videoFile.size

  let uploadLocation = ''
  try {
    const metadata = `filetype ${videoFile.type},title ${videoName}`
    const base64Metadata = Buffer.from(metadata).toString('base64')
    const config: AxiosRequestConfig = {
      headers: {
        ...authHeaders,
        'Tus-Resumable': '1.0.0',
        'Content-Length': '0',
        'Upload-Length': fileSize,
        'Upload-Metadata': base64Metadata,
      },
    }

    const res = await axios.post(tusUploadData.endpoint, null, config)
    uploadLocation = res.headers.location ?? ''
  } catch (e) {
    console.error(e)
    return null
  }

  try {
    const config: AxiosRequestConfig = {
      headers: {
        ...authHeaders,
        'Tus-Resumable': '1.0.0',
        'Upload-Offset': '0',
        'Content-Length': fileSize,
        'Content-Type': 'application/offset+octet-stream',
      },
      onUploadProgress: updateProgressEvent,
      signal: abortController.signal,
    }

    const baseEndpoint = new URL(tusUploadData.endpoint).origin
    const url = baseEndpoint + uploadLocation
    const res = await axios.patch(url, videoFile, config)

    return {
      status: res.status,
      upload_offset: Number(res.headers['upload-offset']),
    }
  } catch (e) {
    console.error(e)
    return null
  }
}

/**
 * is success upload?
 * @param uploadResult `IUploadResult | null`
 * @param videoFile `File`
 * @returns boolean
 */
export const isUploadSuccess = (
  uploadResult: IUploadResult | null,
  videoFile: File
): boolean => {
  return (
    uploadResult !== null &&
    uploadResult.status >= 200 &&
    uploadResult.status < 300 &&
    uploadResult.upload_offset === videoFile.size
  )
}
