import { AuthType } from 'common/auth_type'
import { Routes } from 'common/enums'
import firebase, { auth } from 'common/firebase'
import { ILogin } from 'common/interfaces/login'
import { ISubscriptionObject } from 'common/interfaces/subscription'
import { IFindTeam } from 'common/interfaces/team'
import { IVideoPurchaseLead } from 'common/interfaces/video'
import { makeLoggedInPath } from 'common/link_path'
import { checkFirebaseError } from 'common/utils'
import { History } from 'history'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import {
  getCustomToken,
  saveUserIp,
} from 'repositories/functions/functions_auth'
import { fetchSubscriptionObj } from 'repositories/functions/functions_stripe'
import { findByNonAuthUser } from 'repositories/functions/functions_team'
import { addLoggedInLog } from 'repositories/store/firebase_log'
import { findTeamUserById } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { setTeamId } from 'services/local_storage'

/**
 * チームIDからチーム情報を取得する
 */
export const findTeam = async (
  teamId: string,
  setTeam: React.Dispatch<React.SetStateAction<IFindTeam | null>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setSubscriptionObj: React.Dispatch<
    React.SetStateAction<ISubscriptionObject | null>
  >,
  { replace }: History
): Promise<void> => {
  if (teamId) {
    const findTeam = await findByNonAuthUser(teamId)
    if (findTeam) {
      const subscriptionObj = await fetchSubscriptionObj(
        undefined,
        findTeam.stripeId
      )
      setSubscriptionObj(subscriptionObj)
      setTeam(findTeam)
    } else {
      replace(Routes.LoginAdmin)
    }
  }
  setIsLoading(false)
}

/**
 * manifest生成とその置き換え
 */
export const replaceManifest = (team: IFindTeam | null) => {
  const loginUrl = window.location.href
  const manifest = {
    name: team?.name || 'OneStream',
    short_name: team?.name || 'OneStream',
    start_url: loginUrl,
    display: 'standalone',
    theme_color: '#000000',
    background_color: '#ffffff',
  }

  const manifestStr = JSON.stringify(manifest)
  const blob = new Blob([manifestStr], { type: 'application/json' })
  const manifestUrl = URL.createObjectURL(blob)
  const manifestEl = document.querySelector('link[rel=manifest]')!
  manifestEl.setAttribute('href', manifestUrl)
}

/**
 * ログインフォーム初期値設定
 */
export const initLogin = () => ({ email: '', password: '', custom: '' })

export enum InputType {
  EMAIL,
  PASS,
  CUSTOM,
}

/**
 * `onChangeInput`で使用する入力タイプ
 */
export const onChangeInput = (
  type: InputType,
  { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  { email, password, custom }: ILogin,
  setData: React.Dispatch<React.SetStateAction<ILogin>>
) =>
  setData({
    email: type === InputType.EMAIL ? value : email,
    password: type === InputType.PASS ? value : password,
    custom: type === InputType.CUSTOM ? value : custom,
  })

/**
 * チームにログインする
 */
export const login = async (
  history: History,
  { email, password, custom }: ILogin,
  authType: AuthType,
  team: IFindTeam | null,
  videoPurchaseLead: IVideoPurchaseLead | null
): Promise<void> => {
  try {
    let userCredential
    switch (authType) {
      case AuthType.EMAIL:
        if (!email || !password) {
          throw new Error(i18nValidation('input.emailAndPassword'))
        }
        userCredential = await auth.signInWithEmailAndPassword(email, password)
        break
      case AuthType.GOOGLE:
        userCredential = await auth.signInWithPopup(
          new firebase.auth.GoogleAuthProvider()
        )
        if (userCredential.additionalUserInfo?.isNewUser) {
          await auth.currentUser?.delete()
          throw new Error(i18nAlert('account.noAccount'))
        }
        break
      case AuthType.SAML:
        userCredential = await auth.signInWithPopup(
          new firebase.auth.SAMLAuthProvider(`saml.${team!.saml_provider_id}`)
        )
        break
      case AuthType.CUSTOM:
        if (!custom || !password) {
          throw new Error(i18nValidation('input.id.andPassword'))
        }
        userCredential = await auth.signInWithCustomToken(
          await getCustomToken(`${team!.id}_${custom}`, password, true)
        )
        break
    }
    await checkLogin(history, userCredential, team)

    const nextPath = (() => {
      if (videoPurchaseLead && 'redirectPath' in videoPurchaseLead) {
        return videoPurchaseLead.redirectPath
      }
      return makeLoggedInPath(team?.id)
    })()
    history.push(nextPath)
  } catch (error: any) {
    console.log(error)

    const msg = (() => {
      if ('response' in error && !error.response.data.token) {
        return i18nAlert('account.disabled.password.noUserId')
      }
      return checkFirebaseError(error)
    })()
    alertService.show(false, msg)
  }
}

/**
 * SAML認証を使いログインする
 */
export const loginSaml = async (
  history: History,
  samlProviderId: string
): Promise<void> => {
  try {
    if (!samlProviderId) throw new Error(i18nValidation('input.samlProviderId'))

    const userCredential = await auth.signInWithPopup(
      new firebase.auth.SAMLAuthProvider(`saml.${samlProviderId}`)
    )
    await checkLogin(history, userCredential)

    history.push(Routes.LoggedInAdmin)
  } catch (error: any) {
    console.log(error)

    const msg = (() => {
      if ('response' in error && !error.response.data.token) {
        return i18nAlert('account.disabled.password.noUserId')
      }
      return checkFirebaseError(error)
    })()
    alertService.show(false, msg)
  }
}

/**
 * 複数チームに所属している場合に認証可能かどうか判定する
 */
const checkLogin = async (
  history: History,
  userCredential: firebase.auth.UserCredential,
  team?: IFindTeam | null
): Promise<void> => {
  if (!userCredential?.user) return

  const { uid } = userCredential.user
  const teamUsers = await findTeamUserById(uid)

  if (team) {
    const isTeamAdmin = teamUsers?.some(
      (obj) => obj.user.admin && obj.team.id === team.id
    )
    if (isTeamAdmin) {
      await auth.signOut()
      history.replace(Routes.LoginAdmin)
      throw Error(i18nAlert('account.adminAccountThisPageLogin'))
    }
    const target = teamUsers?.find((obj) => obj.team.id === team.id)
    if (target) {
      if (!target.user.admin) {
        await saveUserIp(target.team.id, uid)
        setTeamId(target.team.id)
      }
      addLoggedInLog(target.team, target.user)
    }
  }
}
