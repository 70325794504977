import FooterButtons from 'components/atoms/Modal/FooterButtons'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Col, Form, FormGroup, Input, Label, Modal, Row } from 'reactstrap'
import { InputType } from 'reactstrap/es/Input'
import {
  NewUserType,
  TNewUser,
  initNewUser,
  onChangeCreateUser,
  createUser as serviceCreateUser,
} from 'services/admin/user'
import './createusermodal.scss'

interface ICreateUserModalProps {
  isOpen: boolean
  toggleModal: () => void
}

const CreateUserModal: React.FC<ICreateUserModalProps> = ({
  isOpen,
  toggleModal,
}) => {
  const { t } = useTranslation('adminUser')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [newUser, setNewUser] = useState<TNewUser>(initNewUser())

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: NewUserType
  ) => onChangeCreateUser(e, type, newUser, setNewUser)

  const createUser = () =>
    serviceCreateUser(storeCache.team!.id, history, newUser, toggleModal)

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggleModal}
    >
      <div className="modal-header">
        <h5 className="modal-title">
          {t('list.modal.createUser.newRegister')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggleModal}
        >
          <span aria-hidden>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <InputForm
            label="ID"
            type="text"
            onChange={(e) => onChange(e, NewUserType.ID)}
          />
          <InputForm
            label={t('list.modal.createUser.password')}
            type="password"
            onChange={(e) => onChange(e, NewUserType.PASS)}
          />
          <FormGroup>
            <Label for="isPasswordChangeable">
              {t('list.modal.createUser.is_password_changeable')}
            </Label>
            <Row>
              <Col md="9">
                <label className="custom-toggle" id="isPasswordChangeable">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      onChange(e, NewUserType.IS_PASSWORD_CHANGEABLE)
                    }
                    checked={newUser.is_password_changeable}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
            </Row>
          </FormGroup>
          <FooterButtons
            leftContent={t('list.modal.buttons.register')}
            rightContent={t('list.modal.buttons.close')}
            leftOnClick={createUser}
            rightOnClick={toggleModal}
          />
        </Form>
      </div>
    </Modal>
  )
}

const InputForm: React.FC<{
  label: string
  type: InputType
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ label, type, onChange }) => (
  <FormGroup row>
    <Col md="10">
      <Label>{label}</Label>
      <Input
        autoComplete={type === 'password' ? 'new-password' : 'off'}
        type={type}
        onChange={onChange}
      />
    </Col>
  </FormGroup>
)

export default CreateUserModal
