import classNames from 'classnames'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavItem, NavLink } from 'reactstrap'
import { signOut } from 'services/admin/account'
import './itemlink.scss'

export enum ItemActionType {
  LOGOUT,
}

interface IItemAction {
  action: ItemActionType
  iconName: string
  team: ITeam
  user: IUser
}

const ItemAction: React.FC<IItemAction> = (props: IItemAction) => {
  return (
    <NavItem>
      <NavLink
        onClick={() => doAction(props.action, props.team, props.user)}
        style={{ cursor: 'pointer' }}
      >
        <i className={classNames('nav-icon', props.iconName)} />
        <Content action={props.action} />
      </NavLink>
    </NavItem>
  )
}

const Content: React.FC<{ action: ItemActionType }> = ({ action }) => {
  const { t } = useTranslation('common')
  if (action === ItemActionType.LOGOUT) {
    return <>{t('logout')}</>
  }
  return null
}

const doAction = (action: ItemActionType, team: ITeam, user: IUser) => {
  if (action === ItemActionType.LOGOUT) {
    signOut(team, user)
  }
}

export default ItemAction
