import { formatCSVForInviteMailCSV } from 'common/csv'
import { EmailAction, Routes } from 'common/enums'
import { IInviteMail, IUserAndEmail } from 'common/interfaces/admin/invite_mail'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IInvite } from 'common/interfaces/invite'
import { ITeam } from 'common/interfaces/team'
import {
  makeJoinAdministratorUrl,
  makeJoinUserUrl,
  makeLoginTeamUrl,
} from 'common/link_url'
import { isLoggedIn } from 'common/utils'
import { History } from 'history'
import {
  i18nAdminCommon,
  i18nAdminInvite,
  i18nAlert,
  i18nMailTemplate,
  i18nValidation,
} from 'i18n/i18n'
import { send, sendToMultiple } from 'repositories/functions/functions_mail'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'
import { createCsvBlobOnlyDataAndNoneDoubleQuote } from './csv_create'

/**
 * `IInviteMail`初期値設定
 */
export const initInviteMail = (
  admin: boolean,
  { name, email }: ITeam
): IInviteMail => {
  return {
    name: '',
    email: '',
    import_csv: [],
    title: admin
      ? i18nMailTemplate('inviteMailTemplate.title.adminInvite', {
          teamEmail: email,
        })
      : i18nMailTemplate('inviteMailTemplate.title.userInvite', {
          adminName: name,
        }),
    content: admin
      ? i18nMailTemplate('inviteMailTemplate.content.adminContent')
      : i18nMailTemplate('inviteMailTemplate.content.userContent'),
  }
}

/**
 * `onChangeInput`入力タイプ
 */
export enum InputType {
  NAME,
  EMAIL,
  UPLOAD_CSV,
  TITLE,
  CONTENT,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  inviteMail: IInviteMail,
  setInviteMail: React.Dispatch<React.SetStateAction<IInviteMail>>,
  type: InputType,
  e: any
) => {
  if (type === InputType.UPLOAD_CSV) {
    const fileReader = new FileReader()
    fileReader.readAsBinaryString(e.target?.files[0])
    fileReader.onload = () => {
      const import_csv: IUserAndEmail[] = formatCSVForInviteMailCSV(fileReader)
      setInviteMail({
        ...inviteMail,
        import_csv,
      })
    }
  }
  const { name, email, title, content } = inviteMail
  const val = e.target?.value
  setInviteMail({
    ...inviteMail,
    name: type === InputType.NAME ? val : name,
    email: type === InputType.EMAIL ? val : email,
    title: type === InputType.TITLE ? val : title,
    content: type === InputType.CONTENT ? e : content,
  })
}

/**
 * サンプルCSV生成
 */
export const generateSampleCSV = (): void => {
  const i18n = i18nAdminInvite('inviteMail.generateSampleCSV', {
    returnObjects: true,
  })
  const sampleData = [
    [i18n.satou, 'satou@demo.com'],
    [i18n.tanaka, 'tanaka@demo.com'],
    [i18n.yamada, 'yamada@demo.com'],
  ]
  const csvUrl = createCsvBlobOnlyDataAndNoneDoubleQuote(sampleData)
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.download = 'mail_sample.csv'
  a.href = csvUrl
  a.click()
  a.remove()
  URL.revokeObjectURL(csvUrl)
}

/**
 * 招待メール送信
 */
export const sendInviteMail = (
  history: History,
  inviteMail: IInviteMail,
  invite: IInvite,
  storeCache: IStoreCache
) => {
  if (!isLoggedIn(storeCache)) return

  try {
    const { title, content, name, email, import_csv } = inviteMail
    if (!title) throw new Error(i18nValidation('input.mail.title'))
    if (!content) throw new Error(i18nValidation('input.mail.content'))

    const isNotImportCSV: boolean = import_csv.length === 0
    if (isNotImportCSV) {
      if (!name) throw new Error(i18nValidation('input.name'))
      if (!email) throw new Error(i18nValidation('input.mail.address'))
    }

    const destination: string = isNotImportCSV
      ? email
      : i18nAdminCommon('severalPeople')
    modalService.show(
      i18nAlert('modal.confirm.sendMail', { destination }),
      async () => {
        try {
          const team = storeCache.team!
          const inviteUrl = invite.admin
            ? makeJoinAdministratorUrl(invite.id)
            : makeJoinUserUrl(invite.id)
          const loginUrl = makeLoginTeamUrl(team.id)
          const mailContent = content
            .replace('{{user_name}}', isNotImportCSV ? name : `{{user_name}}`)
            .replace('{{site_name}}', team.name)
            .replace('{{invite_raw_url}}', inviteUrl)
            .replace(
              '{{invite_url}}',
              `<a href="${inviteUrl}">${inviteUrl}</a>`
            )
            .replace('{{login_raw_url}}', loginUrl)
            .replace('{{login_url}}', `<a href="${loginUrl}">${loginUrl}</a>`)
          if (isNotImportCSV) {
            // CSVインポートなし
            await send(
              team.id,
              EmailAction.INVITATION,
              invite.id,
              name,
              { name: team.name, email: team.email! },
              email,
              { name: team.name, email: team.email! },
              title,
              mailContent,
              mailContent
            )
          } else {
            // CSVインポートあり
            await sendToMultiple(
              team.id,
              EmailAction.BULK_INVITATION,
              invite.id,
              { name: team.name, email: team.email! },
              { name: team.name, email: team.email! },
              import_csv,
              title,
              mailContent
            )
          }
          history.replace(Routes.AdminInvite)
          alertService.show(
            true,
            i18nAlert('invite.mail.sent', { destination })
          )
        } catch (error) {
          console.log(error)
          alertService.show(false, i18nAlert('invite.mail.failedSend'))
        }
      }
    )
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
