import { Routes } from 'common/enums'
import { ISendEmail } from 'common/interfaces/send_email'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/SendEmailForm/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initSendEmail } from 'services/admin/send_email_form'

const SendEmailForm: React.FC = () => {
  const { t } = useTranslation('adminSendEmail')
  const history = useHistory()
  const { pathname, state } = useLocation<{ sendEmail: ISendEmail }>()
  const isCreate = pathname === Routes.AdminSendEmailCreate
  const sendEmail: ISendEmail = isCreate ? initSendEmail() : state.sendEmail

  return (
    <>
      <H1 title={t(isCreate ? 'form.new' : 'form.edit')} />

      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminSendEmail}
          content={t('form.back')}
        />
        <FormBody
          isCreate={isCreate}
          sendEmailObj={sendEmail}
          history={history}
        />
      </Container>
    </>
  )
}

export default SendEmailForm
