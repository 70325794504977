import { auth } from 'common/firebase'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { i18nAlert } from 'i18n/i18n'
import { addLoggedOutLog } from 'repositories/store/firebase_log'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * logout firebase
 */
export const signOut = (team: ITeam, user: IUser) => {
  modalService.show(i18nAlert('modal.confirm.logout'), async () => {
    try {
      await addLoggedOutLog(team, user)
      await auth.signOut()
      alertService.show(true, i18nAlert('auth.loggedout'))
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('auth.cantLoggedout'))
    }
  })
}
