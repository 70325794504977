import { IEnquete } from 'common/interfaces/enquete'
import { IEnqueteAnswerField } from 'common/interfaces/enquete_answer'
import { IEnqueteCustomize } from 'common/interfaces/enquete_customize'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import { questionElement } from 'components/atoms/Question/Question'
import Footer from 'components/molecules/Footer'
import { i18nJoin } from 'i18n/i18n'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap'
import {
  getEnqueteById,
  initEnqueteField,
  onChangeInputForEnquete as serviceOnChangeInputForEnquete,
} from 'services/user/enquete'
import { saveEnqueteAnswerForUser } from 'services/user/enquete_answer'

const AnswerToEnquete: React.FC = () => {
  const { storeCache } = useContext(AuthContext)

  const [enquete, setEnquete] = useState<IEnquete | null>(null)
  const [enqueteField, setEnqueteField] = useState<IEnqueteAnswerField[]>([])

  const { isLoading, team, user } = storeCache

  useEffect(() => {
    ;(async () => {
      if (team && team.enquete_id) {
        await getEnqueteById(storeCache, team.enquete_id, setEnquete)
      }
    })()
  }, [storeCache, team])

  useMemo(() => {
    if (!enquete || !enquete.questions) return

    setEnqueteField(initEnqueteField(enquete))
  }, [enquete])

  if (!team || !user || isLoading || !enquete) return <FullLoadingSpinner />

  const onChangeInputForEnquete = (
    e: React.ChangeEvent<HTMLInputElement>,
    enquete: IEnqueteCustomize,
    index: number
  ) =>
    serviceOnChangeInputForEnquete(e, enquete, index, {
      enqueteField,
      setEnqueteField,
    })

  const onAnswer = () =>
    saveEnqueteAnswerForUser(storeCache, enqueteField, enquete.questions)

  return (
    <div className="main-content">
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-5">
                  <strong>{enquete.title}</strong>
                </div>
                <Form>
                  {questionElement(enquete, onChangeInputForEnquete)}
                  <div className="text-center">
                    <Button className="enquete-btn" onClick={onAnswer}>
                      {i18nJoin('answerToEnquete')}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer team={team} />
    </div>
  )
}

export default AnswerToEnquete
