import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { ICategory } from 'common/interfaces/category'
import ButtonRight from 'components/atoms/Button/Right'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeEditDeleteOperationButtons,
  makeThumbnailElement,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { getCategoryUseCount, removeCategory } from 'services/admin/category'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminCategory')
  const { categories } = useContext(AuthContext).storeCache

  if (categories.length <= 0) {
    return <RegisterItemButton type="category" />
  }
  return (
    <>
      <ButtonRight
        nextPage={Routes.AdminCategoryCreate}
        content={t('list.newRegister')}
      />
      <Table />
    </>
  )
}

const Table: React.FC = () => {
  const { t } = useTranslation('adminCategory')
  const { storeCache } = useContext(AuthContext)
  const { categories } = storeCache
  const history = useHistory()

  const columns = useMemo<ColumnDef<ICategory>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => categories.length - i,
      },
      {
        header: t('list.table.name'),
        accessorKey: 'name',
      },
      {
        header: t('list.table.thumbnail'),
        accessorFn: (c) => makeThumbnailElement(c.image, c.name, 'category'),
      },
      {
        header: t('list.table.sortNumber'),
        accessorFn: (c) => (c.sort_num === null ? '' : c.sort_num),
        enableSorting: true,
      },
      {
        header: t('list.table.usingVideoCount.title'),
        accessorFn: (c) =>
          t('list.table.usingVideoCount.count', {
            categoryUseCount: getCategoryUseCount(c, storeCache),
          }),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (c) =>
          makeEditDeleteOperationButtons<ICategory>(
            history,
            'category',
            Routes.AdminCategoryEdit,
            (e) => removeCategory(e, storeCache),
            c
          ),
      },
    ],
    [storeCache, history, categories.length, t]
  )

  const data = categories.sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  )
  return useTanstackTable<ICategory>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
