import H1 from 'components/atoms/Head/H1'
import TableBody from 'components/organisms/Admin/AnalyticsUsers/TableBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'

const AnalyticsUsers: React.FC = () => {
  const { t } = useTranslation('adminAnalytics')
  return (
    <>
      <H1 title={t('users.title')} />

      <Container fluid>
        <TableBody />
      </Container>
    </>
  )
}

export default AnalyticsUsers
