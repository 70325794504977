import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IGroup } from 'common/interfaces/group'
import { nowTimestamp } from 'common/times'
import { checkFirebaseError, isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nValidation } from 'i18n/i18n'
import { reloadCachedGroups } from 'providers/AuthProvider'
import { store, update } from 'repositories/store/firebase_group'
import { alertService } from 'services/alert'
import { v4 as uuidv4 } from 'uuid'

/**
 * `IGroup`の初期値を返却する
 */
export const initGroup = (): IGroup => {
  return {
    id: uuidv4(),
    name: '',
    created_at: nowTimestamp(),
    updated_at: nowTimestamp(),
  }
}

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  group: IGroup,
  setGroup: React.Dispatch<React.SetStateAction<IGroup>>,
  type: InputType,
  e: React.ChangeEvent<HTMLInputElement>
) => {
  setGroup({
    ...group,
    name: type === InputType.NAME ? e.target.value : group.name,
  })
}

/**
 * グループレコード作成/更新処理
 */
export const saveGroup = async (
  history: History,
  group: IGroup,
  storeCache: IStoreCache,
  isCreate: boolean
) => {
  if (!isLoggedIn(storeCache)) return

  try {
    if (!group.name) {
      throw new Error(i18nValidation('input.groupName'))
    }

    if (isCreate) {
      group.created_at = nowTimestamp()
      group.updated_at = nowTimestamp()
      await store(storeCache.team!, group)
    } else {
      await update(storeCache.team!, group)
    }
    await reloadCachedGroups(storeCache)

    history.push(Routes.AdminGroup)
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}
