import { PaymentMethodType } from 'common/enums'
import { IStripe } from 'common/interfaces/stripe'
import { makeAdminItemSelectPath } from 'common/link_path'
import {
  getAllFilelistSize,
  getAllVideoSize,
  getFileSize,
  getFilelistUploadLimit,
  getVideoUploadLimit,
  isCustomerBalance,
} from 'common/utils'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormProps,
  Input,
  Label,
  Progress,
  Row,
} from 'reactstrap'
import { saveTeamIsInvoicePayment } from 'services/admin/contracting_plan'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'

const Body: React.FC = () => {
  const { t } = useTranslation('adminContractingPlan')
  const { storeCache } = useContext(AuthContext)
  const { subscriptionObj, videos, filelists, team } = storeCache
  const [stripe, setStripe] = useState<IStripe>(initStripe())

  const video = (() => {
    const max = getVideoUploadLimit(subscriptionObj, stripe)
    const value = getAllVideoSize(videos)
    const percentage = Math.round((value / max) * 100)
    const currentUsage = getFileSize(value, true)
    const planUsage = getFileSize(max, true)
    return { max, value, percentage, currentUsage, planUsage }
  })()

  const filelist = (() => {
    const max = getFilelistUploadLimit(subscriptionObj, stripe)
    const value = getAllFilelistSize(filelists)
    const percentage = Math.round((value / max) * 100)
    const currentUsage = getFileSize(value, true)
    const planUsage = getFileSize(max, true)
    return { max, value, percentage, currentUsage, planUsage }
  })()

  useEffect(() => {
    getStripe(setStripe)
  }, [setStripe])

  return (
    <>
      <TeamsPlan />
      <Row className="mt-4">
        <Col xl="6">
          <div className="progress-wrapper">
            <div className="progress-info">
              <div className="progress-label">
                <span>{t('videoVolume')}</span>
              </div>
              <div className="progress-percentage">
                <span>{video.percentage}%</span>
              </div>
            </div>
            <Progress max={video.max} value={video.value} />
          </div>
        </Col>
      </Row>
      <h3>{t('videoUsage')}</h3>
      <h3>
        {video.currentUsage}/{video.planUsage}
      </h3>
      <Row className="mt-5">
        <Col xl="6">
          <div className="progress-wrapper">
            <div className="progress-info">
              <div className="progress-label">
                <span className="text-info">{t('filelistVolume')}</span>
              </div>
              <div className="progress-percentage">
                <span>{filelist.percentage}%</span>
              </div>
            </div>
            <Progress color="info" max={filelist.max} value={filelist.value} />
          </div>
        </Col>
      </Row>
      <h3>{t('filelistUsage')}</h3>
      <h3>
        {filelist.currentUsage}/{filelist.planUsage}
      </h3>
      {!team!.stripeId && <PaymentMethodSelect className="mt-5" />}
    </>
  )
}

const TeamsPlan: React.FC = () => {
  const { t } = useTranslation('adminContractingPlan')
  const { subscriptionObj } = useContext(AuthContext).storeCache
  if (!subscriptionObj) return <>{t('noSubscription')}</>

  const { id, name, nickname } = subscriptionObj.subscription_item.price
  const isCustomerBalancePayment = isCustomerBalance(subscriptionObj)

  return (
    <>
      <h2>{nickname}</h2>
      <h3>
        {isCustomerBalancePayment
          ? t('payment.method.bankTransfer')
          : t('payment.method.creditCard')}
      </h3>
      <h3 className="mt-4">{name}</h3>
      <Link to={makeAdminItemSelectPath(id)}>{t('toItemSelectPage')}</Link>
    </>
  )
}

const PaymentMethodSelect: React.FC<FormProps> = (props) => {
  const { t } = useTranslation('adminContractingPlan')
  const { storeCache } = useContext(AuthContext)
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodType>(
      storeCache.team!.is_invoice_payment
        ? PaymentMethodType.CUSTOMER_BALANCE
        : PaymentMethodType.CARD
    )

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedPaymentMethod(e.target.value as PaymentMethodType)

  const paymentMethodAttributeList = [
    {
      label: t('payment.method.creditCard'),
      value: PaymentMethodType.CARD,
    },
    {
      label: t('payment.method.bankTransfer'),
      value: PaymentMethodType.CUSTOMER_BALANCE,
    },
  ]

  const save = () => saveTeamIsInvoicePayment(storeCache, selectedPaymentMethod)

  return (
    <Form {...props}>
      <h3>{t('payment.title')} </h3>
      <FormGroup check>
        {paymentMethodAttributeList.map((attr) => (
          <div>
            <Input
              type="radio"
              id={attr.value}
              value={attr.value}
              checked={attr.value === selectedPaymentMethod}
              onChange={changeValue}
            />
            <Label htmlFor={attr.value}>{attr.label}</Label>
          </div>
        ))}
      </FormGroup>
      <Button onClick={save} className="mt-3" color="primary" type="button">
        {t('payment.form.save')}
      </Button>
    </Form>
  )
}

export default Body
