import { Routes } from 'common/enums'
import { IVideo } from 'common/interfaces/video'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/VideoForm/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initVideo } from 'services/admin/video_form'

const VideoForm: React.FC = () => {
  const { t } = useTranslation('adminVideo')
  const history = useHistory()
  const location = useLocation<{ video: IVideo }>()
  const isCreate =
    location.pathname === Routes.AdminVideoCreate ||
    location.pathname === Routes.AdminVideoReCreate
  const video =
    location.pathname === Routes.AdminVideoCreate
      ? initVideo()
      : location.state.video

  return (
    <>
      <H1 title={t(isCreate ? 'form.new' : 'form.edit')} />

      <Container fluid>
        <ButtonRight nextPage={Routes.AdminVideo} content={t('form.back')} />
        <FormBody isCreate={isCreate} videoObj={video} history={history} />
      </Container>
    </>
  )
}

export default VideoForm
