import { IStoreCache } from 'common/interfaces/auth_provider'
import GenreCard from 'components/molecules/User/GenreCard/GenreCard'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Col, Row } from 'reactstrap'
import { createTitle, getGenreData } from 'services/user/genre_list'
import './genrelist.scss'

const GenreList: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const title = createTitle()

  return (
    <div className="genre-list">
      <h1 className="title">{title}</h1>
      <Row className="genre-row">{createGenreCols(storeCache)}</Row>
    </div>
  )
}

const createGenreCols = (storeCache: IStoreCache): JSX.Element[] =>
  getGenreData(storeCache).map(({ id, name, link, image }) => (
    <Col key={id} className="genre-col" xs="6" md="4" xl="3">
      <GenreCard name={name} link={link} image={image} is16x9={false} />
    </Col>
  ))

export default GenreList
