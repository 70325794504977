import { Routes } from 'common/enums'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardText, CardTitle, Col, Row } from 'reactstrap'

const LinkBody: React.FC = () => {
  const { t } = useTranslation('adminAnalytics')
  const cardStyle: CSSProperties = { color: 'unset' }

  return (
    <Row>
      <Col sm="12" className="mb-4">
        <Link to={Routes.AdminAnalyticsTotal} style={cardStyle}>
          <Card body>
            <CardTitle tag="h4">{t('total.title')}</CardTitle>
            <CardText>{t('total.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-4">
        <Link to={Routes.AdminAnalyticsVideos} style={cardStyle}>
          <Card body>
            <CardTitle tag="h4">{t('videos.title')}</CardTitle>
            <CardText>{t('videos.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-4">
        <Link to={Routes.AdminAnalyticsUsers} style={cardStyle}>
          <Card body>
            <CardTitle tag="h4">{t('users.title')}</CardTitle>
            <CardText>{t('users.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-5">
        <Link to={Routes.AdminAnalyticsVideoUsers} style={cardStyle}>
          <Card body>
            <CardTitle tag="h4">{t('videoUsers.title')}</CardTitle>
            <CardText>{t('videoUsers.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-2">
        <h2>{t('lives.title')}</h2>
      </Col>
      <Col sm="12" className="mb-5">
        <Link to={Routes.AdminAnalyticsLiveUsers} style={cardStyle}>
          <Card body>
            <CardTitle tag="h4">{t('lives.liveUsers.title')}</CardTitle>
            <CardText>{t('lives.liveUsers.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-2">
        <h2>{t('log.title')}</h2>
      </Col>
      <Col sm="12" className="mb-4">
        <Link to={Routes.AdminAnalyticsLogUser} style={cardStyle}>
          <Card body>
            <CardTitle tag="h4">{t('log.user.title')}</CardTitle>
            <CardText>{t('log.user.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-5">
        <Link to={Routes.AdminAnalyticsLogUserVideos} style={cardStyle}>
          <Card body>
            <CardTitle tag="h4">{t('log.userVideos.title')}</CardTitle>
            <CardText>{t('log.userVideos.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-2">
        <h2>{t('watchLog.title')}</h2>
      </Col>
      <Col sm="12" className="mb-4">
        <Link to={Routes.AdminAnalyticsWatchLogUser} style={cardStyle}>
          <Card body>
            <CardTitle tag="h4">{t('watchLog.user.title')}</CardTitle>
            <CardText>{t('watchLog.user.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-4">
        <Link to={Routes.AdminAnalyticsWatchLogUserVideos} style={cardStyle}>
          <Card body>
            <CardTitle tag="h4">{t('watchLog.userVideos.title')}</CardTitle>
            <CardText>{t('watchLog.userVideos.note')}</CardText>
          </Card>
        </Link>
      </Col>
    </Row>
  )
}

export default LinkBody
