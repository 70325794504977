import React from 'react'
import { Link } from 'react-router-dom'

interface IBlackLink {
  to: string
  content: string
}

const BlackLink: React.FC<IBlackLink> = (props: IBlackLink) => {
  return (
    <Link
      to={props.to}
      style={{
        color: '#000000',
      }}
    >
      {props.content}
    </Link>
  )
}

export default BlackLink
