import { Routes } from 'common/enums'
import {
  getCurrency,
  getLang,
  goBackWithErrorAlert,
  isCustomerBalance,
} from 'common/utils'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import Header from 'components/molecules/Admin/Header'
import Sidebar from 'components/molecules/Admin/Sidebar'
import List from 'components/organisms/Admin/AdminItemSelect/List'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Button, Container } from 'reactstrap'

const AdminItemSelect: React.FC = () => {
  const { t } = useTranslation('itemSelect')
  const { t: i18nAlert } = useTranslation('alert')
  const { isLoading, subscriptionObj } = useContext(AuthContext).storeCache
  const history = useHistory()
  const { pathname } = useLocation()

  const [loading, setLoading] = useState<boolean>(false)

  if (isLoading || loading) return <FullLoadingSpinner />
  if (
    subscriptionObj &&
    !isCustomerBalance(subscriptionObj) &&
    pathname === Routes.AdminItemSelect
  ) {
    history.replace(Routes.AdminVideo)
    return null
  }
  if (
    subscriptionObj &&
    getCurrency() !== subscriptionObj.subscription.currency
  ) {
    goBackWithErrorAlert(
      history,
      i18nAlert('changeLanguageLikeYourCurrency', { language: getLang() })
    )
  }

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <Header />
        <div className="text-right mr-5">
          <Button
            onClick={() => history.push(Routes.AdminVideo)}
            className="mt-4"
            color="default"
            type="button"
          >
            {t('back')}
          </Button>
        </div>

        <Container>
          <List setLoading={setLoading} />
        </Container>
      </div>
    </>
  )
}

export default AdminItemSelect
