import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { ISendEmail } from 'common/interfaces/send_email'
import { dateFormat, datetimeFormat } from 'common/times'
import { formatSendEmailStatus } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeEditDeleteOperationButtons,
  makePrimaryBadges,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  getGroupNamesByIds,
  getSendEmails,
  removeSendEmail,
} from 'services/admin/send_email'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminSendEmail')
  const { storeCache } = useContext(AuthContext)

  const [sendEmails, setSendEmails] = useState<ISendEmail[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await getSendEmails(storeCache, setSendEmails)
      setIsLoading(false)
    })()
  }, [storeCache])

  if (isLoading) return <LoadingSpinner />
  if (sendEmails.length <= 0) {
    return <RegisterItemButton type="send_email" />
  }
  return (
    <>
      <ButtonRight
        nextPage={Routes.AdminSendEmailCreate}
        content={t('list.new')}
      />
      <Table sendEmails={sendEmails} />
    </>
  )
}

const Table: React.FC<{ sendEmails: ISendEmail[] }> = ({ sendEmails }) => {
  const { t } = useTranslation('adminSendEmail')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const columns = useMemo<ColumnDef<ISendEmail>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => sendEmails.length - i,
      },
      {
        header: t('list.table.title'),
        accessorKey: 'title',
      },
      {
        header: t('list.table.subject'),
        accessorKey: 'subject',
      },
      {
        header: t('list.table.groups'),
        accessorFn: (se) => getGroupNamesByIds(storeCache, se.group_ids),
        cell: (cell) => makePrimaryBadges(cell.getValue<string[]>()),
      },
      {
        header: t('list.table.status.title'),
        accessorFn: (se) => formatSendEmailStatus(se.status),
      },
      {
        header: t('list.table.sendAt'),
        accessorFn: (se) => datetimeFormat(se.send_at),
      },
      {
        header: t('list.table.createdAt'),
        accessorFn: (se) => dateFormat(se.created_at),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (se) =>
          makeEditDeleteOperationButtons<ISendEmail>(
            history,
            'sendEmail',
            Routes.AdminSendEmailEdit,
            (e) => removeSendEmail(history, e, storeCache),
            se,
            {
              buttons: makeCustomOperationButton(
                t('list.table.sendEmailLog'),
                () =>
                  history.push({
                    pathname: Routes.AdminSendEmailLog,
                    state: { foreignId: se.id },
                  })
              ),
            }
          ),
      },
    ],
    [storeCache, history, sendEmails.length, t]
  )

  const data = sendEmails.sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  )
  return useTanstackTable<ISendEmail>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
