import { ColumnDef } from '@tanstack/react-table'
import { IUserViewCount } from 'common/interfaces/analytics'
import { IFlattedLog } from 'common/interfaces/log'
import { IFromTo } from 'common/interfaces/time'
import { IUser } from 'common/interfaces/user'
import { isAuthMethodEmail, userUid } from 'common/utils'
import {
  customFieldColumnDef,
  useTanstackTableWithCsvExport,
} from 'components/atoms/Table/CreateTable'
import { makePrimaryBadges } from 'components/atoms/Table/ElementsOnTable'
import AnalyticsRangeSelector from 'components/molecules/Admin/AnalyticsRangeSelector'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DatePeriod,
  getSortedUserLogs,
  initFromTo,
  setMonthSelectOptions,
} from 'services/admin/analytics'
import {
  getDurationTitle,
  getUsers,
  setUserViewCountData,
} from 'services/admin/analytics_users'

const TableBody: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const [users, setUsers] = useState<IUser[]>([])
  const [allUserLogs, setAllUserLogs] = useState<IFlattedLog[]>([])
  const [monthOptions, setMonthOptions] = useState<JSX.Element[]>([])
  const [selectedMonth, setSelectedMonth] = useState<string>('')
  const [userViewCounts, setUserViewCounts] = useState<IUserViewCount[]>([])
  const [selectedDatePeriod, setSelectedDatePeriod] = useState<DatePeriod>(
    DatePeriod.DAYS_7
  )
  const [fromTo, setFromTo] = useState<IFromTo>(initFromTo())

  useEffect(() => {
    getUsers(storeCache, setUsers)
    getSortedUserLogs(storeCache, setAllUserLogs)
  }, [storeCache])

  useEffect(() => {
    setMonthSelectOptions(allUserLogs, setMonthOptions, setSelectedMonth)
  }, [allUserLogs])

  useEffect(() => {
    setUserViewCountData(
      storeCache,
      users,
      allUserLogs,
      selectedDatePeriod,
      setUserViewCounts,
      { date: selectedMonth, fromTo }
    )
  }, [
    storeCache,
    users,
    allUserLogs,
    selectedDatePeriod,
    selectedMonth,
    fromTo,
  ])

  if (!storeCache.team) return null

  return (
    <>
      <AnalyticsRangeSelector
        className="mb-3"
        monthOptions={monthOptions}
        selectedDatePeriod={[selectedDatePeriod, setSelectedDatePeriod]}
        selectedMonth={[selectedMonth, setSelectedMonth]}
        fromTo={[fromTo, setFromTo]}
      >
        <h2 className="mb-0">
          {getDurationTitle(selectedDatePeriod, {
            date: selectedMonth,
            fromTo,
          })}
        </h2>
      </AnalyticsRangeSelector>
      <Table viewCounts={userViewCounts} allUsers={users} />
    </>
  )
}

const Table: React.FC<{ viewCounts: IUserViewCount[]; allUsers: IUser[] }> = ({
  viewCounts,
  allUsers,
}) => {
  const { t } = useTranslation('adminAnalytics')
  const { storeCache } = useContext(AuthContext)
  const isAuthEmail = isAuthMethodEmail(storeCache.team!)

  const columns = useMemo<ColumnDef<IUserViewCount>[]>(
    () => [
      {
        header: 'ID',
        accessorFn: (v) => userUid(v.user_id),
        meta: { hidden: isAuthEmail },
      },
      {
        header: t('userTable.email'),
        accessorKey: 'user_email',
        meta: { hidden: !isAuthEmail, csvExport: isAuthEmail },
      },
      {
        header: t('userTable.name'),
        accessorKey: 'user_name',
      },
      {
        header: t('userTable.phone'),
        accessorKey: 'user_phone',
        meta: { hidden: !isAuthEmail, csvExport: isAuthEmail },
      },
      {
        header: t('userTable.groups'),
        accessorKey: 'user_groups',
        cell: (cell) => makePrimaryBadges(cell.getValue<string[]>()),
      },
      ...customFieldColumnDef<IUserViewCount>(storeCache.team, allUsers),
      {
        header: t('logType.videoPageOpen'),
        accessorKey: 'page_open_count',
      },
      {
        header: t('logType.videoPlay'),
        accessorKey: 'video_play_count',
      },
      {
        header: t('logType.videoNotPlayRate'),
        accessorKey: 'not_play_percent',
      },
      {
        header: t('logType.videoEnded'),
        accessorKey: 'video_ended_count',
      },
      {
        header: t('logType.videoEndedRate'),
        accessorKey: 'video_ended_percent',
      },
    ],
    [storeCache.team, isAuthEmail, allUsers, t]
  )

  return useTanstackTableWithCsvExport<IUserViewCount>(
    columns,
    viewCounts,
    'analytics_userly.csv'
  )
}

export default TableBody
