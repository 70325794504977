import { AuthMethodType } from 'common/enums'
import { goBackWithErrorAlert } from 'common/utils'
import H1 from 'components/atoms/Head/H1'
import TableBody from 'components/organisms/Admin/MailTemplate/TableBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Container } from 'reactstrap'

const MailTemplate: React.FC = () => {
  const { t } = useTranslation('adminMailTemplate')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  if (!storeCache.team) {
    goBackWithErrorAlert(history, i18nAlert('noOperationAuthority'))
    return null
  }

  return (
    <>
      <H1 title={t('list.title')} />

      <Container fluid>
        {storeCache.team.auth_method === AuthMethodType.CUSTOM && (
          <div className="mb-3">{t('list.tips')}</div>
        )}
        <TableBody />
      </Container>
    </>
  )
}

export default MailTemplate
