import firebase from 'common/firebase'
import { periodDatetimeFormat } from 'common/times'
import React from 'react'
import { Card, CardBody, CardText, CardTitle } from 'reactstrap'

type TCard = {
  className?: string
  title?: string
  from: firebase.firestore.Timestamp
  to: firebase.firestore.Timestamp
}

const PlayablePeriodCard: React.FC<TCard> = ({
  className,
  title,
  from,
  to,
}: TCard) => {
  return (
    <Card className={className}>
      <CardBody>
        <CardTitle>{title}</CardTitle>
        <CardText>{periodDatetimeFormat(from, to)}</CardText>
      </CardBody>
    </Card>
  )
}

export default PlayablePeriodCard
