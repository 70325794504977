import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { findCachedCategory } from 'common/find_store_cache'
import { IEvent } from 'common/interfaces/event'
import { dateFormat, periodDatetimeFormat } from 'common/times'
import { formatEventFormat } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  createOverviewContent,
  makeEditDeleteOperationButtons,
  makeTagBadges,
  makeThumbnailElement,
  makeVideoAndLiveBadges,
  makeVideoStatusBadge,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { removeEvent } from 'services/admin/event'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminEvent')
  const { events } = useContext(AuthContext).storeCache

  if (events.length <= 0) {
    return <RegisterItemButton type="event" />
  }
  return (
    <>
      <ButtonRight
        nextPage={Routes.AdminEventCreate}
        content={t('list.register')}
      />
      <Table />
    </>
  )
}

const Table: React.FC = () => {
  const { t } = useTranslation('adminEvent')
  const { storeCache } = useContext(AuthContext)
  const { events } = storeCache
  const history = useHistory()

  const columns = useMemo<ColumnDef<IEvent>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => events.length - i,
      },
      {
        header: t('list.table.status'),
        accessorFn: (e) => makeVideoStatusBadge(e.status),
      },
      {
        header: t('list.table.name'),
        accessorKey: 'name',
      },
      {
        header: t('list.table.format.title'),
        accessorFn: (e) => formatEventFormat(e.format),
      },
      {
        header: t('list.table.holdDate'),
        accessorFn: (e) => periodDatetimeFormat(e.hold_from, e.hold_to),
      },
      {
        header: t('list.table.thumbnail'),
        accessorFn: (e) => makeThumbnailElement(e.image, e.name, 'event'),
      },
      {
        header: t('list.table.category'),
        accessorFn: (e) =>
          findCachedCategory(storeCache, e.category_id)?.name ?? '',
      },
      {
        header: t('list.table.tag'),
        accessorFn: (e) => makeTagBadges(storeCache, e.tag_ids),
      },
      {
        header: t('list.table.overview'),
        accessorFn: (e) => createOverviewContent(e.overview),
      },
      {
        header: t('list.table.contents.title'),
        accessorFn: (e) => makeVideoAndLiveBadges(storeCache, e.contents),
      },
      {
        header: t('list.table.createdAt'),
        accessorFn: (e) => dateFormat(e.created_at),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (e) =>
          makeEditDeleteOperationButtons<IEvent>(
            history,
            'event',
            Routes.AdminEventEdit,
            (e2) => removeEvent(e2, storeCache),
            e
          ),
      },
    ],
    [storeCache, history, events.length, t]
  )

  const data = events.sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  )
  return useTanstackTable<IEvent>(columns, data, {
    fixedLastColumn: true,
    withSearch: true,
  })
}

export default TableBody
