import React from 'react'
import { Container } from 'reactstrap'

interface IH2 {
  title: string
}

const H2: React.FC<IH2> = (props: IH2) => {
  return (
    <div className="header pt-md-2">
      <Container fluid>
        <h3>{props.title}</h3>
      </Container>
    </div>
  )
}

export default H2
