import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICategory } from 'common/interfaces/category'
import { isLoggedIn } from 'common/utils'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { reloadCachedCategories } from 'providers/AuthProvider'
import { removeImageFile } from 'repositories/storage/firebase_category'
import { remove } from 'repositories/store/firebase_category'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * カテゴリを使用している動画/配信の件数を返却する
 */
export const getCategoryUseCount = (
  category: ICategory,
  storeCache: IStoreCache
) => {
  return [...storeCache.videos, ...storeCache.lives].filter(
    (video) => video.category_id === category.id
  ).length
}

/**
 * カテゴリレコード削除処理
 */
export const removeCategory = (
  category: ICategory,
  storeCache: IStoreCache
) => {
  if (!isLoggedIn(storeCache)) return
  if (getCategoryUseCount(category, storeCache) > 0) {
    alertService.show(false, i18nValidation('cantDelete.inUse.category'))
    return
  }

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      if (category.image) {
        await removeImageFile(storeCache.team!, category)
      }
      await remove(storeCache.team!, category)
      await reloadCachedCategories(storeCache)
      alertService.show(true, i18nAlert('deleted.message'))
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('cantDeleted'))
    }
  })
}
