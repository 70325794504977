import { IProduct } from 'common/interfaces/stripe/product'
import AuthFormRow from 'components/organisms/User/Setting/AuthFormRow'
import OperationRow from 'components/organisms/User/Setting/OperationRow'
import ProfileFormRow from 'components/organisms/User/Setting/ProfileFormRow'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'
import { getStripeProducts } from 'services/admin/stripe/product'
import './setting.scss'

const Setting: React.FC = () => {
  const { t } = useTranslation('userSetting')
  const { storeCache } = useContext(AuthContext)

  const [products, setProducts] = useState<IProduct[]>([])

  useEffect(() => {
    getStripeProducts(storeCache, setProducts)
  }, [storeCache])

  return (
    <Container>
      <h1 className="title">{t('myAccount')}</h1>

      <ProfileFormRow />
      <AuthFormRow />
      <OperationRow products={products} />
    </Container>
  )
}

export default Setting
