import { Routes } from 'common/enums'
import { IPlaylist } from 'common/interfaces/playlist'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/PlaylistForm/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initPlaylist } from 'services/admin/playlist_form'

const PlaylistForm: React.FC = () => {
  const { t } = useTranslation('adminPlaylist')
  const history = useHistory()
  const location = useLocation<{ playlist: IPlaylist }>()
  const isCreate = location.pathname === Routes.AdminPlaylistCreate
  const playlist = isCreate ? initPlaylist() : location.state.playlist

  return (
    <>
      <H1 title={t(isCreate ? 'form.new' : 'form.edit')} />

      <Container fluid>
        <ButtonRight nextPage={Routes.AdminPlaylist} content={t('form.back')} />
        <FormBody
          isCreate={isCreate}
          playlistObj={playlist}
          history={history}
        />
      </Container>
    </>
  )
}

export default PlaylistForm
