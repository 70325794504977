import { IUser } from 'common/interfaces/user'
import FooterButtons from 'components/atoms/Modal/FooterButtons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, FormGroup, Input, Label, Modal } from 'reactstrap'
import {
  changeNewPassword,
  initPassword,
  NewPasswordType,
  onChangePassword,
  TPassword,
} from 'services/admin/user'

interface IPasswordChangeModalProps {
  targetUser: IUser | null
  closeModal: () => void
}

const PasswordChangeModal: React.FC<IPasswordChangeModalProps> = ({
  targetUser,
  closeModal,
}) => {
  const { t } = useTranslation('adminUser')
  const isOpen = targetUser !== null

  const [password, setPassword] = useState<TPassword>(initPassword())

  if (!targetUser) return null

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: NewPasswordType
  ) => onChangePassword(e, type, password, setPassword)
  const changeUserPassword = () =>
    changeNewPassword(password, targetUser, closeModal)

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={closeModal}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t('list.modal.passwordChange.settingNewPassword', {
            id: targetUser.origin_id,
          })}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeModal}
        >
          <span aria-hidden>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <PasswordInput
            label={t('list.modal.passwordChange.newPassword')}
            onChange={(e) => onChange(e, NewPasswordType.PASS)}
          />
          <PasswordInput
            label={t('list.modal.passwordChange.confirmPassword')}
            onChange={(e) => onChange(e, NewPasswordType.CONFIRM_PASS)}
          />
          <FooterButtons
            leftContent={t('list.modal.buttons.change')}
            rightContent={t('list.modal.buttons.close')}
            leftOnClick={changeUserPassword}
            rightOnClick={closeModal}
          />
        </Form>
      </div>
    </Modal>
  )
}

const PasswordInput: React.FC<{
  label: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ label, onChange }) => (
  <FormGroup row>
    <Col md="10">
      <Label>{label}</Label>
      <Input autoComplete="new-password" type="password" onChange={onChange} />
    </Col>
  </FormGroup>
)

export default PasswordChangeModal
