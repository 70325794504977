import { LimitPlay } from 'common/enums'
import { IEnquete } from 'common/interfaces/enquete'
import { IEnqueteAnswer } from 'common/interfaces/enquete_answer'
import { IEvent } from 'common/interfaces/event'
import { IVideo } from 'common/interfaces/video'
import { makeVideoPathWithAutoPlay } from 'common/link_path'
import {
  createBunnyFallbackMP4Urls,
  createBunnyHlsUrl,
  createVideoThumbnailUrl,
} from 'common/utils_bunny'
import LimitPlayContainer from 'components/atoms/LimitPlay/LimitPlayContainer'
import HlsVideo, { TimeUpdateEvent } from 'components/atoms/Video/HlsVideo'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import {
  getPremiereStartOverSeconds,
  useVideoWatchLog,
  videoOnTimeUpdate,
  videoOncePlay,
} from 'services/user/video_bunny'
import {
  createVideoChapterWebVttUrl,
  parseVideoChapterFromHtml,
} from 'services/user/video_chapter'
import './bunnyvideo.scss'

interface IBunnyVideo {
  isCatalogPage: boolean
  id?: string
  event: IEvent | null
  video: {
    video: IVideo
    autoplay: boolean
  }
  limitPlay: LimitPlay
  enquete: {
    enquete: IEnquete | null
    enqueteAnswer: IEnqueteAnswer | null
    setEnqueteModal: React.Dispatch<React.SetStateAction<boolean>>
  }
  test: { isShowTest: boolean; onShowTest: () => void }
  onToggleEventApplicationModal: () => void
}

const BunnyVideo: React.FC<IBunnyVideo> = ({
  isCatalogPage,
  id,
  event,
  video: { video, autoplay },
  limitPlay,
  enquete,
  test,
  onToggleEventApplicationModal,
}) => {
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const watchLog = useVideoWatchLog(storeCache, video)
  const isTimeOver90percent = useRef<boolean>(false)
  const [isVideoEnded, setIsVideoEnded] = useState<boolean>(false)

  if (limitPlay !== LimitPlay.VISIBLE) {
    return (
      <LimitPlayContainer
        storeCache={storeCache}
        history={history}
        isCatalogPage={isCatalogPage}
        event={event}
        content={video}
        limitPlay={limitPlay}
        onToggle={onToggleEventApplicationModal}
      />
    )
  }

  const hlsUrl = createBunnyHlsUrl(storeCache.team!, video)
  const fallbackMP4Urls = createBunnyFallbackMP4Urls(storeCache.team!, video)
  const posterUrl = createVideoThumbnailUrl(storeCache.team!, video)
  const chapters = parseVideoChapterFromHtml(video.overview, video.bunny.length)
  const chapterWebVttUrl = createVideoChapterWebVttUrl(chapters)
  const startTime = getPremiereStartOverSeconds(video)
  const videoHook = {
    onPlay: () => watchLog.onPlay(),
    oncePlay: () => videoOncePlay(storeCache, video),
    onTimeUpdate: (e: TimeUpdateEvent) =>
      videoOnTimeUpdate(e, isTimeOver90percent, storeCache, video),
    onPause: () => watchLog.onPause(),
    onEnded: () => {
      watchLog.onEnded()
      setIsVideoEnded(true)
      if (enquete.enquete && !enquete.enqueteAnswer) {
        enquete.setEnqueteModal(true)
      }
      if (test.isShowTest) {
        test.onShowTest()
      }
      if (video.next_video?.activate_next_video) {
        const path = makeVideoPathWithAutoPlay(video.next_video.next_video_id)
        history.push(path)
      }
    },
  }
  const replayVideoEnded = () => {
    if (window.hlsVideoSeek && window.hlsVideoPlay) {
      window.hlsVideoSeek(0)
      window.hlsVideoPlay()
      setIsVideoEnded(false)
    }
  }

  const { action_onend } = video
  /* eslint-disable react/jsx-no-target-blank */
  return (
    <div id={id} className="bunny-video-container">
      {action_onend && isVideoEnded && (
        <div className="video-end-button-container">
          <a
            href={action_onend.link}
            target={action_onend.as_new_tab ? '_blank' : undefined}
            rel={action_onend.as_new_tab ? 'noopener noreferrer' : undefined}
          >
            <Button className="video-end-button">{action_onend.text}</Button>
          </a>
          <button
            className="video-end-replay-button"
            type="button"
            onClick={replayVideoEnded}
          >
            <i className="fas fa-undo text-white" />
          </button>
        </div>
      )}

      <HlsVideo
        className="hls-video"
        hlsUrl={hlsUrl}
        fallbackMP4Urls={fallbackMP4Urls}
        posterUrl={posterUrl}
        chapterWebVttUrl={chapterWebVttUrl}
        autoplay={autoplay}
        resumable
        seek={video.is_seekable}
        liveui={{
          enable: video.is_premiere,
          startTime: startTime ?? -1,
        }}
        oncePlay={videoHook.oncePlay}
        onPlay={videoHook.onPlay}
        onTimeUpdate={videoHook.onTimeUpdate}
        onPause={videoHook.onPause}
        onEnded={videoHook.onEnded}
      />
    </div>
  )
  /* eslint-enable react/jsx-no-target-blank */
}

export default BunnyVideo
