import Footer from 'components/molecules/Footer'
import FormBody from 'components/organisms/PasswordResetRequest/FormBody'
import React from 'react'

const PasswordResetRequest: React.FC = () => (
  <>
    <FormBody />
    <Footer />
  </>
)

export default PasswordResetRequest
