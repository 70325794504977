import { IEnquete } from 'common/interfaces/enquete'
import { IEnqueteAnswer } from 'common/interfaces/enquete_answer'
import {
  IEnqueteCustomize,
  IEnqueteName,
  IEnqueteType,
} from 'common/interfaces/enquete_customize'
import { IFieldCustomizeType } from 'common/interfaces/field_customize'
import { IStripe } from 'common/interfaces/stripe'
import { goBackWithErrorAlert, isBasicPrice } from 'common/utils'
import AddCard from 'components/atoms/FieldCustomize/AddCard'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { getEnqueteAnswersById } from 'services/admin/enquete_answer'
import {
  getFiledPlaceHolder,
  initEnquete,
  initQuestionName,
  saveEnquete,
  onAddItem as serviceOnAddItem,
  onChangeAddCard as serviceOnChangeAddCard,
  onChangeEnqueteTitle as serviceOnChangeEnqueteTitle,
  onDragEnd as serviceOnDragEnd,
  onRemoveItem as serviceOnRemoveItem,
} from 'services/admin/enquete_customize'
import { ChangeAddCardType } from 'services/admin/field_customize'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'
import '../order-customize-style.scss'
import { createItemCard } from './ElementsOnEnqueteCustomize'
import './formbody.scss'

interface IFormBodyProps {
  isCreate: boolean
  enqueteObj: IEnquete
  history: History
}

const FormBody: React.FC<IFormBodyProps> = ({
  isCreate,
  enqueteObj,
  history,
}: IFormBodyProps) => {
  const { t } = useTranslation('adminEnquete')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const [enquete, setEnquete] = useState<IEnquete>(
    isCreate ? initEnquete() : enqueteObj
  )
  const [enqueteCustomize, setEnqueteCustomize] = useState<IEnqueteCustomize[]>(
    isCreate ? [] : (enquete.questions as IEnqueteCustomize[])
  )
  const [questionName, setQuestionName] = useState<IEnqueteName>(
    initQuestionName()
  )
  const [radioOptions, setRadioOptions] = useState<string[]>([''])
  const [checkboxOptions, setCheckboxOptions] = useState<string[]>([''])
  const [answers, setAnswers] = useState<IEnqueteAnswer[]>([])
  const [stripe, setStripe] = useState<IStripe>(initStripe())

  useEffect(() => {
    ;(async () => {
      await Promise.all([
        getEnqueteAnswersById(storeCache, enquete.id, setAnswers),
        getStripe(setStripe),
      ])
    })()
  }, [storeCache, enquete.id])

  if (isBasicPrice(storeCache.subscriptionObj, stripe)) {
    goBackWithErrorAlert(history, i18nAlert('plans.basic.notUseFeature'))
    return null
  }
  if (!storeCache.team) {
    goBackWithErrorAlert(history, i18nAlert('noOperationAuthority'))
    return null
  }

  const onChangeEnqueteTitle = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeEnqueteTitle(e, { enquete, setEnquete })

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: IFieldCustomizeType | IEnqueteType,
    changeType: ChangeAddCardType
  ) =>
    serviceOnChangeAddCard(
      { questionName, setQuestionName },
      e,
      type as IEnqueteType,
      changeType
    )

  const onAddItem = (type: IEnqueteType) =>
    serviceOnAddItem(
      type,
      questionName,
      { enqueteCustomize, setEnqueteCustomize },
      type === IEnqueteType.RADIO ? radioOptions : checkboxOptions,
      type === IEnqueteType.RADIO ? setRadioOptions : setCheckboxOptions
    )

  const onRemoveItem = (position: number) =>
    serviceOnRemoveItem(position, enquete, answers, {
      enqueteCustomize,
      setEnqueteCustomize,
    })

  const onDragEnd = (result: DropResult) =>
    serviceOnDragEnd(result, { enqueteCustomize, setEnqueteCustomize })

  const save = () =>
    saveEnquete(enquete, enqueteCustomize, storeCache, isCreate)

  return (
    <div className="order-customize-style">
      <FormGroup row>
        <Col md="10">
          <Label for="enqueteTitleForm">
            <InfoTooltip
              content={t('form.enqueteTitle.title')}
              tooltipContent={t('form.enqueteTitle.tips')}
            />
          </Label>
          <Input
            id="enqueteTitleForm"
            defaultValue={isCreate ? '' : enquete.title}
            onChange={(e) => onChangeEnqueteTitle(e)}
            type="text"
          />
        </Col>
      </FormGroup>
      <Row>
        <Col md="4" className="all-items">
          <div className="scroll-box">
            <Row>
              <AddCard
                title={t('form.card.title.text')}
                placeholder={getFiledPlaceHolder()}
                type={IEnqueteType.TEXT}
                onChange={onChange}
                onClick={() => onAddItem(IEnqueteType.TEXT)}
              />
              <AddCard
                title={t('form.card.title.textarea')}
                placeholder={getFiledPlaceHolder()}
                type={IEnqueteType.TEXTAREA}
                onChange={onChange}
                onClick={() => onAddItem(IEnqueteType.TEXTAREA)}
              />
              <AddCard
                title={t('form.card.title.radio')}
                placeholder={getFiledPlaceHolder()}
                type={IEnqueteType.RADIO}
                onChange={onChange}
                onClick={() => onAddItem(IEnqueteType.RADIO)}
                options={radioOptions}
                setOptions={setRadioOptions}
              />
              <AddCard
                title={t('form.card.title.checkbox')}
                placeholder={getFiledPlaceHolder()}
                type={IEnqueteType.CHECKBOX}
                onChange={onChange}
                onClick={() => onAddItem(IEnqueteType.CHECKBOX)}
                options={checkboxOptions}
                setOptions={setCheckboxOptions}
              />
            </Row>
          </div>
        </Col>
        <Col md="8" className="selected-items">
          <div className="scroll-box">
            <h2>{t('form.enqueteOrder')}</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {enqueteCustomize.map(({ type }, index) => {
                      const id = type + index
                      return (
                        <Draggable key={id} draggableId={id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {createItemCard(
                                enqueteObj,
                                index,
                                { enqueteCustomize, setEnqueteCustomize },
                                onRemoveItem
                              )}
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Col>
      </Row>

      <div className="text-center mt-3">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {t('form.save')}
        </Button>
      </div>
    </div>
  )
}

export default FormBody
