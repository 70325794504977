import { Routes } from 'common/enums'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Card, CardText, CardTitle, Col, Row } from 'reactstrap'

const LinkBody: React.FC = () => {
  const { t } = useTranslation('adminSiteCustomize')
  return (
    <Row>
      <Col sm="12" className="mb-4">
        <Link to={Routes.AdminSiteCustomizeLp} style={{ color: 'unset' }}>
          <Card body>
            <CardTitle tag="h4">{t('landingCustomize.title')}</CardTitle>
            <CardText>{t('landingCustomize.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-4">
        <Link to={Routes.AdminSiteCustomizeImage} style={{ color: 'unset' }}>
          <Card body>
            <CardTitle tag="h4">{t('imageCustomize.title')}</CardTitle>
            <CardText>{t('imageCustomize.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-4">
        <Link
          to={Routes.AdminSiteCustomizeHeaderItemOrder}
          style={{ color: 'unset' }}
        >
          <Card body>
            <CardTitle tag="h4">{t('headerCustomize.title')}</CardTitle>
            <CardText>{t('headerCustomize.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-4">
        <Link
          to={Routes.AdminSiteCustomizeTopItemOrder}
          style={{ color: 'unset' }}
        >
          <Card body>
            <CardTitle tag="h4">{t('topPageCustomize.title')}</CardTitle>
            <CardText>{t('topPageCustomize.note')}</CardText>
          </Card>
        </Link>
      </Col>
      <Col sm="12" className="mb-4">
        <Link to={Routes.AdminSiteCustomizeColor} style={{ color: 'unset' }}>
          <Card body>
            <CardTitle tag="h4">{t('colorCustomize.title')}</CardTitle>
            <CardText>{t('colorCustomize.note')}</CardText>
          </Card>
        </Link>
      </Col>
    </Row>
  )
}

export default LinkBody
