import { Stripe as StripeJS } from '@stripe/stripe-js'
import { PriceType } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IStripe } from 'common/interfaces/stripe'
import { IProductInfo } from 'common/interfaces/stripe/product_price'
import { addedUnixTime } from 'common/times'
import { isLoggedIn } from 'common/utils'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import {
  createCheckoutSession,
  fetchPriceIdBySubscription,
  fetchProductInfo,
} from 'repositories/functions/functions_stripe'
import { get as getStripe } from 'repositories/store/firebase_stripe'
import { alertService } from './alert'

/**
 * 該当のstripeアカウントの購入済みサブスク商品を取得し、Stateに格納する
 */
export const getPurchasedPriceId = async (
  storeCache: IStoreCache,
  setData: React.Dispatch<React.SetStateAction<string>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    const { team, user } = storeCache
    const { customer_id } = user!
    if (!customer_id) {
      throw new Error(i18nAlert('plans.notJoined'))
    }

    setData(
      await fetchPriceIdBySubscription(team!.stripe.account_id, customer_id)
    )
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * 該当のstripeアカウントの該当商品の全定期購入料金を取得し、Stateに格納する
 */
export const getStripeProductAndPrice = async (
  storeCache: IStoreCache,
  setData: React.Dispatch<React.SetStateAction<IProductInfo[]>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    const { account_id, subscription_product_id } = storeCache.team!.stripe
    setData(
      await fetchProductInfo(account_id, subscription_product_id, undefined)
    )
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * get CheckoutSession Mode
 *
 * @param type `PriceType
 * @returns 'payment' | 'setup' | 'subscription'
 */
const getCheckoutSessionMode = (
  type: PriceType
): 'payment' | 'setup' | 'subscription' =>
  type === PriceType.ONE_TIME ? 'payment' : 'subscription'

/**
 * stripeの支払い画面に遷移する
 */
export const transitionCheckoutSession = async (
  storeCache: IStoreCache,
  { id, tax_rate, type, valid_days, trial }: IProductInfo,
  couponId: string | undefined,
  successURL: string,
  cancelURL: string
): Promise<void> => {
  if (!isLoggedIn(storeCache) || storeCache.user!.admin) return

  try {
    const { team, user } = storeCache
    await redirectToCheckout(
      team!.stripe.account_id,
      id,
      tax_rate.id,
      type,
      user!.customer_id,
      user!.email,
      user!.id,
      couponId,
      successURL,
      cancelURL,
      valid_days,
      trial
    )
  } catch (error: any) {
    console.error(error)
    alertService.show(false, i18nValidation('coupon.disabled'))
  }
}

/**
 * Redirect To Checkout Session Page
 */
export const redirectToCheckout = async (
  stripeAccount: string | undefined,
  priceId: string,
  taxRateId: string,
  type: PriceType,
  customer: string | null | undefined,
  email: string | undefined,
  uid: string,
  couponId: string | undefined,
  successURL: string,
  cancelURL: string,
  valid_days: number | undefined,
  trial: number | undefined
) => {
  const { Stripe } = window
  const { id } = await createCheckoutSession(
    stripeAccount,
    customer || undefined,
    customer ? undefined : email,
    priceId,
    couponId !== '' ? couponId : undefined,
    uid,
    successURL,
    cancelURL,
    taxRateId,
    getCheckoutSessionMode(type),
    valid_days,
    !trial ? undefined : addedUnixTime(trial + 1, 'd')
  )
  const { publishableKey }: IStripe = await getStripe()
  const stripe: StripeJS | undefined = Stripe
    ? Stripe(publishableKey, { stripeAccount })
    : undefined
  if (stripe) stripe.redirectToCheckout({ sessionId: id })
}
