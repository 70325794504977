import { ColumnDef } from '@tanstack/react-table'
import {
  CurrencyType,
  DateRange,
  PaymentIntentStatus,
  PaymentMethodType,
} from 'common/enums'
import { IPaymentIntent } from 'common/interfaces/stripe/payment_intent'
import { IFromTo } from 'common/interfaces/time'
import { IUser } from 'common/interfaces/user'
import { formatUNIXToDate } from 'common/times'
import { formatPrice } from 'common/utils'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import {
  customFieldColumnDef,
  useTanstackTableWithCsvExport,
} from 'components/atoms/Table/CreateTable'
import {
  makeGroupBadges,
  makePaymentMethodTypesStatusBadges,
  makePaymentStatusBadge,
} from 'components/atoms/Table/ElementsOnTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Input, Label, Row } from 'reactstrap'
import {
  InputType,
  getPayments,
  initFromTo,
  isGetData,
  onChangeFromTo as serviceOnChangeFromTo,
} from 'services/admin/stripe/payment_intent'
import { getNewerUsers } from 'services/admin/user'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminStripePayment')
  const { storeCache } = useContext(AuthContext)

  const [dateRange, setDateRange] = useState<DateRange>(DateRange.NONE)
  const [fromTo, setFromTo] = useState<IFromTo>(initFromTo())
  const [paymentIntents, setPaymentIntents] = useState<IPaymentIntent[]>([])
  const [users, setUsers] = useState<IUser[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getPayments(storeCache, dateRange, fromTo, setPaymentIntents, setIsLoading)
    getNewerUsers(setUsers, storeCache)
  }, [storeCache, dateRange, fromTo])

  if (isLoading) return <LoadingSpinner />

  const onChangeFromTo = (value: string, type: InputType) =>
    serviceOnChangeFromTo(value, type, fromTo, setFromTo)

  return (
    <>
      <Row className="justify-content-end mb-3">
        <Col md="3">
          <Input
            onChange={(e) => setDateRange(Number(e.target.value))}
            type="select"
            value={dateRange}
          >
            <option value={DateRange.NONE} />
            <option value={DateRange.CURRENT_MONTH}>
              {t('list.dateRange.currentMonth')}
            </option>
            <option value={DateRange.LAST_MONTH}>
              {t('list.dateRange.lastMonth')}
            </option>
            <option value={DateRange.FROM_TO}>
              {t('list.dateRange.fromTo')}
            </option>
          </Input>
        </Col>
      </Row>
      {dateRange === DateRange.FROM_TO && (
        <Row className="justify-content-end mb-3">
          <DateInput
            label="FROM"
            onChange={(e) => onChangeFromTo(e.target.value, InputType.FROM)}
            value={fromTo.from}
          />
          <DateInput
            label="TO"
            onChange={(e) => onChangeFromTo(e.target.value, InputType.TO)}
            value={fromTo.to}
          />
        </Row>
      )}
      <TableWrapper
        dateRange={dateRange}
        fromTo={fromTo}
        paymentIntents={paymentIntents}
        allUsers={users}
      />
    </>
  )
}

const DateInput: React.FC<{
  label: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  value: string
}> = (props) => (
  <Col md="3">
    <Label>{props.label}</Label>
    <Input onChange={props.onChange} type="date" value={props.value} />
  </Col>
)

const TableWrapper: React.FC<{
  dateRange: DateRange
  fromTo: IFromTo
  paymentIntents: IPaymentIntent[]
  allUsers: IUser[]
}> = (props) => {
  const { t } = useTranslation('adminStripePayment')
  if (isGetData(props.dateRange, props.fromTo)) {
    return <>{t('list.selectMonth')}</>
  }
  if (props.paymentIntents.length <= 0) return <>{t('list.noPayment')}</>

  return (
    <Table paymentIntents={props.paymentIntents} allUsers={props.allUsers} />
  )
}

interface IPaymentIntentTable {
  id: string
  recurring: string
  name: string
  nickname: string
  amount: number
  currency: CurrencyType
  status: PaymentIntentStatus
  user_name: string
  user_email: string
  user_id: string
  firebase_user_email: string
  firebase_user_phone: string
  firebase_user_groups: string[]
  payment_method_types: PaymentMethodType[]
  created: number
}

const Table: React.FC<{
  paymentIntents: IPaymentIntent[]
  allUsers: IUser[]
}> = (props) => {
  const { t } = useTranslation('adminStripePayment')
  const { t: i18nCommon } = useTranslation('common')
  const { storeCache } = useContext(AuthContext)

  const paymentIntentTable: IPaymentIntentTable[] = props.paymentIntents.map(
    (pi) => {
      const user = props.allUsers.find((u) => u.customer_id === pi.customer)
      return {
        id: pi.id,
        recurring: pi.recurring ?? '',
        name: pi.name ?? '',
        nickname: pi.nickname,
        amount: pi.amount,
        currency: pi.currency,
        status: pi.status,
        user_id: user?.id ?? '',
        user_name: pi.user_name ?? '',
        user_email: pi.user_email ?? '',
        firebase_user_email: user?.email ?? '',
        firebase_user_phone: user?.phone ?? '',
        firebase_user_groups: user?.group_ids ?? [],
        payment_method_types: pi.payment_method_types,
        created: pi.created,
      }
    }
  )

  const columns = useMemo<ColumnDef<IPaymentIntentTable>[]>(
    () => [
      { header: t('list.table.id'), accessorKey: 'id', meta: { hidden: true } },
      { header: t('list.table.recurring'), accessorKey: 'recurring' },
      { header: t('list.table.name'), accessorKey: 'name' },
      { header: t('list.table.nickname'), accessorKey: 'nickname' },
      {
        header: t('list.table.price'),
        accessorFn: (pi) => formatPrice(pi.amount, pi.currency),
      },
      {
        header: t('list.table.status'),
        accessorKey: 'status',
        cell: (cell) =>
          makePaymentStatusBadge(cell.getValue<PaymentIntentStatus>()),
      },
      { header: t('list.table.userName'), accessorKey: 'user_name' },
      { header: t('list.table.userEmail'), accessorKey: 'user_email' },
      { header: t('list.table.email'), accessorKey: 'firebase_user_email' },
      { header: t('list.table.phone'), accessorKey: 'firebase_user_phone' },
      {
        header: t('list.table.groups'),
        accessorKey: 'firebase_user_groups',
        cell: (cell) => makeGroupBadges(storeCache, cell.getValue<string[]>()),
      },
      ...customFieldColumnDef<IPaymentIntentTable>(
        storeCache.team,
        props.allUsers
      ),
      {
        header: t('list.table.paymentMethod'),
        accessorKey: 'payment_method_types',
        cell: (cell) =>
          makePaymentMethodTypesStatusBadges(
            cell.getValue<PaymentMethodType[]>()
          ),
      },
      {
        header: t('list.table.createdAt'),
        accessorFn: (pi) =>
          formatUNIXToDate(pi.created, i18nCommon('format.dateTime')),
      },
    ],
    [t, i18nCommon, storeCache, props.allUsers]
  )

  const data = paymentIntentTable.sort((a, b) => a.created - b.created)
  return useTanstackTableWithCsvExport<IPaymentIntentTable>(
    columns,
    data,
    'payment.csv',
    { fixedLastColumn: true }
  )
}

export default TableBody
