import { Routes } from 'common/enums'
import { IInvite } from 'common/interfaces/invite'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import H2 from 'components/atoms/Head/H2'
import TableBody from 'components/organisms/Admin/InvitedUser/TableBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Container } from 'reactstrap'

const InvitedUser: React.FC = () => {
  const i18n = useTranslation('adminInvite').t('invitedUser', {
    returnObjects: true,
  })
  const { state } = useLocation<IInvite>()

  return (
    <>
      <H1 title={i18n.title} />
      <H2 title={i18n.inviteId} />
      <H2 title={state.id} />

      <Container fluid>
        <ButtonRight nextPage={Routes.AdminInvite} content={i18n.backToList} />
        <TableBody invite={state} />
      </Container>
    </>
  )
}

export default InvitedUser
