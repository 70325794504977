import { ISubscription } from 'common/interfaces/subscription'
import { openNewTab } from 'common/utils'
import { fetchInvoice } from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'

/**
 * Stripeの特定の料金を取得しStateに格納する
 */
export const repaymentInvoice = async ({
  latest_invoice,
}: ISubscription): Promise<void> => {
  try {
    const { hosted_invoice_url } = await fetchInvoice(undefined, latest_invoice)
    openNewTab(hosted_invoice_url)
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
