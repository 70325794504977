import { IStoreCache } from 'common/interfaces/auth_provider'
import { makeUserRootPath } from 'common/link_path'
import { History } from 'history'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { changePasswordForCustomAuth } from 'repositories/functions/functions_auth'
import { update } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'

export enum InputType {
  PASS,
  PASS_CONFIRM,
}

export interface IChangePassword {
  password: string
  passwordConfirm: string
}

export const initChangeInput = (): IChangePassword => ({
  password: '',
  passwordConfirm: '',
})

export const onChangeInput = (
  type: InputType,
  e: React.ChangeEvent<HTMLInputElement>,
  ChangePassword: IChangePassword,
  setChangePassword: React.Dispatch<React.SetStateAction<IChangePassword>>
): void =>
  setChangePassword({
    password:
      type === InputType.PASS ? e.target.value : ChangePassword.password,
    passwordConfirm:
      type === InputType.PASS_CONFIRM
        ? e.target.value
        : ChangePassword.passwordConfirm,
  })

export const updatePassword = async (
  storeCache: IStoreCache,
  history: History,
  changePassword: IChangePassword
): Promise<void> => {
  try {
    if (!storeCache.team || !storeCache.user) {
      throw new Error(i18nAlert('auth.noLogin'))
    }
    if (
      !changePassword.password ||
      changePassword.password !== changePassword.passwordConfirm
    ) {
      throw new Error(i18nValidation('notMatch.password'))
    }

    await changePasswordForCustomAuth(
      storeCache.user.id,
      changePassword.password
    )

    const user = storeCache.user
    user.is_password_changeable = false
    await update(storeCache.team, user)

    history.replace(makeUserRootPath(storeCache.team.id))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
