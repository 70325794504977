import { IStoreCache } from 'common/interfaces/auth_provider'
import { IGroup } from 'common/interfaces/group'
import { IUser } from 'common/interfaces/user'
import { isLoggedIn } from 'common/utils'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { reloadCachedGroups } from 'providers/AuthProvider'
import { remove } from 'repositories/store/firebase_group'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * グループに所属しているユーザーの数を返却する
 */
export const getGroupMemberCount = (group: IGroup, allUsers: IUser[]) => {
  return allUsers.filter((u) => u.group_ids.includes(group.id)).length
}

/**
 * グループレコード削除処理
 */
export const removeGroup = (
  group: IGroup,
  allUsers: IUser[],
  storeCache: IStoreCache
) => {
  if (!isLoggedIn(storeCache)) return
  if (getGroupMemberCount(group, allUsers) > 0) {
    alertService.show(false, i18nValidation('cantDelete.group.joinedUser'))
    return
  }

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      await remove(storeCache.team!, group)
      await reloadCachedGroups(storeCache)
      alertService.show(true, i18nAlert('deleted.message'))
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('cantDeleted'))
    }
  })
}
