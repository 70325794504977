import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { INews } from 'common/interfaces/news'
import { dateFormat } from 'common/times'
import ButtonRight from 'components/atoms/Button/Right'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  createOverviewContent,
  makeEditDeleteOperationButtons,
  makeGroupBadges,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { removeNews } from 'services/admin/news'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminNews')
  const { newslists } = useContext(AuthContext).storeCache

  if (newslists.length <= 0) {
    return <RegisterItemButton type="news" />
  }
  return (
    <>
      <ButtonRight nextPage={Routes.AdminNewsCreate} content={t('list.new')} />
      <Table />
    </>
  )
}

const Table: React.FC = () => {
  const { t } = useTranslation('adminNews')
  const { storeCache } = useContext(AuthContext)
  const { newslists } = storeCache
  const history = useHistory()

  const columns = useMemo<ColumnDef<INews>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => newslists.length - i,
      },
      {
        header: t('list.table.title'),
        accessorKey: 'title',
      },
      {
        header: t('list.table.content'),
        accessorFn: (n) => createOverviewContent(n.content),
      },
      {
        header: t('list.table.publishGroup'),
        accessorFn: (n) => makeGroupBadges(storeCache, n.group_ids ?? []),
      },
      {
        header: t('list.table.createdAt'),
        accessorFn: (n) => dateFormat(n.created_at),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (n) =>
          makeEditDeleteOperationButtons<INews>(
            history,
            'news',
            Routes.AdminNewsEdit,
            (e) => removeNews(e, storeCache),
            n
          ),
      },
    ],
    [storeCache, history, newslists.length, t]
  )

  const data = newslists.sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  )
  return useTanstackTable<INews>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
