import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEnquete } from 'common/interfaces/enquete'
import { IEnqueteAnswer } from 'common/interfaces/enquete_answer'
import {
  IEnqueteCustomize,
  IEnqueteName,
  IEnqueteType,
} from 'common/interfaces/enquete_customize'
import { nowTimestamp } from 'common/times'
import { checkFirebaseError, isDuplicate, isLoggedIn } from 'common/utils'
import { i18nAdminEnquete, i18nAlert, i18nValidation } from 'i18n/i18n'
import { DropResult } from 'react-beautiful-dnd'
import { store, update } from 'repositories/store/firebase_enquete'
import { alertService } from 'services/alert'
import { v4 as uuidv4 } from 'uuid'
import { ChangeAddCardType } from './field_customize'

export const getFiledPlaceHolder = () =>
  i18nAdminEnquete('form.card.title.placeholder')

/**
 * アンケートドキュメント初期値設定
 */
export const initEnquete = (): IEnquete => ({
  id: uuidv4(),
  title: '',
  questions: [],
  created_at: nowTimestamp(),
  updated_at: nowTimestamp(),
})

/**
 * 質問項目初期値設定
 */
export const initQuestionName = (): IEnqueteName => ({
  text: '',
  textarea: '',
  radio: '',
  checkbox: '',
  text_required: false,
  textarea_required: false,
  radio_required: false,
  checkbox_required: false,
})

export enum InputTypeEnquete {
  TITLE,
}

/**
 * 追加済カード内onChangeイベントハンドラ
 */
export const onChangeEnqueteField = (
  index: number,
  enqueteCustomizeStates: {
    enqueteCustomize: IEnqueteCustomize[]
    setEnqueteCustomize: React.Dispatch<
      React.SetStateAction<IEnqueteCustomize[]>
    >
  },
  { target: { value, checked } }: React.ChangeEvent<HTMLInputElement>,
  isToggle: boolean
): void => {
  const { enqueteCustomize, setEnqueteCustomize } = enqueteCustomizeStates
  const item = enqueteCustomize[index]
  enqueteCustomize.splice(
    index,
    1,
    isToggle
      ? { ...item, isRequired: checked }
      : { ...item, questionName: value }
  )
  setEnqueteCustomize([...enqueteCustomize])
}

/**
 * タイトル入力欄の更新処理
 */
export const onChangeEnqueteTitle = (
  { target: { value } }: React.ChangeEvent<HTMLInputElement>,
  enqueteStates: {
    enquete: IEnquete
    setEnquete: React.Dispatch<React.SetStateAction<IEnquete>>
  }
): void => enqueteStates.setEnquete({ ...enqueteStates.enquete, title: value })

/**
 * 追加カード内onChangeイベントハンドラ
 */
export const onChangeAddCard = (
  enqueteFieldStates: {
    questionName: IEnqueteName
    setQuestionName: React.Dispatch<React.SetStateAction<IEnqueteName>>
  },
  e: React.ChangeEvent<HTMLInputElement>,
  type: IEnqueteType,
  changeType: ChangeAddCardType
): void => {
  const newQuestionName = { ...enqueteFieldStates.questionName }

  if (changeType === ChangeAddCardType.TITLE) {
    newQuestionName[type] = e.target.value
  } else if (changeType === ChangeAddCardType.REQUIRED) {
    newQuestionName[`${type}_required`] = e.target.checked
  }

  enqueteFieldStates.setQuestionName(newQuestionName)
}

/**
 * 選択項目から追加ボタンを押下した際
 */
export const onAddItem = (
  type: IEnqueteType,
  enqueteNameObj: IEnqueteName,
  enqueteFieldStates: {
    enqueteCustomize: IEnqueteCustomize[]
    setEnqueteCustomize: React.Dispatch<
      React.SetStateAction<IEnqueteCustomize[]>
    >
  },
  options: string[],
  setOptions: React.Dispatch<React.SetStateAction<string[]>>
) => {
  try {
    let questionName = ''
    let isRequired = false
    let newOptions: string[] | undefined = []
    const { enqueteCustomize, setEnqueteCustomize } = enqueteFieldStates
    switch (type) {
      case IEnqueteType.TEXT:
        questionName = checkFieldName(enqueteNameObj.text, enqueteCustomize)
        isRequired = enqueteNameObj.text_required
        break
      case IEnqueteType.TEXTAREA:
        questionName = checkFieldName(enqueteNameObj.textarea, enqueteCustomize)
        isRequired = enqueteNameObj.textarea_required
        break
      case IEnqueteType.RADIO:
      case IEnqueteType.CHECKBOX:
        questionName = checkFieldName(
          type === IEnqueteType.RADIO
            ? enqueteNameObj.radio
            : enqueteNameObj.checkbox,
          enqueteCustomize
        )
        isRequired =
          type === IEnqueteType.RADIO
            ? enqueteNameObj.radio_required
            : enqueteNameObj.checkbox_required

        if (options.length <= 1) {
          throw new Error(i18nValidation('options.moreThanOne'))
        }
        if (options.includes('')) {
          throw new Error(i18nValidation('options.includeEmpty'))
        }
        if (new Set(options).size !== options.length) {
          throw new Error(i18nValidation('options.duplication'))
        }
        newOptions = options
        setOptions([])
    }
    const customizeField: IEnqueteCustomize = {
      type,
      questionName,
      isRequired,
      options: newOptions,
    }
    setEnqueteCustomize([...enqueteCustomize, customizeField])
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * 項目名存在確認
 */
const checkFieldName = (
  questionName: string,
  enqueteCustomize: IEnqueteCustomize[]
): string => {
  if (!questionName) {
    throw new Error(i18nValidation('required.questionName'))
  }

  const existsFieldName = enqueteCustomize.find(
    (customizeField: IEnqueteCustomize) =>
      customizeField.questionName === questionName
  )
  if (existsFieldName) {
    throw new Error(i18nValidation('exist.questionName'))
  }

  return questionName
}

/**
 * 表示順のxボタンを押下した際
 */
export const onRemoveItem = (
  position: number,
  enquete: IEnquete,
  answers: IEnqueteAnswer[],
  customizeFieldStates: {
    enqueteCustomize: IEnqueteCustomize[]
    setEnqueteCustomize: React.Dispatch<
      React.SetStateAction<IEnqueteCustomize[]>
    >
  }
): void => {
  if (
    answers.length > 0 &&
    enquete.questions &&
    enquete.questions?.length - 1 >= position
  ) {
    alertService.show(false, i18nValidation('exist.hasAnswers'))
    return
  }

  customizeFieldStates.enqueteCustomize.splice(position, 1)
  customizeFieldStates.setEnqueteCustomize([
    ...customizeFieldStates.enqueteCustomize,
  ])
}

/**
 * 表示順のドラッグが終了した際
 */
export const onDragEnd = (
  { destination, source }: DropResult,
  customizeFieldStates: {
    enqueteCustomize: IEnqueteCustomize[]
    setEnqueteCustomize: React.Dispatch<
      React.SetStateAction<IEnqueteCustomize[]>
    >
  }
) => {
  if (!destination) return

  const items = (() => {
    const data = Array.from(customizeFieldStates.enqueteCustomize)
    const [removed] = data.splice(source.index, 1)
    data.splice(destination.index, 0, removed)
    return data
  })()
  customizeFieldStates.setEnqueteCustomize(items)
}

/**
 * アンケート作成 or 更新処理
 */
export const saveEnquete = async (
  enquete: IEnquete,
  enqueteCustomize: IEnqueteCustomize[],
  storeCache: IStoreCache,
  isCreate: boolean
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    if (isDuplicate(enqueteCustomize, 'questionName')) {
      throw new Error(i18nValidation('duplication.field'))
    }

    const { team } = storeCache

    enquete.questions = enqueteCustomize
    if (isCreate) {
      await store(team!, enquete)
    } else {
      enquete.updated_at = nowTimestamp()
      await update(team!, enquete)
    }

    alertService.show(true, i18nAlert('saved.enquete'))
  } catch (error) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, message)
  }
}
