import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEvent } from 'common/interfaces/event'
import { isLoggedIn } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedEvents } from 'providers/AuthProvider'
import { removeImageFile } from 'repositories/storage/firebase_event'
import { remove } from 'repositories/store/firebase_event'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * ドキュメントを削除する
 */
export const removeEvent = (event: IEvent, storeCache: IStoreCache) => {
  if (!isLoggedIn(storeCache)) return

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    try {
      event.image && (await removeImageFile(storeCache.team!, event))
    } catch (error: any) {
      if (error.code !== 'storage/object-not-found') {
        console.log(error)
        alertService.show(false, i18nAlert('deleted.fail.message'))
        return
      }
    }

    try {
      await remove(storeCache.team!, event)
      await reloadCachedEvents(storeCache)
      alertService.show(true, i18nAlert('deleted.message'))
    } catch (error) {
      console.log(error)
      alertService.show(false, i18nAlert('deleted.fail.message'))
    }
  })
}
