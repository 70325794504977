import { AuthType } from 'common/auth_type'
import { REGEXP_FORBID_FULLWIDTH } from 'common/const'
import { IInvite } from 'common/interfaces/invite'
import {
  IAdminLogin,
  ILogin,
  ILoginByJoinForUser,
} from 'common/interfaces/login'
import { ITeam } from 'common/interfaces/team'
import { ICustomizeFields } from 'common/interfaces/user'
import { checkPasswordComplex } from 'common/utils'
import { i18nAdminCommon, i18nValidation } from 'i18n/i18n'
import { checkRequiredCustomizeField } from './customize_field'

/**
 * 招待→アカウント作成フォーム用バリデーション
 * 前提としてtryの中で使用すること
 */
export const validateForm = (
  team: ITeam,
  invite: IInvite,
  authType: AuthType,
  joinData: ILogin | IAdminLogin,
  anyJoinData: ILoginByJoinForUser | null,
  customizeFields: ICustomizeFields[] | null | undefined,
  isAdmin: boolean,
  agreement: boolean
): void => {
  if (authType === AuthType.EMAIL && (!joinData.email || !joinData.password)) {
    throw new Error(i18nValidation('input.emailAndPassword'))
  }
  if (
    !checkPasswordComplex(isAdmin, team.is_password_complex, joinData.password)
  ) {
    throw new Error(i18nValidation('input.complexPassword'))
  }

  if (isAdmin && !(joinData as IAdminLogin).name) {
    throw new Error(i18nValidation('input.name'))
  }

  const { custom }: ILogin = joinData as ILogin
  if (authType === AuthType.CUSTOM) {
    if (!custom || !joinData.password) {
      throw new Error(i18nValidation('input.id.andPassword'))
    }

    if (REGEXP_FORBID_FULLWIDTH.test(custom)) {
      throw new Error(i18nValidation('input.id.halfWidth'))
    }
    if (REGEXP_FORBID_FULLWIDTH.test(joinData.password)) {
      throw new Error(i18nValidation('input.password.halfWidth'))
    }
  }

  if (anyJoinData) {
    for (const activateField of Object.keys(invite.activate_field)) {
      const tmpActivateField: any = invite.activate_field
      const tmpAnyJoinData: any = anyJoinData
      if (tmpActivateField[activateField] && !tmpAnyJoinData[activateField]) {
        let fieldName = ''
        switch (activateField) {
          case 'name':
            fieldName = i18nAdminCommon('name')
            break
          case 'phone':
            fieldName = i18nAdminCommon('phone')
            break
        }
        if (fieldName) {
          throw new Error(i18nValidation('input.args.fieldName', { fieldName }))
        }
      }
    }
  }

  checkRequiredCustomizeField(team, customizeFields || [])

  if (!agreement) throw new Error(i18nValidation('check.terms'))
}
