import { ICategory } from 'common/interfaces/category'
import { i18nValidation } from 'i18n/i18n'

/**
 * カテゴリー登録・編集用バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param category `ICategory` カテゴリードキュメント
 * @param isImageCropped boolean サムネイル設定時に切り取りボタンが押下されたかどうか
 */
export const validateForm = (
  category: ICategory,
  isImageCropped: boolean
): void => {
  if (!category.name) {
    throw new Error(i18nValidation('input.category'))
  }

  if (!isImageCropped) {
    throw new Error(i18nValidation('thumbnail.incompleteCut'))
  }
}
