import classNames from 'classnames'
import { TFunction } from 'i18next'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getVideoResume, setVideoResume } from 'services/local_storage'
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import hlsQualitySelector from 'videojs-hls-quality-selector'
import './hlsvideo.scss'

declare module 'video.js' {
  interface VideoJsPlayer {
    hlsQualitySelector: typeof hlsQualitySelector
  }
}

export interface TimeUpdateEvent {
  duration: number
  currentTime: number
  /** 0.0 ~ 1.0 */
  percent: number
}

interface IHlsVideo {
  className?: string
  hlsUrl: string
  fallbackMP4Urls?: string[]
  posterUrl: string
  chapterWebVttUrl?: string | null
  autoplay?: boolean
  resumable?: boolean
  /** default: `true` */
  seek?: boolean
  liveui?: {
    enable: boolean
    startTime?: number
  }
  oncePlay?: () => void
  onPlay?: () => void
  onTimeUpdate?: (e: TimeUpdateEvent) => void
  onPause?: () => void
  onEnded?: () => void
}

const HlsVideo: React.FC<IHlsVideo> = (props) => {
  const { t } = useTranslation('userVideo')
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const playerRef = useRef<VideoJsPlayer | null>(null)
  const resumeTimerRef = useRef<NodeJS.Timer | null>(null)

  useEffect(() => initLanguage(t), [t])

  useEffect(() => {
    if (playerRef.current || !videoRef.current) return
    playerRef.current = videojs(
      videoRef.current,
      videoJsOptions(props),
      function onPlayerReady() {
        initVideoControls(this)
        initHlsQualitySelector(this)
        initErrorHandling(this)
        resumeTimerRef.current = initResumeAutoPlay(this, props)
        initListeners(this, props)
        initLiveUi(this, props.liveui)
      }
    )
  }, [videoRef, props])

  useEffect(() => {
    return () => {
      cleanupVideoControls()
      playerRef.current?.dispose()
      playerRef.current = null
    }
  }, [playerRef])

  useEffect(() => {
    return () => {
      if (resumeTimerRef.current) {
        clearTimeout(resumeTimerRef.current)
        resumeTimerRef.current = null
      }
    }
  }, [resumeTimerRef])

  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <div className={props.className}>
      <div data-vjs-player>
        <video
          ref={videoRef}
          className={classNames('video-js', 'vjs-big-play-centered', {
            liveui: props.liveui?.enable,
          })}
        />
      </div>
    </div>
  )
  /* eslint-enable jsx-a11y/media-has-caption */
}

const videoJsOptions = (props: IHlsVideo): VideoJsPlayerOptions => {
  const isLive = Boolean(props.liveui?.enable)
  const canChangeSpeed = !isLive && (props.seek === undefined || props.seek)

  const remainingTimeDisplay = !isLive
  const seekBar = isLive ? false : props.seek
  const pictureInPictureToggle = !isLive
  const currentTimeDisplay = isLive
  const playbackRates = canChangeSpeed
    ? [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
    : []
  const preferFullWindow = isLive || !seekBar

  const options: VideoJsPlayerOptions = {
    controlBar: {
      captionsButton: false,
      subtitlesButton: false,
      remainingTimeDisplay,
      progressControl: { seekBar },
      pictureInPictureToggle,
      currentTimeDisplay,
      timeDivider: false,
      durationDisplay: false,
      descriptionsButton: false,
      subsCapsButton: false,
      audioTrackButton: false,
    },
    controls: true,
    playbackRates,
    playsinline: true,
    poster: props.posterUrl,
    sources: [
      {
        src: props.hlsUrl,
        type: 'application/x-mpegURL',
      },
    ],
    preferFullWindow,
  }
  if (props.fallbackMP4Urls) {
    const sources = props.fallbackMP4Urls.map((src) => ({
      src,
      type: 'video/mp4',
    }))
    options.sources!.push(...sources)
  }
  if (props.chapterWebVttUrl) {
    options.tracks = [{ kind: 'chapters', src: props.chapterWebVttUrl }]
  }
  return options
}

const initVideoControls = (player: VideoJsPlayer) => {
  window.hlsVideoPaused = () => player.paused()
  window.hlsVideoPlay = () => player.play()
  window.hlsVideoSeek = (time: number) => player.currentTime(time)
  window.hlsVideoCurrentTime = () => player.currentTime()
}

const cleanupVideoControls = () => {
  delete window.hlsVideoPaused
  delete window.hlsVideoPlay
  delete window.hlsVideoSeek
  delete window.hlsVideoCurrentTime
}

/**
 * Initialize resumable & autoplay
 * @returns Save resume time timer
 */
const initResumeAutoPlay = (
  player: VideoJsPlayer,
  props: IHlsVideo
): NodeJS.Timer | null => {
  const enableResumable = !props.liveui?.enable && props.resumable && props.seek

  player.one('loadedmetadata', () => {
    if (enableResumable) {
      const duration = Math.round(player.duration())
      const resumeTime = getVideoResume(props.hlsUrl)
      if (resumeTime && duration !== resumeTime) {
        player.currentTime(resumeTime)
      }
    }
    if (props.autoplay) {
      player.play()
    }
  })

  if (enableResumable) {
    player.on('ended', () => {
      setVideoResume(props.hlsUrl, Math.round(player.duration()))
    })
    return setInterval(() => {
      if (player.paused()) return
      const currentTime = Math.round(player.currentTime())
      setVideoResume(props.hlsUrl, currentTime)
    }, 5000)
  }
  return null
}

const initListeners = (player: VideoJsPlayer, props: IHlsVideo) => {
  player.one('play', () => props.oncePlay?.())
  player.on('play', () => props.onPlay?.())
  player.on('timeupdate', () => {
    if (!props.onTimeUpdate) return
    const duration = player.duration()
    const currentTime = player.currentTime()
    const percent = currentTime / duration
    props.onTimeUpdate({ duration, currentTime, percent })
  })
  player.on('pause', () => props.onPause?.())
  player.on('ended', () => props.onEnded?.())
}

const initHlsQualitySelector = (player: VideoJsPlayer) => {
  videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector)
  player.hlsQualitySelector({ displayCurrentQuality: true })
}

const initErrorHandling = (player: VideoJsPlayer) => {
  player.on('error', () => {
    const code = player.error()?.code ?? -1
    const sources = player.currentSources()
    if (
      (code === MediaError.MEDIA_ERR_DECODE ||
        code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) &&
      sources.length > 1
    ) {
      player.error(null)
      player.src(sources.slice(1))
      if (!player.paused() && !player.ended()) {
        player.play()
      }
    }
  })
}

const initLiveUi = (player: VideoJsPlayer, liveui: IHlsVideo['liveui']) => {
  if (!liveui?.enable) return

  let pausedUnixtime = -1
  player.on('play', () => {
    if (pausedUnixtime < 0) return
    const currentTime = player.currentTime()
    const nowTime = Math.floor(Date.now() / 1000)
    const newTime = currentTime + (nowTime - pausedUnixtime)
    player.currentTime(newTime)
  })
  player.on('pause', () => {
    pausedUnixtime = Math.floor(Date.now() / 1000)
  })

  if (liveui.startTime && liveui.startTime >= 0) {
    player.currentTime(liveui.startTime)
    player.play()
  }
}

const initLanguage = (t: TFunction<'userVideo', undefined>) => {
  videojs.addLanguage('ja', {
    Quality: t('hlsVideo.initLanguage.quality'),
    'The media could not be loaded, either because the server or network failed or because the format is not supported.':
      t('hlsVideo.initLanguage.waitForPrepare'),
    Chapters: t('hlsVideo.initLanguage.chapter'),
  })
}

export default HlsVideo
