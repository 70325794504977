import { ILive, LivePlatform } from 'common/interfaces/live'
import { IStripe } from 'common/interfaces/stripe'
import { VideoStatus } from 'common/interfaces/video'
import { makeCatalogLiveUrl, makeLiveUrl } from 'common/link_url'
import { standardModules } from 'common/quill'
import {
  copyClipboard,
  getDefaultPublicationPeriod,
  getStatusOptions,
  openNewTab,
} from 'common/utils'
import FromToDateTime from 'components/atoms/Form/FromToDateTime'
import LimitPlayInput from 'components/atoms/Form/LimitPlayInput'
import QuillForm from 'components/atoms/Input/QuillForm'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import RequiredText from 'components/atoms/Required/RequiredText'
import ThumbnailCropper from 'components/molecules/Admin/ThumbnailCropper'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap'
import {
  InputType,
  getFormOptions,
  getLiveDefaults,
  saveLive,
  onChangeInput as serviceOnChangeInput,
  onChangeIsPublic as serviceOnChangeIsPublic,
  onChangeIsRegistered as serviceOnChangeIsRegistered,
  onChangeLimitedAccess as serviceOnChangeLimitedAccess,
  onChangeStatus as serviceOnChangeStatus,
} from 'services/admin/live_form'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'
import { modalService } from 'services/modal'

interface IFormBodyProps {
  isCreate: boolean
  liveObj: ILive
  history: History
}

const FormBody: React.FC<IFormBodyProps> = ({
  isCreate,
  liveObj,
  history,
}: IFormBodyProps) => {
  const adminLive = useTranslation('adminLive')
  const adminCommon = useTranslation('adminCommon')
  const { storeCache } = useContext(AuthContext)
  const [live, setLive] = useState<ILive>(liveObj)
  const [imageBlob, setImageBlob] = useState<Blob>()
  const [isImageDelete, setIsImageDelete] = useState<boolean>(false)
  const [isImageCropped, setIsImageCropped] = useState<boolean>(true)
  const [isLimitPlayActive, setIsLimitPlayActive] = useState<boolean>(
    live.price_ids.length > 0 || live.is_registered
  )
  const [stripe, setStripe] = useState<IStripe>(initStripe())

  useEffect(() => {
    getStripe(setStripe)
  }, [])

  const userLiveUrl = makeLiveUrl(live.id, storeCache.team?.id ?? '')
  const copyUserLiveUrl = () => copyClipboard(userLiveUrl)
  const openQrCodeModal = () => modalService.showQrCode(userLiveUrl)
  const openUserLiveUrl = () => openNewTab(userLiveUrl)
  const publicLiveUrl = makeCatalogLiveUrl(live.id, storeCache.team?.id ?? '')
  const copyPublicLiveUrl = () => copyClipboard(publicLiveUrl)
  const openPublicLiveQrCodeModal = () => modalService.showQrCode(publicLiveUrl)

  const {
    defaultCategory,
    defaultCreator,
    defaultTags,
    defaultFilelists,
    defaultGroups,
    defaultPrices,
  } = getLiveDefaults(storeCache, live)

  const {
    tagOptions,
    filelistOptions,
    categoryOptions,
    creatorOptions,
    groupOptions,
    priceOptions,
  } = getFormOptions(storeCache)
  const defaultPublicationPeriod = getDefaultPublicationPeriod(live)

  const statusOptions = getStatusOptions().map((option, key) => (
    <option key={key} value={option.value}>
      {option.label}
    </option>
  ))

  const onChangeInput = (type: InputType, e: any) =>
    serviceOnChangeInput(live, setLive, type, e)

  const onChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeStatus(live, setLive, e)

  const onChangeLimitedAccess = (
    type: 'start' | 'end',
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeLimitedAccess(live, setLive, type, e)

  const onChangeIsPublic = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeIsPublic(live, setLive, e)

  const onChangeIsRegistered = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeIsRegistered(live, setLive, e)

  const save = () =>
    saveLive(
      history,
      live,
      imageBlob,
      isImageDelete,
      isImageCropped,
      storeCache,
      isCreate,
      isLimitPlayActive
    )

  return (
    <Form>
      {!isCreate && (
        <FormGroup row className="mb-5">
          <Col md="10">
            <Label for="userLiveUrlForm">
              <InfoTooltip
                content={adminLive.t('form.publishUrl.title')}
                tooltipContent={adminLive.t('form.publishUrl.tips')}
              />
            </Label>
            <InputGroup id="userLiveUrlForm">
              <Input
                value={userLiveUrl}
                readOnly
                onFocus={(e) => e.target.select()}
              />
              <InputGroupAddon addonType="append">
                <Button color="primary" onClick={copyUserLiveUrl}>
                  {adminCommon.t('copy')}
                </Button>
                <Button color="primary" outline onClick={openQrCodeModal}>
                  {adminCommon.t('qrCode')}
                </Button>
                <Button color="primary" outline onClick={openUserLiveUrl}>
                  {adminCommon.t('openAsNewTab')}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
      )}
      <FormGroup row>
        <Col md="10">
          <RequiredText labelFor="liveTitleForm" label>
            {adminLive.t('form.liveTitle')}
          </RequiredText>
          <Input
            id="liveTitleForm"
            value={live.name}
            onChange={(e) => onChangeInput(InputType.NAME, e)}
            type="text"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="4">
          <Label for="livePlatformForm">
            {adminLive.t('form.livePlatForm')}
          </Label>
          <Input
            id="livePlatformForm"
            value={live.platform}
            onChange={(e) => onChangeInput(InputType.PLATFORM, e)}
            type="select"
          >
            <option value={LivePlatform.ZOOM_MEETING}>Zoom Meeting</option>
            <option value={LivePlatform.ZOOM_WEBINAR}>Zoom Webinar</option>
            <option value={LivePlatform.YOUTUBE}>YouTube</option>
            <option value={LivePlatform.VIMEO_VIDEO}>Vimeo</option>
            <option value={LivePlatform.VIMEO_LIVE}>Vimeo Live</option>
            <option value={LivePlatform.VIMEO_WEBINAR}>Vimeo Webinar</option>
          </Input>
        </Col>
        {[LivePlatform.ZOOM_MEETING, LivePlatform.ZOOM_WEBINAR].includes(
          live.platform
        ) && (
          <>
            <Col md="3">
              <RequiredText labelFor="liveMeetingNumberForm" label>
                {live.platform === LivePlatform.ZOOM_MEETING
                  ? 'Zoom Meeting ID'
                  : 'Zoom Webinar ID'}
              </RequiredText>
              <Input
                id="liveMeetingNumberForm"
                value={live.meeting_number}
                onChange={(e) => onChangeInput(InputType.MEETING_NUMBER, e)}
                type="text"
              />
            </Col>
            <Col md="3">
              <Label for="liveMeetingPasswordForm">
                {adminLive.t('form.password')}
              </Label>
              <Input
                id="liveMeetingPasswordForm"
                value={live.meeting_password}
                onChange={(e) => onChangeInput(InputType.MEETING_PASSWORD, e)}
                type="text"
              />
            </Col>
          </>
        )}
        {live.platform === LivePlatform.YOUTUBE && (
          <Col md="6">
            <RequiredText labelFor="liveYouTubeVideoIdForm" label>
              YouTube Video ID: {live.youtube_video_id}
            </RequiredText>
            <Input
              id="liveYouTubeVideoIdForm"
              defaultValue={live.youtube_video_id}
              onChange={(e) => onChangeInput(InputType.YOUTUBE_VIDEO_ID, e)}
              type="text"
            />
          </Col>
        )}
        {[
          LivePlatform.VIMEO_VIDEO,
          LivePlatform.VIMEO_LIVE,
          LivePlatform.VIMEO_WEBINAR,
        ].includes(live.platform) && (
          <Col md="6">
            <RequiredText labelFor="liveVimeoIdForm" label>
              Vimeo ID: {live.vimeo_id}
            </RequiredText>
            <Input
              id="liveVimeoIdForm"
              defaultValue={live.vimeo_id}
              onChange={(e) => onChangeInput(InputType.VIMEO_ID, e)}
              type="text"
            />
          </Col>
        )}
      </FormGroup>
      <FromToDateTime
        from={live.hold_from}
        to={live.hold_to}
        onChange={(e, isFrom) =>
          onChangeInput(isFrom ? InputType.HOLD_FROM : InputType.HOLD_TO, e)
        }
      />
      <ThumbnailCropper
        type="live"
        croppedData={imageBlob}
        setCroppedData={setImageBlob}
        handleIsImageCropped={setIsImageCropped}
        edit={{ defaultImageUrl: live.image, isImageDelete, setIsImageDelete }}
      />
      <FormGroup row>
        <Col md="10">
          <Label for="liveCategoryForm">
            {adminLive.t('form.liveCategory')}
          </Label>
          <Input
            id="liveCategoryForm"
            value={defaultCategory?.id}
            onChange={(e) => onChangeInput(InputType.CATEGORY_ID, e)}
            type="select"
          >
            {categoryOptions}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="8">
          <Label for="liveTagsForm">{adminLive.t('form.liveTag.title')}</Label>
          <Select
            id="liveTagsForm"
            placeholder={adminLive.t('form.liveTag.placeholder')}
            defaultValue={defaultTags}
            onChange={(e) => onChangeInput(InputType.TAG_IDS, e)}
            options={tagOptions}
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="8">
          <Label for="liveFilelistsForm">
            {adminLive.t('form.liveFilelist.title')}
          </Label>
          <Select
            id="liveFilelistsForm"
            placeholder={adminLive.t('form.liveFilelist.placeholder')}
            defaultValue={defaultFilelists}
            onChange={(e) => onChangeInput(InputType.FILELIST_IDS, e)}
            options={filelistOptions}
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label>{adminLive.t('form.liveOverview')}</Label>
          <pre>
            <QuillForm
              value={live.overview}
              onChange={(e) => onChangeInput(InputType.OVERVIEW, e)}
              modules={standardModules()}
            />
          </pre>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="10">
          <Label for="liveCreatorForm">{adminLive.t('form.liveCreator')}</Label>
          <Input
            id="liveCreatorForm"
            value={defaultCreator?.id}
            onChange={(e) => onChangeInput(InputType.CREATOR_ID, e)}
            type="select"
          >
            {creatorOptions}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="4">
          <Label for="liveStatusForm">{adminLive.t('form.liveStatus')}</Label>
          <Input
            id="liveStatusForm"
            value={live.status}
            onChange={onChangeStatus}
            type="select"
          >
            {statusOptions}
          </Input>
        </Col>
      </FormGroup>

      {live.status === VideoStatus.PUBLICATION_PERIOD && (
        <FromToDateTime
          fromLabel={adminLive.t('form.publicationPeriod.from')}
          toLabel={adminLive.t('form.publicationPeriod.to')}
          from={defaultPublicationPeriod.start}
          to={defaultPublicationPeriod.end!}
          onChange={(e, isFrom) =>
            onChangeLimitedAccess(isFrom ? 'start' : 'end', e)
          }
        />
      )}

      {storeCache.team?.is_site_public && (
        <FormGroup check row className="mb-4">
          <Col md="10">
            <Label check>
              <Input
                defaultChecked={live.is_public}
                onChange={onChangeIsPublic}
                type="checkbox"
              />
              {adminLive.t('form.livePublic.title')}
              <div style={{ fontSize: '0.8rem' }}>
                {adminLive.t('form.livePublic.tips')}
              </div>
            </Label>
            {!isCreate && live.is_public && liveObj.is_public && (
              <div className="mt-2">
                <InputGroup>
                  <Input
                    value={publicLiveUrl}
                    readOnly
                    onFocus={(e) => e.target.select()}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="primary" onClick={copyPublicLiveUrl}>
                      {adminCommon.t('copy')}
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={openPublicLiveQrCodeModal}
                    >
                      {adminCommon.t('qrCode')}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            )}
          </Col>
        </FormGroup>
      )}

      <FormGroup check row className="mb-4">
        <Col md="10">
          <Label check>
            <Input
              defaultChecked={live.is_list_hidden}
              onChange={(e) => onChangeInput(InputType.IS_LIST_HIDDEN, e)}
              type="checkbox"
            />
            {adminLive.t('form.liveListHidden.title')}
            <div style={{ fontSize: '0.8rem' }}>
              {adminLive.t('form.liveListHidden.tips')}
            </div>
          </Label>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col md="8">
          <Label for="liveGroupForm">
            {adminLive.t('form.liveGroup.title')}
          </Label>
          <Select
            id="liveGroupForm"
            placeholder={adminLive.t('form.liveGroup.allGroup')}
            defaultValue={defaultGroups}
            onChange={(e) => onChangeInput(InputType.GROUP_IDS, e)}
            options={groupOptions}
            closeMenuOnSelect={false}
            isMulti
          />
        </Col>
      </FormGroup>

      <fieldset disabled={live.platform === LivePlatform.YOUTUBE}>
        <LimitPlayInput
          storeCache={storeCache}
          stripe={stripe}
          content={live}
          isLimitPlayActive={isLimitPlayActive}
          setIsLimitPlayActive={setIsLimitPlayActive}
          defaultPrices={defaultPrices}
          priceOptions={priceOptions}
          onChange={(e) => onChangeInput(InputType.PRICE_IDS, e)}
          onChangeIsRegistered={(e) => onChangeIsRegistered(e)}
        />
      </fieldset>

      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {adminCommon.t('save')}
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
