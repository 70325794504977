import { ColumnDef } from '@tanstack/react-table'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IUser } from 'common/interfaces/user'
import { IAnalyticsVideoWatchLog } from 'common/interfaces/video_watch_log'
import { dateFormat } from 'common/times'
import {
  duration2str,
  isAuthMethodEmail,
  isLoggedIn,
  userUid,
} from 'common/utils'
import { customFieldColumnDef } from 'components/atoms/Table/CreateTable'
import { i18nAdminAnalytics } from 'i18n/i18n'
import { getNewer } from 'repositories/store/firebase_user'
import { get as getVideoWatchLog } from 'repositories/store/firebase_video_watch_log'

/**
 * すべてのユーザーを取得する
 */
export const getUsers = async (
  storeCache: IStoreCache,
  setUsers: React.Dispatch<React.SetStateAction<IUser[]>>
) => {
  if (!isLoggedIn(storeCache)) return

  const users = await getNewer(storeCache.team!)
  setUsers(users)
}

/**
 * 全ユーザーの最大視聴時間ログを取得する
 */
export const getAllUserWatchLogs = async (
  storeCache: IStoreCache,
  setAllUserWatchLogs: React.Dispatch<
    React.SetStateAction<IAnalyticsVideoWatchLog[]>
  >,
  allUsers: IUser[]
) => {
  if (!isLoggedIn(storeCache)) return

  const getTasks = allUsers.map(
    async (user): Promise<IAnalyticsVideoWatchLog[]> => {
      const log = await getVideoWatchLog(storeCache.team!, user.id)
      return log?.map((l) => ({ ...l, user_id: user.id })) ?? []
    }
  )
  const allUserLogs = (await Promise.all(getTasks)).flat()

  const getMinStartMaxEndLog = (
    userId: string,
    videoId: string
  ): IAnalyticsVideoWatchLog | null => {
    const userVideoLogs = allUserLogs
      .filter((l) => l.user_id === userId && l.video_id === videoId)
      .sort((a, b) => a.started_at.seconds - b.started_at.seconds)
    if (userVideoLogs.length <= 0) return null

    const minStartOldestLog = userVideoLogs.sort((a, b) => a.start - b.start)[0]
    const maxEndOldestLog = userVideoLogs.sort((a, b) => b.end - a.end)[0]

    return {
      id: 'no-use-id',
      video_id: videoId,
      start: minStartOldestLog.start,
      end: maxEndOldestLog.end,
      started_at: minStartOldestLog.started_at,
      ended_at: maxEndOldestLog.ended_at,
      user_id: userId,
    }
  }

  const result = allUsers
    .map((user) =>
      storeCache.videos.map((video) => getMinStartMaxEndLog(user.id, video.id))
    )
    .flat()
    .filter((l): l is IAnalyticsVideoWatchLog => l !== null)
    .sort((a, b) => b.started_at.seconds - a.started_at.seconds)

  setAllUserWatchLogs(result)
}

/**
 * 全ユーザーの最大視聴時間ログを出力するCSVのカラム
 */
export const getAllUserWatchLogColumns = (
  storeCache: IStoreCache,
  allUsers: IUser[]
): ColumnDef<IAnalyticsVideoWatchLog>[] => {
  const userTable = i18nAdminAnalytics('userTable', { returnObjects: true })
  const watchLogTable = i18nAdminAnalytics('watchLogTable', {
    returnObjects: true,
  })

  const isAuthEmail = isAuthMethodEmail(storeCache.team!)

  return [
    {
      header: 'ID',
      accessorFn: (l) => (isAuthEmail ? l.user_id : userUid(l.user_id)),
    },
    {
      header: userTable.email,
      accessorFn: (l) => allUsers.find((u) => u.id === l.user_id)?.email ?? '',
    },
    {
      header: userTable.name,
      accessorFn: (l) => allUsers.find((u) => u.id === l.user_id)?.name ?? '',
    },
    ...customFieldColumnDef<IAnalyticsVideoWatchLog>(storeCache.team, allUsers),
    {
      header: watchLogTable.videoTitle,
      accessorFn: (l) =>
        storeCache.videos.find((v) => v.id === l.video_id)?.name ?? '',
    },
    {
      header: watchLogTable.playStartDate,
      accessorFn: (l) => dateFormat(l.started_at, watchLogTable.dateFormat),
    },
    {
      header: watchLogTable.playStartTime,
      accessorFn: (l) => duration2str(l.start, true),
    },
    {
      header: watchLogTable.playEndDate,
      accessorFn: (l) => dateFormat(l.ended_at, watchLogTable.dateFormat),
    },
    {
      header: watchLogTable.playEndTime,
      accessorFn: (l) => duration2str(l.end, true),
    },
    {
      header: watchLogTable.playDuration,
      accessorFn: (l) => duration2str(l.end - l.start, true),
    },
  ]
}
