import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICoupon } from 'common/interfaces/stripe/coupon'
import { isLoggedIn } from 'common/utils'
import { i18nAlert } from 'i18n/i18n'
import {
  deleteCoupon,
  getCoupons,
} from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * Stripeのクーポンを取得しStateに格納する
 */
export const getStripeCoupons = async (
  storeCache: IStoreCache,
  setData: React.Dispatch<React.SetStateAction<ICoupon[]>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    const { account_id } = storeCache.team!.stripe
    if (!account_id) return

    setData(await getCoupons(account_id))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}

/**
 * Stripeの特定のクーポンを削除する
 */
export const delCoupon = (
  storeCache: IStoreCache,
  couponId: string,
  setData: React.Dispatch<React.SetStateAction<ICoupon[]>>
): void => {
  if (!isLoggedIn(storeCache)) return

  try {
    modalService.show(i18nAlert('modal.confirm.delete'), async () => {
      const { account_id } = storeCache.team!.stripe
      if (!account_id) return

      await deleteCoupon(account_id, couponId)
      setData(await getCoupons(account_id))

      alertService.show(true, i18nAlert('deleted.message'))
    })
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
