import {
  ITopItemOrder,
  TopItemOrderType,
} from 'common/interfaces/top_item_order'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Button, Col, Row } from 'reactstrap'
import {
  ITEM_LIST,
  onAddItem as serviceOnAddItem,
  onChangeSelectedItem as serviceOnChangeSelectedItem,
  onDragEnd as serviceOnDragEnd,
  onRemoveItem as serviceOnRemoveItem,
  updateTeamTopItemOrder,
} from 'services/admin/site_customize_order'
import '../order-customize-style.scss'
import {
  AddColCardPopularVideo,
  AddColCardWithInput,
  AddColCardWoInput,
  ItemCard,
} from './ElementsOnTopItemOrder'

const FormBody: React.FC = () => {
  const { t } = useTranslation('adminSiteCustomize')
  const { storeCache } = useContext(AuthContext)
  const [selectedItems, setSelectedItems] = useState<ITopItemOrder[]>(
    storeCache.team?.item_order ?? []
  )

  const [addPopularGenreId, setAddPopularGenreId] = useState<string>(ITEM_LIST)
  const [addVideoCategoryId, setAddVideoCategoryId] =
    useState<string>(ITEM_LIST)
  const [addVideoTagId, setAddVideoTagId] = useState<string>(ITEM_LIST)
  const [addVideoCreatorId, setAddVideoCreatorId] = useState<string>(ITEM_LIST)

  const [addLiveCategoryId, setAddLiveCategoryId] = useState<string>(ITEM_LIST)
  const [addLiveTagId, setAddLiveTagId] = useState<string>(ITEM_LIST)
  const [addLiveCreatorId, setAddLiveCreatorId] = useState<string>(ITEM_LIST)

  const [addPlaylistCategoryId, setAddPlaylistCategoryId] =
    useState<string>(ITEM_LIST)
  const [addPlaylistTagId, setAddPlaylistTagId] = useState<string>(ITEM_LIST)

  // const [addEventId, setAddEventId] = useState<string>(ITEM_LIST)

  const [addPlaylistId, setAddPlaylistId] = useState<string>(ITEM_LIST)

  if (!storeCache.team) {
    return null
  }

  const onAddItem = (type: TopItemOrderType) =>
    serviceOnAddItem(
      type,
      {
        video: {
          addPopularGenreId,
          addCategoryId: addVideoCategoryId,
          addTagId: addVideoTagId,
          addCreatorId: addVideoCreatorId,
        },
        live: {
          addCategoryId: addLiveCategoryId,
          addTagId: addLiveTagId,
          addCreatorId: addLiveCreatorId,
        },
        playlist: {
          addCategoryId: addPlaylistCategoryId,
          addTagId: addPlaylistTagId,
        },
        addPlaylistId,
      },
      { selectedItems, setSelectedItems }
    )
  const onRemoveItem = (position: number) =>
    serviceOnRemoveItem(position, { selectedItems, setSelectedItems })
  const onChangeSelectedItem = (
    index: number,
    type: TopItemOrderType,
    targetId: string
  ) =>
    serviceOnChangeSelectedItem(index, type, targetId, {
      selectedItems,
      setSelectedItems,
    })
  const onDragEnd = (result: DropResult) =>
    serviceOnDragEnd(result, {
      selectedItems,
      setSelectedItems,
    })

  const update = () => updateTeamTopItemOrder(selectedItems, storeCache)

  return (
    <div className="order-customize-style">
      <Row>
        <Col md="4" className="all-items">
          <div className="scroll-box">
            <h2 className="mb-3">{t('topPageCustomize.video')}</h2>
            <Row>
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.newVideos')}
                onAdd={() => onAddItem(TopItemOrderType.VIDEOS_NEWER)}
              />
              <AddColCardPopularVideo
                onChange={setAddPopularGenreId}
                defaultValue={addPopularGenreId}
                onAdd={() =>
                  onAddItem(TopItemOrderType.VIDEOS_POPULAR_IN_GENRE)
                }
              />
              <AddColCardWithInput
                title={t('topPageCustomize.itemTitles.videoCategories')}
                onChange={setAddVideoCategoryId}
                defaultValue={addVideoCategoryId}
                options={storeCache.categories}
                onAdd={() => onAddItem(TopItemOrderType.VIDEOS_IN_CATEGORY)}
              />
              <AddColCardWithInput
                title={t('topPageCustomize.itemTitles.videoTags')}
                onChange={setAddVideoTagId}
                defaultValue={addVideoTagId}
                options={storeCache.tags}
                onAdd={() => onAddItem(TopItemOrderType.VIDEOS_IN_TAG)}
              />
              <AddColCardWithInput
                title={t('topPageCustomize.itemTitles.videoCreators')}
                onChange={setAddVideoCreatorId}
                defaultValue={addVideoCreatorId}
                options={storeCache.creators}
                onAdd={() => onAddItem(TopItemOrderType.VIDEOS_IN_CREATOR)}
              />
            </Row>
            <hr />
            <h2 className="mb-3">{t('topPageCustomize.live')}</h2>
            <Row>
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.newLives')}
                onAdd={() => onAddItem(TopItemOrderType.LIVES_NEWER)}
              />
              <AddColCardWithInput
                title={t('topPageCustomize.itemTitles.liveCategories')}
                onChange={setAddLiveCategoryId}
                defaultValue={addLiveCategoryId}
                options={storeCache.categories}
                onAdd={() => onAddItem(TopItemOrderType.LIVES_IN_CATEGORY)}
              />
              <AddColCardWithInput
                title={t('topPageCustomize.itemTitles.liveTags')}
                onChange={setAddLiveTagId}
                defaultValue={addLiveTagId}
                options={storeCache.tags}
                onAdd={() => onAddItem(TopItemOrderType.LIVES_IN_TAG)}
              />
              <AddColCardWithInput
                title={t('topPageCustomize.itemTitles.liveCreators')}
                onChange={setAddLiveCreatorId}
                defaultValue={addLiveCreatorId}
                options={storeCache.creators}
                onAdd={() => onAddItem(TopItemOrderType.LIVES_IN_CREATOR)}
              />
            </Row>
            <hr />
            <h2 className="mb-3">{t('topPageCustomize.playlist')}</h2>
            <Row>
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.newPlaylists')}
                onAdd={() => onAddItem(TopItemOrderType.PLAYLISTS_NEWER)}
              />
              <AddColCardWithInput
                title={t('topPageCustomize.itemTitles.playlistCategories')}
                onChange={setAddPlaylistCategoryId}
                defaultValue={addPlaylistCategoryId}
                options={storeCache.categories}
                onAdd={() => onAddItem(TopItemOrderType.PLAYLISTS_IN_CATEGORY)}
              />
              <AddColCardWithInput
                title={t('topPageCustomize.itemTitles.playlistTags')}
                onChange={setAddPlaylistTagId}
                defaultValue={addPlaylistTagId}
                options={storeCache.tags}
                onAdd={() => onAddItem(TopItemOrderType.PLAYLISTS_IN_TAG)}
              />
              <AddColCardWithInput
                title={t('topPageCustomize.itemTitles.playlists')}
                onChange={setAddPlaylistId}
                defaultValue={addPlaylistId}
                options={storeCache.playlists}
                onAdd={() => onAddItem(TopItemOrderType.VIDEOS_IN_PLAYLIST)}
              />
            </Row>
            <hr />
            <h2 className="mb-3">{t('topPageCustomize.event')}</h2>
            <Row>
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.newEvents')}
                onAdd={() => onAddItem(TopItemOrderType.EVENTS_NEWER)}
              />
            </Row>
            <hr />
            <h2 className="mb-3">{t('topPageCustomize.search')}</h2>
            <Row>
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.chooseContentTypes')}
                onAdd={() => onAddItem(TopItemOrderType.CHOOSE_CONTENT_TYPE)}
              />
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.chooseCategories')}
                onAdd={() => onAddItem(TopItemOrderType.CHOOSE_GENRE_CATEGORY)}
              />
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.chooseTags')}
                onAdd={() => onAddItem(TopItemOrderType.CHOOSE_GENRE_TAG)}
              />
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.chooseCreators')}
                onAdd={() => onAddItem(TopItemOrderType.CHOOSE_GENRE_CREATOR)}
              />
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.choosePlayStatus')}
                onAdd={() => onAddItem(TopItemOrderType.CHOOSE_PLAY_STATUS)}
              />
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.searchVideoInput')}
                onAdd={() => onAddItem(TopItemOrderType.SEARCH_VIDEO_INPUT)}
              />
            </Row>
            <hr />
            <h2 className="mb-3">{t('topPageCustomize.others')}</h2>
            <Row>
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.userBookmarks')}
                onAdd={() => onAddItem(TopItemOrderType.USER_BOOKMARKS)}
              />
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.userViewHistories')}
                onAdd={() => onAddItem(TopItemOrderType.USER_VIEW_LOGS)}
              />
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.divider')}
                onAdd={() => onAddItem(TopItemOrderType.DIVIDER)}
              />
              <AddColCardWoInput
                title={t('topPageCustomize.itemTitles.topImages')}
                onAdd={() => onAddItem(TopItemOrderType.TOP_IMAGES)}
              />
            </Row>
          </div>
        </Col>
        <Col md="8" className="selected-items">
          <div className="scroll-box">
            <h2>{t('topPageCustomize.topPageShowOrder')}</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {selectedItems.map((item, index) => {
                      const id = item.type + item.targetId + index
                      return (
                        <Draggable key={id} draggableId={id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ItemCard
                                item={item}
                                storeCache={storeCache}
                                index={index}
                                onChange={onChangeSelectedItem}
                                onRemove={onRemoveItem}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Col>
      </Row>

      <div className="text-center mt-3">
        <Button onClick={update} className="my-4" color="primary" type="button">
          {t('save')}
        </Button>
      </div>
    </div>
  )
}

export default FormBody
