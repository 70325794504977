import {
  HeaderItemOrderType,
  IHeaderItemOrder,
} from 'common/interfaces/header_item_order'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Row } from 'reactstrap'

/**
 * 選択項目の各追加カード
 */
export const AddCard: React.FC<{
  onAddItem: (type: HeaderItemOrderType) => void
}> = ({ onAddItem }) => {
  const { t } = useTranslation('adminSiteCustomize')
  const data = [
    {
      title: t('headerCustomize.cardTitles.divider'),
      type: HeaderItemOrderType.DIVIDER,
    },
    {
      title: t('headerCustomize.cardTitles.myAccount'),
      type: HeaderItemOrderType.MY_ACCOUNT,
    },
    {
      title: t('headerCustomize.cardTitles.viewHistories'),
      type: HeaderItemOrderType.USER_VIEW_LOGS,
    },
    {
      title: t('headerCustomize.cardTitles.bookmarks'),
      type: HeaderItemOrderType.USER_BOOKMARKS,
    },
    {
      title: t('headerCustomize.cardTitles.watchLater'),
      type: HeaderItemOrderType.USER_WATCH_LATER,
    },
    {
      title: t('headerCustomize.cardTitles.purchaseds'),
      type: HeaderItemOrderType.USER_PURCHASEDS,
    },
    {
      title: t('headerCustomize.cardTitles.logout'),
      type: HeaderItemOrderType.LOGOUT,
    },
    {
      title: t('headerCustomize.cardTitles.categories'),
      type: HeaderItemOrderType.CATEGORIES,
    },
    {
      title: t('headerCustomize.cardTitles.tags'),
      type: HeaderItemOrderType.TAGS,
    },
    {
      title: t('headerCustomize.cardTitles.playlists'),
      type: HeaderItemOrderType.PLAYLISTS,
    },
    {
      title: t('headerCustomize.cardTitles.creators'),
      type: HeaderItemOrderType.CREATORS,
    },
  ]

  return (
    <Row>
      {data.map((item, index) => (
        <Col key={index} xs="6" md="12" lg="6">
          <div className="add-card">
            <div>
              <div>{item.title}</div>
            </div>
            <Button
              className="add-button"
              onClick={() => onAddItem(item.type)}
              color="primary"
              size="sm"
              type="button"
            >
              {t('add')}
            </Button>
          </div>
        </Col>
      ))}
    </Row>
  )
}

/**
 * 表示順で使用する各コンテンツのカード
 */
export const ItemCard: React.FC<{
  item: IHeaderItemOrder
  index: number
  onRemove: (index: number) => void
}> = ({ item, index, onRemove }) => {
  const i18n = useTranslation('adminSiteCustomize').t(
    'headerCustomize.cardTitles',
    { returnObjects: true }
  )
  switch (item.type) {
    case HeaderItemOrderType.DIVIDER:
      return selectedCard(i18n.divider, index, onRemove)
    case HeaderItemOrderType.MY_ACCOUNT:
      return selectedCard(i18n.myAccount, index, onRemove)
    case HeaderItemOrderType.USER_VIEW_LOGS:
      return selectedCard(i18n.viewHistories, index, onRemove)
    case HeaderItemOrderType.USER_BOOKMARKS:
      return selectedCard(i18n.bookmarks, index, onRemove)
    case HeaderItemOrderType.USER_WATCH_LATER:
      return selectedCard(i18n.watchLater, index, onRemove)
    case HeaderItemOrderType.USER_PURCHASEDS:
      return selectedCard(i18n.purchaseds, index, onRemove)
    case HeaderItemOrderType.LOGOUT:
      return selectedCard(i18n.logout, index, onRemove)
    case HeaderItemOrderType.CATEGORIES:
      return selectedCard(i18n.categories, index, onRemove)
    case HeaderItemOrderType.TAGS:
      return selectedCard(i18n.tags, index, onRemove)
    case HeaderItemOrderType.PLAYLISTS:
      return selectedCard(i18n.playlists, index, onRemove)
    case HeaderItemOrderType.CREATORS:
      return selectedCard(i18n.creators, index, onRemove)
  }
}

/**
 * 表示順で使用するカードの生成
 */
const selectedCard = (
  title: string,
  index: number,
  onRemove: (index: number) => void
): JSX.Element => (
  <div className="placed-card">
    <Row>
      <Col xs="10">
        {index + 1}. {title}
      </Col>
      <Col xs="2" className="text-right">
        <i
          className="fas fa-times"
          style={{ cursor: 'default' }}
          onClick={() => onRemove(index)}
        />
      </Col>
    </Row>
  </div>
)
