import firebase from 'common/firebase'
import { History } from 'history'
import {
  i18nAdminEvent,
  i18nAdminInvite,
  i18nAdminSendEmail,
  i18nAdminVideo,
  i18nAlert,
  i18nCommon,
  i18nValidation,
} from 'i18n/i18n'
import React from 'react'
import { InputType as InputTypeHTML } from 'reactstrap/es/Input'
import { getCoupons } from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'
import {
  AuthMethodType,
  CouponDurationType,
  CurrencyType,
  EmailLogEvent,
  EventFormat,
  InvoiceStatusType,
  LangType,
  PaymentMethodType,
  PriceIntervalType,
  PriceType,
  SendEmailStatus,
} from './enums'
import { IStoreCache } from './interfaces/auth_provider'
import { ContentsOrderType } from './interfaces/contents_orders'
import { IEvent } from './interfaces/event'
import { IFieldCustomizeType } from './interfaces/field_customize'
import { IFilelist } from './interfaces/filelist'
import { IGroup } from './interfaces/group'
import { IInvite } from './interfaces/invite'
import { ILive } from './interfaces/live'
import { IPlaylist } from './interfaces/playlist'
import { IStripe } from './interfaces/stripe'
import { IPrice } from './interfaces/stripe/price'
import { IProduct } from './interfaces/stripe/product'
import { IProductInfo } from './interfaces/stripe/product_price'
import { ISubscription, ISubscriptionObject } from './interfaces/subscription'
import { ITag } from './interfaces/tag'
import { IFindTeam, ITeam } from './interfaces/team'
import { IUser } from './interfaces/user'
import {
  IPublicationPeriod,
  IVideo,
  IVideoPurchaseLead,
  VideoStatus,
} from './interfaces/video'
import { makeJoinUserPath, makeLoginTeamPath } from './link_path'
import { sortByContentsOrderType } from './sort_contents'
import {
  formatUNIXToDate,
  isTodayThanAfter,
  todayFirebaseTimestamp,
  utcDateFormat,
} from './times'

/**
 * 値がnullの場合、空文字に変換する
 */
export const null2str = (val: string | number | null | undefined): string =>
  val === null || val === undefined ? '' : String(val)

/**
 * 空の文字列の場合はnullを返却し、そうでなければそのまま返却する
 */
export const emptyStr2Null = (val: string): string | null =>
  val.trim() === '' ? null : val

/**
 * 空の数字文字列の場合はnullを返却し、そうでなければNumberとして返却する
 */
export const emptyNumStr2Null = (val: string): number | null =>
  val.trim() === '' ? null : Number(val)

/**
 * stringのhash値を返却する
 */
export const str2hash = (val: string): number => {
  let hash = 0
  for (let i = 0; i < val.length; i++) {
    const chr = val.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  return hash
}

/**
 * ブラウザの言語設定を取得する
 */
const browserLang = (): string =>
  (window.navigator.languages && window.navigator.languages[0]) ||
  window.navigator.language

/**
 * 言語文字列を取得する
 */
export const getLang = (): string => {
  switch (browserLang()) {
    case 'ja':
    case 'ja-JP':
      return i18nCommon('langs.japanese')
    default:
      return i18nCommon('langs.english')
  }
}

/**
 * 言語タイプを取得する
 */
export const getLangType = (): LangType => {
  switch (browserLang()) {
    case 'ja':
    case 'ja-JP':
      return LangType.JA
    default:
      return LangType.EN
  }
}

/**
 * Stripeで使う通貨文字列を取得する
 */
export const getCurrency = (): CurrencyType => {
  switch (browserLang()) {
    // 日本円
    case 'ja':
    case 'ja-JP':
      return CurrencyType.JPY
    // 米ドル
    default:
      return CurrencyType.USD
  }
}

/**
 * 金額フォーマット
 *
 * @param price number
 * @returns string
 */
export const formatPrice = (price: number, currency: CurrencyType): string => {
  switch (currency) {
    // 日本円
    case 'jpy':
      return i18nCommon('format.price.jpy', { price: price.toLocaleString() })
    // 米ドル
    default:
      price /= 100
      return `${price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })}`
  }
}

/**
 * クリップボードにテキストをコピーする
 */
export const copyClipboard = async (
  text: string,
  alertText = i18nAlert('copiedLink')
) => {
  await navigator.clipboard.writeText(text)
  alertService.show(true, alertText)
}

/**
 * URLを新しいタブで開く
 */
export const openNewTab = (url: string) => {
  window.open(url, '_blank')
}

/**
 * 動画の公開非公開の選択肢を生成する
 */
export const getStatusOptions = (): { label: string; value: number }[] => {
  const localize = i18nAdminVideo('status', {
    returnObjects: true,
  })
  return [
    { label: localize.private, value: VideoStatus.PRIVATE },
    { label: localize.public, value: VideoStatus.PUBLIC },
    {
      label: localize.publicationPeriod,
      value: VideoStatus.PUBLICATION_PERIOD,
    },
  ]
}

const createOptions = (
  array: ITag[] | IFilelist[] | IVideo[] | IGroup[]
): { value: string; label: string }[] =>
  array.map((arr) => ({ value: arr.id, label: arr.name }))

/**
 * react-selectで使用するタグの選択肢を生成する
 */
export const createTagOptions = (tags: ITag[]) =>
  createOptions(sortByContentsOrderType(tags, ContentsOrderType.NEWER))

/**
 * react-selectで使用するファイルの選択肢を生成する
 */
export const createFilelistOptions = (filelists: IFilelist[]) =>
  createOptions(
    filelists.sort((a, b) => b.created_at.seconds - a.created_at.seconds)
  )

/**
 * react-selectで使用する動画の選択肢を生成する
 */
export const createVideoOptions = (videos: IVideo[]) =>
  createOptions(sortByContentsOrderType(videos, ContentsOrderType.NEWER))

const createContent = (c: IVideo | ILive, isVideo: boolean) => ({
  id: c.id,
  name: isVideo
    ? i18nAdminEvent('list.table.contents.video', { videoName: c.name })
    : `LIVE：${c.name}`,
  status: c.status,
  image: c.image,
  category_id: c.category_id,
  tag_ids: c.tag_ids,
  group_ids: c.group_ids,
  created_at: c.created_at,
})

export const createContents = (videos: IVideo[], lives: ILive[]) =>
  videos
    .map((v) => createContent(v, true))
    .concat(lives.map((l) => createContent(l, false)))

/**
 * react-selectで使用する動画とLiveの選択肢を生成する
 */
export const createVideoAndLiveOptions = (videos: IVideo[], lives: ILive[]) =>
  createOptions(createContents(videos, lives) as IVideo[])

/**
 * react-selectで使用するグループの選択肢を生成する
 */
export const createGroupOptions = (groups: IGroup[]) =>
  createOptions(sortByContentsOrderType(groups, ContentsOrderType.NAME_ASC))

/**
 * react-selectで使用する商品マスタの選択肢を生成する
 */
export const createProductOptions = (
  products: IProduct[]
): { value: string; label: string }[] =>
  products
    .sort((a, b) => b.created - a.created)
    .map((p) => ({ value: p.id, label: p.name }))

/**
 * react-selectで使用する料金マスタの選択肢を生成する
 */
export const createPriceOptions = (
  prices: IPrice[]
): { value: string; label: string }[] =>
  prices
    .sort((a, b) => b.created - a.created)
    .map((p) => ({ value: p.id, label: generatePriceLabel(p, p.currency) }))

/**
 * generate price label for react-select
 */
export const generatePriceLabel = (
  { recurring, unit_amount, nickname, metadata }: IPrice,
  currency: CurrencyType
): string => {
  const kind = recurring
    ? i18nCommon('stripe.continuationStripeFormatInterval', {
        stripeInterval: formatInterval(recurring.interval),
      })
    : i18nCommon('stripe.lumpSum')
  const amount = formatPrice(unit_amount, currency)
  const name = nickname ?? i18nCommon('stripe.nonExplanation')
  const traial =
    metadata.trial && Number(metadata.trial) > 0
      ? i18nCommon('stripe.trialPeriodDate', {
          trialDate: metadata.trial,
        })
      : ''
  const validDays =
    metadata.valid_days && Number(metadata.valid_days) > 0
      ? i18nCommon('stripe.validPeriodDate', {
          validDate: metadata.valid_days,
        })
      : ''
  return `${kind}：${amount}：${name}${traial}${validDays}`
}

/**
 * 改行を<br>に置換する
 */
export const nl2br = (text: string | null): (string | JSX.Element)[] => {
  if (text === null) return []

  const regex = /(\n)/g
  return text.split(regex).map((line, index) => {
    if (line.match(regex)) return React.createElement('br', { key: index })
    return line
  })
}

/**
 * seconds to hh:mm:ss or mm:ss
 * @param seconds `number`
 * @param ignoreZero `boolean` if true, return `00:00` when seconds is 0, otherwise return `-`.
 * @returns Formatted string
 */
export const duration2str = (duration: number, ignoreZero = false): string => {
  if (duration <= 0 && !ignoreZero) return '-'

  const hour = Math.floor(duration / 3600)
  const minute = Math.floor((duration / 60) % 60)
  const second = (duration % 60).toString().padStart(2, '0')
  if (hour > 0) {
    const padMin = minute.toString().padStart(2, '0')
    return `${hour}:${padMin}:${second}`
  }
  return `${minute}:${second}`
}

/**
 * 表示することができるイベントを返却する
 *
 * @param storeCache `IStoreCache`
 * @returns `IEvent[]`
 */
export const showingEvents = ({
  events,
  videos,
  lives,
}: IStoreCache): IEvent[] =>
  events.filter(({ contents }) =>
    contents.some(
      (c) => videos.find((v) => v.id === c) || lives.find((l) => l.id === c)
    )
  )

/**
 * 表示することができるプレイリストを返却する
 *
 * プレイリスト内に自身のグループの動画があるもののみを返却
 * @param storeCache `IStoreCache`
 * @returns `IPlaylist[]`
 */
export const showingPlaylists = ({
  playlists,
  videos,
}: IStoreCache): IPlaylist[] =>
  playlists.filter((playlist) =>
    playlist.video_ids.some((videoId) => videos.find((v) => v.id === videoId))
  )

/**
 * Firebaseから返ってくるエラーを識別する
 * @param error firebase error
 * @returns error message string
 */
export const checkFirebaseError = (error: any): string => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return i18nValidation('firebaseError.emailAlreadyInUse')
    case 'auth/requires-recent-login':
      return i18nValidation('firebaseError.requireRecentLogin')
    case 'auth/wrong-password':
      return i18nValidation('firebaseError.wrongPassword')
    case 'auth/weak-password':
      return i18nValidation('firebaseError.weakPassword')
    case 'auth/invalid-email':
      return i18nValidation('firebaseError.invalidEmail')
    case 'auth/user-not-found':
      return i18nValidation('firebaseError.userNotFound')
    case 'auth/popup-closed-by-user':
      return i18nValidation('firebaseError.popupClosedByUser')
    case 'auth/operation-not-allowed':
      return i18nValidation('firebaseError.operationNotAllowed')
    default:
      return error.message
  }
}

/**
 * StripeAPIから返ってくるエラーを識別しアラートを表示する
 * StripeAPIのエラーに該当するキーは`error`で固定すること
 *
 * @param error any
 */
export const stripeAPIError = (error: any): void => {
  let message = error.message
  if (
    'response' in error &&
    'data' in error.response &&
    'error' in error.response.data
  ) {
    const resError = error.response.data.error
    if ('decline_code' in resError) {
      switch (resError.decline_code) {
        case 'generic_decline':
          message = i18nValidation('stripe.apiError.declinedPayment')
          break
        case 'insufficient_funds':
          message = i18nValidation('stripe.apiError.insufficientFunds')
          break
        case 'lost_card':
          message = i18nValidation('stripe.apiError.lostCard')
          break
        case 'stolen_card':
          message = i18nValidation('stripe.apiError.stolenCard')
          break
      }
    } else if ('code' in resError) {
      switch (resError.code) {
        case 'expired_card':
          message = i18nValidation('stripe.apiError.expiredCard')
          break
        case 'incorrect_cvc':
          message = i18nValidation('stripe.apiError.incorrectCVC')
          break
        case 'processing_error':
          message = i18nValidation('stripe.apiError.processingError')
          break
        case 'incorrect_number':
          message = i18nValidation('stripe.apiError.incorrectNumber')
          break
      }
    }
  }
  console.error(error)
  alertService.show(false, message)
}

/**
 * ログインしているかの判定。していない場合はアラートを表示。
 * @param storeCache `IStoreCache`
 * @param checkUser is check `storeCache.user`
 * @returns is logged in
 */
export const isLoggedIn = (
  storeCache: IStoreCache,
  checkUser = false
): boolean => {
  if (
    (checkUser && storeCache.team && storeCache.user) ||
    (!checkUser && storeCache.team)
  ) {
    return true
  }

  alertService.show(false, i18nAlert('auth.noLogin'))
  return false
}

/**
 * Storageに設定するmetadataを返却する
 *
 * `cache-control` 1週間
 * @returns `firebase.storage.UploadMetadata`
 */
export const storageMetadata = (): firebase.storage.UploadMetadata => ({
  cacheControl: 'private, max-age=604800',
})

/**
 * bytes to human readable
 *
 * @param bytes file size in bytes
 * @param isGB bytesの値に関わらずGB表示
 * @return string
 */
export const getFileSize = (bytes: number, isGB = false): string => {
  if (bytes <= 0) return '-'

  const kb = 1024
  const mb = kb ** 2
  const gb = kb ** 3
  const tb = kb ** 4

  const round = (size: number, unit: number): number =>
    Math.round((size / unit) * 100.0) / 100.0

  if (isGB) return `${round(bytes, gb)}GB`
  if (bytes >= tb) return `${round(bytes, tb)}TB`
  if (bytes >= gb) return `${round(bytes, gb)}GB`
  if (bytes >= mb) return `${round(bytes, mb)}MB`
  if (bytes >= kb) return `${round(bytes, kb)}KB`

  return i18nCommon('fileSize', { bytes })
}

/**
 * is live video
 *
 * @param content `IVideo | ILive`
 * @returns boolean
 */
export const isLiveVideo = (content: IVideo | ILive): boolean =>
  !('view_count' in content)

/**
 * get expiration date infomation
 *
 * @param team `ITeam`
 * @param currentPeriodEnd number
 * @returns string
 */
export const getExpirationAtInfo = (
  { name, stripe: { is_products_activate } }: ITeam,
  { nickname }: IPrice,
  currentPeriodEnd: number
): string =>
  is_products_activate
    ? i18nAlert('expirationInfo.expiredMessage', {
        date: formatUNIXToDate(currentPeriodEnd),
        name,
      })
    : i18nAlert('expirationInfo.enableMessage', {
        date: formatUNIXToDate(currentPeriodEnd),
        name: nickname,
      })

const existsPriceId = (
  subscriptionObj: ISubscriptionObject | null,
  priceIds: string[]
): boolean => {
  if (!subscriptionObj) return false
  return priceIds.some(
    (priceId) => subscriptionObj.subscription_item.price.id === priceId
  )
}

/**
 * Is Trial Plan
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @returns boolean
 */
export const isTrialPlan = (
  subscriptionObj: ISubscriptionObject | null
): boolean => !subscriptionObj

/**
 * Is Basic Price In Stripe
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @param stripe IStripe
 * @returns boolean
 */
export const isBasicPrice = (
  subscriptionObj: ISubscriptionObject | null,
  { basic_price_id }: IStripe
): boolean => existsPriceId(subscriptionObj, basic_price_id)

/**
 * Is Pro Price In Stripe
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @param stripe IStripe
 * @returns boolean
 */
export const isProPrice = (
  subscriptionObj: ISubscriptionObject | null,
  { pro_price_id }: IStripe
): boolean => existsPriceId(subscriptionObj, pro_price_id)

/**
 * Is Business Price In Stripe
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @param stripe IStripe
 * @returns boolean
 */
export const isBusinessPrice = (
  subscriptionObj: ISubscriptionObject | null,
  { business_price_id }: IStripe
): boolean => existsPriceId(subscriptionObj, business_price_id)

/**
 * Is Enterprise Price In Stripe
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @param stripe `IStripe`
 * @returns boolean
 */
export const isEnterprisePrice = (
  subscriptionObj: ISubscriptionObject | null,
  { enterprise_price_id }: IStripe
): boolean => existsPriceId(subscriptionObj, enterprise_price_id)

/**
 * Is Enterprise 400 Price In Stripe
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @param stripe `IStripe`
 * @returns boolean
 */
export const isEnterprise400Price = (
  subscriptionObj: ISubscriptionObject | null,
  { enterprise_400_price_id }: IStripe
): boolean => existsPriceId(subscriptionObj, enterprise_400_price_id)

/**
 * Is Enterprise 500 Price In Stripe
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @param stripe `IStripe`
 * @returns boolean
 */
export const isEnterprise500Price = (
  subscriptionObj: ISubscriptionObject | null,
  { enterprise_500_price_id }: IStripe
): boolean => existsPriceId(subscriptionObj, enterprise_500_price_id)

/**
 * Is Enterprise 600 Price In Stripe
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @param stripe `IStripe`
 * @returns boolean
 */
export const isEnterprise600Price = (
  subscriptionObj: ISubscriptionObject | null,
  { enterprise_600_price_id }: IStripe
): boolean => existsPriceId(subscriptionObj, enterprise_600_price_id)

/**
 * Is Enterprise 1000 Price In Stripe
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @param stripe `IStripe`
 * @returns boolean
 */
export const isEnterprise1000Price = (
  subscriptionObj: ISubscriptionObject | null,
  { enterprise_1000_price_id }: IStripe
): boolean => existsPriceId(subscriptionObj, enterprise_1000_price_id)

/**
 * Is Enterprise 1500 Price In Stripe
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @param stripe `IStripe`
 * @returns boolean
 */
export const isEnterprise1500Price = (
  subscriptionObj: ISubscriptionObject | null,
  { enterprise_1500_price_id }: IStripe
): boolean => existsPriceId(subscriptionObj, enterprise_1500_price_id)

/**
 * Is Enterprise 2000 Price In Stripe
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @param stripe `IStripe`
 * @returns boolean
 */
export const isEnterprise2000Price = (
  subscriptionObj: ISubscriptionObject | null,
  { enterprise_2000_price_id }: IStripe
): boolean => existsPriceId(subscriptionObj, enterprise_2000_price_id)

/**
 * Is Enterprise 2500 Price In Stripe
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @param stripe `IStripe`
 * @returns boolean
 */
export const isEnterprise2500Price = (
  subscriptionObj: ISubscriptionObject | null,
  { enterprise_2500_price_id }: IStripe
): boolean => existsPriceId(subscriptionObj, enterprise_2500_price_id)

/**
 * Is Enterprise 3000 Price In Stripe
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @param stripe `IStripe`
 * @returns boolean
 */
export const isEnterprise3000Price = (
  subscriptionObj: ISubscriptionObject | null,
  { enterprise_3000_price_id }: IStripe
): boolean => existsPriceId(subscriptionObj, enterprise_3000_price_id)

/**
 * 銀行口座払いなのか（管理者）
 *
 * @param subscriptionObj: ISubscriptionObject | null
 * @returns boolean
 */
export const isCustomerBalance = (
  subscriptionObj: ISubscriptionObject | null
): boolean =>
  (subscriptionObj &&
    subscriptionObj.subscription.payment_settings.payment_method_types &&
    subscriptionObj.subscription.payment_settings.payment_method_types.includes(
      PaymentMethodType.CUSTOMER_BALANCE
    )) as boolean

/**
 * 銀行口座払いなのか（管理者、キャッシュを使わない）
 *
 * @param subscription: ISubscription
 * @returns boolean
 */
export const isCustomerBalanceV2 = ({
  payment_settings: { payment_method_types },
}: ISubscription): boolean =>
  payment_method_types !== null &&
  (payment_method_types.includes(PaymentMethodType.CUSTOMER_BALANCE) as boolean)

/**
 * 銀行口座払いなのか（会員）
 *
 * @param user: IUser
 * @param subscriptionObj: ISubscriptionObject | null
 * @returns boolean
 */
export const isCustomerBalanceUser = (
  { admin }: IUser,
  subscriptionObj: ISubscriptionObject | null
): boolean => (!admin && isCustomerBalance(subscriptionObj)) as boolean

/**
 * get upload limit size of videos
 *
 * @returns number in bytes
 */
export const getVideoUploadLimit = (
  subscriptionObj: ISubscriptionObject | null,
  stripe: IStripe
): number => {
  const limit = (n: number) => n * 1024 * 1024 * 1024
  if (isBasicPrice(subscriptionObj, stripe)) return limit(25)
  if (isProPrice(subscriptionObj, stripe)) return limit(75)
  if (isBusinessPrice(subscriptionObj, stripe)) return limit(150)
  if (isEnterprisePrice(subscriptionObj, stripe)) return limit(300)
  if (isEnterprise400Price(subscriptionObj, stripe)) return limit(400)
  if (isEnterprise500Price(subscriptionObj, stripe)) return limit(500)
  if (isEnterprise600Price(subscriptionObj, stripe)) return limit(600)
  if (isEnterprise1000Price(subscriptionObj, stripe)) return limit(1000)
  if (isEnterprise1500Price(subscriptionObj, stripe)) return limit(1500)
  if (isEnterprise2000Price(subscriptionObj, stripe)) return limit(2000)
  if (isEnterprise2500Price(subscriptionObj, stripe)) return limit(2500)
  if (isEnterprise3000Price(subscriptionObj, stripe)) return limit(3000)
  return limit(3)
}

/**
 * get upload limit size of filelists
 *
 * @returns number in bytes
 */
export const getFilelistUploadLimit = (
  subscriptionObj: ISubscriptionObject | null,
  stripe: IStripe
): number => {
  const limit = (n: number) => n * 1024 * 1024 * 1024
  if (isBasicPrice(subscriptionObj, stripe)) return limit(2.5)
  if (isProPrice(subscriptionObj, stripe)) return limit(7.5)
  if (isBusinessPrice(subscriptionObj, stripe)) return limit(15)
  if (isEnterprisePrice(subscriptionObj, stripe)) return limit(30)
  if (isEnterprise400Price(subscriptionObj, stripe)) return limit(40)
  if (isEnterprise500Price(subscriptionObj, stripe)) return limit(50)
  if (isEnterprise600Price(subscriptionObj, stripe)) return limit(60)
  if (isEnterprise1000Price(subscriptionObj, stripe)) return limit(100)
  if (isEnterprise1500Price(subscriptionObj, stripe)) return limit(150)
  if (isEnterprise2000Price(subscriptionObj, stripe)) return limit(200)
  if (isEnterprise2500Price(subscriptionObj, stripe)) return limit(250)
  if (isEnterprise3000Price(subscriptionObj, stripe)) return limit(300)
  return limit(3)
}

/**
 * get total video size of team
 *
 * @returns number in bytes
 */
export const getAllVideoSize = (videos: IVideo[]): number =>
  videos.reduce((acc, video) => acc + video.bunny.storageSize, 0)

/**
 * get total filelist size of team
 *
 * @returns number in bytes
 */
export const getAllFilelistSize = (filelists: IFilelist[]): number =>
  filelists.reduce((acc, filelist) => acc + filelist.size, 0)

/**
 * can team create video?
 */
export const canCreateVideo = (
  { team, subscriptionObj, videos }: IStoreCache,
  stripe: IStripe
): boolean => {
  if (!team) return false

  const uploadLimit = getVideoUploadLimit(subscriptionObj, stripe)
  const totalVideoSize = getAllVideoSize(videos)
  return uploadLimit > totalVideoSize
}

/**
 * can team create filelist?
 */
export const canCreateFilelist = (
  { team, subscriptionObj, filelists }: IStoreCache,
  stripe: IStripe
): boolean => {
  if (!team) return false

  const uploadLimit = getFilelistUploadLimit(subscriptionObj, stripe)
  const totalfilelistsSize = getAllFilelistSize(filelists)
  return uploadLimit > totalfilelistsSize
}

/**
 * is customizeField enabled?
 */
export const isCustomizeFieldEnabled = (
  team: ITeam,
  invite: IInvite
): boolean =>
  team.customize_field !== undefined && invite.activate_customize_fields

/**
 * is it a duplicate? (for array of objects)
 */
export const isDuplicate = (array: any[], fieldName: string): boolean => {
  const duplication = Array.from(
    array
      .reduce(
        (previousValue, currentValue) =>
          previousValue.set(currentValue[fieldName], currentValue),
        new Map()
      )
      .values()
  )
  return [...new Set(duplication)].length !== array.length
}

/**
 * 公開日時のデフォルト値を返却する
 */
export const getDefaultPublicationPeriod = ({
  publication_period,
}: IVideo | ILive | IEvent): IPublicationPeriod => ({
  start: publication_period?.start ?? todayFirebaseTimestamp()[0],
  end: publication_period?.end ?? todayFirebaseTimestamp()[1],
})

/**
 * get type for input elem
 * @param title `string`
 * @returns InputTypeHTML
 */
export const inputType = (title: string): InputTypeHTML => {
  switch (title) {
    case 'birthdate':
    case IFieldCustomizeType.DATE:
      return 'date'
    case 'email':
      return 'email'
    case 'password':
      return 'password'
    case 'phone':
      return 'tel'
    case IFieldCustomizeType.TEXTAREA:
      return 'textarea'
    case IFieldCustomizeType.NUMBER:
      return 'number'
    case IFieldCustomizeType.BOOL:
      return 'checkbox'
    default:
      return 'text'
  }
}

/**
 * エラーアラートを出しつつ画面を一つ前に戻る
 *
 * @param history History
 * @param message string
 * @returns void
 */
export const goBackWithErrorAlert = (
  { goBack }: History,
  message: string
): void => {
  goBack()
  alertService.show(false, message)
}

/**
 * 請求期間フォーマット
 *
 * @param interval? PriceIntervalType
 * @returns string
 */
export const formatInterval = (
  interval: PriceIntervalType | undefined
): string => {
  switch (interval) {
    case undefined:
      return ''
    case PriceIntervalType.DAY:
      return i18nCommon('interval.day')
    case PriceIntervalType.WEEK:
      return i18nCommon('interval.week')
    case PriceIntervalType.MONTH:
      return i18nCommon('interval.month')
    case PriceIntervalType.YEAR:
      return i18nCommon('interval.year')
  }
}

/**
 * 請求期間フォーマット 2版
 *
 * @param interval? PriceIntervalType
 * @returns string
 */
export const formatIntervalV2 = (
  interval: PriceIntervalType | undefined,
  intervalCount?: number | undefined
): string => {
  switch (interval) {
    case undefined:
      return ''
    case PriceIntervalType.DAY:
      return intervalCount
        ? i18nCommon('intervalCount.days', { dayCount: intervalCount })
        : i18nCommon('intervalCount.day')
    case PriceIntervalType.WEEK:
      return intervalCount
        ? i18nCommon('intervalCount.weeks', { weekCount: intervalCount })
        : i18nCommon('intervalCount.week')
    case PriceIntervalType.MONTH:
      return intervalCount
        ? i18nCommon('intervalCount.months', { monthCount: intervalCount })
        : i18nCommon('intervalCount.month')
    case PriceIntervalType.YEAR:
      return intervalCount
        ? i18nCommon('intervalCount.years', { yearCount: intervalCount })
        : i18nCommon('intervalCount.year')
  }
}

/**
 * 支払いステータスフォーマット
 *
 * @param status InvoiceStatusType
 * @returns string
 */
export const formatInvoiceStatus = (status: InvoiceStatusType): string => {
  switch (status) {
    case InvoiceStatusType.DRAFT:
      return '下書き'
    case InvoiceStatusType.OPEN:
      return 'オープン'
    case InvoiceStatusType.VOID:
      return 'キャンセル済み'
    case InvoiceStatusType.PAID:
      return '支払い済み'
    case InvoiceStatusType.UNCOLLECTIBLE:
      return '回収不能'
    default:
      return ''
  }
}

/**
 * クーポンが引き換え後に適用される期間フォーマット
 *
 * @param duration CouponDurationType
 * @returns string
 */
export const formatCouponInterval = (duration: CouponDurationType): string => {
  switch (duration) {
    case CouponDurationType.FOREVER:
      return i18nCommon('duration.forever')
    case CouponDurationType.ONCE:
      return i18nCommon('duration.once')
    case CouponDurationType.REPEATING:
      return i18nCommon('duration.repeating')
  }
}

/**
 * クーポンが引き換え後に適用される期間を数字を合わせた文字列で返却する
 *
 * @param duration CouponDurationType
 * @param durationInMonths number | null
 * @returns string
 */
export const couponInterval2str = (
  duration: CouponDurationType,
  durationInMonths: number | null
): string => {
  switch (duration) {
    case CouponDurationType.FOREVER:
      return i18nCommon('duration.forever')
    case CouponDurationType.ONCE:
      return i18nCommon('duration.once')
    case CouponDurationType.REPEATING:
      return i18nCommon('duration.month', { month: durationInMonths })
  }
}

/**
 * get CheckoutSession Mode
 *
 * @param type `PriceType`
 * @returns 'payment' | 'setup' | 'subscription'
 */
export const getCheckoutSessionMode = (
  type: PriceType
): 'payment' | 'setup' | 'subscription' =>
  type === PriceType.ONE_TIME ? 'payment' : 'subscription'

/**
 * is password complex
 *
 * @param isAdmin boolean
 * @param isPasswordComplex boolean | undefined
 * @param password string
 * @returns boolean
 */
export const checkPasswordComplex = (
  isAdmin: boolean,
  isPasswordComplex: boolean | undefined,
  password: string
): boolean => {
  const reg = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/)
  if (!isAdmin && isPasswordComplex && !reg.test(password)) return false
  return true
}

/**
 * 会員必須且つ、チーム会員登録URL未設定の場合、チームログインURLに遷移する
 * 会員必須且つ、チーム会員登録URL設定済の場合、その会員登録URLに遷移する
 * 会員必須且つ、チーム会員登録URL設定済且つチーム会員登録URLが`/`で始まる場合、OneStream内でその会員登録URLに遷移する
 *
 * @param history `History`
 * @param team `ITeam`
 * @param state `IVideoPurchaseLead` | undefined
 */
export const transitionAuth = async (
  history: History,
  team: ITeam,
  productInfo: IProductInfo | null,
  state?: IVideoPurchaseLead
): Promise<void> => {
  try {
    if (state?.couponId) {
      const coupons = await getCoupons(team.stripe.account_id)
      const coupon = coupons.find(
        (c) =>
          (c.id === state.couponId && !c.redeem_by) ||
          (c.id === state.couponId &&
            c.redeem_by &&
            isTodayThanAfter(formatUNIXToDate(c.redeem_by))) ||
          (c.applies_to &&
            c.applies_to.products.length > 0 &&
            productInfo &&
            c.applies_to.products.find((p) => p === productInfo.product_id))
      )

      if (!coupon) throw new Error(i18nValidation('coupon.disabled'))
    }

    return history.push(
      team.urls.account_register
        ? makeJoinUserPath(team.urls.account_register)
        : makeLoginTeamPath(team.id),
      state
    )
  } catch (error: any) {
    alertService.show(false, error.message)
    console.error(error)
  }
}

/**
 * Sendgridのメール送信履歴イベントを日本語に変換する
 *
 * @param event `EmailLogEvent`
 * @returns `string`
 */
export const formatEmailEvent = (event: EmailLogEvent): string => {
  const i18n = i18nAdminInvite('emailLog.table.status', {
    returnObjects: true,
  })
  switch (event) {
    case EmailLogEvent.BOUNCE:
      return i18n.bounce
    case EmailLogEvent.DEFERRED:
      return i18n.deferred
    case EmailLogEvent.DELIVERED:
      return i18n.delivered
    case EmailLogEvent.DROPPED:
      return i18n.dropped
    case EmailLogEvent.PROCESSED:
      return i18n.processed
    default:
      return ''
  }
}

/**
 * イベント形式を日本語に変換する
 *
 * @param format `EventFormat`
 * @returns `string`
 */
export const formatEventFormat = (format: EventFormat): string => {
  switch (format) {
    case EventFormat.APPLICATION:
      return i18nAdminEvent('list.table.format.application')
    default:
      return ''
  }
}

/**
 * 送信メールステータスを日本語に変換する
 *
 * @param status `SendEmailStatus`
 * @returns `string`
 */
export const formatSendEmailStatus = (status: SendEmailStatus): string => {
  switch (status) {
    case SendEmailStatus.SPECIFIED_TIME:
      return i18nAdminSendEmail('list.table.status.specifiedTime')
    case SendEmailStatus.SENT:
      return i18nAdminSendEmail('list.table.status.sent')
    default:
      return ''
  }
}

/**
 * チームがID認証を採用しているか
 *
 * @param team `ITeam`
 * @returns `boolean`
 */
export const isAuthMethodEmail = ({
  auth_method,
}: ITeam | IFindTeam): boolean => auth_method === AuthMethodType.EMAIL

/**
 * 前提:チームがID認証を採用している
 * ユーザUIDからチームUIDを削除した文字列を取得
 *
 * @param uid `string`
 * @returns `string`
 */
export const userUid = (uid: string): string =>
  uid.split('_').slice(1).join('_')

/**
 * `e` と `-`の入力を受け付けないようにする
 *
 * @param e `React.KeyboardEvent<HTMLInputElement>`
 */
export const notOnKeyDownHyphen = (e: React.KeyboardEvent<HTMLInputElement>) =>
  ['e', '-'].includes(e.key) && e.preventDefault()

/**
 * 添付ファイルをダウンロードする
 */
export const downloadFile = (filelist: IFilelist): boolean => {
  try {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = () => {
      const blob = xhr.response
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', filelist.name)
      link.click()
    }
    xhr.open('GET', filelist.path)
    xhr.send()
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

/**
 * カレンダーのリンクを生成する
 */
export const createCalendarLink = (
  title: string,
  startTime: firebase.firestore.Timestamp,
  endTime: firebase.firestore.Timestamp,
  calendarType: 'google' | 'office'
): string => {
  const timeFormat =
    calendarType === 'google'
      ? 'YYYYMMDD[T]HHmmss[Z]' // google
      : 'YYYY-MM-DD[T]HH:mm:ss[Z]' // office

  const startDate = utcDateFormat(startTime, timeFormat)
  const endDate = utcDateFormat(endTime, timeFormat)

  const params = new URLSearchParams()
  if (calendarType === 'google') {
    params.append('action', 'TEMPLATE')
    params.append('text', title)
    params.append('dates', `${startDate}/${endDate}`)
  } else {
    params.append('path', '/calendar/action/compose')
    params.append('subject', title)
    params.append('startdt', startDate)
    params.append('enddt', endDate)
  }

  return calendarType === 'google'
    ? `https://www.google.com/calendar/render?${params.toString()}`
    : `https://outlook.live.com/owa/?${params.toString()}`
}
