import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { IPlaylist } from 'common/interfaces/playlist'
import ButtonRight from 'components/atoms/Button/Right'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  createOverviewContent,
  makeEditDeleteOperationButtons,
  makeTagBadges,
  makeThumbnailElement,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  getCategoryName,
  getVideoTitles,
  removePlaylist,
} from 'services/admin/playlist'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminPlaylist')
  const { playlists } = useContext(AuthContext).storeCache

  if (playlists.length <= 0) {
    return <RegisterItemButton type="playlist" />
  }
  return (
    <>
      <ButtonRight
        nextPage={Routes.AdminPlaylistCreate}
        content={t('list.new')}
      />
      <Table />
    </>
  )
}

const Table: React.FC = () => {
  const { t } = useTranslation('adminPlaylist')
  const { storeCache } = useContext(AuthContext)
  const { playlists } = storeCache
  const history = useHistory()

  const columns = useMemo<ColumnDef<IPlaylist>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => playlists.length - i,
      },
      {
        header: t('list.table.title'),
        accessorKey: 'name',
      },
      {
        header: t('list.table.thumbnail'),
        accessorFn: (p) => makeThumbnailElement(p.image, p.name, 'playlist'),
      },
      {
        header: t('list.table.category'),
        accessorFn: (p) => getCategoryName(storeCache, p),
      },
      {
        header: t('list.table.tag'),
        accessorFn: (p) => makeTagBadges(storeCache, p.tag_ids),
      },
      {
        header: t('list.table.video'),
        accessorFn: (p) => (
          <ol className="m-0 p-0 pl-3">
            {getVideoTitles(storeCache, p.video_ids).map((v, i) => (
              <li key={i}>{v}</li>
            ))}
          </ol>
        ),
      },
      {
        header: t('list.table.overview'),
        accessorFn: (p) => createOverviewContent(p.overview),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (p) =>
          makeEditDeleteOperationButtons<IPlaylist>(
            history,
            'playlist',
            Routes.AdminPlaylistEdit,
            (e) => removePlaylist(e, storeCache),
            p
          ),
      },
    ],
    [storeCache, history, playlists.length, t]
  )

  const data = playlists.sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  )
  return useTanstackTable<IPlaylist>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
