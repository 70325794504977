import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { isAuthMethodEmail, userUid } from 'common/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import H2 from './H2'

interface IAuthInfo {
  team: ITeam
  user: IUser
}

const AuthInfo: React.FC<IAuthInfo> = ({ team, user }: IAuthInfo) => {
  const { t } = useTranslation('adminCommon')
  if (!team) return null
  if (isAuthMethodEmail(team)) {
    return authInfoHead(t('email'), user.email)
  }
  return authInfoHead('ID', userUid(user.id))
}

const authInfoHead = (title: string, subtitle: string) => (
  <>
    <H2 title={title} />
    <H2 title={subtitle} />
  </>
)

export default AuthInfo
