import { ICreator } from 'common/interfaces/creator'
import { i18nValidation } from 'i18n/i18n'

/**
 * クリエイター登録・編集用バリデーション
 * 前提としてtryの中で使用すること
 *
 * @param creator `ICreator` クリエイタードキュメント
 * @param isImageCropped boolean サムネイル設定時に切り取りボタンが押下されたかどうか
 */
export const validateForm = (
  creator: ICreator,
  isImageCropped: boolean
): void => {
  if (!creator.name) {
    throw new Error(i18nValidation('input.creatorName'))
  }

  if (!isImageCropped) {
    throw new Error(i18nValidation('thumbnail.incompleteCut'))
  }
}
