import AccentButton from 'components/atoms/Button/Block/AccentButton'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from 'reactstrap'
import { sendPasswordResetEmail } from 'services/password_reset_request'

const FormBody: React.FC = () => {
  const { t } = useTranslation('common')
  const [email, setEmail] = useState<string>('')
  const sendEmail = () => sendPasswordResetEmail(email)

  return (
    <div className="main-content">
      <div className="header py-7 py-lg-8" />

      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-3">
                  {t('passwordReset.title')}
                </div>
                <div className="text-center mb-5">
                  <CardText>
                    <Trans t={t} i18nKey="passwordReset.description" />
                  </CardText>
                </div>

                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t('passwordReset.form.email')}
                        type="email"
                        autoComplete="new-email"
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <AccentButton
                      onClick={sendEmail}
                      className="my-4"
                      content={t('passwordReset.form.submit')}
                    />
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FormBody
