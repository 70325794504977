import { IEnquete } from 'common/interfaces/enquete'
import H1 from 'components/atoms/Head/H1'
import TableBody from 'components/organisms/Admin/EnqueteAnswer/TableBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Container } from 'reactstrap'

const EnqueteAnswer: React.FC = () => {
  const { t } = useTranslation('adminEnquete')
  const { state } = useLocation<IEnquete>()

  return (
    <>
      <H1 title={t('answer.title', { enqueteTitle: state.title })} />

      <Container fluid>
        <TableBody enquete={state} />
      </Container>
    </>
  )
}

export default EnqueteAnswer
