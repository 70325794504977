import { Routes } from 'common/enums'
import { ITag } from 'common/interfaces/tag'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/TagForm/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initTag } from 'services/admin/tag_form'

const TagForm: React.FC = () => {
  const { t } = useTranslation('adminTag')
  const history = useHistory()
  const location = useLocation<{ tag: ITag }>()
  const isCreate = location.pathname === Routes.AdminTagCreate
  const tag = isCreate ? initTag() : location.state.tag

  return (
    <>
      <H1 title={isCreate ? t('form.title.new') : t('form.title.edit')} />

      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminTag}
          content={t('form.backToList')}
        />
        <FormBody isCreate={isCreate} tagObj={tag} history={history} />
      </Container>
    </>
  )
}

export default TagForm
