import { IStoreCache } from 'common/interfaces/auth_provider'
import { IFormImage, IImage, ITeam } from 'common/interfaces/team'
import { checkFirebaseError, isLoggedIn } from 'common/utils'
import { i18nAlert, i18nCommon } from 'i18n/i18n'
import { reloadCachedTeam } from 'providers/AuthProvider'
import {
  FileType,
  removeImageFile,
  upload,
} from 'repositories/storage/firebase_team'
import {
  removeLogo,
  removeTopImage,
  updateLogo,
  updateTopImages,
} from 'repositories/store/firebase_team'
import { alertService } from 'services/alert'
import { v4 as uuidv4 } from 'uuid'

/**
 * Number of top image
 */
export const TOP_IMAGE_COUNT = 3

/**
 * TOP画像の初期値を返却する
 */
export const getTopImageDefaults = (team: ITeam) => {
  const defaultTopImages: IFormImage[] = team.top_images.map(
    (image: IImage) => ({
      ...image,
      newImage: undefined,
    })
  )
  for (let i = 0; i < TOP_IMAGE_COUNT - team.top_images.length; i++) {
    defaultTopImages.push({
      id: '',
      path: '',
      link: '',
      asNewTab: false,
      newImage: undefined,
    })
  }
  return defaultTopImages
}

/**
 * TOP画像の`onChange`イベントハンドラ
 */
export const onChangeTopImage = (
  topImages: IFormImage[],
  setTopImages: React.Dispatch<React.SetStateAction<IFormImage[]>>,
  e: React.ChangeEvent<HTMLInputElement>,
  index: number
) => {
  if (e.target.files) {
    const newTopImages = topImages.concat()
    newTopImages[index].newImage = e.target.files[0]
    setTopImages(newTopImages)
  }
}

/**
 * TOPリンクの`onChange`イベントハンドラ
 */
export const onChangeTopLink = (
  topImages: IFormImage[],
  setTopImages: React.Dispatch<React.SetStateAction<IFormImage[]>>,
  e: React.ChangeEvent<HTMLInputElement>,
  index: number
) => {
  const newTopImages = topImages.concat()
  newTopImages[index].link = e.target.value
  setTopImages(newTopImages)
}

/**
 * TOPリンクの新しいタブで開くcheckboxの`onChange`イベントハンドラ
 */
export const onChangeTopTab = (
  topImages: IFormImage[],
  setTopImages: React.Dispatch<React.SetStateAction<IFormImage[]>>,
  e: React.ChangeEvent<HTMLInputElement>,
  index: number
) => {
  const newTopImages = topImages.concat()
  newTopImages[index].asNewTab = e.target.checked
  setTopImages(newTopImages)
}

/**
 * ユーザのロゴ及びTOP画像更新処理
 */
export const updateImage = async (
  logo: File | undefined,
  topImages: IFormImage[],
  storeCache: IStoreCache
): Promise<void> => {
  const team = storeCache.team!
  try {
    if (logo) {
      const fileId = team.logo?.id || uuidv4()
      const obj: IImage = {
        id: fileId,
        path: await upload(team, logo, fileId, FileType.LOGO),
        link: '',
        asNewTab: false,
      }
      await updateLogo(team, obj)
    }

    for (const image of topImages) {
      if (image.id === '' && !image.newImage && image.link !== '') {
        alertService.show(false, i18nAlert('cantSetOnlyLink'))
        return
      }
    }

    const newTopImages: IImage[] = []
    for (let i = 0; i < topImages.length; i++) {
      if (topImages[i].id === '') {
        const id = uuidv4()
        newTopImages.push({
          id,
          path: await upload(
            team,
            topImages[i].newImage,
            id,
            FileType.TOP_IMAGES
          ),
          link: topImages[i].link,
          asNewTab: topImages[i].asNewTab,
        })
      } else {
        const id = topImages[i].id
        newTopImages.push({
          id,
          path: topImages[i].newImage
            ? await upload(team, topImages[i].newImage, id, FileType.TOP_IMAGES)
            : topImages[i].path,
          link: topImages[i].link,
          asNewTab: topImages[i].asNewTab,
        })
      }
    }
    const filteredTopImages = newTopImages.filter((nti) => nti.id && nti.path)

    await updateTopImages(team, filteredTopImages)
    await reloadCachedTeam(storeCache)

    alertService.show(true, i18nAlert('updated.siteImage'))
  } catch (error) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(false, i18nAlert('updated.fail.siteImage'))
  }
}

/**
 * チームの画像を削除する(ドキュメント更新 & ファイル削除)
 */
export const deleteFile = async (
  storeCache: IStoreCache,
  isLogo: boolean,
  fileId: string
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  const fieldName = isLogo ? i18nCommon('logo') : i18nCommon('topImage')

  try {
    if (isLogo) {
      await removeImageFile(storeCache.team!, fileId, FileType.LOGO)
      await removeLogo(storeCache.team!)
    } else {
      await removeImageFile(storeCache.team!, fileId, FileType.TOP_IMAGES)
      await removeTopImage(storeCache.team!, fileId)
    }

    await reloadCachedTeam(storeCache)

    alertService.show(true, i18nAlert('deleted.message', { file: fieldName }))
  } catch (error) {
    const message = checkFirebaseError(error)
    console.log(message)
    alertService.show(
      false,
      i18nAlert('deleted.fail.file', { file: fieldName })
    )
  }
}
