import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { findCachedGroupsNonNull } from 'common/find_store_cache'
import { IEnquete } from 'common/interfaces/enquete'
import { IEnqueteAnswer } from 'common/interfaces/enquete_answer'
import { IEnqueteType } from 'common/interfaces/enquete_customize'
import { IUser } from 'common/interfaces/user'
import { dateFormat } from 'common/times'
import { isAuthMethodEmail, userUid } from 'common/utils'
import NormalButton from 'components/atoms/Button/Normal'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import {
  customFieldColumnDef,
  useTanstackTableWithCsvExport,
} from 'components/atoms/Table/CreateTable'
import { makePrimaryBadges } from 'components/atoms/Table/ElementsOnTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { getEnqueteAnswersById } from 'services/admin/enquete_answer'
import { getNewerUsers } from 'services/admin/user'

interface ITableBody {
  enquete: IEnquete
}

const TableBody: React.FC<ITableBody> = ({ enquete }) => {
  const { t } = useTranslation('adminEnquete')
  const { storeCache } = useContext(AuthContext)

  const [answers, setAnswers] = useState<IEnqueteAnswer[]>([])
  const [users, setUsers] = useState<IUser[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await Promise.all([
        getEnqueteAnswersById(storeCache, enquete.id, setAnswers),
        getNewerUsers(setUsers, storeCache),
      ])
      setIsLoading(false)
    })()
  }, [storeCache, enquete.id])

  if (isLoading) return <LoadingSpinner />
  if (!answers) return null
  if (answers.length <= 0) return <>{t('answer.noAnswer')}</>
  return <Table enquete={enquete} answers={answers} users={users} />
}

const Table: React.FC<{
  enquete: IEnquete
  answers: IEnqueteAnswer[]
  users: IUser[]
}> = ({ enquete, answers, users }) => {
  const { t } = useTranslation('adminEnquete')
  const { storeCache } = useContext(AuthContext)
  const isAuthEmail = isAuthMethodEmail(storeCache.team!)

  const columns = useMemo<ColumnDef<IEnqueteAnswer>[]>(
    () => [
      {
        header: 'ID',
        accessorFn: (e) => e.id,
        meta: { hidden: true },
      },
      {
        header: 'No',
        accessorFn: (_, i) => answers.length - i,
      },
      {
        header: t('answer.table.userId'),
        accessorFn: (e) => (isAuthEmail ? e.user_id : userUid(e.user_id)),
        meta: { hidden: isAuthEmail },
      },
      {
        header: t('answer.table.email'),
        accessorFn: (e) => users.find((u) => u.id === e.user_id)?.email ?? '',
        meta: { hidden: !isAuthEmail },
      },
      {
        header: t('answer.table.name'),
        accessorFn: (e) => users.find((u) => u.id === e.user_id)?.name ?? '',
      },
      {
        header: t('answer.table.phone'),
        accessorFn: (e) => users.find((u) => u.id === e.user_id)?.phone ?? '',
      },
      {
        header: t('answer.table.groups'),
        accessorFn: (e) => {
          const groups = users.find((u) => u.id === e.user_id)?.group_ids ?? []
          return findCachedGroupsNonNull(storeCache, groups).map((g) => g.name)
        },
        cell: (cell) => makePrimaryBadges(cell.getValue<string[]>()),
      },
      ...customFieldColumnDef<IEnqueteAnswer>(storeCache.team, users),
      {
        header: t('answer.table.videoName'),
        accessorFn: (e) =>
          storeCache.videos.find((v) => v.id === e.video_id)?.name ?? '',
      },
      ...(enquete.questions?.map((q) => ({
        header: q.questionName,
        accessorFn: (e: IEnqueteAnswer) => {
          const answersObj = e.answers.find(
            (answersObj) => Object.keys(answersObj)[0] === q.questionName
          )
          if (!answersObj) return ''
          const value = Object.values(answersObj)[0]
          return q.type !== IEnqueteType.CHECKBOX ? value : value.join(',')
        },
      })) ?? []),
      {
        header: t('answer.table.createdAt'),
        accessorFn: (e) => dateFormat(e.created_at),
      },
    ],
    [storeCache, isAuthEmail, enquete, answers.length, users, t]
  )

  const data = answers.sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  )
  return useTanstackTableWithCsvExport<IEnqueteAnswer>(
    columns,
    data,
    'enquete_answer.csv',
    {},
    <BackButton />
  )
}

const BackButton: React.FC = () => {
  const { t } = useTranslation('adminEnquete')
  const history = useHistory()
  return (
    <NormalButton
      className="ml-3"
      onClick={() => history.push(Routes.AdminEnquete)}
      content={t('answer.back')}
    />
  )
}

export default TableBody
