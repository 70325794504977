import firebase from 'common/firebase'
import { ITeam } from 'common/interfaces/team'
import { IWatchlist, IWatchlistContent } from 'common/interfaces/watchlist'
import { nowTimestamp } from 'common/times'
import { Teams } from './firebase_team'

const Watchlists = (team: ITeam, userId: string) =>
  Teams.doc(team.id).collection('users').doc(userId).collection('watchlists')

const WATCH_LATER = 'watchlater'

const WatchLater = (team: ITeam, userId: string) =>
  Watchlists(team, userId).doc(WATCH_LATER)

/**
 * get watchlists
 */
export const get = async (
  team: ITeam,
  userId: string
): Promise<IWatchlist[]> => {
  const watchlistDatas = await Watchlists(team, userId)
    .orderBy('created_at', 'desc')
    .get()
  return watchlistDatas.docs.map((watchlist) => watchlist.data() as IWatchlist)
}

/**
 * get watch later
 */
export const getWatchLater = async (
  team: ITeam,
  userId: string
): Promise<IWatchlist | null> => {
  try {
    const watchlaterData = await WatchLater(team, userId).get()
    return watchlaterData.exists ? (watchlaterData.data() as IWatchlist) : null
  } catch (error) {
    console.log(error)
  }
  return null
}

/**
 * init watch later
 * @throws Firebase error
 */
export const initWatchLater = async (
  team: ITeam,
  userId: string
): Promise<void> => {
  const now = nowTimestamp()
  const watchlist: IWatchlist = {
    id: WATCH_LATER,
    name: '',
    contents: [],
    created_at: now,
    updated_at: now,
  }
  await WatchLater(team, userId).set(watchlist)
}

/**
 * add content in watch later
 * @throws Firebase error
 */
export const addContentInWatchLater = async (
  team: ITeam,
  userId: string,
  contentId: string
): Promise<void> => {
  const content: IWatchlistContent = {
    content_id: contentId,
    created_at: nowTimestamp(),
  }
  await WatchLater(team, userId).update({
    contents: firebase.firestore.FieldValue.arrayUnion(content),
  })
}

/**
 * delete content in watch later
 * @throws Firebase error
 */
export const deleteContentInWatchLater = async (
  team: ITeam,
  userId: string,
  contentId: string
): Promise<void> => {
  const watchLater = await getWatchLater(team, userId)
  const content = watchLater?.contents.find(
    (content) => content.content_id === contentId
  )
  if (!content) return

  await WatchLater(team, userId).update({
    contents: firebase.firestore.FieldValue.arrayRemove(content),
  })
}

/**
 * delete watchlist
 * @throws Firebase error
 */
export const remove = async (
  team: ITeam,
  userId: string,
  watchlistId: string
): Promise<void> => {
  await Watchlists(team, userId).doc(watchlistId).delete()
}

/**
 * delete All watchlist
 * @throws Firebase error
 */
export const removeAll = async (team: ITeam, userId: string): Promise<void> => {
  const watchlists = await get(team, userId)
  const removeTask = watchlists.map((watchlist) =>
    remove(team, userId, watchlist.id)
  )
  await Promise.all(removeTask)
}
