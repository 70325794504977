import { Routes } from 'common/enums'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/UserEdit/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'

const UserEdit: React.FC = () => {
  const { t } = useTranslation('adminUser')
  return (
    <>
      <H1 title={t('edit.title')} />

      <Container fluid>
        <ButtonRight nextPage={Routes.AdminUser} content={t('backToList')} />
        <FormBody />
      </Container>
    </>
  )
}

export default UserEdit
