import ChartBody from 'components/organisms/Admin/AnalyticsTotal/ChartBody'
import React from 'react'
import { Container } from 'reactstrap'

const AnalyticsTotal: React.FC = () => {
  return (
    <>
      <Container fluid className="pt-3">
        <ChartBody />
      </Container>
    </>
  )
}

export default AnalyticsTotal
