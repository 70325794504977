import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { findCachedGroupsNonNull } from 'common/find_store_cache'
import { IUser } from 'common/interfaces/user'
import { dateFormat } from 'common/times'
import { isAuthMethodEmail, userUid } from 'common/utils'
import NormalButton from 'components/atoms/Button/Normal'
import ButtonRight from 'components/atoms/Button/Right'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import {
  customFieldColumnDef,
  useTanstackTableWithCsvExport,
} from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeCustomOperationButtons,
  makePrimaryBadges,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap'
import { UpdateType, getApprovedNewerUsers } from 'services/admin/user'
import CreateUserModal from './CreateUserModal'
import PasswordChangeModal from './PasswordChangeModal'
import UserMultiSelectModal from './UserMultiSelectModal'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminUser')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [users, setUsers] = useState<IUser[]>([])
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([])
  const [userUpdateType, setUserUpdateType] = useState<UpdateType>(
    UpdateType.DISABLE
  )
  const [createUserModal, setCreateUserModal] = useState<boolean>(false)
  const toggleCreateUserModal = () => setCreateUserModal(!createUserModal)
  const [changePasswdUser, setChangePasswdUser] = useState<IUser | null>(null)
  const closeChangePasswdModal = () => setChangePasswdUser(null)
  const [userMultiSelectModal, setUserMultiSelectModal] =
    useState<boolean>(false)
  const toggleUserMultiSelectModal = (type?: UpdateType) => {
    if (type) setUserUpdateType(type)
    setUserMultiSelectModal(!userMultiSelectModal)
  }

  useEffect(() => {
    ;(async () => {
      await getApprovedNewerUsers(setUsers, storeCache)
      setIsLoading(false)
    })()
  }, [storeCache])

  if (isLoading) return <LoadingSpinner />

  const isAuthEmail = isAuthMethodEmail(storeCache.team!)

  const createUserModalEl = (
    <CreateUserModal
      isOpen={createUserModal}
      toggleModal={toggleCreateUserModal}
    />
  )

  if (users.length <= 0) {
    return isAuthEmail ? (
      <RegisterItemButton type="user" />
    ) : (
      <>
        <RegisterItemButton
          type="custom_user"
          onClick={toggleCreateUserModal}
        />
        {createUserModalEl}
      </>
    )
  }
  return (
    <>
      {isAuthEmail ? (
        <ButtonRight
          nextPage={Routes.AdminUserInviteCreate}
          content={t('list.newInvite')}
        />
      ) : (
        <Row className="justify-content-end mb-3 mr-1 mr-md-0">
          <NormalButton
            onClick={() => history.push(Routes.AdminUserInviteCreate)}
            content={t('list.newInvite')}
          />
          <NormalButton
            onClick={toggleCreateUserModal}
            content={t('list.newRegister')}
          />
          <NormalButton
            onClick={() => history.push(Routes.AdminUserImport)}
            content={t('list.onceImport')}
          />
        </Row>
      )}
      {createUserModalEl}
      <PasswordChangeModal
        targetUser={changePasswdUser}
        closeModal={closeChangePasswdModal}
      />

      <UserMultiSelectModal
        isOpen={userMultiSelectModal}
        toggleModal={toggleUserMultiSelectModal}
        type={userUpdateType}
        selectedUsers={selectedUsers}
      />

      {selectedUsers.length > 0 && (
        <Trans
          t={t}
          i18nKey="list.dropdown.action"
          components={[
            <UncontrolledDropdown>
              <DropdownToggle caret color="secondary">
                <></>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => toggleUserMultiSelectModal(UpdateType.DISABLE)}
                >
                  {t('list.dropdown.disabled')}
                </DropdownItem>
                <DropdownItem
                  onClick={() => toggleUserMultiSelectModal(UpdateType.ENABLE)}
                >
                  {t('list.dropdown.activate')}
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    toggleUserMultiSelectModal(UpdateType.ADDGROUPS)
                  }
                >
                  {t('list.dropdown.addGroup')}
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    toggleUserMultiSelectModal(UpdateType.EXPIRATION)
                  }
                >
                  {t('list.dropdown.expiration')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>,
          ]}
        />
      )}

      <Table
        users={users}
        openChangePasswordModal={setChangePasswdUser}
        onChangeSelectedUsers={setSelectedUsers}
      />
    </>
  )
}

const Table: React.FC<{
  users: IUser[]
  openChangePasswordModal: (user: IUser) => void
  onChangeSelectedUsers: (users: IUser[]) => void
}> = ({ users, openChangePasswordModal, onChangeSelectedUsers }) => {
  const { t } = useTranslation('adminUser')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()
  const isAuthEmail = isAuthMethodEmail(storeCache.team!)

  const columns = useMemo<ColumnDef<IUser>[]>(
    () => [
      {
        header: 'FullID',
        accessorKey: 'id',
        meta: { hidden: true },
      },
      {
        header: 'No',
        accessorFn: (_, i) => users.length - i,
      },
      {
        header: isAuthEmail ? t('list.table.email') : 'ID',
        accessorFn: (u) => (isAuthEmail ? u.email : userUid(u.id)),
        enableSorting: true,
        enableColumnFilter: true,
        meta: {
          columnFilterPlaceholder: isAuthEmail ? t('list.table.email') : 'ID',
        },
      },
      {
        header: t('list.table.name'),
        accessorFn: (u) => u.name ?? '',
        enableSorting: true,
        enableColumnFilter: true,
        meta: { columnFilterPlaceholder: t('list.table.name') },
      },
      {
        header: t('list.table.phone'),
        accessorFn: (u) => u.phone ?? '',
        enableSorting: true,
        enableColumnFilter: true,
        meta: { columnFilterPlaceholder: t('list.table.phone') },
      },
      {
        header: t('list.table.groups'),
        accessorFn: (u) =>
          findCachedGroupsNonNull(storeCache, u.group_ids).map((g) => g.name),
        cell: (cell) => makePrimaryBadges(cell.getValue<string[]>()),
        enableSorting: true,
      },
      {
        header: t('list.table.disabled'),
        accessorFn: (u) => (u.disabled ? t('list.table.yes') : ''),
        enableSorting: true,
      },
      {
        header: t('list.table.createdAt'),
        accessorFn: (u) => dateFormat(u.created_at),
        enableSorting: true,
      },
      {
        header: t('list.table.customerId'),
        accessorKey: 'customer_id',
        enableSorting: true,
        meta: { hidden: !storeCache.team?.stripe.account_id },
      },
      ...customFieldColumnDef<IUser>(storeCache.team!, users),
      {
        header: t('list.table.notifySetting'),
        accessorFn: (u) => (u.notification_setting.news ? 'ON' : 'OFF'),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (u) =>
          makeCustomOperationButtons([
            makeCustomOperationButton(t('list.table.edit'), () =>
              history.push({ pathname: Routes.AdminUserEdit, state: u })
            ),
            makeCustomOperationButton(t('list.table.viewHistory'), () =>
              history.push({ pathname: Routes.AdminUserViewLog, state: u })
            ),
            makeCustomOperationButton(t('list.table.paidHistory'), () =>
              history.push({ pathname: Routes.AdminUserPaidHistory, state: u })
            ),
            isAuthEmail ? (
              <></>
            ) : (
              makeCustomOperationButton(t('list.table.changePassword'), () =>
                openChangePasswordModal(u)
              )
            ),
          ]),
        meta: { csvExport: false },
      },
    ],
    [storeCache, history, isAuthEmail, users, openChangePasswordModal, t]
  )

  const data = users.sort((a, b) => b.created_at.seconds - a.created_at.seconds)
  return useTanstackTableWithCsvExport<IUser>(columns, data, 'users.csv', {
    fixedLastColumn: true,
    tableHeightSmall: true,
    enableMultiRowSelection: true,
    enableAllRowSelection: true,
    onChangeSelectedRows: onChangeSelectedUsers,
  })
}

export default TableBody
