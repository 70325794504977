import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import 'cropperjs/dist/cropper.css'
import React, { useState } from 'react'
import Cropper from 'react-cropper'
import { useTranslation } from 'react-i18next'
import { Button, Col, CustomInput, FormGroup, Label, Row } from 'reactstrap'

interface IThumbnailCropper {
  type: 'video' | 'playlist' | 'live' | 'event' | 'category' | 'tag' | 'creator'
  croppedData: Blob | undefined
  setCroppedData: React.Dispatch<React.SetStateAction<Blob | undefined>>
  edit?: {
    defaultImageUrl: string | null
    isImageDelete: boolean
    setIsImageDelete: React.Dispatch<React.SetStateAction<boolean>>
  }
  handleIsImageCropped: (isCropped: boolean) => void
}

const ThumbnailCropper: React.FC<IThumbnailCropper> = (
  props: IThumbnailCropper
) => {
  const i18n = useTranslation('adminCommon').t('thumbnailCropper', {
    returnObjects: true,
  })
  const [image, setImage] = useState<string>()
  const [cropper, setCropper] = useState<Cropper>()

  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.onload = () => {
        setImage(reader.result as any)
      }
      reader.readAsDataURL(e.target.files[0])

      props.setCroppedData(undefined)
      props.edit?.setIsImageDelete(false)
      props.handleIsImageCropped(false)
    }
  }

  const onCrop = () => {
    if (!cropper) return

    cropper
      .getCroppedCanvas()
      .toBlob((blob) => blob && props.setCroppedData(blob))
    props.handleIsImageCropped(true)
  }

  const tooltip = useTooltipText(props)
  const aspect = aspectRatio(props)

  const showCroppedPreview = props.croppedData && !props.edit
  const showDeletablePreview =
    props.edit &&
    !props.edit.isImageDelete &&
    (props.croppedData || props.edit.defaultImageUrl)

  return (
    <>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="thumbnailCropperForm">
              <InfoTooltip
                content={i18n.thumbnail.title}
                tooltipContent={tooltip}
              />
              {isShowThumbnailInfo(props) && (
                <div style={{ fontSize: '0.8rem' }}>
                  {i18n.thumbnail.caution}
                </div>
              )}
            </Label>
            <CustomInput
              id="thumbnailCropperForm"
              label={i18n.notSelectedFile}
              onChange={onChangeImage}
              type="file"
              accept="image/*"
            />
          </FormGroup>
        </Col>
      </Row>
      {image && !props.croppedData && (
        <Row className="mb-4">
          <Col md="6">
            <Cropper
              zoomTo={0.5}
              initialAspectRatio={aspect}
              aspectRatio={aspect}
              src={image}
              viewMode={1}
              dragMode="move"
              minCropBoxWidth={128}
              minCropBoxHeight={72}
              background={false}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => setCropper(instance)}
              responsive
              guides
            />
          </Col>
          <Col>
            <Button
              onClick={onCrop}
              style={{ position: 'absolute', bottom: 0 }}
              color="primary"
              type="button"
            >
              {i18n.cut}
            </Button>
          </Col>
        </Row>
      )}
      {showCroppedPreview && props.croppedData && (
        <Row className="mb-4">
          <Col md="4">
            <img
              width="auto"
              height="150"
              src={URL.createObjectURL(props.croppedData)}
              alt="cropped"
            />
          </Col>
        </Row>
      )}
      {showDeletablePreview && (
        <Row className="mb-4">
          <Col>
            <Label>{i18n.settedThumbnail}</Label>
            <Row>
              <Col md="4">
                <img
                  width="auto"
                  height="150"
                  src={
                    props.croppedData
                      ? URL.createObjectURL(props.croppedData)
                      : props.edit!.defaultImageUrl ?? undefined
                  }
                  alt="preview"
                />
              </Col>
              <Col md="8">
                <Button
                  onClick={() => props.edit!.setIsImageDelete(true)}
                  className="my-4"
                  color="danger"
                  type="button"
                >
                  {i18n.delete}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  )
}

const useTooltipText = ({ type }: IThumbnailCropper): string => {
  const i18n = useTranslation('adminCommon').t('thumbnailCropper.tooltipText', {
    returnObjects: true,
  })
  switch (type) {
    case 'video':
      return i18n.video
    case 'playlist':
      return i18n.playlist
    case 'live':
      return i18n.live
    case 'event':
      return i18n.event
    case 'category':
      return i18n.category
    case 'tag':
      return i18n.tag
    case 'creator':
      return i18n.creator
  }
}

const aspectRatio = ({ type }: IThumbnailCropper): number => {
  switch (type) {
    default:
      return 16 / 9
    case 'category':
    case 'tag':
    case 'creator':
      return 1
  }
}

const isShowThumbnailInfo = ({ type }: IThumbnailCropper): boolean =>
  type === 'video'

export default ThumbnailCropper
