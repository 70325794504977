import { db } from 'common/firebase'
import { IStripe } from 'common/interfaces/stripe'

const Stripes = () => db.collection('stripe')

/**
 * get stripe
 */
export const get = async (): Promise<IStripe> =>
  (await Stripes().get()).docs[0].data() as IStripe
