import classNames from 'classnames'
import React from 'react'
import 'react-quill/dist/quill.snow.css'

interface TQuillHtml {
  html: string | null
  editorClassName?: string
}

const QuillHtml: React.FC<TQuillHtml> = (props: TQuillHtml) => {
  /* eslint react/no-danger: off */
  return (
    <div className="ql-snow">
      <div
        className={classNames('ql-editor', props.editorClassName)}
        dangerouslySetInnerHTML={{ __html: props.html ?? '' }}
      />
    </div>
  )
}

export default QuillHtml
