import { Routes } from 'common/enums'
import Alert from 'components/atoms/Alert/Alert'
import Confirm from 'components/atoms/Alert/Confirm'
import ScrollToTop from 'components/atoms/Scroll/ScrollToTop'
import { AuthProvider } from 'providers/AuthProvider'
import { NonAuthProvider } from 'providers/NonAuthProvider'
import React from 'react'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import Admin from 'routes/Admin'
import Catalog from 'routes/Catalog'
import LoggedIn from 'routes/LoggedIn'
import User from 'routes/User'
import AnswerToEnquete from 'template/AnswerToEnquete'
import ChangePassword from 'template/ChangePassword'
import Entry from 'template/Entry'
import InvalidInviteCode from 'template/InvalidInviteCode'
import ItemSelect from 'template/ItemSelect'
import JoinAdministrator from 'template/JoinAdministrator'
import JoinUser from 'template/JoinUser'
import Login from 'template/Login'
import PasswordResetRequest from 'template/PasswordResetRequest'
import Register from 'template/Register'
import SamlLogin from 'template/SamlLogin'
import TeamLanding from 'template/TeamLanding'
import Top from 'template/Top'
import Unapproved from 'template/Unapproved'
import AdminItemSelect from 'template/admin/AdminItemSelect'
import ItemSelectContent from 'template/admin/ItemSelectContent'
import CreditCardUpdate from 'template/user/CreditCardUpdate'

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path={Routes.Root} component={() => <Top />} exact />
          <Route
            path={Routes.TeamLanding}
            component={() => <TeamLanding />}
            exact
          />
          <Route
            path={Routes.Catalog}
            component={() => (
              <NonAuthProvider>
                <Catalog />
              </NonAuthProvider>
            )}
          />
          <Route path={Routes.Register} component={() => <Register />} exact />
          <Route
            path={[Routes.LoginAdmin, Routes.LoginUser]}
            component={() => <Login />}
            exact
          />
          <Route
            path={Routes.SamlLogin}
            component={() => <SamlLogin />}
            exact
          />
          <Route
            path={Routes.PasswordResetRequest}
            component={() => <PasswordResetRequest />}
            exact
          />

          <Route
            path={Routes.JoinAdmin}
            component={() => <JoinAdministrator />}
            exact
          />
          <Route path={Routes.JoinUser} component={() => <JoinUser />} exact />
          <Route
            path={Routes.InvalidInviteCode}
            component={() => <InvalidInviteCode />}
            exact
          />

          <AuthProvider>
            <Switch>
              <Route
                path={[Routes.LoggedInAdmin, Routes.LoggedInUser]}
                component={() => <LoggedIn />}
              />
              <Route path={Routes.Admin} component={() => <Admin />} />
              <Route path={Routes.User} component={() => <User />} />
              <Route
                path={[Routes.EntryAdmin, Routes.EntryUser]}
                component={() => <Entry />}
                exact
              />
              <Route
                path={Routes.ItemSelect}
                component={() => <ItemSelect />}
                exact
              />
              <Route
                path={[Routes.AdminItemSelect, Routes.AdminItemChange]}
                component={() => <AdminItemSelect />}
                exact
              />
              <Route
                path={Routes.ItemSelectContent}
                component={() => <ItemSelectContent />}
                exact
              />
              <Route
                path={Routes.CreditCardUpdate}
                component={() => <CreditCardUpdate />}
                exact
              />
              <Route
                path={Routes.Unapproved}
                component={() => <Unapproved />}
                exact
              />
              <Route
                path={Routes.AnswerToEnqueteUser}
                component={() => <AnswerToEnquete />}
                exact
              />
              <Route
                path={Routes.ChangePassword}
                component={() => <ChangePassword />}
                exact
              />
            </Switch>
          </AuthProvider>
        </Switch>
      </BrowserRouter>
      <Alert />
      <Confirm />
    </>
  )
}

export default App
