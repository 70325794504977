import { ColumnDef } from '@tanstack/react-table'
import { IInvoice } from 'common/interfaces/stripe/Invoice'
import { formatUNIXToDate } from 'common/times'
import { formatInvoiceStatus, formatPrice } from 'common/utils'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPaidHistories } from 'services/admin/paid_history'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminPaidHistory')
  const { storeCache } = useContext(AuthContext)
  const [invoices, setInvoices] = useState<IInvoice[]>([])

  useEffect(() => {
    getPaidHistories(storeCache, setInvoices)
  }, [storeCache])

  if (invoices.length <= 0) return <>{t('list.noPaidHistory')}</>
  return <Table invoices={invoices} />
}

const Table: React.FC<{ invoices: IInvoice[] }> = ({ invoices }) => {
  const { t } = useTranslation('adminPaidHistory')
  const columns = useMemo<ColumnDef<IInvoice>[]>(
    () => [
      { header: 'No', accessorFn: (_, i) => invoices.length - i },
      { header: t('list.table.number'), accessorKey: 'number' },
      {
        header: t('list.table.price'),
        accessorFn: (i) => formatPrice(i.total, i.currency),
      },
      {
        header: t('list.table.status'),
        accessorFn: (i) => formatInvoiceStatus(i.status),
      },
      {
        header: t('list.table.invoice_pdf'),
        accessorFn: (i) => (
          <a href={i.invoice_pdf}>{t('list.table.download')}</a>
        ),
      },
      {
        header: t('list.table.hosted_invoice_url'),
        accessorFn: (i) => (
          <a
            href={i.hosted_invoice_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('list.table.download')}
          </a>
        ),
      },
      {
        header: t('list.table.status'),
        accessorFn: (i) => formatInvoiceStatus(i.status),
      },
      {
        header: t('list.table.createdAt'),
        accessorFn: (i) => formatUNIXToDate(i.created),
      },
    ],
    [invoices.length, t]
  )

  const data = invoices.sort((a, b) => b.created - a.created)
  return useTanstackTable<IInvoice>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
