import { Routes } from 'common/enums'
import { EffectiveDaysStatus, IFormInvite } from 'common/interfaces/invite'
import { IStripe } from 'common/interfaces/stripe'
import { makeJoinUserUrl } from 'common/link_url'
import { tomorrowDatetimeLocalFormat } from 'common/times'
import {
  copyClipboard,
  isBasicPrice,
  notOnKeyDownHyphen,
  openNewTab,
} from 'common/utils'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import RequiredText from 'components/atoms/Required/RequiredText'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import {
  saveInviteUser,
  onClickInviteList as serviceOnClickInviteList,
} from 'services/admin/invite'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'
import {
  InputType,
  getFormOptions,
  getInviteDefaultGroups,
  onChangeInfinity as serviceOnChangeInfinity,
  onChangeInput as serviceOnChangeInput,
} from 'services/admin/user_invite_form'

interface IFormBodyProps {
  isCreate: boolean
  inviteObj: IFormInvite
}

const FormBody: React.FC<IFormBodyProps> = (props) => {
  const { t } = useTranslation('adminInvite')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const defaultExpireDatetime = tomorrowDatetimeLocalFormat()
  const { defaultGroups } = getInviteDefaultGroups(storeCache, props.inviteObj)

  const [invite, setInvite] = useState<IFormInvite>(props.inviteObj)
  const [stripe, setStripe] = useState<IStripe>(initStripe())

  useEffect(() => {
    getStripe(setStripe)
  }, [])

  const isBasicPriceTeam = useMemo(
    () => isBasicPrice(storeCache.subscriptionObj, stripe),
    [storeCache, stripe]
  )

  const { groupOptions } = getFormOptions(storeCache)

  const onChangeInput = (type: InputType, e: any) =>
    serviceOnChangeInput(invite, setInvite, type, e)

  const onChangeInfinityCount = (isInfinity: boolean) =>
    serviceOnChangeInfinity(invite, setInvite, { count: isInfinity })

  const onChangeInfinityExpire = (isInfinity: boolean) =>
    serviceOnChangeInfinity(invite, setInvite, { expire: isInfinity })

  const onChangeInfinityeffEctivePeriod = (isInfinity: EffectiveDaysStatus) =>
    serviceOnChangeInfinity(invite, setInvite, { effective_days: isInfinity })

  const [isInviteSuccess, setIsInviteSuccess] = useState<boolean>(false)

  const anyFields: { title: string; type: InputType }[] = [
    { title: t('inviteForm.anyFields.name'), type: InputType.ACTIVATE_NAME },
    { title: t('inviteForm.anyFields.phone'), type: InputType.ACTIVATE_PHONE },
    {
      title: t('inviteForm.anyFields.customFields'),
      type: InputType.ACTIVATE_CUSTOMIZE_FIELDS,
    },
  ]

  const joinUrl = makeJoinUserUrl(invite.id)

  const save = () =>
    saveInviteUser(props.isCreate, invite, setIsInviteSuccess, storeCache)
  const copyUrl = () => copyClipboard(joinUrl)
  const openAsNewTab = () => openNewTab(joinUrl)
  const inviteMail = () =>
    history.push({ pathname: Routes.AdminInviteMail, state: invite })
  const onClickInviteList = () => serviceOnClickInviteList(history)

  return (
    <Form>
      <FormGroup row>
        <Col md="8">
          <RequiredText labelFor="nameForm" label>
            {t('inviteForm.title')}
          </RequiredText>
          <Input
            id="nameForm"
            disabled={isInviteSuccess}
            value={invite.name}
            onChange={(e) => onChangeInput(InputType.NAME, e)}
            type="text"
          />
        </Col>
      </FormGroup>
      <div className="mb-4">
        <Label>
          <InfoTooltip
            content={t('inviteForm.canRegisterPeoples.title')}
            tooltipContent={t('inviteForm.canRegisterPeoples.tips')}
          />
        </Label>
        <FormGroup check>
          <Label check>
            <Input
              disabled={isInviteSuccess}
              onChange={() => onChangeInfinityCount(true)}
              type="radio"
              checked={invite.count_infinity}
            />
            {t('inviteForm.unlimited')}
          </Label>
        </FormGroup>
        <FormGroup check className="mt-2">
          <Label check>
            <Input
              disabled={isInviteSuccess}
              onChange={() => onChangeInfinityCount(false)}
              type="radio"
              checked={!invite.count_infinity}
            />
            {t('inviteForm.limitPeoples')}
          </Label>
        </FormGroup>
        {!invite.count_infinity && (
          <Row className="mt-2">
            <Col md="2">
              <InputGroup>
                <Input
                  disabled={invite.count_infinity || isInviteSuccess}
                  onChange={(e) => onChangeInput(InputType.COUNT, e)}
                  defaultValue={invite.count}
                  type="number"
                  step="1"
                  min="0"
                  onKeyDown={notOnKeyDownHyphen}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    {t('inviteForm.peoplesCount')}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        )}
      </div>

      <div className="mb-4">
        <Label>
          <InfoTooltip
            content={t('inviteForm.registerDeadline.title')}
            tooltipContent={t('inviteForm.registerDeadline.tips')}
          />
        </Label>
        <FormGroup check>
          <Label check>
            <Input
              disabled={isInviteSuccess}
              onChange={() => onChangeInfinityExpire(true)}
              type="radio"
              checked={invite.expire_infinity}
            />
            {t('inviteForm.unlimited')}
          </Label>
        </FormGroup>
        <FormGroup check className="mt-2">
          <Label check>
            <Input
              disabled={isInviteSuccess}
              onChange={() => onChangeInfinityExpire(false)}
              type="radio"
              checked={!invite.expire_infinity}
            />
            {t('inviteForm.setExpire')}
          </Label>
        </FormGroup>
        {!invite.expire_infinity && (
          <Row className="mt-2">
            <Col md="5">
              <Input
                disabled={invite.expire_infinity || isInviteSuccess}
                onChange={(e) => onChangeInput(InputType.EXPIRE_DATETIME, e)}
                defaultValue={defaultExpireDatetime}
                type="datetime-local"
              />
            </Col>
          </Row>
        )}
      </div>

      <div className="mb-4">
        <Label>
          <InfoTooltip
            content={t('inviteForm.validPeriod.title')}
            tooltipContent={t('inviteForm.validPeriod.tips')}
          />
        </Label>
        <FormGroup check>
          <Label check>
            <Input
              disabled={isInviteSuccess || !props.isCreate}
              onChange={() =>
                onChangeInfinityeffEctivePeriod(EffectiveDaysStatus.INFINITY)
              }
              type="radio"
              checked={
                invite.effective_days_status === EffectiveDaysStatus.INFINITY
              }
            />
            {t('inviteForm.unlimited')}
          </Label>
        </FormGroup>
        <FormGroup check className="mt-2">
          <Label check>
            <Input
              disabled={isInviteSuccess || !props.isCreate}
              onChange={() =>
                onChangeInfinityeffEctivePeriod(EffectiveDaysStatus.DAYS)
              }
              type="radio"
              checked={
                invite.effective_days_status === EffectiveDaysStatus.DAYS
              }
            />
            {t('inviteForm.setValidPeriod')}
          </Label>
        </FormGroup>
        {invite.effective_days_status === EffectiveDaysStatus.DAYS && (
          <Row className="mt-2">
            <Col md="2">
              <InputGroup>
                <Input
                  disabled={
                    isInviteSuccess ||
                    invite.effective_days_status !== EffectiveDaysStatus.DAYS ||
                    !props.isCreate
                  }
                  onChange={(e) => onChangeInput(InputType.EFFECTIVE_DAYS, e)}
                  defaultValue={invite.effective_days}
                  type="number"
                  step="1"
                  min="0"
                  onKeyDown={notOnKeyDownHyphen}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>{t('inviteForm.days')}</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        )}
        <FormGroup check className="mt-2">
          <Label check>
            <Input
              disabled={isInviteSuccess || !props.isCreate}
              onChange={() =>
                onChangeInfinityeffEctivePeriod(EffectiveDaysStatus.PERIOD)
              }
              type="radio"
              checked={
                invite.effective_days_status === EffectiveDaysStatus.PERIOD
              }
            />
            {t('inviteForm.setExpiration')}
          </Label>
        </FormGroup>
        {invite.effective_days_status === EffectiveDaysStatus.PERIOD && (
          <Row className="mt-2">
            <Col md="5">
              <Input
                disabled={
                  isInviteSuccess ||
                  invite.effective_days_status !== EffectiveDaysStatus.PERIOD ||
                  !props.isCreate
                }
                onChange={(e) =>
                  onChangeInput(InputType.EFFECTIVE_DAYS_PERIOD, e)
                }
                defaultValue={defaultExpireDatetime}
                type="datetime-local"
              />
            </Col>
          </Row>
        )}
      </div>

      <Row>
        <Col md="8">
          <FormGroup>
            <Label for="joinGroupForm">
              <InfoTooltip
                content={t('inviteForm.joinGroups.title')}
                tooltipContent={t('inviteForm.joinGroups.tips')}
              />
            </Label>
            <Select
              id="joinGroupForm"
              placeholder={t('inviteForm.none')}
              defaultValue={defaultGroups}
              isDisabled={isInviteSuccess || !props.isCreate}
              onChange={(e) => onChangeInput(InputType.JOIN_GROUP_IDS, e)}
              options={groupOptions}
              closeMenuOnSelect={false}
              isMulti
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md="8">
          <FormGroup check inline>
            <Label check>
              <Input
                disabled={
                  isInviteSuccess || isBasicPriceTeam || !props.isCreate
                }
                onChange={(e) => onChangeInput(InputType.APPROVAL, e)}
                checked={invite.approval}
                type="checkbox"
              />
              {t('inviteForm.accountCreateAfterApproved.title')}
              <br />
              <div style={{ fontSize: '0.8rem' }}>
                {t('inviteForm.accountCreateAfterApproved.caution')}
              </div>
            </Label>
          </FormGroup>
        </Col>
      </Row>

      <div className="mb-4">
        <h2>{t('inviteForm.inputFields')}</h2>
        {anyFields.map((anyField, key) => (
          <FormGroup className="mb-2" check key={key}>
            <Row>
              <Col xs="6" md="4" lg="3">
                <Label for="checkForm">{anyField.title}</Label>
              </Col>
              <Col xs="6" md="8" lg="9">
                <label className="custom-toggle" id="checkForm">
                  <input
                    disabled={
                      isInviteSuccess ||
                      (anyField.type === InputType.ACTIVATE_CUSTOMIZE_FIELDS &&
                        isBasicPriceTeam) ||
                      !props.isCreate
                    }
                    type="checkbox"
                    onChange={(e) => onChangeInput(anyField.type, e)}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </Col>
            </Row>
            {anyField.type === InputType.ACTIVATE_CUSTOMIZE_FIELDS && (
              <div style={{ fontSize: '0.8rem' }}>
                <Trans
                  t={t}
                  i18nKey="inviteForm.customizeFieldCaution"
                  components={{
                    editLink: (
                      <Link
                        to={Routes.AdminFieldCustomize}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                    icon: <i className="fas fa-external-link-alt ml-1" />,
                  }}
                />
              </div>
            )}
          </FormGroup>
        ))}
      </div>

      <FormGroup row>
        <Col md="8">
          <Label for="commentForm">
            <InfoTooltip
              content={t('inviteForm.comment.title')}
              tooltipContent={t('inviteForm.comment.tips')}
            />
          </Label>
          <Input
            id="commentForm"
            disabled={isInviteSuccess}
            defaultValue={invite.comment}
            onChange={(e) => onChangeInput(InputType.COMMENT, e)}
            type="text"
          />
        </Col>
      </FormGroup>

      {isInviteSuccess ? (
        <div className="my-5">
          <Label for="joinUrl">{t('inviteForm.registerAndShareURL')}</Label>
          <InputGroup>
            <Input
              id="joinUrl"
              value={joinUrl}
              readOnly
              onFocus={(e) => e.target.select()}
            />
            <InputGroupAddon addonType="append">
              <Button color="primary" onClick={copyUrl}>
                {t('inviteForm.copy')}
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <div className="d-flex mt-4">
            <Button
              onClick={openAsNewTab}
              color="primary"
              type="button"
              size="sm"
            >
              {t('inviteForm.openNewTab')}
            </Button>
            <Button
              onClick={inviteMail}
              color="primary"
              type="button"
              size="sm"
            >
              {t('inviteForm.emailInvite')}
            </Button>
            <Button
              onClick={onClickInviteList}
              color="primary"
              type="button"
              size="sm"
            >
              {t('inviteForm.backToInviteList')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <Button onClick={save} className="my-4" color="primary" type="button">
            {props.isCreate ? t('inviteForm.create') : t('inviteForm.update')}
          </Button>
        </div>
      )}
    </Form>
  )
}

export default FormBody
