import AdminLogo from 'assets/images/admin_logo.png'
import { Routes } from 'common/enums'
import { makeLandingPath, makeUserRootPath } from 'common/link_path'
import ItemAction, { ItemActionType } from 'components/atoms/Nav/ItemAction'
import ItemCollapse from 'components/atoms/Nav/ItemCollapse'
import ItemLink from 'components/atoms/Nav/ItemLink'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from 'reactstrap'
import './sidebar.scss'

enum OpenType {
  VIDEO,
  // EVENT,
  NOTICE,
  FEEDBACK,
  USER,
  MASTER,
  BILLING,
  SETTING,
  ACCOUNT,
}

const Sidebar: React.FC = () => {
  const { t } = useTranslation('adminCommon')
  const { team, user } = useContext(AuthContext).storeCache
  const userUrl = makeUserRootPath(team?.id)
  const landingUrl = makeLandingPath(team?.id ?? '')

  const [collapseOpen, setCollapseOpen] = useState<boolean>(false)
  const sideBarToggle = () => setCollapseOpen(!collapseOpen)

  const [videoOpen, setVideoOpen] = useState<boolean>(isOpen(OpenType.VIDEO))
  const videoToggle = () => setVideoOpen(!videoOpen)

  // const [eventOpen, setEventOpen] = useState<boolean>(isOpen(OpenType.EVENT))
  // const eventToggle = () => setEventOpen(!eventOpen)

  const [noticeOpen, setNoticeOpen] = useState<boolean>(isOpen(OpenType.NOTICE))
  const noticeToggle = () => setNoticeOpen(!noticeOpen)

  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(
    isOpen(OpenType.FEEDBACK)
  )
  const feedbackToggle = () => setFeedbackOpen(!feedbackOpen)

  const [userOpen, setUserOpen] = useState<boolean>(isOpen(OpenType.USER))
  const userToggle = () => setUserOpen(!userOpen)

  const [masterOpen, setMasterOpen] = useState<boolean>(isOpen(OpenType.MASTER))
  const masterToggle = () => setMasterOpen(!masterOpen)

  const [billingOpen, setBillingOpen] = useState<boolean>(
    isOpen(OpenType.BILLING)
  )
  const billingToggle = () => setBillingOpen(!billingOpen)

  const [settingOpen, setSettingOpen] = useState<boolean>(
    isOpen(OpenType.SETTING)
  )
  const settingToggle = () => setSettingOpen(!settingOpen)

  const [accountOpen, setAccountOpen] = useState<boolean>(
    isOpen(OpenType.ACCOUNT)
  )
  const accountToggle = () => setAccountOpen(!accountOpen)

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white nav-container admin-navbar"
      expand="md"
      id="sidenav-main"
    >
      <NavbarBrand className="pt-0">
        <img className="navbar-brand-img" src={AdminLogo} alt="admin logo" />
      </NavbarBrand>
      <NavbarToggler onClick={sideBarToggle} className="mr-2" />
      <Container fluid>
        <Collapse navbar isOpen={collapseOpen}>
          <Nav navbar>
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={userUrl}
              iconName="fas fa-home"
              content={t('sidebar.openSite')}
              asNewTab
            />
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={landingUrl}
              iconName="fas fa-pager"
              content={t('sidebar.openLandingPage')}
              asNewTab
            />
          </Nav>

          <hr className="my-2" />

          <Nav navbar>
            <ItemCollapse
              content={t('sidebar.broadcastManagement.title')}
              isOpen={videoOpen}
              toggleCollapse={videoToggle}
            />
          </Nav>
          <Collapse isOpen={videoOpen} className="pl-2">
            <Nav navbar>
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminVideo}
                iconName="fab fa-youtube"
                content={t('sidebar.broadcastManagement.video')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminPlaylist}
                iconName="fas fa-photo-video"
                content={t('sidebar.broadcastManagement.playlist')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminLive}
                iconName="fas fa-broadcast-tower"
                content={t('sidebar.broadcastManagement.live')}
              />
            </Nav>
          </Collapse>

          {/* <Nav navbar>
            <ItemCollapse
              content="イベント管理"
              isOpen={eventOpen}
              toggleCollapse={eventToggle}
            />
          </Nav>
          <Collapse isOpen={eventOpen} className="pl-2">
            <Nav navbar>
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminEvent}
                iconName="fas fa-calendar"
                content="イベント"
              />
            </Nav>
          </Collapse> */}

          <Nav navbar>
            <ItemCollapse
              content={t('sidebar.noticeManagement.title')}
              isOpen={noticeOpen}
              toggleCollapse={noticeToggle}
            />
          </Nav>
          <Collapse isOpen={noticeOpen} className="pl-2">
            <Nav navbar>
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminNews}
                iconName="fas fa-newspaper"
                content={t('sidebar.noticeManagement.news')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminSendEmail}
                iconName="fas fa-paper-plane"
                content={t('sidebar.noticeManagement.sendMail')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminMailTemplate}
                iconName="fas fa-mail-bulk"
                content={t('sidebar.noticeManagement.mail')}
              />
            </Nav>
          </Collapse>

          <Nav navbar>
            <ItemCollapse
              content={t('sidebar.feedback.title')}
              isOpen={feedbackOpen}
              toggleCollapse={feedbackToggle}
            />
          </Nav>
          <Collapse isOpen={feedbackOpen} className="pl-2">
            <Nav navbar>
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminEnquete}
                iconName="far fa-question-circle"
                content={t('sidebar.feedback.enquete')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminTest}
                iconName="far fa-check-square"
                content={t('sidebar.feedback.test')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminComment}
                iconName="far fa-comment"
                content={t('sidebar.feedback.comment')}
              />
            </Nav>
          </Collapse>

          <Nav navbar>
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.AdminAnalytics}
              iconName="far fa-chart-bar"
              content={t('sidebar.analytics')}
            />
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.AdminSiteCustomize}
              iconName="fas fa-edit"
              content={t('sidebar.design')}
            />
          </Nav>

          <Nav navbar>
            <ItemCollapse
              content={t('sidebar.userManagement.title')}
              isOpen={userOpen}
              toggleCollapse={userToggle}
            />
          </Nav>
          <Collapse isOpen={userOpen} className="pl-2">
            <Nav navbar>
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminUser}
                iconName="fas fa-users"
                content={t('sidebar.userManagement.user')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminGroup}
                iconName="far fa-object-group"
                content={t('sidebar.userManagement.userGroup')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminAdministrator}
                iconName="fas fa-users-cog"
                content={t('sidebar.userManagement.administrator')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminInvite}
                iconName="far fa-envelope"
                content={t('sidebar.userManagement.invite')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminApprovalPendingAllUsers}
                iconName="fas fa-solid fa-user-check"
                content={t('sidebar.userManagement.approvalPendingAllUsers')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminFieldCustomize}
                iconName="fas fa-user-cog"
                content={t('sidebar.userManagement.fieldCustomize')}
              />
            </Nav>
          </Collapse>

          <Nav navbar>
            <ItemCollapse
              content={t('sidebar.masterManagement.title')}
              isOpen={masterOpen}
              toggleCollapse={masterToggle}
            />
          </Nav>
          <Collapse isOpen={masterOpen} className="pl-2">
            <Nav navbar>
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminCategory}
                iconName="far fa-folder-open"
                content={t('sidebar.masterManagement.category')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminTag}
                iconName="fas fa-tags"
                content={t('sidebar.masterManagement.tag')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminCreator}
                iconName="fas fa-at"
                content={t('sidebar.masterManagement.creator')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminFilelist}
                iconName="fas fa-photo-video"
                content={t('sidebar.masterManagement.file')}
              />
            </Nav>
          </Collapse>

          <Nav navbar>
            <ItemCollapse
              content={t('sidebar.billManagement.title')}
              isOpen={billingOpen}
              toggleCollapse={billingToggle}
            />
          </Nav>
          <Collapse isOpen={billingOpen} className="pl-2">
            <Nav navbar>
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminStripeProduct}
                iconName="fab fa-product-hunt"
                content={t('sidebar.billManagement.product')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminStripeCoupon}
                iconName="fas fa-ticket-alt"
                content={t('sidebar.billManagement.coupon')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminPayment}
                iconName="fas fa-file-invoice"
                content={t('sidebar.billManagement.payment')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminBilling}
                iconName="fas fa-wallet"
                content={t('sidebar.billManagement.settings')}
              />
            </Nav>
          </Collapse>

          <Nav navbar>
            <ItemCollapse
              content={t('sidebar.settings.title')}
              isOpen={settingOpen}
              toggleCollapse={settingToggle}
            />
          </Nav>
          <Collapse isOpen={settingOpen} className="pl-2">
            <Nav navbar>
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminBasicInfo}
                iconName="fas fa-sliders-h"
                content={t('sidebar.settings.site')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage="https://one-stream.oopy.io/"
                iconName="fas fa-info-circle"
                content={t('sidebar.manual')}
                asNewTab
                tag="a"
              />
            </Nav>
          </Collapse>

          <Nav navbar>
            <ItemCollapse
              content={t('sidebar.account.title')}
              isOpen={accountOpen}
              toggleCollapse={accountToggle}
            />
          </Nav>
          <Collapse isOpen={accountOpen} className="pl-2">
            <Nav navbar>
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminSetting}
                iconName="fas fa-user-circle"
                content={t('sidebar.account.profile')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminContractingPlan}
                iconName="fas fa-hand-holding-usd"
                content={t('sidebar.account.contractingPlan')}
              />
              <ItemLink
                setCollapseOpen={setCollapseOpen}
                nextPage={Routes.AdminPaidHistory}
                iconName="fas fa-yen-sign"
                content={t('sidebar.account.paymentHistory')}
              />
              <ItemAction
                action={ItemActionType.LOGOUT}
                iconName="fas fa-sign-out-alt"
                team={team!}
                user={user!}
              />
            </Nav>
          </Collapse>
        </Collapse>
      </Container>
    </Navbar>
  )
}

const isOpen = (type: OpenType): boolean => {
  const path = document.location.pathname
  switch (type) {
    case OpenType.VIDEO:
      return (
        path.startsWith(Routes.AdminVideo) ||
        path.startsWith(Routes.AdminPlaylist) ||
        path.startsWith(Routes.AdminLive)
      )
    // case OpenType.EVENT:
    //   return path.startsWith(Routes.AdminEvent)
    case OpenType.NOTICE:
      return (
        path.startsWith(Routes.AdminNews) ||
        path.startsWith(Routes.AdminSendEmail) ||
        path.startsWith(Routes.AdminMailTemplate)
      )
    case OpenType.FEEDBACK:
      return (
        path.startsWith(Routes.AdminEnquete) ||
        path.startsWith(Routes.AdminTest) ||
        path.startsWith(Routes.AdminComment)
      )
    case OpenType.USER:
      return (
        path.startsWith(Routes.AdminUser) ||
        path.startsWith(Routes.AdminGroup) ||
        path.startsWith(Routes.AdminAdministrator) ||
        path.startsWith(Routes.AdminInvite) ||
        path.startsWith(Routes.AdminFieldCustomize)
      )
    case OpenType.MASTER:
      return (
        path.startsWith(Routes.AdminCategory) ||
        path.startsWith(Routes.AdminTag) ||
        path.startsWith(Routes.AdminCreator) ||
        path.startsWith(Routes.AdminFilelist)
      )
    case OpenType.BILLING:
      return (
        path.startsWith(Routes.AdminStripeProduct) ||
        path.startsWith(Routes.AdminStripeCoupon) ||
        path.startsWith(Routes.AdminPayment) ||
        path.startsWith(Routes.AdminBilling)
      )
    case OpenType.SETTING:
      return path.startsWith(Routes.AdminBasicInfo)
    case OpenType.ACCOUNT:
      return (
        path.startsWith(Routes.AdminSetting) ||
        path.startsWith(Routes.AdminContractingPlan) ||
        path.startsWith(Routes.AdminPaidHistory)
      )
  }
}

export default Sidebar
