import { INews } from 'common/interfaces/news'
import { ITeam } from 'common/interfaces/team'
import { Teams } from './firebase_team'

const News = (team: ITeam) => Teams.doc(team.id).collection('news')

const isShowNews = (
  news: INews,
  userGroupIds: string[],
  isForUser: boolean
): boolean =>
  (isForUser &&
    (news.group_ids.length === 0 ||
      news.group_ids.some((id) => userGroupIds.some((ugid) => id === ugid)))) ||
  !isForUser

const getNews = async (
  team: ITeam,
  userGroupIds: string[],
  isForUser: boolean
): Promise<INews[]> => {
  const newsDatas = await News(team).orderBy('created_at', 'desc').get()
  return newsDatas.docs
    .map((n) => n.data() as INews)
    .filter((n) => isShowNews(n, userGroupIds, isForUser))
}

/**
 * get all news
 */
export const get = async (team: ITeam): Promise<INews[]> =>
  getNews(team, [], false)

/**
 * get news for user
 */
export const getForUser = async (
  team: ITeam,
  userGroupIds: string[]
): Promise<INews[]> => getNews(team, userGroupIds, true)

/**
 * add news
 * @throws Firebase error
 */
export const store = async (team: ITeam, news: INews): Promise<void> => {
  await News(team).doc(news.id).set(news)
}

/**
 * update news
 * @throws Firebase error
 */
export const update = async (team: ITeam, news: INews): Promise<void> => {
  await News(team).doc(news.id).update(news)
}

/**
 * delete news
 * @throws Firebase error
 */
export const remove = async (team: ITeam, news: INews): Promise<void> => {
  await News(team).doc(news.id).delete()
}
