import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import Footer from 'components/molecules/Footer'
import List from 'components/organisms/ItemSelect/List'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Container } from 'reactstrap'
import { signOut } from 'services/user/setting'

const ItemSelect: React.FC = () => {
  const { t } = useTranslation('itemSelect')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [loading, setLoading] = useState<boolean>(false)

  const { user, isLoading } = storeCache
  if (loading || user === null || isLoading) return <FullLoadingSpinner />

  return (
    <>
      <Container>
        <div className="text-right mr-5">
          {!user!.admin && (
            <Button
              onClick={() => signOut(history, storeCache)}
              className="mt-4"
              color="default"
              type="button"
            >
              {t('logout')}
            </Button>
          )}
        </div>
        <List setLoading={setLoading} />
      </Container>
      <Footer />
    </>
  )
}

export default ItemSelect
