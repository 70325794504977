import firebase from 'common/firebase'
import { IFilelist } from 'common/interfaces/filelist'
import { ILive } from 'common/interfaces/live'
import {
  ILog,
  ILogEnqueteAnswered,
  ILogFilelistDownloaded,
  ILogLivePageOpen,
  ILogLoggedIn,
  ILogLoggedOut,
  ILogTestAnswered,
  ILogVideoBookmark,
  ILogVideoCommented,
  ILogVideoEnded,
  ILogVideoPageOpen,
  ILogVideoPlay,
  ILogVideoReplyCommented,
  ILogVideoUnBookmark,
} from 'common/interfaces/log'
import { ITeam } from 'common/interfaces/team'
import { IUser } from 'common/interfaces/user'
import { IVideo } from 'common/interfaces/video'
import { isInTodayTime, nowTimestamp } from 'common/times'
import { Teams } from './firebase_team'

const Logs = (team: ITeam) => Teams.doc(team.id).collection('logs')

const makeLog = (data: firebase.firestore.DocumentData | undefined): ILog => {
  if (!data) {
    throw new Error('Log does not exist')
  }
  return {
    user_id: data.user_id,
    video_page_opens: data.video_page_opens ?? [],
    video_plays: data.video_plays ?? [],
    video_endeds: data.video_endeds ?? [],
    filelist_downloadeds: data.filelist_downloadeds ?? [],
    video_bookmarks: data.video_bookmarks ?? [],
    video_unbookmarks: data.video_unbookmarks ?? [],
    logged_ins: data.logged_ins ?? [],
    logged_outs: data.logged_outs ?? [],
    enquete_answereds: data.enquete_answereds ?? [],
    test_answereds: data.test_answereds ?? [],
    live_page_opens: data.live_page_opens ?? [],
    video_commenteds: data.video_commenteds ?? [],
    video_reply_commenteds: data.video_reply_commenteds ?? [],
    user_published_subscriptions: data.user_published_subscriptions ?? [],
  }
}

/**
 * get user log
 * @returns If log found, return `ILog` object. If not found, return `null`. If error, return `undefined`.
 */
export const get = async (
  team: ITeam,
  user: IUser
): Promise<ILog | null | undefined> => {
  try {
    const logData = await Logs(team).doc(user.id).get()
    if (logData.exists) {
      return makeLog(logData.data())
    }
    return null
  } catch (error) {
    console.log(error)
    return undefined
  }
}

/**
 * get all user logs
 */
export const getAll = async (team: ITeam): Promise<ILog[]> => {
  try {
    const logDatas = await Logs(team).get()
    return logDatas.docs.map((log) => makeLog(log.data()))
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * add log to logs collection
 * @param team `ITeam`
 * @param user `IUser`
 * @param log any interface of `ILog`
 * @param type `keyof Omit<ILog, 'user_id'>`
 * @param addLogConfirm Final confirmation function to add to logs
 *
 * eg. `(log) => log.video_page_opens.length < 10` if length < 10 then add to logs
 */
const addLog = async (
  team: ITeam,
  user: IUser,
  log: any,
  type: keyof Omit<ILog, 'user_id'>,
  addLogConfirm: (l: ILog) => boolean = () => true
): Promise<void> => {
  const writeLog = async (): Promise<boolean> => {
    const existsLog = await get(team, user)
    if (existsLog === undefined) {
      return false
    }
    if (existsLog && !addLogConfirm(existsLog)) {
      return true
    }

    try {
      await Logs(team)
        .doc(user.id)
        .set(
          {
            user_id: user.id,
            [type]: firebase.firestore.FieldValue.arrayUnion(log),
          },
          { merge: true }
        )
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }

  let retryCount = 0
  do {
    const isWritten = await writeLog()
    if (isWritten) {
      return
    }
    retryCount += 1
    await new Promise((resolve) => setTimeout(resolve, 10 * 1000))
  } while (retryCount < 6)
}

/**
 * add video logs
 * @param team `ITeam`
 * @param user `IUser`
 * @param video target `IVideo`
 * @param type 'video_page_opens' | 'video_plays' | 'video_endeds' | 'video_bookmarks' | 'video_unbookmarks'
 */
const addVideoLog = async (
  team: ITeam,
  user: IUser,
  video: IVideo,
  type:
    | 'video_page_opens'
    | 'video_plays'
    | 'video_endeds'
    | 'video_bookmarks'
    | 'video_unbookmarks'
): Promise<void> => {
  const videoLog:
    | ILogVideoPageOpen
    | ILogVideoPlay
    | ILogVideoEnded
    | ILogVideoBookmark
    | ILogVideoUnBookmark = {
    timestamp: nowTimestamp(),
    video_id: video.id,
  }

  await addLog(team, user, videoLog, type, (log) => {
    const isAlreadyAddedToday = log[type].some(
      (l) => isInTodayTime(l.timestamp) && l.video_id === video.id
    )
    return !isAlreadyAddedToday
  })
}

/**
 * add video page open log
 * @param team `ITeam`
 * @param user `IUser`
 * @param video target `IVideo`
 */
export const addVideoPageOpenLog = async (
  team: ITeam,
  user: IUser,
  video: IVideo
): Promise<void> => {
  await addVideoLog(team, user, video, 'video_page_opens')
}

/**
 * add video play log
 * @param team `ITeam`
 * @param user `IUser`
 * @param video target `IVideo`
 */
export const addVideoPlayLog = async (
  team: ITeam,
  user: IUser,
  video: IVideo
): Promise<void> => {
  await addVideoLog(team, user, video, 'video_plays')
}

/**
 * add video ended log
 * @param team `ITeam`
 * @param user `IUser`
 * @param video target `IVideo`
 */
export const addVideoEndedLog = async (
  team: ITeam,
  user: IUser,
  video: IVideo
): Promise<void> => {
  await addVideoLog(team, user, video, 'video_endeds')
}

/**
 * add filelist downloaded log
 * @param team `ITeam`
 * @param user `IUser`
 * @param video target `IVideo`
 * @param filelist `IFilelist`
 */
export const addFilelistDownloadedLog = async (
  team: ITeam,
  user: IUser,
  video: IVideo,
  filelist: IFilelist
): Promise<void> => {
  const filelistLog: ILogFilelistDownloaded = {
    video_id: video.id,
    filelist_id: filelist.id,
    timestamp: nowTimestamp(),
  }

  await addLog(team, user, filelistLog, 'filelist_downloadeds', (log) => {
    const isAlreadyAdded = log.filelist_downloadeds.some(
      (fd) => fd.video_id === video.id && fd.filelist_id === filelist.id
    )
    return !isAlreadyAdded
  })
}

/**
 * add video bookmark log
 * @param team `ITeam`
 * @param user `IUser`
 * @param video target `IVideo`
 * @throws Firebase error
 */
export const addVideoBookmarkLog = async (
  team: ITeam,
  user: IUser,
  video: IVideo
): Promise<void> => {
  await addVideoLog(team, user, video, 'video_bookmarks')
}

/**
 * add video unbookmark log
 * @param team `ITeam`
 * @param user `IUser`
 * @param video target `IVideo`
 * @throws Firebase error
 */
export const addVideoUnBookmarkLog = async (
  team: ITeam,
  user: IUser,
  video: IVideo
): Promise<void> => {
  await addVideoLog(team, user, video, 'video_unbookmarks')
}

/**
 * add logged logs
 * @param team `ITeam`
 * @param user `IUser`
 * @param type `'logged_ins' | 'logged_outs'`
 */
const addLoggedLog = async (
  team: ITeam,
  user: IUser,
  type: 'logged_ins' | 'logged_outs'
): Promise<void> => {
  const loggedLog: ILogLoggedIn | ILogLoggedOut = {
    timestamp: nowTimestamp(),
  }
  await addLog(team, user, loggedLog, type)
}

/**
 * add logged in log
 * @param team `ITeam`
 * @param user `IUser`
 */
export const addLoggedInLog = async (
  team: ITeam,
  user: IUser
): Promise<void> => {
  await addLoggedLog(team, user, 'logged_ins')
}

/**
 * add logged out log
 * @param team `ITeam`
 * @param user `IUser`
 */
export const addLoggedOutLog = async (
  team: ITeam,
  user: IUser
): Promise<void> => {
  await addLoggedLog(team, user, 'logged_outs')
}

/**
 * add enquete answered log
 * @param team `ITeam`
 * @param user `IUser`
 * @param video_id `string`
 * @param enquete_id `string`
 */
export const addEnqueteAnsweredLog = async (
  team: ITeam,
  user: IUser,
  video_id: string,
  enquete_id: string
): Promise<void> => {
  const enqueteAnsweredLog: ILogEnqueteAnswered = {
    video_id,
    enquete_id,
    timestamp: nowTimestamp(),
  }

  await addLog(team, user, enqueteAnsweredLog, 'enquete_answereds')
}

/**
 * add test answered log
 * @param team `ITeam`
 * @param user `IUser`
 * @param video_id `string`
 * @param test_id `string`
 */
export const addTestAnsweredLog = async (
  team: ITeam,
  user: IUser,
  video_id: string,
  test_id: string
): Promise<void> => {
  const enqueteAnsweredLog: ILogTestAnswered = {
    video_id,
    test_id,
    timestamp: nowTimestamp(),
  }

  await addLog(team, user, enqueteAnsweredLog, 'test_answereds')
}

/**
 * add live page open log
 * @param team `ITeam`
 * @param user `IUser`
 * @param video target `IVideo`
 */
export const addLivePageOpenLog = async (
  team: ITeam,
  user: IUser,
  live: ILive
): Promise<void> => {
  const livePageOpenLog: ILogLivePageOpen = {
    timestamp: nowTimestamp(),
    live_id: live.id,
  }
  await addLog(team, user, livePageOpenLog, 'live_page_opens', (log) => {
    const isAlreadyAddedToday = log.live_page_opens.some(
      (l) => isInTodayTime(l.timestamp) && l.live_id === live.id
    )
    return !isAlreadyAddedToday
  })
}

/**
 * add video commented or video reply commented log
 * @param team `ITeam`
 * @param user `IUser`
 * @param video_id `string`
 */
export const addVideoCommentedLog = async (
  team: ITeam,
  user: IUser,
  video_id: string,
  type: 'video_commenteds' | 'video_reply_commenteds'
): Promise<void> => {
  const videoCommentedLog: ILogVideoCommented | ILogVideoReplyCommented = {
    video_id,
    timestamp: nowTimestamp(),
  }

  await addLog(team, user, videoCommentedLog, type)
}
