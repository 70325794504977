import { Routes } from './enums'
import { isCatalogPage, teamUid } from './link'

/**
 * @returns if catalog page `/catalog/:catalogTeamId` else `/user/:teamId`
 */
const userRootPath = (): string =>
  isCatalogPage() ? catalogRootPath() : makeUserRootPath()

/**
 * カタログページのRootPath
 * @returns `/catalog/:thisTeamId`
 */
export const catalogRootPath = (): string => `/catalog/${teamUid()}`

/**
 * チームのログインPath
 * @returns `/login/:teamId`
 */
export const makeLoginTeamPath = (teamId: string): string => `/login/${teamId}`

/**
 * ログイン後のPath
 * @returns `/logged_in` or `/logged_in/:teamId`
 */
export const makeLoggedInPath = (teamId = ''): string =>
  `/logged_in${teamId ? `/${teamId}` : ''}`

/**
 * サブスク商品有効時に強制遷移される料金選択画面のPath
 * @returns `/item_select/:teamId`
 */
export const makeItemSelectPath = (teamId: string): string =>
  `/item_select/${teamId}`

/**
 * チームのEntry Path
 * @returns `/entry` or `/entry/:teamId`
 */
export const makeEntryPath = (teamId = '', isUserAdmin = false): string =>
  `/entry${teamId && !isUserAdmin ? `/${teamId}` : ''}`

/**
 * チームのアンケート回答Path
 * @returns `/answer_to_enquete/:teamId`
 */
export const makeAnswerToEnquetePath = (teamId: string): string =>
  `/answer_to_enquete/${teamId}`

/**
 * チームのLPのPath
 * @returns `/lp/:teamId`
 */
export const makeLandingPath = (teamId: string): string => `/lp/${teamId}`

/**
 * 指定のチームのカタログページのPath
 * @returns `/catalog/:teamId`
 */
export const makeCatalogPath = (teamId: string): string => `/catalog/${teamId}`

/**
 * 管理者の決済変更Path？
 * @returns `/admin_item_select/:purchasedPriceId`
 */
export const makeAdminItemSelectPath = (purchasedPriceId: string): string =>
  `/admin_item_select/${purchasedPriceId}`

/**
 * 管理者の招待Path
 * @returns `/join/admin/:inviteId`
 */
export const makeJoinAdministratorPath = (inviteId: string): string =>
  `/join/admin/${inviteId}`

/**
 * 会員の招待Path
 * @returns `/join/user/:inviteId`
 */
export const makeJoinUserPath = (inviteId: string): string =>
  `/join/user/${inviteId}`

/**
 * 会員側のRootPath
 * @returns `/user/:thisTeamId`
 */
export const makeUserRootPath = (teamId?: string): string =>
  `/user/${teamId || teamUid()}`

/**
 * 会員側のお知らせPath
 * @returns `/user/:thisTeamId/news`
 */
export const makeNewsPath = (): string => `/user/${teamUid()}/news`

/**
 * 会員側のマイアカウントPath
 * @returns `/user/:thisTeamId/setting`
 */
export const makeSettingPath = (teamId?: string): string =>
  `/user/${teamId || teamUid()}/setting`

/**
 * 会員側の決済履歴Path
 * @returns `/user/:thisTeamId/paid_history`
 */
export const makePaidHistoryPath = (): string =>
  `/user/${teamUid()}/paid_history`

/**
 * 会員側のプラン変更Path
 * @returns `/user/:thisTeamId/change_price`
 */
export const makeChangePricePath = (): string =>
  `/user/${teamUid()}/change_price`

/**
 * 会員側の視聴履歴Path
 * @returns `/user/:thisTeamId/view_log`
 */
export const makeViewLogPath = (): string => `/user/${teamUid()}/view_log`

/**
 * 会員側のお気に入りPath
 * @returns `/user/:thisTeamId/bookmark`
 */
export const makeBookmarkPath = (): string => `/user/${teamUid()}/bookmark`

/**
 * 会員側の後で見るPath
 * @returns `/user/:thisTeamId/watchLater`
 */
export const makeWatchLaterPath = (): string => `/user/${teamUid()}/watchLater`

/**
 * 会員側の購入済みPath
 * @returns `/user/:thisTeamId/purchased`
 */
export const makePurchasedPath = (): string => `/user/${teamUid()}/purchased`

/**
 * 動画の決済Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/item_select_content`
 */
export const makeItemSelectContentPath = (): string =>
  isCatalogPage()
    ? `${catalogRootPath()}${Routes.ItemSelectContent}`
    : Routes.ItemSelectContent

/**
 * 動画カテゴリ一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/videos/categories`
 */
export const allVideoCategoryPath = (): string =>
  `${userRootPath()}/videos/categories`

/**
 * 配信カテゴリ一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/lives/categories`
 */
export const allLiveCategoryPath = (): string =>
  `${userRootPath()}/lives/categories`

/**
 * プレイリストカテゴリ一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/playlists/categories`
 */
export const allPlaylistCategoryPath = (): string =>
  `${userRootPath()}/playlists/categories`

/**
 * カテゴリの動画一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/videos/category/:categoryId`
 */
export const makeCategoryVideoPath = (categoryId: string): string =>
  `${userRootPath()}/videos/category/${categoryId}`

/**
 * カテゴリの配信一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/lives/category/:categoryId`
 */
export const makeCategoryLivePath = (categoryId: string): string =>
  `${userRootPath()}/lives/category/${categoryId}`

/**
 * カテゴリのプレイリスト一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/playlists/category/:categoryId`
 */
export const makeCategoryPlaylistPath = (categoryId: string): string =>
  `${userRootPath()}/playlists/category/${categoryId}`

/**
 * 動画タグ一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/videos/tags`
 */
export const allVideoTagPath = (): string => `${userRootPath()}/videos/tags`

/**
 * 配信タグ一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/lives/tags`
 */
export const allLiveTagPath = (): string => `${userRootPath()}/lives/tags`

/**
 * プレイリストタグ一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/playlists/tags`
 */
export const allPlaylistTagPath = (): string =>
  `${userRootPath()}/playlists/tags`

/**
 * タグの動画一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/videos/tag/:tagId`
 */
export const makeTagVideoPath = (tagId: string): string =>
  `${userRootPath()}/videos/tag/${tagId}`

/**
 * タグの配信一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/lives/tag/:tagId`
 */
export const makeTagLivePath = (tagId: string): string =>
  `${userRootPath()}/lives/tag/${tagId}`

/**
 * タグのプレイリスト一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/playlists/tag/:tagId`
 */
export const makeTagPlaylistPath = (tagId: string): string =>
  `${userRootPath()}/playlists/tag/${tagId}`

/**
 * 動画クリエイター一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/videos/creators`
 */
export const allVideoCreatorPath = (): string =>
  `${userRootPath()}/videos/creators`

/**
 * 配信クリエイター一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/lives/creators`
 */
export const allLiveCreatorPath = (): string =>
  `${userRootPath()}/lives/creators`

/**
 * クリエイターの動画一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/videos/creator/:creatorId`
 */
export const makeCreatorVideoPath = (creatorId: string): string =>
  `${userRootPath()}/videos/creator/${creatorId}`

/**
 * クリエイターの配信一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/lives/creator/:creatorId`
 */
export const makeCreatorLivePath = (creatorId: string): string =>
  `${userRootPath()}/lives/creator/${creatorId}`

/**
 * 新着の動画一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/videos/newer`
 */
export const newerVideosPath = (): string => `${userRootPath()}/videos/newer`

/**
 * 人気の動画一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/videos/popular`
 */
export const popularVideosPath = (): string =>
  `${userRootPath()}/videos/popular`

/**
 * 動画詳細Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/video/:videoId`
 */
export const makeVideoPath = (videoId: string): string =>
  `${userRootPath()}/video/${videoId}`

/**
 * 動画詳細Path 自動再生あり
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/video/:videoId?autoplay=true`
 */
export const makeVideoPathWithAutoPlay = (videoId: string): string =>
  `${makeVideoPath(videoId)}?autoplay=true`

/**
 * イベント詳細Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/event/:playlistId`
 */
export const makeEventPath = (eventId: string) =>
  `${userRootPath()}/event/${eventId}`

/**
 * イベントの動画詳細Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/event(/live)?/:eventId/:contentId`
 */
export const makeEventVideoPath = (
  eventId: string,
  contentId: string,
  isLive?: boolean
) => `${userRootPath()}/event${isLive ? '/live' : ''}/${eventId}/${contentId}`

/**
 * 新着のイベント一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/events/newer`
 */
export const newerEventsPath = (): string => `${userRootPath()}/events/newer`

/**
 * プレイリスト一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/playlists`
 */
export const allPlaylistPath = (): string => `${userRootPath()}/playlists`

/**
 * プレイリスト詳細Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/playlist/:playlistId`
 */
export const makePlaylistPath = (playlistId: string) =>
  `${userRootPath()}/playlist/${playlistId}`

/**
 * プレイリストの動画詳細Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/playlist/:playlistId/:videoId`
 */
export const makePlaylistVideoPath = (playlistId: string, videoId: string) =>
  `${userRootPath()}/playlist/${playlistId}/${videoId}`

/**
 * 新着のプレイリスト一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/playlists/newer`
 */
export const newerPlaylistsPath = (): string =>
  `${userRootPath()}/playlists/newer`

/**
 * クリエイター詳細Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/creator/:creatorId`
 */
export const makeCreatorDetailPath = (creatorId: string) =>
  `${userRootPath()}/creator/${creatorId}`

/**
 * 動画検索Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/videos/search/:query`
 */
export const makeSearchPath = (query: string) => {
  query = encodeURIComponent(encodeURIComponent(query))
  return `${userRootPath()}/videos/search/${query}`
}

/**
 * 動画/配信/プレイリストCombine検索Path
 *
 * **Base**: `(/catalog/:thisTeamId | /user/:teamId)`
 *
 * **Path**
 * ```ts
 * /(videos|lives|events|playlists)(/category/:categoryId)?(/tag/:tagId)?(/creator/:creatorId)?(/playStatus/:status)?
 * ```
 */
export const makeCombinePath = (
  urlParams: {
    type: string | undefined
    categoryId: string | undefined
    tagId: string | undefined
    creatorId: string | undefined
    playStatus: string | undefined
  },
  onclick: {
    type: 'content_type' | 'category' | 'tag' | 'creator' | 'playStatus'
    id: string
  }
): string => {
  let path =
    onclick.type === 'content_type'
      ? `${userRootPath()}/${onclick.id}`
      : `${userRootPath()}/${urlParams.type || 'videos'}`
  const arr = [
    [urlParams.categoryId, 'category'],
    [urlParams.tagId, 'tag'],
    [urlParams.creatorId, 'creator'],
    [urlParams.playStatus, 'playStatus'],
  ]

  for (const [param, type] of arr) {
    if ((param || onclick.type === type) && param !== onclick.id) {
      const id = onclick.type === type ? onclick.id : param
      path += `/${type}/${id}`
    }
  }
  return path
}

/**
 * 配信詳細Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/live/:liveId`
 */
export const makeLivePath = (liveId: string) =>
  `${userRootPath()}/live/${liveId}`

/**
 * 新着の配信一覧Path
 * @returns `(/catalog/:thisTeamId | /user/:teamId)/lives/newer`
 */
export const newerLivePath = (): string => `${userRootPath()}/lives/newer`
