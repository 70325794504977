import { IStoreCache } from 'common/interfaces/auth_provider'
import { IMailTemplate } from 'common/interfaces/team'
import { makeLoginTeamUrl } from 'common/link_url'
import { send } from 'repositories/functions/functions_mail'
import { checkFirebaseError } from 'common/utils'
import { EMAIL_REG } from 'common/const'
import { alertService } from 'services/alert'
import { i18nValidation } from 'i18n/i18n'

// テスト用のメールを送信する
export const sendTestEMail = async (
  sendToAddress: string,
  storeCache: IStoreCache,
  template: IMailTemplate | undefined
) => {
  if (!template) {
    return
  }

  const { team, user } = storeCache
  const loginUrl = makeLoginTeamUrl(team!.id)

  const mailContent = template.email_content
    .replace('{{user_name}}', user!.name || user!.email)
    .replace('{{site_name}}', team!.name || '')
    .replace('{{login_raw_url}}', loginUrl)
    .replace('{{login_url}}', `<a href="${loginUrl}">${loginUrl}</a>`)
    .replace(/{{price_name}}/g, 'ご購入商品')

  await send(
    team!.id,
    // テストメールが送信メールログに保存されるのを防ぐため、第二・第三引数はundefined
    undefined,
    undefined,
    '送信テスト',
    null,
    sendToAddress,
    { name: team!.name, email: team!.email! },
    `【送信テスト】${template.email_title}`,
    mailContent,
    mailContent
  )
}

// テストメール送信モーダル用Submit関数
export const onSubmitTestEmailForm = async (
  email: string,
  onSubmit: (email: string) => Promise<void>,
  onToggleModal: () => void
) => {
  try {
    const valid = EMAIL_REG.test(email)
    if (!valid) {
      throw new Error(i18nValidation('firebaseError.invalidEmail'))
    }

    await onSubmit(email)
    onToggleModal()
  } catch (error) {
    console.log(error)
    const errorMessage = checkFirebaseError(error)
    alertService.show(false, errorMessage)
  }
}
