import { IStoreCache } from 'common/interfaces/auth_provider'
import { IProduct } from 'common/interfaces/stripe/product'
import { isLoggedIn } from 'common/utils'
import { getProducts } from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'

/**
 * Stripeの全ての商品を取得しStateに格納する
 */
export const getStripeProducts = async (
  storeCache: IStoreCache,
  setData: React.Dispatch<React.SetStateAction<IProduct[]>>
): Promise<void> => {
  if (!isLoggedIn(storeCache) || !storeCache.team!.stripe.account_id) return

  try {
    setData(await getProducts(storeCache.team!.stripe.account_id))
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
