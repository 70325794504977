import { IImage } from 'common/interfaces/team'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from 'reactstrap'
import './topimage.css'

const TopImage: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [animating, setAnimating] = useState<boolean>(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex: number) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const team = useContext(AuthContext).storeCache.team
  if (!team) {
    return null
  }

  const items: any[] = []
  team.top_images.forEach((topImage: IImage, key: number) => {
    items.push({
      src: topImage.path,
      altText: `${team.name}_${key}`,
      caption: '',
      link: topImage.link,
      asNewTab: topImage.asNewTab,
    })
  })

  /* eslint-disable react/jsx-no-target-blank */
  const slides = items.map((item) => (
    <CarouselItem
      key={item.src}
      className="mb-3"
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
    >
      <a
        href={item.link}
        onClick={item.link ? undefined : (e) => e.preventDefault()}
        style={item.link ? undefined : { cursor: 'unset' }}
        target={item.asNewTab ? '_blank' : undefined}
        rel={item.asNewTab ? 'noopener noreferrer' : undefined}
      >
        <img className="top-image" src={item.src} alt={item.altText} />
      </a>
    </CarouselItem>
  ))
  /* eslint-enable react/jsx-no-target-blank */

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval="4000"
      className="carousel-fade"
    >
      {slides}
      {items.length > 1 && (
        <>
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </>
      )}
    </Carousel>
  )
}

export default TopImage
