import { IEvent } from 'common/interfaces/event'
import { periodDatetimeFormat } from 'common/times'
import FooterButtons from 'components/atoms/Modal/FooterButtons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import { EventApplicationInputType as InputType } from 'services/user/event_application'

interface IEventApplicationModalProps {
  isEvent: boolean
  event: IEvent | null
  showModal: boolean
  onToggleModal: () => void
  onChange: (type: InputType, e: React.ChangeEvent<HTMLInputElement>) => void
  onApplyToEvent: () => void
}

const EventApplicationModal: React.FC<IEventApplicationModalProps> = ({
  isEvent,
  event,
  showModal,
  onToggleModal,
  onChange,
  onApplyToEvent,
}: IEventApplicationModalProps) => {
  const { t } = useTranslation('userCommon')
  if (!isEvent || !event) return null
  return (
    <Modal isOpen={showModal} toggle={onToggleModal} centered>
      <ModalHeader toggle={onToggleModal}>
        <h2>{t('eventApplicationModal.confirmationAttendEvent')}</h2>
      </ModalHeader>
      <ModalBody>
        <h3>{event.name}</h3>
        <h3>{periodDatetimeFormat(event.hold_from, event.hold_to)}</h3>
        <Form className="mt-5">
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                onChange={(e) => onChange(InputType.IS_RECEIVE_REMIND_EMAIL, e)}
              />
              {t('eventApplicationModal.receiveRemindEmail')}
            </Label>
          </FormGroup>
          <FooterButtons
            leftContent="OK"
            rightContent={t('eventApplicationModal.cancel')}
            leftOnClick={onApplyToEvent}
            rightOnClick={onToggleModal}
          />
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default EventApplicationModal
