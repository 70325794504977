import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import List from 'components/organisms/ItemSelectContent/List'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Button, Container } from 'reactstrap'

const ItemSelectContent: React.FC = () => {
  const { t } = useTranslation('itemSelect')
  const history = useHistory()

  const [loading, setLoading] = useState<boolean>(false)

  if (loading) return <FullLoadingSpinner />

  return (
    <div className="main-content">
      <div className="text-right mr-5">
        <Button
          onClick={() => history.goBack()}
          className="mt-4"
          color="default"
          type="button"
        >
          {t('back')}
        </Button>
      </div>
      <Container>
        <List setLoading={setLoading} />
      </Container>
    </div>
  )
}

export default ItemSelectContent
