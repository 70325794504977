import { ColumnDef } from '@tanstack/react-table'
import { makePrimaryBadges } from 'components/atoms/Table/ElementsOnTable'
import { i18nAdminAnalytics } from 'i18n/i18n'
import { get as getLog } from 'repositories/store/firebase_log'
import {
  findCachedCategory,
  findCachedCreator,
  findCachedFilelists,
  findCachedGroupsNonNull,
  findCachedTagsNonNull,
} from './find_store_cache'
import { IStoreCache } from './interfaces/auth_provider'
import { IEnquete } from './interfaces/enquete'
import { ILive } from './interfaces/live'
import { IFlattedLog, ILog, LogType } from './interfaces/log'
import { IPlaylist } from './interfaces/playlist'
import { ITest } from './interfaces/test'
import { IUser } from './interfaces/user'
import { IVideo } from './interfaces/video'
import { datetimeFormat } from './times'

/**
 * is no logs?
 * @param log `ILog`
 * @returns `boolean`
 */
export const isLogEmpty = (log: ILog): boolean => {
  const l = log as any
  return Object.keys(l)
    .filter((k) => Array.isArray(l[k]))
    .every((k) => l[k].length <= 0)
}

/**
 * ログを各タイプを入れ込んだ配列にして返却する
 */
export const flattedLog = (log: ILog): IFlattedLog[] => {
  type NoUserIdLog = Omit<ILog, 'user_id'>

  const keys: { key: keyof NoUserIdLog; type: LogType }[] = [
    { key: 'video_page_opens', type: LogType.VIDEO_PAGE_OPEN },
    { key: 'video_plays', type: LogType.VIDEO_PLAY },
    { key: 'video_endeds', type: LogType.VIDEO_ENDED },
    { key: 'filelist_downloadeds', type: LogType.FILELIST_DOWNLOADED },
    { key: 'video_bookmarks', type: LogType.VIDEO_BOOKMARK },
    { key: 'video_unbookmarks', type: LogType.VIDEO_UNBOOKMARK },
    { key: 'logged_ins', type: LogType.LOGGED_IN },
    { key: 'logged_outs', type: LogType.LOGGED_OUT },
    { key: 'enquete_answereds', type: LogType.ENQUETE_ANSWERED },
    { key: 'test_answereds', type: LogType.TEST_ANSWERED },
    { key: 'live_page_opens', type: LogType.LIVE_PAGE_OPEN },
    { key: 'video_commenteds', type: LogType.VIDEO_COMMENTED },
    { key: 'video_reply_commenteds', type: LogType.VIDEO_REPLY_COMMENTED },
    {
      key: 'user_published_subscriptions',
      type: LogType.USER_PUBLISHED_SUBSCRIPTION,
    },
  ]

  return keys
    .reduce<IFlattedLog[]>(
      (acc, { key, type }) =>
        acc.concat(
          log[key].map((l) => ({
            ...l,
            user_id: log.user_id,
            video_id: 'video_id' in l ? l.video_id : '',
            type,
          }))
        ),
      []
    )
    .sort((a, b) => a.timestamp.seconds - b.timestamp.seconds)
}

/**
 * ログタイプを文字列に変換する
 */
const logType2str = (type: LogType): string => {
  const i18n = i18nAdminAnalytics('logType', { returnObjects: true })
  switch (type) {
    case LogType.VIDEO_PAGE_OPEN:
      return i18n.videoPageOpen
    case LogType.VIDEO_PLAY:
      return i18n.videoPlay
    case LogType.VIDEO_ENDED:
      return i18n.videoEnded
    case LogType.FILELIST_DOWNLOADED:
      return i18n.filelistDownloaded
    case LogType.VIDEO_BOOKMARK:
      return i18n.videoBookmark
    case LogType.VIDEO_UNBOOKMARK:
      return i18n.videoUnbookmark
    case LogType.LOGGED_IN:
      return i18n.loggedIn
    case LogType.LOGGED_OUT:
      return i18n.loggedOut
    case LogType.ACCOUNT_CREATED:
      return i18n.accountCreated
    case LogType.ENQUETE_ANSWERED:
      return i18n.enqueteAnswered
    case LogType.TEST_ANSWERED:
      return i18n.testAnswered
    case LogType.LIVE_PAGE_OPEN:
      return i18n.livePageOpen
    case LogType.VIDEO_COMMENTED:
      return i18n.videoCommented
    case LogType.VIDEO_REPLY_COMMENTED:
      return i18n.videoReplyCommented
    case LogType.USER_PUBLISHED_SUBSCRIPTION:
      return i18n.userPublishedSubscription
  }
}

/**
 * アカウント作成イベントのログを取得
 */
export const getAccountCreatedLog = (user: IUser): IFlattedLog => ({
  user_id: user.id,
  timestamp: user.created_at,
  type: LogType.ACCOUNT_CREATED,
  video_id: '',
})

/**
 * 分析のTableで使うカラムの返却
 * @param storeCache `IStoreCache`
 * @param allData アンケートとテストのデータ
 * @returns Tableで使うカラム
 */
export const getLogTableColumns = (
  storeCache: IStoreCache,
  allData: {
    enquetes: IEnquete[]
    tests: ITest[]
  }
): ColumnDef<IFlattedLog>[] => {
  const video = (log: IFlattedLog) =>
    storeCache.videos.find((v) => v.id === log.video_id)
  const live = (log: IFlattedLog) =>
    storeCache.lives.find((l) => l.id === log.live_id)
  const enquete = (log: IFlattedLog) =>
    allData.enquetes.find((e) => e.id === log.enquete_id)
  const test = (log: IFlattedLog) =>
    allData.tests.find((t) => t.id === log.test_id)

  const mov = (log: IFlattedLog) => video(log) || live(log)

  const category = (log: IFlattedLog) =>
    findCachedCategory(storeCache, mov(log)?.category_id ?? '')
  const tags = (log: IFlattedLog) =>
    findCachedTagsNonNull(storeCache, mov(log)?.tag_ids ?? [])
  const groups = (log: IFlattedLog) =>
    findCachedGroupsNonNull(storeCache, mov(log)?.group_ids ?? [])
  const creator = (log: IFlattedLog) =>
    findCachedCreator(storeCache, mov(log)?.creator_id ?? '')
  const filelistName = (log: IFlattedLog) =>
    log.filelist_id
      ? findCachedFilelists(storeCache, [log.filelist_id])[0]?.name ?? ''
      : ''

  const i18n = i18nAdminAnalytics('videoTable', { returnObjects: true })
  return [
    {
      header: i18n.viewedDate,
      accessorFn: (l) => datetimeFormat(l.timestamp),
    },
    {
      header: i18n.event,
      accessorFn: (l) => logType2str(l.type),
    },
    {
      header: i18n.videoTitle,
      accessorFn: (l) => video(l)?.name ?? '',
    },
    {
      header: i18n.liveTitle,
      accessorFn: (l) => live(l)?.name ?? '',
    },
    {
      header: i18n.enqueteTitle,
      accessorFn: (l) => enquete(l)?.title ?? '',
    },
    {
      header: i18n.testTitle,
      accessorFn: (l) => test(l)?.name ?? '',
    },
    {
      header: i18n.category,
      accessorFn: (l) => category(l)?.name ?? '',
    },
    {
      header: i18n.tag,
      accessorFn: (l) => tags(l).map((t) => t.name),
      cell: (cell) => makePrimaryBadges(cell.getValue<string[]>()),
    },
    {
      header: i18n.groups,
      accessorFn: (l) => groups(l).map((g) => g.name),
      cell: (cell) => makePrimaryBadges(cell.getValue<string[]>()),
    },
    {
      header: i18n.creator,
      accessorFn: (l) => creator(l)?.name ?? '',
    },
    {
      header: i18n.filelist,
      accessorFn: (l) => filelistName(l),
    },
  ]
}

/**
 * 視聴ステータスでフィルターされたコンテンツを返却
 */
export const filterContentsByPlayStatus = async <
  T extends IVideo | ILive | IPlaylist
>(
  storeCache: IStoreCache,
  playStatus: 'notYet' | 'playing' | 'played',
  contents: T[]
): Promise<T[]> => {
  if (contents.length <= 0) return []

  const log = await getLog(storeCache.team!, storeCache.user!)
  if (!log) return []

  return contents.filter((c) => {
    const videoIds =
      'video_ids' in c
        ? c.video_ids.filter((id) => storeCache.videos.find((v) => v.id === id))
        : [c.id]

    if (playStatus === 'notYet') {
      return videoIds.every(
        (id) =>
          !log.video_plays.find((vp) => vp.video_id === id) &&
          !log.video_endeds.find((ve) => ve.video_id === id)
      )
    }
    if (playStatus === 'playing') {
      return videoIds.some(
        (id) =>
          log.video_plays.find((vp) => vp.video_id === id) &&
          !log.video_endeds.find((ve) => ve.video_id === id)
      )
    }
    if (playStatus === 'played') {
      return videoIds.every((id) =>
        log.video_endeds.find((ve) => ve.video_id === id)
      )
    }
    return false
  })
}
