import { Routes } from 'common/enums'
import { IGroup } from 'common/interfaces/group'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/GroupForm/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { initGroup } from 'services/admin/group_form'

const GroupForm: React.FC = () => {
  const { t } = useTranslation('adminUserGroup')
  const history = useHistory()
  const location = useLocation<{ group: IGroup }>()
  const isCreate = location.pathname === Routes.AdminGroupCreate
  const group = isCreate ? initGroup() : location.state.group

  return (
    <>
      <H1 title={isCreate ? t('form.title.register') : t('form.title.edit')} />

      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminGroup}
          content={t('form.backToList')}
        />
        <FormBody isCreate={isCreate} groupObj={group} history={history} />
      </Container>
    </>
  )
}

export default GroupForm
