import { ITeam } from 'common/interfaces/team'
import { ITest } from 'common/interfaces/test'
import { ITestAnswer } from 'common/interfaces/test_answer'
import { Teams } from './firebase_team'

const TestAnswers = (team: ITeam, test: ITest) =>
  Teams.doc(team.id).collection('tests').doc(test.id).collection('answers')

/**
 * get all Answers of Test
 */
export const get = async (team: ITeam, test: ITest): Promise<ITestAnswer[]> => {
  try {
    const answerDatas = await TestAnswers(team, test).get()
    return answerDatas.docs.map((answer) => answer.data() as ITestAnswer)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get Answer by user_id and video_id
 * @returns found: answer, not found: `null`, error: `undefined`
 */
export const findByUserIdAndVideoId = async (
  team: ITeam,
  test: ITest,
  userId: string,
  videoId: string
): Promise<ITestAnswer | null | undefined> => {
  try {
    const answerDatas = await TestAnswers(team, test)
      .where('user_id', '==', userId)
      .where('video_id', '==', videoId)
      .get()
    return answerDatas.empty
      ? null
      : (answerDatas.docs[0].data() as ITestAnswer)
  } catch (error) {
    console.log(error)
    return undefined
  }
}

/**
 * add Test's Answer
 * @throws Firebase error
 */
export const store = async (team: ITeam, test: ITest, answer: ITestAnswer) => {
  await TestAnswers(team, test).doc(answer.id).set(answer)
}

/**
 * delete Answer of Test
 * @throws Firebase error
 */
export const remove = async (team: ITeam, test: ITest, answer: ITestAnswer) => {
  await TestAnswers(team, test).doc(answer.id).delete()
}

/**
 * delete all Answers of Test
 * @throws Firebase error
 */
export const removeAllAnswersInTest = async (team: ITeam, test: ITest) => {
  const answers = await get(team, test)
  const removeTask = answers.map((answer) => remove(team, test, answer))
  await Promise.all(removeTask)
}
