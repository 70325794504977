import { AxiosProgressEvent } from 'axios'
import { i18nAlert } from 'i18n/i18n'
import { BehaviorSubject } from 'rxjs'

export interface IUploadProgress {
  videoId: string
  videoName: string
  progressEvent: AxiosProgressEvent
  abortController: AbortController
}

export const initProgressEvent = (): AxiosProgressEvent => ({
  loaded: 0,
  bytes: 0,
})

const uploadProgressSubject = new BehaviorSubject<IUploadProgress[]>([])
const cancelledVideoIds: string[] = []

export const uploadProgressService = {
  onChange,
  update,
  remove,
  cancel,
  isUploadCancelled,
}

function onChange() {
  return uploadProgressSubject.asObservable()
}

const updateBeforeUnload = (newEvents: IUploadProgress[]) => {
  window.onbeforeunload =
    newEvents.length > 0
      ? () => i18nAlert('onBeforeUnload.confirmLeavePage')
      : null
}

function update(progress: IUploadProgress) {
  const events = uploadProgressSubject.getValue()
  const target = events.find((e) => e.videoId === progress.videoId)
  if (target === undefined) {
    events.push({ ...progress })
  } else {
    events[events.indexOf(target)] = { ...progress }
  }
  uploadProgressSubject.next([...events])
}

function remove(videoId: string) {
  const events = uploadProgressSubject.getValue()
  const target = events.find((e) => e.videoId === videoId)
  if (target) {
    events.splice(events.indexOf(target), 1)
    const newEvents = [...events]
    updateBeforeUnload(newEvents)
    uploadProgressSubject.next(newEvents)
  }
}

function cancel(videoId: string) {
  cancelledVideoIds.push(videoId)
  const events = uploadProgressSubject.getValue()
  const target = events.find((e) => e.videoId === videoId)
  target?.abortController.abort()
  remove(videoId)
}

function isUploadCancelled(videoId: string): boolean {
  return cancelledVideoIds.includes(videoId)
}
