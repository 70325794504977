import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICreator } from 'common/interfaces/creator'
import { nowTimestamp } from 'common/times'
import { checkFirebaseError, emptyNumStr2Null, isLoggedIn } from 'common/utils'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { reloadCachedCreators } from 'providers/AuthProvider'
import { removeImageFile, upload } from 'repositories/storage/firebase_creator'
import { removeImage, store, update } from 'repositories/store/firebase_creator'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/creator_form'
import { v4 as uuidv4 } from 'uuid'

/**
 * `ICreator`の初期値を返却する
 */
export const initCreator = (): ICreator => {
  return {
    id: uuidv4(),
    name: '',
    image: null,
    sort_num: null,
    profile: '',
    created_at: nowTimestamp(),
    updated_at: nowTimestamp(),
  }
}

/**
 * `onChangeInput`で使用する入力タイプ
 */
export enum InputType {
  NAME,
  PROFILE,
  SORT_NUM,
}

/**
 * 各入力欄の`onChange`イベントハンドラ
 */
export const onChangeInput = (
  creator: ICreator,
  setCreator: React.Dispatch<React.SetStateAction<ICreator>>,
  type: InputType,
  e: any
) => {
  setCreator({
    ...creator,
    name: type === InputType.NAME ? e.target.value : creator.name,
    sort_num:
      type === InputType.SORT_NUM
        ? emptyNumStr2Null(e.target.value)
        : creator.sort_num,
    profile: type === InputType.PROFILE ? e : creator.profile,
  })
}

/**
 * 投稿者レコード作成/更新処理
 */
export const saveCreator = async (
  history: History,
  creator: ICreator,
  imageBlob: Blob | undefined,
  isImageDelete: boolean,
  isImageCropped: boolean,
  storeCache: IStoreCache,
  isCreate: boolean
) => {
  if (!isLoggedIn(storeCache)) return

  try {
    validateForm(creator, isImageCropped)

    if (imageBlob && !isImageDelete) {
      creator.image = await upload(storeCache.team!, creator, imageBlob)
    }
    if (!isCreate && isImageDelete) {
      await deleteImage(creator, storeCache)
    }

    if (isCreate) {
      creator.created_at = nowTimestamp()
      creator.updated_at = nowTimestamp()
      await store(storeCache.team!, creator)
    } else {
      await update(storeCache.team!, creator)
    }
    await reloadCachedCreators(storeCache)

    history.push(Routes.AdminCreator)
  } catch (error) {
    console.log(error)
    alertService.show(false, checkFirebaseError(error))
  }
}

/**
 * 投稿者の画像を削除する(ドキュメント更新 & ファイル削除)
 */
const deleteImage = async (
  creator: ICreator,
  storeCache: IStoreCache
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return

  try {
    await removeImageFile(storeCache.team!, creator)
    await removeImage(storeCache.team!, creator)
    alertService.show(true, i18nAlert('deleted.thumbnail'))
  } catch (error) {
    console.log(error)
    alertService.show(false, i18nAlert('deleted.fail.thumbnail'))
  }
}
