import { IComment, ICommentInfo } from 'common/interfaces/comment'
import {
  IReplyComment,
  IReplyCommentInfo,
} from 'common/interfaces/reply_comment'
import { ITeam } from 'common/interfaces/team'
import { IVideo } from 'common/interfaces/video'
import { Teams } from './firebase_team'

const ReplyComments = (
  team: ITeam,
  video: IVideo,
  comment: IComment | ICommentInfo
) =>
  Teams.doc(team.id)
    .collection('videos')
    .doc(video.id)
    .collection('comments')
    .doc(comment.id)
    .collection('reply_comments')

/**
 * get reply comments
 */
export const get = async (
  team: ITeam,
  video: IVideo,
  comment: IComment | ICommentInfo
): Promise<IReplyComment[]> => {
  const commentDatas = await ReplyComments(team, video, comment)
    .orderBy('created_at', 'desc')
    .get()
  return commentDatas.docs.map(
    (replyComment) => replyComment.data() as IReplyComment
  )
}

/**
 * add reply comment in comment
 * @throws Firebase error
 */
export const store = async (
  team: ITeam,
  video: IVideo,
  comment: ICommentInfo,
  replyComment: IReplyComment
): Promise<void> => {
  await ReplyComments(team, video, comment)
    .doc(replyComment.id)
    .set(replyComment)
}

/**
 * delete reply comment in comment
 * @throws Firebase error
 */
export const remove = async (
  team: ITeam,
  video: IVideo,
  comment: IComment | ICommentInfo,
  replyComment: IReplyComment | IReplyCommentInfo
): Promise<void> => {
  await ReplyComments(team, video, comment).doc(replyComment.id).delete()
}

/**
 * delete reply comments in comment
 * @throws Firebase error
 */
export const removeAllReplyCommentInComment = async (
  team: ITeam,
  video: IVideo,
  comment: IComment | ICommentInfo
): Promise<void> => {
  const replyComments = await get(team, video, comment)
  const removeTask = replyComments.map((replyComment) =>
    remove(team, video, comment, replyComment)
  )
  await Promise.all(removeTask)
}
