import { IVideo, VideoStatus } from 'common/interfaces/video'
import { MultiVideoUpdateType } from 'common/interfaces/video_multi_update'
import { datetimeLocalFormat, nowTimestamp } from 'common/times'
import {
  createGroupOptions,
  createTagOptions,
  getDefaultPublicationPeriod,
  getStatusOptions,
} from 'common/utils'
import FromToDateTime from 'components/atoms/Form/FromToDateTime'
import FooterButtons from 'components/atoms/Modal/FooterButtons'
import {
  createCategoryJSXOptions,
  createCreatorJSXOptions,
} from 'components/atoms/Table/CreateTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Form, Input, Label, Modal, ModalBody } from 'reactstrap'
import {
  InputType,
  getReleaseDate,
  initVideo,
  onChangeInput as serviceOnChangeInput,
  onChangeLimitedAccess as serviceOnChangeLimitedAccess,
  onChangeStatus as serviceOnChangeStatus,
} from 'services/admin/video_form'
import { onUpdateVideos as serviceOnUpdateVideos } from 'services/admin/video_multi_update'

interface IVideoMultiSelectModalProps {
  isOpen: boolean
  toggleModal: () => void
  type: MultiVideoUpdateType
  selectedVideos: IVideo[]
}

const VideoMultiSelectModal: React.FC<IVideoMultiSelectModalProps> = ({
  isOpen,
  type,
  selectedVideos,
  toggleModal,
}) => {
  const { storeCache } = useContext(AuthContext)
  const { t } = useTranslation('alert')
  const adminVideo = useTranslation('adminVideo')
  const [newVideo, setNewVideo] = useState<IVideo>({
    ...initVideo(),
    name: 'temporarily',
  })
  const tagOptions = createTagOptions(storeCache.tags)
  const categoryOptions = createCategoryJSXOptions(storeCache.categories)
  const groupOptions = createGroupOptions(storeCache.groups)
  const creatorOptions = createCreatorJSXOptions(storeCache.creators)

  const defaultPublicationPeriod = getDefaultPublicationPeriod(newVideo)

  const onChangeInput = (type: InputType, e: any) =>
    serviceOnChangeInput(newVideo, setNewVideo, type, e)

  const onUpdateVideos = () => {
    serviceOnUpdateVideos(
      storeCache,
      selectedVideos,
      toggleModal,
      type,
      newVideo
    )
  }

  const onChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeStatus(newVideo, setNewVideo, e)

  const onChangeLimitedAccess = (
    type: 'start' | 'end',
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeLimitedAccess(newVideo, setNewVideo, type, e)

  const statusOptions = getStatusOptions().map((option, key) => (
    <option key={key} value={option.value}>
      {option.label}
    </option>
  ))

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered>
      <ModalBody>
        <Trans
          t={t}
          i18nKey={`modal.confirm.selectedVideos.${type}`}
          components={{
            selectTag: (
              <Select
                onChange={(e) => onChangeInput(InputType.TAG_IDS, e)}
                options={tagOptions}
                closeMenuOnSelect={false}
                isMulti
              />
            ),
            selectCategory: (
              <Input
                onChange={(e) => onChangeInput(InputType.CATEGORY_ID, e)}
                options={categoryOptions}
                type="select"
              >
                {categoryOptions}
              </Input>
            ),
            selectVideoStatus: (
              <Input
                id="videoStatusForm"
                value={newVideo.status}
                onChange={onChangeStatus}
                type="select"
              >
                {statusOptions}
              </Input>
            ),
            selectPublishGroup: (
              <Select
                onChange={(e) => onChangeInput(InputType.GROUP_IDS, e)}
                options={groupOptions}
                closeMenuOnSelect={false}
                isMulti
              />
            ),
            selectCreator: (
              <Input
                onChange={(e) => onChangeInput(InputType.CREATOR_ID, e)}
                options={creatorOptions}
                type="select"
              >
                {creatorOptions}
              </Input>
            ),
          }}
        />

        {type === MultiVideoUpdateType.CHANGE_VIDEO_STATUS &&
          newVideo.status !== VideoStatus.PRIVATE && (
            <>
              <Label className="mt-3" for="releasedAt">
                {adminVideo.t('form.releasedAt')}
              </Label>
              <Input
                id="releasedAt"
                onChange={(e) => onChangeInput(InputType.RELEASED_AT, e)}
                value={getReleaseDate(newVideo)}
                max={datetimeLocalFormat(nowTimestamp())}
                type="datetime-local"
                disabled={newVideo.status === VideoStatus.PUBLICATION_PERIOD}
              />
            </>
          )}
        {type === MultiVideoUpdateType.CHANGE_VIDEO_STATUS &&
          newVideo.status === VideoStatus.PUBLICATION_PERIOD && (
            <FromToDateTime
              fromLabel={adminVideo.t('form.publicationPeriod.from')}
              toLabel={adminVideo.t('form.publicationPeriod.to')}
              from={defaultPublicationPeriod.start}
              to={defaultPublicationPeriod.end!}
              onChange={(e, isFrom) =>
                onChangeLimitedAccess(isFrom ? 'start' : 'end', e)
              }
            />
          )}
        <Form>
          <FooterButtons
            leftContent="キャンセル"
            rightContent="OK"
            leftOnClick={toggleModal}
            rightOnClick={onUpdateVideos}
          />
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default VideoMultiSelectModal
