import { ITest, ITestQuestion, ITestQuestionType } from 'common/interfaces/test'
import { ITestAnswer } from 'common/interfaces/test_answer'
import { IVideo } from 'common/interfaces/video'
import QuillHtml from 'components/atoms/Quill/QuillHtml'
import { TFunction } from 'i18next'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import {
  canAnswerTest,
  existsTest,
  getOldTestAnswer,
  getTest,
  isAlreadyPassed,
  isCorrectForCheckbox,
  onAnswer as serviceOnAnswer,
  onChangeInput as serviceOnChangeInput,
} from 'services/user/video_detail_test'
import TestPassedModal from './TestPassedModal'
import './test.scss'

interface ITestProps {
  video: IVideo
}

const Test: React.FC<ITestProps> = ({ video }: ITestProps) => {
  const { t } = useTranslation('userVideo')
  const { storeCache } = useContext(AuthContext)
  const testExists = existsTest(video)
  const [test, setTest] = useState<ITest | null>(null)
  const [oldTestAnswer, setOldTestAnswer] = useState<ITestAnswer | null>(null)
  const [answers, setAnswers] = useState<(number | number[])[]>([])
  const [isShowCorrectAnswer, setIsShowCorrectAnswer] = useState(false)
  const [isTestPassed, setIsTestPassed] = useState(false)
  const [isTestPassedModalOpen, setIsTestPassedModalOpen] = useState(false)
  const testPassedModalToggle = () =>
    setIsTestPassedModalOpen(!isTestPassedModalOpen)

  useEffect(() => {
    getTest(storeCache, video, setTest)
  }, [storeCache, video])

  useEffect(() => {
    getOldTestAnswer(storeCache, video, test, setOldTestAnswer)
  }, [storeCache, video, test])

  if (!testExists || !test || !oldTestAnswer) return null

  const onAnswer = () =>
    serviceOnAnswer(storeCache, test, video.id, {
      oldTestAnswer,
      setOldTestAnswer,
      answers,
      setAnswers,
      isShowCorrectAnswer,
      setIsShowCorrectAnswer,
      setIsTestPassed,
      setIsTestPassedModalOpen,
    })

  const testElement =
    canAnswerTest(test, oldTestAnswer) || isShowCorrectAnswer
      ? canAnswerElement(
          t,
          test,
          oldTestAnswer,
          testPassedModalToggle,
          answers,
          setAnswers,
          isShowCorrectAnswer,
          isTestPassed,
          onAnswer
        )
      : canNotAnswerElement(t, test, oldTestAnswer, testPassedModalToggle)

  return (
    <div className="test">
      {testElement}

      <TestPassedModal
        test={test}
        modal={{ isOpen: isTestPassedModalOpen, toggle: testPassedModalToggle }}
      />
    </div>
  )
}

const alreadyAnsweredElement = (
  t: TFunction<'userVideo', undefined>,
  { passed: { button_text } }: ITest,
  oldTestAnswer: ITestAnswer,
  testPassedModalToggle: () => void
) => {
  const answeredCount = oldTestAnswer.answers.length
  const isPassed = isAlreadyPassed(oldTestAnswer)

  const passed = isPassed
    ? t('test.alreadyAnsweredElement.pass')
    : t('test.alreadyAnsweredElement.failure')
  const note =
    answeredCount <= 1
      ? t('test.alreadyAnsweredElement.pastJudgment', { passed })
      : t('test.alreadyAnsweredElement.pastJudgments', {
          answeredCount,
          passed,
        })

  return (
    <>
      <h2>{t('test.alreadyAnsweredElement.alreadyAnswered')}</h2>
      <div>{note}</div>

      {isPassed && (
        <div className="passed-btn-wrapper">
          <Button className="passed-btn" onClick={testPassedModalToggle}>
            {button_text || t('test.alreadyAnsweredElement.show')}
          </Button>
        </div>
      )}
    </>
  )
}

const canNotAnswerElement = (
  t: TFunction<'userVideo', undefined>,
  test: ITest,
  oldTestAnswer: ITestAnswer,
  testPassedModalToggle: () => void
) => {
  const answeredElement = alreadyAnsweredElement(
    t,
    test,
    oldTestAnswer,
    testPassedModalToggle
  )
  return (
    <>
      <h2 className="test-title">{test.name}</h2>
      {answeredElement}
    </>
  )
}

const canAnswerElement = (
  t: TFunction<'userVideo', undefined>,
  test: ITest,
  oldTestAnswer: ITestAnswer,
  testPassedModalToggle: () => void,
  answers: (number | number[])[],
  setAnswers: React.Dispatch<React.SetStateAction<(number | number[])[]>>,
  isShowCorrectAnswer: boolean,
  isTestPassed: boolean,
  onAnswer: () => void
) => {
  const answeredElement =
    isAlreadyPassed(oldTestAnswer) &&
    alreadyAnsweredElement(t, test, oldTestAnswer, testPassedModalToggle)

  return (
    <>
      <h2 className="test-title">{test.name}</h2>
      {answeredElement}
      {questionElements(t, test, answers, setAnswers, isShowCorrectAnswer)}

      <div className="text-center">
        {isShowCorrectAnswer && !isTestPassed && (
          <div className="test-failed">
            {t('test.canAnswerElement.failureMessage')}
          </div>
        )}
        <Button onClick={onAnswer} className="btn-answer">
          {isShowCorrectAnswer && isTestPassed
            ? t('test.canAnswerElement.confirm')
            : isShowCorrectAnswer
            ? t('test.canAnswerElement.retest')
            : t('test.canAnswerElement.answer')}
        </Button>
      </div>
    </>
  )
}

const questionElements = (
  t: TFunction<'userVideo', undefined>,
  test: ITest,
  answers: (number | number[])[],
  setAnswers: React.Dispatch<React.SetStateAction<(number | number[])[]>>,
  isShowCorrectAnswer: boolean
) => {
  const onChangeInput = (
    questionIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
    questionType: ITestQuestionType
  ) => serviceOnChangeInput(answers, setAnswers, questionIndex, e, questionType)

  const isCorrect = (
    { type, correct_index }: ITestQuestion,
    questionIndex: number
  ): boolean => {
    if (type === ITestQuestionType.RADIO) {
      return correct_index === answers[questionIndex]
    }
    return isCorrectForCheckbox(correct_index, answers, questionIndex)
  }

  return test.questions.map((question, questionIndex) => (
    <div className="question-item" key={questionIndex}>
      <div className="question-title">
        <QuillHtml html={question.name} editorClassName="p-0" />
      </div>
      {question.options.map((option, optionIndex) => (
        <FormGroup check key={optionIndex}>
          <Input
            type={
              question.type === ITestQuestionType.RADIO ? 'radio' : 'checkbox'
            }
            id={`test-question-${questionIndex}-${optionIndex}`}
            name={`question-${questionIndex}`}
            value={optionIndex}
            onChange={(e) => onChangeInput(questionIndex, e, question.type)}
            checked={answerChecked(
              question,
              answers,
              questionIndex,
              optionIndex
            )}
            disabled={isShowCorrectAnswer}
          />
          <Label htmlFor={`test-question-${questionIndex}-${optionIndex}`}>
            {option}
          </Label>
        </FormGroup>
      ))}
      {isShowCorrectAnswer && (
        <div className="correct-answer">
          <div>
            {isCorrect(question, questionIndex)
              ? t('test.questionElement.correct')
              : t('test.questionElement.incorrect')}
          </div>
          <div>
            {isCorrect(question, questionIndex) ||
              t('test.questionElement.yourAnswer', {
                showAnswers: showAnswers(question, answers, questionIndex),
              })}
          </div>
          <div>
            {!isCorrect(question, questionIndex) &&
              test.failed.is_show_answer &&
              t('test.questionElement.correctAnswer', {
                answer:
                  typeof question.correct_index === 'number'
                    ? question.options[question.correct_index]
                    : question.correct_index.map((ci) => question.options[ci]),
              })}
          </div>
          <div>
            {!isCorrect(question, questionIndex) &&
              test.failed.is_show_explain &&
              t('test.questionElement.explanation', {
                explain: question.explain,
              })}
          </div>
        </div>
      )}
    </div>
  ))
}

const answerChecked = (
  { type }: ITestQuestion,
  answers: (number | number[])[],
  questionIndex: number,
  optionIndex: number
): boolean => {
  if (answers[questionIndex] === undefined) return false
  if (type === ITestQuestionType.RADIO) {
    return answers[questionIndex] === optionIndex
  }
  return (answers[questionIndex] as number[]).includes(optionIndex)
}

const showAnswers = (
  { type, options }: ITestQuestion,
  answers: (number | number[])[],
  questionIndex: number
): string | string[] => {
  if (type === ITestQuestionType.RADIO) {
    return options[answers[questionIndex] as number]
  }
  return (answers[questionIndex] as number[]).map((a) => options[a])
}

export default Test
