import { ReplyCommentInputType } from 'common/enums'
import { ICommentInfo } from 'common/interfaces/comment'
import {
  IReplyCommentContent,
  IReplyCommentInfo,
} from 'common/interfaces/reply_comment'
import { IUser } from 'common/interfaces/user'
import { IVideo } from 'common/interfaces/video'
import { nl2br } from 'common/utils'
import { AuthContext } from 'providers/AuthProvider'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Input, Row } from 'reactstrap'
import {
  deleteComment,
  deleteReplyComment,
  getComments,
  saveComment,
  saveReplyComment,
} from 'services/user/video_detail_comment'
import './comment.scss'

interface ICommentProps {
  video: IVideo
}

const Comment: React.FC<ICommentProps> = ({ video }: ICommentProps) => {
  const { t } = useTranslation('userVideo')
  const { storeCache } = useContext(AuthContext)
  const [comments, setComments] = useState<ICommentInfo[]>([])
  const commentInputRef = useRef<HTMLTextAreaElement>(null)
  const [replyCommentContent, setReplyCommentContent] = useState<
    IReplyCommentContent[]
  >([])

  const fetchComments = useCallback(() => {
    getComments(storeCache, video, setComments, setReplyCommentContent)
  }, [storeCache, video])

  useEffect(() => {
    fetchComments()
  }, [fetchComments])

  const onChangeInput = (
    inputType: ReplyCommentInputType,
    key: number,
    e: any
  ) => {
    const val = e.target?.value
    const tmpReplyCommentContent = [...replyCommentContent]
    switch (inputType) {
      case ReplyCommentInputType.CONTENT:
        tmpReplyCommentContent[key].content = val
        break
      case ReplyCommentInputType.SHOW:
        tmpReplyCommentContent[key].show = !tmpReplyCommentContent[key].show
        break
    }
    setReplyCommentContent(tmpReplyCommentContent)
  }
  const sendComment = () =>
    saveComment(storeCache, video, commentInputRef, fetchComments)
  const removeComment = (comment: ICommentInfo) =>
    deleteComment(storeCache, video, comment, fetchComments)

  const sendReplyComment = (comment: ICommentInfo, key: number) =>
    saveReplyComment(
      storeCache,
      video,
      comment,
      replyCommentContent[key],
      fetchComments
    )
  const removeReplyComment = (
    comment: ICommentInfo,
    replyComment: IReplyCommentInfo
  ) =>
    deleteReplyComment(storeCache, video, comment, replyComment, fetchComments)

  return (
    <div className="video-comment">
      <h2>{t('comment.severalComments', { length: comments.length })}</h2>
      <Input
        className="video-comment__textarea"
        innerRef={commentInputRef}
        type="textarea"
      />
      <div className="text-right my-3">
        <Button
          className="video-comment__btn-do-comment"
          onClick={sendComment}
          type="button"
        >
          {t('comment.toComment')}
        </Button>
      </div>
      <hr />
      {comments.map((comment, key) => (
        <div className="comment" key={key}>
          <Row>
            <Col>
              <div className="username">{comment.user_name}</div>
              <div className="time">{comment.created_at}</div>
              <div className="content">{nl2br(comment.content)}</div>
            </Col>
            {deleteButtonCol(storeCache.user!, comment, () =>
              removeComment(comment)
            )}
          </Row>
          <div className="reply-button">
            <Button
              onClick={(e) => onChangeInput(ReplyCommentInputType.SHOW, key, e)}
              className="video-comment__btn-reply-comment-content"
              type="button"
              size="sm"
              outline
            >
              {replyCommentContent[key]?.show
                ? t('comment.replyCommentContent.hidden')
                : t('comment.replyCommentContent.reply')}
            </Button>
          </div>
          {replyCommentContent[key]?.show && (
            <div className="reply-text-form">
              <Input
                className="reply-text-area"
                onChange={(e) =>
                  onChangeInput(ReplyCommentInputType.CONTENT, key, e)
                }
                type="textarea"
              />
              <div className="reply-text-send">
                <Button
                  onClick={() => sendReplyComment(comment, key)}
                  className="video-comment__btn-reply-comment"
                  type="button"
                  size="sm"
                >
                  {t('comment.replyComment')}
                </Button>
              </div>
            </div>
          )}

          {replyComments(comment, storeCache.user!, removeReplyComment)}
        </div>
      ))}
    </div>
  )
}

const replyComments = (
  comment: ICommentInfo,
  user: IUser,
  removeReplyComment: (
    comment: ICommentInfo,
    replyComment: IReplyCommentInfo
  ) => void
) => {
  if (comment.reply_comments.length <= 0) return null

  return (
    <div className="reply-comments">
      {comment.reply_comments.map((replyComment, childKey) => (
        <div className="comment" key={childKey}>
          <Row>
            <Col>
              <div className="username">{replyComment.user_name}</div>
              <div className="time">{replyComment.created_at}</div>
              <div className="content">{nl2br(replyComment.content)}</div>
            </Col>
            {deleteButtonCol(user, replyComment, () =>
              removeReplyComment(comment, replyComment)
            )}
          </Row>
        </div>
      ))}
    </div>
  )
}

const deleteButtonCol = (
  user: IUser,
  comment: ICommentInfo | IReplyCommentInfo,
  removeComment: () => void
) => {
  const canDeleteComment = user.admin || comment.user_id === user.id
  if (!canDeleteComment) return null

  return (
    <Col>
      <Button
        onClick={removeComment}
        color="danger"
        type="button"
        size="sm"
        outline
      >
        <i className="fas fa-trash" />
      </Button>
    </Col>
  )
}

export default Comment
