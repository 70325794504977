import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Modal, Row, Input, Label, Button } from 'reactstrap'
import { onSubmitTestEmailForm } from 'services/admin/mail_template'

type TTestMailModal = {
  isOpen: boolean
  onToggleModal: () => void
  onSubmit: (email: string) => Promise<void>
}

const TestMailModal: React.FC<TTestMailModal> = (props) => {
  const [email, setEmail] = useState<string>('')

  useEffect(() => {
    // 閉じたらリセット
    if (!props.isOpen) {
      setEmail('')
    }
  }, [props.isOpen])

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value)
    },
    [setEmail]
  )

  const handleOnSubmit = useCallback(async () => {
    await onSubmitTestEmailForm(email, props.onSubmit, props.onToggleModal)
  }, [email, props])

  const disabled = useMemo(() => {
    return !email.length
  }, [email])

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={props.isOpen}
      toggle={props.onToggleModal}
      size="lg"
    >
      <div className="modal-header">
        <p className="h2">メール送信テスト</p>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <Label color="danger">
              メールの内容を確認できます。自分自身または同僚にテスト送信しましょう。
            </Label>
            <Input
              type="email"
              value={email}
              onChange={handleOnChange}
              placeholder="aaa@bbb.co.jp"
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <div className="d-flex justify-content-between">
          <Button color="secondary" type="button" onClick={props.onToggleModal}>
            キャンセル
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={handleOnSubmit}
            disabled={disabled}
          >
            送信
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default TestMailModal
