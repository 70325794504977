import { ColumnDef } from '@tanstack/react-table'
import { AuthMethodType, Routes } from 'common/enums'
import { IInvite } from 'common/interfaces/invite'
import { IUser } from 'common/interfaces/user'
import ButtonRight from 'components/atoms/Button/Right'
import {
  customFieldColumnDef,
  useTanstackTableWithCsvExport,
} from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeCustomOperationButtons,
} from 'components/atoms/Table/ElementsOnTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import {
  approveUser,
  removePendingUser,
} from 'services/admin/approval_pending_user'
import { getUnapprovedUserByInvited } from 'services/admin/user'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminInvite')
  const { storeCache } = useContext(AuthContext)
  const invitedId = useLocation<IInvite>().state.id
  const [invitedUsers, setInvitedUsers] = useState<IUser[]>([])
  useEffect(() => {
    getUnapprovedUserByInvited(setInvitedUsers, invitedId, storeCache)
  }, [setInvitedUsers, invitedId, storeCache])

  return (
    <>
      <ButtonRight
        nextPage={Routes.AdminInvite}
        content={t('approvalPendingUser.backToList')}
      />
      <Table invitedId={invitedId} invitedUsers={invitedUsers} />
    </>
  )
}

const Table: React.FC<{ invitedId: string; invitedUsers: IUser[] }> = ({
  invitedId,
  invitedUsers,
}) => {
  const i18n = useTranslation('adminInvite').t('approvalPendingUser.table', {
    returnObjects: true,
  })
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const isCustomAuthMethod =
    storeCache.team!.auth_method === AuthMethodType.CUSTOM
  const columns = useMemo<ColumnDef<IUser>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
        meta: { hidden: !isCustomAuthMethod },
      },
      {
        header: i18n.email,
        accessorKey: 'email',
        meta: { hidden: isCustomAuthMethod },
      },
      {
        header: i18n.name,
        accessorFn: (u) => u.name ?? '',
      },
      {
        header: i18n.phone,
        accessorFn: (u) => u.phone ?? '',
      },
      ...customFieldColumnDef<IUser>(storeCache.team!, invitedUsers),
      {
        header: i18n.operations,
        accessorFn: (u) =>
          makeCustomOperationButtons([
            makeCustomOperationButton(i18n.approve, () =>
              approveUser(storeCache, u, invitedId)
            ),
            makeCustomOperationButton(i18n.delete, () =>
              removePendingUser(storeCache, { history, invitedId }, u.id)
            ),
          ]),
        meta: { csvExport: false },
      },
    ],
    [storeCache, history, invitedId, isCustomAuthMethod, invitedUsers, i18n]
  )
  const data = invitedUsers.sort(
    (a, b) => b.created_at.seconds - a.created_at.seconds
  )
  return useTanstackTableWithCsvExport<IUser>(
    columns,
    data,
    'approval_pending_user.csv'
  )
}

export default TableBody
