import { Routes } from 'common/enums'
import { ITeam } from 'common/interfaces/team'
import { makePath2Url } from 'common/link_url'
import { getAccountLinks } from 'repositories/functions/functions_stripe'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/billing'

/**
 * Stripeでアカウントを作成するためのURLを取得する
 */
export const transitionCreateAccountInStripe = async (
  team: ITeam
): Promise<void> => {
  try {
    validateForm(team)

    const redirectUri = makePath2Url(Routes.AdminBilling)
    const { url } = await getAccountLinks(redirectUri)
    window.location.href = url
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
