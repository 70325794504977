import classNames from 'classnames'
import React from 'react'
import { NavItem, NavLink } from 'reactstrap'

interface IItemCollapse {
  content: string
  isOpen: boolean
  toggleCollapse: () => void
}

const ItemCollapse: React.FC<IItemCollapse> = (props: IItemCollapse) => {
  return (
    <NavItem>
      <NavLink onClick={props.toggleCollapse} style={{ cursor: 'pointer' }}>
        <i
          className={classNames('fas', {
            'fa-caret-down': props.isOpen,
            'fa-caret-right': !props.isOpen,
          })}
        />
        {props.content}
      </NavLink>
    </NavItem>
  )
}

export default ItemCollapse
