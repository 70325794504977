import { ICategory } from 'common/interfaces/category'
import { ITeam } from 'common/interfaces/team'
import { nowTimestamp } from 'common/times'
import { Teams } from './firebase_team'

const Categories = (team: ITeam) => Teams.doc(team.id).collection('categories')

/**
 * get all categories
 */
export const get = async (team: ITeam): Promise<ICategory[]> => {
  try {
    const categoryDatas = await Categories(team).get()
    return categoryDatas.docs.map((category) => category.data() as ICategory)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * add category
 * @throws Firebase error
 */
export const store = async (
  team: ITeam,
  category: ICategory
): Promise<void> => {
  await Categories(team).doc(category.id).set(category)
}

/**
 * update category
 * @throws Firebase error
 */
export const update = async (
  team: ITeam,
  category: ICategory
): Promise<void> => {
  category.updated_at = nowTimestamp()
  await Categories(team).doc(category.id).update(category)
}

/**
 * delete category
 * @throws Firebase error
 */
export const remove = async (
  team: ITeam,
  category: ICategory
): Promise<void> => {
  await Categories(team).doc(category.id).delete()
}

/**
 * remove category image
 * @throws Firebase error
 */
export const removeImage = async (
  team: ITeam,
  category: ICategory
): Promise<void> => {
  category.image = null
  category.updated_at = nowTimestamp()
  await Categories(team).doc(category.id).update(category)
}
