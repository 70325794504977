import Footer from 'components/molecules/Footer'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, Container } from 'reactstrap'
import { signOut } from 'services/user/setting'

const Unapproved: React.FC = () => {
  const { t } = useTranslation('join')
  const history = useHistory()
  const { storeCache } = useContext(AuthContext)
  const logOut = () => signOut(history, storeCache)

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ height: '75vh' }}
      >
        <div>
          {t('unapproved.pleaseWaitUntilAdminApprove')}
          <div className="text-center mt-5">
            <Button onClick={logOut} color="primary" type="button">
              {t('unapproved.logout')}
            </Button>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default Unapproved
