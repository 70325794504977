import Footer from 'components/molecules/Footer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'

const InvalidInviteCode: React.FC = () => {
  const { t } = useTranslation('join')
  const message =
    useLocation<{ message: string } | undefined>().state?.message ??
    t('invalidInviteCode.happenedUnknownError')

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center text-center"
        style={{ height: '75vh' }}
      >
        <div>
          {message}
          <br />
          {t('invalidInviteCode.pleaseContactAdmin')}
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default InvalidInviteCode
