import { ColumnDef } from '@tanstack/react-table'
import {
  findCachedCategory,
  findCachedVideoAndLivesNonNull,
} from 'common/find_store_cache'
import { IEvent } from 'common/interfaces/event'
import { IVideo } from 'common/interfaces/video'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeGroupBadges,
  makeTagBadges,
  makeThumbnailElement,
  makeVideoStatusBadge,
} from 'components/atoms/Table/ElementsOnTable'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface ITableBody {
  eventObj: IEvent
}

const TableBody: React.FC<ITableBody> = ({ eventObj }) => {
  const { t } = useTranslation('adminEvent')
  const { storeCache } = useContext(AuthContext)

  const columns = useMemo<ColumnDef<IVideo>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => i + 1,
      },
      {
        header: t('list.table.status'),
        accessorFn: (v) => makeVideoStatusBadge(v.status),
      },
      {
        header: t('list.table.name'),
        accessorKey: 'name',
      },
      {
        header: t('list.table.thumbnail'),
        accessorFn: (v) => makeThumbnailElement(v.image, v.name, 'event'),
      },
      {
        header: t('list.table.category'),
        accessorFn: (v) =>
          findCachedCategory(storeCache, v.category_id)?.name ?? '',
      },
      {
        header: t('list.table.tag'),
        accessorFn: (v) => makeTagBadges(storeCache, v.tag_ids),
      },
      {
        header: t('list.table.group'),
        accessorFn: (v) => makeGroupBadges(storeCache, v.group_ids),
      },
    ],
    [storeCache, t]
  )

  const data = findCachedVideoAndLivesNonNull(storeCache, eventObj.contents)
  return useTanstackTable<IVideo>(columns, data, {
    withSearch: true,
  })
}

export default TableBody
