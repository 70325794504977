import { EntryType, Routes, ViewType } from 'common/enums'
import { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import {
  makeAnswerToEnquetePath,
  makeEntryPath,
  makeItemSelectPath,
  makeLoginTeamPath,
  makeUserRootPath,
} from 'common/link_path'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
  cancelledTeam,
  checkDisabled,
  disabledUser,
  restrictedIp,
  restrictedSingleLogin,
} from 'services/auth'
import { LOCAL_STORAGE_TEAM_ID } from 'services/local_storage'
import { isNotPaidUser, isNotTrialAndNotSubscribe } from 'services/subscribe'

const LoggedIn: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const [viewType, setViewType] = useState<ViewType>(ViewType.LOADING)

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (isLoading(storeCache)) {
        setViewType(ViewType.LOADING)
      } else if (user === null) {
        setViewType(ViewType.LOGIN)
      } else if (checkDisabled(storeCache)) {
        setViewType(ViewType.DISABLED_USER)
      } else if (!storeCache.isSingleLogin) {
        setViewType(ViewType.RESTRICTED_SINGLE_LOGIN)
      } else if (!storeCache.isIpAllowed) {
        setViewType(ViewType.RESTRICTED_IP)
      } else if (isNotTrialAndNotSubscribe(storeCache)) {
        storeCache.user?.admin
          ? setViewType(ViewType.NOTSUBSCRIBE)
          : setViewType(ViewType.ENTRY)
      } else if (isNotPaidUser(storeCache)) {
        storeCache.user?.customer_id
          ? setViewType(ViewType.UPDATE_FAILURE)
          : setViewType(ViewType.NOTPAID)
      } else if (storeCache.user?.is_approved === true) {
        setViewType(ViewType.UNAPPROVED)
      } else if (storeCache.user?.is_password_changeable) {
        setViewType(ViewType.CHANGE_PASSWORD)
      } else if (storeCache.team?.cancelled_date) {
        setViewType(ViewType.CANCELLED)
      } else if (
        !storeCache.user?.admin &&
        storeCache.team?.enquete_id &&
        !storeCache.isAnswered
      ) {
        setViewType(ViewType.ANSWER_TO_ENQUETE)
      } else {
        setViewType(ViewType.VISIBLE)
      }
    })
  }, [setViewType, storeCache])

  const history = useHistory()
  useEffect(() => {
    switch (viewType) {
      case ViewType.LOGIN:
        const teamId = LOCAL_STORAGE_TEAM_ID
        if (teamId) {
          history.replace(makeLoginTeamPath(teamId))
        } else {
          const path = makeEntryPath(
            storeCache.team?.id,
            storeCache.user?.admin
          )
          history.replace(path, { type: EntryType.NOT_TEAM })
        }
        break
      case ViewType.DISABLED_USER:
        disabledUser(history, storeCache)
        break
      case ViewType.RESTRICTED_SINGLE_LOGIN:
        restrictedSingleLogin(history, storeCache)
        break
      case ViewType.RESTRICTED_IP:
        restrictedIp(history, storeCache)
        break
      case ViewType.NOTSUBSCRIBE: {
        const path = makeEntryPath(storeCache.team?.id, storeCache.user?.admin)
        history.replace(path, { type: EntryType.NOT_SUBSCRIBE })
        break
      }
      case ViewType.NOTPAID:
        history.replace(makeItemSelectPath(storeCache.team!.id))
        break
      case ViewType.UPDATE_FAILURE:
        history.replace(Routes.CreditCardUpdate)
        break
      case ViewType.UNAPPROVED:
        history.replace(Routes.Unapproved)
        break
      case ViewType.CANCELLED:
        cancelledTeam(history, storeCache)
        break
      case ViewType.ANSWER_TO_ENQUETE: {
        const path = makeAnswerToEnquetePath(storeCache.team!.id)
        history.replace(path)
        break
      }
      case ViewType.CHANGE_PASSWORD:
        history.replace(Routes.ChangePassword)
        break
      case ViewType.ENTRY: {
        const path = makeEntryPath(storeCache.team?.id, storeCache.user?.admin)
        history.replace(path, { type: EntryType.NOT_TEAM })
        break
      }
      case ViewType.VISIBLE:
        history.replace(
          storeCache.user!.admin
            ? Routes.Admin
            : makeUserRootPath(storeCache.team?.id)
        )
        break
    }
  }, [viewType, history, storeCache])

  if (viewType === ViewType.LOADING || viewType === ViewType.GONE) {
    return <FullLoadingSpinner />
  }

  return null
}

const isLoading = (storeCache: IStoreCache) =>
  storeCache.user === null || storeCache.isLoading

export default LoggedIn
