import { IEvent } from 'common/interfaces/event'
import { ITeam } from 'common/interfaces/team'
import { VideoStatus } from 'common/interfaces/video'
import { nowTimestamp } from 'common/times'
import { Teams } from './firebase_team'

const Events = (team: ITeam) => Teams.doc(team.id).collection('events')

/**
 * is show event?
 * @param data `IEvent`
 * @param isOnlyForUser is event for user?
 * @param nowTime current unixtime
 */
export const isShow = (
  { status, publication_period }: IEvent,
  isOnlyForUser: boolean,
  nowTime: number
): boolean =>
  (isOnlyForUser && status === VideoStatus.PUBLIC) ||
  (isOnlyForUser &&
    status === VideoStatus.PUBLICATION_PERIOD &&
    publication_period!.start.seconds <= nowTime &&
    publication_period!.end!.seconds >= nowTime) ||
  !isOnlyForUser

const getEvents = async (
  team: ITeam,
  isOnlyForUser: boolean
): Promise<IEvent[]> => {
  const datas = await Events(team).orderBy('created_at', 'desc').get()
  return datas.docs
    .map((event) => event.data() as IEvent)
    .filter((event) => isShow(event, isOnlyForUser, nowTimestamp().seconds))
}

/**
 * get events order by created_at
 *
 * includes secret events
 */
export const get = async (team: ITeam) => getEvents(team, false)

/**
 * get for user events order by created_at
 *
 * excludes secret events
 */
export const getForUser = async (team: ITeam) => getEvents(team, true)

/**
 * get for non auth user events order by created_at
 *
 * `team.is_site_public` must be true
 */
export const getForNonAuthUser = async (team: ITeam): Promise<IEvent[]> => {
  const nowTime = nowTimestamp().seconds
  const datas = await Events(team)
    .where('is_public', '==', true)
    .orderBy('created_at', 'desc')
    .get()
  return datas.docs
    .map((event) => event.data() as IEvent)
    .filter(
      ({ status, publication_period }) =>
        status === VideoStatus.PUBLIC ||
        (status === VideoStatus.PUBLICATION_PERIOD &&
          publication_period!.end!.seconds >= nowTime &&
          publication_period!.start.seconds <= nowTime)
    )
}

/**
 * add event
 * @throws Firebase error
 */
export const store = async (team: ITeam, event: IEvent): Promise<void> => {
  await Events(team).doc(event.id).set(event)
}

/**
 * update event
 * @throws Firebase error
 */
export const update = async (team: ITeam, event: IEvent): Promise<void> => {
  event.updated_at = nowTimestamp()
  await Events(team).doc(event.id).update(event)
}

/**
 * delete event
 * @throws Firebase error
 */
export const remove = async (team: ITeam, event: IEvent): Promise<void> => {
  await Events(team).doc(event.id).delete()
}

/**
 * remove event image
 * @throws Firebase error
 */
export const removeImage = async (
  team: ITeam,
  event: IEvent
): Promise<void> => {
  event.image = null
  event.updated_at = nowTimestamp()
  await Events(team).doc(event.id).update(event)
}
