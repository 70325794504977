import { IMailTemplate } from 'common/interfaces/team'
import MailTemplateForm from 'components/molecules/Admin/MailTemplateForm/MailTemplateForm'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import {
  initMailTemplate,
  InputType,
  onChangeInput as serviceOnChangeInput,
  onChangeInputQuill as serviceOnChangeInputQuill,
  saveMailTemplate,
} from 'services/admin/mail_template_form'

type TFormBody = {
  mailTemplateObj: IMailTemplate
  history: History
}

const FormBody: React.FC<TFormBody> = ({
  mailTemplateObj,
  history,
}: TFormBody) => {
  const { storeCache } = useContext(AuthContext)
  const [mailTemplate, setMailTemplate] = useState<IMailTemplate>(
    initMailTemplate(mailTemplateObj)
  )

  const onChange = (type: InputType, e: React.ChangeEvent<HTMLInputElement>) =>
    serviceOnChangeInput(mailTemplate, setMailTemplate, type, e)
  const onChangeQuill = (e: string) =>
    serviceOnChangeInputQuill(mailTemplate, setMailTemplate, e)
  const save = () => saveMailTemplate(history, mailTemplate, storeCache)

  return (
    <MailTemplateForm
      isAdministrator={false}
      mailTemplate={mailTemplate}
      onChange={onChange}
      onChangeQuill={onChangeQuill}
      save={save}
    />
  )
}

export default FormBody
