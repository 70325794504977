import { IEventApplication } from 'common/interfaces/event_application'
import { ITeam } from 'common/interfaces/team'
import { Teams } from './firebase_team'

const EventApplications = (team: ITeam, eventId: string) =>
  Teams.doc(team.id)
    .collection('events')
    .doc(eventId)
    .collection('event_applications')

/**
 * get event_applications of team and event
 */
export const get = async (
  team: ITeam,
  eventId: string
): Promise<IEventApplication[]> =>
  (await EventApplications(team, eventId).get()).docs.map(
    (d) => d.data() as IEventApplication
  )

/**
 * add event
 * @throws Firebase error
 */
export const store = async (
  team: ITeam,
  eventId: string,
  eventApplication: IEventApplication
): Promise<void> => {
  await EventApplications(team, eventId)
    .doc(eventApplication.id)
    .set(eventApplication)
}
