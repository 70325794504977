import { ColumnDef } from '@tanstack/react-table'
import { Routes } from 'common/enums'
import { IEnquete } from 'common/interfaces/enquete'
import { IStripe } from 'common/interfaces/stripe'
import { goBackWithErrorAlert, isBasicPrice } from 'common/utils'
import ButtonRight from 'components/atoms/Button/Right'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import { useTanstackTable } from 'components/atoms/Table/CreateTable'
import {
  makeCustomOperationButton,
  makeEditDeleteOperationButtons,
} from 'components/atoms/Table/ElementsOnTable'
import RegisterItemButton from 'components/molecules/Admin/RegisterItemButton'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  getEnquetesWithAnswerCount,
  getEnqueteUseCount,
  IEnqueteData,
  removeEnquete,
} from 'services/admin/enquete'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'

const TableBody: React.FC = () => {
  const { t } = useTranslation('adminEnquete')
  const { t: i18nAlert } = useTranslation('alert')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const [enqueteDatas, setEnqueteDatas] = useState<IEnqueteData[]>([])
  const [stripe, setStripe] = useState<IStripe>(initStripe())
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      await getStripe(setStripe)
      await getEnquetesWithAnswerCount(storeCache, setEnqueteDatas)
      setIsLoading(false)
    })()
  }, [storeCache])

  if (isLoading) return <LoadingSpinner />
  if (isBasicPrice(storeCache.subscriptionObj, stripe)) {
    goBackWithErrorAlert(history, i18nAlert('noOperationAuthority'))
    return null
  }
  if (enqueteDatas.length <= 0) {
    return <RegisterItemButton type="enquete" />
  }
  return (
    <>
      <ButtonRight
        nextPage={Routes.AdminEnqueteCreate}
        content={t('list.new')}
      />
      <Table enqueteDatas={enqueteDatas} setEnqueteDatas={setEnqueteDatas} />
    </>
  )
}

const Table: React.FC<{
  enqueteDatas: IEnqueteData[]
  setEnqueteDatas: React.Dispatch<React.SetStateAction<IEnqueteData[]>>
}> = ({ enqueteDatas, setEnqueteDatas }) => {
  const { t } = useTranslation('adminEnquete')
  const { storeCache } = useContext(AuthContext)
  const history = useHistory()

  const columns = useMemo<ColumnDef<IEnqueteData>[]>(
    () => [
      {
        header: 'No',
        accessorFn: (_, i) => enqueteDatas.length - i,
      },
      {
        header: t('list.table.title'),
        accessorKey: 'enquete.title',
      },
      {
        header: t('list.table.usingCount'),
        accessorFn: (e) =>
          t('list.count', {
            count: getEnqueteUseCount(e.enquete, storeCache.videos),
          }),
      },
      {
        header: t('list.table.answerCount'),
        accessorFn: (e) => t('list.count', { count: e.answerCount }),
      },
      {
        header: t('list.table.operations'),
        accessorFn: (e) =>
          makeEditDeleteOperationButtons<IEnquete>(
            history,
            'enquete',
            Routes.AdminEnqueteEdit,
            (e2) => removeEnquete(storeCache, e2, setEnqueteDatas),
            e.enquete,
            {
              buttons: makeCustomOperationButton(t('list.answerList'), () =>
                history.push({
                  pathname: Routes.AdminEnqueteAnswer,
                  state: e.enquete,
                })
              ),
              isFirst: true,
            }
          ),
      },
    ],
    [storeCache, history, enqueteDatas.length, setEnqueteDatas, t]
  )

  const data = enqueteDatas.sort(
    (a, b) => b.enquete.created_at.seconds - a.enquete.created_at.seconds
  )
  return useTanstackTable<IEnqueteData>(columns, data, {
    fixedLastColumn: true,
  })
}

export default TableBody
