import { Routes } from 'common/enums'
import { IComment } from 'common/interfaces/comment'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import TableBody from 'components/organisms/Admin/CommentReply/TableBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'

const CommentReply: React.FC = () => {
  const { t } = useTranslation('adminComment')
  const { comment, videoId } = useLocation<{
    comment: IComment
    videoId: string
  }>().state

  return (
    <>
      <H1 title={t('title.replyComment')} />

      <Container fluid>
        <ButtonRight
          nextPage={`${Routes.AdminComment}?videoId=${videoId}`}
          content={t('backToList')}
        />
        <TableBody comment={comment} videoId={videoId} />
      </Container>
    </>
  )
}

export default CommentReply
