import { Subject } from 'rxjs'

export interface IModal {
  isShow: boolean
  message: string
  isQrCode?: boolean
  isRegisteredTeam?: boolean
  isPaid?: boolean
  isStripeConnect?: boolean
  isDeleteVideo?: boolean
  onOk: () => void
}

const modalSubject = new Subject<IModal>()

export const modalService = {
  onModal,
  show,
  showQrCode,
  showRegisteredTeamThanks,
  showPaymentThanks,
  showStripeConnect,
  showDeleteVideo,
  close,
}

function onModal() {
  return modalSubject.asObservable()
}

function show(message: string, onOk: () => void) {
  modalSubject.next({ isShow: true, message, onOk })
}

function showQrCode(value: string) {
  modalSubject.next({
    isShow: true,
    message: value,
    isQrCode: true,
    onOk: () => {},
  })
}

function showRegisteredTeamThanks(message: string, onOk: () => void) {
  modalSubject.next({ isShow: true, message, isRegisteredTeam: true, onOk })
}

function showPaymentThanks(message: string, onOk: () => void) {
  modalSubject.next({ isShow: true, message, isPaid: true, onOk })
}

function showStripeConnect(onOk: () => void) {
  modalSubject.next({ isShow: true, message: '', isStripeConnect: true, onOk })
}

function showDeleteVideo(onOk: () => void) {
  modalSubject.next({ isShow: true, message: '', isDeleteVideo: true, onOk })
}

function close(closeModal: IModal) {
  modalSubject.next({ ...closeModal, isShow: false })
}
