import axios from 'axios'
import { IFindTeam } from 'common/interfaces/team'

const CF_API_BASE_URL = String(process.env.REACT_APP_FIREBASE_FUNCTIONS_URI)

/**
 * delete team
 * @param teamId team id
 * @param operatedUserId operated user id
 * @returns void
 */
export const deleteTeam = async (
  teamId: string,
  operatedUserId: string
): Promise<void> => {
  const url = `${CF_API_BASE_URL}/team/${teamId}`
  const headers = { 'Content-Type': 'application/json' }
  const data = { operatedUserId }
  await axios.delete(url, { headers, data })
}

/**
 * find team by non authorized user
 * @param teamId team id
 * @returns `IFindTeam | null`
 */
export const findByNonAuthUser = async (
  teamId: string
): Promise<IFindTeam | null> => {
  try {
    const url = `${CF_API_BASE_URL}/team/${teamId}`
    const response = (
      await axios.get<{
        success: boolean
        data: IFindTeam | null
      }>(url)
    ).data
    if (response.success) {
      return response.data
    }
  } catch (e) {
    console.log(e)
  }
  return null
}
