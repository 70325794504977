import { getFileSize } from 'common/utils'
import React from 'react'

interface IFileInfo {
  fileName: string
  fileSize: number
}

const FileInfo: React.FC<IFileInfo> = (props: IFileInfo) => {
  return (
    <aside>
      <p>
        {props.fileName} - {getFileSize(props.fileSize)}
      </p>
    </aside>
  )
}

export default FileInfo
