import { ITeam } from 'common/interfaces/team'
import { ITest } from 'common/interfaces/test'
import { nowTimestamp } from 'common/times'
import { Teams } from './firebase_team'
import { removeAllAnswersInTest } from './firebase_test_answer'

const Tests = (team: ITeam) => Teams.doc(team.id).collection('tests')

/**
 * get all tests
 */
export const get = async (team: ITeam): Promise<ITest[]> => {
  try {
    const testDatas = await Tests(team).orderBy('created_at', 'desc').get()
    return testDatas.docs.map((test) => test.data() as ITest)
  } catch (error) {
    console.log(error)
  }
  return []
}

/**
 * get test by id
 */
export const findById = async (
  team: ITeam,
  testId: string
): Promise<ITest | null> => {
  try {
    const testData = await Tests(team).doc(testId).get()
    return testData.exists ? (testData.data() as ITest) : null
  } catch (error) {
    console.log(error)
  }
  return null
}

/**
 * add test
 * @throws Firebase error
 */
export const store = async (team: ITeam, test: ITest) => {
  await Tests(team).doc(test.id).set(test)
}

/**
 * update test
 * @throws Firebase error
 */
export const update = async (team: ITeam, test: ITest) => {
  test.updated_at = nowTimestamp()
  await Tests(team).doc(test.id).update(test)
}

/**
 * delete test
 * @throws Firebase error
 */
export const remove = async (team: ITeam, test: ITest) => {
  await Tests(team).doc(test.id).delete()
  await removeAllAnswersInTest(team, test)
}
