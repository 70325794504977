import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEnquete } from 'common/interfaces/enquete'
import { IVideo } from 'common/interfaces/video'
import { isLoggedIn } from 'common/utils'
import { i18nAlert, i18nValidation } from 'i18n/i18n'
import { get, remove } from 'repositories/store/firebase_enquete'
import { get as getEnqueteAnswers } from 'repositories/store/firebase_enquete_answer'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

export interface IEnqueteData {
  enquete: IEnquete
  answerCount: number
}

/**
 * アンケート全て取得処理
 */
export const getEnquetes = async (
  storeCache: IStoreCache,
  setEnquetes: React.Dispatch<React.SetStateAction<IEnquete[]>>
): Promise<void> => {
  try {
    setEnquetes(await get(storeCache.team!))
  } catch (error) {
    console.log(error)
  }
}

/**
 * アンケートとその回答数を取得
 */
export const getEnquetesWithAnswerCount = async (
  storeCache: IStoreCache,
  setEnquetes: React.Dispatch<React.SetStateAction<IEnqueteData[]>>
): Promise<void> => {
  try {
    const result = (await get(storeCache.team!)).map((e) => ({
      enquete: e,
      answerCount: 0,
    }))

    const putEnqueteAnswerCount = async (data: IEnqueteData) => {
      data.answerCount = (
        await getEnqueteAnswers(storeCache.team!, data.enquete.id)
      ).length
    }

    const getAnswersTasks = result.map((d) => putEnqueteAnswerCount(d))
    await Promise.all(getAnswersTasks)
    setEnquetes(result)
  } catch (error) {
    console.log(error)
  }
}

/**
 * アンケートを使用している動画の件数を返却する
 */
export const getEnqueteUseCount = (enquete: IEnquete, videos: IVideo[]) => {
  return videos.filter((video) => video.enquete?.enquete_id === enquete.id)
    .length
}

/**
 * 当該アンケート削除処理
 */
export const removeEnquete = async (
  storeCache: IStoreCache,
  enquete: IEnquete,
  setEnquetes: React.Dispatch<React.SetStateAction<IEnqueteData[]>>
): Promise<void> => {
  if (!isLoggedIn(storeCache)) return
  if (
    getEnqueteUseCount(enquete, storeCache.videos) > 0 ||
    (storeCache.team && storeCache.team.enquete_id === enquete.id)
  ) {
    alertService.show(false, i18nValidation('cantDelete.inUse.enquete'))
    return
  }

  modalService.show(i18nAlert('modal.confirm.delete'), async () => {
    const { team } = storeCache

    try {
      await remove(team!, enquete)
      getEnquetesWithAnswerCount(storeCache, setEnquetes)
    } catch (error) {
      console.log(error)
    }
  })
}
