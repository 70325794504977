import { IStoreCache } from 'common/interfaces/auth_provider'
import { IEvent } from 'common/interfaces/event'
import { IEventApplication } from 'common/interfaces/event_application'
import { IUser } from 'common/interfaces/user'
import { History } from 'history'
import { i18nAlert } from 'i18n/i18n'
import { store } from 'repositories/store/firebase_event_application'
import { alertService } from 'services/alert'
import { v4 as uuidv4 } from 'uuid'

/**
 * `IEventApplication`初期値を作成
 */
export const initEventApplication = (user: IUser | null): IEventApplication => {
  return {
    id: uuidv4(),
    user_id: user?.id ?? '',
    is_receive_remind_email: false,
  }
}

export enum EventApplicationInputType {
  IS_RECEIVE_REMIND_EMAIL,
}

/**
 * イベント申し込み入力欄の`onChange`イベントハンドラ
 */
export const onChangeInputForEventApplication = (
  type: EventApplicationInputType,
  { target }: React.ChangeEvent<HTMLInputElement>,
  states: {
    eventApplication: IEventApplication
    setEventApplication: React.Dispatch<React.SetStateAction<IEventApplication>>
  }
): void => {
  const { eventApplication, setEventApplication } = states
  const { checked } = target
  setEventApplication({
    ...eventApplication,
    is_receive_remind_email:
      type === EventApplicationInputType.IS_RECEIVE_REMIND_EMAIL
        ? checked
        : eventApplication.is_receive_remind_email,
  })
}

/**
 * イベント申し込み処理
 */
export const createEventApplication = async (
  history: History,
  storeCache: IStoreCache,
  isEvent: boolean,
  event: IEvent | null,
  eventApplication: IEventApplication,
  onToggleModal: () => void
): Promise<void> => {
  try {
    if (!storeCache.team || !isEvent || !event) {
      throw new Error(i18nAlert('illegalOperation'))
    }

    await store(storeCache.team, event.id, eventApplication)

    onToggleModal()
    alertService.show(true, i18nAlert('applied.event'))
    history.replace(window.location.pathname)
  } catch (error: any) {
    console.log(error)
    alertService.show(false, error.message)
  }
}
