import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'
import './usingtrialnavbar.scss'

export const UsingTrialNavbar: React.FC = () => {
  const { t } = useTranslation('userCommon')

  return (
    <Container fluid className="trial-message-container">
      <div className="trial-message">{t('header.useTrial')}</div>
    </Container>
  )
}
