import { IStripe } from 'common/interfaces/stripe'
import { ITest } from 'common/interfaces/test'
import {
  goBackWithErrorAlert,
  isBasicPrice,
  isLoggedIn,
  isProPrice,
} from 'common/utils'
import LoadingSpinner from 'components/atoms/LoadingSpinner/LoadingSpinner'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import { getStripe, initStripe } from 'services/admin/stripe/stripe'
import {
  InputType,
  initTest,
  isTestEditAll,
  saveTest,
  onChangeInput as serviceOnChangeInput,
} from 'services/admin/test_form'
import FormPartBasic from './FormPartBasic'
import FormPartQuestions from './FormPartQuestions'
import './formbody.scss'

interface IFormBodyProps {
  isCreate: boolean
  testObj: ITest
  history: History
}

const FormBody: React.FC<IFormBodyProps> = ({
  isCreate,
  testObj,
  history,
}: IFormBodyProps) => {
  const { t } = useTranslation('adminTest')
  const { t: i18nAlert } = useTranslation('alert')
  const adminCommon = useTranslation('adminCommon')
  const { storeCache } = useContext(AuthContext)
  const [test, setTest] = useState<ITest>(isCreate ? initTest() : testObj)
  const [stripe, setStripe] = useState<IStripe>(initStripe())

  const [isEditAll, setIsEditAll] = useState<boolean>(false)
  const [isLoadingEditable, setIsLoadingEditable] = useState<boolean>(true)

  useEffect(() => {
    getStripe(setStripe)

    if (isCreate) {
      setIsEditAll(true)
      setIsLoadingEditable(false)
      return
    }
    isTestEditAll(storeCache, testObj, setIsEditAll, setIsLoadingEditable)
  }, [isCreate, storeCache, testObj])

  if (isLoadingEditable) {
    return <LoadingSpinner />
  }

  if (
    isBasicPrice(storeCache.subscriptionObj, stripe) ||
    isProPrice(storeCache.subscriptionObj, stripe)
  ) {
    goBackWithErrorAlert(history, i18nAlert('plans.pro.under.notUseFeature'))
    return null
  }
  if (!isLoggedIn(storeCache)) {
    return null
  }

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement> | string
  ) => serviceOnChangeInput(test, setTest, type, e)
  const save = () => saveTest(isCreate, history, test, storeCache)

  return (
    <div className="test-form">
      <details open>
        <summary>{t('form.basic.title')}</summary>
        <div>
          <FormPartBasic test={test} onChangeInput={onChangeInput} />
        </div>
      </details>
      <details open className="mt-4">
        <summary>{t('form.questions.title')}</summary>
        <div>
          <FormPartQuestions
            test={test}
            setTest={setTest}
            onChangeInput={onChangeInput}
            showAddCardColumn={isCreate || isEditAll}
            isChangePassCorrectCount={isCreate || isEditAll}
          />
        </div>
      </details>

      <div className="text-center mt-3">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {adminCommon.t('save')}
        </Button>
      </div>
    </div>
  )
}

export default FormBody
