import { ITag } from 'common/interfaces/tag'
import { null2str } from 'common/utils'
import InfoTooltip from 'components/atoms/Nav/InfoTooltip'
import ThumbnailCropper from 'components/molecules/Admin/ThumbnailCropper'
import { History } from 'history'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import {
  InputType,
  saveTag,
  onChangeInput as serviceOnChangeInput,
} from 'services/admin/tag_form'

interface ITagFormProps {
  isCreate: boolean
  tagObj: ITag
  history: History
}

const FormBody: React.FC<ITagFormProps> = ({
  isCreate,
  tagObj,
  history,
}: ITagFormProps) => {
  const { t } = useTranslation('adminTag')
  const { storeCache } = useContext(AuthContext)
  const [tag, setTag] = useState<ITag>(tagObj)
  const [imageBlob, setImageBlob] = useState<Blob>()
  const [isImageDelete, setIsImageDelete] = useState<boolean>(false)
  const [isImageCropped, setIsImageCropped] = useState<boolean>(true)

  const onChangeInput = (
    type: InputType,
    e: React.ChangeEvent<HTMLInputElement>
  ) => serviceOnChangeInput(tag, setTag, type, e)
  const save = () =>
    saveTag(
      history,
      tag,
      imageBlob,
      isImageDelete,
      isImageCropped,
      storeCache,
      isCreate
    )

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="tagNameForm">
              <InfoTooltip
                content={t('form.name.title')}
                tooltipContent={t('form.name.tips')}
                required
              />
            </Label>
            <Input
              id="tagNameForm"
              value={tag.name}
              onChange={(e) => onChangeInput(InputType.NAME, e)}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <ThumbnailCropper
        type="tag"
        croppedData={imageBlob}
        setCroppedData={setImageBlob}
        handleIsImageCropped={setIsImageCropped}
        edit={{
          defaultImageUrl: tag.image,
          isImageDelete,
          setIsImageDelete,
        }}
      />
      <Row>
        <Col md="3">
          <FormGroup>
            <Label for="tagSortNumForm">
              <InfoTooltip
                content={t('form.sortNumber.title')}
                tooltipContent={t('form.sortNumber.tips')}
              />
            </Label>
            <Input
              id="tagSortNumForm"
              value={null2str(tag.sort_num)}
              onChange={(e) => onChangeInput(InputType.SORT_NUM, e)}
              type="number"
              step="1"
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={save} className="my-4" color="primary" type="button">
          {t('form.save')}
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
