import PaymentThanks from 'assets/images/payment_thanks.svg'
import RegisteredThanks from 'assets/images/registered_thanks.svg'
import Stripe from 'assets/images/stripe.svg'
import classNames from 'classnames'
import { nl2br } from 'common/utils'
import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { IModal, modalService } from 'services/modal'

const Confirm: React.FC = () => {
  const { t } = useTranslation('adminCommon')
  const [modal, setModal] = useState<IModal>({
    isShow: false,
    message: '',
    onOk: () => {},
  })

  useEffect(() => {
    const subscription = modalService
      .onModal()
      .subscribe((modal) => setModal(modal))
    return () => subscription.unsubscribe()
  }, [])

  const close = () => modalService.close(modal)
  const ok = () => {
    close()
    modal.onOk()
  }

  if (modal.isRegisteredTeam) {
    return (
      <Modal isOpen={modal.isShow} onClosed={close} centered>
        <img
          src={RegisteredThanks}
          alt="payment_thanks"
          style={{ maxWidth: '50%', margin: '40px auto' }}
        />
        <ModalHeader className="row justify-content-center">
          <h2>{t('confirm.registeredTeam.title')}</h2>
        </ModalHeader>
        <ModalBody className="text-center">{nl2br(modal.message)}</ModalBody>
        <ModalFooter
          className="row justify-content-center mb-4"
          style={{ borderTop: 'unset' }}
        >
          <Button color="primary" onClick={ok}>
            {t('confirm.registeredTeam.close')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  if (modal.isPaid) {
    return (
      <Modal isOpen={modal.isShow} onClosed={close} centered>
        <img
          src={PaymentThanks}
          alt="payment_thanks"
          style={{ maxWidth: '50%', margin: '40px auto' }}
        />
        <ModalHeader className="row justify-content-center">
          <h2>{t('confirm.paid.title')}</h2>
        </ModalHeader>
        <ModalBody className="text-center">{nl2br(modal.message)}</ModalBody>
        <ModalFooter
          className="row justify-content-center mb-4"
          style={{ borderTop: 'unset' }}
        >
          <Button color="primary" onClick={ok}>
            {t('confirm.paid.close')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  if (modal.isStripeConnect) {
    return (
      <Modal isOpen={modal.isShow} onClosed={close} centered>
        <img
          src={Stripe}
          alt="stripe"
          style={{ maxWidth: '50%', margin: '40px auto' }}
        />
        <ModalHeader className="justify-content-center">
          <h2>{t('confirm.stripeConnect.title')}</h2>
        </ModalHeader>
        <ModalBody className="text-center">
          <Trans
            t={t}
            i18nKey="confirm.stripeConnect.message"
            components={{
              stripeLink: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className="font-weight-bold"
                  href="https://stripe.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              manualLink: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className="font-weight-bold"
                  href="https://one-stream.oopy.io/f73a886c-79c1-4391-82cb-00d8ef13b757"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              div: <div />,
              contactLink: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className="font-weight-bold"
                  href="https://one-stream.youcanbook.me/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </ModalBody>
        <ModalFooter className="justify-content-around pb-5">
          <Button color="primary" onClick={ok}>
            {t('confirm.stripeConnect.register')}
          </Button>
          <Button color="primary" onClick={close}>
            {t('confirm.stripeConnect.close')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  const modalSecondBtn = (
    <Button color="secondary" onClick={modal.isQrCode ? downloadQrCode : close}>
      {modal.isQrCode
        ? t('confirm.modalSecondBtn.download')
        : t('confirm.modalSecondBtn.cancel')}
    </Button>
  )

  if (modal.isDeleteVideo) {
    return (
      <Modal isOpen={modal.isShow} toggle={close} centered onClosed={close}>
        <ModalHeader className="row justify-content-center">
          <h2>{t('confirm.deleteVideo.title')}</h2>
        </ModalHeader>
        <ModalBody>
          <Trans
            t={t}
            i18nKey="confirm.deleteVideo.message"
            components={{ br: <br /> }}
          />
        </ModalBody>
        <ModalFooter style={{ borderTop: 'unset' }}>
          {modalSecondBtn}
          <Button color="primary" onClick={ok}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  const modalBody = modal.isQrCode ? (
    <QRCode className="qr-code" value={modal.message} />
  ) : (
    nl2br(modal.message)
  )

  return (
    <Modal isOpen={modal.isShow} toggle={close} centered onClosed={close}>
      <ModalBody className={classNames({ 'text-center pb-0': modal.isQrCode })}>
        {modalBody}
      </ModalBody>
      <ModalFooter style={{ borderTop: 'unset' }}>
        {modalSecondBtn}
        <Button color="primary" onClick={ok}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const downloadQrCode = () => {
  const el: any = document.querySelector('canvas.qr-code')
  el.toBlob((blob: any) => {
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'QRCode'
    a.click()
  })
}

export default Confirm
