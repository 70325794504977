import firebase from 'common/firebase'
import Encoding from 'encoding-japanese'
import { i18nValidation } from 'i18n/i18n'
import { IUserAndEmail } from './interfaces/admin/invite_mail'
import { IFieldCustomizeType } from './interfaces/field_customize'
import { ITeam } from './interfaces/team'
import { IUserFromCSV } from './interfaces/user'
import { date2Timestamp, isValid } from './times'

export const CSV_DEFAULT_HEADER = [
  'id',
  'password',
  'name',
  'group_ids',
  'expire',
  'is_password_changeable',
]

/**
 * Shift-JISのCSVを改行で分けたUnicodeの配列に変換する
 */
const sjisCsv2UnicodeArray = (csvData: string): string[] => {
  const strArray = Encoding.stringToCode(csvData)
  const unicodeArray = Encoding.convert(strArray, 'UNICODE', 'SJIS')
  const unicode = Encoding.codeToString(unicodeArray)
  return unicode.split(/\r\n|\n/)
}

/**
 * `IUserAndEmail`の配列に変換する
 */
export const formatCSVForInviteMailCSV = ({
  result,
}: FileReader): IUserAndEmail[] =>
  sjisCsv2UnicodeArray(String(result)).map((r) => {
    const arr = r.split(',')
    return { name: arr[0], email: arr[1] }
  })

/**
 * `IUserFromCSV`の配列に変換する
 */
export const formatUserCSV = (
  { customize_field }: ITeam,
  { result }: FileReader
): { headers: string[]; customHeaders: string[]; data: IUserFromCSV[] } => {
  const csv = sjisCsv2UnicodeArray(String(result))
  const headers = csv[0].split(',')
  const customHeaders = headers.filter((h) => !CSV_DEFAULT_HEADER.includes(h))
  const customizeFields = customize_field
    ? customize_field.filter((cf) =>
        customHeaders.find((ch) => ch === cf.fieldName)
      )
    : []
  if (customize_field && customizeFields.length !== customHeaders.length) {
    throw new Error(i18nValidation('csv.header.customField.notSet'))
  }

  const data = csv
    .filter((c, i) => c && i !== 0)
    .map((c, i) => {
      const datas = c.split(',')
      if (headers.length !== datas.length) {
        throw new Error(i18nValidation('csv.row.field.lack'))
      }

      const result: IUserFromCSV = {
        id: datas[0],
        password: datas[1],
        name: datas[2],
        group_ids: datas[3] ? datas[3].split('&') : [],
        expire: datas[4]
          ? date2Timestamp(new Date(datas[4]))
          : firebase.firestore.Timestamp.fromMillis(0),
        is_password_changeable: datas[5] === '1',
      }

      const rowStr = i18nValidation('csv.row.count', { num: i + 2 })

      datas.splice(0, CSV_DEFAULT_HEADER.length)
      datas.forEach((data, childIndex) => {
        const index = childIndex + CSV_DEFAULT_HEADER.length
        const customizeField = customize_field?.find(
          (cf) => headers[index] === cf.fieldName
        )
        switch (customizeField?.type) {
          case IFieldCustomizeType.NUMBER:
            if (!/^[+,-]?([1-9]\d*|0)$/.test(data) && data !== '') {
              throw new Error(
                i18nValidation('csv.input.number', {
                  rowStr,
                  fieldName: customizeField.fieldName,
                })
              )
            }

            result[headers[index]] = Number(data)
            break
          case IFieldCustomizeType.BOOL:
            if (!/YES|NO/.test(data) && data !== '') {
              throw new Error(
                i18nValidation('csv.input.boolean', {
                  rowStr,
                  fieldName: customizeField.fieldName,
                })
              )
            }

            result[headers[index]] = data === 'YES'
            break
          case IFieldCustomizeType.DATE:
            if (!isValid(data) && data !== '') {
              throw new Error(
                i18nValidation('csv.input.date', {
                  rowStr,
                  fieldName: customizeField.fieldName,
                })
              )
            }

            result[headers[index]] = data
            break
          default:
            result[headers[index]] = data
        }
      })
      return result
    })

  if (data.length > 100) {
    throw new Error(
      i18nValidation('csv.data.canRegist.upTo100', {
        number: data.length,
      })
    )
  }

  return { headers, customHeaders, data }
}
