import { ILandingPage } from 'common/interfaces/team'
import { standardModules } from 'common/quill'
import QuillForm from 'components/atoms/Input/QuillForm'
import ButtonLoading from 'components/atoms/Loading/ButtonLoading'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import {
  deleteBannerImage,
  onChangeFile,
  update as serviceUpdate,
} from 'services/admin/site_customize_lp'

const FormBody: React.FC = () => {
  const { t } = useTranslation('adminSiteCustomize')
  const { storeCache } = useContext(AuthContext)
  const [bannerFile, setBannerFile] = useState<File>()
  const [lp, setLp] = useState<ILandingPage>(
    storeCache.team?.landing_page || ({} as ILandingPage)
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const videoOptions = storeCache.videos.map((v) => (
    <option key={v.bunny.videoId} value={v.bunny.videoId}>
      {v.name}
    </option>
  ))

  const onChangeBannerFile = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChangeFile(e, setBannerFile)
  const onChangeSiteOverview = (value: string) =>
    setLp({ ...lp, site_overview: value })
  const onChangeIntroVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const bunnyId = e.target.value || null
    setLp({ ...lp, intro_video_bunny_id: bunnyId })
  }

  const update = () => serviceUpdate(storeCache, bannerFile, lp, setIsLoading)

  if (!storeCache.team) return null

  return (
    <Form>
      <FormGroup row>
        <Col md="6">
          <Label for="bannerForm">
            {t('landingCustomize.form.bannerImage')}
          </Label>
          <CustomInput
            id="bannerForm"
            onChange={onChangeBannerFile}
            type="file"
            accept="image/*"
          />
        </Col>
      </FormGroup>
      {lp.banner_image !== null && (
        <Row className="mb-4">
          <Col>
            <Label>{t('landingCustomize.form.settedBannerImage')}</Label>
            <Row>
              <Col md="4">
                <img
                  height="auto"
                  width={200}
                  src={lp.banner_image.path}
                  alt="{t('landingCustomize.form.bannerImage')}"
                />
              </Col>
              <Col md="8">
                <Button
                  onClick={() => deleteBannerImage(storeCache)}
                  className="my-4"
                  color="danger"
                  type="button"
                >
                  {t('delete')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <FormGroup row>
        <Col md="10">
          <Label>{t('landingCustomize.form.overview')}</Label>
          <pre>
            <QuillForm
              value={lp.site_overview}
              onChange={onChangeSiteOverview}
              modules={standardModules()}
            />
          </pre>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col md="6">
          <Label for="introVideoForm">
            {t('landingCustomize.form.introductionVideo')}
          </Label>
          <Input
            id="introVideoForm"
            value={lp.intro_video_bunny_id ?? ''}
            onChange={onChangeIntroVideo}
            type="select"
          >
            <option value="">{t('landingCustomize.form.notSet')}</option>
            {videoOptions}
          </Input>
        </Col>
      </FormGroup>

      <div className="text-center">
        {isLoading ? (
          <ButtonLoading className="my-4" />
        ) : (
          <Button
            onClick={update}
            className="my-4"
            color="primary"
            type="button"
          >
            {t('save')}
          </Button>
        )}
      </div>
    </Form>
  )
}

export default FormBody
