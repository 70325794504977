import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form } from 'reactstrap'

type TCheckoutForm = {
  clientSecret: string
  buttonLoading: boolean
  onClick: (stripe: Stripe | null, elements: StripeElements | null) => void
}

const CheckoutForm: React.FC<TCheckoutForm> = (props) => {
  const { t } = useTranslation('itemSelect')
  const stripe = useStripe()
  const elements = useElements()

  return (
    <Form>
      <PaymentElement />
      <div className="mt-4 text-center">
        <Button
          block
          color="primary"
          onClick={() => props.onClick(stripe, elements)}
          disabled={props.buttonLoading}
        >
          {t('paymentFormModal.checkoutForm.apply')}
        </Button>
      </div>
    </Form>
  )
}

export default CheckoutForm
