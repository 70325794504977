import { IBookmark } from 'common/interfaces/bookmark'
import { ILogVideoPageOpen } from 'common/interfaces/log'
import { IPurchased } from 'common/interfaces/stripe/price'
import { IWatchlistContent } from 'common/interfaces/watchlist'
import { makeLivePath, makeVideoPath } from 'common/link_path'
import { dateFormat } from 'common/times'
import WideContentCard from 'components/molecules/User/WideContentCard/WideContentCard'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import {
  IMyVideoListData,
  PageType,
  createTitle,
  pageType,
  useData,
} from 'services/user/my_video_list'
import './myvideolist.scss'

const MyVideoList: React.FC = () => {
  const { storeCache } = useContext(AuthContext)
  const [data, setData] = useState<IMyVideoListData>({
    videoPageOpenLogs: [],
    bookmarks: [],
    watchLaters: [],
    purchaseds: [],
  })

  useData(storeCache, setData)

  return (
    <div className="user-my-video-list">
      <Container>
        <h1 className="title">{createTitle()}</h1>
        {createElements(data)}
      </Container>
    </div>
  )
}

const createElements = (data: IMyVideoListData): JSX.Element[] => {
  switch (pageType()) {
    case PageType.VIDEO_PAGE_OPEN_LOGS:
      const result: JSX.Element[] = []
      let beforeDate = ''
      data.videoPageOpenLogs.reverse().forEach((history) => {
        const date = dateFormat(history.timestamp)
        if (beforeDate !== date) {
          result.push(createDateElement(history.timestamp.seconds + 1, date))
          beforeDate = date
        }
        result.push(createVideoCardElement(history))
      })
      return result
    case PageType.BOOKMARK:
      return data.bookmarks.map((d) => createVideoCardElement(d))
    case PageType.WATCH_LATER:
      return data.watchLaters.map((d) => createVideoCardElement(d))
    case PageType.PURCHASED:
      return data.purchaseds.map((d) => createVideoCardElement(d))
  }
}

const createDateElement = (key: number, formattedDate: string): JSX.Element => (
  <div key={key} className="date">
    {formattedDate}
  </div>
)

const createVideoCardElement = (
  data: ILogVideoPageOpen | IBookmark | IWatchlistContent | IPurchased
): JSX.Element => {
  const timestamp = 'timestamp' in data ? data.timestamp : data.created_at
  const isLive = 'is_video' in data && !data.is_video
  const contentId = 'content_id' in data ? data.content_id : data.video_id
  return (
    <Link
      key={timestamp.seconds}
      to={isLive ? makeLivePath(contentId) : makeVideoPath(contentId)}
      className="video-list-card-link"
    >
      <WideContentCard
        className="video-list-card"
        videoId={contentId}
        purchaseds={'purchaseds' in data ? data.purchaseds : []}
      />
    </Link>
  )
}

export default MyVideoList
