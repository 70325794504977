import { ICreator } from 'common/interfaces/creator'
import { makeCreatorDetailPath } from 'common/link_path'
import { AuthContext } from 'providers/AuthProvider'
import React, { CSSProperties, useContext } from 'react'
import { Link } from 'react-router-dom'
import './creatoricon.css'

interface ICreatorIcon {
  creator?: ICreator
  creatorId?: string
  width?: string
  centered?: boolean
  enableLink?: boolean
}

const CreatorIcon: React.FC<ICreatorIcon> = ({
  creator,
  creatorId,
  width,
  centered,
  enableLink,
}) => {
  const { creators } = useContext(AuthContext).storeCache

  let c = creator
  if (!c) {
    c = creators.find((c) => c.id === creatorId)
    if (!c) return null
  }
  if (!c.image) return null

  const wrapStyle: CSSProperties = {
    textAlign: centered ? 'center' : 'unset',
  }
  const imgStyle: CSSProperties = {
    width: width || '12rem',
  }

  const imgEl = (
    <img
      className="creator-icon"
      style={imgStyle}
      src={c.image}
      alt="creator icon"
    />
  )

  return (
    <div className="creator-icon-wrapper" style={wrapStyle}>
      {enableLink ? (
        <Link to={makeCreatorDetailPath(c.id)}>{imgEl}</Link>
      ) : (
        imgEl
      )}
    </div>
  )
}

export default CreatorIcon
