import { IStoreCache } from 'common/interfaces/auth_provider'
import {
  ITopItemOrder,
  TopItemOrderType,
} from 'common/interfaces/top_item_order'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Input, Row } from 'reactstrap'
import { ITEM_LIST } from 'services/admin/site_customize_order'

/**
 * 追加カードの「追加」ボタン
 */
const AddButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation('adminSiteCustomize')
  return (
    <Button
      className="add-button"
      onClick={onClick}
      color="primary"
      size="sm"
      type="button"
    >
      {t('add')}
    </Button>
  )
}

/**
 * Inputなしの追加カード
 */
export const AddColCardWoInput: React.FC<{
  title: string
  onAdd: () => void
}> = ({ title, onAdd }) => (
  <Col xs="6" md="12" lg="6">
    <div className="add-card">
      <div>
        <div>{title}</div>
      </div>
      <AddButton onClick={onAdd} />
    </div>
  </Col>
)

/**
 * Inputありの追加カード
 */
export const AddColCardWithInput: React.FC<{
  title: string
  onChange: (value: string) => void
  defaultValue: string
  options: { id: string; name: string }[]
  onAdd: () => void
}> = ({ title, onChange, defaultValue, options, onAdd }) => {
  const { t } = useTranslation('adminSiteCustomize')
  return (
    <Col xs="6" md="12" lg="6">
      <div className="add-card with-input">
        <div>
          <div>{title}</div>
          <Input
            onChange={(e) => onChange(e.target.value)}
            defaultValue={defaultValue}
            type="select"
          >
            <option value={ITEM_LIST}>{t('topPageCustomize.list')}</option>
            {createOptions(options)}
          </Input>
        </div>
        <AddButton onClick={onAdd} />
      </div>
    </Col>
  )
}

const PopularVideoOptions: React.FC = () => {
  const { t } = useTranslation('adminSiteCustomize')
  const { storeCache } = useContext(AuthContext)
  return (
    <>
      <optgroup label={t('topPageCustomize.popularVideoOption.category')}>
        {createOptions(storeCache.categories)}
      </optgroup>
      <optgroup label={t('topPageCustomize.popularVideoOption.tag')}>
        {createOptions(storeCache.tags)}
      </optgroup>
      <optgroup label={t('topPageCustomize.popularVideoOption.creator')}>
        {createOptions(storeCache.creators)}
      </optgroup>
    </>
  )
}

/**
 * 人気動画の追加カード
 */
export const AddColCardPopularVideo: React.FC<{
  onChange: (value: string) => void
  defaultValue: string
  onAdd: () => void
}> = ({ onChange, defaultValue, onAdd }) => {
  const { t } = useTranslation('adminSiteCustomize')
  return (
    <Col xs="6" md="12" lg="6">
      <div className="add-card with-input">
        <div>
          <div>{t('topPageCustomize.itemTitles.popularVideos')}</div>
          <Input
            onChange={(e) => onChange(e.target.value)}
            defaultValue={defaultValue}
            type="select"
          >
            <option value={ITEM_LIST}>{t('topPageCustomize.allVideos')}</option>
            <PopularVideoOptions />
          </Input>
        </div>
        <AddButton onClick={onAdd} />
      </div>
    </Col>
  )
}

/**
 * 表示順で使用するxボタンを含むタイトルの生成
 */
const titleRow = (title: string, onRemove: () => void): JSX.Element => (
  <Row>
    <Col xs="10">{title}</Col>
    <Col xs="2" className="text-right">
      <i
        className="fas fa-times"
        style={{ cursor: 'default' }}
        onClick={onRemove}
      />
    </Col>
  </Row>
)

/**
 * Inputなしの追加されたカード
 */
const AddedCardWoInput: React.FC<{
  index: number
  title: string
  onRemove: () => void
}> = ({ index, title, onRemove }) => (
  <div className="placed-card">
    {titleRow(`${index + 1}. ${title}`, onRemove)}
  </div>
)

/**
 * Inputありの追加されたカード
 */
const AddedCardWithInput: React.FC<{
  index: number
  title: string
  defaultValue: string | undefined
  options: { id: string; name: string }[]
  onChange: (targetId: string) => void
  onRemove: () => void
}> = ({
  index,
  title,
  defaultValue = ITEM_LIST,
  options,
  onChange,
  onRemove,
}) => {
  const { t } = useTranslation('adminSiteCustomize')
  return (
    <div className="placed-card">
      {titleRow(`${index + 1}. ${title}`, onRemove)}
      <Input
        onChange={(e) => onChange(e.target.value)}
        defaultValue={defaultValue}
        type="select"
      >
        <option value={ITEM_LIST}>{t('topPageCustomize.list')}</option>
        {createOptions(options)}
      </Input>
    </div>
  )
}

/**
 * 人気動画の追加されたカード
 */
export const AddedCardPopularVideo: React.FC<{
  index: number
  defaultValue: string | undefined
  onChange: (targetId: string) => void
  onRemove: () => void
}> = ({ index, defaultValue, onChange, onRemove }) => {
  const { t } = useTranslation('adminSiteCustomize')
  return (
    <div className="placed-card">
      {titleRow(
        `${index + 1}. ${t('topPageCustomize.itemTitles.popularVideos')}`,
        onRemove
      )}
      <Input
        onChange={(e) => onChange(e.target.value)}
        defaultValue={defaultValue}
        type="select"
      >
        <option value={ITEM_LIST}>{t('topPageCustomize.allVideos')}</option>
        <PopularVideoOptions />
      </Input>
    </div>
  )
}

/**
 * Inputで使う`<option>`の生成
 */
const createOptions = (op: { id: string; name: string }[]): JSX.Element[] =>
  op.map((o) => (
    <option key={o.id} value={o.id}>
      {o.name}
    </option>
  ))

/**
 * 表示順で使用する各コンテンツのカード
 */
export const ItemCard: React.FC<{
  item: ITopItemOrder
  storeCache: IStoreCache
  index: number
  onChange: (index: number, type: TopItemOrderType, targetId: string) => void
  onRemove: (index: number) => void
}> = ({ item, storeCache, index, onChange, onRemove }) => {
  const i18n = useTranslation('adminSiteCustomize').t(
    'topPageCustomize.itemTitles',
    { returnObjects: true }
  )
  const onChangeInput = (targetId: string) =>
    onChange(index, item.type, targetId)
  const onRemoveItem = () => onRemove(index)
  switch (item.type) {
    case TopItemOrderType.DIVIDER:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.divider}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.TOP_IMAGES:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.topImages}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.CHOOSE_CONTENT_TYPE:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.chooseContentTypes}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.CHOOSE_GENRE_CATEGORY:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.chooseCategories}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.CHOOSE_GENRE_TAG:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.chooseTags}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.CHOOSE_GENRE_CREATOR:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.chooseCreators}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.CHOOSE_PLAY_STATUS:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.choosePlayStatus}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.SEARCH_VIDEO_INPUT:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.searchVideoInput}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.VIDEOS_NEWER:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.newVideos}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.VIDEOS_POPULAR:
    case TopItemOrderType.VIDEOS_POPULAR_IN_GENRE:
      return (
        <AddedCardPopularVideo
          index={index}
          defaultValue={item.targetId}
          onChange={onChangeInput}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.CATEGORIES_VIDEO:
    case TopItemOrderType.VIDEOS_IN_CATEGORY:
      return (
        <AddedCardWithInput
          index={index}
          title={i18n.videoCategories}
          defaultValue={item.targetId}
          options={storeCache.categories}
          onChange={onChangeInput}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.TAGS_VIDEO:
    case TopItemOrderType.VIDEOS_IN_TAG:
      return (
        <AddedCardWithInput
          index={index}
          title={i18n.videoTags}
          defaultValue={item.targetId}
          options={storeCache.tags}
          onChange={onChangeInput}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.CREATORS_VIDEO:
    case TopItemOrderType.VIDEOS_IN_CREATOR:
      return (
        <AddedCardWithInput
          index={index}
          title={i18n.videoCreators}
          defaultValue={item.targetId}
          options={storeCache.creators}
          onChange={onChangeInput}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.LIVES_NEWER:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.newLives}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.CATEGORIES_LIVE:
    case TopItemOrderType.LIVES_IN_CATEGORY:
      return (
        <AddedCardWithInput
          index={index}
          title={i18n.liveCategories}
          defaultValue={item.targetId}
          options={storeCache.categories}
          onChange={onChangeInput}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.TAGS_LIVE:
    case TopItemOrderType.LIVES_IN_TAG:
      return (
        <AddedCardWithInput
          index={index}
          title={i18n.liveTags}
          defaultValue={item.targetId}
          options={storeCache.tags}
          onChange={onChangeInput}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.CREATORS_LIVE:
    case TopItemOrderType.LIVES_IN_CREATOR:
      return (
        <AddedCardWithInput
          index={index}
          title={i18n.liveCreators}
          defaultValue={item.targetId}
          options={storeCache.creators}
          onChange={onChangeInput}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.EVENTS_NEWER:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.newEvents}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.PLAYLISTS_NEWER:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.newPlaylists}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.CATEGORIES_PLAYLIST:
    case TopItemOrderType.PLAYLISTS_IN_CATEGORY:
      return (
        <AddedCardWithInput
          index={index}
          title={i18n.playlistCategories}
          defaultValue={item.targetId}
          options={storeCache.categories}
          onChange={onChangeInput}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.TAGS_PLAYLIST:
    case TopItemOrderType.PLAYLISTS_IN_TAG:
      return (
        <AddedCardWithInput
          index={index}
          title={i18n.playlistTags}
          defaultValue={item.targetId}
          options={storeCache.tags}
          onChange={onChangeInput}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.PLAYLISTS:
    case TopItemOrderType.VIDEOS_IN_PLAYLIST:
      return (
        <AddedCardWithInput
          index={index}
          title={i18n.playlists}
          defaultValue={item.targetId}
          options={storeCache.playlists}
          onChange={onChangeInput}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.USER_BOOKMARKS:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.userBookmarks}
          onRemove={onRemoveItem}
        />
      )
    case TopItemOrderType.USER_VIEW_LOGS:
      return (
        <AddedCardWoInput
          index={index}
          title={i18n.userViewHistories}
          onRemove={onRemoveItem}
        />
      )
  }
}
