import { Routes } from 'common/enums'
import { IMailTemplate } from 'common/interfaces/team'
import ButtonRight from 'components/atoms/Button/Right'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/MailTemplateForm/FormBody'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'

const MailTemplateForm: React.FC = () => {
  const { t } = useTranslation('adminMailTemplate')
  const history = useHistory()
  const { mailTemplate } = useLocation<{ mailTemplate: IMailTemplate }>().state
  return (
    <>
      <H1 title={t('form.edit')} />

      <Container fluid>
        <ButtonRight
          nextPage={Routes.AdminMailTemplate}
          content={t('form.back')}
        />
        <FormBody mailTemplateObj={mailTemplate} history={history} />
      </Container>
    </>
  )
}

export default MailTemplateForm
