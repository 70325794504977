import axios from 'axios'
import { IZoomSignature } from 'common/interfaces/broadcast'

const CF_API_BASE_URL = String(process.env.REACT_APP_FIREBASE_FUNCTIONS_URI)

/**
 * get zoom signature and sdk_key
 * @param operatedUserId user id
 * @param teamId team id
 * @param meetingNumber zoom meeting number
 */
export const getZoomSignature = async (
  operatedUserId: string | undefined,
  teamId: string | undefined,
  meetingNumber: string
): Promise<IZoomSignature | null> => {
  try {
    const url = `${CF_API_BASE_URL}/broadcasts/zoom/signature`
    const headers = { 'Content-Type': 'application/json' }
    const data = { operatedUserId, teamId, meetingNumber }
    const response = await axios.post<{
      success: boolean
      data: IZoomSignature | null
    }>(url, data, { headers })
    if (response.data.success) return response.data.data
  } catch (e) {
    console.log(e)
  }
  return null
}
